import React from 'react';import { Theme, List, ListItem, Box, Typography } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { convertISO8601StringDayToDate } from 'src/common/string';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
function DialogViewReadMessage({ listConversation }) {
  const theme = useTheme();

  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const [showConversation, setShowConversation] = React.useState(false);
  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        iconClose: {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f1f2f5',
          borderRadius: '50%',
          padding: 1,
          height: 20,
          width: 20,
          right: -5,
          top: 0,
          zIndex: 40,
          border: '2px solid rgba(0,0,0,0.1)',
          '&:hover': {
            cursor: 'pointer'
          }
        },
        arrow: {
          backgroundColor: '#ffffff',
          padding: '8px 12px',
          borderRadius: '100%',
          transform: 'rotate(45deg)'
        },
        countConversation: {
          width: '100%',
          height: '100%',
          backgroundColor: '#33333375',
          borderRadius: '50%',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fafafa',
          fontWeight: 600,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#33333350'
          }
        },
        customScrollbar: {
          '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
            borderRadius: '100px'
          }
        },
        styleTextName: {
          fontSize: '14px',
          fontWeight: '600',
          color: theme.palette.mode === 'light' ? '1c1e21' : '#fafafa'
        },
        styleTextTime: {
          fontSize: '10px',
          fontWeight: '500',
          opacity: 0.8,
          color: theme.palette.mode === 'light' ? '1c1e21' : '#fafafa'
        }
      }),
    { index: 1 }
  );

  const classes = useStyles();
  // const dispatch = useDispatch();
  // const history = useHistory();

  const ConversationPlus = props => {
    const { conversation } = props;

    return (
      <>
        <ListItem
          button
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '4px',
            gap: '8px',
            lineHeight: '1.3'
          }}
        >
          <AvatarSocial
            type="feed"
            style={{
              height: 30,
              width: 30,
              marginRight: '2px'
            }}
            avatarObj={conversation.avatar_media?.show_url}
          />
          <Typography className={classes.styleTextName}>
            {conversation?.user.name.split(' ')[0]}
          </Typography>
          <Typography className={classes.styleTextTime}>
            {convertISO8601StringDayToDate(conversation._updatedAt)}
          </Typography>
        </ListItem>
      </>
    );
  };

  return (
    <Box
      style={{ position: 'relative', display: 'flex' }}
      onMouseEnter={() => setShowConversation(true)}
      onMouseLeave={() => setShowConversation(false)}
    >
      {showConversation && (
        <List
          // onMouseEnter={() => setShowConversation(true)}
          // onMouseLeave={() => setShowConversation(false)}
          style={{
            padding: '10px',
            borderRadius: '10px',
            backgroundColor:
              theme.palette.mode === 'light' ? '#fafafa' : '#1c1e21',
            width: '240px',
            height: 'auto',
            overflowY: 'auto',
            position: 'absolute',
            right: 0,
            bottom: 0,
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.1),0 12px 28px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100
          }}
          className={classes.customScrollbar}
        >
          {listConversation.map((conversation: any) => (
            <ConversationPlus
              key={conversation._id}
              conversation={conversation}
            />
          ))}
        </List>
      )}
      <div
        style={{
          position: 'relative',
          height: 14,
          width: 14
        }}
      >
        <AvatarSocial
          type="feed"
          style={{
            height: 14,
            width: 14
          }}
        />
        <Box className={classes.countConversation}>
          <Typography style={{ fontSize: '10px' }}>+{listConversation.length - 3}</Typography>
        </Box>
      </div>
    </Box>
  );
}

export default DialogViewReadMessage;
