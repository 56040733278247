import * as types from 'src/constants/store/pageSocial';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

export interface RolePreviewEditUser extends RolePreviewEdit {
  // update when use role me
  role: '';
  page_owner_id: null;
}

export interface RolePreviewEditPage extends RolePreviewEdit {
  // update when use role page
  // admin và moderator for chính page đó tương tác với bài viết trong page
  // '' for các page khác của bản thân tương tác với bài viết trong page
  role: '' | 'admin' | 'moderator';
  page_owner_id: string;
}

export const pagePreview = (data: Object) => ({
  type: types.PAGE_PREVIEW_INFO,
  payload: {
    data
  }
});

export const roleEditPreviewDetailUser = (data: RolePreviewEditUser) => ({
  type: types.ROLE_EDIT_PREVIEW_DETAIL_USER,
  payload: {
    data
  }
});

export const roleEditPreviewDetailPage = (data: RolePreviewEditPage) => ({
  type: types.ROLE_EDIT_PREVIEW_DETAIL_PAGE,
  payload: {
    data
  }
});

export const getPageDetailReq = (id: any) => ({
  type: types.GET_PAGE_DETAIL_REQ,
  payload: {
    id
  }
});

export const getPageDetailSuccess = (data: Object) => ({
  type: types.GET_PAGE_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getPageDetailError = (data: Object) => ({
  type: types.GET_PAGE_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const setPageTarget = (data: Object) => ({
  type: types.SET_PAGE_TARGET,
  payload: {
    data
  }
});

export const resetPageTarget = () => ({
  type: types.RESET_PAGE_TARGET
});

export const getListMyPageReq = (params: any) => ({
  type: types.GET_LIST_MY_PAGE_REQ,
  payload: {
    params
  }
});

export const resetListPage = () => ({
  type: types.RESET_LIST_PAGE
});

export const updateHasMore = (value: any) => ({
  type: types.UPDATE_HAS_MORE,
  payload: {
    value
  }
});

export const isLoadingListPage = (value: any) => ({
  type: types.LOADING_LIST_PAGE,
  payload: {
    value
  }
});

export const getSettingPageRequest = (id: any) => ({
  type: types.GET_SETTING_PAGE_REQ,
  payload: {
    id
  }
});

export const getSettingPageSuccess = data => ({
  type: types.GET_SETTING_PAGE_SUCCESS,
  payload: {
    data
  }
});

export const getListMyPageSuccess = (data: Object) => ({
  type: types.GET_LIST_MY_PAGE_SUCCESS,
  payload: {
    data
  }
});

export const filterListMyPage = data => ({
  type: types.FILTER_LIST_MY_PAGE,
  payload: {
    data
  }
});

export const updateLocationPageSuccess = (
  address: string | null,
  location: Object
) => ({
  type: types.UPDATE_LOCATION_PAGE,
  payload: {
    data: {
      address,
      location
    }
  }
});

export const resetPage = () => ({
  type: types.RESET_PAGE
});

export const getTotalCensorshipDiary = (total: number) => ({
  type: types.GET_TOTAL_CENSORSHIP_DIARY,
  payload: total
});

export const updateTotalCensorshipDiary = (number: number) => ({
  type: types.UPDATE_TOTAL_CENSORSHIP_DIARY,
  payload: number
});

export const updateNotifyVerifyPageName = (pageId: string) => ({
  type: types.UPDATE_PAGE_NAME_NOTIFY_VERIFY,
  payload: pageId
});
