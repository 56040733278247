import { apiGet } from './apis';

export const getListProvince = async () => {
  return apiGet(`provinces`, null, null);
};

export const getListProvincesApi = async () => {
  return apiGet('list_provinces', null, null);
};

export const getListAddressCheckIn = async value => {
  return apiGet('places', { keyword: value, limit: 10 }, null);
};

export const getListDistrictsApi = async provinceId => {
  return apiGet('list_districts', { province_id: provinceId }, null);
};

export const getListWardsApi = async districtId => {
  return apiGet('list_wards', { district_id: districtId }, null);
};
