import { Tab, Tabs, Tooltip } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import menuglobal from 'src/assets/images/menuglobal.png';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      tabs: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 2,
        '& .MuiTabs-indicator': {
          backgroundColor: 'unset'
        }
        // marginBottom: '-5px'
      },
      tabItem: {
        [theme.breakpoints.up('md')]: {
          minWidth: '120px !important'
        },
        [theme.breakpoints.down('md')]: {
          minWidth: '60px !important'
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '20px !important'
        },
        '&:hover': {
          backgroundColor: theme.palette.button.default.hover,
          borderRadius: '5px'
        }
      },
      selected: {
        '&:hover': {
          backgroundColor: 'button.primary.hover'
        }
      }
    }),
  { index: 1 }
);

interface PropType {
  valueTab: string; // Change the type to string to match the tab keys
  handleChangeTab: Function;
  handleLinkToHome?: Function;
  handleLinkToStore?: Function;
}

function TabsGlobal(props: PropType) {
  const classes = useStyles(props);
  const { valueTab, handleChangeTab, handleLinkToHome, handleLinkToStore } =
    props;
  const theme = useTheme();

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`
    };
  }

  const renderIcon = (tab: any, index: any) => {
    return (
      <Tooltip key={tab.key} title={tab.title ?? 'Trang chủ'}>
        <Tab
          sx={{
            borderBottom: valueTab === tab.key ? '3px solid #f3802e' : 'none'
          }}
          onClick={() => tab.action && tab.action()}
          className={classes.tabItem}
          icon={
            <div
              style={{
                height: '28px',
                width: '28px',
                display: 'flex',
                position: 'relative'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '28px',
                  width: '28px',
                  overflow: 'hidden'
                }}
              >
                <img
                  style={{
                    // width: '36px',
                    height: '707px',
                    objectFit: 'cover',
                    transform: `translate(${0}px, ${
                      valueTab === tab.key
                        ? tab.posHere
                        : theme.palette.mode === 'dark'
                        ? tab.posDark
                        : tab.posLight
                    }px)`
                  }}
                  src={menuglobal}
                  alt="menuglobal"
                ></img>
              </div>
            </div>
          }
          {...a11yProps(index)}
          value={tab.key} // Use the tab.key as the value
          key={index}
          classes={{ selected: classes.selected }}
        />
      </Tooltip>
    );
  };

  return (
    <div className={classes.tabs}>
      <Tabs
        value={
          ['home', 'media', 'watch', 'store', 'group'].indexOf(valueTab) === -1
            ? 0
            : ['home', 'media', 'watch', 'store', 'group'].indexOf(valueTab)
        }
        onChange={(event, newValue) => {
          handleChangeTab(event, newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
      >
        {[
          {
            key: 'home',
            title: 'Trang chủ',
            action: handleLinkToHome,
            posHere: -441,
            posDark: -502,
            posLight: -473
          },
          {
            key: 'media',
            title: 'Khoảnh khắc',
            posHere: -163,
            posDark: -230,
            posLight: -196
          },
          {
            key: 'watch',
            title: 'Watch',
            posHere: -262,
            posDark: -322,
            posLight: -292
          },
          {
            key: 'store',
            title: 'Cửa hàng',
            action: handleLinkToStore,
            posHere: -630,
            posDark: -684,
            posLight: -656
          },
          {
            key: 'group',
            title: 'Nhóm',
            posHere: -534,
            posDark: -598,
            posLight: -566
          }
        ].map((el, index) => renderIcon(el, index))}
      </Tabs>
    </div>
  );
}

export default TabsGlobal;
