import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CustomizedMenusProps {
  label?: any;
  disabled?: boolean;
  color?: string;
  action?: (e) => void;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | any;
  variant?: string | any;
  textColor?: string;
  loading?: boolean;
  styleHover?: React.CSSProperties;
  solid?: boolean;
  type?: string;
  styleText?: any;
}

export default function CustomizedMenus(props: CustomizedMenusProps) {
  const {
    label,
    disabled,
    color,
    action,
    fullWidth,
    icon,
    endIcon,
    style,
    variant,
    textColor,
    loading,
    styleHover,
    solid,
    styleText = {},
    type = 'button'
  } = props;

  const theme = useTheme();
  return (
    <LoadingButton
      type={type}
      id="demo-customized-button"
      aria-haspopup="true"
      variant={variant ? variant : 'contained'}
      disableElevation
      loading={loading}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
      onClick={e => {
        action && action(e);
      }}
      startIcon={icon}
      endIcon={endIcon}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        textTransform: 'none',
        fontWeight: '600 !important',
        fontSize: '16px',
        padding: '4px 12px',
        marginRight: '7px',
        backgroundColor: loading
          ? 'button.custom.background'
          : variant === 'text'
          ? 'transparent'
          : color && !disabled
          ? color
          : theme.palette.mode === 'dark'
          ? '#4b4b4b'
          : 'button.custom.background',
        color: textColor
          ? textColor
          : color
          ? '#f7f7f7'
          : theme.palette.mode === 'dark'
          ? '#e4e6ea'
          : '#0b0b0b',
        '&:hover': {
          backgroundColor: solid
            ? 'button.custom.hoverSolid'
            : 'button.custom.hover',
          ...styleHover
        },
        height: style?.height ? style.height : 'auto',
        borderRadius: '6px',
        cursor: disabled ? 'no-drop' : 'pointer',
        opacity: disabled ? '0.5' : '1',
        ...style
      }}
    >
      <Box
        sx={{
          fontSize: style?.fontSizeText ? style.fontSizeText : 'inherit',
          lineHeight: style?.lineHeightText ? style.lineHeightText : 'inherit',
          whiteSpace: 'normal',
          textAlign: 'center',
          ...styleText
        }}
      >
        {label}
      </Box>
    </LoadingButton>
  );
}
