import {
  Button,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  PopperProps,
  Theme,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { loadingSearchRecruit } from 'src/store/action/socialRecruitAction';

const StyledMenu = styled((props: PopperProps) => <Popper {...props} />)(
  ({ theme }) => ({
    '& .MuiPaper-root': {
      '&::-webkit-scrollbar': {
        width: '10px'
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
        borderRadius: '100px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
      },
      borderRadius: 8,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        }
      }
    },
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '.875rem'
  })
);

const useStyles = makeStyles((theme: Theme) => ({
  textTitle: {
    fontWeight: 'bold',
    opacity: '.7'
  }
}));

interface Props {
  action?: any;
  label?: String;
  color?: String;
  options?: any;
  style?: React.CSSProperties | any;
  width?: String;
  startIcon?: any;
  endIcon?: any;
  labelStyle?: React.CSSProperties | any;
  disabled?: boolean;
  handleAction?: any;
  popupStyle?: React.CSSProperties | any;
  type?: String;
  popperClassName?: String | any;
  colorText?: String;
  height?: String;
}

export default function CustomizedMenus(props: Props) {
  const {
    action,
    label,
    color,
    options,
    style,
    width,
    startIcon,
    endIcon,
    labelStyle,
    disabled,
    handleAction,
    popupStyle,
    type,
    popperClassName = '',
    colorText,
    height
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (action) {
      action();
    } else {
      setAnchorEl(event.currentTarget);
      setOpenPopper(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    return (
      <div style={{ maxHeight: '210px', overflowY: 'auto' }}>
        {options?.map((item: any) => (
          <MenuItem
            disabled={item?.disabled ?? false}
            key={item.id}
            onClick={() => {
              if (item.action) {
                item.action(anchorEl);
                setOpenPopper(false);
              } else {
                handleAction(item);
                if (type === 'recruit') {
                  dispatch(loadingSearchRecruit(true));
                  setOpenPopper(false);
                }
              }
              handleClose();
            }}
          >
            {item.icon && (
              <ListItemIcon>
                <i style={item.styleIcon} className={item.icon}></i>
              </ListItemIcon>
            )}
            <ListItemText
              id="switch-list-label-wifi"
              primary={
                <Typography
                  fontWeight={500}
                  fontSize={'14px'}
                  style={item.labelStyle ? item.labelStyle : labelStyle}
                >
                  {item.label || item?.province_name}
                </Typography>
              }
              secondary={
                <Typography
                  style={{
                    wordBreak: 'normal',
                    whiteSpace: 'break-spaces'
                  }}
                >
                  {item.description}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Button
        disabled={disabled}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={{
          width,
          textTransform: 'none',
          padding: '4px 12px',
          fontWeight: '600',
          fontSize: '.875rem',
          marginLeft: '6px',
          height: '36px',
          backgroundColor: color ? color : 'button.secondary.background',
          color: colorText ? colorText : 'text.primary',
          ...style
        }}
      >
        <Typography fontWeight={500} fontSize={'14px'} sx={labelStyle}>
          {label}
        </Typography>
      </Button>
      {openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            id="simple_menu"
            className={popperClassName}
            anchorEl={anchorEl}
            open={openPopper}
            placement={popupStyle?.placement || 'bottom-end'}
            disablePortal
            style={{
              // padding: '5px 0px',
              zIndex: 1001,
              width,
              // height: height ? height : null,
              overflow: options && options.length > 10 ? 'auto' : 'visible',
              maxHeight: options && options.length > 10 ? '590px' : 'auto',
              maxWidth: type === 'group' ? '320px' : '600px',
              minWidth: popupStyle?.minWidth || '200px',
              ...popupStyle
            }}
          >
            <Paper>{renderMenuItem()}</Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
}
