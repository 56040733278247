import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { shortenLargeNumber } from 'src/common/number';
import { truncateText } from 'src/helpers/string';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import styleMention from './mention.module.css';
import styleDarkMention from './mention-dark.module.css';
import { limitText } from 'src/common/limitText';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      wrapItem: {
        display: 'flex',
        alignItems: ' center'
      },
      wrapText: {
        display: 'flex',
        flexDirection: 'column'
      },
      subTitle: {
        fontSize: '13px !important',
        color: '#65676b',
        display: 'flex',
        alignItems: 'center'
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }
    }),
  { index: 1 }
);

interface MentionsProps {
  inputRef: any;
  idInput: string | any;
  name?: string;
  localContent?: any;
  handleChange?: any;
  handlePaste?: any;
  debounceSearchGlobal?: any;
  handleActionMention?: any;
  handleKeyDown?: any;
  placeholder?: string;
  style?: React.CSSProperties;
  container?: any;
  maxLengthPlaceholder?: any;
  sendTypingEvent?: any;
  handleFocus?: any;
  type?: string;
  styleInput?: React.CSSProperties;
  styleHighLighter?: React.CSSProperties;
  disabled?: boolean;
}

const Mentions: React.FC<MentionsProps> = props => {
  const {
    inputRef,
    idInput,
    name,
    localContent,
    handleChange,
    handlePaste,
    debounceSearchGlobal,
    handleActionMention,
    handleKeyDown,
    placeholder,
    style,
    container,
    maxLengthPlaceholder,
    sendTypingEvent,
    handleFocus,
    type,
    styleInput,
    styleHighLighter,
    disabled
  } = props;
  const classes = useStyles();
  const theme: any = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const truncatedPlaceholder = maxLengthPlaceholder
    ? truncateText(placeholder, maxLengthPlaceholder)
    : placeholder;

  const renderTitle = el => {
    if (el?.page_relationship) {
      return 'Trang';
    } else if (el?.group_relationship) {
      return 'Nhóm';
    } else if (el?.relationships?.friendship_status === 'ARE_FRIENDS') {
      return 'Bạn bè';
    } else {
      return 'Người dùng';
    }
  };

  const renderSubTitle = el => {
    if (el?.page_relationship) {
      return `${shortenLargeNumber(el.like_count)} người thích trang này`;
    } else if (el?.group_relationship) {
      return `${shortenLargeNumber(el.member_count)} thành viên`;
    }
  };

  const renderText = item => {
    return renderTitle(item) + ' . ' + renderSubTitle(item);
  };

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
      setIsFocused(true);
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }
  }, []);

  const handleInputChange = (event, newValue, displayValue) => {
    if (newValue.trim() === '') {
      // Nếu nội dung chỉ toàn khoảng trắng, cập nhật lại giá trị về rỗng
      handleChange(event, '', displayValue);
      return;
    }
    // Cắt bỏ khoảng trắng thừa ở cuối nếu có
    const trimmedValue = newValue.replace(/\s+$/, ' ');
    handleChange(event, trimmedValue, displayValue);
    sendTypingEvent && sendTypingEvent();
  };

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      const currentValue = localContent || '';
      const newPosition = inputRef.current.selectionStart + 1;
      const updatedValue = [
        currentValue.slice(0, inputRef.current.selectionStart),
        '\n',
        currentValue.slice(inputRef.current.selectionEnd)
      ].join('');
      handleChange(
        { target: { value: updatedValue } },
        updatedValue,
        updatedValue
      );
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = newPosition;
          inputRef.current.selectionEnd = newPosition;
        }
      }, 0);
    } else if (event.key === 'Enter') {
      // Handle other Enter key behavior here if needed
      if (type === 'post') {
        event.preventDefault();
        const currentValue = localContent || '';
        const newPosition = inputRef.current.selectionStart + 1;
        const updatedValue = [
          currentValue.slice(0, inputRef.current.selectionStart),
          '\n',
          currentValue.slice(inputRef.current.selectionEnd)
        ].join('');
        handleChange(
          { target: { value: updatedValue } },
          updatedValue,
          updatedValue
        );
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.selectionStart = newPosition;
            inputRef.current.selectionEnd = newPosition;
          }
        }, 0);
      } else {
        handleKeyDown(event);
      }
    }
  };

  return (
    <MentionsInput
      disabled={disabled}
      inputRef={inputRef}
      id={idInput}
      name={name}
      className="mentions"
      classNames={
        theme.palette.mode === 'light' ? styleMention : styleDarkMention
      }
      style={{
        suggestions: {
          list: {
            backgroundColor: theme.palette.background.primary,
            border: '1px solid rgba(0,0,0,0.15)',
            borderRadius: '6px',
            fontSize: 14
          },
          item: {
            padding: '5px 15px',
            '&focused': {
              backgroundColor: 'rgba(113, 101, 224, 0.19)'
            }
          },
          zIndex: '1001',
          height: 'auto',
          borderRadius: '6px',
          backgroundColor: theme.palette.background.default,
          minWidth: '300px',
          maxHeight: type === 'chat' ? '240px' : 'unset',
          overflow: 'auto'
        },
        ...style,
        input: {
          ...styleInput
        },
        highlighter: {
          ...styleHighLighter
        }
      }}
      placeholder={truncatedPlaceholder}
      value={localContent}
      allowSuggestionsAboveCursor
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      onPaste={e => {
        handlePaste(e);
      }}
      onFocus={e => {
        if (!isFocused) {
          handleFocus && handleFocus(e);
          setIsFocused(true);
        }
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      allowSpaceInQuery={true}
      suggestionsPortalHost={container}
    >
      <Mention
        className={styleMention.mentions__mention}
        trigger="@"
        data={debounceSearchGlobal}
        appendSpaceOnAdd={true}
        onAdd={handleActionMention}
        markup={
          type === 'chat' ? '@[__id__](__display__)' : '@[__display__](__id__)'
        }
        renderSuggestion={(suggestion: SuggestionDataItem) => {
          return (
            <Grid container className={classes.wrapItem} key={suggestion.id}>
              <Grid item xs={3}>
                <AvatarSocial
                  type="feed"
                  style={{ width: 48, height: 48, marginRight: 8 }}
                  avatarObj={suggestion.avatar_media}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className={classes.wrapText}
                sx={{ flexDirection: 'column' }}
              >
                <Typography
                  className={classes.lineClamp}
                  sx={{
                    fontSize: '15px',
                    fontWeight: '500'
                  }}
                >
                  {limitText(parse(suggestion.display), 20)}
                </Typography>
                {suggestion?.relationships && (
                  <Typography className={classes.subTitle}>
                    {parse(
                      suggestion?.relationships?.relationships
                        ?.friendship_status
                        ? renderTitle(suggestion.relationships)
                        : renderText(suggestion.relationships)
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        style={{
          backgroundColor: 'rgba(113, 101, 224, 0.19)'
        }}
        displayTransform={(id, name) => {
          return `${name}`;
        }}
      />
    </MentionsInput>
  );
};

export default React.memo(Mentions);
