import * as types from 'src/constants/store/socialChat';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import soundMessagePath from '../../socket/Notification.mp3';
import produce from 'immer';
import { urlRocketChat } from 'src/util/config';

import { urlWebEmso } from 'src/util/config';
import { renderAvatarChat } from 'src/common/chat';
interface Conversation {
  action: Array<any>;
  acct: String;
  avatarETag: String;
  avatar_media: Object;
  banner: Object;
  bot: Boolean;
  created_at: String;
  discoverable: null;
  // display_name: String;
  emojis: any[];
  fields: any[];
  followers_count: Number;
  following_count: Number;
  friends_count: Number;
  group: Boolean;
  _id: String;
  last_status_at: String;
  locked: Boolean;
  note: String;
  relationships: Object;
  statuses_count: Number;
  username: String;
  roomId: String;
  type: String;
  name: String;
  // displayName: String;
  fname: String;
  rid: String;
  blocked?: Boolean;
  blocker?: any;
  disableNotifications?: Boolean;
  customFields: {
    icon: String;
  };
  topic: String;
  members?: any[];
  t: String;
  description: String;
  room: any;
  RoomsList: any[];
  lastMessage: any;
  unread: Number;
  ro: Boolean;
  status?: String;
  f: Boolean;
  notes: any[];
}
interface PopupChat {
  id: Number;
  conversation: Conversation;
  listMessage: Array<any>;
  hasMore: Boolean;
  lastMoment: string | null;
  idSubRocket: string | null;
  action: Array<any>;
  focus: Boolean;
  isShowTimeSend: Boolean;
}

interface BadgeChat {
  conversation: Conversation;
  countUnread: number;
}

interface SettingChat {
  allow_call_sound: Boolean;
  allow_chat_sound: Boolean;
  auto_open_chatbox: Boolean;
}

interface meInfo {
  id: string | null;
  status: string | null;
}

interface userChatStatus {
  username?: string;
  status?: string;
}

const initialState = {
  // list box chat (maximum 3)
  messages: [],
  listPopupChat: [] as PopupChat[],
  // list badge chat (badge mini right)
  queuePopupChat: [] as BadgeChat[],
  listRooms: [],
  // list conversation (global popup chat)
  listConversations: [] as any[],
  // open box create
  isOpenBoxCreate: false as Boolean,
  // initial conversation box create
  initialConversationBoxChatCreate: {} as any,
  // scroll to load list conversation
  lastUpdatedConversation: Date.now() as number,
  hasMore: true as Boolean,
  // status active / id user
  me: {} as meInfo,
  userChat: {} as userChatStatus,
  // setting chat - auto open / disable sound message / ...
  settingChat: {} as SettingChat,
  listConversationsChatPage: [] as any[],
  messageAutomatic: [],

  voteForm: {} as any,
  listInsertedRoom: [] as any[]
};

export const socialChatReducer = (state = initialState, action) => {
  const handleAction = (
    newState: any,
    conversation: any,
    message: any,
    isFocus: any
    // typeSearchPage?: string
  ) => {
    if (conversation) {
      let indexBadge = newState.queuePopupChat.findIndex(
        el => el.conversation.rid === conversation.rid
      );
      if (indexBadge !== -1) {
        return;
      }
      if (newState.listPopupChat.length < 2 && window.innerWidth > 1000) {
        if (newState.listPopupChat.length > 0) {
          newState.listPopupChat.map((el: any) => {
            el.focus = false;
          });
        }
        newState.listPopupChat.push({
          id: newState.listPopupChat.length,
          conversation,
          listMessage: message ? [message] : [],
          hasMore: true,
          lastMoment: Date.now().toString(),
          idSubRocket: '',
          action: [],
          focus: isFocus
        });
      } else {
        const theRemoveConversation = newState.listPopupChat.shift();

        if (theRemoveConversation) {
          newState.queuePopupChat.push({
            conversation: theRemoveConversation.conversation,
            countUnread: 0
          });
        }
        if (newState.listPopupChat.length > 0) {
          newState.listPopupChat.map((el: any) => {
            el.focus = false;
          });
        }
        newState.listPopupChat.push({
          id: newState.listPopupChat.length,
          conversation,
          listMessage: message ? [message] : [],
          hasMore: true,
          lastMoment: Date.now().toString(),
          idSubRocket: '',
          action: [],
          focus: isFocus
        });
      }
    }
  };

  switch (action.type) {
    case types.DELETE_CONVERSATION: {
      const { id, type } = action.payload;
      let newState = cloneDeep(state);
      const newListConversation = _.cloneDeep(newState.listConversations);
      const newListConversationChatPage = _.cloneDeep(
        newState.listConversationsChatPage
      );

      if (type === 'cp') {
        const newConversationChatPage = newListConversationChatPage.filter(
          (el: any) => el?.rid !== id
        );
        newState.listConversationsChatPage = newConversationChatPage;
      } else {
        const newConversation = newListConversation.filter(
          (el: any) => el?.rid !== id
        );
        newState.listConversations = newConversation;
      }

      return newState;
    }
    case types.BLOCKER_USER: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversation = newState.listConversations.map((el: any) => {
        if (el._id === id) {
          el.blocker = true;
        }
        return el;
      });
      newState.listConversations = new_conversation;
      return newState;
    }
    case types.BLOCKED_USER: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversation = newState.listConversations.map((el: any) => {
        if (el._id === id) {
          el.blocked = true;
        }
        return el;
      });

      newState.listConversations = new_conversation;
      return newState;
    }
    case types.UN_BLOCKER_USER: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversation = newState.listConversations.map((el: any) => {
        if (el._id === id) {
          el.blocker = false;
        }
        return el;
      });

      newState.listConversations = new_conversation;
      return newState;
    }
    case types.BLOCKER_PAGE: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversationChatPage = newState.listConversationsChatPage.map(
        (el: any) => {
          if (el._id === id) {
            el.userBlock = true;
          }
          return el;
        }
      );

      newState.listConversations = new_conversationChatPage;
      return newState;
    }
    case types.UN_BLOCKER_PAGE: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversationChatPage = newState.listConversationsChatPage.map(
        (el: any) => {
          if (el._id === id) {
            el.userBlock = false;
          }
          return el;
        }
      );

      newState.listConversations = new_conversationChatPage;
      return newState;
    }

    case types.PAGE_BLOCK_USER_CHAT: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversationChatPage = newState.listConversationsChatPage.map(
        (el: any) => {
          if (el._id === id) {
            el.pageBlock = true;
          }
          return el;
        }
      );
      newState.listConversations = new_conversationChatPage;
      return newState;
    }
    case types.PAGE_UNBLOCK_USER_CHAT: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const new_conversationChatPage = newState.listConversationsChatPage.map(
        (el: any) => {
          if (el._id === id) {
            el.pageBlock = false;
          }
          return el;
        }
      );

      newState.listConversations = new_conversationChatPage;
      return newState;
    }
    case types.GET_MESSAGE_AUTOMATIC: {
      const { message } = action.payload;
      let newState = cloneDeep(state);

      newState.messageAutomatic = message;
      return { ...newState };
    }
    case types.SELECT_USER_CHAT: {
      const { conversation, type } = action.payload;
      return produce(state, draftState => {
        // remove if conversation exist in queue
        const newQueue = draftState.queuePopupChat.filter(
          el => el.conversation?.rid !== conversation?.rid
        );
        draftState.queuePopupChat = newQueue;
        // --------------------------------------

        // update if conversation exist in list popupChat
        const index = draftState.listPopupChat.findIndex(
          el => el?.conversation?.rid === conversation?.rid
        );
        const location = window.location.href;

        if (index !== -1 && location !== `${urlWebEmso}/messages/news`) {
          // chỉ cập nhật những gì cần thiết ( trừ displayname)
          if (
            conversation &&
            conversation.t !== 'cp' &&
            !conversation.is_user_chat
          ) {
            draftState.listPopupChat[index].conversation.blocked =
              conversation?.blocked ?? false;
            draftState.listPopupChat[index].conversation.blocker =
              conversation?.blocker ?? false;
            draftState.listPopupChat[index].conversation.disableNotifications =
              conversation?.disableNotifications ?? false;
            draftState.listPopupChat[index].conversation.f = conversation?.f;
            draftState.listPopupChat[index].conversation.lastMessage =
              conversation?.lastMessage;
            draftState.listPopupChat[index].conversation.unread =
              conversation?.unread ?? 0;
            if (conversation && conversation.topic) {
              draftState.listPopupChat[index].conversation.topic =
                conversation?.topic;
            }
            if (conversation && conversation.customFields) {
              draftState.listPopupChat[index].conversation.customFields =
                conversation?.customFields;
            }
          }
        } else {
          handleAction(
            draftState,
            conversation,
            conversation?.createBoxChat ? conversation.lastMessage : undefined,
            true
          );
        }
      });
    }

    case types.SUB_ROOM_SUCCESS: {
      const { roomId, subId } = action.payload;
      return produce(state, draftState => {
        const index = draftState.listPopupChat.findIndex(
          el => el?.conversation?.rid === roomId
        );
        if (index !== -1) {
          draftState.listPopupChat[index].idSubRocket = subId;
        }
      });
    }

    case types.DELETE_NOTE_USER_CHAT: {
      const { conversationId, noteId } = action.payload;
      let newState = cloneDeep(state);
      
      const popupChatSelected = newState.listPopupChat.find(
        item => item?.conversation?.rid === conversationId
      );
      if (popupChatSelected) {
        popupChatSelected.conversation.notes =
          popupChatSelected.conversation.notes.filter(
            item => item?.id !== noteId
          );
      }
      const conversationsChatSelected = newState.listConversationsChatPage.find(
        item => item?.rid === conversationId
      );
      if (conversationsChatSelected) {
        conversationsChatSelected.notes =
          conversationsChatSelected.notes.filter(item => item.id !== noteId);
      }
      return newState;
    }

    case types.HIDDEN_USER_CHAT: {
      let rocketId = localStorage.getItem('userId');
      const { conversation } = action.payload;
      let newState = cloneDeep(state);
      // remove conversation exist in list popupChat
      const newListPopupChat = newState.listPopupChat.filter(
        el => el?.conversation?.rid !== conversation.rid
      );

      newState.listPopupChat = newListPopupChat;
      // -------------------------------------------

      if (
        newState.queuePopupChat.find(
          el => el.conversation.rid === conversation.rid
        )
      ) {
        return newState;
      } else {
        conversation.avatar_media = renderAvatarChat(conversation, rocketId);
        newState.queuePopupChat.push({
          conversation,
          countUnread: 0
        });
      }
      return newState;
    }

    case types.REMOVE_USER_CHAT: {
      const { conversation } = action.payload;

      return produce(state, draft => {
        draft.listPopupChat = draft.listPopupChat.filter(
          el => el.conversation?.rid !== conversation.rid
        );
        draft.queuePopupChat = draft.queuePopupChat.filter(
          el => el.conversation?.rid !== conversation.rid
        );
      });
    }

    case types.SEND_MESSAGE_SUCCESS: {
      // not in use
      const { message } = action.payload;
      let newState = cloneDeep(state);
      return newState;
    }

    // event for boxChat
    case types.RECEIVE_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      return produce(state, draftState => {
        const index = state.listPopupChat.findIndex(
          el => el.conversation?.rid === message.rid
        );
        const indexList = state.listConversations.findIndex(
          el => el?.rid === message.rid
        );
        if (index !== -1) {
          let indexMessage = state.listPopupChat[index].listMessage.findIndex(
            el => el._id === message._id ||  (el.uuid && el.uuid === message.uuid)
          );
          if (indexMessage !== -1) {
            
            draftState.listPopupChat[index].listMessage[indexMessage] = message;
            draftState.listPopupChat[index].conversation.lastMessage.unread =
              message?.unread ? true : false;
          } else {
            let rocketId = localStorage.getItem('userId');
            const listType = [
              'au',
              'room_changed_topic',
              'room_e2e_enabled',
              'message_pinned',
              'message_unpinned',
              'r',
              'ru',
              'subscription-role-added',
              'room_changed_avatar',
              'room_changed_privacy',
              'room-set-read-only',
              'room-removed-read-only',
              'subscription-role-removed'
            ];
            const listStatus = ['sending', 'error'];
            if (
              message?.u?._id === rocketId &&
              message &&
              message?.type !== 'automatic_reply'
            ) {
              if (
                listStatus.includes(message?.status) ||
                listType.includes(message?.t) ||
                message.msg.includes(':like_big: ') ||
                message.msg.includes(':like_small: ') ||
                message.msg.includes(':like_largest: ') ||
                state.listPopupChat[index].listMessage.length === 0 ||
                (typeof message.msg === 'string' &&
                  message.msg.includes(`[ ](${urlRocketChat}/messages`))
              ) {
                draftState.listPopupChat[index].listMessage.unshift(message);
              }
              draftState.listPopupChat[index].conversation.lastMessage =
                message;

              const indexToUpdate = draftState.listPopupChat[
                index
              ].listMessage.findIndex(el => {
                return (
                  message?.status === undefined &&
                  message?.uuid === el._id &&
                  (el?.status === 'sending' ||
                    el?.status === 'error' ||
                    listType.includes(message?.t))
                );
              });

              if (indexToUpdate !== -1) {
                const el =
                  draftState.listPopupChat[index].listMessage[indexToUpdate];

                draftState.listPopupChat[index].listMessage[indexToUpdate] = {
                  ...el,
                  status: el?.status === 'error' ? 'error' : 'success',
                  _id: message?._id,
                  t: message?.t,
                  file: message?.file,
                  files: message?.files,
                  urls: message?.urls,
                  ts: message?.ts,
                  isPage: message.isPage,
                  mentions: message?.mentions,
                  unread: message?.unread,
                  attachments: message?.attachments,
                  _updatedAt: message?._updatedAt,
                  type: message?.type
                };
              }
              // draftState.listPopupChat[index].listMessage =
              //   newArrMessageSuccess;
              if (!listType.includes(message?.t)) {
                if (!message?.status) {
                  const matchingElements = draftState.listPopupChat[
                    index
                  ].listMessage.filter(item => item._id === message._id);
                  if (matchingElements.length === 0) {
                    const listMessage =
                      draftState.listPopupChat[index].listMessage;
                    listMessage.unshift(message);
                    draftState.listPopupChat[index].listMessage = listMessage;
                  }
                }
              }
            } else {
              // draftState.listPopupChat[index].listMessage.unshift(message);
              const listMessage = draftState.listPopupChat[index].listMessage;
              listMessage.unshift(message);
              draftState.listPopupChat[index].listMessage = _.uniqBy(
                listMessage,
                '_id'
              );

              draftState.listPopupChat[index].conversation.lastMessage =
                message;
            }
          }
        }
        if (indexList !== -1) {
          draftState.listConversations[indexList].lastMessage = message;
        }
      });
      // push new message to all boxChat
      // return state;
    }

    case types.RECEIVE_ACTION_BOX_SUCCESS: {
      const { activity } = action.payload;
      return produce(state, draftState => {
        draftState.listPopupChat
          .filter(el => el.conversation.rid === activity.roomId)
          .map(el => {
            const index = el.action.findIndex(el => el.user === activity.user);
            if (index === -1) {
              el.action.push(activity);
            } else {
              el.action[index] = activity;
            }
            return el;
          });
      });
    }

    case types.LOAD_MESSAGE_SUCCESS: {
      const { messages, roomId } = action.payload;

      return produce(state, draftState => {
        const index = draftState.listPopupChat.findIndex(
          el => el?.conversation?.rid === roomId
        );
        if (index !== -1) {
          draftState.listPopupChat[index].listMessage =
            draftState.listPopupChat[index].listMessage.concat(messages);

          draftState.listPopupChat[index].lastMoment =
            draftState.listPopupChat[index].listMessage[
              draftState.listPopupChat[index].listMessage.length - 1
            ]?.ts?.$date || Date.now(); //   );
          if (messages?.length < 20) {
            draftState.listPopupChat[index].hasMore = false;
          }
        }
      });
    }

    case types.OPEN_BOX_CREATE: {
      let newState = cloneDeep(state);
      newState.isOpenBoxCreate = true;
      return newState;
    }

    case types.CLOSE_BOX_CREATE: {
      let newState = cloneDeep(state);
      newState.isOpenBoxCreate = false;
      newState.initialConversationBoxChatCreate = {};
      return newState;
    }

    case types.CREATE_GROUP_CHAT_WITH: {
      const { conversation } = action.payload;
      let newState = cloneDeep(state);
      newState.isOpenBoxCreate = true;
      newState.initialConversationBoxChatCreate = conversation;
      return newState;
    }

    // event for global popupChat
    case types.GET_LIST_CONVERSATION_SUCCESS: {
      const { conversations } = action.payload;
      let newState = cloneDeep(state);

      let tempListConversations: any = [];
      let newListConversations: any[] = newState.listConversations;
      conversations.map((conversation: any) => {
        if (
          newListConversations.findIndex(
            (item: any, index) => item.rid === conversation.rid
          ) === -1
        ) {
          tempListConversations.push(conversation);
        } else {
          newListConversations[
            newListConversations.findIndex(
              (item: any, index) => item.rid === conversation.rid
            )
          ] = conversation;
        }
      });

      // assign list conversation
      newState.listConversations = [
        ...newListConversations,
        ...tempListConversations
      ];
      // update conversation in list box chat is opening

      newState.listPopupChat.map((el: any) => {
        const newConversation = conversations.find(
          newEl => newEl.rid === el.conversation?.rid
        );
        if (newConversation) {
          el.conversation = newConversation;
        }
        return el;
      });
      // set last updated conversation, use to scroll to load
      newState.lastUpdatedConversation =
        conversations[conversations.length - 1]?.lastMessage?._updatedAt.$date;
      // set hasMore, use scroll to load
      if (conversations.length < 10) {
        newState.hasMore = false;
      }

      return newState;
    }
    case types.READ_UNREAD_MESSAGE: {
      let newState = cloneDeep(state);
      const { idRoom, type } = action.payload;
      const newListConversation = _.cloneDeep(
        newState.listConversations
      ) as any;
      const index: number = newListConversation.findIndex(
        (el: any) => el.rid === idRoom
      );
      if (type === 'read') {
        newListConversation[index].ls = Date.now();
        newListConversation[index].unread = 0;
        newListConversation[index].lastMessage.unread = false;
      } else if (type === 'make_unread') {
        newListConversation[index].ls =
          newListConversation[index].lastMessage.ts;
        newListConversation[index].lastMessage.unread = true;
      }
      newState.listConversations = newListConversation;

      return newState;
    }
    case types.READ_UNREAD_MESSAGE_PAGE: {
      let newState = cloneDeep(state);
      const { idRoom, type } = action.payload;

      const newListConversation = _.cloneDeep(
        newState.listConversationsChatPage
      ) as any;
      const index: number = newListConversation.findIndex(
        (el: any) => el.rid === idRoom
      );

      newListConversation[index].ls = Date.now();

      if (type === 'read') {
        newListConversation[index].countUnreadPage = 0;
      } else {
        newListConversation[index].countUnreadPage = 1;
      }

      newState.listConversationsChatPage = newListConversation;

      return newState;
    }
    case types.EVENT_NEW_MESSAGE: {
      const { conversation } = action.payload;

      return produce(state, draftState => {
        // count badge
        let indexBadge = draftState.queuePopupChat.findIndex(
          el => el.conversation.rid === conversation.rid
        );

        let rocketId = localStorage.getItem('userId');

        if (indexBadge !== -1) {
          const newListBadgeChat: any = draftState.queuePopupChat;
          if (
            newListBadgeChat[indexBadge].conversation.lastMessage._id !==
            conversation.lastMessage._id
          ) {
            newListBadgeChat[indexBadge].countUnread += 1;
          }

          newListBadgeChat[indexBadge].conversation.lastMessage =
            conversation.lastMessage;

          draftState.queuePopupChat = newListBadgeChat;
        }
        // handle

        const index: number = draftState.listConversations.findIndex(
          (el: any) => el.rid === conversation.rid
        );

        if (conversation.lastMessage?.t !== 'ul') {
          if (
            index === -1 &&
            (!conversation?.lastMessage?.pending ||
              conversation?.lastMessage?.u?._id === rocketId)
          ) {
            // new conversation, unshift to list conversation

            conversation.lastMessage?.u?._id !== conversation.u?._id
              ? (conversation.unread = 1)
              : (conversation.unread = 0);
            let newConversation;
            if (
              !conversation?.avatar_media &&
              conversation?.uids &&
              conversation?.uids.length > 0
            ) {
              newConversation = {
                ...conversation,

                avatar_media: renderAvatarChat(conversation, rocketId)
              };
            } else {
              newConversation = conversation;
            }

            draftState.listConversations.unshift(newConversation);
          } else {
            // exist conversation, update in list conversation
            if (conversation?.lastMessage?.unread === true) {
              draftState.listConversations[index].unread += 1;
            }
            // newListConversation[index].avatar_media = conversation.avatar_media;

            draftState.listConversations[index].lastMessage =
              conversation.lastMessage;
            draftState.listConversations[index]._updatedAt =
              conversation._updatedAt;
            if (!draftState.listConversations[index]?.avatar_media) {
              draftState.listConversations[index] = {
                ...draftState.listConversations[index],
                avatar_media:
                  conversation.t === 'd'
                    ? {
                        show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                          conversation?.uids?.filter(el => el !== rocketId)[0]
                        }`
                      }
                    : null
              };
            }
          }
        }

        // sort list conversation
        const listConversationsSort = draftState.listConversations.sort(
          (a, b) => sortConversation(a, b)
        );

        draftState.listConversations = listConversationsSort;
        conversation.disableNotifications =
          listConversationsSort[0].disableNotifications;
        // play sound if conversation enable noti

        // open box chat if enable auto open chatbox

        if (
          draftState.settingChat.auto_open_chatbox &&
          conversation.lastMessage?.type !== 'forward' &&
          !conversation.lastMessage?.pending
        ) {
          const index = draftState.listPopupChat.findIndex(
            el => el.conversation?.rid === conversation?.rid
          );
          if (index !== -1) {
            // Problem: Step:
            // Delete tin mess
            // Create new mess
            // Reload
            // open popup chat by icon header
            // send mess
            // => error duplicate
            // this conversation is opening

            // if (draftState.listPopupChat[index].listMessage.length < 10) {
            //   if (
            //     conversation.lastMessage?.u?._id === rocketId &&
            //     conversation.lastMessage &&
            //     conversation.lastMessage?.t &&
            //     conversation.lastMessage?.type !== 'automatic_reply'
            //   ) {
            //   } else {
            //     const listMessage = draftState.listPopupChat[index].listMessage;
            //     listMessage.unshift(conversation.lastMessage);
            //     draftState.listPopupChat[index].listMessage = _.uniqBy(
            //       listMessage,
            //       '_id'
            //     );
            //   }
            // }

            return draftState;
          } else {
            if (
              !conversation.disableNotifications &&
              conversation.lastMessage?.t !== 'ul' &&
              indexBadge === -1
            ) {
              let newConversation = {
                ...conversation,
                // fname:
                //   conversation.t === 'd'
                //     ? conversation?.lastMessage?.u?.name
                //     : conversation?.fname,

                avatar_media:
                  conversation.t === 'd'
                    ? {
                        show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                          conversation?.uids?.filter(el => el !== rocketId)[0]
                        }`
                      }
                    : conversation.t === 'p' || conversation.t === 'c'
                    ? {
                        avatar_top: {
                          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                            conversation?.uids?.filter(el => el !== rocketId)[0]
                          }`
                        },
                        avatar_bottom: {
                          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                            conversation?.uids?.filter(el => el !== rocketId)[1]
                          }`
                        }
                      }
                    : {
                        show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${conversation.prid}`
                      }
              };

              handleAction(
                draftState,
                newConversation,
                conversation.lastMessage,
                false
              );
            }
          }
        }
      });
    }

    case types.EVENT_MESSAGE_FROM_CHAT_PAGE: {
      const { conversation } = action.payload;
      return produce(state, draftState => {
        // count badge
        // console.log({ conversation });
        // handle
        const newListConversation = _.cloneDeep(
          draftState.listConversationsChatPage
        ) as any;

        const index: number = newListConversation.findIndex(
          (el: any) => el.rid === conversation.rid
        );

        if (index === -1) {
          // new conversation, unshift to list conversation
    
          conversation.unread = 0
          conversation.is_user_chat = true
          newListConversation.unshift(conversation);
        } else {
          // exist conversation, update in list conversation
          if (conversation?.unread === true) {
            newListConversation[index].unread = 0;
          }
          // newListConversation[index].avatar_media = conversation.avatar_media;
          newListConversation[index].lastMessage = conversation;
          newListConversation[index]._updatedAt = conversation._updatedAt;
        }
        // sort list conversation
        const listConversationsSort = newListConversation.sort((a, b) =>
          sortConversation(a, b)
        );

        draftState.listConversationsChatPage = listConversationsSort;
        // play sound if conversation enable noti

        // open box chat if enable auto open chatbox
      });
    }
    case types.EVENT_ROOM_CHANGE: {
      let newState = cloneDeep(state);
      const { room } = action.payload;
      const rocketId = localStorage.getItem('userId');
      newState.listInsertedRoom = [...newState.listInsertedRoom, room];
      const newListConversation = newState.listConversations as any;

      const conversation = newListConversation.find(el => el.rid === room._id);
      if (conversation) {
        conversation.RoomsList = [room];
        conversation.avatar_media =
          conversation.t === 'd'
            ? {
                show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                  room?.uids?.filter(el => el !== rocketId)[0]
                }`
              }
            : room.t === 'p' || room.t === 'c'
            ? {
                avatar_top: {
                  show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                    room?.uids?.filter(el => el !== rocketId)[0]
                  }`
                },
                avatar_bottom: {
                  show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                    room?.uids?.filter(el => el !== rocketId)[1]
                  }`
                }
              }
            : {
                show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${conversation.prid}`
              };
      }

      const newListPopupChat = newState.listPopupChat as any;

      const popupChat = newListPopupChat.find(
        el => el.conversation.rid === room._id
      );
      if (popupChat) {
        popupChat.conversation.RoomsList = [room];
        popupChat.conversation.avatar_media = conversation.avatar_media;
      }

      return newState;
    }
    case types.EVENT_NEW_SUBSCRIPTION: {
      const newState = produce(state, draft => {
        const { conversation, message } = action.payload;
        const rocketId = localStorage.getItem('userId');

        const newListConversation = draft.listConversations as any;
        const listConversationsChatPage =
          draft.listConversationsChatPage as any;
        const newListPopupChat = draft.listPopupChat as any;

        const index: number = newListConversation.findIndex(
          (el: any) => el.rid === conversation.rid
        );
        const indexUserChatPage: number = listConversationsChatPage.findIndex(
          (el: any) => el.rid === conversation.rid
        );
        const checkPrid =
          listConversationsChatPage[0]?.prid === conversation?.prid;

        if (!conversation.RoomsList) {
          const roomList = draft.listInsertedRoom.find(
            el => el._id === conversation.rid
          );
          if (roomList) {
            conversation.RoomsList = roomList;
          }
        }
        // xử lý list conversation chatpage

        if (conversation.t === 'cp' && !message?.isPage) {
          if (indexUserChatPage === -1) {
            const newConversation = {
              ...conversation,
              is_user_chat: true,
              avatar_media: {
                show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${conversation.u._id}`
              }
            };
            listConversationsChatPage.unshift(newConversation);
          } else {
            if (conversation?.lastMessage) {
              listConversationsChatPage[indexUserChatPage].lastMessage =
                conversation.lastMessage;
            }
            if (typeof conversation.countUnreadPage !== 'undefined') {
              listConversationsChatPage[indexUserChatPage].countUnreadPage =
                conversation.countUnreadPage;
            }
            listConversationsChatPage[indexUserChatPage]._updatedAt =
              conversation._updatedAt;
          }
        }

        // xử lý list popup chat
        const indexBoxChat: number = newListPopupChat.findIndex(
          (el: any) => el.conversation.rid === conversation.rid
        );

        if (indexBoxChat === -1) {
          conversation.avatar_media = renderAvatarChat(conversation, rocketId)
          handleAction(draft, conversation, undefined, true);
        } else {
          if (conversation?.avatar_media) {
            newListPopupChat[indexBoxChat].conversation.avatar_media =
              conversation.avatar_media;
          }
          if (!newListPopupChat[indexBoxChat].RoomsList) {
            newListPopupChat[indexBoxChat].RoomsList = conversation.RoomsList;
          }
          if(!newListPopupChat[indexBoxChat].conversation){
            newListPopupChat[indexBoxChat].conversation.fname =
            conversation?.fname;
          }
        
          newListPopupChat[indexBoxChat].conversation.countUnreadPage =
            conversation?.countUnreadPage;
          newListPopupChat[indexBoxChat].conversation.pageBlock =
            conversation?.pageBlock;
          newListPopupChat[indexBoxChat].conversation.userBlock =
            conversation?.userBlock;

          newListPopupChat[indexBoxChat].conversation.blocked =
            conversation?.blocked ? conversation.blocked : false;
          newListPopupChat[indexBoxChat].conversation.blocker =
            conversation?.blocker ? conversation.blocker : false;
          newListPopupChat[indexBoxChat].conversation.disableNotifications =
            conversation.disableNotifications;
        }

        const listConversationsSortChatPage = listConversationsChatPage.sort(
          (a, b) => sortConversation(a, b)
        );

        // xử lý list conversation
        if (index === -1 && !message?.pending) {
          newListConversation.unshift(conversation);
        } else {
          if (
            !message?.pending ||
            (message?.pending && message.u._id === rocketId)
          ) {
            if (conversation?.unread) {
              newListConversation[index].unread = conversation.unread;
            }
            if (!newListConversation[index].RoomsList) {
              newListConversation[index].RoomsList = conversation.RoomsList;
            }
            newListConversation[index].t = conversation.t;
            newListConversation[index]._updatedAt = conversation._updatedAt;
            newListConversation[index].f = conversation.f;

            newListConversation[index].avatar_media = newListConversation[index]
              ?.avatar_media
              ? newListConversation[index]?.avatar_media
              : conversation.avatar_media;

            newListConversation[index].disableNotifications =
              conversation.disableNotifications;

            if (conversation.type === 'group') {
              newListConversation[index].avatarEtag = conversation.avatarEtag;
            }
          }
        }

        const listConversationsSort = newListConversation.sort((a, b) =>
          sortConversation(a, b)
        );

        draft.listPopupChat = newListPopupChat;
        draft.listConversations = listConversationsSort;
        draft.listConversationsChatPage = listConversationsSortChatPage;
      });

      return newState;
    }

    case types.EVENT_DELETE_MESSAGE: {
      const newState = produce(state, draft => {
        const { roomId, messageId } = action.payload;
        draft.listPopupChat.forEach(el => {
          if (el.conversation.rid === roomId) {
            el.listMessage = el.listMessage.filter(message => message._id !== messageId);
          }
        });
        draft.listConversationsChatPage.forEach(el => {
          if (el.conversation.rid === roomId) {
            el.listMessage = el.listMessage.filter(message => message._id !== messageId);
          }
        });
      });
      return newState
    }

    case types.IS_BLOCKER: {
      const { roomId, isBlocker } = action.payload;

      const newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => (el.conversation.rid = roomId)
      );
      if (index !== -1) {
        newState.listPopupChat[index].conversation.blocker = isBlocker;
      }
      return newState;
    }

    case types.IS_BLOCKED: {
      const { roomId, isBlocked } = action.payload;

      const newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => (el.conversation.rid = roomId)
      );
      if (index !== -1) {
        newState.listPopupChat[index].conversation.blocked = isBlocked;
      }
      return newState;
    }

    case types.GET_INFO_ME_CHAT_SUCCESS: {
      const { me } = action.payload;
      let newState = cloneDeep(state);
      newState.me = me;
      return newState;
    }

    case types.USER_STATUS_REAL_TIME: {
      const { username, status } = action.payload;
      let newState = cloneDeep(state);
      // newState.userChat = { username, status };
      // console.log(newState.userChat);
      const index: number = newState.listPopupChat.findIndex(
        (el: any) => el.conversation.username === username
      );
      if (index !== -1) {
        newState.listPopupChat[index].conversation.status = status;
      }
      return newState;
    }

    case types.SETTING_CHAT: {
      const { settings } = action.payload;
      let newState = cloneDeep(state);
      newState.settingChat = settings;
      if (settings.allow_chat_sound === true) {
        // playSound();
      }
      return newState;
    }

    case types.SOUND_NOTIFICATION: {
      const { isPlay } = action.payload;
      const location = window.location;
      const fullScreenChat = location.pathname.startsWith('/messages');
      let newState = cloneDeep(state);
      const notification = newState.settingChat.allow_chat_sound;
      if (notification && isPlay && !fullScreenChat) {
        // new Audio(soundMessagePath).play();
        playSound();
      }

      return newState;
    }

    case types.FOCUS_CONVERSATION: {
      const { id } = action.payload;
      let newState = cloneDeep(state);
      const newListPopupChat = newState.listPopupChat;

      newListPopupChat.map((el: any) => {
        if (id === '') {
          return (el.focus = false);
        } else {
          return el.id === id ? (el.focus = true) : (el.focus = false);
        }
      });

      newState.listPopupChat = newListPopupChat;
      return newState;
    }
    case types.RESET_LIST_POPUP_CHAT: {
      let newState = cloneDeep(state);
      newState.listPopupChat = [];
      return newState;
    }

    case types.DELETE_QUEUE_POPUP_CHAT: {
      const { idPopupChat } = action.payload;

      let newState = cloneDeep(state);

      // remove if conversation exist in queue
      const newQueue = newState.listPopupChat.filter(
        el => el.conversation.rid !== idPopupChat
      );
      newState.listPopupChat = newQueue;

      return newState;
    }
    case types.CHANGE_NAME_POPUP_CHAT: {
      const { roomId, roomName } = action.payload;

      let newState = cloneDeep(state);

      const index = newState.listPopupChat.findIndex(
        el => el.conversation.rid === roomId
      );
      if (index === -1) {
        return newState;
      } else {
        newState.listPopupChat[index].conversation.fname = roomName;
        newState.listPopupChat[index].conversation.name = roomName;
        // newState.listPopupChat[index].conversation.displayName = roomName;
        return newState;
      }
    }
    case types.CHANGE_EMOTICON: {
      const { roomId, emoticon } = action.payload;
      let newState = cloneDeep(state);

      const index = newState.listPopupChat.findIndex(
        el => el.conversation.rid === roomId
      );
      if (index === -1) {
        return newState;
      } else {
        newState.listPopupChat[index].conversation.customFields ??= {} as any;
        newState.listPopupChat[index].conversation.customFields.icon = emoticon;
        return newState;
      }
    }

    case types.DISABLE_NOTIFICATION: {
      const { roomId, disableNotifications } = action.payload;
      let newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => el.conversation.rid === roomId
      );
      if (index === -1) {
        return newState;
      } else {
        newState.listPopupChat[index].conversation.disableNotifications =
          disableNotifications;
        return newState;
      }
    }

    case types.CHANGE_ROLES_ADD_MEMBER_GROUP: {
      const { roomId, roomType } = action.payload;
      let newState = cloneDeep(state);

      const index = newState.listPopupChat.findIndex(
        el => el.conversation.rid === roomId
      );
      if (index === -1) {
        return newState;
      } else {
        newState.listPopupChat[index].conversation.t = roomType;
        return newState;
      }
    }

    case types.CHANGE_TOPIC: {
      const { rid, topic } = action.payload;
      let newState = cloneDeep(state);

      const index = newState.listPopupChat.findIndex(
        el => el.conversation?.rid === rid
      );
      if (index === -1) {
        return newState;
      } else {
        newState.listPopupChat[index].conversation.topic = topic;
        return newState;
      }
    }

    case types.PINNED_CONVERSATION: {
      return produce(state, draftState => {
        const { roomId, isPinned } = action.payload;
        const index = draftState.listPopupChat.findIndex(
          el => el.conversation?.rid === roomId
        );
        if (index !== -1) {
          draftState.listPopupChat[index].conversation.f = isPinned;
        }
        const conversation = draftState.listConversations.find(
          el => el?.rid === roomId
        );

        if (conversation) {
          conversation.f = isPinned;
        }
      });
    }

    case types.CHANGE_AVATAR_POPUP_CHAT: {
      const { idPopupChat, newAvatar } = action.payload;

      let newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => el.conversation?.rid === idPopupChat
      );

      if (index !== -1) {
        newState.listPopupChat[index].conversation.avatarETag = newAvatar;
        return newState;
      }
      return newState;
    }
    case types.CHANGE_ROLES_GROUP_CHAT: {
      const { roomId, roleChat } = action.payload;
      let newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => el.conversation?.rid === roomId
      );

      if (index !== -1) {
        newState.listPopupChat[index].conversation.ro = roleChat;
        if (newState.listPopupChat[index].conversation?.RoomsList) {
          newState.listPopupChat[index].conversation.RoomsList[0].ro = roleChat;
        }
        return newState;
      }
      return newState;
    }
    // update conversation members

    case types.UPDATE_ADD_MEMBERS_GROUP: {
      const { members, roomId } = action.payload;

      let newState = cloneDeep(state);
      const index = newState.listPopupChat.findIndex(
        el => el.conversation?.rid === roomId
      );
      const indexListConversations = newState.listConversations.findIndex(
        el => el._id === roomId
      );

      if (index !== -1) {
        newState.listPopupChat[index].conversation.members = members;
        if (indexListConversations !== -1) {
          newState.listConversations[indexListConversations].members = members;
        }
        return newState;
      }
      return newState;
    }

    // eslint-disable-next-line no-fallthrough
    case types.GET_LIST_CONVERSATION_CHAT_PAGE_SUCCESS: {
      const { listConversations, idPage, label } = action.payload;

      let newState = cloneDeep(state);

      if (label) {
        listConversations.sort((a, b) => sortConversation(a, b));
        newState.listConversationsChatPage = listConversations;
      } else {
        listConversations.sort((a, b) => sortConversation(a, b));
        let tempListConversations: any = [];
        let newListConversations: any[] =
          newState.listConversationsChatPage.length > 0 &&
          newState.listConversationsChatPage[0].prid === idPage
            ? newState.listConversationsChatPage
            : listConversations;

        const findIndex = (_item: any) => {
          return newListConversations.findIndex(
            (item: any, index) =>
              item.rid === _item.rid && _item.prid === idPage
          );
        };

        listConversations &&
          listConversations.map((conversation: any) => {
            if (findIndex(conversation) === -1) {
              tempListConversations.push(conversation);
            } else {
              newListConversations[findIndex(conversation)] = conversation;
            }
          });

        // assign list conversation
        newState.listConversationsChatPage = [
          ...newListConversations,
          ...tempListConversations
        ];

        newState.lastUpdatedConversation =
          listConversations?.[
            listConversations?.length - 1
          ]?.lastMessage?._updatedAt.$date;
        // set hasMore, use scroll to load
        if (listConversations && listConversations?.length < 10) {
          newState.hasMore = false;
        }
      }
      return newState;
    }

    case types.ADD_LABEL_CHAT_PAGE: {
      const { roomId, label } = action.payload;
      let newState = cloneDeep(state);
      const { listConversationsChatPage } = newState;
      const index = listConversationsChatPage.findIndex(
        el => el.rid === roomId
      );
      if (index !== -1) {
        if (!listConversationsChatPage[index].label) {
          listConversationsChatPage[index].label = [];
        }
        listConversationsChatPage[index].label.push(label);
      }
      return newState;
    }

    case types.REMOVE_LABEL_CHAT_PAGE: {
      const { roomId, label } = action.payload;
      let newState = cloneDeep(state);
      const { listConversationsChatPage } = newState;
      const index = listConversationsChatPage.findIndex(
        el => el.rid === roomId
      );
      if (index !== -1) {
        if (
          listConversationsChatPage[index].label &&
          listConversationsChatPage[index].label.length > 0
        ) {
          const indexLabel = listConversationsChatPage[index].label.findIndex(
            el => el.title === label.title
          );

          if (indexLabel !== -1) {
            listConversationsChatPage[index].label.splice(indexLabel, 1);
          }
        }
      }
      return newState;
    }

    case types.REMOVE_LABEL_TOTAL_PAGE: {
      const { label } = action.payload;
      const newState = cloneDeep(state);
      let { listConversationsChatPage } = newState;
      const newListChatPage = listConversationsChatPage.map(conversationCP => {
        if (conversationCP.label && conversationCP.label.length > 0) {
          const index = conversationCP.label.findIndex(
            el => el.title === label.title
          );
          if (index !== -1) {
            conversationCP.label.splice(index, 1);
          }
        }
        return conversationCP;
      });
      listConversationsChatPage = newListChatPage;
      return newState;
    }

    case types.GET_CREATE_VOTE_MESSAGE: {
      const { voteForm } = action.payload;
      let newState = cloneDeep(state);
      newState.voteForm = voteForm;
      return newState;
    }

    case types.LEAVE_CONVERSATION: {
      const { rid } = action.payload;
      let newState = cloneDeep(state);
      const new_conversation = newState.listConversations.filter(
        (el: any) => el?.rid !== rid
      );
      newState.listConversations = new_conversation;
      return newState;
    }

    case types.SYNCHRONIZE_LIST_POPUP_CHAT: {
      const { roomId, newData } = action.payload;
      let newState = cloneDeep(state);
      const index_element_change = newState.listPopupChat.findIndex(
        (el: any) => el?.conversation?._id === roomId
      );
      if (index_element_change !== -1) {
        newState.listPopupChat[index_element_change].conversation = newData;
      }
      return newState;
    }

    case types.SYNCHRONIZE_LIST_CONVERSATIONS_CHAT_PAGE: {
      const { roomId, newData } = action.payload;
      let newState = cloneDeep(state);
      const index_element_change = newState.listConversationsChatPage.findIndex(
        (el: any) => el?._id === roomId
      );
      if (index_element_change !== -1) {
        newState.listConversationsChatPage[index_element_change] = newData;
      }
      return newState;
    }

    default:
      return state;
  }
};

// if boxChat is opening, read message right away
// const readMessage = async roomId => {
//   try {
//     const response = await seenMessage(roomId);
//     if (response.status === 200) {
//       // handle
//     }
//   } catch (error) {
//     console.log('error read message');
//   }
// };

const sortConversation = (a, b) => {
  let timeStamp_a = a.lastMessage
    ? a.lastMessage._updatedAt?.$date
    : a.ls
    ? a.ls.$date
    : a._updatedAt?.$date;
  let timeStamp_b = b.lastMessage
    ? b.lastMessage._updatedAt?.$date
    : b.ls
    ? b.ls?.$date
    : b._updatedAt?.$date;
  return timeStamp_b - timeStamp_a;
};

// sound new message
function playSound() {
  try {
    var audio = new Audio(soundMessagePath);
    audio.play();
  } catch (error) {
    console.log('error play sound', error);
  }
}
