import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Snackbar, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: '12px 6px 0px 12px'
  },
  content: {
    paddingTop: 6,
    paddingLeft: 6
  }
}));
const NotificationUi = props => {
  const classes = useStyles();
  const { openNoti, setOpenNoti, component, data } = props;
  return data?.type === 'bad_status' ? (
    <div className={classes.content}>{component()}</div>
  ) : (
    <Snackbar
      open={openNoti}
      autoHideDuration={8000}
      onClose={() => setOpenNoti(false)}
    >
      <Box
        sx={{
          maxWidth: '350px',
          minHeight: '140px',
          zIndex: 1200,
          border: '1px solid #0000001a',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(68, 73, 80, 0.35)',
          backgroundColor: 'background.primary'
        }}
      >
        <div className={classes.title}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {/* <Avatar
              style={{
                cursor: 'pointer',
                width: '30px',
                height: 'auto'
              }}
              alt="EasyEdu"
              src={logo}
            /> */}
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginLeft: 5 }}
            >
              Thông báo mới
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={() => setOpenNoti(false)}
            sx={{
              position: 'absolute',
              right: 13,
              top: 13,
              color: '#606770',
              backgroundColor: 'button.secondary.background',
              padding: '2px'
            }}
          >
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </div>
        <div className={classes.content}>{component()}</div>
      </Box>
    </Snackbar>
  );
};

export default NotificationUi;
