import * as types from 'src/constants/saga/user';
const _ = require('lodash');

const initialState = {
  infor: {},
  list: {
    data: [],
    loaded: true,
    currentPage: -1
  },
  failed: false,
  listSocial: [],
  listLifeEvents: [],
  actionFriend: null,
  isLock: '',
  accountSetting: null,
  errorReq: false,
  dataErrorReq: {}
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFOR_USER_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      newState.infor = _.cloneDeep(data);
      newState.failed = false;
      return newState;
    }
    case types.GET_LIST_USER_SUCCESS: {
      let { data, currentPage, loaded } = action.payload;
      let newState = _.cloneDeep(state);
      newState.list.data = _.cloneDeep(data);
      newState.list.currentPage = currentPage;
      newState.list.loaded = loaded;
      newState.failed = false;
      return newState;
    }
    // case types.DESTROY_USER: {
    //   console.log('test api');
    //   // let newState = { ...initialState };
    //   // return newState;
    // }
    case types.USER_LOCK: {
      return {
        ...state,
        isLock: action.payload
      };
    }
    case types.DELETE_LOCK: {
      return {
        ...state,
        isLock: action.payload
      };
    }
    case types.GET_INFOR_USER_FAILED: {
      let newState = _.cloneDeep(state);
      newState.failed = true;
      return newState;
    }
    case types.GET_LIST_SOCIAL: {
      let { listSocial } = action.payload;
      return { ...state, listSocial: listSocial };
    }
    case types.GET_LIST_LIFE_EVENTS_SUCCESS: {
      return { ...state, listLifeEvents: action.payload.listLifeEvent };
    }
    case types.ACTION_FRIEND: {
      const { type } = action.payload;

      return {
        ...state,
        actionFriend: type === 'success' ? true : false
      };
    }
    case types.RESET_USER: {
      let newState;
      if (action.payload === 'user') {
        newState = {
          ...state,
          infor: {}
        };
      } else {
        newState = {
          ...initialState
        };
      }
      return newState;
    }
    case types.ACCOUNT_SETTINGS: {
      let { data } = action.payload;

      return {
        ...state,
        accountSetting: data
      };
    }
    case types.ERROR_MANY_REQUESTS: {
      let { data } = action.payload;
      return {
        ...state,
        errorReq: data
      };
    }
    case types.DATA_ERROR_MANY_REQUESTS: {
      let data = action.payload;
      return {
        ...state,
        dataErrorReq: data
      };
    }

    default:
      return state;
  }
};
