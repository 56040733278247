import { IconButton, InputAdornment, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import './input.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    '&::placeholder': {
      fontFamily:
        "'Segoe UI',Roboto, Tahoma, Geneva, Verdana, sans-serif !important"
    },
    fontFamily:
      "'Segoe UI',Roboto, Tahoma, Geneva, Verdana, sans-serif !important"
  },
  cssLabel: {
    fontFamily:
      "'Segoe UI',Roboto, Tahoma, Geneva, Verdana, sans-serif !important"
  },
  styleInput: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: buttonColor.backgroundColor
      }
    }
  }
}));

interface Props {
  formik?: any;
  label?: string;
  placeholder?: string;
  field?: any;
  id?: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  autoFocus?: boolean;
  size?: any;
  onBlur?: () => void;
  reset?: any;
  maxLength?: number;
}

const InputTextField = (props: Props) => {
  const {
    formik,
    label,
    placeholder,
    field,
    id,
    type,
    autoComplete,
    required,
    autoFocus,
    size,
    onBlur,
    reset,
    maxLength = 3000
  } = props;

  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState<any>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChane = e => {
    let value = e.target.value;

    // Không cho phép dấu cách đối với trường email và password
    if (field === 'username' || field === 'email' || field === 'password') {
      value = value.replace(/\s/g, '');
    } else {
      // Chỉ loại bỏ dấu cách ở đầu chuỗi cho các trường khác
      value = value.replace(/^\s+/, '');
    }

    if (!reset) {
      formik.setFieldValue(field, value);
    }
  };
  return (
    <TextField
      required={required}
      fullWidth
      id={id}
      type={
        type
          ? type === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : type
          : 'text'
      }
      label={label}
      size={size}
      placeholder={placeholder}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={formik.values[field]}
      onFocus={() => formik.setFieldTouched(field, true)}
      onBlur={onBlur}
      onChange={handleChane}
      error={formik.touched[field] && Boolean(formik.errors[field])}
      helperText={formik.touched[field] && formik.errors[field]}
      InputProps={{
        classes: { input: classes.input },
        endAdornment:
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
        inputProps: { maxLength },
        onKeyDown: e => {
          if (e.key === 'Enter') {
            formik.submitForm();
          }
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel
        }
      }}
      className={classes.styleInput}
    />
  );
};

export default InputTextField;
