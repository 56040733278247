import { Typography } from '@material-ui/core';
import { Avatar, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useInView } from 'react-hook-inview';
import { useDispatch, useSelector } from 'react-redux';
import NotificationUi from 'src/Notification/NotificationUi';
import { postWatchHistoriesApi, updatePostTags } from 'src/apis/socialPost.api';
import logo from 'src/assets/images/LogoEmso.svg';
import { boxShadow, buttonColor } from 'src/constants/styles';
import FeedMoment from 'src/pages/Moment/FeedMoment';
import { subStreaming, unSubStreaming } from 'src/socket/Streaming';
import {
  addPostFromDiary,
  resetNotiSharePost,
  updatePostsTagsUsers
} from 'src/store/action/socialPostAction';
import ButtonInherit from '../Button/ButtonInherit';
import SocialContent from './SocialContent';
import SocialPostAction from './SocialPostAction';
import SocialPostInformation from './SocialPostInformartion';
const useStyles = makeStyles((theme: any) => ({
  social: {
    ...boxShadow,
    width: '100%',
    // backgroundColor: theme.palette.myBackgroundColor?.primary,
    marginTop: '12px',
    borderRadius: '10px'
  },
  subText: {
    color: theme.palette.text.customColor,
    fontSize: '15px !important',
    marginTop: 17,
    padding: '0px 5px 0px 5px'
  },
  actionTags: {
    padding: '20px 0',
    textAlign: 'right'
  },
  typing: {
    width: '240px',
    height: '12px !important',
    position: 'relative',
    padding: '8px 4px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  typingDot: {
    float: 'left',
    width: '6px',
    height: '6px',
    margin: '0 3px',
    background: theme.palette.mode === 'light' ? '#8d8c91' : '#FAFAFA',
    borderRadius: '50%',
    opacity: '0'
  },
  typingText: {
    marginLeft: '6px',
    fontColor: theme.palette.mode === 'light' ? '#8d8c91' : '#FAFAFA'
  }
}));

interface Props {
  post?: any;
  type?: string | any;
  typeAction?: string;
  stylePost?: React.CSSProperties;
  typePost?: string;
  actionContent?: any;
  openDialogRating?: boolean;
  setOpenDialogRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setUpdateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setCreateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  itemRating?: any;
  ratingSelected?: any;
  setRatingSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  value?: number;
  setValue?: React.Dispatch<React.SetStateAction<number>> | any;
  setFiles?: React.Dispatch<React.SetStateAction<any>> | any;
  idPost?: string;
  typeShare?: string;
  styleShare?: React.CSSProperties;
  meInfo?: any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  scrollToTop?: any;
  fetchPostDetail?: any;
  infoData?: any;
  tempViewedPostIds?: any;
  setTempViewedPostIds?: React.Dispatch<React.SetStateAction<any>> | any;
  setPostDetail?: React.Dispatch<React.SetStateAction<any>> | any;
  setListPostRating?: any;
  offset?: number;
  pageInfo?: any;
  eventInfo?: any;
  courseInfo?: any;
  projectInfo?: any;
  setFollowPageId?: (id: string) => void;
}

const SocialPost = React.memo((props: Props) => {
  const {
    post,
    type,
    typeAction,
    stylePost,
    typePost,
    actionContent,
    openDialogRating,
    setOpenDialogRating,
    setUpdateRating,
    setCreateRating,
    itemRating,
    ratingSelected,
    setRatingSelected,
    value,
    setValue,
    setFiles,
    idPost,
    typeShare,
    styleShare,
    meInfo,
    setNoti,
    setOpenSnackbar,
    scrollToTop,
    fetchPostDetail,
    infoData,
    pageInfo,
    eventInfo,
    courseInfo,
    projectInfo,
    tempViewedPostIds,
    setTempViewedPostIds,
    setPostDetail,
    setListPostRating,
    offset, // Dùng trong react player để hiển thị player hay không
    setFollowPageId
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [postData, setPostData] = React.useState<any>(null);
  const [flagCount, setFlagCount] = React.useState(1);
  const [openNoti, setOpenNoti] = React.useState(false);
  const [currentPostId, setCurrentPostId] = React.useState('');

  const meInfor = useSelector((state: any) => state.meReducer.info);
  const [loadingTags, setLoadingTags] = React.useState({
    loading: false,
    action: ''
  });
  const [postSelectedTags, setPostSelectedTags] = React.useState<any>({});
  // const postRef: any = React.useRef();
  const updateVideoHistories = React.useCallback(
    _.debounce(async data => {
      let response = await postWatchHistoriesApi(data);
      if (response.status === 200) {
        setFlagCount(1);
      }
    }, 800),
    []
  );

  const updatePostsTags = async (type: any) => {
    try {
      let response = await updatePostTags(meInfo.id, {
        mentions_status: type,
        statuse_id: post.id
      });
      if (response.status === 200) {
        dispatch(updatePostsTagsUsers(post.id));
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message:
            type === 'approved'
              ? 'Bài viết đã được thêm vào dòng thời gian của bạn'
              : 'Bài viết này có thể vẫn xuất hiện trong kết quả tìm kiếm, Bảng feed và những nơi khác trên Emso.'
        });
        setLoadingTags({
          loading: false,
          action: ''
        });
      }
    } catch (e: any) {
      setOpenSnackbar(true);
      setNoti({
        code: e.response.status,
        message: 'Có lỗi xảy ra trong quá trình xử lý.Vui lòng thử lại sau!'
      });
      setLoadingTags({
        loading: false,
        action: ''
      });
    }
  };

  React.useEffect(() => {
    if (post?.status_video_create === 'pending') {
      dispatch(resetNotiSharePost());
      setOpenNoti(true);
      return;
    }
    if (post && Object.keys(post).length) {
      setPostData(post);
    }
    return () => {};
  }, [post]);

  React.useEffect(() => {
    // khởi tạo biến tính toán thời gian pending thì component unmount
    let unsubscribePending = false;
    let unsubscribeTimer;
    if (postData?.id && flagCount === 1) {
      // setFlagCount(prev => prev + 1);
      // setCurrentPostId(postData.id);
      if (postData?.page?.id) {
        subStreaming(postData.id, meInfor?.id, postData.page?.id);
      } else {
        subStreaming(postData.id, meInfor?.id, null);
      }
      if (tempViewedPostIds && !tempViewedPostIds.includes(postData.id)) {
        setTempViewedPostIds(prev => [...prev, postData.id]);
      }
      if (postData?.post_type === 'watch') {

        // updateVideoHistories({
        //   action_type: 'view_3s',
        //   status_ids: [postData.id]
        // });
      }
    } else {
      // check delay
      if (currentPostId && !unsubscribePending) {
        unsubscribeTimer = setTimeout(() => {
          if (postData?.page?.id) {
            unSubStreaming(currentPostId, meInfor?.id, postData.page.id);
          } else {
            unSubStreaming(currentPostId, meInfor?.id, null);
          }
          unsubscribePending = false;
        }, 1000); // thời gian delay bảo rằng nếu sau khi component bắt đầu unmount, có thể vẫn có một hành động re-subscribing
        unsubscribePending = true;
      }
    }

    return () => {
      clearTimeout(unsubscribeTimer);
      if (currentPostId && unsubscribePending) {
        if (postData?.page?.id) {
          unSubStreaming(currentPostId, meInfor?.id, postData.page.id);
        } else {
          unSubStreaming(currentPostId, meInfor?.id, null);
        }
      }
    };
  }, [postData?.id, currentPostId]);
  return (
    <>
      {postData &&
        (postData.post_type === 'moment' ? (
          <>
            <FeedMoment
              type={'postMoment'}
              typePost={typePost}
              key={'feedmoment-' + postData.id}
              moment={postData}
            />
          </>
        ) : (
          <Box
            key={postData.id}
            className={classes.social}
            style={{ ...stylePost }}
            sx={{
              backgroundColor: 'background.primary',
              marginTop: '20px'
            }}
            id={postData.id}
            // ref={postRef}
          >
            {typePost === 'diary_tags' && (
              <div className={classes.actionTags}>
                <ButtonInherit
                  label="Ẩn"
                  style={{
                    width: '240px',
                    height: '40px',
                    backgroundColor: '#e4e6eb',
                    color: buttonColor.backgroundColor,
                    '&:hover': {
                      backgroundColor: '#f0f1f4 !important'
                    }
                  }}
                  action={() => {
                    updatePostsTags('rejected');
                    setLoadingTags({
                      loading: true,
                      action: 'rejected'
                    });
                    setPostSelectedTags(post);
                  }}
                  loading={
                    loadingTags.loading &&
                    loadingTags.action === 'rejected' &&
                    postSelectedTags?.id === post?.id
                      ? true
                      : false
                  }
                />
                <ButtonInherit
                  label="Thêm vào trang cá nhân"
                  style={{
                    width: '230px',
                    height: '40px',
                    backgroundColor: buttonColor.backgroundColor,
                    color: '#fff',
                    marginRight: '10px'
                  }}
                  action={() => {
                    updatePostsTags('approved');
                    setLoadingTags({
                      loading: true,
                      action: 'approved'
                    });
                    setPostSelectedTags(post);
                    dispatch(addPostFromDiary(post));
                  }}
                  loading={
                    loadingTags.loading &&
                    loadingTags.action === 'approved' &&
                    postSelectedTags?.id === post?.id
                      ? true
                      : false
                  }
                />
                <Divider sx={{ paddingTop: '20px' }} />
              </div>
            )}
            <div
              id={`video-${postData?.media_attachments[0]?.id}`}
              // style={typePost === 'pinned' ? { height: '87px' } : {}}
            >
              <SocialPostInformation
                fetchPostDetail={fetchPostDetail}
                type={type}
                key={`${postData.id}-postInformation`}
                post={postData}
                setPostData={setPostData}
                typePost={typePost}
                typeAction={typeAction}
                openDialogRating={openDialogRating}
                setOpenDialogRating={setOpenDialogRating}
                setUpdateRating={setUpdateRating}
                setCreateRating={setCreateRating}
                setRatingSelected={setRatingSelected}
                ratingSelected={ratingSelected}
                itemRating={itemRating}
                idPost={idPost}
                setValue={setValue}
                setFiles={setFiles}
                valueRating={value}
                typeShare={typeShare}
                setPostDetail={setPostDetail}
                roleGroupMember={post?.group_account_relationship}
                setListPostRating={setListPostRating}
                setOpenSnackbar={setOpenSnackbar}
                setNoti={setNoti}
                setFollowPageId={setFollowPageId}
                pageInfo={pageInfo}
                eventInfo={eventInfo}
                courseInfo={courseInfo}
                projectInfo={projectInfo}
              />
            </div>
            <div
              onClick={() => (actionContent ? actionContent() : undefined)}
              style={{
                ...styleShare,
                cursor: actionContent ? 'pointer' : 'auto',
                width: '100%',
                height:
                  typePost === 'pinned' &&
                  post &&
                  post?.album &&
                  post?.album?.category !== 'avatar' &&
                  post?.album?.category !== 'banner'
                    ? 'calc(100% - 220px)'
                    : typePost === 'pinned'
                    ? 'calc(100% - 180px)'
                    : '100%',
                overflow: typePost === 'pinned' ? 'hidden' : 'visible',
                marginBottom: '10px'
              }}
            >
              <SocialContent
                key={`${postData.id}-socialContent`}
                post={postData}
                type={type}
                typePost={typePost}
                valueRating={value}
                typeShare={typeShare}
                scrollToTop={scrollToTop}
                offset={offset}
              />
            </div>

            {typeShare !== 'share-messenger' && !['rating'].includes(type) && (
              <SocialPostAction
                key={`${postData.id}-socialAction`}
                type={type}
                typePost={typePost}
                post={postData}
                setPostData={setPostData}
                typeAction={typeAction}
              />
            )}
            {postData.isTyping && (
              <div id="typing" className={classes.typing}>
                <div className={`typing__dot ${classes.typingDot}`}></div>
                <div className={`typing__dot ${classes.typingDot}`}></div>
                <div className={`typing__dot ${classes.typingDot}`}></div>
                <Typography className={classes.typingText} variant="caption">
                  Ai đó đang viết bình luận ...
                </Typography>
              </div>
            )}
          </Box>
        ))}

      {openNoti && (
        <NotificationUi
          openNoti={openNoti}
          setOpenNoti={setOpenNoti}
          component={() => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  cursor: 'pointer',
                  width: '50px',
                  height: 'auto'
                }}
                alt="Emso"
                src={logo}
              />
              <Typography className={classes.subText}>
                Video của bạn đang trong quá trình xử lý, chúng tôi sẽ thông báo
                cho bạn khi video đã sẵn sàng.
              </Typography>
            </div>
          )}
        />
      )}
    </>
  );
});

export default SocialPost;
