import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Theme,
  Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { alpha, styled } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  crudBookmarkApi,
  crudBookmarkCollectionApi
} from 'src/apis/socialPost.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import { updateSchedulePost } from 'src/store/action/socialGroupAction';
import { getPageDetailReq } from 'src/store/action/socialPageAction';
import {
  bookmarkPostSuccess,
  getListBookmarkCollectionReq
} from 'src/store/action/socialPostAction';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: 700
  }
}));

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      wrapMenu: {
        borderRadius: '10px!important'
      }
    }),
  { index: 1 }
);
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function ScrollDialog(props) {
  const {
    open,
    handleClose,
    bookmarkId,
    entityType,
    setOpenSnackbar,
    setNoti,
    setData,
    setPostData
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isError, setIsError] = React.useState(false);
  const [name, setName] = React.useState<any>('');
  const [isLoadingButton, setIsLoadingButton] = React.useState<any>(false);
  const [showInput, setShowInput] = React.useState<any>(false);
  const [collectionSelected, setCollectionSelected] =
    React.useState<any>(false);
  const match: any = useRouteMatch();

  let listCollections = useSelector((state: any) => {
    return state.socialSavedReducer.collectionSaved;
  });
  React.useEffect(() => {
    dispatch(getListBookmarkCollectionReq());
  }, []);

  React.useEffect(() => {
    if (open === 1) setShowInput(true); //dialog only create
  }, []);

  const createCollection = async (dataSelected: any) => {
    try {
      if (open === 1) {
        let data = {
          name: name
        };
        let response = await crudBookmarkCollectionApi('POST', null, data);
        if (response.status === 200) {
          setPostData &&
            setPostData(prev =>
              prev.id === bookmarkId
                ? { ...prev, bookmarked: res.data.bookmarked }
                : prev
            );
          if (setData) {
            setData(prev =>
              prev.map(item => {
                if (item.page.id === bookmarkId) {
                  return {
                    ...item,
                    page: {
                      ...item.page,
                      bookmarked: response.data.bookmarked
                    }
                  };
                } else {
                  return item;
                }
              })
            );
          } else {
            if (match.params?.key === 'schedule_post') {
              dispatch(updateSchedulePost(response.data));
            }
            dispatch(getListBookmarkCollectionReq());
          }
          setIsLoadingButton(false);
          setNoti({
            code: 200,
            message: 'Tạo bộ sưu tập thành công!'
          });
          setOpenSnackbar(true);
          handleClose();
        }
      } else {
        if (showInput) {
          let response = await crudBookmarkCollectionApi('POST', null, {
            name: name
          });
          if (response.status === 200) {
            let data = {
              bookmark_id: bookmarkId,
              entity_type: entityType,
              bookmark_collection_id: response.data.id
            };
            let res = await crudBookmarkApi('POST', null, data, null, null);
            if (res.status === 200) {
              setPostData &&
                setPostData(prev =>
                  prev.id === bookmarkId
                    ? { ...prev, bookmarked: res.data.bookmarked }
                    : prev
                );
              if (setData) {
                setData(prev =>
                  prev.map(item => {
                    if (item.page.id === bookmarkId) {
                      return {
                        ...item,
                        page: {
                          ...item.page,
                          bookmarked: res.data.bookmarked
                        }
                      };
                    } else {
                      return item;
                    }
                  })
                );
              } else {
                dispatch(
                  entityType === 'Status'
                    ? bookmarkPostSuccess(bookmarkId, res.data)
                    : getPageDetailReq(bookmarkId)
                );
              }
              if (match.params?.key === 'schedule_post') {
                dispatch(updateSchedulePost(res.data));
              }
              setNoti({
                code: 200,
                message: `Đã lưu vào ${name}`
              });
              setOpenSnackbar(true);
              setIsLoadingButton(false);
              handleClose();
            }
          }
        } else {
          let data = {
            bookmark_id: bookmarkId,
            entity_type: entityType,
            bookmark_collection_id: dataSelected?.id
          };
          let res = await crudBookmarkApi('POST', null, data, null, null);
          if (res.status === 200) {
            setPostData &&
              setPostData(prev =>
                prev.id === bookmarkId
                  ? { ...prev, bookmarked: res.data.bookmarked }
                  : prev
              );
            if (setData) {
              setData(prev =>
                prev.map(item => {
                  if (item.page.id === bookmarkId) {
                    return {
                      ...item,
                      page: {
                        ...item.page,
                        bookmarked: res.data.bookmarked
                      }
                    };
                  } else {
                    return item;
                  }
                })
              );
            } else {
              dispatch(
                entityType === 'Status'
                  ? bookmarkPostSuccess(bookmarkId, res.data)
                  : getPageDetailReq(bookmarkId)
              );
            }
            if (match.params?.key === 'schedule_post') {
              dispatch(updateSchedulePost(res.data));
            }
            setNoti({
              code: 200,
              message: `Đã lưu vào ${
                dataSelected?.name ? dataSelected?.name : 'Mục đã lưu'
              }`
            });
            setOpenSnackbar(true);
            setIsLoadingButton(false);
            handleClose();
          }
        }
      }
    } catch (e: any) {
      const message =
        e?.response?.data?.error === 'Có các lỗi sau: Name đã có'
          ? 'Tên bộ sưu tập đã tồn tại, vui lòng đổi lại tên mới.'
          : 'Có lỗi khi tạo bộ sưu tập';
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: message
      });
      handleClose();
    } finally {
      setIsLoadingButton(false);
    }
  };

  const handleCancelAction = () => {
    setShowInput(false);
  };

  const handleClick = data => {
    setCollectionSelected(() => data);
  };

  return (
    <div>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20, color: 'text.primary' }}
            textAlign={'center'}
          >
            Lưu vào
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {open === 1 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 5,
                  justifyContent: 'space-between'
                }}
              >
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                  Tên
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FormControl style={{ width: '100%' }} variant="standard">
                  <BootstrapInput
                    autoFocus
                    defaultValue={name}
                    fullWidth
                    multiline
                    placeholder="Đặt tên cho bộ sưu tập của bạn..."
                    size="small"
                    rows={1}
                    onChange={e => {
                      if (e.target.value.length > 40) {
                        setIsError(true);
                      } else {
                        setName(e.target.value);
                        setIsError(false);
                      }
                    }}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isError ? 'space-between' : 'start'
                }}
              >
                {(isError ||
                  (name && name.trim().length === 0) ||
                  name.length === 0) && (
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: '#f00' }}
                  >
                    {name.length === 0
                      ? 'Tên bộ sưu tập không được để trống'
                      : isError
                      ? 'Chỉ được phép nhập tối đa 40 ký tự'
                      : ''}
                  </FormHelperText>
                )}
              </div>
            </>
          ) : (
            <>
              {listCollections?.length
                ? listCollections.map(el => (
                    <ListItemButton
                      key={el.id}
                      className={classes.wrapMenu}
                      onClick={() => (showInput ? null : handleClick(el))}
                    >
                      <ListItemIcon>
                        <div
                          style={{
                            width: 36,
                            height: 36,
                            borderRadius: '10px',
                            backgroundColor: buttonColor.backgroundColor,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <i
                            style={{
                              color: '#fff',
                              fontSize: 15
                            }}
                            className="fa-solid fa-bookmark"
                          ></i>
                        </div>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontSize: '15px',
                              fontWeight: '600',
                              marginLeft: '-10px'
                            }}
                          >
                            {el?.name}
                          </Typography>
                        }
                      />
                      <Radio
                        checked={collectionSelected?.id === el.id}
                        disabled={showInput}
                        value={el.id}
                        name={`radio-buttons-${el.id}`}
                        inputProps={{ 'aria-label': `${el.id}` }}
                      />
                    </ListItemButton>
                  ))
                : null}
              <Divider />
              {showInput ? (
                <Box sx={{ marginTop: '15px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl style={{ width: '100%' }} variant="standard">
                      <BootstrapInput
                        autoFocus
                        defaultValue={name}
                        fullWidth
                        multiline
                        placeholder="Đặt tên cho bộ sưu tập của bạn..."
                        size="small"
                        rows={1}
                        onChange={e => {
                          if (e.target.value.length > 40) {
                            setIsError(true);
                          } else {
                            setName(e.target.value);
                            setIsError(false);
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: isError ? 'space-between' : 'start'
                    }}
                  >
                    {(isError ||
                      (name && name.trim().length === 0) ||
                      name.length === 0) && (
                      <FormHelperText
                        id="component-error-text"
                        sx={{ color: '#f00' }}
                      >
                        {name.length === 0
                          ? 'Tên bộ sưu tập không được để trống'
                          : isError
                          ? 'Chỉ được phép nhập tối đa 40 ký tự'
                          : ''}
                      </FormHelperText>
                    )}
                  </div>
                </Box>
              ) : (
                <ListItemButton
                  className={classes.wrapMenu}
                  onClick={() => {
                    setShowInput(true);
                  }}
                  sx={{ marginTop: '15px' }}
                >
                  <div
                    style={{
                      width: 36,
                      height: 36,
                      borderRadius: '10px',
                      backgroundColor: buttonColor.backgroundColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <i
                      style={{
                        color: '#fff',
                        fontSize: 15
                      }}
                      className="fa-light fa-plus"
                    ></i>
                  </div>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: '600',
                      marginLeft: '10px'
                    }}
                  >
                    Bộ sưu tập mới
                  </Typography>
                </ListItemButton>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: 5
            }}
          >
            <ButtonInherit
              label="Hủy"
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                if (showInput) {
                  handleCancelAction();
                } else {
                  handleClose();
                }
              }}
            />
            <ButtonInherit
              disabled={
                isError ||
                (showInput &&
                  ((name && name.trim().length === 0) || name.length === 0))
              }
              loading={isLoadingButton}
              label={showInput ? 'Tạo' : 'Xong'}
              style={{
                marginLeft: '10px',
                '&:hover': {
                  backgroundColor: '#7d72df'
                }
              }}
              color={buttonColor.backgroundColor}
              action={() => {
                if (showInput) {
                  setIsLoadingButton(true);
                  createCollection(null);
                } else {
                  setIsLoadingButton(true);
                  createCollection(collectionSelected);
                }
              }}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
