import * as types from 'src/constants/store/report';
const _ = require('lodash');
const initialState = {
  listReport: [],
  listReportShop: []
};

export const socialReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_REPORT_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, listReport: newState };
    }
    case types.GET_LIST_REPORT_SHOP_SUCCESS: {
      let { data } = action.payload;
      return { ...state, listReportShop: data };
    }
    default:
      return state;
  }
};
