import * as types from 'src/constants/saga/user';
export const getDetailUserReq = (userId: String) => ({
  type: types.GET_PROFILE_USER_REQ,
  payload: {
    userId
  }
});

export const getDetailUserSuccess = (data: Object) => ({
  type: types.GET_PROFILE_USER_SUCCESS,
  payload: {
    data
  }
});

export const getDetailUserFailed = () => ({
  type: types.GET_PROFILE_USER_FAILED
});

export const getInforlUserReq = (data: string) => ({
  type: types.GET_INFOR_USER_REQ,
  payload: {
    data
  }
});

export const getInforlUserSuccess = (data: Object) => ({
  type: types.GET_INFOR_USER_SUCCESS,
  payload: {
    data
  }
});

export const getInforlUserFailed = () => ({
  type: types.GET_INFOR_USER_FAILED
});

export const getListUserReq = (params: any) => {
  return {
    type: types.GET_LIST_USER_REQ,
    payload: {
      params
    }
  };
};

export const getListUserSuccess = (
  data: any,
  currentPage: number,
  loaded: boolean
) => {
  return {
    type: types.GET_LIST_USER_SUCCESS,
    payload: {
      data,
      currentPage,
      loaded
    }
  };
};

export const destroyUser = () => {
  return { type: types.DESTROY_USER };
};
export const userLock = (isLock: string) => ({
  type: types.USER_LOCK,
  payload: isLock
});
export const deleteLock = (isLock: string) => ({
  type: types.DELETE_LOCK,
  payload: isLock
});
export const getListSocial = listSocial => {
  return {
    type: types.GET_LIST_SOCIAL,
    payload: { listSocial }
  };
};

export const getListLifeEventsRes = userInfo => ({
  type: types.GET_LIST_LIFE_EVENTS_REQUEST,
  payload: { userInfo }
});
export const getListLifeEventSuccess = listLifeEvent => {
  return {
    type: types.GET_LIST_LIFE_EVENTS_SUCCESS,
    payload: { listLifeEvent }
  };
};

export const actionFriend = type => ({
  type: types.ACTION_FRIEND,
  payload: { type }
});

export const resetUser = (type: any) => ({
  type: types.RESET_USER,
  payload: type
});

export const accountSetting = data => ({
  type: types.ACCOUNT_SETTINGS,
  payload: { data }
});

export const errorManyReq = (data: boolean) => ({
  type: types.ERROR_MANY_REQUESTS,
  payload: { data }
});

export const dataErrorManyReq = data => ({
  type: types.DATA_ERROR_MANY_REQUESTS,
  payload: data
});
