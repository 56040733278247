import { useSelector } from 'react-redux';
import { select } from 'redux-saga/effects';
import * as types from 'src/constants/saga/watchSocial';
const _ = require('lodash');

const initialState = {
  watchSelected: {},
  timePlay: 0,
  volume: 0,
  postSelected: {},
  postReacted: {} as any,
  watchSearch: {},
  dataActivity: [],
  watchSaveTimeRun: {} as any,
  momentSearch: [],
  idVideo: '',
  src: '',
  type: '',
  showMiniPlayer: false
};

export const watchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.WATCH_SELECTED:
      let { watch } = action.payload;
      return {
        ...state,
        watchSelected: watch
      };
    case types.SHOW_MINI_PLAYER:
      return {
        ...state,
        idVideo: action.payload.data.id,
        src: action.payload.data.src,
        type: action.payload.data.type,
        showMiniPlayer: action.payload.data.showMiniPlayer
      };

    case types.DATA_ACTIVITY:
      let { data } = action.payload;

      return {
        ...state,
        dataActivity: data
      };
    case types.POST_SELECTED:
      let { post } = action.payload;
      let result = state.dataActivity.filter((e: any) => {
        if (post.id === e.id) {
          return e;
        }
      });
      if (
        post.id === state.postReacted?.id &&
        state.postReacted?.viewer_reaction &&
        state.postReacted?.reactions
      ) {
        post.viewer_reaction = state.postReacted?.viewer_reaction;
        post.reactions = state.postReacted?.reactions;
      }

      return {
        ...state,
        postSelected: result.length > 0 ? result[0] : post
      };

    case types.CHANGE_REACTION:
      const newState = _.cloneDeep(state);
      let { reaction, postSelected, listReaction } = action.payload.data;

      let index = newState.dataActivity.findIndex(
        (e: any) => e?.id === postSelected?.id
      );
      const newPostSelected = {
        ...postSelected,
        viewer_reaction: reaction,
        reactions: listReaction
      };

      if (index !== -1) {
        newState.dataActivity[index].viewer_reaction = reaction;
        newState.dataActivity[index].reactions = listReaction;
      }

      return { ...newState, postReacted: newPostSelected };

    case types.RESET_WATCH_SELECTED:
      return {
        ...state,
        watchSelected: {}
      };

    case types.TIME_PLAY_SELECTED:
      let { time } = action.payload;
      return {
        ...state,
        timePlay: time
      };
    case types.RESET_TIME_PLAY_SELECTED:
      return {
        ...state,
        timePlay: 0
      };
    case types.WATCH_SAVE_TIME_RUN:
      let { idWatch, timePlay, openDialog } = action.payload;
      return {
        ...state,
        watchSaveTimeRun: {
          idWatch: idWatch ?? state?.watchSaveTimeRun?.idWatch,
          timePlay: timePlay ?? state?.watchSaveTimeRun?.timePlay,
          openDialog: openDialog // Thêm để bắt sự kiện đóng dialog thì mới play video
        }
      };

    case types.VOLUME_PLAY_SELECTED:
      let { volume } = action.payload;

      return {
        ...state,
        volume: volume
      };

    case types.WATCH_SEARCH:
      return {
        ...state,
        watchSearch: action.payload
      };

    case types.SEARCH_MOMENT:
      let { accounts, groups, pages, statuses } = action.payload.data;
      return {
        ...state,
        momentSearch: [...accounts, ...groups, ...pages, ...statuses]
      };

    case types.RESET_MOMENT_SEARCH:
      return {
        ...state,
        momentSearch: []
      };

    default:
      return state;
  }
};
