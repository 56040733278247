import { apiDelete, apiGet, apiPost } from './apis';
import { apiGetV2 } from './apisv2';

export const searchUserGlobal = async (params: any) => {
  return apiGetV2('search', params, null);
};

export const userSearchHistory = async (params: any) => {
  return apiGet('search_histories', params, null);
};

export const addUserSearchHistory = async (formdata: any) => {
  return apiPost('search_histories', null, formdata);
};

export const deleteUserSearchHistory = async (id: any) => {
  return apiDelete(`search_histories/${id}`, null, null);
};
