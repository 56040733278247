import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EmojiPicker, {
  EmojiStyle,
  Categories,
  EmojiClickData,
  Theme
} from 'emoji-picker-react';

import { updateEmoticonConversation } from 'src/apis/socialChat.apis';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import Button from 'src/components/Button/Button';
function DialogChangedEmoticon(props) {
  const { open, setOpen, conversation } = props;
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };

  const currentlyEmoticon = conversation.customFields?.icon;
  async function onClick(emojiData: EmojiClickData, event: MouseEvent) {
    try {
      if (emojiData && !conversation?.blocker && !conversation?.blocked) {
        const roomId = conversation.rid;
        const icon = emojiData.emoji;
        const user = conversation?.u;
        const response = await updateEmoticonConversation(roomId, icon, user);
        if (response.status === 200) {
        }
        await handleClose();
      }
    } catch (error) {
      console.log('[handleChangeEmoticon]', error);
    }
  }
  const handleRemoveCurrentEmoticon = async () => {
    try {
      if (currentlyEmoticon) {
        const roomId = conversation.rid;
        const user = conversation?.u;
        const response = await updateEmoticonConversation(roomId, '', user);
        if (response.status === 200) {
          // dispatch(changedEmoticon(roomId, icon));
        }
        await handleClose();
      }
    } catch (error) {
      console.log('[handleChangeEmoticon]', error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: '360px',
            width: '370px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Biểu tượng cảm xúc
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          {currentlyEmoticon && (
            <Box
              style={{
                width: '100%',
                padding: '4px 14px'
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
                Biểu tượng cảm xúc hiện tại
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography sx={{ fontSize: '26px' }}>
                  {currentlyEmoticon}
                </Typography>
                <Button
                  label="Gỡ"
                  icon={<i className="fa-solid fa-xmark"></i>}
                  style={{
                    width: 'fit-content',
                    padding: '4px 16px',
                    fontSize: '14px'
                  }}
                  action={handleRemoveCurrentEmoticon}
                />
              </Box>
            </Box>
          )}

          <EmojiPicker
            onEmojiClick={onClick}
            searchDisabled
            height={350}
            previewConfig={{
              showPreview: false
            }}
            emojiStyle={EmojiStyle.FACEBOOK}
            theme={theme.palette.mode === 'dark' ? Theme.DARK : Theme.LIGHT}
            emojiVersion="0.6"
            lazyLoadEmojis={true}
            categories={[
              {
                name: 'Dùng thường xuyên',
                category: Categories.SUGGESTED
              },
              {
                name: 'Mặt cười và hình người',
                category: Categories.SMILEYS_PEOPLE
              },
              {
                name: 'Động vật và thiên nhiên',
                category: Categories.ANIMALS_NATURE
              },
              {
                name: 'Đồ ăn',
                category: Categories.FOOD_DRINK
              },
              {
                name: 'Địa điểm',
                category: Categories.TRAVEL_PLACES
              },
              {
                name: 'Hoạt động',
                category: Categories.ACTIVITIES
              },
              {
                name: 'Đối tượng',
                category: Categories.OBJECTS
              },
              {
                name: 'Ký hiệu',
                category: Categories.SYMBOLS
              },
              {
                name: 'Cờ',
                category: Categories.FLAGS
              }
            ]}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DialogChangedEmoticon;
