import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
const routesHelper = [
  PATHS.HELPER,
  PATHS.HELPER_ITEM,
  PATHS.HElPER_WRAP,
  PATHS.TERMS_ITEM,
  PATHS.POLICY_DOC
];
function AuthenticatedGuard(props) {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        if (
          !localStorage.getItem('token') &&
          !routesHelper.includes(rest.computedMatch.path)
        ) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
}

export default AuthenticatedGuard;
