import { apiDelete, apiGet, apiPatch, apiPost } from './apis';
import { apiGetV2 } from './apisv2';

export const getListReportMusic = async () => {
  return apiGet('report_categories', { music: true }, null);
};

export const getArtistApi = async (params: any) => {
  return apiGet('artists', params, null);
};

export const getTopAlbumApi = async (params: any) => {
  return apiGet('top_albums', params, null);
};
export const getMyAlbumApi = async (params: any) => {
  return apiGet('playlists', params, null);
};
export const getCategoriesMusic = async () => {
  return apiGet('music_categories', null, null);
};
export const getTopMusicApi = async (params: any) => {
  return apiGet('top_musics', params, null);
};
export const getMusicChartApi = async (params: any) => {
  return apiGet('music_chart', params, null);
};
export const getMusicInPlaylistApi = async (idALbum: any, params: any) => {
  return apiGet(`songlists/${idALbum}/musics`, params, null);
};
export const getMusicOfArtistApi = async (params: any, IdArtist: any) => {
  return apiGet(`artists/${IdArtist}/musics`, params, null);
};
export const getAlbumMusicApi = async (params: any, IdAlbum: any) => {
  return apiGet(`playlists/${IdAlbum}/musics`, params, null);
};
export const getDetailAlbumApi = async (IdAlbum: any) => {
  return apiGet(`playlists/${IdAlbum}`, null, null);
};
export const getPlaylistApi = async (params: any) => {
  return apiGet(`songlists`, params, null);
};

export const getDetailPlaylistApi = async (id: any) => {
  return apiGet(`songlists/${id}`, null, null);
};
export const searchFriendUserMusic = async (params: any) => {
  return apiGet('friendships', params, null);
};
export const createUpdateMusicApi = async (data: any) => {
  var formdata = new FormData();
  formdata.append('name', data.title);
  formdata.append('description', data.description);
  formdata.append('music_category_id', data.category_music_id);
  formdata.append('copyright_id', data.copyright_id);
  for (var i = 0; i < data.music_host_added.length; i++) {
    formdata.append('music_author_added[]', data.music_host_added[i]);
  }

  if (data.fileMp3.id) {
    formdata.append('media_id', data.fileMp3.id);
  }
  if (data.banner.id) {
    formdata.append('banner_id', data.banner.id);
  }

  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id.id);
  }

  return apiPost('musics', null, formdata);
};

export const createUpdateAlbum = async (data: any, type, idAlbum) => {
  if (type === 'update') {
    return apiPatch(`playlists/${idAlbum}`, null, data);
  }

  return apiPost(`playlists`, null, data);
};

export const removeAlbumApi = async (id: any) => {
  return apiDelete(`playlists/${id}`, null, null);
};

export const createUpdatePlaylistApi = async (data: any, type, idPlaylist) => {
  const formdata = new FormData();
  formdata.append('name', data.name);
  if (data.banner) {
    if (data.banner.id) {
      formdata.append('banner_id', data.banner.id);
    } else {
      formdata.append('banner_id', data.banner.file.id);
    }
  }

  if (type === 'update') {
    return apiPatch(`songlists/${idPlaylist}`, null, formdata);
  }

  return apiPost('songlists', null, formdata);
};

export const removePlaylistApi = async (id: any) => {
  return apiDelete(`songlists/${id}`, null, null);
};
export const removeMusicInPlaylistApi = async (id: any, data: any) => {
  return apiPost(`songlists/${id}/remove_music`, null, data);
};
export const getAlbumCategoryApi = async (id: any, params: any) => {
  return apiGet(`music_categories/${id}/albums`, params, null);
};
export const likeFanApi = async (id: any, params: any) => {
  return apiGet(`artists/${id}/fans`, params, null);
};
export const getArtistsAlbumApi = async (id: any, params: any) => {
  return apiGet(`artists/${id}/albums`, params, null);
};
export const getNewMusicApi = async (params: any) => {
  return apiGet(`latest_musics`, params, null);
};
export const getNewAlbumReleaseApi = async (params: any) => {
  return apiGet(`new_release_albums`, params, null);
};
export const getListMusicUploadted = async params => {
  return apiGet(`uploaded_songs`, params, null);
};

export const getListMusicUploadtedHasTotal = async params => {
  return apiGet(`list_uploaded_songs`, params, null);
};
export const getListAlbumUploaded = async params => {
  return apiGet(`created_albums`, params, null);
};
export const countListen = async (data: any) => {
  return apiPost(`music_histories`, null, data);
};
export const addMusicPlaylist = async (id: any, data: any) => {
  let musicId = {
    music_ids: [data]
  };
  return apiPost(`songlists/${id}/add_music`, null, musicId);
};
export const getDetailMusic = async (id: any) => {
  return apiGet(`musics/${id}`, null, null);
};
export const deleteMusic = async data => {
  return apiDelete(`uploaded_songs`, null, data);
};
export const addMusicToAlbum = async (id: any, data) => {
  return apiPost(`playlists/${id}/add_music`, null, data);
};
export const removeMusicInAlbum = async (id: any, data: any) => {
  return apiPost(`playlists/${id}/remove_music`, null, data);
};
export const musicByCategories = async (params: any) => {
  return apiGet(`list_by_categories`, params, null);
};
export const getListInvitesAuthorsMusic = async () => {
  return apiGet(`music_invitation_authors`, null, null);
};
export const respondInvite = async (id: any, params: any) => {
  return apiPost(
    `musics/${id}/invitation_authors/invitations_respond`,
    params,
    null
  );
};

export const searchMusicApi = async (keyWord: any) => {
  return apiGetV2(
    `search?type[]=musics&type[]=playlists&q=${encodeURIComponent(
      keyWord
    )}&limit=3&offset=0`,
    null,
    null
  );
};

export const getListAlbumAdmin = async () => {
  return apiGet('list_albums', null, null);
};

export const getDetailAlbumAdmin = async idAlbum => {
  return apiGet(`playlists/${idAlbum}`, null, null);
};

export const getMusicAuthorList = (data: any) => {
  return apiGet(`music_author_list`, data, null);
};
