import * as React from 'react';import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  DialogActions,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { handleActionCleanHistory } from 'src/pages/Chat/functionChat';
import { buttonColor } from 'src/constants/styles';

function DialogDeleteConversation(props) {
  const { open, setOpen, conversation } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const handleDeleteConversation = async () => {
    await handleActionCleanHistory(conversation, dispatch);
    handleClose();
  };
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: '200px',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Xóa cuộc trò chuyện này ?
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <Typography style={{ fontSize: '14px', padding: '12px 0 0' }}>
            <span
              style={{
                fontWeight: 700,
                color: '#d11b11',
                textDecoration: 'underline'
              }}
            >
              Lưu ý:{' '}
            </span>
            Tất cả mọi người trong cuộc trò chuyện này sẽ không còn nhìn thấy
            tin nhắn trong đoạn chat này nữa. Bạn không thể hoàn tác sau khi xóa
            bản sao của cuộc trò chuyện này.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              // color: buttonColor.backgroundColor,
              // backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Xác nhận'}
            style={{
              width: '100px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: theme.palette.background.secondary,

              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleDeleteConversation}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogDeleteConversation;
