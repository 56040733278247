import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {
  createUpdateCV,
  getListCV,
  getListUserApplyRecruit
} from 'src/apis/socialRecruit.api';
import ImageRender from 'src/components/Dialog/DialogCUPost/CUMenuUi/ImageRender';
import calendar from 'src/assets/images/calendar.png';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import {
  buttonColor,
  modalBackdrop,
  buttonColorHover
} from 'src/constants/styles';
import {
  notiMobileCvRecruit,
  submitRecruit
} from 'src/store/action/socialRecruitAction';
import * as Yup from 'yup';
import { InfoCv } from './InfoCv';
import { handleChatNow } from 'src/pages/Chat/functionChat';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import { useDropzone } from 'react-dropzone';
import { limitText } from 'src/common/limitText';
import { phoneRegExp } from 'src/constants/regex';
import backgroundCV from 'src/assets/images/backgroundCV.png';
import DialogConfirmation from 'src/components/Dialog/DialogConfirmDelete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    height: '70vh'
  },
  '& .MuiDialog-container': {
    overflow: 'hidden !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root ': {
    maxWidth: '1200px !important',
    borderRadius: '15px !important',
    overflow: 'unset !important'
  }
}));

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      wrapIcon: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: '#000'
      },
      wrapTitle: {
        maxHeight: '40px',
        fontSize: '18px!important',
        backgroundColor: '#ff823c',
        borderRadius: '50px',
        padding: '5px 20px',
        color: '#fff',
        fontWeight: 'bold!important',
        '&.MuiTypography-root': {
          marginTop: '20px'
        }
      },
      wrapTitleWorkingExp: {
        fontSize: '20px!important',
        backgroundColor: '#ff823c',
        borderRadius: '50px',
        padding: '5px 20px',
        color: '#fff',
        fontWeight: 'bold!important'
        // '&.MuiTypography-root': {
        //   marginTop: '-30px'
        // }
      },
      wrapCard: {
        width: '100%',
        maxWidth: '248.8px',
        height: '380px',
        border: 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: '#eee',
          cursor: 'pointer'
        },
        boxShadow: '2px 2px 10px 1px rgb(0 0 0 / 8%)!important'
      },
      wrapChip: {
        fontWeight: '700 !important',
        padding: '3px 8px',
        borderRadius: '3px',
        fontSize: '11px !important',
        color: '#2db14f',
        backgroundColor: '#e5f7ed'
      },
      wrapText: {
        fontWeight: '700 !important',
        fontSize: '15px !important',
        color: '#666',
        marginTop: '30px !important',
        wordBreak: 'break-all'
      },
      wrapColor: {
        position: 'absolute',
        bottom: '20px',
        left: '15px'
      },
      wrapButtonEdit: {
        width: '110px',
        height: '76px',
        borderRight: '1px solid #eee',
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '&:hover': {
          cursor: 'pointer'
        }
      },
      wrapDot: {
        width: 16,
        height: 16,
        borderRadius: 50
      },
      textCv: {
        width: '100%',
        fontSize: '13px !important',
        fontWeight: '700 !important',
        textAlign: 'justify',
        wordBreak: 'break-word',
        color: '#000 '
      },
      textTitle: {
        width: '100%'
      },
      contentRight: {
        paddingRight: '30px'
      },
      nameCv: {
        margin: '10px 0 0 0 !important',
        paddingLeft: '10px !important',
        width: '100%',
        fontSize: '16px !important',
        fontWeight: '400 !important',
        color: '#444',
        fontFamily: 'robotocondensed, Arial, sans-serif !important',
        wordBreak: 'break-all'
      },
      titleRight: {
        width: '100%',
        color: '#000'
      },
      textRight: {
        width: '100%',
        fontSize: '13px !important',
        marginTop: '10px !important',
        wordBreak: 'break-word',
        color: '#000'
      },
      cardMediaCv: {
        width: '100%',
        height: '215px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px'
      },
      noCv: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '100px'
      },
      printCv: {
        fontWeight: '600 !important',
        fontSize: '16px !important'
      },
      textPlusCv: {
        color: '#212f3f',
        fontSize: 15,
        fontWeight: 'bold',
        marginBottom: '20px'
      },
      scroll: {
        '&::-webkit-scrollbar': {
          width: '4px'
        }
      },
      boxIcon: {
        display: 'flex',
        alignItems: 'center'
      },
      divicon: {
        marginRight: '6px',
        paddingTop: '10px'
      },
      wrapDrag: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative'
      },
      wrapUpload: {
        width: '65%',
        height: '100%',
        borderRadius: 10,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.custom,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.background.hover
        }
      },
      wrapRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      },
      wrapIconText: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: theme.palette.button.primary.background,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      text: {
        fontSize: '17px !important',

        fontWeight: '500 !important'
      },
      subText: {
        fontSize: '12px !important',
        color: '#80838a',
        textAlign: 'center'
      }
    }),
  { index: 1 }
);
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  back?: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, back, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {back ? (
        <IconButton
          aria-label="close"
          onClick={back}
          sx={{
            position: 'absolute',
            left: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <i
            className="fa-regular fa-arrow-left-long"
            style={{ fontSize: 18 }}
          ></i>
        </IconButton>
      ) : null}
      {children}
      {onClose ? (
        <Tooltip title="Nhấn ESC để đóng">
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </DialogTitle>
  );
};

const textFieldCss = {
  fontSize: '16px',
  fontWeight: 400,
  color: '#444',
  fontFamily: 'robotocondensed, Arial, sans-serif',
  textAlign: 'justify',
  paddingLeft: '10px'
};

const listButtonOption = [
  {
    id: 1,
    label: 'Ảnh đại diện',
    icon: <i className="fa-solid fa-image-user" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'account_name',
        placeholder: 'Họ tên',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'vacancies',
        placeholder: 'Vị trí ứng tuyển',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 2,
    label: 'Thông tin Cá nhân',
    icon: (
      <i
        className="fa-regular fa-circle-exclamation"
        style={{ fontSize: 14 }}
      ></i>
    ),
    renderTexts: [
      {
        field: 'birth_day',
        placeholder: 'DD/MM/YY(*)',
        icon: (
          <i
            className="fa-solid fa-calendar"
            style={{ fontSize: 14, color: '#333' }}
          ></i>
        ),
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'phone',
        placeholder: 'Số điện thoại(*)',
        icon: (
          <i
            className="fa-solid fa-phone"
            style={{ fontSize: 14, color: '#333' }}
          ></i>
        ),
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'email',
        placeholder: 'Email(*)',
        icon: (
          <i
            className="fa-solid fa-envelope"
            style={{ fontSize: 14, color: '#333' }}
          ></i>
        ),
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'address',
        placeholder: 'Địa chỉ(*)',
        icon: (
          <i
            className="fa-solid fa-location-dot"
            style={{ fontSize: 14, color: '#333' }}
          ></i>
        ),
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 3,
    label: 'Mục tiêu nghè nghiệp',
    icon: <i className="fa-regular fa-bullseye" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'career_goals',
        placeholder: 'Mục tiêu nghề nghiệp',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 5,
    label: 'Học vấn',
    icon: (
      <i className="fa-regular fa-graduation-cap" style={{ fontSize: 14 }}></i>
    ),
    renderTexts: [
      {
        field: 'education',
        placeholder: 'Học vấn',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'Schools_name',
        placeholder: 'Tên trường học',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'majors',
        placeholder: 'Chuyên ngành học',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'startDate_endDate',
        placeholder: 'Ngày bắt đầu và ngày kết thúc',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 7,
    label: 'Kinh nghiệm làm việc',
    icon: <i className="fa-regular fa-briefcase" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'name_cty',
        placeholder: 'Tên công ty',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'work_experience',
        placeholder: 'Kinh nghiệm làm việc',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'working_position',
        placeholder: 'Vị trí làm việc',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'startDate_endDate_work_experience',
        placeholder: 'Thời gian làm việc',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'description_work_experience',
        placeholder: 'Mô tả thêm',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 12,
    label: 'Project',
    icon: <i className="fa-regular fa-briefcase" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'project',
        placeholder: 'Tên Project ',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'startDate_endDateProject',
        placeholder: 'Thời gian làm dự án',
        styleText: textFieldCss,
        styleField: {}
      },
      {
        field: 'descriptionProject',
        placeholder: 'Thông tin mô tả thêm về dự án',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 4,
    label: 'Kỹ năng',
    icon: <i className="fa-regular fa-pen-fancy" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'skill',
        placeholder: 'Kỹ năng',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 9,
    label: 'Hoạt động',
    icon: <i className="fa-light fa-hand" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'work',
        placeholder: 'Hoạt động',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 8,
    label: 'Giải thưởng',
    icon: <i className="fa-regular fa-award" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'prize',
        placeholder: 'Giải thưởng',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 6,
    label: 'Danh thiếp',
    icon: <i className="fa-regular fa-user-tag" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'business_card',
        placeholder: 'Danh thiếp',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 10,
    label: 'Người tham chiếu',
    icon: <i className="fa-regular fa-user-plus" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'reference_person',
        placeholder: 'Người tham chiếu',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  },
  {
    id: 11,
    label: 'Sở thích',
    icon: <i className="fa-regular fa-thumbs-up" style={{ fontSize: 14 }}></i>,
    renderTexts: [
      {
        field: 'hobby',
        placeholder: 'Sở thích',
        styleText: textFieldCss,
        styleField: {}
      }
    ]
  }
];

export default function ScrollDialog(props) {
  const {
    open,
    setOpen,
    handleClose,
    isUpdate,
    setIsUpdate,
    cvSelected,
    createRecruitCV,
    setCreateRecruitCV,
    setCVSelect,
    cvSelect,
    // handleChatNow,
    setListCVSaved,
    type,
    setOpenAl
  } = props;

  const recruitInfo = useSelector((state: any) => state.recruitReducer.info);
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [themeCV, setThemeCV] = React.useState<any>('#ff823c');
  const [listCV, setListCV] = React.useState<any>([]);
  const [buttunOption, setButtonOption] = React.useState(listButtonOption);
  const [notUsedYet, setNotUsedYet] = React.useState<any>([]);
  const detailCvId = useParams<any>();
  const [openOptionCv, setOpenOptionCv] = React.useState(false);
  const [openNotiCv, setOpenNotiCv] = React.useState(false);
  const [OptionItem, setOptionItem] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const meInfo = useSelector((state: any) => state?.meReducer?.info);
  const rocketId = meInfo.id || '';
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const componentRef = React.useRef(null);
  const [openDialogConfirmation, setOpenDialogConfirmation] =
    React.useState<boolean>(false);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button
        sx={{
          backgroundColor: 'button.primary.background',
          color: theme.palette.mode === 'dark' ? '#fff' : '#0b0b0b',
          textTransform: 'none '
        }}
        className={classes.printCv}
        startIcon={<i className="fa-solid fa-print"></i>}
      >
        Print
      </Button>
    );
  }, []);
  const handleAddOrDeleteButtonOption = (buttonOptionItem, type) => {
    try {
      if (buttonOptionItem && type === 'delete') {
        const buttonOptionItemField = buttonOptionItem.renderTexts.map(
          item => item.field
        );
        setButtonOption(
          buttunOption.filter(item => item?.id !== buttonOptionItem?.id)
        );
        setNotUsedYet([...notUsedYet, buttonOptionItem]);
        setOpenNotiCv(false);
        if (buttonOptionItemField) {
          for (let i of buttonOptionItemField) {
            formik.setFieldValue(i, '');
          }
        }
      }
      if (buttonOptionItem && type === 'add') {
        const exitsButtonOption = buttunOption.find(
          buttunOption => buttunOption?.id === buttonOptionItem?.id
        );
        if (!exitsButtonOption) {
          setButtonOption([...buttunOption, buttonOptionItem]);
          setNotUsedYet(
            notUsedYet.filter(item => item?.id !== buttonOptionItem?.id)
          );
        } else {
          setButtonOption([...buttunOption]);
        }
      }
    } catch (error) {
      setOpenNotiCv(false);
    }
  };

  const handleOffOptionCv = type => {
    if (type === 'offOptionCv') {
      setOpenOptionCv(false);
    }
    if (type === 'offNoti') {
      setOpenNotiCv(false);
    }
  };

  const handleOnOption = buttonOptionItem => {
    setOpenNotiCv(true);
    setOptionItem(buttonOptionItem);
  };

  const renderCv = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),

    account_name: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    vacancies: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),

    birth_day: Yup.string()
      .matches(
        /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/,
        'Ngày sinh không hợp lệ, vui lòng nhập theo định dạng DD/MM/YYYY'
      )
      .test(
        'is-valid-date',
        'Ngày sinh không hợp lệ, không được vượt quá ngày hiện tại!',
        function (value) {
          if (!value) return false;
          const [day, month, year] = value.split('/');
          const dateObject = new Date(year, month - 1, day);
          if (
            dateObject.getFullYear() !== parseInt(year, 10) ||
            dateObject.getMonth() !== parseInt(month, 10) - 1 ||
            dateObject.getDate() !== parseInt(day, 10)
          ) {
            return false;
          }
          // Kiểm tra nếu ngày nhập vào vượt quá ngày hiện tại
          const currentDate = new Date();
          const inputDate = new Date(year, month - 1, day);
          return inputDate <= currentDate;
        }
      ),
    phone: Yup.string()
      .matches(
        phoneRegExp,
        'Số điện thoại chỉ được chứa số, đầu cuối không được chứa khoảng cách'
      )
      .max(10, 'Số điện thoại không được dài hơn 10 ký tự.')
      .min(10, 'Số điện thoại phải có ít nhất 10 ký tự.')
      .required('Số điện thoại không được để trống!')
      .nullable(),
    email: Yup.string()
      .email('Email không hợp lệ, vui lòng nhập lại!')
      .max(150, 'Không được nhập quá 150 kí tự')
      .required('Email không được để trống!'),
    address: Yup.string()
      .max(70, 'Không được nhập quá 70 kí tự')
      .required('Không được để trống, vui lòng nhập địa chỉ!'),

    career_goals: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    education: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    Schools_name: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    majors: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    startDate_endDate: Yup.string().max(100, 'Không được nhập quá 100 kí tự'),

    work_experience: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    working_position: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    startDate_endDate_work_experience: Yup.string().max(
      100,
      'Không được nhập quá 100 kí tự'
    ),
    name_cty: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),
    description_work_experience: Yup.string().max(
      250,
      'Không được nhập quá 250 kí tự'
    ),

    project: Yup.string().max(150, 'Không được nhập quá 150 kí tự'),
    startDate_endDateProject: Yup.string().max(
      100,
      'Không được nhập quá 100 kí tự'
    ),
    descriptionProject: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),

    skill: Yup.string().max(1500, 'Không được nhập quá 1500 kí tự'),

    work: Yup.string().max(1500, 'Không được nhập quá 1500 kí tự'),

    prize: Yup.string().max(1500, 'Không được nhập quá 1500 kí tự'),

    business_card: Yup.string().max(1500, 'Không được nhập quá 1500 kí tự'),

    reference_person: Yup.string().max(250, 'Không được nhập quá 250 kí tự'),

    hobby: Yup.string().max(1500, 'Không được nhập quá 1500 kí tự')
  });
  const handleActionApplyCV = async () => {
    try {
      let data = {
        account_information: {
          ...formik.values
        },
        template:
          'https://www.topcv.vn/images/cv/screenshots/thumbs/cv-template-thumbnails-v1.2/chrome.png?v=1.0.4'
      };
      let res = await getListUserApplyRecruit(
        recruitInfo?.id ? recruitInfo?.id : detailCvId?.id,
        'POST',
        {
          account_information: cvSelect
            ? cvSelect?.account_information
            : JSON.stringify(data.account_information),
          template: cvSelect ? cvSelect?.template : data.template
        } as any
      );
      if (res.status === 200) {
        dispatch(submitRecruit());
        dispatch(notiMobileCvRecruit(true));
        setOpenAl &&
          setOpenAl({
            text: 'Nộp cv thành công',
            boolean: true,
            code: 200
          });
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
    } catch (error) {
      dispatch(notiMobileCvRecruit(false));
      setOpenAl &&
        setOpenAl({
          text: 'Nộp cv không thành công',
          boolean: true,
          code: 500
        });
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (meInfo?.id) {
      fetchListCV();
    }
  }, []);

  const fetchListCV = async () => {
    let res = await getListCV(meInfo?.id);
    if (res.status === 200) {
      setListCV(res.data);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf',
    maxFiles: 1,
    onDrop: (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = acceptedFiles.map((file, index) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: files?.length ? files?.length + 1 : index + 1
        })
      );
      let newFiles = fileAccepts.concat(filesState);
      setFiles(newFiles);
    },
    multiple: false
  });
  const formik = useFormik({
    initialValues: {
      title: 'Untitled CV',

      account_name: meInfo?.display_name,
      vacancies: '',

      birth_day: '',
      phone: '',
      email: '',
      address: '',

      career_goals: '',

      education: '',
      Schools_name: '',
      majors: '',
      startDate_endDate: '',

      work_experience: '',
      working_position: '',
      startDate_endDate_work_experience: '',
      name_cty: '',
      description_work_experience: '',

      project: '',
      startDate_endDateProject: '',
      descriptionProject: '',

      skill: '',

      work: '',

      prize: '',

      business_card: '',

      reference_person: '',

      hobby: '',
      themeCV: themeCV
    },
    validateOnChange: true,
    // validateOnBlur: true,
    validationSchema: validationSchema,

    onSubmit: values => {
      try {
        const { themeCV } = values;
        if (isUpdate && cvSelected?.id) {
          handleCreateUpdateCV(meInfo?.id, cvSelected?.id, themeCV);
        }
        if (createRecruitCV) {
          handleCreateUpdateCV(meInfo?.id, 'create', themeCV);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  React.useEffect(() => {
    if (isUpdate && cvSelected && Object.keys(cvSelected).length) {
      let account_information: any = JSON.parse(
        cvSelected?.account_information
      );
      formik.setValues({
        title: cvSelected?.title,
        ...account_information
      });
    }
  }, [JSON.stringify(cvSelected)]);

  const renderText = (field, label, styleText, styleField) => {
    return (
      <>
        <TextField
          hiddenLabel
          id="filled-hidden-label-small"
          name={field ?? 'field'}
          placeholder={label}
          variant="outlined"
          required
          fullWidth
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: field === 'title' ? '1pt dashed #77a5cc' : 'unset'
            },
            '& .MuiInputBase-root:hover': {
              outline: '1pt dashed #77a5cc'
            },
            '& .MuiInputBase-root:active': {
              outline: '1pt dotted #77a5cc'
            },
            marginTop: '10px',
            '& .MuiInputBase-input': {
              fontSize: '13px',
              padding: '1px',
              color: '#050505',
              ...styleText
            },
            '& .MuiInputBase-root': {
              borderRadius: 'unset',
              ...styleField
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: 'unset',
              padding: '0px !important'
            },
            '& .MuiFormHelperText-root': {
              marginLeft: 0
            },
            '& .Mui-error': {
              textAlign: 'right'
            }
          }}
          size="small"
          multiline
          InputLabelProps={{ shrink: false }}
          value={formik.values[field]}
          onChange={e => {
            // Không làm gì nếu kí tự đầu tiên là dấu cách
            const newValue = e.target.value;
            if (newValue.length === 1 && newValue[0] === ' ') {
              return;
            }
            formik.setFieldValue(field, newValue);
          }}
          error={Boolean(formik.errors[field])}
          helperText={
            Boolean(formik.errors[field]) ? formik.errors[field] : null
          }
        />
      </>
    );
  };

  const renderDots = () => {
    return (
      <Stack direction="row" spacing={1} className={classes.wrapColor}>
        <div
          className={classes.wrapDot}
          style={{
            backgroundColor: '#0a84ff'
          }}
        ></div>
        <div
          className={classes.wrapDot}
          style={{
            backgroundColor: '#00b14f'
          }}
        ></div>
        <div
          className={classes.wrapDot}
          style={{
            backgroundColor: '#ff9f0a'
          }}
        ></div>
        <div
          className={classes.wrapDot}
          style={{
            backgroundColor: '#ff453a'
          }}
        ></div>
      </Stack>
    );
  };

  const renderBlock = () => {
    return (
      <Grid
        container
        sx={{
          width: '100%',
          marginTop: '0px',
          padding: 5,
          display: 'flex',
          flexDirection: 'column'
        }}
        spacing={2}
      >
        {buttunOption.map(item => (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              pading: '4px',
              width: '100%',
              '&:hover': {
                '& .iconClose': {
                  display: 'block'
                }
              }
            }}
          >
            {/* <Box
              className="iconClose"
              sx={{
                position: 'absolute',
                top: 0,
                right: 20,
                cursor: 'pointer',
                display: 'none'
              }}
              onClick={() => handleOnOption(item)}
            >
              <i
                className="fa-solid fa-x"
                style={{ fontSize: 16, color: '#d3cfcf' }}
              ></i>
            </Box> */}
            {item?.label !== 'Ảnh đại diện' && (
              <div>
                <Typography
                  className={classes.wrapTitle}
                  sx={{
                    backgroundColor: formik?.values?.themeCV,
                    '&.MuiTypography-root': {
                      marginTop: '0'
                    }
                  }}
                  gutterBottom
                >
                  ● {item?.label}
                </Typography>
              </div>
            )}
            {item?.label === 'Ảnh đại diện' && (
              <div>
                <AvatarSocial
                  avatarObj={meInfo?.avatar_media ?? meInfo?.avatar_static}
                  style={{
                    width: 170,
                    height: 170,
                    margin: 0
                  }}
                />
              </div>
            )}
            <Grid item xs={12}>
              {item?.renderTexts &&
                item?.renderTexts.map(itemrenderTexts => (
                  <Box className={classes.boxIcon}>
                    <div className={classes.divicon}>
                      {itemrenderTexts?.icon}
                    </div>
                    {renderText(
                      itemrenderTexts?.field,
                      itemrenderTexts?.placeholder,
                      itemrenderTexts?.styleText,
                      itemrenderTexts?.styleField
                    )}
                  </Box>
                ))}
            </Grid>
          </Grid>
        ))}
        {openNotiCv && (
          <DialogConfirmDelete
            title="Xóa nội dung"
            text="Bạn có chắc muốn xóa nội dung này?"
            open={openNotiCv}
            handleClose={() => setOpenNotiCv(false)}
            action={() => handleAddOrDeleteButtonOption(OptionItem, 'delete')}
            labelButton="Tôi đồng ý"
          />
        )}
      </Grid>
    );
  };
  const renderPreviewCV = (value: any) => {
    return (
      <Grid
        container
        sx={{
          width: '100%',
          marginTop: '0px',
          padding: 5,
          display: 'flex',
          flexDirection: 'column'
        }}
        spacing={2}
      >
        <Grid
          container
          xs={12}
          md={12}
          sx={{
            marginTop: '20px',
            position: 'relative',
            width: '100%'
          }}
        >
          {value?.account?.avatar_media && (
            <div>
              <AvatarSocial
                avatarObj={value?.account?.avatar_media}
                style={{
                  width: 170,
                  height: 170,
                  margin: 0
                }}
              />
            </div>
          )}
          {(value?.account_information?.account_name ||
            value?.account_information?.vacancies) && (
            <Grid item xs={12}>
              <Typography
                className={classes.nameCv}
                sx={{ fontWeight: '700 !important' }}
              >
                {value?.account_information?.account_name}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.vacancies}
              </Typography>
            </Grid>
          )}
        </Grid>
        {(value?.account_information?.birth_day ||
          value?.account_information?.phone ||
          value?.account_information?.email ||
          value?.account_information?.address) && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Thông tin cá nhân
              </Typography>
            </div>
            <Grid item xs={12}>
              {value?.account_information?.birth_day && (
                <Box className={classes.boxIcon}>
                  <div className={classes.divicon}>
                    <i
                      className="fa-solid fa-calendar"
                      style={{ fontSize: 14, color: '#333' }}
                    ></i>
                  </div>
                  <Typography className={classes.nameCv}>
                    {value?.account_information?.birth_day}
                  </Typography>
                </Box>
              )}
              {value?.account_information?.phone && (
                <Box className={classes.boxIcon}>
                  <div className={classes.divicon}>
                    <i
                      className="fa-solid fa-phone"
                      style={{ fontSize: 14, color: '#333' }}
                    ></i>
                  </div>
                  <Typography className={classes.nameCv}>
                    {value?.account_information?.phone}
                  </Typography>
                </Box>
              )}
              {value?.account_information?.email && (
                <Box className={classes.boxIcon}>
                  <div className={classes.divicon}>
                    <i
                      className="fa-solid fa-envelope"
                      style={{ fontSize: 14, color: '#333' }}
                    ></i>
                  </div>
                  <Typography className={classes.nameCv}>
                    {value?.account_information?.email}
                  </Typography>
                </Box>
              )}
              {value?.account_information?.address && (
                <Box className={classes.boxIcon}>
                  <div className={classes.divicon}>
                    <i
                      className="fa-solid fa-location-dot"
                      style={{ fontSize: 14, color: '#333' }}
                    ></i>
                  </div>
                  <Typography className={classes.nameCv}>
                    {value?.account_information?.address}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
        {value?.account_information?.career_goals && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Mục tiêu nghè nghiệp
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.career_goals}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(value?.account_information?.education ||
          value?.account_information?.Schools_name ||
          value?.account_information?.majors ||
          value?.account_information?.startDate_endDate) && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Học vấn
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.education}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.Schools_name}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.majors}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.startDate_endDate}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(value?.account_information?.name_cty ||
          value?.account_information?.work_experience ||
          value?.account_information?.working_position ||
          value?.account_information?.startDate_endDate_work_experience ||
          value?.account_information?.description_work_experience) && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Kinh nghiệm làm việc
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.name_cty}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.work_experience}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.working_position}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.startDate_endDate_work_experience}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.description_work_experience}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(value?.account_information?.project ||
          value?.account_information?.startDate_endDateProject ||
          value?.account_information?.descriptionProject) && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Project
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.project}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.startDate_endDateProject}
              </Typography>
              <Typography className={classes.nameCv}>
                {value?.account_information?.descriptionProject}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.skill && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Kỹ năng
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.skill}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.work && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Hoạt động
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.work}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.prize && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Giải thưởng
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.prize}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.business_card && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Danh thiếp
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.business_card}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.reference_person && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Người tham chiếu
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.reference_person}
              </Typography>
            </Grid>
          </Grid>
        )}
        {value?.account_information?.hobby && (
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              marginTop: '20px',
              position: 'relative',
              width: '100%'
            }}
          >
            <div>
              <Typography
                className={classes.wrapTitle}
                sx={{
                  backgroundColor: formik?.values?.themeCV,
                  '&.MuiTypography-root': {
                    marginTop: '0'
                  }
                }}
                gutterBottom
              >
                ● Sở thích
              </Typography>
            </div>
            <Grid item xs={12}>
              <Typography className={classes.nameCv}>
                {value?.account_information?.hobby}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  const handleCreateUpdateCV = async (
    idUser: any,
    type: any,
    themeCV: string
  ) => {
    try {
      let data = {
        ...formik.values,
        themeCV
      };

      let response = await createUpdateCV(
        {
          title: formik.values.title || 'Untitled CV',
          account_information: JSON.stringify(data),
          template:
            'https://www.topcv.vn/images/cv/screenshots/thumbs/cv-template-thumbnails-v1.2/chrome.png?v=1.0.4'
        },
        idUser,
        type
      );
      if (response.status === 200) {
        if (isUpdate) {
          setIsLoading(false);
          setIsUpdate(false);
          setOpen(false);
          if (!recruitInfo?.id) {
            setListCVSaved(prev => {
              const newListCV = prev;
              newListCV[prev.findIndex(el => el.id === cvSelected.id)] =
                response.data;
              return newListCV;
            });
            history.push('/recruits/cvemso');
          }
        } else if (createRecruitCV) {
          if (setListCVSaved) {
            setListCVSaved(prev => [...prev, response.data]);
          }
          setIsLoading(false);
          setCreateRecruitCV(false);
          setTimeout(() => {
            handleClose();
          }, 1000);
          if (!recruitInfo?.id) {
            history.push('/recruits/cvemso');
          }
        }
        setOpenAl &&
          setOpenAl({ text: 'Lưu cv thành công', boolean: true, code: 200 });
      }
    } catch (error: any) {
      setOpenAl &&
        setOpenAl({
          text: `${error.response.data.error}, vui lòng đổi tên khác`,
          boolean: true,
          code: 500
        });
      setOpen(null);
    }
  };

  const fakeMedia = [
    {
      id: 'basic1',
      title: 'Mẫu CV Basic Tinh tế',
      template:
        'https://www.topcv.vn/images/cv/screenshots/vi/mau-cv-cv_color.png?v=1.0.1',
      chip1: 'Màu sắc',
      chap2: 'Sáng tạo'
    },
    {
      id: 'chrome1',
      title: 'Mẫu CV Chrome',
      template:
        'https://www.topcv.vn/images/cv/screenshots/thumbs/cv-template-thumbnails-v1.2/chrome.png?v=1.0.4',
      chip1: 'Chuyên nghiệp',
      chap2: 'Thanh lịch'
    },
    {
      id: 'it1',
      title: 'Mẫu CV Lập trình viên',
      template:
        'https://www.topcv.vn/images/cv/screenshots/vi/mau-cv-basic_5.png?v=1.0.1',
      chip1: 'Chuyên nghiệp',
      chap2: 'Sáng tạo'
    }
  ];
  const renderContent = () => {
    return (
      <Grid
        container
        style={{
          width: '70%',
          margin: '0px auto',
          backgroundColor: '#fff',
          borderRadius: '10px'
        }}
      >
        <Grid
          item
          sx={{
            width: '100%',
            margin: '0px auto 0px auto'
          }}
          xs={12}
        >
          {renderText(
            'title',
            'Tiêu đề CV',
            {
              textAlign: 'center',
              fontWeight: 400,
              fontSize: 22
            },
            {}
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            width: '100vw',
            height: '100vh',
            margin: '0px auto',
            backgroundImage: `url(${backgroundCV})`,
            backgroundSize: '100% 100%',
            boxShadow: '0 0 1px rgb(0 0 0 / 25%)',
            position: 'relative',
            borderRadius: '10px'
          }}
        >
          <Box
            style={{
              position: 'absolute',
              width: '100%',
              backgroundImage: `url(${backgroundCV})`
            }}
          >
            {renderBlock()}
            <Grid
              item
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center'
              }}
              xs={12}
            >
              <ButtonCustom
                label="Nộp CV"
                style={{
                  color: '#fff',
                  backgroundColor: buttonColor.backgroundColor,
                  width: '200px',
                  margin: '0 30px 30px 0'
                }}
                action={() => {
                  history.push(`/recruit/${detailCvId?.id}/about`);
                  handleActionApplyCV();
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const renderSelectCV = () => {
    return (
      <>
        <DialogContent
          dividers
          sx={{ width: '100%', height: 'calc(100% - 123px)' }}
        >
          <div style={{ width: '100%', margin: '0px auto' }}>
            <Grid container lg={12} md={12} xs={12} spacing={4}>
              {fakeMedia.map(el => (
                <Grid item lg={4} md={4} xs={12}>
                  <Card className={classes.wrapCard} onClick={() => setOpen(2)}>
                    <CardMedia
                      component="img"
                      image={el?.template}
                      alt="account-cv"
                      className={classes.cardMediaCv}
                    />
                    <CardContent
                      sx={{
                        flex: 1
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography align="center" className={classes.wrapChip}>
                          {el?.chip1}
                        </Typography>
                        <Typography align="center" className={classes.wrapChip}>
                          {el?.chap2}
                        </Typography>
                      </Stack>
                      <Typography className={classes.wrapText}>
                        {el?.title}
                      </Typography>
                      {renderDots()}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
      </>
    );
  };
  const renderCreateCV = () => {
    const handleThemeChange = (newTheme: string) => {
      setThemeCV(newTheme);
      formik.setFieldValue('themeCV', newTheme);
    };

    const isDisable = (values, validationSchema) => {
      return Object.keys(values).every(key => {
        const fieldValue = values[key];
        const isRequiredField =
          validationSchema.fields[key]?.exclusiveTests?.required;

        if (isRequiredField) {
          return fieldValue && fieldValue.trim() !== '';
        }
        return true;
      });
    };
    return (
      <>
        <DialogContent
          dividers
          sx={{
            width: '100%',
            height: 'calc(100% - 123px)'
          }}
        >
          <div
            style={{
              width: '793.7px',
              margin: '0px auto',
              backgroundColor: '#fff',
              borderRadius: '10px'
            }}
          >
            <div
              style={{
                width: '793.7px',
                margin: '0px auto',
                borderRadius: '10px'
              }}
            >
              <Stack
                direction="row"
                sx={{ justifyContent: 'flex-end', padding: '8px' }}
              >
                <div className={classes.wrapButtonEdit}>
                  <div style={{ color: '#050505' }}>Tông màu</div>
                  <Stack direction="row" spacing={1}>
                    <i
                      className="fa-solid fa-circle"
                      style={{ fontSize: 22, color: '#FF823C' }}
                      onClick={() => handleThemeChange('#FF823C')}
                    ></i>
                    <i
                      className="fa-solid fa-circle"
                      style={{ fontSize: 22, color: '#40BA77' }}
                      onClick={() => handleThemeChange('#40BA77')}
                    ></i>
                    <i
                      className="fa-solid fa-circle"
                      style={{ fontSize: 22, color: '#5D5FEF' }}
                      onClick={() => handleThemeChange('#5D5FEF')}
                    ></i>
                  </Stack>
                </div>
                <div
                  className={classes.wrapButtonEdit}
                  onClick={() => setOpen(1)}
                >
                  <div style={{ color: '#050505' }}>Đổi mẫu CV</div>
                  <i
                    className="fa fa-files"
                    style={{ fontSize: 26, color: '#666' }}
                  ></i>
                </div>
              </Stack>
            </div>
            <Grid
              sx={{
                width: '793.7px',
                margin: '0px auto 0px auto'
              }}
            >
              {renderText(
                'title',
                'Tiêu đề CV',
                {
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: 22
                },
                {}
              )}
            </Grid>
            <div
              style={{
                width: '793.7px',
                height: '100%',
                margin: '0px auto',
                backgroundImage: `url(${backgroundCV})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                boxShadow: '0 0 1px rgb(0 0 0 / 25%)',
                position: 'relative',
                borderRadius: '10px'
              }}
            >
              <div
                style={{
                  // position: 'absolute',
                  width: '100%'
                }}
              >
                {renderBlock()}
              </div>
            </div>
            {openOptionCv && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: ' rgba(0, 0, 0, 0.1)',
                  zIndex: 1100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    width: '530px',
                    height: '540px',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(0,0,0,0.04)',
                    zIndex: 1100,
                    padding: '16px',
                    borderRadius: '6px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '57px',
                      borderBottom: '1px solid #eee'
                    }}
                  >
                    <Typography variant="h6">Thêm mục</Typography>
                    <div
                      style={{ padding: 10, cursor: 'pointer' }}
                      onClick={() => handleOffOptionCv('offOptionCv')}
                    >
                      <i className="fa-solid fa-x" style={{ fontSize: 16 }}></i>
                    </div>
                  </div>
                  <div
                    className={classes.scroll}
                    style={{
                      maxHeight: '400px',
                      overflow: 'hidden',
                      overflowY: 'auto'
                    }}
                  >
                    <div style={{ marginBottom: '20px' }}>
                      <Box sx={{ margin: '0 0 20px 5px' }}>
                        <Typography className={classes.textPlusCv}>
                          Mục chưa sử dụng
                        </Typography>
                      </Box>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          gap: '10px 12px'
                        }}
                      >
                        {notUsedYet.map(item => (
                          <ButtonInherit
                            label={item?.label}
                            icon={item?.icon}
                            style={{
                              marginRight: 0,
                              marginLeft: 5,
                              width: 235,
                              borderRadius: '6px',
                              height: '40px',
                              display: 'flex',
                              justifyContent: 'start',
                              background: '#f4f4f4',
                              color: '#999999',
                              '&:hover': {
                                background: '#e5f7ed !important',
                                color: '#00b14f !important'
                              }
                            }}
                            action={() =>
                              handleAddOrDeleteButtonOption(item, 'add')
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <Box sx={{ margin: '0 0 20px 5px' }}>
                        <Typography className={classes.textPlusCv}>
                          Mục đã sử dụng
                        </Typography>
                      </Box>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          gap: '10px 12px'
                        }}
                      >
                        {buttunOption.map(item => (
                          <ButtonInherit
                            label={item?.label}
                            icon={item?.icon}
                            disabled
                            style={{
                              marginRight: 0,
                              marginLeft: 5,
                              width: 235,
                              borderRadius: '6px',
                              height: '40px',
                              justifyContent: 'start',
                              background: '#f4f4f4',
                              color: '#999999'
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: 5
            }}
          >
            <ButtonInherit
              label="Hủy"
              // color={buttonColor.backgroundColor}
              style={{
                marginRight: 0,
                marginLeft: 5
                // '&:hover': {
                //   backgroundColor: buttonColorHover.backgroundColor
                // }
              }}
              action={() => {
                setOpenDialogConfirmation(true);
              }}
            />
            <ButtonInherit
              label="Tiếp"
              color={buttonColor.backgroundColor}
              style={{
                marginRight: 0,
                marginLeft: '10px',
                '&:hover': {
                  backgroundColor: buttonColorHover.backgroundColor
                }
              }}
              action={() => {
                setOpen(3);
              }}
              disabled={
                !formik.isValid || !isDisable(formik.values, validationSchema)
              }
            />
          </div>
        </DialogActions>
      </>
    );
  };
  const renderItem = () => {
    return (
      <>
        <InfoCv
          ref={componentRef}
          recruitInfo={recruitInfo}
          cvSelect={cvSelect}
          formik={formik}
          renderPreviewCV={renderPreviewCV}
          meInfo={meInfo}
        />

        <DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: 5
            }}
          >
            {recruitInfo?.recruit_relationships?.host_recruit ? null : (
              <ButtonInherit
                label={recruitInfo?.id && cvSelect ? 'Hủy' : 'Cập nhật'}
                // color={buttonColor.backgroundColor}
                style={{
                  marginRight: 0,
                  marginLeft: 5
                  // '&:hover': {
                  //   backgroundColor: buttonColorHover.backgroundColor
                  // }
                }}
                action={() => {
                  if (recruitInfo?.id && cvSelect) {
                    setOpen(4);
                  } else {
                    setIsLoading(false);
                    setOpen(2);
                  }
                }}
              />
            )}
            {isLoading ? (
              <LoadingButton
                loading
                variant="contained"
                color="secondary"
                sx={{ marginRight: 0, marginLeft: 1 }}
              >
                Lưu
              </LoadingButton>
            ) : recruitInfo?.recruit_relationships?.host_recruit ? (
              <>
                <ButtonInherit
                  label="Nhắn tin"
                  style={{ backgroundColor: 'button.primary.background' }}
                  action={() => {
                    const existsPopup = listPopupChat.find(el =>
                      el.conversation.rid.includes(cvSelect?.account?.id)
                    );
                    if (!existsPopup) {
                      handleChatNow(cvSelect?.account, rocketId, dispatch);
                      handleClose();
                    }
                  }}
                />
                <ReactToPrint
                  content={renderCv}
                  trigger={reactToPrintTrigger}
                  removeAfterPrint
                />
              </>
            ) : (
              <ButtonInherit
                label={recruitInfo?.id && cvSelect ? 'Nộp CV' : 'Lưu'}
                color={buttonColor.backgroundColor}
                style={{
                  marginRight: 0,
                  marginLeft: '10px',
                  '&:hover': {
                    backgroundColor: buttonColorHover.backgroundColor
                  }
                }}
                action={() => {
                  if (recruitInfo?.id && cvSelect) {
                    setIsLoading(true);
                    handleActionApplyCV();
                  } else {
                    setIsLoading(true);
                    if (!isUpdate) {
                      setCreateRecruitCV && setCreateRecruitCV(true);
                    }
                    formik.submitForm();
                  }
                }}
              />
            )}
          </div>
        </DialogActions>
      </>
    );
  };
  const renderSelectMyCV = () => {
    return (
      <>
        <DialogContent
          dividers
          sx={{ width: '100%', height: 'calc(100% - 123px)' }}
        >
          <div style={{ width: '100%', margin: '0px auto' }}>
            {listCV?.length ? (
              <Grid container lg={12} md={12} xs={12} spacing={4}>
                {listCV.map((item: any) => {
                  return (
                    <Grid item lg={4} md={4} xs={4}>
                      <Card
                        className={classes.wrapCard}
                        onClick={() => {
                          setCVSelect(item);
                          setOpen(3);
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={item?.template}
                          alt="account-cv"
                          className={classes.cardMediaCv}
                        />
                        <CardContent
                          sx={{
                            flex: 1
                          }}
                        >
                          <Stack direction="row" spacing={1}>
                            <Typography
                              align="center"
                              className={classes.wrapChip}
                            >
                              Màu sắc
                            </Typography>
                            <Typography
                              align="center"
                              className={classes.wrapChip}
                            >
                              Sáng tạo
                            </Typography>
                          </Stack>
                          <Typography className={classes.wrapText}>
                            {limitText(item?.title, 52)}
                          </Typography>
                          {renderDots()}
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid className={classes.noCv} flexDirection="column">
                <img
                  src={calendar}
                  style={{ height: '150px', width: '150px' }}
                  alt="img"
                />
                <Typography sx={{ color: '#717171', margin: '8px' }}>
                  Hiện bạn chưa có CV nào
                </Typography>
                <Box>
                  <ButtonCustom
                    label="Tạo CV"
                    style={{
                      color: '#fff',
                      backgroundColor: buttonColor.backgroundColor,
                      width: '200px',
                      margin: '5px 0'
                    }}
                    action={() => setOpen(1)}
                  />
                  <ButtonCustom
                    label="Tải CV lên"
                    style={{
                      color: '#fff',
                      backgroundColor: buttonColor.backgroundColor,
                      width: '200px',
                      margin: '5px 0'
                    }}
                    action={() => {
                      setOpen(6);
                    }}
                  />
                </Box>
              </Grid>
            )}
          </div>
        </DialogContent>
      </>
    );
  };
  const renderPreview = () => {
    return (
      <>
        <DialogContent
          dividers
          sx={{ width: '100%', height: 'calc(100% - 123px)' }}
        >
          <div></div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: 5
            }}
          >
            <ButtonInherit
              label="3"
              // color={buttonColor.backgroundColor}
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                setOpen(3);
              }}
            />
            <ButtonInherit
              label="Xong"
              color={buttonColor.backgroundColor}
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                setOpen(3);
              }}
            />
          </div>
        </DialogActions>
      </>
    );
  };
  const renderUploadCV = () => {
    return (
      <>
        <DialogContent
          dividers
          sx={{ width: '100%', height: 'calc(100% - 123px)' }}
        >
          <Box className={classes.wrapDrag}>
            <div
              {...getRootProps({ className: 'dropzone' })}
              className={classes.wrapUpload}
            >
              <div className={classes.wrapRoot}>
                {files.length > 0 ? (
                  <ImageRender
                    type="create-upload"
                    images={files?.map((el: any) => {
                      return el.preview;
                    })}
                    imagesType={files?.map((el: any) => el.type)}
                    medias={files}
                    typeAction={type}
                  />
                ) : (
                  <>
                    <Box className={classes.wrapIconText}>
                      <i className="fas fa-file-plus fa-lg"></i>
                    </Box>
                    <Box className={classes.wrapText}>
                      <Typography className={classes.text}>Thêm CV</Typography>
                      <Typography className={classes.subText}>
                        hoặc kéo và thả
                      </Typography>
                    </Box>
                    <input {...getInputProps()} />
                  </>
                )}
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: 5
            }}
          >
            <ButtonInherit
              label="Hủy"
              // color={buttonColor.backgroundColor}
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                handleClose();
              }}
            />
            <ButtonInherit
              label="Xong"
              color={buttonColor.backgroundColor}
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                setOpen(3);
              }}
            />
          </div>
        </DialogActions>
      </>
    );
  };
  const renderPoper = () => {
    switch (open) {
      case 1:
        return renderSelectCV();
      case 2:
        return renderCreateCV();
      case 3:
        return renderItem();
      case 4:
        return renderSelectMyCV();
      case 5:
        return renderPreview();
      case 6:
        return renderUploadCV();
      default:
        return;
    }
  };
  const titleRender = () => {
    switch (open) {
      case 1:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Chọn mẫu CV
          </Typography>
        );
      case 2:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Tạo CV
          </Typography>
        );
      case 3:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Xem trước CV
          </Typography>
        );
      case 4:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Chọn CV của bạn
          </Typography>
        );
      case 5:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Xem trước
          </Typography>
        );
      case 6:
        return (
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Tải CV của bạn lên
          </Typography>
        );
      default:
        return;
    }
  };
  const handleCloseDialog = (event, reason) => {
    if (reason === 'backdropClick') {
      setOpenDialogConfirmation(true);
    } else {
      handleClose();
    }
  };
  return (
    <div>
      {type !== 'seen' ? (
        <BootstrapDialog
          fullWidth
          open={open}
          onClose={handleCloseDialog}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          BackdropProps={modalBackdrop}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            back={open === 2 ? () => setOpen(1) : undefined}
          >
            {titleRender()}
          </BootstrapDialogTitle>
          {renderPoper()}
        </BootstrapDialog>
      ) : (
        renderContent()
      )}
      {openDialogConfirmation && (
        <DialogConfirmation
          title={'Rời khỏi trang ?'}
          text={
            'Bài viết của bạn chưa được lưu thông tin, bạn có xác nhận rời khỏi không?'
          }
          open={openDialogConfirmation}
          handleClose={() => {
            setOpenDialogConfirmation(false);
          }}
          action={() => {
            setOpenDialogConfirmation(false);
            setOpen(1);
          }}
          alignText={'center'}
          labelButton={'Rời khỏi'}
        />
      )}
    </div>
  );
}
