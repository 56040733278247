import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { DeleteOutlined } from '@mui/icons-material';

import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  OutlinedInput,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { Theme, styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { collapseString } from 'src/common/string';
import {
  addLabelIntoChatPage,
  addLabelPage,
  getTeamInfoById,
  removeLabelChatPage,
  removeLabelPage
} from 'src/apis/socialChat.apis';
import {
  subArray,
  colorLabelOptions
} from '../../FullScreenChat/InfoUserChatPage';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  addLabelConversationChatPage,
  removeLabelConversationChatPage,
  removeLabelTotalPage
} from 'src/store/action/socialChatAction';
const useStyles = makeStyles((theme: Theme) => ({
  styleHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '10px'
  },
  styleBoxAvatar: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flex: 1,
    width: '100%'
  },
  styleItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '10px',
    background: 'transparent',
    textTransform: 'none',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#dddddd80'
    },
    cursor: 'pointer'
  },
  styleAddLabel: {
    display: 'flex',
    gap: 2,
    width: '100%',
    justifyContent: 'center',
    height: '100%'
  }
}));

interface ChipData {
  color: string;
  title: string;
}

export default function DialogAddLabel(props) {
  const {
    open,
    setOpen,
    conversation,
    pageId,
    setIsToastMessage,
    setCodeMessage,
    setTitleToastMessage
  } = props;

  const { label } = conversation;
  const inputRef = React.useRef<any>(null);
  const [listLabelPage, setListLabelPage] = React.useState<any>([]);

  const [labelConversation, setLabelConversation] = React.useState<any>(
    label || []
  );
  const [titleLabel, setTitleLabel] = React.useState<string>('');
  const [colorLabel, setColorLabel] = React.useState<string>('#63BE09');
  const [anchorElPopover, setAnchorElPopover] =
    React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const openPopover = Boolean(anchorElPopover);

  const idPopover = openPopover ? 'simple-popover' : undefined;
  const theme = useTheme();
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const getLabelTeamInfo = async () => {
    try {
      const response = await getTeamInfoById(pageId);
      if (response.status === 200) {
        const labelPage = response.data.teamInfo?.label || [];
        if (label) {
          setListLabelPage(subArray(labelPage, labelConversation));
        } else {
          setListLabelPage(labelPage);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  React.useEffect(() => {
    if (pageId) {
      getLabelTeamInfo();
    }
  }, []);

  const handleAddLabelSuggestion = async label => {
    try {
      const roomId = conversation.rid;
      const response = await addLabelIntoChatPage(roomId, label);
      if (response.status === 200) {
        setLabelConversation([...labelConversation, label]);
        setListLabelPage(
          listLabelPage.filter(item => item.title !== label.title)
        );
        dispatch(addLabelConversationChatPage(roomId, label));
      }
    } catch (error) {
      // console.log({ error });
    }
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13 && titleLabel) {
      // handleAddLabelIntoConversations();
      handleAddLabelPage();
    }
  };

  const handleInputTitleLabel = e => {
    if (e.target.value.length <= 20) {
      setTitleLabel(e.target.value);
    }
  };
  const hasNonWhitespaceCharacter = (text: string) => {
    return text.trim().length > 0;
  };

  const handleAddLabelPage = async () => {
    try {
      const label = {
        color: colorLabel,
        title: titleLabel
      };
      if (titleLabel) {
        const response = await addLabelPage(pageId, label);
        if (response.status === 200) {
          const updatedLabelPage = response?.data?.teamNew?.label.filter(
            newLabel => !labelConversation.some(label => label.title === newLabel.title)
          );
          setListLabelPage(updatedLabelPage);
          setTitleLabel('');
          inputRef.current.focus();
          setIsToastMessage(true);
          setCodeMessage(200);
          setTitleToastMessage('Thêm nhãn mới thành công.');
        }
      }
    } catch (error: any) {
      setIsToastMessage(true);
      setCodeMessage(400);
      setTitleToastMessage(error?.response?.data?.error);
    }
  };

  const handleDelete = async (chipToDelete: ChipData) => {
    try {
      const roomId = conversation.rid;
      const response = await removeLabelChatPage(roomId, chipToDelete);
      if (response.status === 200) {
        setLabelConversation(
          labelConversation.filter(chip => chip.title !== chipToDelete.title)
        );
        setListLabelPage([...listLabelPage, chipToDelete]);
        dispatch(removeLabelConversationChatPage(roomId, chipToDelete));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteOptionLable = chipToDelete => async () => {
    try {
      const response = await removeLabelPage(pageId, chipToDelete);
      if (response.status === 200) {
        setListLabelPage(response.data.team.label);

        dispatch(removeLabelTotalPage(chipToDelete));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const LabelConversation = props => {
    const { label } = props;

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          paddingTop: '4px',
          flexWrap: 'wrap',
          flex: 2
        }}
      >
        {label && labelConversation.length > 0 && labelConversation.length < 10
          ? labelConversation.slice(0, 10).map(el => (
              <Box
                key={el.title}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  // background: 'rgba(221, 221, 221, 0.1)',
                  borderRadius: '6px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    padding: '1px 10px',
                    borderRadius: '4px',
                    border: `0.5px solid ${el.color}`,
                    color:
                      theme.palette.mode === 'dark'
                        ? el.color === '#90949c'
                          ? '#c8c8c8'
                          : el.color
                        : `${el.color}`,
                    backgroundColor: `${el.color}20`
                  }}
                >
                  {el.title}
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => handleDelete(el)}
                >
                  <Delete
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? el.color === '#90949c'
                            ? '#c8c8c8'
                            : el.color
                          : `${el.color}`
                    }}
                  />
                </IconButton>
                </Typography>
              </Box>
            ))
          : labelConversation.slice(0, 9).map(el => (
              <Box
                key={el.title}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  // background: '#dddddd80',
                  borderRadius: '6px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    padding: '0 10px',
                    borderRadius: '4px',
                    border: `0.5px solid ${el.color}`,
                    color: `${el.color}`,
                    backgroundColor: `${el.color}20`
                  }}
                >
                  {el.title}
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => handleDelete(el)}
                >
                  <Delete sx={{ color: el.color }} />
                </IconButton>
                </Typography>
              </Box>
            ))}
        {label && labelConversation.length > 10 ? (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              padding: '4px 10px',
              backgroundColor: theme.palette.mode === 'light' ? '#ddd' : 'ccc',
              border: `0.5px solid `,
              borderRadius: '4px'
            }}
          >
            +{labelConversation.length - 9} nhãn khác
          </Typography>
        ) : null}
      </Box>
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.styleHeader}>
        <Box className={classes.styleBoxAvatar}>
          <AvatarSocial
            type="feed"
            style={{
              width: 50,
              height: 50
            }}
            avatarObj={conversation.avatar_media}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            {conversation?.u?.name}
          </Typography>
        </Box>
        {conversation && conversation.label ? (
          <LabelConversation label={conversation.label} />
        ) : (
          <Box sx={{ display: 'none' }}></Box>
        )}
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          minHeight: '200px',
          maxHeight: '400px'
        }}
      >
        <div style={{ overflowY: 'auto' }}>
        {listLabelPage?.length > 0 &&
          listLabelPage?.map((option: any, index) => (
            <Box>
              <ListItem
                key={`${option.color}${index}`}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={handleDeleteOptionLable(option)}
                  >
                    <DeleteOutlined
                      style={{
                        color: option.color,
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  sx={{ padding: '6px 8px' }}
                  onClick={() => handleAddLabelSuggestion(option)}
                >
                  <Chip
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiChip-label': {
                        fontSize: '15px',
                        fontWeight: 450
                      }
                    }}
                    icon={
                      <LocalOfferIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          color: `${option.color} !important`
                        }}
                      />
                    }
                    label={option.title}
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
        </div>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 600, padding: '4px 4px 8px 4px' }}
        >
          Thêm nhãn mới
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="standard-adornment-label">Tên nhãn</InputLabel>
          <OutlinedInput
            ref={inputRef}
            id="standard-adornment-label"
            label="Tên nhãn"
            onChange={e => handleInputTitleLabel(e)}
            onKeyDown={e => handleOnKeyDown(e)}
            value={titleLabel}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle label visibility"
                  sx={{ paddingRight: 2 }}
                  aria-describedby={idPopover}
                  onClick={(event: any) => {
                    setAnchorElPopover(event.currentTarget);
                  }}
                >
                  <LocalOfferIcon
                    sx={{
                      width: '18px',
                      height: '18px',
                      color: `${colorLabel} !important`
                    }}
                  />
                </IconButton>
                <Popover
                  id={idPopover}
                  open={openPopover}
                  anchorEl={anchorElPopover}
                  onClose={() => {
                    setAnchorElPopover(null);
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  style={{ display: 'flex' }}
                >
                  {colorLabelOptions?.map(option => (
                    <Tooltip
                      key={option.color}
                      title={option.title}
                      arrow
                      placement="left-start"
                    >
                      <IconButton
                        onClick={() => {
                          setColorLabel(option.color);
                          setAnchorElPopover(null);
                        }}
                      >
                        <LocalOfferIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            color: `${option.color} !important`
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ))}
                </Popover>
              </InputAdornment>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAddLabelPage}
          sx={{ textTransform: 'none' }}
          disabled={!hasNonWhitespaceCharacter(titleLabel)}
        >
          Thêm nhãn
        </Button>
        <Button onClick={handleClose} sx={{ textTransform: 'none' }}>
          Trở ra
        </Button>
      </DialogActions>
    </Dialog>
  );
}
