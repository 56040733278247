import React from 'react';import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import imageQR from 'src/assets/images/QR_code.jpeg';
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex'
    },
    boxTitle: {
      display: 'flex',
      alignItems: 'baseline'
    },
    boxBodyCenter: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    boxColor: {
      background: '#fff',
      width: '4px',
      height: '46px'
    },
    textTitleKey: {
      display: 'flex',
      fontWeight: 700,
      color: '#fff',
      paddingLeft: '10px'
    }
  })
);
const AboutUsCenter = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const screenIpad = useMediaQuery('(min-width:900px)');
  const screenIphone = useMediaQuery('(min-width:400px)');
  const { key } = match.params;

  const handleWidth = () => {
    switch (key) {
      case 'support':
      case 'investors': {
        return {
          maxWidth: screenIphone ? (screenIpad ? '1140px' : '600px') : '360px',
          width: '100%'
        };
      }
      case 'mission':
      case 'vision':
      case 'coreValues':
      case 'contact': {
        return {
          maxWidth: screenIphone ? (screenIpad ? '750px' : '500px') : '360px',
          width: '100%',
          height: '100%'
        };
      }
    }
  };

  const textCss = {
    textTransform: 'uppercase',
    fontSize: screenIphone ? (screenIpad ? '40px' : '20px') : '13px',
    fontWeight: 700,
    color: '#fff',
    marginTop: '40px'
  };
  const textChildrenCss = {
    fontSize: screenIphone ? (screenIpad ? '24px' : '18px') : '13px',
    fontWeight: 300,
    color: '#fff',
    marginTop: '40px'
  };
  const listOptionText = [
    {
      type: 'mission',
      textKey: 'Sứ mệnh',
      title: 'của chúng tôi là:',
      text: ' Xây dựng vì lợi ích cộng đồng phát triển vì lợi ích quốc gia - dân tộc',
      style: {
        paddingTop: '250px'
      },
      styleText: {
        ...textCss
      }
    },
    {
      type: 'vision',
      textKey: 'Tầm nhìn',
      title: 'của chúng tôi là:',
      text: ' Trở thành mạng xã hội mang lại nhiều giá trị - lợi ích nhất cho người việt',
      style: {
        paddingTop: '250px'
      },
      styleText: {
        ...textCss
      }
    },
    {
      type: 'coreValues',
      textKey: 'Gía trị cốt lõi',
      title: 'của chúng tôi là:',
      text: ' Kiên - Tốc - Trí - Tín - Nhân -Khiêm',
      style: {
        paddingTop: '250px'
      },
      styleText: {
        ...textCss
      }
    },
    {
      type: 'support',
      textKeyLast: 'Mạng xã hội vì người Việt',
      title: 'Chung tay xây dựng nên',
      textChildren1:
        '- Chúng tôi tin tưởng, Mạng xã hội EMSO sẽ mang lại nhiều giá trị lợi ích nhất cho người dùng Việt Nam. Hãy cùng chúng tôi xây nên mạng xã hội này bằng việc sáng tạo nên những nội dung hấp dẫn cho cộng đồng. Hãy cùng chúng tôi xây dựng nên mạng xã hội này bằng cách báo cáo những thông tin xấu độc.... Chúng tôi Cam kết sẽ dốc toàn bộ sức lực, trí tuệ để cùng tất cả các bạn xây nên một Mạng xã hội Của người Việt – do người Việt làm chủ và Vì lợi ích của người Việt',
      textChildren2:
        '- Chúng tôi cũng hy vọng sẽ nhận được sự hỗ trợ của các bạn về tài chính, chúng tôi trân trọng và biết ơn mọi sự đóng góp, ủng hộ của các bạn trong dự án này. Vui lòng ủng hộ chúng tôi theo số tài khoản:',
      bankInformation: 'Vietcombank: 0021000449070',
      cty: 'Công ty cổ phần EMSO Việt Nam',
      imageQR: imageQR,
      textChildren3: (
        <Typography sx={{ ...textChildrenCss }}>
          Mọi thông tin đóng góp từ các bạn, sẽ được chúng tôi công khai tại:{' '}
          <a href=" https://emso.vn" style={{ color: '#fff' }}>
            https://emso.vn/
          </a>{' '}
          Chúng tôi Cam kết sẽ sử dụng những đồng tiền các bạn ủng hộ đúng mục
          đích, mang lại nhiều giá trị hơn nữa cho Cộng đồng, tất cả vì lợi ích
          Quốc gia dân tộc
        </Typography>
      ),
      styleChildren: { ...textChildrenCss },
      style: {
        paddingTop: screenIpad ? '80px' : '120px'
      },
      styleTextBank: {
        fontSize: screenIphone ? (screenIpad ? '28px' : '18px') : '13px',
        fontWeight: '700',
        color: '#fff',
        display: 'flex',
        flexDirection: screenIphone ? '' : 'column',
        paddingLeft: screenIphone ? '10px' : ''
      }
    },
    {
      type: 'investors',
      textKeyLast: 'Mạng xã hội vì người Việt',
      title: 'Chung tay xây dựng nên',
      textChildren1:
        '- Chúng tôi tin tưởng Mạng xã hội EMSO sẽ mang lại nhiều lợi ích cho các Qũy đầu tư, các Tổ chức/ Cá nhân đầu tư cho dự án.',
      textChildren2:
        '- Chúng tôi luôn cởi mở, mong muốn được hợp tác với các Qũy/ Tổ chức/ Cá nhân mong muốn đồng hành cùng chúng tôi xây nên một Doanh nghiệp Mạnh và Bền vững',
      textChildren3: (
        <Typography sx={{ ...textChildrenCss }}>
          - Mọi thông tin đề nghị đầu tư, vui lòng gửi về:{' '}
          <a href="qhdt@emso.vn" style={{ color: '#fff' }}>
            qhdt@emso.vn
          </a>{' '}
        </Typography>
      ),
      styleChildren: { ...textChildrenCss },
      style: {
        paddingTop: '180px'
      }
    },
    {
      type: 'contact',
      title:
        'ĐỊA CHỈ:  Block 33 -35. Tầng 2A, Tòa A2, Thăng Long Garden 250 Minh Khai, Hai Bà Trưng, Hà nội.',
      // textChildren1: 'Điện thoại: 19000000',
      textChildren2: (
        <Typography sx={{ ...textChildrenCss }}>
          Email:{' '}
          <a style={{ color: '#fff' }}>
            info@emso.vn
          </a>{' '}
        </Typography>
      ),
      textChildren3: (
        <Typography sx={{ ...textChildrenCss }}>
          Website:{' '}
          <a href="https://emso.vn/about_us/mission" style={{ color: '#fff' }}>
            https://emso.vn/about_us/mission
          </a>{' '}
        </Typography>
      ),
      styleChildren: { ...textChildrenCss },
      style: {
        paddingTop: '180px'
      }
    }
  ];

  const renderBody = () => {
    switch (key) {
      case 'mission':
      case 'vision':
      case 'coreValues':
      case 'support':
      case 'investors':
      case 'contact': {
        return (
          <Grid>
            {listOptionText.map(
              (item, index) =>
                item?.type === key && (
                  <Box key={index}>
                    <Box className={classes.boxTitle} sx={item?.style}>
                      <Box
                        className={classes.boxColor}
                        sx={{
                          height: screenIphone
                            ? screenIpad
                              ? '46px'
                              : '30px'
                            : '20px'
                        }}
                      ></Box>
                      <Typography
                        className={classes.textTitleKey}
                        sx={{
                          fontSize: screenIphone
                            ? screenIpad
                              ? '28px'
                              : '20px'
                            : '13px',
                          paddingLeft: screenIphone
                            ? screenIpad
                              ? '10px'
                              : '8px'
                            : '4px'
                        }}
                      >
                        {item?.textKey}{' '}
                        {item?.title && (
                          <Typography
                            className={classes.textTitleKey}
                            sx={{
                              fontWeight: 300,
                              fontSize: screenIphone
                                ? screenIpad
                                  ? '28px'
                                  : '20px'
                                : '13px',
                              paddingLeft: screenIphone
                                ? screenIpad
                                  ? '10px'
                                  : '8px'
                                : '4px'
                            }}
                          >
                            {item?.title}
                          </Typography>
                        )}
                        {item?.textKeyLast && (
                          <Typography
                            className={classes.textTitleKey}
                            sx={{
                              fontSize: screenIphone
                                ? screenIpad
                                  ? '28px'
                                  : '20px'
                                : '13px',
                              paddingLeft: screenIphone
                                ? screenIpad
                                  ? '10px'
                                  : '8px'
                                : '4px'
                            }}
                          >
                            {item?.textKeyLast}{' '}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                    {item?.text && (
                      <Box>
                        <Typography sx={item?.styleText}>
                          {item?.text}
                        </Typography>
                      </Box>
                    )}
                    {item?.textChildren1 && (
                      <Box>
                        <Typography sx={item?.styleChildren}>
                          {item?.textChildren1}
                        </Typography>
                      </Box>
                    )}
                    {item?.textChildren2 && (
                      <Box>
                        <Typography sx={item?.styleChildren}>
                          {item?.textChildren2}
                        </Typography>
                      </Box>
                    )}
                    {(item?.bankInformation || item?.cty) && (
                      <Typography sx={item?.styleTextBank}>
                        {item?.bankInformation}{' '}
                        <Typography
                          sx={{
                            ...item?.styleTextBank,
                            textTransform: 'uppercase'
                          }}
                        >
                          {item?.cty}
                        </Typography>
                      </Typography>
                    )}
                    {item?.imageQR && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <img
                          src={item?.imageQR}
                          alt="image_QR"
                          style={{
                            width: '250px',
                            height: '250px',
                            objectFit: 'contain'
                          }} 
                        />
                      </div>
                    )}
                    {item?.textChildren3 && <Box>{item?.textChildren3}</Box>}
                  </Box>
                )
            )}
          </Grid>
        );
      }
    }
  };

  return <Grid sx={handleWidth()}>{renderBody()}</Grid>;
};

export default AboutUsCenter;
