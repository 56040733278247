import {
  Box,
  CardMedia,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import iconMusic from 'src/assets/images/iconFavorite/amnhac.png';
import iconGrows from 'src/assets/images/iconFavorite/goivon.png';
import iconCourse from 'src/assets/images/iconFavorite/hoctap.png';
import iconMoment from 'src/assets/images/iconFavorite/khoanhkhac.png';
import iconRecruits from 'src/assets/images/iconFavorite/tuyendung.png';
import DialogFavorite from 'src/components/Dialog/DialogConfirmDelete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { buttonColor } from 'src/constants/styles';

interface IconFavoriteProps {
  type: 'courses' | 'grows' | 'music' | 'recruits' | 'moment';
  styleContainer?: any;
}

const IconFavorite: React.FC<IconFavoriteProps> = ({
  type,
  styleContainer
}) => {
  const theme: any = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const styleBold = {
    fontWeight: 500,
    color: isDark ? buttonColor.newTextColor : 'initial'
  };

  const [openFavorite, setOpenFavorite] = React.useState<boolean>(false);
  const dataFavorite = {
    courses: {
      title: 'Không gian học tập',
      image: iconCourse,
      desc: [
        <>
          Chúng tôi tin tưởng Không gian học tập sẽ trở thành{' '}
          <b style={styleBold}>Kho tàng tri thức cho người Việt.</b> Với sứ mệnh{' '}
          <b style={styleBold}>Nâng tầm tri thức – Chinh phục tương lai</b> –
          Chúng tôi tin tưởng sẽ góp phần nhỏ bé vào khát vọng chung của dân
          tộc, đó là cùng nhau kiến tạo nên một xã hội tri thức.
        </>,
        <>
          Chúng tôi cũng tin tưởng Không gian học tập trên Mạng xã hội EMSO sẽ
          là kênh thu nhập thụ động vô cùng tiềm năng cho các thầy cô giáo,
          những người sẵn sàng chia sẻ kiến thức, tri thức của mình cho cộng
          đồng.
        </>,
        <>
          Chúng tôi cũng hy vọng, sẽ có nhiều khóa học miễn phí được các thầy cô
          chia sẻ trên Không gian học tập, giúp những người khát khao tri thức -
          nhưng chưa có điều kiện học tập có thể có thêm kênh học tập miễn phí,
          để cùng chung tay xây dựng nên một đất nước Việt Nam hùng cường, giàu
          mạnh trên nền tảng tri thức của cả một dân tộc, làm rạng danh con Lạc
          – cháu Hồng.
        </>
      ]
    },
    grows: {
      title: 'Không gian gọi vốn',
      image: iconGrows,
      desc: [
        <>
          Với sứ mệnh{' '}
          <b style={styleBold}>Chắp cánh giấc mơ doanh nghiệp Việt</b>, chúng
          tôi tin tưởng cộng đồng Doanh nghiệp, Startup sẽ{' '}
          <b style={styleBold}>Kiên định niềm tin – Nắm bắt cơ hội</b> và chinh
          phục được cộng đồng, chinh phục được những nhà đầu tư khó tính nhằm có
          thêm nguồn lực tài chính, kiến tạo nên những Doanh nghiệp vĩ đại. Góp
          phần mang lại nhiều giá trị lợi ích cho cộng đồng, cùng chung tay xây
          dựng quê hương giàu đẹp.
        </>,
        <>
          Chúng tôi tin tưởng Không gian gọi vốn sẽ là nơi Doanh nghiệp, Startup
          dễ dàng tiếp cận nguồn đầu tư lớn từ Cộng đồng, từ các nhà đầu tư cá
          nhân, Qũy đầu tư tài chính. Đồng thời dễ dàng Quảng bá và xây dựng
          thương hiệu trên 1 hệ sinh thái thống nhất, một nền tảng Mạng xã hội
          của người Việt, do người Việt và vì người Việt.
        </>,
        <>
          Chúng tôi cũng tin tưởng, Không gian gọi vốn sẽ mang đến Cơ hội cho
          các nhà đầu tư, sẽ giúp các nhà đầu tư dễ dàng tìm kiếm được những dự
          án tiềm năng, mang lại lợi nhuận lớn.
        </>,
        <>
          Chúng tôi cũng hy vọng, các doanh nghiệp/ Startup trẻ - với những dự
          án mang lại nhiều giá trị lợi ích cho cộng đồng sẽ nhận được nhiều
          những sự ủng hộ, hỗ trợ thông qua kênh Donate, để có thêm động lực
          vượt qua những khó khăn thách thức và có thêm cơ hội đem lại nhiều hơn
          những giá trị lợi ích cho cộng đồng thông qua sản phẩm, dự án của
          mình. Từ đó chung tay xây dựng nên một Việt Nam hùng cường, giàu mạnh.
        </>
      ]
    },
    music: {
      title: 'Không gian âm nhạc',
      image: iconMusic,
      desc: [
        <>
          Với sứ mệnh trở thành{' '}
          <b style={styleBold}>Bệ phóng cho Nghệ sĩ và Âm nhạc Việt</b>, chúng
          tôi tin tưởng Không gian Âm nhạc sẽ{' '}
          <b style={styleBold}>Khơi dòng sáng tạo – Chắp cánh ước mơ</b>, sẽ
          giúp thu hẹp khoảng cách giữa công chúng với thần tượng của mình.
        </>,
        <>
          Chúng tôi tin tưởng, Không gian Âm nhạc sẽ là nơi những người yêu nghệ
          thuật âm nhạc sẽ dễ dàng ủng hộ thần tượng của mình thông qua kênh
          Donate ngay trên từng tác phẩm.
        </>,
        <>
          Chúng tôi tin tưởng Không gian Âm nhạc sẽ là kênh thu nhập thụ động vô
          cùng tiềm năng cho Nghệ sĩ, để những người nghệ sĩ có thể yên tâm sáng
          tạo, làm nên những tác phẩm Tuyệt vời dành cho công chúng, làm đẹp
          thêm cho 1 xã hội Việt Nam mới, không chỉ giàu mạnh về kinh tế - tri
          thức mà còn giàu đẹp cả tâm hồn.
        </>,
        <>
          Chúng tôi sẽ xây dựng nên những Gala vinh danh những nghệ sĩ tài hoa
          ấy, để cùng cộng đồng tiếp thêm động lực, nguồn cho những tài năng
          Việt.
        </>
      ]
    },
    recruits: {
      title: 'Không gian tuyển dụng',
      image: iconRecruits,
      desc: [
        <>
          Với sứ mệnh{' '}
          <b style={styleBold}>Kết nối cơ hội việc làm cho người Việt</b>, chúng
          tôi tin tưởng Không gian tuyển dụng trên Mạng xã hội EMSO sẽ là nơi
          người Việt chúng ta{' '}
          <b style={styleBold}>Khởi đầu sự nghiệp – Mở cửa tương lai</b>.
        </>,
        <>
          Với mục tiêu góp phần giúp những ứng viên, những nhà tuyển dụng sớm
          vượt qua những khó khăn thách thức trong bối cảnh khủng hoảng kinh tế
          thế giới. Chúng tôi sẽ Miễn phí hoàn toàn mọi hoạt động đăng tuyển,
          ứng tuyển trên hệ thống.
        </>,
        <>
          Với tính năng khác biệt, chúng tôi tin tưởng sẽ giúp Ứng viên/ Nhà
          tuyển dụng tiết kiệm 70% thời gian và quy trình tuyển dụng. Giúp các
          Doanh nghiệp giữ lại nhiều hơn nguồn lực tài chính để thúc đẩy hoạt
          động sản xuất kinh doanh của mình.
        </>,
        <>
          Chúng tôi cũng tin tưởng với tính năng xác minh danh tính, Không gian
          tuyển dụng sẽ giúp các Ứng viên loại bỏ được nỗi lo lừa đảo trong
          tuyển dụng, thông qua đó yên tâm tìm kiếm những công việc mà mình yêu
          thích, để có thêm cơ hội việc làm, có thêm cơ hội mang lại giá trị cho
          xã hội, thông qua đó ổn định và phát triển thu nhập, làm giàu mạnh cho
          cá nhân – gia đình và xã hội.
        </>
      ]
    },
    moment: {
      title: 'Khoảnh Khắc',
      image: iconMoment,
      desc: [
        <>
          Với tâm nguyện{' '}
          <b style={styleBold}>Trân trọng từng khoảnh khắc của người Việt</b>,
          chúng tôi tin tưởng cộng đồng EMSOER sẽ{' '}
          <b style={styleBold}>Trân trọng khoảnh khắc – Xây đắp niềm vui</b>,
          cùng nhau sáng tạo và chia sẻ những khoảnh khắc tuyệt vời đến với cộng
          đồng. Chúng tôi sẽ tôn vinh những nhà sáng tạo nội dung, có tác động
          tích cực đến với cộng đồng người Việt.
        </>,
        <>
          Chúng tôi cũng tin tưởng, đây sẽ là kênh giải trí hấp dẫn cho người
          Việt, giúp chúng ta có thêm nhiều kênh thông tin vui vẻ, bổ ích để có
          thêm không gian thư giãn, có thêm những góc nhìn Tuyệt vời hơn với
          cuộc sống.
        </>,
        <>
          Chúng tôi cũng tin tưởng, những nhà sáng tạo nội dung cũng sẽ có thêm
          nhiều cơ hội xây dựng những cộng đồng người hâm mộ cho mình. Thông qua
          đó có thêm kênh thu nhập thụ động như nhận Donate, bật kiếm tiền…
        </>,
        <>
          Chúng tôi cũng sẽ tôn vinh những nhà sáng tạo nội dung có số lượng fan
          hâm mộ lớn, có tác động tích cực đến xã hội thông qua những Gala đặc
          biệt thường niên. Chúng tôi tin tưởng, chúng ta sẽ cùng nhau xây dựng
          nên một mạng xã hội của người Việt, do người Việt và vì người Việt.
        </>
      ]
    }
  };

  const dataDisplay = dataFavorite[type];

  return (
    <Box sx={{ ...styleContainer }}>
      <IconButton onClick={() => setOpenFavorite(true)}>
        <FavoriteIcon color="error" />
      </IconButton>

      {openFavorite && (
        <DialogFavorite
          open={openFavorite}
          setOpen={setOpenFavorite}
          handleClose={() => setOpenFavorite(false)}
          hiddenButton={true}
          hiddenTitle={true}
          maxWidth={'md'}
          contentDialog={
            <Box p="0 50px 24px">
              <Typography
                fontWeight={600}
                fontSize={40}
                textAlign="center"
                gutterBottom
              >
                {dataDisplay.title}
              </Typography>
              <CardMedia
                component="img"
                src={dataDisplay.image}
                sx={{ width: '80px', margin: '0 auto', marginBottom: '30px' }}
              />

              {dataDisplay.desc.map((item: any) => (
                <Typography
                  key={item.title}
                  fontSize={18}
                  color={theme.palette.text.customColor}
                  gutterBottom
                  textAlign="justify"
                >
                  <li>{item}</li>
                </Typography>
              ))}
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default IconFavorite;
