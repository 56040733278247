import { LOADING_RECRUIT } from './../../constants/store/recruitSocial';
import * as types from 'src/constants/store/recruitSocial';
const _ = require('lodash');

const initialState = {
  info: {} as any,
  rolePreviewEdit: { role: '' },
  error: false,
  tempInteract: [],
  infoPreview: null,
  isLoading: false,
  notiMobile: null,
  searchLoading: false,
  openCv: false,
  category: []
};

export const recruitReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RECRUIT_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, infoPreview: newState };
    }

    case types.GET_RECRUIT_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: data,
        infoPreview: null
      };
    }

    case types.RESET_RECRUIT: {
      return {
        info: {
          id: '',
          title: '',
          benefit: '',
          company_description: '',
          due_date: '',
          gender: '',
          job_description: '',
          level: '',
          recruit_category_id: '',
          recruitments_count: '',
          requirement: '',
          salary_max: '',
          salary_min: '',
          visibility: '',
          work_experience: '',
          working_form: '',
          page_category_id: [],
          banner: null,
          location: null,
          address: ''
        },
        rolePreviewEdit: { role: '' },
        infoPreview: null
      };
    }

    case types.GET_RECRUIT_DETAIL_ERROR: {
      return { ...state, error: true, infoPreview: null };
    }
    case types.UPDATE_PAGE_OWNER: {
      return {
        ...state,
        info: {
          ...state.info,
          page_owner: {
            ...state.info.page_owner,
            page_relationship: {
              ...state.info.page_owner.page_relationship,
              like: !state.info.page_owner.page_relationship.like
            }
          }
        }
      };
    }
    case types.SUBMIT_RECRUIT: {
      return {
        ...state,
        info: {
          ...state.info,
          recruit_relationships: {
            ...state.info.recruit_relationships,
            apply_recruit: 'pending'
          }
        }
      };
    }

    // create and handle data before run action
    case types.UPDATE_RELATIONSHIPS: {
      const new_relationships = action.payload.data;
      return {
        ...state,
        info: {
          ...state.info,
          recruit_relationships: {
            ...state.info.recruit_relationships,
            ...new_relationships
          }
        }
      };
    }

    case types.RESET_ERROR_RECRUIT: {
      return {
        ...state,
        error: false
      };
    }
    case types.LOADING_RECRUIT: {
      return {
        ...state,
        isLoading: action.payload.value
      };
    }
    case types.NOTIMOBILE: {
      return { ...state, notiMobile: action.payload.value };
    }
    case types.LOADING_SEARCH_RECRUIT: {
      return { ...state, searchLoading: action.payload.value };
    }
    case types.OPENCV: {
      return {
        ...state,
        openCv: action.payload.value
      };
    }
    case types.UPDATE_FOLLOW_RECRUIT: {
      let { data } = action.payload;
      return {
        ...state,
        info: {
          ...state.info,
          recruit_relationships: {
            ...state.info.recruit_relationships,
            follow_recruit: data
          }
        }
      };
    }
    case types.GET_RECRUIT_CATEGORIES_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        category: data
      };
    }
    default:
      return state;
  }
};
