import { urlRocketChat } from 'src/util/config';

export const renderAvatarChat = (conversation: any, rocketId) => {
  let avatarData;

  switch (conversation.t) {
    case 'd': // Direct messages
      if (conversation.avatar_media?.show_url) {
        avatarData = conversation.avatar_media;
      }else{
        let userId = conversation.u._id;
        if (conversation.rid.includes(conversation.u?._id)) {
          if (conversation.u?._id) {
            userId = conversation.rid.replace(conversation.u?._id, '');
          }
        }
        avatarData = {
          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${userId}`
        };
      }
     
      break;
    case 'p': // Private group
    case 'c': // Channel
      avatarData = {
        avatar_top: {
          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
            conversation?.uids?.filter(el => el !== rocketId)[0]
          }`
        },
        avatar_bottom: {
          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
            conversation?.uids?.filter(el => el !== rocketId)[1]
          }`
        }
      };
      break;

    default:
      // Teams or other conversation types
      avatarData = {
        show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${conversation.prid}`
      };
      break;
  }
  return avatarData;
};
