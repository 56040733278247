import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Skeleton,
  Avatar,
  Theme
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import InfiniteScroll from 'react-infinite-scroll-component';
import { getReactionPeopleApi } from 'src/apis/socialPost.api';

import CloseIcon from '@mui/icons-material/Close';
import TabSocial from 'src/components/Tabs/TabSocial';
import { staticImgReaction } from 'src/helpers/common';
import { shortenLargeNumber } from 'src/common/number';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import UserReactionItem from './UserReactionItem';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      like: {
        width: '19px !important',
        height: '19px !important',
        paddingBottom: '1px !important'
      },
      love: {
        width: '32px !important',
        height: '26px !important',
        margin: '0px -1px 0px -13px !important'
      },
      yay: {
        width: '21px !important',
        height: '30px !important'
      },
      haha: {
        width: '24px !important',
        height: '23px !important',
        marginRight: '-1px !important'
      },
      wow: {
        width: '21px !important',
        height: '20px !important',
        paddingBottom: '2px !important'
      },
      angry: {
        width: '18px !important',
        height: '18px !important',
        paddingBottom: '2px !important'
      },
      sad: {
        width: '21px !important',
        height: '21px !important',
        paddingBottom: '2px !important'
      }
    }),
  { index: 1 }
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { open, setOpen, post } = props;
  const classes = useStyles();
  const [valueTab, setValueTab] = React.useState('');
  const [hasMore, setHasMore] = React.useState(true);
  const [listPeople, setListPeople] = React.useState<any>([]);
  const [listTabReaction, setListTabReaction] = React.useState<any>([]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
    type
  ) => {
    setValueTab(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderIconTab = tab => {
    return (
      <Avatar
        alt={tab}
        classes={{ root: classes[tab] }}
        src={staticImgReaction[tab]}
      />
    );
  };

  const renderIconDetail = tab => {
    return (
      <Avatar
        sx={{
          width: '16px !important',
          height: '16px !important',
          borderRadius: '50%',
          position: 'absolute',
          right: '-3px',
          bottom: '0px'
        }}
        alt={tab}
        src={staticImgReaction[tab]}
      />
    );
  };

  const getCountTypeReaction = (type, text) => {
    return shortenLargeNumber(
      post.reactions?.find((el: any) => el.type === type)[text] ?? 0
    );
  };

  const listTabs = [
    { id: '', type: '', text: 'Tất cả' },
    {
      type: 'like',
      text: renderIconTab('like'),
      count: getCountTypeReaction('like', 'likes_count')
    },
    {
      type: 'love',
      text: renderIconTab('love'),
      count: getCountTypeReaction('love', 'loves_count')
    },
    {
      type: 'sad',
      text: renderIconTab('sad'),
      count: getCountTypeReaction('sad', 'sads_count')
    },
    {
      type: 'haha',
      text: renderIconTab('haha'),
      count: getCountTypeReaction('haha', 'hahas_count')
    },
    {
      type: 'wow',
      text: renderIconTab('wow'),
      count: getCountTypeReaction('wow', 'wows_count')
    },
    {
      type: 'angry',
      text: renderIconTab('angry'),
      count: getCountTypeReaction('angry', 'angrys_count')
    },
    {
      type: 'yay',
      text: renderIconTab('yay'),
      count: getCountTypeReaction('yay', 'yays_count')
    }
  ];
  const renderTitle = item => item;

  const getPeopleReaction = async params => {
    let res: any = await getReactionPeopleApi(post.id, params);
    if (res.status === 200) {
      if (res.data.length) {
        setListPeople(prev => [...prev, ...res.data]);
      }
      if (!res.data.length) {
        setHasMore(false);
      }
    }
  };
  const funcLoad = () => {
    let maxId = [...listPeople].pop().id;
    let params = {
      max_id: maxId,
      vote_type: valueTab
    };

    getPeopleReaction(params);
  };

  React.useEffect(() => {
    setListPeople([]);
    let params = valueTab && {
      vote_type: valueTab
    };

    getPeopleReaction(params);
  }, [valueTab]);

  React.useEffect(() => {
    if (post && Object.keys(post).length) {
      let reactions = post?.reactions?.map(el => Object.values(el));
      if (reactions?.length) {
        let sortReactions = reactions
          ?.filter(el => el[1])
          .sort((a, b) => b[1] - a[1]);
        setListTabReaction([
          listTabs[0],
          ...sortReactions?.map((el: any) => ({
            id: el[0],
            text: renderIconTab(el[0]),
            count: el[1]
          }))
        ]);
      }
    }
  }, [JSON.stringify(post?.reactions)]);

  const userSkeleton = (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
      <Skeleton
        variant="circular"
        animation="wave"
        width={40}
        height={40}
        sx={{ marginLeft: 2, marginTop: 1, marginRight: 2 }}
      />
      <Skeleton
        animation="wave"
        width={'50%'}
        variant="text"
        sx={{ fontSize: '1.2rem', marginTop: 1 }}
      />
    </div>
  );
  const loaderSkeleton = (
    <>
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
    </>
  );

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <TabSocial
            style={{ borderBottom: `3px solid ${buttonColor.backgroundColor}` }}
            value={valueTab}
            handleChange={(e, number) =>
              handleChange(e, number, listTabReaction[number].id)
            }
            listTabs={listTabReaction?.map((el: any, index) => {
              return {
                ...el,
                text: (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: -7
                    }}
                  >
                    <span
                      style={{
                        color:
                          el.id === '' && el.id === valueTab
                            ? 'rgb(32 120 244)'
                            : '#65676b'
                      }}
                    >
                      {el.text === 'Tất cả' ? (
                        <div>&nbsp;&nbsp;{el.text}</div>
                      ) : (
                        el.text
                      )}
                    </span>{' '}
                    &nbsp;
                    <span
                      style={{
                        color:
                          el.id === 'like' && el.id === valueTab
                            ? 'rgb(32 120 244)'
                            : el.id === 'love' && el.id === valueTab
                            ? 'rgb(243 62 88)'
                            : el.id === 'angry' && el.id === valueTab
                            ? 'rgb(233 113 15)'
                            : (el.id === 'sad' && el.id === valueTab) ||
                              (el.id === 'haha' && el.id === valueTab) ||
                              (el.id === 'wow' && el.id === valueTab) ||
                              (el.id === 'yay' && el.id === valueTab)
                            ? 'rgb(247 177 37)'
                            : '#65676b'
                      }}
                    >
                      {el.count}
                    </span>
                  </div>
                )
              };
            })}
          />
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            height: 364,
            '& .infinite-scroll-component__outerdiv': {
              height: listPeople?.length < 5 ? '100%' : 'unset'
            }
          }}
          dividers
        >
          <InfiniteScroll
            dataLength={listPeople?.length ?? 0} //This is important field to render the next data
            next={funcLoad}
            hasMore={hasMore}
            loader={loaderSkeleton}
            style={{ overflow: 'hidden', height: '100%' }}
            scrollThreshold={1}
          >
            {listPeople &&
              listPeople?.map((el, index) => (
                <UserReactionItem
                  item={el}
                  index={index}
                  renderIconDetail={renderIconDetail}
                  renderTitle={renderTitle}
                  handleClose={handleClose}
                />
              ))}
          </InfiniteScroll>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
