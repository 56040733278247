import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface CandidateInformation {
  infoUpdate?: any;
  formik?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  organization?: any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const CandidateInformation = (props: CandidateInformation) => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    setCheckStep,
    organization,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);
  React.useEffect(() => {
    if (setCheckStep) {
      if (
        ( Object.keys(formik.values?.description_course?.trim())?.length >= 10 &&
          Object.keys(formik.values?.refund_policy?.trim())?.length >=10 &&
          formik.values?.allow_discussion !== undefined ) && 
         ( organization?.page_owner_id && 
          Object.keys(formik.values?.description_company?.trim())?.length >= 10 )
      ) {
        setCheckStep(prev => ({ ...prev, step2: true }));
      } else if (
        ( Object.keys(formik.values?.description_course?.trim())?.length >= 10 &&
          Object.keys(formik.values?.refund_policy?.trim())?.length >= 10 &&
          formik.values?.allow_discussion !== undefined ) &&
          (!organization?.page_owner_id ||
          Object.keys(formik.values?.description_company?.trim())?.length >= 10)
      ) { 
        setCheckStep(prev => ({ ...prev, step2: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step2: false }));
      }
    }
  }, [JSON.stringify(formik.values), organization?.page_owner_id]);
  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'benefits',
      placeholder: 'Vui lòng nhập nội dung...',
      label: 'Lợi ích khóa học',
      onChange: true,
      rows: 4,
      defaultValue: formik.values.benefits
    },
    {
      field_type: 'textField',
      name: 'target_audience',
      placeholder: 'Vui lòng nhập nội dung...',
      label: 'Phù hợp với',
      onChange: true,
      maxRows: 2,
      defaultValue: formik.values.target_audience
    },
    {
      field_type: organization?.page_owner_id ? 'textField' : null,
      name: 'description_company',
      placeholder: 'Mô tả trung tâm...',
      label: 'Mô tả trung tâm',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      rows: 4,
      defaultValue: formik.values.description_company
    },
    {
      field_type: 'textField',
      name: 'description_course',
      placeholder: 'Mô tả khóa học...',
      label: 'Mô tả khóa học',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      rows: 4,
      defaultValue: formik.values.description_course
    },
    {
      field_type: 'textField',
      name: 'refund_policy',
      placeholder: 'Vui lòng nhập nội dung...',
      label: 'Chính sách hoàn trả',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      rows: 4,
      defaultValue: formik.values.refund_policy
    },
    {
      field_type: 'radio',
      name: 'allow_discussion',
      label: 'Thảo luận',
      options: [
        {
          value: true,
          labelRadio: 'Cho phép thảo luận',
          helpText: 'Học viên được phép thảo luận...'
        },
        {
          value: false,
          labelRadio: 'Không cho phép thảo luận',
          helpText: 'Học viên không được phép thảo luận...'
        }
      ]
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default CandidateInformation;
