import { Box, Divider, Grid, List, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getSearchMoment, searchVideos } from 'src/apis/socialPost.api';
import {
  addUserSearchHistory,
  userSearchHistory
} from 'src/apis/socialSearch.api';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import Loading from 'src/components/Loading/Loading';
import { PATHS } from 'src/constants/paths';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import {
  resetMomentSearch,
  searchMoment,
  searchWatchVideos
} from 'src/store/action/socialWatchAction';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        width: 310,
        position: 'fixed',
        padding: '20px 10px 40px 8px',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)',
        backgroundColor: theme.palette.background.primary
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px',
        backgroundColor: theme.palette.background.primary
      },
      textBlur: {
        fontSize: '14px !important',
        color: theme.palette.mode === 'dark' ? 'rgb(228, 230, 235)' : '#333',
        opacity: '0.8'
      },
      titleLeft: {
        fontSize: '22px !important',
        fontWeight: '700 !important'
      },
      wrapInput: {
        padding: '8px 0px'
      },
      list: {
        width: '285px'
      }
    }),
  { index: 1 }
);

const SearchLeft = props => {
  const { styleGui } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let key: any = query.get('q');
  let videoType: any = query.get('type');
  const [keyword, setKeyword] = React.useState<string>(key);
  const listWatchSearch = useSelector(
    (state: any) => state.watchReducer.watchSearch
  );
  const listMomentSearch = useSelector(
    (state: any) => state.watchReducer.momentSearch
  );
  const [listSearchHistory, setListSearchHistory] = React.useState<any>([]);
  const [focusInput, setFocusInput] = React.useState(false);
  const classes = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);

  useEffect(() => {
    setKeyword(key);
  }, [key, videoType]);

  const handleSearch = React.useCallback(
    _.debounce(async keyword => {
      setIsLoading(true);
      let response;
      if (PATHS.WATCH_SEARCH === match.path && videoType !== 'live') {
        response = await searchVideos(keyword, 'watch');
      } else if (PATHS.WATCH_SEARCH === match.path && videoType === 'live') {
        response = await searchVideos(keyword, 'livestream');
      } else {
        response = await getSearchMoment(keyword);
      }
      if (response.status === 200) {
        if (PATHS.WATCH_SEARCH === match.path && videoType !== 'live') {
          dispatch(
            searchWatchVideos(response.data.statuses, keyword, 'result_search')
          );
        } else if (PATHS.WATCH_SEARCH === match.path && videoType === 'live') {
          dispatch(searchWatchVideos(response.data.statuses, keyword, 'watch'));
        } else {
          dispatch(searchMoment(response.data));
        }
        setIsLoading(false);
      }
    }, 800),
    []
  );

  const handleAddUserSearchHistory = async params => {
    let response = await addUserSearchHistory(params);
    if (response.status === 200) {
      fetchUserSearchHistory();
    }
  };

  const fetchUserSearchHistory = async () => {
    let response;
    response = await userSearchHistory({
      search_type: 'moment',
      limit: 6
    });
    if (response.status === 200) {
      setListSearchHistory(response.data);
      setIsLoading(false);
    }
  };

  function checkHashtagsStr(str: any) {
    const regex = /#\w+/g;
    return regex.test(str);
  }
  React.useEffect(() => {
    if (keyword) {
      handleSearch(keyword);
    } else {
      if (PATHS.MOMENT_SEARCH === match.path) {
        dispatch(resetMomentSearch());
      }
    }
  }, [keyword]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const renderBody = () => {
    const checkSearch = () => {
      if (listWatchSearch?.data?.length > 0) {
        return listWatchSearch.data?.map((el, index) => {
          return (
            <Box key={index} sx={{ width: '100%', height: 'auto' }}>
              <Conversation
                type="video"
                conversation={el}
                key={index}
                setIsHovered={setIsHovered}
              />
            </Box>
          );
        });
      } else if (listSearchHistory.length && keyword.length < 1) {
        return (
          <>
            <div>
              <Typography
                fontSize="16px"
                fontWeight="600"
                sx={{ paddingLeft: '10px' }}
              >
                Tìm kiếm gần đây
              </Typography>
            </div>
            <List className={classes.list} component="nav" aria-label="sidebar">
              {listSearchHistory?.map((el: any, index) => (
                <Box
                  key={index}
                  sx={{ width: '100%', height: 'auto' }}
                  onClick={() => {
                    history.push(
                      `/moments/search/top?q=${el.keyword ?? el.name}`
                    );
                    handleAddUserSearchHistory({
                      keyword: el.keyword ?? el.name,
                      search_type: 'moment'
                    });
                  }}
                >
                  <Conversation
                    type="moment"
                    conversation={el}
                    key={index}
                    setIsHovered={setIsHovered}
                  />
                </Box>
              ))}
            </List>
          </>
        );
      } else if (listMomentSearch?.length > 0) {
        return listMomentSearch.map((el, index) => {
          return (
            <Box
              key={index}
              sx={{ width: '100%', height: 'auto' }}
              onClick={() => {
                handleAddUserSearchHistory({
                  keyword: el.display_name ?? el.title,
                  search_type: 'moment'
                });
              }}
            >
              <Conversation type="moment" conversation={el} key={index} />
            </Box>
          );
        });
      } else if (focusInput && !listMomentSearch.length) {
        return <div style={{ textAlign: 'center' }}>Không tìm thấy video.</div>;
      }
    };
    return (
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '96%',
              backgroundColor: 'background.primary'
            }}
          >
            <Typography className={classes.textBlur}>
              {PATHS.WATCH_SEARCH === match.path ? 'Watch' : 'Khoảnh khắc'}
            </Typography>
            <Typography className={classes.titleLeft}>
              Kết quả tìm kiếm
            </Typography>
            <Box className={classes.wrapInput}>
              <InputSearch
                label="Tìm kiếm video"
                keyword={keyword}
                setKeyword={setKeyword}
                type={'video'}
                action={() => {
                  if (checkHashtagsStr(keyword)) {
                    history.push(`/hashtag/${keyword.slice(1)}`);
                  } else if (PATHS.MOMENT_SEARCH === match.path) {
                    history.push(`/moments/search/top?q=${keyword}`);
                    handleAddUserSearchHistory({
                      keyword: keyword,
                      search_type: 'moment'
                    });
                  } else if (PATHS.WATCH_SEARCH === match.path) {
                    history.push(`/watchs/search/?q=${keyword}`);
                    handleAddUserSearchHistory({
                      keyword: keyword,
                      search_type: 'watch'
                    });
                  }
                }}
                actionFocus={() => {
                  setFocusInput(true);
                }}
                actionBlur={() => {
                  setFocusInput(false);
                }}
                noAutoFocus={true}
              />
              <Box sx={{ height: 15 }}></Box>

              <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                {isHovered || focusInput ? (
                  isLoading ? (
                    <Loading
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center'
                      }}
                    />
                  ) : (
                    checkSearch()
                  )
                ) : null}
              </Box>
            </Box>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      className={classes.root}
      style={styleGui}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <div className={classes.headerPage}>{renderBody()}</div>
    </Box>
  );
};

export default SearchLeft;
