export const GET_PROFILE_USER_REQ = 'GET_PROFILE_USER_REQ';
export const GET_PROFILE_USER_SUCCESS = 'GET_PROFILE_USER_SUCCESS';
export const GET_PROFILE_USER_FAILED = 'GET_PROFILE_USER_FAILED';

export const GET_INFOR_USER_REQ = 'GET_INFOR_USER_REQ';
export const GET_INFOR_USER_SUCCESS = 'GET_INFOR_USER_SUCCESS';
export const GET_INFOR_USER_FAILED = 'GET_INFOR_USER_FAILED';

export const GET_LIST_USER_REQ = 'GET_LIST_USER_REQ';
export const GET_LIST_USER_SUCCESS = 'GET_LIST_USER_SUCCESS';

export const DESTROY_USER = 'DESTROY_USER';

export const GET_LIST_SOCIAL = 'GET_LIST_SOCIAL';

export const GET_LIST_LIFE_EVENTS_REQUEST = 'GET_LIST_LIFE_EVENTS_REQUEST';
export const GET_LIST_LIFE_EVENTS_SUCCESS = 'GET_LIST_LIFE_EVENTS_SUCCESS';

export const RESET_USER = 'RESET_USER';

export const ACTION_FRIEND = 'ACTION_FRIEND';
export const USER_LOCK = 'USER_LOCK';
export const DELETE_LOCK = 'DELETE_LOCK';

export const ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS';

export const ERROR_MANY_REQUESTS = 'ERROR_MANY_REQUESTS';

export const DATA_ERROR_MANY_REQUESTS = 'DATA_ERROR_MANY_REQUESTS';
