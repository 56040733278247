import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { listVisibility } from 'src/constants/common';
import { buttonColor } from 'src/constants/styles';
import imageDefault from 'src/assets/images/pages_profile.png';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles((theme: Theme) => ({
  visibility: {
    backgroundColor: theme.palette.mode === 'dark' ? '#4e4e4e' : '#e4e6eb',
    padding: '4px 8px',
    height: 24,
    borderRadius: 6,
    maxWidth: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disableVisibility: {
    backgroundColor: theme.palette.mode === 'dark' ? '#4e4e4e' : '#e4e6eb',
    padding: '4px 8px',
    height: 24,
    borderRadius: 6,
    maxWidth: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    '&:hover': {
      cursor: 'not-allowed! important'
    }
  },
  rootTop: {
    margin: '0px 16px',
    padding: '16px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  wrapTopContent: {
    display: 'flex',
    width: '88%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  textName: {
    fontSize: '15px !important',
    fontWeight: '600 !important',
    wordBreak: 'break-word'
  },
  textLabel: {
    fontSize: '13px !important',
    fontWeight: '500 !important',
    marginBottom: '2px !important'
  },
  textTag: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBread: 'break-word'
  }
}));

interface Props {
  locationSelected?: any;
  friendSelected?: any;
  visibility?: any;
  setOpenVisibility?: React.Dispatch<React.SetStateAction<boolean>> | any;
  activitiesSelected?: any;
  parentSelected?: any;
  setOpenTagFriend?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setOpenLocation?: React.Dispatch<React.SetStateAction<boolean>> | any;
  type?: string;
  post?: any;
  groupShared?: any;
  friendShared?: any;
  pageShared?: any;
  eventVisibility?: any;
  setVisibility?: React.Dispatch<React.SetStateAction<any>> | any;
  eventInfo?: any;
  pageInfo?: any;
  projectInfo?: any;
  course?: any;
  sharePostInNewFeed?: any;
}

const CUPostTop = React.memo((props: Props) => {
  const classes = useStyles();
  const {
    locationSelected,
    friendSelected,
    visibility,
    setOpenVisibility,
    activitiesSelected,
    parentSelected,
    setOpenTagFriend,
    setOpenLocation,
    type,
    post,
    groupShared,
    friendShared,
    pageShared,
    pageInfo,
    eventVisibility,
    setVisibility,
    eventInfo,
    projectInfo,
    course,
    sharePostInNewFeed
  } = props;
  const meInfo = useSelector((state: any) => state?.meReducer?.info) || {};
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const pageInteract = pageInfo ?? eventInfo?.page ?? projectInfo?.page_owner;
  const pageRelationship =
    pageInfo?.page_relationship ??
    eventInfo?.page?.page_relationship ??
    projectInfo?.page_owner?.page_relationship;

  const handleChangeVisibility = key => {
    let visibilitySelected: any = listVisibility?.find(
      (el: any) => el.key === key
    );
    setVisibility(visibilitySelected);
  };

  React.useEffect(() => {
    if (eventVisibility) {
      setVisibility(eventVisibility);
    } else if (
      (post?.group || post?.page) &&
      post?.visibility === 'scheduled'
    ) {
      if (post?.group?.is_private) {
        handleChangeVisibility('private');
      } else {
        handleChangeVisibility('public');
      }
    }
  }, [post]);

  const checkAdmin = () => {
    //function for created by page
    if (
      !eventInfo?.page_owner &&
      !projectInfo?.page_owner &&
      !course?.page_owner
    )
      return false;

    if (
      eventInfo?.event_relationship?.host_event ||
      eventInfo?.page_owner?.page_relationship?.role === 'owner' ||
      eventInfo?.page_owner?.page_relationship?.role === 'admin' ||
      eventInfo?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;

    if (
      projectInfo?.project_relationship?.host_project ||
      projectInfo?.page_owner?.page_relationship?.role === 'owner' ||
      projectInfo?.page_owner?.page_relationship?.role === 'admin' ||
      projectInfo?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;

    if (
      course?.course_relationships?.host_course ||
      course?.page_owner?.page_relationship?.role === 'owner' ||
      course?.page_owner?.page_relationship?.role === 'admin' ||
      course?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;
  };

  const renderAvatarObj = () => {
    if (checkAdmin())
      return (
        course?.page_owner?.avatar_url ??
        projectInfo?.page_owner?.avatar_media?.url ??
        eventInfo?.page_owner?.avatar_media?.url
      );

    if (
      type === 'page' ||
      type === 'header-post-page' ||
      type === 'stream_home' ||
      type === 'stream_profile'
    ) {
      return rolePage?.page_owner_id
        ? rolePage.avatar_media?.preview_url
        : meInfo.avatar_media
        ? meInfo.avatar_media
        : meInfo?.avatar_static;
    } else if (
      pageInteract?.id &&
      pageRelationship?.role &&
      rolePage.page_owner_id
    ) {
      return pageInteract?.avatar_media;
    } else return meInfo.avatar_media ?? meInfo?.avatar_static;
  };

  const renderText = () => {
    if (checkAdmin())
      return (
        course?.page_owner?.title ??
        projectInfo?.page_owner?.title ??
        eventInfo?.page_owner?.title
      );

    if (
      type === 'page' ||
      type === 'header-post-page' ||
      type === 'stream_home' ||
      type === 'stream_profile'
    ) {
      return rolePage?.page_owner_id ? rolePage.title : meInfo.display_name;
    } else if (
      pageInteract?.id &&
      pageRelationship?.role &&
      rolePage.page_owner_id
    ) {
      return rolePage.title;
    } else return meInfo.display_name;

    // Động bộ với avatar , giữ code cũ để debug
    // if (
    //   (type === 'page' ||
    //     type === 'header-post-page' ||
    //     type === 'stream_home' ||
    //     type === 'stream_profile') &&
    //   (rolePage?.role === 'admin' || rolePage?.role === 'moderator')
    // ) {
    //   return rolePage.title;
    // } else if (
    //   ((pageInteract?.title && rolePage?.role === 'admin') ||
    //     pageRelationship?.role === 'admin') &&
    //   rolePage.page_owner_id
    // ) {
    //   return pageInteract?.title;
    // } else {
    //   return meInfo.display_name;
    // }
  };

  return (
    <>
      <div className={classes.rootTop}>
        <AvatarSocial
          type="feed"
          avatarObj={renderAvatarObj()}
          style={{ width: 42, height: 42, marginRight: 11 }}
        />

        <div className={classes.wrapTopContent}>
          <div>
            <Typography className={classes.textName}>
              {renderText()}
              {(!(type === 'page' || type === 'header-post-page') ||
                !rolePage?.role) &&
              !activitiesSelected &&
              friendSelected.length === 0 &&
              !locationSelected &&
              meInfo.certified ? (
                <span>
                  {' '}
                  <Tooltip
                    placement="top-start"
                    title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                    arrow
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          fontSize: '14px',
                          padding: '10px'
                        }
                      }
                    }}
                  >
                    <VerifiedIcon
                      // titleAccess="Tài khoản đã xác minh"
                      fontSize="small"
                      viewBox="0 -2 24 24"
                      sx={{
                        color: buttonColor.backgroundColor,
                        fontSize: '14px',
                        '&:hover': {
                          color: buttonColor.backgroundColor
                        }
                      }}
                    />
                  </Tooltip>
                </span>
              ) : null}
              {!meInfo.certified && meInfo.identity_verification ? (
                <Tooltip
                  placement="top-start"
                  title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                  arrow
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        fontSize: '14px',
                        padding: '10px'
                      }
                    }
                  }}
                >
                  <VerifiedIcon
                    // titleAccess="Tài khoản đã xác minh"
                    fontSize="small"
                    viewBox="0 -2 24 24"
                    sx={{
                      color: 'hsla(0, 0%, 50%)',
                      fontSize: '14px',
                      '&:hover': {
                        color: 'hsla(0, 0%, 60%)'
                      }
                    }}
                  />
                </Tooltip>
              ) : null}
              &nbsp;
              {activitiesSelected ? (
                activitiesSelected.data_type === 'emoji' ? (
                  <span style={{ fontWeight: 400 }}>
                    đang&nbsp;
                    <img
                      src={activitiesSelected.url}
                      alt={activitiesSelected.name}
                      width={20}
                      height={20}
                      style={{ marginBottom: '-5px' }}
                    />
                    &nbsp;cảm thấy {activitiesSelected.name}&nbsp;
                  </span>
                ) : (
                  <span>
                    {parentSelected
                      ? parentSelected.name
                          ?.slice(0, parentSelected.name?.length)
                          ?.toLowerCase()
                      : ''}{' '}
                    <img
                      src={parentSelected?.url ?? imageDefault}
                      alt={activitiesSelected.name ?? activitiesSelected?.title}
                      width={20}
                      height={20}
                      style={{ marginBottom: '-5px' }}
                      onError={(e: any) => (e.target.src = imageDefault)}
                    />
                    &nbsp;{' '}
                    {activitiesSelected.name ?? activitiesSelected?.title}&nbsp;
                  </span>
                )
              ) : null}
              {friendSelected.length ? (
                <>
                  <span style={{ fontWeight: 400 }}>cùng với&nbsp;</span>
                  {friendSelected
                    .slice(0, 3)
                    .map((el: any, index) => (
                      <React.Fragment key={index}>
                        <span
                          className={classes.textTag}
                          onClick={() => {
                            setOpenTagFriend(true);
                          }}
                        >
                          {el.display_name}
                        </span>
                      </React.Fragment>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
                  {friendSelected.length > 3 ? (
                    <>
                      <span style={{ fontWeight: 400 }}>&nbsp;và&nbsp;</span>
                      <span
                        className={classes.textTag}
                        onClick={() => {
                          setOpenTagFriend(true);
                        }}
                      >
                        {`${friendSelected.length - 3} người khác`}
                      </span>
                    </>
                  ) : null}
                </>
              ) : null}
              {locationSelected ? (
                <>
                  <span style={{ fontWeight: 400 }}>{` tại`}&nbsp;</span>

                  <span
                    className={classes.textTag}
                    onClick={() => {
                      setOpenLocation(true);
                    }}
                  >
                    {locationSelected.title}
                  </span>
                </>
              ) : null}
            </Typography>
          </div>

          {eventVisibility || post?.event ? (
            <div
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <i
                style={{ marginTop: 1, fontSize: '12px' }}
                className={
                  eventVisibility === 'public' ||
                  post?.event?.visibility === 'public'
                    ? 'fas fa-globe-asia'
                    : 'fa-solid fa-envelope'
                }
              ></i>{' '}
              &nbsp;
              <Typography
                className={classes.textLabel}
                style={{ width: '100%', wordWrap: 'break-word' }}
              >
                <span className={classes.lineClamp} style={{ width: '95%' }}>
                  {eventVisibility === 'public' ||
                  post?.event?.visibility === 'public'
                    ? 'Công khai'
                    : 'Chỉ những người được mời'}
                </span>
              </Typography>
            </div>
          ) : (
            <Box
              onClick={() => {
                if (
                  sharePostInNewFeed ||
                  (!post?.group &&
                    type !== 'stream_group' &&
                    type !== 'course' &&
                    type !== 'project')
                ) {
                  if (
                    post?.visibility !== 'scheduled' &&
                    pageShared === undefined
                  ) {
                    setOpenVisibility(true);
                  }
                }
              }}
              className={
                (!sharePostInNewFeed && post?.page) ||
                (!sharePostInNewFeed && type === 'page') ||
                type === 'header-post-page' ||
                // (groupShared && Object.keys(groupShared).length > 0) ||
                friendShared ||
                (post?.visibility === 'scheduled' && post?.group)
                  ? classes.disableVisibility
                  : classes.visibility
              }
            >
              {groupShared && Object.keys(groupShared).length > 0 ? (
                <Tooltip title={groupShared.title ?? ''} sx={{ width: '100%' }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {/* {groupShared.is_private ? (
                      <i
                        style={{ marginTop: 1, fontSize: '12px' }}
                        className="fa-solid fa-users"
                      ></i>
                    ) : (
                      <i
                        style={{ marginTop: 1, fontSize: '12px' }}
                        className="fa-solid fa-globe-asia"
                      ></i>
                    )}{' '} */}
                    <i
                      style={{ marginTop: 1, fontSize: '12px' }}
                      className={visibility?.icon}
                    ></i>{' '}
                    &nbsp;
                    <Typography
                      className={classes.textLabel}
                      style={{ width: '100%', wordWrap: 'break-word' }}
                    >
                      <span className={classes.lineClamp}>
                        {groupShared.is_private
                          ? 'Nhóm riêng tư'
                          : visibility.label}
                      </span>
                    </Typography>
                    &nbsp;
                    <i className="fas fa-caret-down"></i>
                  </div>
                </Tooltip>
              ) : friendShared ? (
                <Tooltip
                  title={friendShared?.title ?? friendShared?.display_name}
                  sx={{ width: '100%' }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <i
                      style={{ marginTop: 1, fontSize: '12px' }}
                      className="fa-solid fa-user"
                    ></i>{' '}
                    &nbsp;
                    <Typography className={classes.textLabel}>
                      <span className={classes.lineClamp}>
                        {visibility.label}
                      </span>
                    </Typography>
                    &nbsp;
                    <i className="fas fa-caret-down"></i>
                  </div>
                </Tooltip>
              ) : pageShared ? (
                <Tooltip title={pageShared?.title ?? ''} sx={{ width: '100%' }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <i
                      style={{ marginTop: 1, fontSize: '12px' }}
                      className="fa-solid fa-user"
                    ></i>{' '}
                    &nbsp;
                    <Typography className={classes.textLabel}>
                      <span className={classes.lineClamp}>
                        {pageShared?.title}
                      </span>
                    </Typography>
                    &nbsp;
                    <i className="fas fa-caret-down"></i>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <i
                    style={{ marginTop: 1 }}
                    className={`${visibility?.icon} fa-xs`}
                  ></i>{' '}
                  &nbsp;
                  <Typography className={classes.textLabel}>
                    {visibility?.label}
                  </Typography>
                  &nbsp;
                  {!post?.group && <i className="fas fa-caret-down"></i>}
                </>
              )}
            </Box>
          )}
        </div>
      </div>
    </>
  );
});

export default CUPostTop;
