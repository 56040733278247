import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClickAwayListener, Theme } from '@material-ui/core';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface Props {
  videoRef: any;
  handleSetPlaybackRate: Function;
  speed: number;
  resolution: number;
  setSpeed: Function;
  setResolution: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  MenuListSetting: {
    marginLeft: '8px',
    fontWeight: '600',
    minWidth: '90px',
    display: 'flex',
    justifyContent: 'end'
  }
}));

const speeds = [
  { value: 0.5, label: '0.5×' },
  { value: 0.75, label: '0.75×' },
  { value: 1.0, label: 'Thường' },
  { value: 1.25, label: '1.25×' },
  { value: 1.5, label: '1.5×' },
  { value: 1.75, label: '1.75×' },
  { value: 2, label: '2×' }
];
const SettingVideo = (props: Props) => {
  const classes = useStyles();
  const { videoRef, handleSetPlaybackRate, speed, setSpeed, resolution, setResolution } = props;
  const [tabSeting, setTabSeting] = React.useState('');

  const [levels, setLevels] = React.useState<any>([
    { value: -1, label: 'Tự động' }
  ]);
  const [optionSettings, setOptionSettings] = React.useState<any>([]);

  React.useEffect(() => {
    const hlsPlayer = videoRef.current?.getInternalPlayer('hls');
    if (hlsPlayer && hlsPlayer.levels) {
      const newLevels = hlsPlayer.levels?.map((level, id) => {
        return { value: id, label: `${level.height}p` };
      });
      setLevels([{ value: -1, label: 'Tự động' }, ...newLevels]);
    }
  }, [videoRef]);

  const onChangeBitrate = value => {
    const internalPlayer = videoRef.current?.getInternalPlayer('hls');
    if (internalPlayer) {
      internalPlayer.currentLevel = value < 0 ? -1 : value;
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setTabSeting('');
      }}
    >
      <Box>
        <IconButton
          style={{ color: '#fff' }}
          onClick={() => {
            if (tabSeting) {
              setTabSeting('');
            } else {
              setTabSeting('default');
            }
          }}
        >
          <SettingsIcon fontSize="small" />
        </IconButton>

        {tabSeting == 'default' && (
          <MenuList
            dense
            sx={{
              bottom: '50px',
              color: '#fff',
              float: 'right',
              marginRight: '20px',
              background: 'rgba(0, 0, 0, 0.4);',
              position: 'absolute',
              right: 0
            }}
          >
            <MenuItem
              onClick={() => {
                setTabSeting('resolution');
                setOptionSettings(levels);
              }}
            >
              <Typography variant="caption" sx={{ minWidth: '65px' }}>
                Chất lượng
              </Typography>

              <Typography variant="caption" className={classes.MenuListSetting}>
                {levels.find(el=>el.value === resolution)?.label}
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: '10px' }} />
            </MenuItem>

            <MenuItem
              onClick={() => {
                setTabSeting('speed');
                setOptionSettings(speeds);
              }}
            >
              <Typography sx={{ minWidth: '65px' }} variant="caption">
                Tốc độ
              </Typography>
              <Typography className={classes.MenuListSetting} variant="caption">
                {speeds.find(el=>el.value === speed)?.label}
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: '10px' }} />
            </MenuItem>
          </MenuList>
        )}
        {['resolution', 'speed'].includes(tabSeting) && (
          <MenuList
            dense
            sx={{
              bottom: '50px',
              color: '#fff',
              float: 'right',
              marginRight: '20px',
              background: 'rgba(0, 0, 0, 0.4);',
              paddingLeft: '8px',
              position: 'absolute',
              right: 0
            }}
          >
            <MenuItem
              onClick={() => {
                setTabSeting('default');
              }}
            >
              <Typography sx={{ minWidth: '65px' }} variant="caption">
                <ArrowBackIosIcon sx={{ fontSize: '10px' }} />
                {tabSeting == 'resolution' ? 'Chất lượng' : 'Tốc độ'}
              </Typography>
            </MenuItem>
            <Divider />
            <RadioGroup
              defaultValue={tabSeting == 'resolution' ? resolution : speed}
              onChange={event => {
                const value = (event.target as HTMLInputElement).value;
                if (tabSeting == 'resolution') {
                  setResolution(parseInt(value));
                  onChangeBitrate(parseInt(value));
                } else {
                  handleSetPlaybackRate(parseFloat(value));
                  setSpeed(parseFloat(value));
                }
              }}
            >
              {optionSettings.map((el: any) => (
                <FormControlLabel
                  value={el.value}
                  key={'formControlLabel' + el.value.toString()}
                  control={
                    <Radio
                      checked={
                        (resolution === el.value &&
                          tabSeting == 'resolution') ||
                        (speed === el.value && tabSeting == 'speed')
                      }
                      key={'radio' + el.value.toString()}
                      sx={{
                        color: '#fff',
                        '&.Mui-checked': {
                          color: '#fff'
                        }
                      }}
                      size="small"
                    />
                  }
                  label={<Typography variant="caption">{el.label}</Typography>}
                />
              ))}
            </RadioGroup>
          </MenuList>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SettingVideo;
