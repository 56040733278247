import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialEventAction';
import * as types from 'src/constants/store/eventSocial';
import { getDetailEventApi } from 'src/apis/socialEvent.api';
const typesSetting: any = types;

export const getEventDetailSagas = function* ({ payload }) {
  try {
    yield put(actions.loadingEvent(true));
    const { idEvent } = payload;
    let response = yield call(getDetailEventApi, idEvent);
    if (response.status === 200) {
      yield put(actions.loadingEvent(false));
      yield put(actions.getEventDetailSuccess(response.data));
      yield put(actions.checkEmptyLayout(true));
    }
  } catch (error: any) {
    yield put(actions.loadingEvent(false));
    yield put(actions.getEventDetailError(error.response));
    yield put(actions.checkEmptyLayout(false));
  }
};

export function* watchEventSocialAsync() {
  yield takeEvery(typesSetting.GET_EVENT_DETAIL_REQUEST, getEventDetailSagas);
}
