import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  ListItem,
  Grid,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Box
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles, createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { boxShadow, buttonColor, modalBackdrop } from 'src/constants/styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import iconCoin from 'src/assets/images/icon-coin.svg';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { DialogActions } from '@material-ui/core';
import { handleEcoin, handlePrice } from 'src/helpers/string';
import { rechargeEcoin } from 'src/apis/socialCourse.api';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import SocialCutImage from '../SocialCutImage/SocialCutImage';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        fontWeight: 'bold !important',
        fontSize: '20px !important'
      },
      wrapGoldCoin: {
        width: '62px',
        height: '62px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...boxShadow
      },
      wrapPrice: {
        marginLeft: '5px',
        '& .MuiListItemText-primary': {
          fontSize: '18px',
          fontWeight: '600'
        }
      },
      wrapAction: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
      }
    }),
  { index: 1 }
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: 700
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function ScrollDialog(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    setOpenDialogPaymentDetail,
    exchangeSelected,
    setOpenSnackbar,
    setNoti,
    setBalance
  } = props;
  const [isError, setIsError] = React.useState(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);

  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      expireDate: '',
      cvv: '',
      username: ''
    },
    // validationSchema: validationSchema,
    onSubmit: values => {
      // handleCreateUpdate(values);
    }
  });

  const handleRecharge = async () => {
    try {
      let data = {
        amount:
          exchangeSelected?.price / 1000 !== exchangeSelected?.amount
            ? exchangeSelected?.amount * 1000
            : exchangeSelected?.price,
        note: `${
          !!meInfo?.display_name ? meInfo?.display_name : meInfo?.username
        } đã nạp ${handlePrice(exchangeSelected?.price)} vào Social Network`
      };
      let res = await rechargeEcoin(data);
      if (res.status === 200) {
        setNoti(() => ({
          code: 200,
          message: 'Nạp E-XU thành công'
        }));
        setBalance(() => ({
          account_id: res?.data?.account?.id,
          balance: res?.data?.account_balance,
          updated_at: res?.data?.updated_at
        }));
        setOpenSnackbar(true);
        setOpenDialogPaymentDetail(false);
      } else {
        setNoti(() => ({
          code: 400,
          message: 'Nạp E-XU không thành công'
        }));
      }
    } catch (error) {
      setOpenSnackbar(true);
      setOpenDialogPaymentDetail(false);
    }
  };

  const handleOnChange = (field: any, event: any) => {
    formik.setFieldValue(field, event.target.value);
  };

  return (
    <div>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className={classes.title} textAlign={'center'}>
            Thông tin thanh toán
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <ListItem disableGutters>
                <ListItemAvatar className={classes.wrapGoldCoin}>
                  <SocialCutImage
                    image={iconCoin}
                    item={{
                      positionX: 1,
                      positionY: 3
                    }}
                    zoom={1.4}
                    style={{
                      height: '40px',
                      width: '40px'
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={handlePrice(exchangeSelected?.price)}
                  secondary={`${handleEcoin(exchangeSelected?.amount)} E-XU`}
                  className={classes.wrapPrice}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Thông tin thanh toán&ensp;
                <i
                  style={{ fontSize: '13px' }}
                  className="fa-solid fa-lock"
                ></i>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="cardNumber"
                onChange={e => handleOnChange('cardNumber', e)}
                fullWidth
                id="card-number"
                placeholder="Số thẻ"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          backgroundImage:
                            'url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/A9AWbxJI6Lp.png)',
                          backgroundPosition: formik?.values?.cardNumber
                            ? formik?.values?.cardNumber?.slice(0, 1) === '4'
                              ? '-64px -140px'
                              : '-66px -87px'
                            : '-66px -87px',
                          backgroundSize: 'auto',
                          width: '30px',
                          height: '20px',
                          backgroundRepeat: 'no-repeat',
                          display: 'inline-block'
                        }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="expireDate"
                  id="expire"
                  placeholder="MM/YY"
                  onChange={e => handleOnChange('expireDate', e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="cvv"
                  id="cvv"
                  placeholder="CVV"
                  onChange={e => handleOnChange('cvv', e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="username"
                id="username"
                placeholder="Tên chủ thẻ"
                onChange={e => handleOnChange('username', e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className={classes.wrapAction}>
            <ButtonInherit
              label="Hủy"
              style={{ marginRight: 0, marginLeft: 5 }}
              action={handleClose}
            />
            <ButtonInherit
              disabled={isError}
              label="Nạp"
              color={buttonColor.backgroundColor}
              style={{ marginRight: 0, marginLeft: 5 }}
              action={() => {
                handleRecharge();
              }}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
