import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  addLabelPage,
  getTeamInfoById,
  removeLabelPage
} from 'src/apis/socialChat.apis';
import { Box, Popper, Typography } from '@mui/material';
import { removeLabelTotalPage } from 'src/store/action/socialChatAction';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/base/';
import LableChatPage from './LableChatPage';
import CustomizedSnackBars from 'src/components/Snackbar/SnackbarSocial';
const colorLabelOptions = [
  {
    color: '#63BE09',
    title: 'Xanh lá'
  },
  {
    color: '#fb9240',
    title: 'Cam'
  },
  {
    color: '#5291ff',
    title: 'Xanh da trời'
  },
  {
    color: '#f65569',
    title: 'Đỏ'
  },
  {
    color: '#8a73c9',
    title: 'Tím'
  },
  {
    color: '#90949c',
    title: 'Xám'
  }
];

function CustomizedMenus(props) {
  const { teamId, label, setLabel, handleDeleteFilter, checked, setChecked } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElPopover, setAnchorElPopover] =
    React.useState<null | HTMLElement>(null);
  const [labelFilter, setLabelFilter] = React.useState<any>([]);
  const [titleLabel, setTitleLabel] = React.useState<string>('');
  const [colorLabel, setColorLabel] = React.useState<string>('#63BE09');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openFormAddLable, setOpenFormAddLable] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const [isToastMessage, setIsToastMessage] = React.useState<boolean>(false);
  const [codeMessage, setCodeMessage] = React.useState<any>(200);
  const [titleToastMessage, setTitleToastMessage] = React.useState<string>('');
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchLabelPage = async () => {
    try {
      const response = await getTeamInfoById(teamId);
      if (response.status === 200) {
        setLabelFilter(response.data.teamInfo?.label || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchLabelPage();
  }, [teamId, anchorEl]);
  const handleClickAddLabel = chipSelected => {
    const index = label.findIndex(el => el.title === chipSelected.title);
    if (index === -1) {
      setLabel([...label, chipSelected]);
    }
  };

  const handleAddLabelPage = async () => {
    try {
      const label = {
        color: colorLabel,
        title: titleLabel
      };
      if (titleLabel) {
        const response = await addLabelPage(teamId, label);
        if (response.status === 200) {
          setLabelFilter([...labelFilter, label]);
          setTitleLabel('');
          setOpenFormAddLable(false);
          setIsToastMessage(true);
          setCodeMessage(200);
          setTitleToastMessage('Thêm nhãn mới thành công.');
        }
      }
    } catch (error) {
      setIsToastMessage(true);
      setCodeMessage(400);
      setTitleToastMessage(error?.response?.data?.error);
    }
  };

  const handleDelete = chipToDelete => async () => {
    try {
      const response = await removeLabelPage(teamId, chipToDelete);
      if (response.status === 200) {
        setLabelFilter(chips =>
          chips.filter(chip => chip.title !== chipToDelete.title)
        );
        setLabel(chips =>
          chips.filter(chip => chip.title !== chipToDelete.title)
        );

        dispatch(removeLabelTotalPage(chipToDelete));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputTitleLabel = e => {
    if (e.target.value.length <= 20) {
      setTitleLabel(e.target.value);
    }
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13 && titleLabel) {
      handleAddLabelPage();
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box sx={{ position: 'relative' }}>
          <Button
            id={teamId}
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            aria-describedby={id}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              textTransform: 'none',
              padding: '6px 12px ',
              fontSize: '14px',
              color: '#fff',
              backgroundColor:
                theme.palette.mode === 'light' ? '#7165e0' : '#4b4b4b',
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'light' ? '#7D72DF' : '#424648'
              }
            }}
          >
            Nhãn
          </Button>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 100 }}
          >
            <Box
              sx={{
                backgroundColor: 'background.primary',
                borderRadius: '4px',
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                border: '1px solid rgba(0,0,0,0.2)',
                padding: '0px 8px'
              }}
            >
              <Typography
                sx={{
                  paddingTop: '6px',
                  margin: '0xp 8px',
                  fontWeight: 500,
                  paddingLeft: '6px'
                }}
              >
                Tìm kiếm theo nhãn
              </Typography>

              <LableChatPage
                labelFilter={labelFilter}
                checked={checked}
                handleChange={handleChange}
                handleClickAddLabel={handleClickAddLabel}
                handleClickOpenDialog={handleClickOpenDialog}
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                openFormAddLable={openFormAddLable}
                handleDelete={handleDelete}
                setOpenFormAddLable={setOpenFormAddLable}
                handleInputTitleLabel={handleInputTitleLabel}
                handleOnKeyDown={handleOnKeyDown}
                anchorElPopover={anchorElPopover}
                setAnchorElPopover={setAnchorElPopover}
                colorLabel={colorLabel}
                colorLabelOptions={colorLabelOptions}
                setColorLabel={setColorLabel}
                handleAddLabelPage={handleAddLabelPage}
                handleDeleteFilter={handleDeleteFilter}
                titleLabel={titleLabel}
              />
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
      {isToastMessage ? (
        <CustomizedSnackBars
          open={isToastMessage}
          setOpen={setIsToastMessage}
          message={titleToastMessage}
          code={codeMessage}
        />
      ) : null}
    </>
  );
}
export default React.memo(CustomizedMenus);
