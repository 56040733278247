import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import {
  AvatarGroup,
  Box,
  CssBaseline,
  Grid,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { groupRelationshipApi } from 'src/apis/socialGroup';
import { checkPasswordUser, getStatusUser } from 'src/apis/socialUser';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import Emso from 'src/assets/images/logoEmso/logoEmso.png';
import logoFull from 'src/assets/images/logoEmso/logoFull.png';
import { collapseString } from 'src/common/string';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import ShortCutSideBar from 'src/components/ShortCutSideBar';
import Sidebar from 'src/components/Sidebar/Sidebar';
import WatchPlayer from 'src/components/VideoPlayer/WatchPlayer';
import { PATHS } from 'src/constants/paths';
import HeaderContainer from 'src/containers/HeaderContainer/Index';
import useWindowDimensions from 'src/hooks/useSizeScreen';
import BloodDonationLeft from 'src/pages/BooldDonation/BloodDonationLeft';
import CelebrateLeft from 'src/pages/Celebrate/CelebrateLeft';
import BadgeChat from 'src/pages/Chat/BadgeChat';
import BadgeCreate from 'src/pages/Chat/BadgeCreate';
import BoxChat from 'src/pages/Chat/BoxChat/BoxChat';
import BoxCreateChat from 'src/pages/Chat/BoxCreateChat';
import EventLeft from 'src/pages/Events/EventLeft';
import FriendBar from 'src/pages/Friends/FriendBar';
import GroupLeft from 'src/pages/Groups/GroupLeft';
import LiveStreamLeft from 'src/pages/LiveStream/LiveStreamLeft';
import MomentBar from 'src/pages/Moment/MomentBar';
import MediaDetail from 'src/pages/PageInfomation/MediaDetail';
import PostSavedLeft from 'src/pages/PageInfomation/PostSaved/PostSavedLeft';
import PageLeft from 'src/pages/Pages/PageLeft';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';
import DiaryUserLeft from 'src/pages/User/Diary/DiaryLeft';
import SettingsUserLeft from 'src/pages/User/Settings/SettingsLeft';
import WatchBar from 'src/pages/Watch/WatchBar';
import DialogWatchScreen from 'src/pages/Watch/WatchScreen/DialogWatchScreen';
import { connectStreaming, receiveStreaming } from 'src/socket/Streaming';
import SocketChat from 'src/socket/socketChat';
import { resetEvent } from 'src/store/action/socialEventAction';
import {
  getGroupDetailReq,
  groupRelationShipReq,
  resetGroup
} from 'src/store/action/socialGroupAction';
import {
  getListMyPageReq,
  resetPage,
  resetPageTarget
} from 'src/store/action/socialPageAction';
import {
  getListBookmarkCollectionReq,
  resetActivities
} from 'src/store/action/socialPostAction';
import { resetSearchGlobal } from 'src/store/action/socialSearchGlobalAction';
import {
  resetWatchSelectedAction,
  timePlayAction
} from 'src/store/action/socialWatchAction';
import {
  getInfoMeReq,
  getInfoMeSuccess,
  resetUser
} from 'src/store/action/userAction';

import moment from 'moment';
import Notification from 'src/Notification/Notification';
import { getListReportMusic, getNewMusicApi } from 'src/apis/SocialMusic.api';
import { useMatchMedia } from 'src/common/useMatchMedia';
import DialogConfirm from 'src/components/Dialog/DialogConfirm';
import DialogCreatePass from 'src/components/Dialog/DialogCreatePass';
import DialogVerifiedPass from 'src/components/Dialog/DialogVerifiedPass';
import {
  default as CustomizedSnackBars,
  default as SnackbarNotification
} from 'src/components/Snackbar/SnackbarSocial';
import MiniPlayer from 'src/components/VideoPlayer/MiniPlayer';
import { buttonColor } from 'src/constants/styles';
import { ColorModeContext } from 'src/context/ThemeContext';
import BadgeChatPlus from 'src/pages/Chat/BadgeChatPlus';
import Complain from 'src/pages/Complain';
import CourseLeft from 'src/pages/Courses/CourseLeft';
import GrowLeft from 'src/pages/Grows/GrowLeft';
import HelperLeft from 'src/pages/Helper_ES/HelperLeft';
import PaymentLeft from 'src/pages/HistoryPayment/PaymentLeft';
import LiveBar from 'src/pages/LiveStream/LiveBar';
import MarketplaceLeft from 'src/pages/Marketplaces/MarketplaceLeft';
import { NavigationSidebar } from 'src/pages/Marketplaces/MarketplaceLeft/NavigationSidebar';
import MusicSpaceBar from 'src/pages/MusicSpace/MusicSpaceBar';
import MusicPlayerSlider from 'src/pages/MusicSpace/MusicSpaceHome/MusicSpaceList/MusicPlayerSlider';
import RecruitLeft from 'src/pages/Recruits/RecruitLeft';
import SearchLeft from 'src/pages/Watch/WatchSearch/SearchLeft';
import { checkHasScroll } from 'src/store/action/settingAction';
import { networkRestoreSuccess } from 'src/store/action/socialChatAction';
import { resetCourse } from 'src/store/action/socialCourseAction';
import { getListFriendsReq } from 'src/store/action/socialFriendsAction';
import { resetGrow } from 'src/store/action/socialGrowAction';
import {
  getListMusic,
  listReportMusic
} from 'src/store/action/socialMusicAction';
import { loginPayment } from 'src/store/action/socialPaymentAction';
import { resetProduct } from 'src/store/action/socialProductAction';
import { resetRecruit } from 'src/store/action/socialRecruitAction';
import { getListReportReq } from 'src/store/action/socialReportAction';
import { accountSetting } from 'src/store/action/socialUserAction';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    borderBottom: '1px solid transparent'
  },
  main: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    textAlign: 'unset'
  },
  grid: {
    marginTop: '56px !important',
    height: 'calc(100vh - 56px)',
    // overflow: 'auto',
    position: 'relative',
    paddingBottom: '1px'
  },
  gridItem: {
    paddingTop: '0px !important',
    flex: 1
  },
  gridItemSidebar: {
    height: '100%',
    paddingTop: '0px !important',
    zIndex: 100,
    width: '310px !important',
    position: 'relative'
  },
  gridItemSidebarFullLeft: {
    height: '100%',
    paddingTop: '0px !important',
    width: '360px !important',
    position: 'relative',
    zIndex: 1003,
    marginLeft: '-50px !important'
  },
  sidebar: {
    height: '100%',
    paddingTop: '0px !important',
    zIndex: 100,
    width: '300px !important',
    position: 'relative'
  },
  sidebarResponsive: {
    height: '100%',
    paddingTop: '0px !important',
    zIndex: 100,
    width: '50px !important',
    position: 'relative'
  },
  gridNoItemSidebar: {
    height: '0px',
    paddingTop: '0px !important',
    zIndex: 100,
    width: '0px !important',
    position: 'relative'
  },
  wrapIcon: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginLeft: 10
  },
  menuHidden: {
    position: 'fixed',
    zIndex: 1000,
    top: 55,
    left: 315,
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E4E6EB',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  menuDisplay: {
    position: 'fixed',
    zIndex: 1000,
    top: 60,
    left: 15,
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E4E6EB',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 36
  },
  text_input: {
    display: 'block',
    height: '100%',
    marginLeft: 8,
    width: '100%',
    fontSize: '14px',
    padding: '8px 5px'
  },
  group_button: {
    display: 'flex',
    alignItems: 'center'
  },
  action_button: {
    width: 32,
    height: 32
  },
  gridItemSidebarChatPage: {
    height: '100%',
    paddingTop: '0px !important',
    zIndex: 100,
    position: 'relative'
  },
  postSavedLeft: {
    [theme.breakpoints.down(1000)]: {
      display: 'none !important'
    }
  },
  showLeft: {
    [theme.breakpoints.down(1000)]: {
      display: 'block',
      width: '100vw'
    }
  }
}));
interface Props {
  children: ReactNode;
}
const routeHelper = [
  PATHS.HELPER,
  PATHS.HELPER_ITEM,
  PATHS.HElPER_WRAP,
  PATHS.TERMS_ITEM,
  PATHS.POLICY_DOC
];

const pathShowLeft = [
  PATHS.PAGE_CREATE,
  PATHS.EVENT_CREATE,
  PATHS.GROUP_CREATE,
  PATHS.COURSE_CREATE,
  PATHS.RECRUIT_CREATE,
  PATHS.GROW_CREATE
];
export default function MainLayout(props: Props) {
  const { children } = props as any;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1000px)');
  const displayZoomDown = useMatchMedia('(min-width:600px)', true);
  const isMobile = useMediaQuery('(max-width:500px)');
  const isSmallMobile = useMediaQuery('(max-width:360px)');
  const colorMode = useContext(ColorModeContext);
  const [isOpenShortCut, setIsOpenShortCut] = React.useState(false);
  const [numberTitle, setNumberTitle] = React.useState<any>();
  const [isShown, setIsShown] = React.useState(false);
  const [isInitialDialogOpen, setIsInitialDialogOpen] = React.useState(false); // Dùng để check nếu người dùng truy cập lần đầu link có ?v=watchId thì component dưới dialog k render
  const [listConversationPlus, setListConversationPlus] = React.useState<any>(
    []
  );
  const [isConnection, setIsConnection] = React.useState(false);
  const [isConnectionStatus, setIsConnectionStatus] =
    React.useState<string>('');
  const meInfo = useSelector((state: any) => state.meReducer);
  const page = useSelector((state: any) => state.pageReducer.info);
  const group = useSelector((state: any) => state.groupReducer.groupSelected);
  const musicData = useSelector((state: any) => state.musicReducer);
  const rolePreviewEdit: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const groupRelationShip = useSelector(
    (state: any) => state.groupReducer.groupRelationShip
  );
  const groupSelected = useSelector(
    (state: any) => state.groupReducer.groupSelected
  );
  const watchSelected = useSelector(
    (state: any) => state.watchReducer.watchSelected
  );
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const my_pages = useSelector((state: any) => state.pageReducer.my_pages);
  const listReport = useSelector(
    (state: any) => state.socialReportReducer.listReport
  );
  const isShowAudio = useSelector(
    (state: any) => state.musicReducer.isShowMiniAudio
  );
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );

  const dataMiniPlayer = useSelector((state: any) => state.watchReducer);

  const eventInfo = useSelector((state: any) => state.eventReducer.info);
  const growInfo = useSelector((state: any) => state.growReducer.info);
  const courseInfo = useSelector((state: any) => state.courseReducer.info);
  const recruitInfo = useSelector((state: any) => state.recruitReducer.info);
  const productInfo = useSelector((state: any) => state.productReducer.info);
  const dataMusicSpace = useSelector(
    (state: any) => state.musicReducer.listMusic
  );
  const postInfo = useSelector(
    (state: any) => state.socialPostReducer.notiSharePost
  );
  const statusPayment = useSelector(
    (state: any) => state.paymentReducer.status
  );
  const scrollBody = useSelector((state: any) => state.scrollReducer.position);
  const errorManyReq = useSelector((state: any) => state.userReducer.errorReq);
  const dataErrorReq = useSelector(
    (state: any) => state.userReducer.dataErrorReq
  );

  let hasVerticalScrollbar =
    document.documentElement.scrollHeight > window.innerHeight;
  const match: any = useRouteMatch();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [widthLeft, setWidthLeft] = React.useState<any>('block');
  const [openWatchScreen, setOpenWatchScreen] = React.useState(false);
  const [openCreatePass, setOpenCreatePass] = React.useState(false);
  const [typeWatch, setTypeWatch] = React.useState('');
  const [openMediaPost, setOpenMediaPost] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openMiniPlayer, setOpenMiniPlayer] = React.useState(false);
  const [openVerifiedPass, setOpenVerifiedPass] = React.useState(false);
  const [openErrorReq, setOpenErrorReq] = React.useState<boolean>(false);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const refScroll = useRef(null);
  const dataMe: any = localStorage.getItem('me');
  const isStatus = localStorage.getItem('isStatus');
  const meCache = JSON.parse(dataMe);
  const [initialScrollTop, setInitialScrollTop] = React.useState(0);
  const token: any = localStorage.getItem('token');
  const [isBannerHidden, setIsBannerHidden] = React.useState(false);

  React.useEffect(() => {
    if (
      !statusPayment &&
      (match.path === PATHS.PAYMENT ||
        match.path === PATHS.PAYMENT_MONEY ||
        match.path === PATHS.PAYMENT_HISTORY ||
        match.path === PATHS.PAYMENT_COIN ||
        match.path === PATHS.PAYMENT_BUY ||
        match.path === PATHS.PAYMENT_BANK ||
        match.path === PATHS.PAYMENT_TERMS ||
        match.path === PATHS.PAYMENT_VTV_PAY)
    ) {
      setOpenVerifiedPass(true);
    } else {
      setOpenVerifiedPass(false);
    }
  }, [statusPayment, match.path]);

  //tắt DialogWatchScreen khi đường dẫn thay đổi
  React.useEffect(() => {
    if (match.path === PATHS.PAGE_DETAIL) setOpenWatchScreen(false);
  }, [match.path]);

  React.useEffect(() => {
    if (errorManyReq) {
      setOpenErrorReq(true);
    } else {
      setOpenErrorReq(false);
    }
  }, [errorManyReq]);

  React.useEffect(() => {
    const handleOnline = () => {
      setIsConnection(true);
      setIsConnectionStatus('online');
      localStorage.setItem('isStatus', 'online');

      if (listPopupChat?.length > 0) {
        let newArr = listPopupChat.map(el => {
          return [...el.listMessage];
        });
        let flattenedArray = [].concat(...newArr);

        let messageData = flattenedArray.filter(
          (message: any) => message.status === 'error'
        );

        let messageDataSort = messageData
          .sort((a: any, b: any) => a._updatedAt.$date - b._updatedAt.$date)
          .reverse();

        dispatch(networkRestoreSuccess(messageDataSort));
      }
    };

    const handleOffline = () => {
      setIsConnection(true);
      setIsConnectionStatus('offline');
      localStorage.setItem('isStatus', 'offline');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [JSON.stringify(listPopupChat)]);

  useEffect(() => {
    if (hasVerticalScrollbar !== undefined) {
      dispatch(checkHasScroll(hasVerticalScrollbar));
    }
  }, [hasVerticalScrollbar]);

  useEffect(() => {
    if (match.path.includes('music')) {
      getListCategoriesReportMusic();
    }
  }, [JSON.stringify(match.path)]);

  const queuePopupChat = useSelector(
    (state: any) => state.socialChatReducer.queuePopupChat
  );
  React.useEffect(() => {
    const data = queuePopupChat
      .filter(
        item => item.conversation?.type && !item.conversation?.user_chat_page
      )
      .slice(5);
    setListConversationPlus(data);
  }, [queuePopupChat]);

  const isOpenBoxCreate = useSelector(
    (state: any) => state.socialChatReducer.isOpenBoxCreate
  );

  const getListCategoriesReportMusic = async () => {
    const data = await getListReportMusic();
    dispatch(listReportMusic(data));
  };

  const getDataMeInfo = useCallback(() => {
    if (!meInfo?.info?.id) {
      if (isStatus === 'offline' && dataMe) {
        const newDataMe: any = JSON.parse(dataMe);
        dispatch(getInfoMeSuccess(newDataMe));
      } else if (!routeHelper.includes(match.path)) {
        dispatch(getInfoMeReq());
      }
    }
  }, [meInfo?.info?.id]);

  const handleCheckLoginUser = async () => {
    try {
      let response = await checkPasswordUser({ current_password: password });
      if (response.status === 200) {
        setOpenVerifiedPass(false);
        setLoadingAction(false);
        dispatch(loginPayment('success'));
        //by notification
        if (match.path === '/emso_payment/payment_history')
          history.push(`${match.path}?status=all`);
        else history.push('/emso_payment/home');
      }
    } catch (err: any) {
      dispatch(loginPayment('faild'));
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: err.response?.data.error
      });
      setLoadingAction(false);
    }
  };

  const checkAdminGroup = async id => {
    let response: any = await groupRelationshipApi({
      groupIds: [id]
    });
    if (response.status === 200) {
      dispatch(groupRelationShipReq(response.data));
    }
  };

  const getNewMusic = async () => {
    try {
      let res = await getNewMusicApi({ limit: 18 });
      if (res.status === 200) {
        localStorage.setItem('listMusic', JSON.stringify(res.data));
        if (musicData.listMusic.length === 0) {
          dispatch(
            getListMusic({
              data: res.data,
              index: 0,
              playAudio: false
            })
          );
        }
      }
    } catch (error) {}
  };

  const fetchChatSetting = async () => {
    try {
      const response = await getStatusUser();
      if (response.status === 200) {
        const data = response.data;
        dispatch(accountSetting(data));
      }
    } catch (error) {
      console.log('[fetchChatSetting]', error);
    }
  };

  let query = useQuery();
  const queryLive = query.get('host');
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  React.useEffect(() => {
    dispatch(resetGroup());
    dispatch(resetActivities());

    if (
      (match.params?.id || match.params?.groupId) &&
      (match.path.includes('group') || match.path.includes('groups'))
    ) {
      if (match.params?.id || match.params?.groupId) {
        dispatch(
          getGroupDetailReq(match.params?.id || match.params?.groupId, '')
        );
      }
    }
  }, [match.params?.id, match.params?.groupId]);

  React.useEffect(() => {
    if (groupSelected?.id) {
      checkAdminGroup(groupSelected?.id);
    }
  }, [groupSelected?.id]);
  React.useEffect(() => {
    if (numberTitle !== undefined) {
      document.title = `${
        numberTitle === 0 ? '' : `(${numberTitle})`
      } Mạng xã hội Emso - Mạng xã hội vì người Việt`;
    }
  }, [numberTitle]);
  React.useEffect(() => {
    let { id }: any = match.params;
    if (match.path === PATHS.EVENT_DETAIL && !id) {
      dispatch(resetEvent());
    }
    if (match.path === PATHS.GROW_DETAIL && !id) {
      dispatch(resetGrow());
      dispatch(resetPageTarget());
    }
    if (match.path === PATHS.RECRUIT_DETAIL && !id) {
      dispatch(resetRecruit());
    }
    if (match.path === PATHS.COURSE_DETAIL && !id) {
      dispatch(resetCourse());
    }
    if (match.path === PATHS.PRODUCT_DETAIL && !id) {
      dispatch(resetProduct());
    }

    //check lại PostActionButton nếu sửa đoạn dưới này
    // {
    //reset data event in redux when change path.
    if (!match.path.includes('event')) {
      dispatch(resetEvent());
    }

    //reset data grow in redux when change path.
    if (!match.path.includes('grow')) {
      dispatch(resetGrow());
      dispatch(resetPageTarget());
    }

    //reset data grow in redux when change path.
    if (!match.path.includes('course')) {
      dispatch(resetCourse());
      dispatch(resetPageTarget());
    }
    // }
  }, [JSON.stringify(match.path), JSON.stringify(match.params?.id)]);

  React.useEffect(() => {
    if (query.get('v')) {
      setIsInitialDialogOpen(true);
    }
  }, []);
  React.useEffect(() => {
    if (query.get('v')) {
      setOpenWatchScreen(true);
      if (query.get('type') === 'post_saved') {
        setTypeWatch('post_saved');
      } else if (query.get('page_id')) {
        setTypeWatch('post_page');
      } else {
        setTypeWatch('watch-detail');
      }
    }
    if (match.path === PATHS.WATCH_PAGE) {
      setTypeWatch('page');
    }
    if (match.params.key === 'watch-new') {
      setTypeWatch('watch-new');
    }
    if (match.path === PATHS.WATCH_SEARCH) {
      setTypeWatch('watch-search');
    }
  }, [match.path, match.params.key, query.get('v')]);

  React.useEffect(() => {
    if (query.get('postMediaId')) {
      if (query.get('mediaId') && history.action === 'POP') {
        setOpenMediaPost(true);
      }
    } else if (!query.get('postMediaId') && query.get('mediaId')) {
      if (query.get('mediaId') && history.action === 'POP') {
        setOpenMediaPost(true);
      }
    } else setOpenMediaPost(false);
  }, [query.get('mediaId')]);

  React.useEffect(() => {
    if (meInfo && Object.keys(meInfo).length) {
      let dataStorage: any = localStorage.getItem('dataUser');
      let dataUser: any = JSON.parse(dataStorage) ?? [];
      let isFastLogin: any = localStorage.getItem('isFastLogin');
      let listdataStorage: any = localStorage.getItem('userRememberPassword');
      let listdataUser: any = JSON.parse(listdataStorage) ?? [];
      let index = dataUser?.findIndex((el: any) => el.id === meInfo.info?.id);
      if (index >= 0) {
        dataUser = [
          ...dataUser.slice(0, index),
          {
            id: meInfo.info?.id,
            name: meInfo.info?.display_name,
            email: meInfo.info.email,
            show_url:
              meInfo.info?.avatar_media?.show_url ??
              meInfo.info?.avatar_media?.url ??
              avatarDefault,
            token: token,
            username: meInfo.info?.username,
            darkThemeUser: meInfo.info?.theme,
            isFastLogin: isFastLogin ? isFastLogin : 'no'
          },
          ...dataUser.slice(index + 1)
        ];
      } else {
        dataUser.push({
          id: meInfo.info?.id,
          name: meInfo.info?.display_name,
          email: meInfo.info.email,
          show_url:
            meInfo.info?.avatar_media?.show_url ??
            meInfo.info?.avatar_media?.url ??
            avatarDefault,
          token: token,
          username: meInfo.info?.username,
          darkThemeUser: meInfo.info?.theme,
          isFastLogin: isFastLogin ? isFastLogin : 'no'
        });
      }

      localStorage.setItem('darkThemeUser', meInfo.info?.theme);
      if (meInfo.info?.theme) {
        colorMode.toggleColorMode(meInfo.info?.theme);
      }
      listdataUser.push({
        id: meInfo.info?.id,
        isFastLogin: isFastLogin
      });
      localStorage.setItem(
        'dataUser',
        JSON.stringify(
          _.uniqBy(
            dataUser?.filter((el: any) => el.id),
            'id'
          )
        )
      );
      localStorage.setItem(
        'userRememberPassword',
        JSON.stringify(
          _.uniqBy(
            listdataUser?.filter((el: any) => el.id),
            'id'
          )
        )
      );
    }
  }, [JSON.stringify(meInfo?.info)]);

  React.useEffect(() => {
    if (postInfo?.code) {
      setOpenSnackbar(true);
    }
  }, [postInfo]);

  React.useEffect(() => {
    fetchChatSetting();
    getNewMusic();
  }, []);

  useEffect(() => {
    getDataMeInfo();
    if (!my_pages?.length) {
      dispatch(getListMyPageReq({ page: 1, limit: 10, status: 'active' }));
    }
    if (match.path !== PATHS.SEARCH_GLOBAL) {
      dispatch(resetSearchGlobal());
    }

    connectStreaming();
    receiveStreaming();
    // Nếu has more là true sẽ hiển thị animation load page
    // Khi đổi path đều set giá trị về true. Vào phần load page chấp nhận load lại 1 lần để set giá trị về false
    // Action load này thường dùng trong scroll.
    // Vấn đề gặp phải một số account không đủ số lượng page để hiển thị thanh scroll khiến không thực hiện được action load này để set has more về false
    // Nên animation load page luôn luôn hiển thị
    // return () => {
    //   dispatch(updateHasMore(true));
    // };
  }, [JSON.stringify(match.path)]);

  useEffect(() => {
    if (!match.params.id && !query.get('page_id')) {
      dispatch(resetPage());
      dispatch(resetPageTarget());
    }
  }, [JSON.stringify(match.params.id), query.get('page_id')]);

  useEffect(() => {
    if (
      match.path === PATHS.PAGE_SAVED ||
      match.path === PATHS.PAGE_SAVED_DETAIL
    ) {
      dispatch(getListBookmarkCollectionReq());
    }
  }, [match.path]);

  useEffect(() => {
    if (meInfo?.error) {
      let listdataStorage: any = localStorage.getItem('userRememberPassword');
      let listdataUser: any = JSON.parse(listdataStorage) ?? [];
      let index = listdataUser?.findIndex(
        (el: any) => el.id === meInfo?.info?.id
      );
      if (index >= 0) {
        listdataUser = [
          ...listdataUser.slice(0, index),
          {
            id: meInfo?.info?.id,
            isFastLogin: 'no'
          },
          ...listdataUser.slice(index + 1)
        ];
        localStorage.setItem(
          'userRememberPassword',
          JSON.stringify(listdataUser)
        );
      }
      history.push(`${PATHS.LOGIN}`);
      dispatch(resetUser());
    }
  }, [JSON.stringify(meInfo?.error)]);

  useEffect(() => {
    if (!token && routeHelper.includes(match.path)) {
      localStorage.setItem('darkThemeUser', 'light');
    }
  }, [match.path]);

  useEffect(() => {
    const heightScroll = window.scrollY;
    if (heightScroll > 0) {
      setInitialScrollTop(heightScroll);
    }
  }, [window.scrollY]);

  React.useEffect(() => {
    if (scrollBody) {
      if (['hidden', 'auto'].includes(scrollBody)) {
        document.body.style.overflow = scrollBody;
        if (scrollBody == 'hidden') {
          document.head.style.marginRight = '17px';
          document.body.style.marginRight = '17px';
        } else {
          document.head.style.marginRight = '0px';
          document.body.style.marginRight = '0px';
        }
      } else {
        document.body.style.position = scrollBody;
        window.scrollTo(0, initialScrollTop);
      }
    }
  }, [scrollBody]);

  React.useEffect(() => {
    if (!listReport?.length) {
      dispatch(getListReportReq());
    }
    if (friends.length < 1 && meInfo?.info?.id) {
      dispatch(getListFriendsReq(meInfo?.info?.id, { limit: 20 }));
    }
  }, [match.path, JSON.stringify(meInfo)]);

  React.useEffect(() => {
    if (meInfo?.info?.reset_password_required) {
      setOpenCreatePass(true);
    } else {
      setOpenCreatePass(false);
    }
  }, [JSON.stringify(meInfo?.info?.reset_password_required)]);

  const renderDataMenu: any = () => {
    switch (match.path) {
      case PATHS.USER: {
        if (match.params.key === 'search') {
          return {
            componentRight:
              width > 1200 ? (
                <Grid className={classes.gridItemSidebar} container item>
                  <SearchGlobalLeft type="user" />
                </Grid>
              ) : null
          };
        } else return { componentRight: 'none', component: null };
      }
      // case PATHS.GROUP_ACTIVITIES_USER: {
      //   return {
      //     componentRight: (
      //       <Grid className={classes.gridItemSidebarFullLeft} container item>
      //         <GroupLeft />
      //       </Grid>
      //     ),
      //     component: (
      //       <GroupActivitiesLogUser
      //         groupSelected={groupSelected}
      //         groupRelationShip={groupRelationShip}
      //       />
      //     )
      //   };
      // }
      case PATHS.PAGE_HASHTAG:
      case PATHS.POST_DETAIL: {
        return {
          componentRight: 'none'
        };
      }
      case PATHS.GROUP_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <GroupLeft />
            </Grid>
          )
        };
      case PATHS.GROUP:
      case PATHS.GROUP_DISCOVER:
      case PATHS.GROUP_CATEGORY:
      case PATHS.GROUP_SEARCH:
      case PATHS.GROUP_JOINS:
      case PATHS.GROUP_REQUEST:
      case PATHS.GROUP_POST_DETAIL:
      case PATHS.GROUP_DETAIL:
      case PATHS.GROUP_ACTIVITIES_USER: {
        if (match.path === PATHS.GROUP_ACTIVITIES_USER) {
          return {
            componentRight: 'none'
          };
        } else if (
          match.params.key === 'search' &&
          (groupSelected?.group_relationship?.member ||
            !groupSelected.is_private)
        ) {
          return {
            componentRight:
              width > 1000 ? (
                <Grid className={classes.gridItemSidebar} container item>
                  <SearchGlobalLeft type="group" />
                </Grid>
              ) : null
          };
        } else if (false) {
        } else {
          return {
            componentRight:
              Object.keys(groupRelationShip).length &&
              !groupRelationShip.admin &&
              !groupRelationShip.moderator &&
              (match.params?.id || match.params?.groupId) ? (
                'none'
              ) : widthLeft !== 'none' ? (
                !matches &&
                (groupSelected?.group_relationship?.admin ||
                  groupSelected?.group_relationship?.moderator ||
                  !match.params?.id) ? (
                  <Grid
                    sx={{ display: widthLeft }}
                    className={classes.gridItemSidebar}
                    container
                    item
                  >
                    <GroupLeft />
                  </Grid>
                ) : (
                  'none'
                )
              ) : (
                <div
                  onClick={() => {
                    setWidthLeft('block');
                  }}
                  className={classes.menuDisplay}
                >
                  <Tooltip title="Hiện menu">
                    <i className="fa-regular fa-sidebar-flip fa-lg"></i>
                  </Tooltip>
                </div>
              )
          };
        }
      }

      case PATHS.EVENT_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <EventLeft />
            </Grid>
          )
        };
      case PATHS.EVENT:
      case PATHS.EVENT_MINE:
      case PATHS.EVENT_DETAIL:
      case PATHS.EVENT_CATEGORY:
        return {
          componentRight:
            width > 1000 ||
            (match.params?.key === 'edit' && match.params?.id) ? (
              match.params?.key === 'edit' &&
              !(
                eventInfo?.event_relationship?.host_event ||
                eventInfo?.page_owner?.page_relationship?.role === 'owner' ||
                eventInfo?.page_owner?.page_relationship?.role === 'admin' ||
                eventInfo?.page_owner?.page_relationship?.role === 'moderator'
              ) ? null : (
                <Grid
                  className={
                    !(match.params?.key === 'edit' && !!match.params?.id)
                      ? classes.gridItemSidebar
                      : classes.gridItemSidebarFullLeft
                  }
                  container
                  item
                >
                  <EventLeft />
                </Grid>
              )
            ) : null
        };
      case PATHS.PAGE_SAVED_DETAIL:
      case PATHS.PAGE_SAVED: {
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebar} container item>
              <PostSavedLeft />
            </Grid>
          )
        };
      }
      case PATHS.PAGE_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <PageLeft />
            </Grid>
          )
        };
      case PATHS.PAGE:
      case PATHS.PAGE_DISCOVER:
      case PATHS.PAGE_LIKED:
      case PATHS.PAGE_INVITES:
        return {
          componentRight:
            width > 1000 ? (
              <Grid
                className={
                  match.path !== PATHS.PAGE_CREATE
                    ? classes.gridItemSidebar
                    : classes.gridItemSidebarFullLeft
                }
                container
                item
              >
                <PageLeft />
              </Grid>
            ) : null
        };
      case PATHS.PAGE_DETAIL:
        let { id }: any = match.params;
        return {
          componentRight:
            width > 1000 ? (
              id &&
              page?.id &&
              page?.page_relationship?.role !== '' &&
              page?.id === rolePreviewEdit?.page_owner_id ? (
                <Grid
                  className={
                    match.params?.key === 'chat' && !isShown
                      ? classes.gridItemSidebarChatPage
                      : classes.gridItemSidebar
                  }
                  container
                  item
                >
                  <PageLeft isShown={isShown} setIsShown={setIsShown} />
                </Grid>
              ) : match.params?.key === 'search' ? (
                <div style={{ marginBottom: '200px' }}>
                  <SearchGlobalLeft
                    type="page"
                    styleGui={{
                      height: '100%',
                      width: 380,
                      backgroundColor: 'background.primary',
                      position: 'none',
                      overflowY: 'hidden'
                    }}
                  />
                </div>
              ) : (
                'none'
              )
            ) : null
        };

      case PATHS.BLOOD_DONATION:
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <BloodDonationLeft />
              </Grid>
            ) : null
        };
      case PATHS.CELEBRATE:
      case PATHS.CELEBRATENOTIFI:
      case PATHS.CELEBRATEHIDE:
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <CelebrateLeft />
              </Grid>
            ) : null
        };

      case PATHS.MOMENT:
      case PATHS.MOMENT_TAG:
      case PATHS.MOMENT_USER: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <MomentBar />
              </Grid>
            ) : null
        };
      }

      case PATHS.WATCH:
      case PATHS.WATCH_SHOW_DETAIL:
      case PATHS.WATCH_PAGE: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <WatchBar />
              </Grid>
            ) : null
        };
      }

      case PATHS.LIVESTREAM_HOME:
      case PATHS.LIVESTREAM_FOLLOW:
      case PATHS.LIVESTREAM_SELL:
      case PATHS.LIVESTREAM_GAME:
      case PATHS.LIVESTREAM_DETAIL: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <LiveBar />
              </Grid>
            ) : null,
          type: 'live_stream'
        };
      }

      case PATHS.WATCH_SEARCH:
      case PATHS.MOMENT_SEARCH: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <SearchLeft />
              </Grid>
            ) : null
        };
      }

      case PATHS.FRIEND: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <FriendBar />
              </Grid>
            ) : null
        };
      }
      case PATHS.SETTINGS_USER: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <SettingsUserLeft />
              </Grid>
            ) : null
        };
      }
      case PATHS.DIARY_USER: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <DiaryUserLeft />
              </Grid>
            ) : null
        };
      }
      case PATHS.SEARCH_GLOBAL: {
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebar} container item>
                <SearchGlobalLeft />
              </Grid>
            ) : null
        };
      }
      case PATHS.HOME:
      case PATHS.WELCOME: {
        return {
          component: null
        };
      }

      case PATHS.GROW_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <GrowLeft />
            </Grid>
          )
        };
      case PATHS.GROW:
      case PATHS.GROW_MINE:
      case PATHS.GROW_DETAIL:
      case PATHS.GROW_CATEGORY:
        return {
          componentRight:
            width > 1000 ||
            (match.params?.key === 'edit' && match.params?.id) ? (
              match.params?.key === 'edit' &&
              !(
                growInfo?.project_relationship?.host_project ||
                growInfo?.page_owner?.page_relationship?.role === 'owner' ||
                growInfo?.page_owner?.page_relationship?.role === 'admin' ||
                growInfo?.page_owner?.page_relationship?.role === 'moderator'
              ) ? null : (
                <Grid
                  className={
                    !(match.params?.key === 'edit' && !!match.params?.id)
                      ? classes.gridItemSidebar
                      : classes.gridItemSidebarFullLeft
                  }
                  container
                  item
                >
                  <GrowLeft />
                </Grid>
              )
            ) : null
        };
      case PATHS.GROW_NOTI_IDENTITY:
        return {
          componentRight: 'none'
        };
      case PATHS.NOTI:
      case PATHS.CHAT:
        return {
          componentRight: 'none'
        };
      case PATHS.SALE:
        return {
          componentRight: 'none'
        };
      case PATHS.LIVESTREAM:
      case PATHS.LIVESTREAM_CREATE:
      case PATHS.LIVESTREAM_REALTIME:
        return {
          componentRight:
            width > 1000 ? (
              <Grid
                className={
                  match.path === PATHS.LIVESTREAM ||
                  match.path === PATHS.LIVESTREAM_CREATE ||
                  match.path === PATHS.LIVESTREAM_REALTIME
                    ? classes.gridItemSidebarFullLeft
                    : classes.gridItemSidebar
                }
                container
                item
              >
                <LiveStreamLeft />
              </Grid>
            ) : null
        };
      case PATHS.MUSICSPACE:
      case PATHS.MUSICSPACE_CHART:
      case PATHS.MUSICSPACE_NEW_CHART:
      case PATHS.MUSICSPACE_ALBUM:
      case PATHS.MUSICSPACE_SEARCH:
      case PATHS.MUSICSPACE_MY_PLAYLIST:
      case PATHS.MUSICSPACE_DETAIL_PLAYLIST:
      case PATHS.MUSICSPACE_TRACK:
      case PATHS.MUSICSPACE_DETAIL_SEARCH:
      case PATHS.MUSICSPACE_NEW_RELEASE_SONG:
      case PATHS.MUSICSPACE_NEW_RELEASE_ALBUM:
      case PATHS.MUSICSPACE_CREATE_ALBUM:
      case PATHS.MUSICSPACE_ARTIST:
      case PATHS.MUSICSPACE_INVITE:
      case PATHS.MUSICSPACE_DETAIL:
      case PATHS.MUSICSPACE_DETAIL_ALBUM:
      case PATHS.MUSICSPACE_UPLOADED:
      case PATHS.MUSICSPACE_UPDATE_ALBUM:
      case PATHS.MUSICSPACE_RANK_SONG:
      case PATHS.MUSICSPACE_MUSIC_AUTHOR_LIST:
        return {
          componentRight:
            PATHS.MUSICSPACE_UPDATE_ALBUM === match.path ? (
              'none'
            ) : (
              <Grid
                sx={{
                  height: '100%',
                  paddingTop: '0px !important',
                  width: '240px !important',
                  position: 'relative',
                  marginTop: '-2px'
                }}
                container
                item
              >
                <MusicSpaceBar />
              </Grid>
            ),
          type:
            PATHS.MUSICSPACE_UPDATE_ALBUM === match.path
              ? 'update_album'
              : 'music_space'
        };
      case PATHS.RECRUIT_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <RecruitLeft />
            </Grid>
          )
        };
      case PATHS.RECRUIT:
      case PATHS.RECRUIT_CREATECV:
      case PATHS.RECRUIT_MINE:
      case PATHS.RECRUIT_DETAIL:
      case PATHS.RECRUIT_CATEGORY:
        return {
          componentRight:
            width > 1000 ||
            (match.params?.key === 'edit' && match.params?.id) ? (
              match.params?.key === 'edit' &&
              !(
                recruitInfo?.recruit_relationships?.host_recruit ||
                recruitInfo?.page_owner?.page_relationship?.role === 'owner' ||
                recruitInfo?.page_owner?.page_relationship?.role === 'admin' ||
                recruitInfo?.page_owner?.page_relationship?.role === 'moderator'
              ) ? null : (
                <Grid
                  className={
                    !(match.params?.key === 'edit' && !!match.params?.id)
                      ? classes.gridItemSidebar
                      : classes.gridItemSidebarFullLeft
                  }
                  container
                  item
                >
                  <RecruitLeft />
                </Grid>
              )
            ) : null
        };

      case PATHS.COURSE_CREATE:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebarFullLeft} container item>
              <CourseLeft />
            </Grid>
          )
        };
      case PATHS.COURSE:
      case PATHS.COURSE_MINE:
      case PATHS.COURSE_DETAIL:
      case PATHS.COURSE_CATEGORY:
        return {
          componentRight:
            width > 1000 ? (
              match.params?.key === 'edit' &&
              !(
                courseInfo?.course_relationships?.host_course ||
                courseInfo?.page_owner?.page_relationship?.role === 'owner' ||
                courseInfo?.page_owner?.page_relationship?.role === 'admin' ||
                courseInfo?.page_owner?.page_relationship?.role === 'moderator'
              ) ? null : (
                <Grid
                  className={
                    !(match.params?.key === 'edit' && !!match.params?.id)
                      ? classes.gridItemSidebar
                      : classes.gridItemSidebarFullLeft
                  }
                  container
                  item
                >
                  <CourseLeft />
                </Grid>
              )
            ) : null
        };

      case PATHS.PRODUCT_CREATE:
        return {
          componentRight:
            width > 1000 ? (
              <Grid
                className={classes.gridItemSidebar}
                container
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  width: '360px !important'
                }}
              >
                <NavigationSidebar />
              </Grid>
            ) : null
        };
      case PATHS.MARKETPLACE_SHOP:
        return {
          componentRight:
            page?.id &&
            page?.page_relationship?.role !== '' &&
            page?.id === rolePreviewEdit?.page_owner_id ? (
              <Grid sx={{ maxWidth: '310px' }} container item>
                <MarketplaceLeft
                  setNoti={setNoti}
                  setOpenSnackbar={setOpenSnackbar}
                />
              </Grid>
            ) : (
              'none'
            )
        };
      case PATHS.MARKETPLACE:
      case PATHS.PRODUCT_DETAIL:
      case PATHS.ORDER_DETAIL:
      case PATHS.MANAGE_ORDER_DETAIL:
      case PATHS.MARKETPLACE_CATEGORY:
      case PATHS.CATEGORY_GUIDE:
      case PATHS.MARKETPLACE_MINE:
      case PATHS.MARKETPLACE_TOP_PRODUCTS:
      case PATHS.MARKETPLACE_FLASH_SALE:
      case PATHS.MARKETPLACE_CAMPAIGN:
      case PATHS.MARKETPLACE_BEST_SELLER:
      case PATHS.MARKETPLACE_FULL_STAR:
      case PATHS.MARKETPLACE_MALL:
      case PATHS.MARKETPLACE_CART_RECOMMEND:
      case PATHS.MARKETPLACE_EMSO_COMMIT:
        return {
          componentRight:
            width > 1200 ? (
              !(match.params?.key === 'edit' && !!match.params?.id) ? (
                'none'
              ) : match.params?.key === 'edit' &&
                !productInfo?.product_relationships?.host_product ? null : (
                <Grid
                  className={classes.gridItemSidebar}
                  container
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '360px !important'
                  }}
                >
                  <NavigationSidebar />
                </Grid>
              )
            ) : null
        };

      case PATHS.PAYMENT:
      case PATHS.PAYMENT_MONEY:
      case PATHS.PAYMENT_HISTORY:
      case PATHS.PAYMENT_COIN:
      case PATHS.RECURRING_PAYMENTS:
      case PATHS.REQUEST_PAYMENTS:
      case PATHS.REFUND_PAYMENTS:
      case PATHS.SETTINGS_PAYMENT:
      case PATHS.HELP_PAYMENT:
      case PATHS.PAYMENT_TERMS:
      case PATHS.PAYMENT_BANK:
        return {
          componentRight: (
            <Grid className={classes.gridItemSidebar} container item>
              <PaymentLeft />
            </Grid>
          ),
          type: 'payment'
        };

      case PATHS.HELPER:
      case PATHS.HELPER_ITEM:
      case PATHS.TERMS_ITEM:
      case PATHS.HElPER_WRAP:
      case PATHS.POLICY_DOC:
        return {
          componentRight:
            width > 1000 ? (
              <Grid className={classes.gridItemSidebarFullLeft} container item>
                <HelperLeft />
              </Grid>
            ) : null
        };
      default:
        return { componentRight: null, component: null };
    }
  };

  return (
    <>
      {meInfo?.info?.theme ||
      meCache?.theme ||
      routeHelper.includes(match.path) ? (
        <div
          className={classes.root}
          style={{
            paddingBottom: isMobile ? '100px' : undefined,
            backgroundColor:
              theme.palette.mode === 'dark' ? '#121212' : '#f1f2f5',
            height: '100%'
          }}
        >
          {match.path !== PATHS.PATH_DETAILRECRUIT && (
            <HeaderContainer
              user={meInfo?.info}
              noti={noti}
              setNumberTitle={setNumberTitle}
              isBannerHidden={isBannerHidden}
              setIsBannerHidden={setIsBannerHidden}
            />
          )}
          <main
            className={classes.main}
            style={{
              height:
                !displayZoomDown && match.path === PATHS.CHAT
                  ? '100vh'
                  : !displayZoomDown
                  ? isBannerHidden
                    ? '125vh'
                    : isSmallMobile
                    ? '285vh'
                    : '240vh'
                  : undefined,
              overflow:
                isOpenShortCut || match.params.key === 'chat'
                  ? 'hidden'
                  : 'unset'
            }}
          >
            {meInfo?.info?.suspended ||
            recruitInfo?.status === 'locked' ||
            growInfo?.status === 'locked' ||
            eventInfo?.status === 'locked' ||
            (courseInfo?.status === 'locked' &&
              match.path === PATHS.COURSE_DETAIL &&
              meInfo?.info?.id === courseInfo?.account?.id) ||
            (['violated', 'restricted'].includes(productInfo?.status) &&
              match.path === PATHS.PRODUCT_DETAIL) ||
            ((['wait_close', 'closed'].includes(page?.status) ||
              (group?.status === 'locked' &&
                group?.group_relationship?.admin)) &&
              match.path !== PATHS.HELPER) ? (
              <Complain pageStatus={page?.status} groupStatus={group?.status} />
            ) : !openMediaPost ? (
              <>
                {isOpenShortCut && match.path !== PATHS.PATH_DETAILRECRUIT && (
                  <ShortCutSideBar
                    isOpenShortCut={isOpenShortCut}
                    setIsOpenShortCut={setIsOpenShortCut}
                  />
                )}
                <CssBaseline />
                <Grid
                  className={classes.grid}
                  id={
                    match.path === PATHS.HOME
                      ? 'scrollableDivHome'
                      : 'scrollableDiv'
                  }
                  container
                  sx={{
                    height: !displayZoomDown
                      ? '100%'
                      : match.path === PATHS.MARKETPLACE_SHOP &&
                        match.params.key === 'chat'
                      ? 'inherit'
                      : match.path === PATHS.CHAT || match.params.key === 'chat'
                      ? 'unset'
                      : 'inherit',
                    transform:
                      !displayZoomDown && match.path !== PATHS.CHAT
                        ? 'scale(0.9)'
                        : 'unset',
                    marginTop:
                      !displayZoomDown && match.path !== PATHS.CHAT
                        ? '0px !important'
                        : 'unset'
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      width: isMobile
                        ? '0px'
                        : matches ||
                          renderDataMenu().componentRight === 'none' ||
                          PATHS.PAYMENT_BUY === match.path ||
                          PATHS.PAYMENT_VTV_PAY === match.path ||
                          PATHS.ALBUM_CREATE === match.path ||
                          PATHS.ALBUM_UPDATE === match.path ||
                          PATHS.ALBUM_DETAIL === match.path
                        ? '50px'
                        : renderDataMenu().type === 'music_space'
                        ? '290px'
                        : renderDataMenu().type === 'payment'
                        ? '360px'
                        : renderDataMenu().type
                        ? '410px'
                        : match.params?.key === 'chat'
                        ? '0px'
                        : (match.path === PATHS.MARKETPLACE_SHOP ||
                            match.path === PATHS.MARKETPLACE ||
                            match.path === PATHS.PRODUCT_DETAIL ||
                            match.path === PATHS.ORDER_DETAIL ||
                            match.path === PATHS.MANAGE_ORDER_DETAIL ||
                            match.path === PATHS.MARKETPLACE_CATEGORY ||
                            match.path === PATHS.CATEGORY_GUIDE ||
                            match.path === PATHS.MARKETPLACE_MINE ||
                            match.path === PATHS.MARKETPLACE_TOP_PRODUCTS ||
                            match.path === PATHS.MARKETPLACE_FLASH_SALE ||
                            match.path === PATHS.MARKETPLACE_CAMPAIGN ||
                            match.path === PATHS.MARKETPLACE_BEST_SELLER ||
                            match.path === PATHS.MARKETPLACE_FULL_STAR ||
                            match.path === PATHS.MARKETPLACE_MALL ||
                            match.path === PATHS.MARKETPLACE_CART_RECOMMEND ||
                            match.path === PATHS.MARKETPLACE_EMSO_COMMIT) &&
                          width < 1300
                        ? '60px'
                        : '360px',
                      zIndex: 100,
                      '&:hover': {
                        zIndex: match.path.includes('/music_space') ? 0 : 100
                      }
                    }}
                    className={
                      pathShowLeft.includes(match.path) ||
                      (match.params.id && match.params.key === 'edit')
                        ? classes.showLeft
                        : classes.postSavedLeft
                    }
                  >
                    {renderDataMenu().componentRight === undefined
                      ? !isMobile &&
                        !isOpenShortCut && (
                          <Sidebar
                            isOpenShortCut={isOpenShortCut}
                            setIsOpenShortCut={setIsOpenShortCut}
                            setOpenVerifiedPass={setOpenVerifiedPass}
                          />
                        )
                      : !isMobile &&
                        !isOpenShortCut && (
                          <Sidebar
                            isOpenShortCut={isOpenShortCut}
                            setIsOpenShortCut={setIsOpenShortCut}
                            type={'shortCutMenu'}
                            setOpenVerifiedPass={setOpenVerifiedPass}
                          />
                        )}
                    {match.path !== PATHS.PATH_DETAILRECRUIT && (
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          width: isMobile
                            ? '0px'
                            : matches ||
                              renderDataMenu().componentRight === 'none' ||
                              PATHS.PAYMENT_BUY === match.path ||
                              PATHS.PAYMENT_VTV_PAY === match.path
                            ? '50px'
                            : renderDataMenu().type === 'music_space'
                            ? '290px'
                            : renderDataMenu().type
                            ? '410px'
                            : '360px',
                          marginTop: '-2px'
                        }}
                      >
                        {match.path !== PATHS.PATH_DETAILRECRUIT &&
                          (renderDataMenu().componentRight === undefined
                            ? !isMobile &&
                              !isOpenShortCut && (
                                <Sidebar
                                  isOpenShortCut={isOpenShortCut}
                                  setIsOpenShortCut={setIsOpenShortCut}
                                  setOpenVerifiedPass={setOpenVerifiedPass}
                                />
                              )
                            : !isMobile &&
                              !isOpenShortCut && (
                                <Sidebar
                                  isOpenShortCut={isOpenShortCut}
                                  setIsOpenShortCut={setIsOpenShortCut}
                                  type={'shortCutMenu'}
                                  setOpenVerifiedPass={setOpenVerifiedPass}
                                />
                              ))}

                        {renderDataMenu().componentRight ===
                        'none' ? null : renderDataMenu().componentRight !==
                          'none' ? (
                          <div
                            style={{
                              marginLeft: '50px'
                            }}
                          >
                            {renderDataMenu().componentRight}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Box>

                  <Grid
                    className={classes.gridItem}
                    ref={refScroll}
                    container
                    item
                    style={{
                      width:
                        renderDataMenu().componentRight === undefined &&
                        !matches
                          ? 'calc(100% - 360px)'
                          : matches ||
                            renderDataMenu().componentRight === 'none'
                          ? 'calc(100% - 60px) !important'
                          : renderDataMenu().type
                          ? 'calc(100% - 410px)'
                          : match.path === PATHS.MARKETPLACE_SHOP &&
                            width < 1300
                          ? 'calc(100% - 60px)'
                          : 'calc(100% - 360px)',
                      marginTop: '-2px',
                      marginLeft:
                        (match.params?.key === 'chat' && width > 1000) ||
                        (match.path === PATHS.MARKETPLACE_SHOP &&
                          width < 1300 &&
                          width > 1000)
                          ? '115px'
                          : '0px',
                      overflow:
                        match.params.key === 'chat' ? 'hidden' : 'initial'
                    }}
                  >
                    {!isInitialDialogOpen &&
                      React.cloneElement(children, {
                        refScroll: refScroll,
                        widthLeft: widthLeft
                      })}
                  </Grid>
                </Grid>
              </>
            ) : match.path === PATHS.PATH_DETAILRECRUIT ? (
              <></>
            ) : (
              <MediaDetail
                openPreview={openMediaPost}
                setOpenPreview={setOpenMediaPost}
              />
            )}
          </main>
          <div
            style={{
              position: 'fixed',
              zIndex: 1004,
              bottom: 0,
              right: 90,
              borderRadius: '8px 8px 0px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {watchSelected && Object.keys(watchSelected).length ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end'
                }}
              >
                <div
                  style={{
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px 8px 0px 0px',
                    bottom: 5,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff',
                      borderRadius: '8px 8px 0 0',
                      padding: 8
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AvatarSocial
                        type="feed"
                        avatarObj={
                          watchSelected?.page?.avatar_media ??
                          watchSelected?.account?.avatar_media
                        }
                        style={{ width: 38, height: 38 }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                          marginLeft: 15
                        }}
                      >
                        <Typography style={{ fontSize: 15, color: '#65676b' }}>
                          {collapseString(
                            watchSelected?.page?.title ??
                              watchSelected?.account?.display_name,
                            45
                          )}
                        </Typography>
                        <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                          {collapseString(
                            watchSelected.content ?? watchSelected.title,
                            45
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const videoPreview: any = document.getElementById(
                          `${watchSelected.media_attachments[0].id}preview-mini`
                        );

                        const videoDOM: any = document.getElementById(
                          `${watchSelected.media_attachments[0].id}preview-watch`
                        );
                        videoDOM?.play();
                        dispatch(timePlayAction(videoPreview.currentTime));
                        dispatch(resetWatchSelectedAction());
                      }}
                      className={classes.wrapIcon}
                    >
                      <i className="fa-solid fa-xmark fa-lg"></i>
                    </div>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <WatchPlayer
                      type="preview-mini"
                      video={watchSelected?.media_attachments?.[0]}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {dataMiniPlayer.showMiniPlayer ? (
              <>
                <MiniPlayer />
              </>
            ) : null}
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex:
                (match.path === PATHS.WATCH && query.get('v')) ||
                query.get('mediaId')
                  ? 1301
                  : 1004,
              bottom: 0,
              right: 90,
              borderRadius: '8px 8px 0px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {match.path !== PATHS.CHAT &&
            match.params.key !== 'chat' &&
            listPopupChat.length > 0 ? (
              <>
                {listPopupChat
                  .filter(el => {
                    return !(
                      el.conversation?.t === 'cp' &&
                      el?.conversation?.u?.hasOwnProperty('_id') &&
                      el.conversation?.u._id !== meInfo.info.id
                    );
                  })
                  .map((el: any, index: any) => {
                    return (
                      <Box key={el.conversation.rid}>
                        <BoxChat
                          keyRender={el.conversation.rid}
                          conversation={el}
                          setOpenSnackbar={setOpenSnackbar}
                          setNoti={setNoti}
                          type="boxChat"
                        />
                      </Box>
                    );
                  })}
              </>
            ) : null}
            {isOpenBoxCreate && match.path !== PATHS.CHAT ? (
              <BoxCreateChat />
            ) : null}
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: 0,
              right: 5,
              zIndex: renderDataMenu().type === 'music_space' ? 101 : 1003,
              borderRadius: '8px 8px 0px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {(renderDataMenu().type === 'music_space' ||
              openMiniPlayer ||
              isShowAudio) &&
            match.path !== PATHS.MUSICSPACE_UPDATE_ALBUM ? (
              <MusicPlayerSlider
                data={dataMusicSpace}
                openMiniPlayer={openMiniPlayer}
                setOpenMiniPlayer={setOpenMiniPlayer}
                setOpenSnackbar={setOpenSnackbar}
                setNoti={setNoti}
              />
            ) : null}
          </div>
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              right: 30,
              zIndex: query.get('mediaId') ? 1301 : 100,
              borderRadius: '8px 8px 0px 0px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {match.path !== PATHS.CHAT && match.params.key !== 'chat' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  marginBottom: '10px',
                  marginLeft: '10px'
                }}
              >
                {![PATHS.PATH_DETAILRECRUIT, PATHS.MARKETPLACE_SHOP].includes(
                  match.path
                ) ? (
                  <BadgeCreate />
                ) : null}
                {listConversationPlus.length > 0 && (
                  <BadgeChatPlus listConversation={listConversationPlus} />
                )}
                <AvatarGroup
                  max={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    marginBottom: '10px',
                    gap: '10px'
                  }}
                >
                  {queuePopupChat
                    .filter(
                      item =>
                        // item.conversation?.type && //debug
                        !item.conversation?.user_chat_page
                    )
                    .slice(0, 5)
                    .map((el, index) => {
                      return (
                        <BadgeChat
                          key={el.conversation._id}
                          conversation={el.conversation}
                          countUnread={el.countUnread}
                        />
                      );
                    })}
                </AvatarGroup>
              </div>
            )}
          </div>

          {openCreatePass && (
            <DialogCreatePass
              title="Cài đặt mật khẩu"
              open={openCreatePass}
              handleClose={() => setOpenCreatePass(false)}
            />
          )}

          {openWatchScreen && (
            <DialogWatchScreen
              open={openWatchScreen}
              setOpen={value => {
                setOpenWatchScreen(value);
                if (!value) {
                  setIsInitialDialogOpen(false);
                }
              }}
              typeWatch={typeWatch}
              setTypeWatch={setTypeWatch}
            />
          )}

          <SocketChat />
          <Notification
            noti={noti}
            setNoti={setNoti}
            userId={meInfo?.info?.id}
          />
          {openSnackbar &&
            Object.keys(postInfo)?.length > 0 && ( //noti liên quan tới bài post
              <SnackbarNotification
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                code={postInfo?.code}
                message={postInfo?.msg}
                type={'notiInMainLayout'}
              />
            )}

          {openErrorReq && (
            <DialogConfirm
              open={openErrorReq}
              handleClose={() => setOpenErrorReq(false)}
              title="Cảnh báo"
              alignText="center"
              contentDialog={
                <>
                  <i
                    className="fa-solid fa-triangle-exclamation"
                    style={{ color: '#ff0000d6', fontSize: '30px' }}
                  ></i>
                  <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                    Tài khoản của bạn bị hạn chế
                    {dataErrorReq?.type_error === 'comment'
                      ? ` đến ${moment(dataErrorReq?.time_ban)
                          .utcOffset('+07:00')
                          .format('HH:mm')} `
                      : ' trong 1 giờ'}
                  </Typography>
                  <Typography>
                    Bạn không thể làm một hay nhiều việc như bình thường do hoạt
                    động trên tài khoản của bạn không tuân thủ Tiêu chuẩn cộng
                    đồng của chúng tôi.
                  </Typography>
                </>
              }
            />
          )}

          {openVerifiedPass && (
            <DialogVerifiedPass
              open={openVerifiedPass}
              handleClose={() => {
                setOpenVerifiedPass(false);
                setOpenSnackbar(false);
              }}
              action={() => {
                setLoadingAction(true);
                handleCheckLoginUser();
              }}
              setPassword={setPassword}
              loading={loadingAction}
            />
          )}
          {openSnackbar &&
            noti.message?.length > 0 && ( //noti các hành vi khác
              <SnackbarNotification
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                code={noti.code}
                message={noti.message}
              />
            )}

          {isConnection && isConnectionStatus === 'offline' ? (
            <CustomizedSnackBars
              open={isConnection}
              setOpen={setIsConnection}
              code={500}
              icon={<WifiOffIcon fontSize="inherit" />}
              message={'Bạn đang mất kết nối mạng.'}
            />
          ) : (
            isConnectionStatus === 'online' && (
              <CustomizedSnackBars
                open={isConnection}
                setOpen={setIsConnection}
                message={'Đã khôi phục kết nối mạng.'}
                code={200}
                icon={<WifiIcon fontSize="inherit" />}
              />
            )
          )}
        </div>
      ) : (
        <div
          style={{
            height: '100vh',
            position: 'relative',
            backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff'
          }}
        >
          <div
            style={{
              position: 'absolute',
              height: '65%',
              bottom: 0,
              right: 0,
              left: 0
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <img
                src={Emso}
                style={{
                  height: '89px',
                  width: '89px',
                  marginBottom: '12px'
                }}
                alt="emSoImage"
              ></img>
              <Typography
                sx={{
                  color: buttonColor.backgroundColor,
                  fontWeight: 600,
                  fontSize: '22px'
                }}
              >
                Mạng xã hội Vì người Việt
              </Typography>
            </div>
            <img
              src={logoFull}
              style={{
                width: '80px',
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translate(-50%,0)'
              }}
              alt="logoFull"
            ></img>
          </div>
        </div>
      )}
    </>
  );
}
