export const SAVE_CACHE_USER_INFO = 'SAVE_CACHE_USER_INFO';
export const SAVE_CACHE_ME_INFO = 'SAVE_CACHE_ME_INFO';
export const SAVE_ABOUT_USER = 'SAVE_ABOUT_USER';
export const SAVE_LIFE_EVENTS_USER = 'SAVE_LIFE_EVENTS_USER';
export const SAVE_BLOCK_USER = 'SAVE_BLOCK_USER';
export const SAVE_LIST_FRIEND = 'SAVE_LIST_FRIEND';
export const SAVE_STATUS_FRIEND = 'SAVE_STATUS_FRIEND';
export const SAVE_SOCIAL_CATEGORY = 'SAVE_SOCIAL_CATEGORY';
export const SAVE_LIST_LISTNOTION_COLLECTION =
  'SAVE_LIST_LISTNOTION_COLLECTION';
export const SAVE_MEDIA_USER = 'SAVE_MEDIA_USER';
export const SAVE_PIN_USER = 'SAVE_PIN_USER';
export const SAVE_FRIEND_SUGGEST_USER = 'SAVE_FRIEND_SUGGEST_USER';

export const RESET_USER_INFO = 'RESET_USER_INFO';
