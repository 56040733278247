import { Avatar, Box, Divider, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendFeedback, sendComplain } from 'src/apis/socialUser';
import { sendComplainMarketPlace } from 'src/apis/socialCourse.api';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogComplain from 'src/components/Dialog/DialogComplain';
import { buttonColor } from 'src/constants/styles';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import user_banner from 'src/assets/images/user_banner.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  infoCamplain: {
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    borderRadius: '10px',
    padding: '25px 10px 60px 10px',
    color: theme.palette.mode === 'dark' ? '#fff' : '#242526'
  },
  boxTop: {
    textAlign: 'center'
  },
  nameUser: {
    fontWeight: '500 !important',
    fontSize: '18px !important',
    wordBreak: 'break-word',
    padding: '10px 0px'
  },
  dateCamplain: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: 'red'
  }
}));

const Complain = props => {
  const classes = useStyles();
  const { pageStatus, groupStatus } = props;
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const page = useSelector((state: any) => state.pageReducer.info);
  const group = useSelector((state: any) => state.groupReducer.groupSelected);
  const recruit = useSelector((state: any) => state.recruitReducer.info);
  const product = useSelector((state: any) => state.productReducer.info);
  const event = useSelector((state: any) => state.eventReducer.info);
  const grow = useSelector((state: any) => state.growReducer.info);
  const course = useSelector((state: any) => state.courseReducer.info);

  const pageSelected =
    useSelector((state: any) => state.pageReducer.info) || {};
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const timeLocked = pageStatus
    ? pageSelected?.report_violation?.updated_at
    : groupStatus
    ? groupSelected?.report_violation?.updated_at
    : recruit?.id
    ? recruit?.report_violation?.updated_at
    : product?.id
    ? product?.updated_at
    : event?.id
    ? event?.report_violation?.updated_at
    : grow?.id
    ? grow?.report_violation?.updated_at
    : course?.id
    ? course?.updated_at
    : meInfo?.suspended_at;
  const [openDialogConfirm, setOpenDialogConfirm] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [listIdFile, setListIdFile] = React.useState<string[]>([]);

  const userSendFeedBack = async (value, listIdFile) => {
    try {
      let response;
      response = await sendComplain({
        feedback_category_id: 1,
        entity_type: pageStatus
          ? 'Page'
          : groupStatus
          ? 'Group'
          : recruit?.id
          ? 'Recruit'
          : event?.id
          ? 'Event'
          : grow?.id
          ? 'Project'
          : 'Account',
        entity_id: pageStatus
          ? page?.id
          : groupStatus
          ? group?.id
          : recruit?.id
          ? recruit?.id
          : event?.id
          ? event?.id
          : grow?.id
          ? grow?.id
          : meInfo?.id,
        complain_images: listIdFile,
        note: value
      });
      if (response.status === 200) {
        setLoadingConfirm(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Gửi khiếu lại thành công.'
        });
        setOpenDialogConfirm(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Xảy ra quá trình lỗi khi gửi khiếu lại.Vui lòng thử lại sau!'
      });
      setLoadingConfirm(false);
    }
  };

  const userSendFeedBackMarketplace = async (value, listIdFile) => {
    try {
      let response;
      response = await sendComplainMarketPlace({
        entity_type: course?.id ? 'Course' : 'Product',
        entity_id: course?.id || product?.id,
        complain_report_images: listIdFile,
        note: value
      });
      if (response.status === 200) {
        setLoadingConfirm(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Gửi khiếu lại thành công.'
        });
        setOpenDialogConfirm(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Xảy ra quá trình lỗi khi gửi khiếu lại.Vui lòng thử lại sau!'
      });
      setLoadingConfirm(false);
    }
  };

  const getAvatarUrl = () => {
    if (pageStatus) {
      return (
        pageSelected?.avatar_media?.show_url ??
        pageSelected?.avatar_media?.preview_url ??
        user_banner
      );
    }
    if (groupStatus) {
      return groupSelected?.banner?.preview_url ?? user_banner;
    }
    if (recruit?.id) {
      return recruit?.banner?.preview_url ?? user_banner;
    }
    if (event?.id) {
      return (
        event?.banner?.show_url ?? event?.banner?.preview_url ?? user_banner
      );
    }
    if (product?.id) {
      return (
        product?.product_image_attachments?.[0]?.attachment?.preview_url ??
        user_banner
      );
    }
    if (grow?.id) {
      return grow?.banner?.show_url ?? grow?.banner?.preview_url ?? user_banner;
    }
    if (course?.id) {
      return course?.banner?.preview_url ?? user_banner;
    }
    if (meInfo?.avatar_media) {
      return (
        meInfo?.avatar_media?.show_url ?? meInfo?.avatar_media?.preview_url
      );
    }
    return user_banner;
  };
  const getTitle = () => {
    if (pageStatus) return pageSelected?.title;
    if (groupStatus) return groupSelected?.title;
    if (recruit?.id) return recruit?.title;
    if (product?.id) return product?.title;
    if (event?.id) return event?.title;
    if (grow?.id) return grow?.title;
    if (course?.id) return course?.title;
    return meInfo?.display_name;
  };
  const getEntityType = () => {
    if (pageStatus) return 'trang';
    if (groupStatus) return 'nhóm';
    if (recruit?.id) return 'tin tuyển dụng';
    if (event?.id) return 'sự kiện';
    if (grow?.id) return 'dự án';
    if (product?.id) return 'sản phẩm';
    if (course?.id) return 'khoá học';
    return 'tài khoản';
  };
  //viết hoa chữ cái đầu của chuỗi
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getSuspensionMessage = () => {
    if (pageStatus)
      return `Trang của bạn đã bị tạm ngừng hoạt động vì lý do ${pageSelected?.report_violation?.note_admin}`;
    if (groupStatus)
      return `Nhóm của bạn đã bị tạm ngừng hoạt động vì lý do ${groupSelected?.report_violation?.note_admin}`;
    if (recruit?.id)
      return `Tin tuyển dụng của bạn đã bị tạm ngừng hoạt động vì lý do ${recruit?.report_violation?.note_admin}`;
    if (event?.id)
      return `Sự kiện của bạn đã bị tạm ngừng hoạt động vì lý do ${event?.report_violation?.note_admin}`;
    if (grow?.id)
      return `Dự án của bạn đã bị tạm ngừng hoạt động vì lý do ${grow?.report_violation?.note_admin}`;
    if (product?.id)
      return `Sản phẩm của bạn đã bị tạm ngừng hoạt động vì lý do ${product?.report_violation?.note_admin}`;
    if (course?.id)
      return `Khoá học của bạn đã bị tạm ngừng hoạt động vì lý do ${course?.report_violation?.note_admin}`;
    return `Tài khoản của bạn hoặc hoạt động trên đó vi phạm tiêu chuẩn cộng đồng của chúng tôi`;
  };

  const isDisabledComplain = () => {
    return (
      page?.complain ||
      course?.complain ||
      product?.complain ||
      meInfo?.complain ||
      recruit?.complain ||
      event?.complain ||
      grow?.complain ||
      group?.complain
    );
  };
  const isRejectedComplain = () => {
    return (
      page?.complain?.status === 'rejected' ||
      course?.complain?.status === 'rejected' ||
      product?.complain?.status === 'rejected' ||
      meInfo?.complain?.status === 'rejected' ||
      recruit?.complain?.status === 'rejected' ||
      event?.complain?.status === 'rejected' ||
      grow?.complain?.status === 'rejected' ||
      group?.complain?.status === 'rejected'
    );
  };

  return (
    <Box className={classes.root}>
      {isRejectedComplain() ? (
        <Box
          sx={{ maxWidth: '600px', height: 'fit-content', marginTop: '50px' }}
        >
          <Box className={classes.infoCamplain}>
            <Box className={classes.boxTop}>
              <Avatar
                sx={{ margin: '0 auto' }}
                alt="avatar"
                src={getAvatarUrl()}
              />
              <Typography className={classes.nameUser}>{getTitle()}</Typography>
              <Typography sx={{ fontWeight: '700', fontSize: '22px' }}>
                {capitalizeFirstLetter(getEntityType())} của bạn đã bị khóa vĩnh
                viễn
              </Typography>
              <Typography
                className={classes.dateCamplain}
                sx={{ textTransform: 'uppercase' }}
              >
                Vào {formatDateTimeToStringDateTime(timeLocked, false)}
              </Typography>
              <Typography sx={{ fontSize: '20px', paddingTop: '5px' }}>
                {capitalizeFirstLetter(getEntityType())} của bạn bị khóa vĩnh
                viễn vì lý do: Vi phạm chính sách cộng đồng EMSO
              </Typography>
              <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                Chúng tôi sẽ ẩn {getEntityType()} của bạn với mọi người trên
                Emso và bạn cũng không thể sử dụng {getEntityType()} của mình
              </Typography>
            </Box>
            <Divider sx={{ margin: '10px 0px' }} />
            <Box>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                Quyết định của EMSO
              </Typography>
              <Typography>
                Chúng tôi rất tiếc phải thông báo rằng {getEntityType()}{' '}
                <span style={{ fontWeight: '700' }}>{getTitle()} </span>
                của bạn đã bị khóa vĩnh viễn.
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Trước đó, {getEntityType()} của bạn đã bị khóa do vi phạm Tiêu
                chuẩn cộng đồng của chúng tôi. Bạn đã gửi khiếu nại, nhưng sau
                khi xem xét, chúng tôi xác định rằng quyết định ban đầu là chính
                xác.
              </Typography>
              <Typography sx={{ margin: '10px 0px' }}>
                Chúng tôi hiểu rằng điều này có thể gây ra sự bất tiện cho bạn,
                nhưng chúng tôi cần phải thực thi các tiêu chuẩn để đảm bảo an
                toàn và trải nhiệm tích cực cho tất cả người dùng.
              </Typography>
              <Link
                href="/help"
                underline="hover"
                sx={{ fontWeight: '500', fontSize: '14px' }}
              >
                Tìm hiểu thêm về Tiêu chuẩn cộng đồng của chúng tôi
              </Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ maxWidth: '600px', height: 'fit-content', marginTop: '50px' }}
        >
          <Box className={classes.infoCamplain}>
            <Box className={classes.boxTop}>
              <Avatar
                sx={{ margin: '0 auto' }}
                alt="avatar"
                src={getAvatarUrl()}
              />
              <Typography className={classes.nameUser}>{getTitle()}</Typography>
              <Typography sx={{ fontWeight: '700', fontSize: '22px' }}>
                Chúng tôi đã tạm ngừng {getEntityType()} của bạn
              </Typography>
              <Typography
                className={classes.dateCamplain}
                sx={{ textTransform: 'uppercase' }}
              >
                Vào {formatDateTimeToStringDateTime(timeLocked, false)}
              </Typography>
              <Typography sx={{ fontSize: '20px', paddingTop: '5px' }}>
                {getSuspensionMessage()}
              </Typography>
              <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                Chúng tôi sẽ ẩn {getEntityType()} của bạn với mọi người trên
                Emso và bạn cũng không thể sử dụng {getEntityType()} của mình
              </Typography>
            </Box>
            <Divider sx={{ margin: '10px 0px' }} />
            <Box>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                Còn 30 ngày để phản đối quyết định này
              </Typography>
              <Typography>
                Nếu bạn cho rằng việc tạm ngừng {getEntityType()} là nhầm lẫn,
                chúng tôi có thể hướng dẫn bạn một số bước để phản đối quyết
                định này.
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Sau khi chúng tôi xử lý {getEntityType()} của bạn lần đầu, bạn
                có 30 ngày để phản đối, nếu không thì {getEntityType()} sẽ bị vô
                hiệu hóa vĩnh viễn. Bạn còn 30 ngày.
              </Typography>
              <Typography sx={{ margin: '10px 0px' }}>
                Lưu ý: Đội ngũ xét duyệt chúng tôi hiện có ít nhân lực hơn do
                ảnh hưởng của đại dịch virus corona(COVID-19). Vì vậy, chúng tôi
                có thể không xét duyệt được mọi yêu cầu và cách chúng tôi xử lý
                quá trình xem xét lại đã thay đổi. Chúng tôi sẽ hướng dẫn một số
                bước để bạn có thể yêu cầu xem xét lại.
              </Typography>
              <Link
                href="/help"
                underline="hover"
                sx={{ fontWeight: '500', fontSize: '14px' }}
              >
                Tìm hiểu thêm về Tiêu chuẩn cộng đồng của chúng tôi
              </Link>
            </Box>
          </Box>
          <Box>
            <ButtonInherit
              label={
                isDisabledComplain()
                  ? 'Đang chờ xử lý khiếu nại quyết định'
                  : 'Phản đối quyết định'
              }
              style={{
                width: '100%',
                backgroundColor: buttonColor.backgroundColor,
                color: '#fff',
                marginTop: '20px'
              }}
              disabled={isDisabledComplain()}
              action={() => setOpenDialogConfirm(true)}
            />
          </Box>
        </Box>
      )}
      {openDialogConfirm && (
        <DialogComplain
          listIdFile={listIdFile}
          setListIdFile={setListIdFile}
          open={openDialogConfirm}
          handleClose={() => {
            setOpenDialogConfirm(false);
            setLoadingConfirm(false);
          }}
          title="Phản hồi quyết định"
          action={e => {
            if (course?.id || product?.id) {
              userSendFeedBackMarketplace(e, listIdFile);
            } else {
              userSendFeedBack(e, listIdFile);
            }
            setLoadingConfirm(true);
          }}
          loading={loadingConfirm}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default Complain;
