/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable array-callback-return */ import React, {
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStatusUser } from 'src/apis/socialUser';
import { getListConversationReq } from 'src/store/action/socialChatAction';
import { connectRocket, onmessage } from 'src/socket/RocketChatSubscriptions';
import { settingChat } from 'src/store/action/socialChatAction';

function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.meReducer.info) || {};
  const accountSetting = useSelector(
    (state: any) => state.userReducer.accountSetting
  );
  const [flag, setFlag] = React.useState(false);
  const rocketChatToken = localStorage.getItem('token');
  const dataUser = localStorage.getItem('dataUser');
  const rocketId = JSON.parse(dataUser ?? '[]')?.find(
    el => el.token === rocketChatToken
  )?.id;

  React.useEffect(() => {
    if (Object.keys(accountSetting || {}).length > 0 && !flag) {
      setFlag(true);
      dispatch(
        settingChat({
          auto_open_chatbox: accountSetting.auto_open_chatbox ?? true,
          allow_chat_sound: accountSetting.allow_chat_sound ?? true,
          allow_call_sound: accountSetting.allow_call_sound ?? true
        })
      );
    }
    // fetchStatusUser();
  }, [accountSetting]);
  const handleMessage = onmessage;
  React.useEffect(() => {
    if (userInfo.id && rocketId && dataUser) {
      rocketChat(userInfo.id);
      let limit: number = 10;
      let lastUpdated: number = Date.now();
      dispatch(getListConversationReq(limit, lastUpdated));
    }
  }, [userInfo?.id, dataUser]);

  const rocketChat = rocketId => {
    try {
      const ws = connectRocket(rocketId);

      ws.onmessage = handleMessage;
      // receiveRocketChat(userInfo?.username);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchStatusUser = async () => {
  //   let response = await getStatusUser();
  //   if (response.status === 200) {
  //     dispatch(
  //       settingChat({
  //         auto_open_chatbox: response.data.auto_open_chatbox ?? true,
  //         allow_chat_sound: response.data.allow_chat_sound ?? true,
  //         allow_call_sound: response.data.allow_call_sound ?? true
  //       })
  //     );
  //   }
  // };

  return <></>;
}

export default App;
