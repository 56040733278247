import * as React from 'react';
import { Box, Dialog, Grid, IconButton, Slide, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { useDispatch, useSelector } from 'react-redux';
import { postSelectedAction } from 'src/store/action/socialWatchAction';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import ButtonInherit from 'src/components/Button/ButtonInherit';
import WatchScreen from './WatchScreen';
import { makeStyles } from '@mui/styles';
import { buttonColor } from 'src/constants/styles';
import RightHeaderGlobal from 'src/components/Header/HeaderGlobal/RightHeaderGlobal';
import DropdownGlobalContainer from 'src/containers/HeaderContainer/DropdownGlobalContainer';
import DropdownArrow from 'src/containers/HeaderContainer/DropdownArrow';
import {
  getDetailNotification,
  getNotificationsApi
} from 'src/apis/socialNoti';
import { getNotificationApi } from 'src/apis/commonSocialApi';
import { useSocket } from '@novu/notification-center';
import { scrollSocial } from 'src/store/action/socialScrollAction';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  wrapHeader: {
    width: '100%',
    height: 56,
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1) !important',
    border: '1px solid rgba(0,0,0,0.10)'
  },
  iconButton: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    margin: '0px 10px'
  },
  paper: {
    backgroundColor: '#f1f2f5 !important',
    overflow: 'hidden !important'
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

export default function FullScreenDialog(props) {
  const { open, setOpen, typeWatch } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const match = useRouteMatch();
  const { socket } = useSocket();
  const [openMenuArrow, setOpenMenuArrow] = React.useState(false);
  const [numberNotification, setNumberNotification] = React.useState<any>(null);
  const [defaultDataNoti, setDefaultDataNoti] = React.useState<any>([]);
  const [noti, setNoti] = React.useState<number>();

  let query = useQuery();
  const location = useLocation();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const watchSearch = query.get('v');
  const pageId = query.get('page_id');

  const handleClick = () => {
    setOpenMenuArrow(prev => !prev);
  };

  function handleLinkToMe() {
    history.push(`/user/${meInfo.username ? meInfo.username : meInfo.id}`);
  }

  const handleClose = () => {
    setOpen(false);
    dispatch(postSelectedAction({}));
    if (location?.state?.prevPath) {
      history.push(location?.state?.prevPath);
      return;
    }

    if (query?.get('watchshowId')) {
      history.push(`/watch/watch-show/${query.get('watchshowId')}`);
      return;
    }

    if (typeWatch === 'watch-new') {
      history.push(`/watch/${typeWatch}`);
    } else if (typeWatch === 'watch-search') {
      history.push(`/watchs/search/?q=${watchSearch}`);
    } else if (typeWatch === 'post_saved') {
      history.push('/watch/watch-save');
    } else if (typeWatch === 'watch-detail') {
      history.push('/watch');
    } else if (typeWatch === 'post_page') {
      history.push(`/page/${pageId}`);
    } else {
      history.goBack();
    }
  };

  const fetchNotification = async () => {
    let res = await getNotificationsApi();
    setNumberNotification(res.data.notifications.unseen);
  };

  const fetchDataNoti = async () => {
    let response = await getNotificationApi({ limit: 20 });
    if (response.status === 200) {
      setDefaultDataNoti(response.data);

      if (response.data.length) {
      } else {
      }
    }
  };

  const fetchDetailNoti = async id => {
    let res = await getDetailNotification(id);
    if (res.status === 200) {
      setNoti(res.data);
    }
  };

  React.useEffect(() => {
    if (socket) {
      socket.on('unseen_count_changed', data => {
        fetchDetailNoti(data?.id);
      });
    }
    return () => {
      if (socket) {
        socket.off('unseen_count_changed');
      }
    };
  }, [socket]);

  // React.useEffect(() => {
  //   if (!match.path.includes('/watch')) {
  //     setOpen(false);
  //   }
  // }, [match.path]);

  React.useEffect(() => {
    if (open) {
      dispatch(scrollSocial('hidden'));
    }

    return () => {
      dispatch(scrollSocial('auto'));
    };
  }, [open]);

  React.useEffect(() => {
    fetchNotification();
    fetchDataNoti();
  }, [JSON.stringify(noti)]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paper: classes.paper }}
      >
        <Grid container className={classes.wrapHeader}>
          <Grid item xs={4} className={classes.headerLeft}>
            <Box className={classes.iconButton}>
              <IconButton disabled={!open} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              onClick={() => {
                setOpen(false);
                history.push('/');
              }}
            >
              <ButtonInherit
                color={buttonColor.backgroundColor}
                label="Trang chủ"
              />
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.headerRight}>
            <RightHeaderGlobal
              onSelectExpand={() => handleClick()}
              numberNotification={numberNotification}
              setNumberNotification={setNumberNotification}
              noti={noti}
              defaultDataNoti={defaultDataNoti}
            >
              <DropdownGlobalContainer />
            </RightHeaderGlobal>
          </Grid>
        </Grid>

        <WatchScreen setOpen={setOpen} open={open} />
        {openMenuArrow && (
          <DropdownArrow
            handleLinkToProfile={handleLinkToMe}
            setOpen={setOpenMenuArrow}
          />
        )}
      </Dialog>
    </div>
  );
}
