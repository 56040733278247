import * as types from 'src/constants/store/marketplaceSocial';
export const productPreview = (data: Object) => ({
  type: types.PRODUCT_PREVIEW_INFO,
  payload: {
    data
  }
});

export const getProductDetailSuccess = (data: any) => ({
  type: types.GET_PRODUCT_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getProductDetailError = (data: any) => ({
  type: types.GET_PRODUCT_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getProductDetailRequest = (
  idProduct: any,
  type: 'buyer' | 'seller' = 'buyer',
  pageId: string | null = null
) => ({
  type: types.GET_PRODUCT_DETAIL_REQUEST,
  payload: {
    idProduct,
    type,
    pageId
  }
});

export const getOrderDetailSuccess = (data: any) => ({
  type: types.GET_ORDER_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getOrderDetailError = (data: any) => ({
  type: types.GET_ORDER_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getOrderDetailRequest = (
  idOrder: any,
  type: 'buyer' | 'seller' = 'buyer',
  pageId: string | null = null
) => ({
  type: types.GET_ORDER_DETAIL_REQUEST,
  payload: {
    pageId,
    idOrder,
    type
  }
});

export const resetProduct = () => ({
  type: types.RESET_PRODUCT
});

// Each action in the shopping cart will be updated to redux using this action
export const getDetailCart = (data: Object) => ({
  type: types.GET_CART_DETAIL,
  payload: {
    data
  }
});

export const getCountChildProduct = (data: any) => ({
  type: types.GET_COUNT_CHILD_PRODUCT,
  payload: {
    data
  }
});
export const getListCart = (data: any) => ({
  type: types.GET_LIST_CART,
  payload: {
    data
  }
});

export const replaceListCart = (data: any) => ({
  type: types.REPLACE_LIST_CART,
  payload: {
    data
  }
});

export const updateListCart = (data: any) => ({
  type: types.UPDATE_LIST_CART,
  payload: {
    data
  }
});

export const updateVariantChecked = (data: any) => ({
  type: types.UPDATE_VARIANT_CHECKED,
  payload: { data }
});

export const updateListVoucher = (data: any) => ({
  type: types.UPDATE_LIST_VOUCHER,
  payload: {
    data
  }
});

export const getProductOwner = (data: Object) => ({
  type: types.GET_PRODUCT_OWNER,
  payload: {
    data
  }
});

export const loadingProduct = (value: any) => ({
  type: types.LOADING_PRODUCT,
  payload: {
    value
  }
});

export const changePaymentMethod = (value: any) => ({
  type: types.PAYMENT_METHOD,
  payload: {
    value
  }
});

export const loginFinanceMarket = (type: String) => ({
  type: types.LOGIN_FINANCE_MARKET,
  payload: type
});

export const getListMyPageMarketRequest = params => ({
  type: types.GET_LIST_MY_PAGE_MARKET_REQUEST,
  payload: {
    params
  }
});

export const getListMyPageMarketSuccess = data => ({
  type: types.GET_LIST_MY_PAGE_MARKET_SUCCESS,
  payload: {
    data
  }
});

export const getBalanceRequest = params => ({
  type: types.GET_BALANCE_REQUEST,
  payload: {
    params
  }
});

export const getBalanceSuccess = data => ({
  type: types.GET_BALANCE_SUCCESS,
  payload: {
    data
  }
});

export const isLoadingListPageMarket = (value: boolean) => ({
  type: types.LOADING_LIST_MY_PAGE_MARKET,
  payload: {
    value
  }
});
