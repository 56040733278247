import {
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

const MenuAction = React.memo((props: any) => {
  const { anchorEl, listMenu, openPopper, setOpenPopper } = props;
  const theme: any = useTheme();

  return (
    <>
      {openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            id="simple_menu"
            anchorEl={anchorEl}
            open={openPopper}
            placement="bottom-end"
            disablePortal
            style={{
              padding: '5px 0px',
              zIndex: 1001
            }}
          >
            <Paper>
              {listMenu
                ?.filter(item => !item.hidden)
                .map(
                  (item: any) =>
                    item && (
                      <MenuItem key={item.key} onClick={() => item.action()}>
                        {item.icon ? (
                          <ListItemIcon
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? '#fff' : '#000'
                            }}
                          >
                            <i className={`${item.icon} fa-lg`}></i>
                          </ListItemIcon>
                        ) : null}
                        {item.image ? (
                          <ListItemIcon sx={{ color: '#000' }}>
                            <img
                              src={item.image}
                              alt=""
                              width={24}
                              height={24}
                            ></img>
                          </ListItemIcon>
                        ) : null}
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, fontSize: '15px' }}
                        >
                          {item.label}
                          {item.description && (
                            <>
                              <br />
                              <Typography
                                style={{ fontSize: '12px', color: '#65676b' }}
                                variant="body2"
                              >
                                {item.description}
                              </Typography>
                            </>
                          )}
                        </Typography>
                      </MenuItem>
                    )
                )}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
});

export default MenuAction;
