import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  resetNotiPost,
  resetNotiSharePost
} from 'src/store/action/socialPostAction';

type TransitionProps = Omit<SlideProps, 'direction'>;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

interface CustomizedSnackBarsProps {
  open: boolean;
  setOpen: any;
  message: string;
  code: number;
  style?: any;
  notiPost?: any;
  icon?: any;
  type?: any;
  onClose?: any;
}

export default function CustomizedSnackBars(props: CustomizedSnackBarsProps) {
  const { open, setOpen, message, code, style, notiPost, icon, type, onClose } =
    props;
  const dispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (notiPost) {
      dispatch(resetNotiPost());
    }
  }, [JSON.stringify(notiPost)]);
  React.useEffect(() => {
    if (type) {
      setTimeout(() => {
        dispatch(resetNotiSharePost());
      }, 5000);
    }
  }, [type]);
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      TransitionComponent={TransitionUp}
      onClose={handleClose}
      sx={{
        ...style,
        padding: '4px',
        zIndex: 100000,
        marginLeft: '30px'
      }}
    >
      <Alert
        onClose={handleClose}
        severity={code === 200 ? 'success' : 'error'}
        sx={{
          width: '100%',
          backgroundColor: '#262626',
          color: 'white'
        }}
        variant="standard"
        icon={icon}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
