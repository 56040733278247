import { apiGet } from './apis';

export const SearchSticker = async (params: any) => {
  return apiGet('stickers/search', params, null);
};

export const getInventoryApi = () => {
  return apiGet('stickers/inventory', null, null);
};

export const getTagsKeywordApi = () => {
  return apiGet('tags/keyword', null, null);
};

export const getTrendingApi = () => {
  return apiGet('stickers/trendings', null, null);
};

export const getEmoticonApi = () => {
  return apiGet('stickers/tag2stickers/emotion', null, null);
};

export const getCategoryApi = () => {
  return apiGet('stickers/categories', null, null);
};

export const getPackFreeApi = () => {
  return apiGet('stickers/packs', null, null);
};

export const getPackDetailApi = id => {
  return apiGet(`stickers/packs/${id}`, null, null);
};
