import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  size?: any;
  hiddenTitle?: undefined;
  title?: string | undefined;
  text?: string | undefined;
  open: boolean;
  handleClose?: any;
  colorButton?: string | undefined;
  styleInBtn?: any;
  styleHover?: any;
  loading?: boolean | undefined;
  contentDialog?: any;
  alignText?: any;
  hiddenButton?: boolean | undefined;
  optionDialog?: any;
  labelButton?: any;
  actionOption?: any;
  btnDisabled?: any;
  styleDialogContent?: React.CSSProperties | undefined;
  type?: string | undefined;
}

export default function DialogConfirm(props: Props) {
  const {
    size,
    hiddenTitle,
    title,
    text,
    open,
    handleClose,
    colorButton,
    styleInBtn,
    styleHover,
    loading,
    contentDialog,
    alignText,
    hiddenButton,
    optionDialog,
    labelButton,
    actionOption,
    btnDisabled,
    styleDialogContent,
    type
  } = props;
  const showMultiButton = () => {
    return labelButton.map((titleBtn, index) => {
      return (
        <ButtonInherit
          color={
            colorButton === 'default'
              ? ''
              : colorButton
              ? colorButton[index] || buttonColor.backgroundColor
              : buttonColor.backgroundColor
          }
          style={
            styleInBtn
              ? { fontSize: '14px', ...styleInBtn[index] }
              : { fontSize: '14px' }
          }
          styleHover={styleHover ? styleHover[index] : null}
          label={titleBtn}
          loading={loading}
          action={() => {
            actionOption[index]();
          }}
          disabled={btnDisabled ? btnDisabled[index] : false}
        />
      );
    });
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
        maxWidth={size || 'sm'}
      >
        {!hiddenTitle && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Typography
                style={{ fontWeight: 'bold', fontSize: 20 }}
                textAlign={alignText ?? 'start'}
              >
                {title}
              </Typography>
            </BootstrapDialogTitle>
            <Divider />
          </>
        )}
        <DialogContent style={{ ...styleDialogContent }}>
          {type ? (
            text
          ) : (
            <Typography style={{ fontSize: 15, wordBreak: 'break-word' }}>
              {text}
            </Typography>
          )}
          {contentDialog || null}
        </DialogContent>
        {!hiddenButton && (
          <DialogActions>
            <ButtonInherit
              action={() => {
                handleClose();
              }}
              color={colorButton || buttonColor.backgroundColor}
              style={{ fontSize: '14px' }}
              label="OK"
              loading={loading}
            />
          </DialogActions>
        )}
        {optionDialog && (
          <DialogActions sx={{ paddingBottom: '16px !important' }}>
            {showMultiButton()}
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
