import * as React from 'react';import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  DialogActions,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { unSubRoom } from 'src/socket/RocketChatSubscriptions';
import { handleSettingNotification } from 'src/pages/Chat/functionChat';
import { buttonColor } from 'src/constants/styles';
function DialogSkipConversation(props) {
  const {
    open,
    setOpen,
    handleHideConversation,
    conversation,
    setIsToastMessage
    // handleSettingNotification
  } = props;
  const handleClose = () => {
    setOpen(false);
  };

  const handleSkipMessage = async () => {
    await handleSettingNotification(conversation, setIsToastMessage, true);
    await handleHideConversation();
    await unSubRoom(conversation.idSubRocket);
    handleClose();
  };
  const theme = useTheme();
  const showNameConversation = conversation.fname;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: '200px',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Bỏ qua cuộc trò chuyện này ?
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <Typography style={{ fontSize: '14px', padding: '12px 0 0' }}>
            Bạn sẽ không nhận được thông báo khi{' '}
            <span style={{ fontWeight: '600' }}>{showNameConversation}</span>{' '}
            nhắn tin trực tiếp cho bạn . Chúng tôi sẽ không cho{' '}
            <span style={{ fontWeight: '600' }}>{showNameConversation}</span>{' '}
            biết là bạn đã bỏ qua tin nhắn.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Xác nhận'}
            style={{
              width: '100px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: theme.palette.background.secondary,

              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleSkipMessage}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogSkipConversation;
