import * as types from 'src/constants/store/socialSaveCache';
export const saveCachePage = (dataPage: any) => ({
  type: types.SAVE_CACHE_PAGE,
  payload: dataPage
});
export const updateCachePage = (data: any) => ({
  type: types.UPDATE_CACHE_PAGE,
  payload: data
});
export const saveCachePageUser = dataPageUser => {
  return {
    type: types.SAVE_CACHE_PAGE_USER,
    payload: dataPageUser
  };
};
export const typeSaveCache = (type: any) => ({
  type: types.TYPE_CACHE,
  payload: type
});
export const saveIndexPost = (indexPost: any) => ({
  type: types.SAVE_INDEX_POST,
  payload: indexPost
});
export const saveGroupSuggest = (data: any) => ({
  type: types.SAVE_GROUPS_SUGGEST,
  payload: data
});
export const saveFriendSuggest = (data: any) => ({
  type: types.SAVE_FRIENDS_SUGGEST,
  payload: data
});
export const savePageSuggest = (data: any) => ({
  type: types.SAVE_PAGES_SUGGEST,
  payload: data
});
export const saveMomentSuggest = (data: any) => ({
  type: types.SAVE_MOMENTS_SUGGEST,
  payload: data
});
export const saveListFriendRequest = (data: any) => {
  return {
    type: types.SAVE_LIST_FRIEND_REQUEST,
    payload: data
  };
};
export const saveListGroup = (data: any) => ({
  type: types.SAVE_LIST_GROUP,
  payload: data
});
export const saveListPage = (data: any) => ({
  type: types.SAVE_LIST_PAGE,
  payload: data
});
export const saveListPageManage = (data: any) => ({
  type: types.SAVE_LIST_PAGE_MANAGE,
  payload: data
});
export const saveListEvent = (data: any) => ({
  type: types.SAVE_LIST_EVENT,
  payload: data
});
export const saveListEventHost = (data: any) => ({
  type: types.SAVE_LIST_EVENT_HOST,
  payload: data
});
export const saveListGroupChat = (data: any) => ({
  type: types.SAVE_LIST_GROUP_CHAT,
  payload: data
});
export const saveCheckMeOnline = (data: any) => ({
  type: types.SAVE_CHECK_ME_ONLINE,
  payload: data
});
export const actionReload = type => ({
  type: types.ACTION_RELOAD,
  payload: type
});
export const resetActivitiesCacheUser = () => ({
  type: types.RESET_ACTIVITIES_CACHE_USER
});

export const resetActivitiesCache = () => ({
  type: types.RESET_ACTIVITIES_CACHE
});
