import React from 'react';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';

import iconUrl from 'src/assets/images/location.png';

import 'leaflet/dist/leaflet.css';
import './CULocationPost.css';

const CULocationPost = props => {
  const { locationSelected, style } = props;

  let position: any = locationSelected?.location
    ? Object.values(locationSelected.location)
    : [];

  const newIcon = new Leaflet.Icon({
    iconUrl,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [28, 30]
  });

  return position.length ? (
    <MapContainer
      style={style ? style : { height: 149, width: 469 }}
      center={position}
      zoom={15}
      scrollWheelZoom={false}
      zoomControl={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url={`https://api.mapbox.com/styles/v1/thanghoa1420/cl8dw369f000114pdxdhvyrf3/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhhbmdob2ExNDIwIiwiYSI6ImNsOGNzaGNraTBvaXozcW9mOTZvemlxM3QifQ.amy7PdzwTqaolJMVQzpGSg`}
      />
      <Marker
        title={locationSelected.name}
        icon={newIcon}
        draggable={false}
        position={position}
      >
        <Popup>{locationSelected.name}</Popup>
      </Marker>
    </MapContainer>
  ) : null;
};

export default CULocationPost;
