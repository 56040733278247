import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { FormControl, Typography, TextField } from '@mui/material';

const BootstrapInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: ({ multiline }) => (multiline ? 'none' : '1px solid #ced4da'),
    fontSize: 15,
    padding: ({ multiline }) => (multiline ? '8px 12px' : '10px 12px'),
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: ({ theme, multiline }) =>
        multiline
          ? 'none'
          : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: ({ theme, multiline }) =>
        multiline ? 'transparent' : theme.palette.primary.main
    },
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bdbdbd',
      borderRadius: 10
    }
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: theme.palette.mode === 'dark' ? ' #fff' : '#000',
    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
  }
}));

const InputUpLoad = props => {
  const {
    fullWidth,
    placeholder,
    field,
    formik,
    inputProps,
    rows,
    multiline,
    title,
    required,
    disabled,
    value,
    action,
    style,
    styleTitle,
    secondTitle,
    styleSecondTitle,
    styleInput,
    type,
    handleKeyDown,
    endAdornment,
    error
  } = props;

  return (
    <FormControl style={{ width: '100%', ...style }} variant="standard">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ ...styleTitle }}>{title}</Typography>
        <Typography sx={{ ...styleSecondTitle }}>{secondTitle}</Typography>
      </div>
      <BootstrapInput
        fullWidth={fullWidth}
        placeholder={placeholder}
        name={field ?? 'field'}
        value={formik ? formik.values[field] : value}
        onChange={e => {
          let newValue = e?.target?.value;
          if (type === 'text') {
            // Loại bỏ khoảng trắng đầu và cuối
            newValue = newValue.trimStart().replace(/^\s+/, '');
          }
          if (newValue.startsWith(' ')) {
            newValue = newValue.substring(1);
          }
          if (formik) {
            formik.setFieldValue(field, newValue);
          } else {
            action(newValue);
          }
        }}
        onBlur={formik ? formik.handleBlur : null}
        error={
          formik
            ? formik.touched[field] && Boolean(formik.errors[field])
            : error
        }
        helperText={
          formik ? formik.touched[field] && formik.errors[field] : error
        }
        multiline={multiline}
        rows={rows}
        required={required}
        disabled={disabled}
        sx={styleInput}
        inputProps={inputProps}
        type={type && type}
        onKeyDownCapture={handleKeyDown}
        onClick={(e) => {
          action && action(e)
        }}
        InputProps={{
          endAdornment: endAdornment
        }}
      />
    </FormControl>
  );
};

export default InputUpLoad;
