import React from 'react';
import Routes from 'src/routes/routes';
import { CssBaseline } from '@mui/material';
import { withTheme } from '@mui/styles';
import './App.css';
import '../assets/scss/myScss/app.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from 'src/constants/common';
import { SnackbarProvider } from 'notistack';
function App() {
  const token: any = localStorage.getItem('token');
  const [lastHiddenTime, setLastHiddenTime] = React.useState(null as any);

  React.useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  React.useEffect(() => {
    if (!token) {
      localStorage.setItem('darkThemeUser', 'light');
    }
  }, []);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // If the tab becomes hidden, store the current time
        setLastHiddenTime(Date.now());
      } else if (lastHiddenTime) {
        // When the tab becomes visible, check if it was inactive for over 10 minutes
        const hiddenDuration = Date.now() - lastHiddenTime;
        if (hiddenDuration >= 1 * 60 * 10000) {
          window.location.reload(); // Reload the page if hidden for over 10 minutes
        }
      }
    };

    // Add visibility change event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      // Cleanup listener on component unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [lastHiddenTime]);

  return (
    <div className="app">
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <CssBaseline />
          <Routes />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </div>
  );
}

export default withTheme(App);
