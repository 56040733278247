import * as React from 'react';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  ListItemText,
  ListItem
} from '@mui/material';
import MuiListItem from '@material-ui/core/ListItem';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import IconButtonOptions from '../Button/IconButtonOption';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import {
  unFriendUser,
  unFollowUser,
  followUser,
  addObjectBlock
} from 'src/apis/socialUser';
import { reportPostGroupApi } from 'src/apis/socialGroup';
import ButtonCustom from 'src/components/Button/Button';
import { getInforUser } from 'src/apis/socialUser';
import DialogConfirmReport from 'src/components/Dialog/DialogConfirmReport';
import { useHistory } from 'react-router-dom';
import { notiSharePost } from 'src/store/action/socialPostAction';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: 600,
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'hidden'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      firstListItem: {
        marginLeft: 10,
        '&.MuiListItemText-root': {
          fontWeight: 'bold'
        }
      },
      list: {
        '&.MuiListItem-root': {
          display: 'flex !important',
          padding: '0px 0px 0px 0px',
          '&:hover:': {
            cursor: 'pointer',
            backgroundColor: 'silver'
          }
        }
      },
      buttonAction: {
        display: 'flex',
        minWidth: 'fit-content'
      }
    }),
  { index: 1 }
);
const ListItems = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#e5e5e5',
      cursor: 'pointer',
      borderRadius: 10
    }
  }
})(MuiListItem);
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  back?: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, back, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogUpdatePage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { open, styleText, handleClose, info, postReport } = props;
  const [fieldUpdate, setFieldUpdate] = React.useState<any>(null);
  const [fieldConfirm, setFieldConfirm] = React.useState<any>(null);
  const [userReport, setUserReport] = React.useState<any>(null);
  const [confirmUnFriend, setConfirmUnFriend] = React.useState<any>(false);
  const [confirmUnFollow, setConfirmUnFollow] = React.useState<any>(false);
  const [confirmBlocked, setConfirmBlocked] = React.useState<any>(false);
  const [openDialogConfirmReport, setOpenDialogConfirmReport] =
    React.useState<any>(false);
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const dispatch: any = useDispatch();
  async function fetchRelationships() {
    try {
      let response = await getInforUser(
        postReport?.account?.id ?? info?.account?.id,
        null
      );
      if (response.status === 200) {
        setUserReport(response.data?.relationships);
        if (response.data?.relationships?.following === false) {
          setConfirmUnFollow(true);
        }
        //   setConfirmUnFriend(true);
      }
    } catch (error: any) {
      setConfirmBlocked(true);
    }
  }
  const handleBlockUser = async () => {
    let res = await addObjectBlock('blocks', {
      account_id: postReport?.account?.id ?? info?.account?.id
    });
    if (res.status === 200) {
      setOpenDialogConfirmReport(true);
    }
  };
  const handleunFriendUser = async () => {
    let res = await unFriendUser(postReport?.account?.id ?? info?.account?.id);
    if (res.status === 200) {
      setConfirmUnFriend(true);
    }
  };
  const handleunFollowUser = async () => {
    let res = await unFollowUser(postReport?.account?.id ?? info?.account?.id);
    if (res.status === 200) {
      setConfirmUnFollow(true);
    }
  };
  const handleFollowUser = async () => {
    let res = await followUser(postReport?.account?.id ?? info?.account?.id);
    if (res.status === 200) {
      setConfirmUnFollow(false);
    }
  };
  const listCategoriesAdminReport = [
    { id: 1, text: 'Vi phạm quy tắc nhóm' },
    { id: 2, text: 'Nội dung không liên quan' },
    { id: 3, text: 'Tin giả' },
    { id: 4, text: 'Xung đột giữa các thành viên' },
    { id: 5, text: 'Spam' },
    { id: 6, text: 'Quấy rối' },
    { id: 7, text: 'Ngôn ngữ gây thù gét' },
    { id: 8, text: 'Ảnh khỏa thân hoặc hoạt động tình dục' },
    { id: 9, text: 'Bạo lực' },
    { id: 10, text: 'Vấn đề khác' }
  ];
  const ListItemOption = [
    {
      id: 'reportPersonPage',
      icon:
        confirmBlocked === true
          ? 'fa-regular fa-check'
          : 'fa-regular fa-user-lock',
      contentTop: `${
        confirmBlocked === true ? 'Đã chặn' : 'Chặn'
      } trang cá nhân của ${
        postReport?.account?.display_name ?? info?.account?.display_name
      }`,
      contentBottom: 'Các bạn sẽ không thể nhìn thấy hoặc liên hệ với nhau.',
      action: () => {
        handleBlockUser();
      },
      visible: true
    },
    {
      id: 'unfollow',
      icon:
        confirmUnFollow === true
          ? 'fa-regular fa-check'
          : 'fa-regular fa-user-xmark',
      contentTop: `${confirmUnFollow === true ? 'Đã bỏ' : 'Bỏ'} theo dõi ${
        postReport?.account?.display_name ?? info?.account?.display_name
      }`,
      contentBottom: 'Dừng xem bài viết nhưng vẫn là bạn bè.',
      action: () => {
        handleunFollowUser();
      },
      visible: userReport?.friendship_status === 'ARE_FRIENDS' ? true : false
    },
    {
      id: 'unfriend',
      icon:
        confirmUnFriend === true
          ? 'fa-regular fa-check'
          : 'fa-regular fa-user-minus',
      contentTop: `${confirmUnFriend === true ? 'Đã hủy' : 'Hủy'} kết bạn với ${
        postReport?.account?.display_name ?? info?.account?.display_name
      }`,
      contentBottom: 'Các bạn sẽ không còn là bạn bè trên EMSO nữa.',
      action: () => {
        handleunFriendUser();
      },
      visible: userReport?.friendship_status === 'ARE_FRIENDS' ? true : false
    }
    // {
    //   id: 'hide',
    //   icon: 'fa-regular fa-user-xmark',
    //   contentTop: `Ẩn tất cả từ ${
    //     postReport?.account?.display_name ?? info?.account?.display_name
    //   }`,
    //   contentBottom: 'Không xem bài viết từ người này nữa.',
    //   action: () => {},
    //   visible: userReport?.friendship_status === 'CAN_REQUEST' ? true : false
    // }
  ];
  const handlePostReportAdmin = async (option: any) => {
    try {
      let res = await reportPostGroupApi(postReport?.group.id, {
        account_id: postReport?.account?.id ?? info?.account?.id,
        status_id: postReport?.id ?? info?.id,
        comment: '',
        forward: false,
        category: option?.id
      });
      if (res.status === 200) {
        setFieldConfirm(option);
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Đã gửi phản hồi cho quản trị viên nhóm. Cảm ơn vì sự đóng góp của bạn.'
          })
        );
      }
    } catch (error) {
      console.log('🚀 ~ handlePostReportAdmin ~ error:', error);
    }
  };
  const renderContentAction = (option: any, type: any = null) => {
    return (
      <IconButtonOptions
        name={option.text}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          height: 45,
          hover: {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? '#3e4042 !important'
                : '#fff !important'
          },
          marginLeft: '0px'
        }}
        icon={
          <i
            className="fa-light fa-angle-right"
            style={{
              marginRight: '4px'
            }}
          ></i>
        }
        startIconStyle={{
          fontSize: 18,
          margin: '0px 10px 0px 0px',
          backgroundColor: '#ecebeb',
          color: '#000',
          padding: '8px',
          borderRadius: '50%',
          border: '1px solid #e2e2e2'
        }}
        styleNameButton={{
          color:
            theme.palette.mode === 'dark'
              ? '#fff !important'
              : '#000 !important'
        }}
        action={() => {
          handlePostReportAdmin(option);
        }}
      />
    );
  };
  const renderContentList = (option: any, key) => {
    return fieldUpdate === option.id ? (
      <div key={key}>
        <ListItem className={classes.list}>
          <i
            className={option?.icon}
            style={{
              width: 40,
              height: 40,
              backgroundColor: '#d8dadf',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 20
            }}
          ></i>
          <ListItemText
            className={classes.firstListItem}
            primary={
              <>
                <Typography
                  style={{
                    fontSize: 17,

                    fontWeight: 600
                  }}
                >
                  {option?.contentTop}
                </Typography>
              </>
            }
          />
          <div className={classes.buttonAction}>
            <ButtonInherit
              style={{ backgroundColor: '#1a6ed8', color: '#fff' }}
              label="Đồng ý"
              action={() => {
                setFieldUpdate('');
                option?.action();
              }}
            />
            <ButtonInherit label="Hủy" action={() => setFieldUpdate('')} />
          </div>
        </ListItem>
      </div>
    ) : (
      <div
        key={key}
        onClick={() =>
          (confirmUnFriend === true && option?.id === 'unfriend') ||
          (confirmBlocked === true && option?.id === 'reportPersonPage')
            ? setFieldUpdate('')
            : setFieldUpdate(option.id)
        }
      >
        <ListItem className={classes.list}>
          <div
            style={{
              width: 40,
              height: 40,
              backgroundColor: '#d8dadf',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 20
            }}
          >
            <i
              className={option?.icon}
              style={{
                fontSize: 20,
                marginLeft: 5
              }}
            ></i>
          </div>
          <ListItemText
            className={classes.firstListItem}
            primary={
              <>
                <Typography
                  style={{
                    fontSize: 17,

                    fontWeight: 600
                  }}
                >
                  {option?.contentTop}
                </Typography>
                <Typography
                  style={{
                    fontSize: 15,
                    color: '#808080',
                    fontWeight: 400
                  }}
                >
                  {option?.contentBottom}
                </Typography>
              </>
            }
          />
          {option?.id === 'unfollow' && confirmUnFollow === true ? (
            <ButtonInherit
              style={{
                backgroundColor: '#e7f3ff',
                color: buttonColor.backgroundColor
              }}
              label="Hoàn tác"
              action={() => {
                handleFollowUser();
                setFieldUpdate('');
              }}
            />
          ) : null}
        </ListItem>
      </div>
    );
  };
  React.useEffect(() => {
    if (postReport?.account?.id || info?.account?.id) {
      fetchRelationships();
    }
  }, [postReport?.account?.id, info?.account?.id]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '300px'
        }}
      >
        <BootstrapDialog
          onClose={() => {
            handleClose();
            setFieldUpdate(null);
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          BackdropProps={modalBackdrop}
          maxWidth={'sm'}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              handleClose();
              setFieldUpdate(null);
            }}
            // back={
            //   fieldConfirm?.id
            //     ? () => setFieldConfirm(null)
            //     : fieldUpdate?.id
            //     ? () => setFieldUpdate(null)
            //     : undefined
            // }
          >
            <Typography
              style={{ fontWeight: 'bold', fontSize: 21 }}
              textAlign={'center'}
            >
              {fieldConfirm?.id
                ? 'Cảm ơn bạn đã báo cáo bài viết này'
                : 'Báo cáo'}
            </Typography>
          </BootstrapDialogTitle>
          {fieldConfirm?.id ? (
            <DialogContent dividers className={classes.root}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 10
                }}
              >
                <ButtonCustom
                  style={{
                    padding: '0px 8px',
                    //   flex: 'auto!important',
                    borderRadius: 20,
                    width: 'fit-content'
                  }}
                  icon={
                    <i
                      style={{ fontSize: 15 }}
                      className="fa-solid fa-check"
                    ></i>
                  }
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {fieldConfirm?.text}
                    </Typography>
                  }
                />
              </div>

              <Typography
                style={{
                  fontWeight: 400,
                  textAlign: 'center',
                  marginBottom: 10
                }}
              >
                Bạn đã báo cáo bài viết này với quản trị viên nhóm.
              </Typography>
              <div>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 20
                  }}
                >
                  Các bước khác mà bạn có thể thực hiện
                </Typography>
              </div>
              {ListItemOption?.filter((el: any) => el.visible === true)?.map(
                (item: any, index) => renderContentList(item, index)
              )}
              <ButtonInherit
                label="Xong"
                style={{
                  width: '100%',
                  backgroundColor: buttonColor.backgroundColor,
                  marginTop: 10,
                  color: '#ffffff'
                }}
                action={() => {
                  handleClose();
                }}
              />
            </DialogContent>
          ) : (
            <DialogContent
              dividers
              className={classes.root}
              style={{ overflow: 'hidden !important' }}
            >
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: '8px',
                  fontWeight: 'bold',
                  ...styleText
                }}
              >
                Báo cáo bài viết với quản trị viên
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  marginLeft: '8px',
                  ...styleText
                }}
              >
                Hãy cho quản trị viên biết bài viết này có vấn đề gì. Chúng tôi
                sẽ không thông báo cho người đăng rằng bạn đã báo cáo.
              </Typography>
              {listCategoriesAdminReport?.map((el: any, index) => (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    margin: '5px 0px',
                    overflow: 'hidden'
                  }}
                >
                  {renderContentAction(el)}
                </div>
              ))}
            </DialogContent>
          )}
        </BootstrapDialog>
      </div>
      {openDialogConfirmReport && (
        <DialogConfirmReport
          open={openDialogConfirmReport}
          handleClose={() => {
            setOpenDialogConfirmReport(false);
            handleClose();
            history.push(
              `/group/${groupSelected?.username ?? groupSelected?.id}`
            );
          }}
          userBlock={
            postReport?.account?.display_name ?? info?.account?.display_name
          }
        />
      )}
    </>
  );
}
