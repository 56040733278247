import * as React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  IconButton,
  Popover,
  Box,
  CircularProgress,
  Skeleton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { urlRocketChat } from 'src/util/config';

import {
  getRoomRoles,
  removeMemberGroupChat,
  getListMemberGroupChat,
  searchMemberChannelGroupChat
} from 'src/apis/socialChat.apis';
import { resetListPopupChat } from 'src/store/action/socialChatAction';

import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';

import { handleSelectedChatUser } from 'src/pages/Chat/functionChat';
import { buttonColor } from 'src/constants/styles';

// const styleIcon = { fontWeight: '300', fontSize: '20px', color: '#212121' };

const styleListAction = {
  width: '250px',
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  margin: 0
};

const styleTypography = {
  fontWeight: '500',
  fontSize: '15px',
  fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
  display: 'inline-block',
  marginLeft: '20px'
};

const useStyles = makeStyles((theme: any) => ({
  style_root: {
    width: '100%',
    height: '100%',
    marginTop: '10px',
    overflowY: 'auto',
    color: theme.palette.text.primary,
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  },
  styleIcon: {
    fontWeight: '300',
    fontSize: '20px',
    color: theme.palette.text.primary
  }
}));
export default function DialogMemberGroupChat(props) {
  const {
    open,
    setOpen,
    conversation,
    listMemberGrChat,
    handleRemoveAdmin,
    handleAddAdmin,
    listAdmin,
    currentRole,
    setAnchorEl,
    anchorEl
    // handleChatNow
  } = props;
  const meInfo = useSelector((state: any) => state.meReducer.info);

  const dispatch = useDispatch();
  const theme = useTheme();
  const match: any = useRouteMatch();
  const history = useHistory();
  const classes: any = useStyles();
  const [typeMember, setTypeMember] = React.useState<'all' | 'admin'>('all');
  const [listMember, setListMember] =
    React.useState<Object[]>(listMemberGrChat);

  const [page, setPage] = React.useState<number>(0); // chua co infinityscroll, hien lay 25 members dau tien
  const [userSelected, setUserSelected] = React.useState<any>({});
  const [isToastMessage, setIsToastMessage] = React.useState<boolean>(false);

  const openPopover = Boolean(anchorEl);
  const [keyword, setKeyword] = React.useState<string>('');
  const [searchMembers, setSearchMembers] = React.useState<any>([]);
  const [isLoadingSearch, setIsLoadingSearch] = React.useState<boolean>(false);
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );
  const [isLoadingMembers, setIsLoadingMembers] =
    React.useState<boolean>(false);

  const isUserAdmin =
    listAdmin && listAdmin.find((el: any) => el.u._id === userSelected?._id);
  const isMeInfo = meInfo.id === userSelected?._id;
  const isAdmin = listAdmin.find((el: any) => el.u._id === meInfo.id);

  // const handleFetchListAdmin = async () => {
  //   const response = await getRoomRoles(conversation.rid);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickHeader = (type: 'all' | 'admin') => {
    setTypeMember(type);
  };

  const listActionMember = [
    {
      label: 'Nhắn tin',
      icon: 'fa-light fa-message-lines',
      id: 'message',
      classes: classes.styleIcon,
      action: () => {
        const existsPopup = listPopupChat.find(el =>
          el.conversation.rid.includes(userSelected.id)
        );
        if (!existsPopup) {
          handleSelectedChatUser(
            userSelected,
            match,
            history,
            dispatch,
            setIsToastMessage,
            setOpen
          );
        }
      }
    },
    {
      label: 'Xem trang cá nhân',
      icon: 'fa-light fa-user',
      id: 'profile',
      classes: classes.styleIcon,
      action: () => {
        history.push(`/user/${userSelected.username}`);
        dispatch(resetListPopupChat());
        handleClose();
      }
    }
  ];

  const listActionAdmin = [
    isUserAdmin
      ? {
          label: 'Xóa quản trị viên',
          icon: 'fa-light fa-user-minus',
          id: 'deleteAdmin',
          classes: classes.styleIcon,
          render: isAdmin && !isMeInfo ? 'block' : 'none',
          action: () => {
            handleRemoveAdmin(conversation, userSelected);
          }
        }
      : {
          label: 'Thêm làm quản trị viên',
          icon: 'fa-light fa-user-plus',
          id: 'addAdmin',
          classes: classes.styleIcon,
          action: () => {
            handleAddAdmin(conversation, userSelected);
          }
        },
    {
      label: 'Xóa khỏi nhóm',
      icon: 'fa-light fa-ban',
      id: 'deleteGroup',
      classes: classes.styleIcon,
      render: isAdmin && !isMeInfo ? 'block' : 'none',

      action: () => {
        handleRemoveMember();
      }
    }
  ];

  const handleSearchMemberInGroupChat = React.useCallback(
    _.debounce(async q => {
      const roomId = conversation.rid;
      const count = 25;
      const offset = 0;
      setIsLoadingSearch(true);
      try {
        if (conversation.t === 'p') {
          const response = await getListMemberGroupChat(
            count,
            offset,
            roomId,
            q
          );
          if (response.status === 200) {
            setSearchMembers(response.data.members);
            setIsLoadingSearch(false);
          }
        }

        if (conversation.t === 'c') {
          const response = await searchMemberChannelGroupChat(
            count,
            q,
            offset,
            roomId
          );
          if (response.status === 200) {
            setSearchMembers(response.data.members);
            setIsLoadingSearch(false);
          }
        }
      } catch (error) {
        console.log({ error });
      }
    }, 500),
    []
  );

  React.useEffect(() => {
    if (keyword) {
      handleSearchMemberInGroupChat(keyword);
    }
  }, [keyword]);

  const handleOpenPopupAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: any
  ) => {
    setAnchorEl(event.currentTarget);
    setUserSelected(user);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRemoveMember = async () => {
    const roomId = conversation.rid;
    const memberId = userSelected._id;
    if (roomId && memberId) {
      try {
        const response = await removeMemberGroupChat(roomId, memberId);

        if (response.status === 200) {
          setAnchorEl(null);
          setListMember(prev => prev.filter((el: any) => el._id !== memberId));
        }
      } catch (error) {}
    }
  };

  const getListMemberInGroup = async () => {
    try {
      setIsLoadingMembers(true);
      const count: number = 30;
      const offset: number = 0;
      const roomId: string = conversation.rid;
      let res = await getListMemberGroupChat(count, offset, roomId);
      if (res.status === 200) {
        setIsLoadingMembers(false);

        setListMember(res.data?.members);
      }
    } catch (error) {
      setIsLoadingMembers(false);
      console.log('error', error);
    }
  };
  React.useEffect(() => {
    getListMemberInGroup();
  }, []);

  const listMemberRender =
    typeMember === 'all'
      ? listMember
      : listMember.filter(
          (el: any) =>
            listAdmin &&
            listAdmin.map((admin: any) => admin.u._id).includes(el._id)
        );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '16px',
            height: '532px',
            width: '497px',
            zIndex: 0
          }
        }}
      >
        <IconButtonOptions
          icon={
            <i
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.mode === 'light' ? '#505256' : '#fff'
              }}
              className="fa-light fa-xmark-large"
            ></i>
          }
          action={() => handleClose()}
          style={{
            borderRadius: '50%',
            width: '36px',
            marginRight: '0',
            zIndex: 1,
            top: '25px',
            right: '16px',
            position: 'absolute'
          }}
        />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <ButtonInherit
              label={
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color:
                      typeMember === 'all'
                        ? buttonColor.backgroundColor
                        : '#6e7074'
                  }}
                >
                  {`Tất cả (${listMember.length})`}
                </Typography>
              }
              style={{
                width: '110px',
                height: '60px',
                borderRadius: 0,
                borderBottom:
                  typeMember === 'all'
                    ? `solid 3px ${buttonColor.backgroundColor}`
                    : 'none',
                backgroundColor: theme.palette.anTd.primary
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#e4e6eb !important',
                  borderRadius: '8px',
                  padding: '8px'
                }
              }}
              action={() => handleClickHeader('all')}
            />
            <ButtonInherit
              label={
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color:
                      typeMember === 'admin'
                        ? buttonColor.backgroundColor
                        : '#6e7074'
                  }}
                >
                  {`Quản trị viên (${listAdmin.length})`}
                </Typography>
              }
              style={{
                width: '140px',
                height: '60px',
                borderRadius: 0,
                borderBottom:
                  typeMember === 'admin'
                    ? `solid 3px ${buttonColor.backgroundColor}`
                    : 'none',
                backgroundColor: theme.palette.anTd.primary
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#e4e6eb !important',
                  borderRadius: '8px',
                  padding: '8px'
                }
              }}
              action={() => handleClickHeader('admin')}
            />
          </div>
          {/* <Divider /> */}
          {typeMember === 'all' && (
            <InputSearch
              label="Tìm kiếm thành viên"
              keyword={keyword}
              setKeyword={setKeyword}
              // action={handleSearchMemberInGroupChat}
              styleInput={{
                margin: '12px 0'
              }}
            />
          )}

          <Box className={classes.style_root}>
            {keyword ? (
              isLoadingSearch ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                typeMember === 'all' &&
                searchMembers.map(el => (
                  <ListItem
                    key={el._id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '50px',
                      padding: '0px 8px'
                    }}
                  >
                    <ListItemAvatar>
                      <AvatarSocial
                        type="feed"
                        avatarObj={`${urlRocketChat}/avatar/${el.username}`}
                        style={{ width: 36, height: 36, marginRight: '15px' }}
                        // userId={member?.account?.id}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontSize: '14px', fontWeight: '600' }}
                        >
                          {el.name}
                        </Typography>
                      }
                    />
                    <IconButton
                      style={{
                        borderRadius: '50%',
                        width: '36px',
                        height: '36px',
                        marginRight: '-15px',
                        zIndex: 1,
                        top: '8px',
                        right: '16px',
                        position: 'absolute'
                      }}
                      onClick={e => {
                        handleOpenPopupAction(e, el);
                      }}
                    >
                      <i
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: '#505256'
                        }}
                        className="fa-regular fa-ellipsis"
                      ></i>
                    </IconButton>
                  </ListItem>
                ))
              )
            ) : (
              listMemberRender.map((el: any, index) => {
                return isLoadingMembers ? (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      marginBottom: '15px'
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={45}
                      height={45}
                    />
                    <Box sx={{ width: '100%', marginLeft: '10px' }}>
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="40%"
                        style={{ marginBottom: 6 }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <ListItem
                    key={el._id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '50px',
                      padding: '0px 8px'
                    }}
                  >
                    <ListItemAvatar>
                      <AvatarSocial
                        type="feed"
                        avatarObj={
                          el.avatar_media ??
                          el.avatar_social ??
                          el.avatar_media?.show_url ??
                          `${urlRocketChat}/avatar/${el.username}`
                        }
                        style={{ width: 36, height: 36, marginRight: '15px' }}
                        // userId={member?.account?.id}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontSize: '14px', fontWeight: '600' }}
                        >
                          {el.name}
                        </Typography>
                      }
                    />
                    <IconButton
                      style={{
                        borderRadius: '50%',
                        width: '36px',
                        height: '36px',
                        marginRight: '-15px',
                        zIndex: 1,
                        top: '8px',
                        right: '16px',
                        position: 'absolute'
                      }}
                      onClick={e => {
                        handleOpenPopupAction(e, el);
                      }}
                    >
                      <i
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: '#505256'
                        }}
                        className="fa-regular fa-ellipsis"
                      ></i>
                    </IconButton>
                  </ListItem>
                );
              })
            )}
            {}
          </Box>
        </DialogContent>
      </Dialog>

      {openPopover && (
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {listActionMember.map((el: any) => {
            return (
              <ListItem
                key={el.label}
                button
                onClick={el.action}
                sx={styleListAction}
              >
                <i id={classes.styleIcon} className={el.icon}></i>
                <Typography
                  sx={styleTypography}
                  color={theme.palette.text.primary}
                >
                  {el.label}
                </Typography>
              </ListItem>
            );
          })}
          {currentRole === 'admin'
            ? listActionAdmin.map((action: any) => (
                <ListItem
                  button
                  key={action.label}
                  onClick={action.action}
                  sx={{
                    ...styleListAction,
                    display: action.render
                  }}
                >
                  <i id={classes.styleIcon} className={action.icon}></i>
                  <Typography
                    sx={styleTypography}
                    color={theme.palette.text.primary}
                  >
                    {action.label}
                  </Typography>
                </ListItem>
              ))
            : null}
        </Popover>
      )}
    </>
  );
}
