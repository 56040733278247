import { apiDelete, apiGet, apiPatch, apiPost } from './apis';

export const createInviteGroup = async (idGroup: any, data: any) => {
  return apiPost(`groups/${idGroup}/invitations`, null, data);
};

export const getListGroupApi = async (params: Object) => {
  return apiGet('groups', params, null);
};

export const getWaitingPostApi = async (id, params) => {
  return apiGet(`groups/${id}/pending_statuses`, params, null);
};

export const updateWaitingPostApi = async (id, params, data) => {
  return apiPatch(`groups/${id}/pending_statuses`, params, data);
};

export const updateWaitingMemberApi = async (id, data) => {
  return apiPost(`groups/${id}/join_requests/respond`, null, data);
};

export const getPostAllGroupApi = async (params: Object) => {
  return apiGet('timelines/group_collection', params, null);
};

export const deleteGroupApi = async id => {
  return apiDelete(`groups/${id}`, null, null);
};

export const getGroupAccountApi = async (id: any, params: any) => {
  return apiGet(`groups/${id}/accounts`, params, null);
};

export const searchGroupAccountApi = async (id, params) => {
  return apiGet(`groups/${id}/member_search`, params, null);
};

export const searchGroupByCategoryApi = async (id, params) => {
  return apiGet(`groups/_/category/${id}`, params, null);
};

export const addMemberGroupApi = async (id, data) => {
  return apiPost(`groups/${id}/accounts`, null, data);
};

export const getListGroupResponseApi = async (params: Object) => {
  return apiGet('group_invitations', params, null);
};

export const getListGroupRequestApi = async (params: Object) => {
  return apiGet('group_join_requests', params, null);
};

export const deleteMemberGroupApi = async id => {
  return apiDelete(`groups/${id}/leave`, null, null);
};

export const updatePermissionAccountGroupApi = async (id, params, data) => {
  return apiPatch(`groups/${id}/accounts`, params, data);
};

export const deleteAccountGroupApi = async (id, params) => {
  return apiDelete(`groups/${id}/removed_accounts`, params, null);
};

export const CUDRuleGroupApi = async (type, id, data, params) => {
  if (type === 'create') return apiPost(`/groups/${id}/rules`, params, data);

  if (type === 'update') return apiPatch(`/groups/${id}/rules`, params, data);

  if (type === 'get') return apiGet(`/groups/${id}/rules`, params, data);

  return apiDelete(`/groups/${id}/rules`, params, data);
};

export const requestListAccountGroupApi = async (id, params) => {
  return apiGet(`groups/${id}/join_requests`, params, null);
};

export const responseRequestListAccountGroupApi = async (id, data) => {
  return apiPost(`groups/${id}/invitations/respond`, null, data);
};

export const getListAccountRemoveGroupApi = async id => {
  return apiGet(`groups/${id}/removed_accounts`, null, null);
};

export const removeAccountGroupApi = async (id, params, type = 'post') => {
  if (type === 'post')
    return apiPost(`groups/${id}/removed_accounts`, params, null);

  return apiDelete(`groups/${id}/removed_accounts`, params, null);
};

export const groupRelationshipApi = async (data: Object) => {
  return apiPost('group_relationships', null, data);
};

export const groupCategoryApi = async (params = null) => {
  return apiGet('categories', params, null);
};

export const groupTimelineApi = async (id, params) => {
  return apiGet(`timelines/group/${id}`, params, null);
};

export const searchFilterPostInGroup = async (id, params) => {
  return apiGet(`timelines/group/${id}/search`, params, null);
};
export const deleteStatusGroupApi = async id => {
  return apiDelete(`groups/1/statuses/${id}`, null, null);
};

export const getGroupDetailApi = async id => {
  return apiGet(`groups/${id}`, null, null);
};

export const updateSettingGroupApi = async (id, data) => {
  return apiPatch(`groups/${id}/settings`, null, data);
};

export const getReportPostGroupApi = async (id, params) => {
  return apiGet(`groups/${id}/reported_statuses`, params, null);
};

export const reportPostGroupApi = async (id, data) => {
  return apiPost(`groups/${id}/reported_statuses`, null, data);
};

export const deleteReportPostGroupApi = async (id, params) => {
  return apiDelete(`groups/${id}/reported_statuses`, params, null);
};

export const deleteReportAndPostGroupApi = async (id, statusId) => {
  return apiPost(
    `groups/${id}/reported_statuses/${statusId}/approve`,
    null,
    null
  );
};

export const deleteSchedulePostGroupApi = async (id, params) => {
  return apiDelete(`groups/${id}/scheduled_statuses`, params, null);
};

export const CRUDMemberQuestionGroupApi = async (type, id, params, data) => {
  if (type === 'post')
    return apiPost(`groups/${id}/member_questions`, params, data);

  if (type === 'patch')
    return apiPatch(`groups/${id}/member_questions`, params, data);

  if (type === 'delete')
    return apiDelete(`groups/${id}/member_questions`, params, data);

  return apiGet(`groups/${id}/member_questions`, params, data);
};

export const publishSchedulePostGroupApi = async (id, statusId) => {
  return apiPost(
    `groups/${id}/scheduled_statuses/${statusId}/public`,
    null,
    null
  );
};

export const updateSchedulePostGroupApi = async (id, params, data) => {
  return apiPatch(`groups/${id}/scheduled_statuses`, params, data);
};

export const getSchedulePostGroupApi = async id => {
  return apiGet(`groups/${id}/scheduled_statuses`, null, null);
};

export const getMediaGroupApi = async (id, params) => {
  return apiGet(`groups/${id}/media_attachments`, params, null);
};

export const CRUDActivityLogApi = async (type, id, params, data) => {
  if (type === 'get') return apiGet(`groups/${id}/activity_logs`, params, data);

  return apiPatch(`groups/${id}/activity_logs`, params, data);
};

export const CRUDReportCensorshipGroupApi = async (
  type,
  id,
  params,
  data,
  link
) => {
  if (type === 'post')
    return apiPost(`groups/${id}/status_alerts/${link}`, params, data);

  if (type === 'delete')
    return apiDelete(`groups/${id}/status_alerts`, params, data);

  return apiGet(`groups/${id}/status_alerts`, params, data);
};

export const createGroupApi = async (data: any) => {
  var formdata = new FormData();
  if (data.cover_image) {
    if (data.cover_image.file) {
      formdata.append('cover_image[file]', data.cover_image.file);
    }
    if (data.cover_image.id) {
      formdata.append('cover_image[id]', data.cover_image.id);
    }
    formdata.append('cover_image[show_url]', data.cover_image.show_url);
  }
  formdata.append('title', data.title);
  if (data.description) {
    formdata.append('description', data.description);
  }
  if (data.group_category_id) {
    formdata.append('category_id', data.group_category_id);
  } else formdata.append('category_id', '');
  if (data.is_private) {
    formdata.append('is_private', data.is_private);
  }
  formdata.append('is_visible', data.is_visible);
  if (data.tags) {
    formdata.append('tags', data.tags);
  }

  return apiPost('groups', null, formdata);
};

export const updateGroupApi = async (id: any, data: any) => {
  var formdata = new FormData();
  if (data.cover_image) {
    if (data.cover_image.file) {
      formdata.append('cover_image[file]', data.cover_image.file);
    }
    if (data.cover_image.id) {
      formdata.append('cover_image[id]', data.cover_image.id);
    }
    formdata.append('cover_image[show_url]', data.cover_image.show_url);
    formdata.append('cover_image[status]', data.cover_image.status);
  }
  if (data.title) {
    formdata.append('title', data.title);
  }
  formdata.append('description', data.description);
  if (data.group_category_id) {
    formdata.append('category_id', data.group_category_id);
  }
  if (data.tags) {
    formdata.append('tags', data.tags);
  }
  if (data.monitored_keywords) {
    data.monitored_keywords.forEach((el: any) => {
      formdata.append('monitored_keywords[]', el);
    });
  }
  if (data.inactivate_reason) {
    formdata.append(
      'inactivate_reason',
      data.inactivate_reason === 'active' ? '' : data.inactivate_reason
    );
  }
  if (data.username) {
    formdata.append('username', data.username);
  }
  if (data.is_private !== undefined && data.is_private !== null) {
    formdata.append('is_private', data.is_private);
  }
  if (data.is_visible !== undefined && data.is_visible !== null) {
    formdata.append('is_visible', data.is_visible);
  }
  if (data.post_approval_setting) {
    formdata.append('post_approval_setting', data.post_approval_setting);
  }
  if (data.post_permission_setting) {
    formdata.append('post_permission_setting', data.post_permission_setting);
  }

  return apiPatch(`groups/${id}`, null, formdata);
};

export const getListInvitationGroupApi = async (id, params) => {
  return apiGet(`groups/${id}/invitations`, params, null);
};

export const deleteInvitationGroupApi = async (id, invitationId) => {
  return apiDelete(`groups/${id}/invitations/${invitationId}`, null, null);
};

export const searchGroupApi = async (id, params) => {
  return apiGet(`timelines/group/${id}/search`, params, null);
};

export const getSuggestionGroup = async (params: any) => {
  return apiGet(`suggestions/group`, params, null);
};

export const blockAndRemovePostGroup = async (id: any, params: any) => {
  return apiPost(`groups/${id}/block_and_remove_status`, null, params);
};

export const getPinnedPostGroup = async (idGroup: any, params: any) => {
  return apiGet(`groups/${idGroup}/pins`, params, null);
};

export const removeGroupAdmin = async (idGroup: any, idAdmin: any) => {
  return apiPatch(
    `groups/${idGroup}/accounts/${idAdmin}/remove_admin`,
    null,
    null
  );
};

export const searchGroupActivityLogs = async (groupId, params: any) => {
  return apiGet(`groups/${groupId}/activity_logs`, params, null);
};

export const searchMemberJoinedGroup = async (idGroup: any, params: any) => {
  return apiGet(`groups/${idGroup}/join_requests`, params, null);
};
