import { Box, Grid } from '@mui/material';
import Hls from 'hls.js';
import Plyr from 'plyr';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import {
  miniPlayer,
  watchSaveTimeRun
} from 'src/store/action/socialWatchAction';
import './videojs.css';
import MediaDetail from 'src/pages/PageInfomation/MediaDetail';

interface Props {
  src?: any;
  previewMedia?: any;
  id?: String | any;
  typePage?: String | any;
  isClickAction?: boolean | any;
  visibleVideo?: boolean | any;
  idViewPort?: String | any;
  action?: any;
  isLiveStream?: boolean | any;
  processingLiveStream?: any;
  type?: String | any;
  autoPlay?: boolean | any;
  typeLive?: String | any;
  styleJs?: React.CSSProperties | any;
  styleVideo?: React.CSSProperties | any;
  handleClose?: any;
  open?: boolean; // Bắt sự kiện khi đóng dialog thì đẩy thời gian video lên
}

const VideoJs = (props: Props) => {
  const {
    src,
    previewMedia,
    id,
    typePage,
    isClickAction,
    visibleVideo,
    idViewPort,
    action,
    isLiveStream,
    processingLiveStream,
    type,
    autoPlay,
    typeLive,
    styleJs,
    styleVideo,
    handleClose,
    open
  } = props;
  const videoRef: any = React.useRef();
  const boxVideoRef: any = React.useRef();
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const liveStreamSocket = useSelector(
    (state: any) => state.socialLiveStreamReducer.liveStreamSocket
  );

  const numberRandom = Math.floor(Math.random() * 1000000000);
  const watchSelected = useSelector((state: any) => state.watchReducer);
  const watchSave = useSelector(
    (state: any) => state.watchReducer.watchSaveTimeRun
  );
  const location = useLocation();
  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const dataMiniPlayer = useSelector((state: any) => state.watchReducer);
  const itemPlyr: any = localStorage.getItem('plyr');
  const converedItemPlyr = JSON.parse(itemPlyr);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [openMediaPost, setOpenMediaPost] = React.useState(false);

  const optionNormal = {
    i18n: {
      play: 'Phát',
      pause: 'Dừng',
      volume: 'Âm lượng',
      mute: 'Tắt tiếng',
      enterFullscreen: 'Toàn màn hình',
      exitFullscreen: 'Thoát toàn màn hình',
      speed: 'Tốc độ',
      normal: 'Thường',
      quality: 'Chất lượng',
      loop: 'Phát lại'
    },
    speed: {
      selected: 1,
      options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
    }
  };

  const styleDivRoot = {
    top: '5px',
    left: '5px',
    zIndex: '10',
    display: 'flex'
  };

  const styleDivChildLive = {
    backgroundColor: '#c6213d',
    color: '#fff',
    padding: '4px',
    borderRadius: '5px',
    fontSize: '13px',
    fontWeight: '500',
    marginRight: '5px'
  };

  const styleDivChildSeen = {
    backgroundColor: '#5b5962',
    color: '#fff',
    padding: '4px',
    borderRadius: '5px',
    fontSize: '13px',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  const defaultOptions: any =
    type === 'moment'
      ? {
          controls: ['play', 'progress', 'mute', 'volume', 'fullscreen'],
          ...optionNormal
        }
      : isLiveStream || autoPlay
      ? {
          controls: [
            'play',
            'mute',
            'volume',
            'captions',
            'settings',
            'pip',
            'fullscreen'
          ],
          ...optionNormal
        }
      : optionNormal;

  const stopOtherVideos = (vd: any) => {
    const videos = document.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i++) {
      if (videos[i] !== vd && !videos[i].paused) {
        if (videos[i].id === 'miniVideo') {
          videos[i].play();
        } else {
          videos[i].pause();
        }
      }
    }
  };

  const isOtherVideoPlaying = (vd: any) => {
    const videos = document.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i++) {
      if (videos[i] !== vd && !videos[i].paused) {
        return true;
      }
    }
    return false;
  };

  const handleEnded = () => {
    if (match?.path === PATHS.WATCH) {
      const videos = document.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        if (videos[i] === videoRef?.current && videos[i + 1]) {
          videos[i + 1].scrollIntoView({ behavior: 'smooth' });
        }
      }

      setPlaying(false);
    }
  };
  const handleVideoLoaded = () => {
    if (videoRef.current) {
      if (
        watchSave &&
        Object.keys(watchSave).length !== 0 &&
        id === watchSave.idWatch
      ) {
        videoRef.current.currentTime = watchSave?.timePlay ?? 0;
      }
    }
  };

  const handleVisibilityChange = () => {
    // Kiểm tra nếu tab bị chuyển đi (trở thành "hidden" state)
    if (document.visibilityState === 'hidden') {
      // Kiểm tra nếu video đang phát, thì tạm dừng nó
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    } else {
      let videoDOM: any = document.getElementsByClassName('visibleVideo');
      if (videoDOM.length > 0) {
        if (
          query.get('v') ||
          query.get('postMediaId') ||
          query.get('mediaId')
        ) {
          videoDOM[videoDOM.length - 1]?.play();
          stopOtherVideos(videoDOM[videoDOM.length - 1]);
        } else {
          videoDOM[0]?.play();
          stopOtherVideos(videoDOM[0]);
        }
      }
    }
  };

  const resetVideo = () => {
    if (videoRef?.current) {
      videoRef?.current.load();
      videoRef?.current.play();
    }
  };

  const updateQualitySelector = hls => {
    const availableQualities = hls.levels.map(l => l.height);
    availableQualities.unshift(0);

    defaultOptions.quality = {
      default: 0,
      options: availableQualities,
      forced: true,

      onChange: newQuality => {
        if (newQuality === 0) {
          hls.currentLevel = -1;
        } else {
          hls.levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
              hls.currentLevel = levelIndex;
            }
          });
        }
      }
    };
    defaultOptions.i18n = {
      ...defaultOptions.i18n,
      qualityLabel: {
        0: 'Tự động'
      }
    };

    hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
      var span: any = document.querySelector(
        ".plyr__menu__container [data-plyr='quality'][value='0'] span"
      );
      if (span) {
        if (hls.autoLevelEnabled) {
          span.innerHTML = `Tự động (${hls.levels[data.level].height}p)`;
        } else {
          span.innerHTML = `Tự động`;
        }
      }
    });

    return defaultOptions;
  };

  React.useEffect(() => {
    if (src?.includes('m3u8')) {
      var video: any = document.querySelector(
        type === 'watch-selected'
          ? `#video${type}${numberRandom}${id}${typePage}`
          : `#video${numberRandom}${id}${typePage}`
      );
      let hls = new Hls();

      if (Hls.isSupported()) {
        hls.loadSource(src);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          const player = new Plyr(video, updateQualitySelector(hls));
          if (typePage === 'preview-video') {
            player.play();
          }
          if (isClickAction) {
            player.pause();
          }
        });
      }
    } else {
      const players = Plyr.setup('.js-player', defaultOptions);
    }

    if (type === 'watch-selected') {
      let listPlyr = {
        quality: 0,
        volume: 1,
        muted: false
      };
      localStorage.setItem('plyr', JSON.stringify(listPlyr));
    } else {
      let listPlyr = {
        quality: converedItemPlyr ? converedItemPlyr?.volume : 0,
        volume: converedItemPlyr ? converedItemPlyr?.volume : 1,
        muted: converedItemPlyr ? converedItemPlyr?.muted : true
      };
      localStorage.setItem('plyr', JSON.stringify(listPlyr));
    }

    if (src) {
      resetVideo();
    }

    return () => {};
  }, [src, isClickAction, match.path, type]);

  React.useEffect(() => {
    if (visibleVideo) {
      let tempVideo: any;
      let videoDOM: any = document.getElementsByClassName('visibleVideo');
      if (videoDOM.length > 0) {
        if (
          query.get('v') ||
          query.get('postMediaId') ||
          query.get('mediaId')
        ) {
          tempVideo = videoDOM.length - 1;
        } else {
          tempVideo = 0;
        }
        videoDOM[tempVideo]?.play();
        if (isOtherVideoPlaying(videoDOM[tempVideo])) {
          stopOtherVideos(videoDOM[tempVideo]);
        }
      }
    } else {
      let videoDOM: any = document.getElementsByClassName('visibleVideo');
      if (videoDOM?.length === 0) {
        const videos = document.getElementsByTagName('video');
        for (let i = 0; i < videos.length; i++) {
          if (!videos[i].paused && videos[i].id !== 'miniVideo') {
            videos[i].pause();
          }
        }
      }
    }
  }, [visibleVideo, JSON.stringify(query.get('v'))]);

  React.useEffect(() => {
    if (converedItemPlyr) {
      let mutedCurrent = converedItemPlyr.muted;
      let volumnCurrent = converedItemPlyr.volume;

      if (mutedCurrent !== null && mutedCurrent !== undefined) {
        const videos: any = document.getElementsByClassName('js-player');
        for (let i = 0; i < videos.length; i++) {
          videos[i].muted = mutedCurrent;
          videos[i].volume = volumnCurrent;
        }
      }
    }
  }, [JSON.stringify(converedItemPlyr)]);

  React.useEffect(()=>{
    if(open == false){
      dispatch(watchSaveTimeRun(id, videoRef.current.currentTime, open));
    }
 
  },[open])
  React.useEffect(() => {
    const handleClick = () => {
      if (query.get('v') === null && id && videoRef?.current?.currentTime) {
        const currentTime = videoRef.current.currentTime;
        dispatch(watchSaveTimeRun(id, currentTime));
        videoRef.current.currentTime = currentTime;
      }
      action && action();
    };

    if (idViewPort === id) {
      let listOverlay = document.getElementsByClassName('plyr__video-wrapper');
      let overlayClick = Array.from(listOverlay).find((el: any) =>
        el.children[0].id.includes(idViewPort)
      );
      if (overlayClick) {
        overlayClick.addEventListener('click', handleClick);
      }
    }

    return () => {
      if (idViewPort === id) {
        let listOverlay = document.getElementsByClassName(
          'plyr__video-wrapper'
        );
        let overlayClick = Array.from(listOverlay).find((el: any) =>
          el.children[0].id.includes(idViewPort)
        );
        if (overlayClick) {
          overlayClick.removeEventListener('click', handleClick);
        }
      }
    };
  }, [videoRef, idViewPort]);

  React.useEffect(() => {
    if (
      query.get('v') === null &&
      watchSave &&
      Object.keys(watchSave).length !== 0
    ) {
      if (
        watchSave &&
        Object.keys(watchSave).length !== 0 &&
        id === watchSave.idWatch
      ) {
        videoRef.current.currentTime = watchSave?.timePlay ?? 0;
      }
    } else if (dataMiniPlayer.showMiniPlayer === false) {
      if (videoRef.current) {
        videoRef.current.currentTime = watchSelected?.timePlay ?? 0;
      }
    }
  }, [JSON.stringify(query.get('v')), dataMiniPlayer.showMiniPlayer, id]);

  React.useEffect(() => {
    let videoCurrent: any = document.querySelector('.plyr__pip-button');

    const pipButton = !videoCurrent?.elements?.buttons?.pip
      ? videoCurrent?.elements?.buttons?.pip
      : videoRef.current?.plyr?.elements?.buttons?.pip;
    const playButton = videoCurrent?.elements?.buttons?.play
      ? videoCurrent?.elements?.buttons?.play[0]
      : null;

    const handleTogglePiP = () => {
      if (handleClose && typePage === 'preview-video') {
        handleClose();     
      }
      dispatch(
        miniPlayer({
          src: src,
          type: type,
          id: id,
          showMiniPlayer: true
        })
      );
      videoRef.current.pause();
    };

    const handleClickVideo = (event: any) => {
      videoRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    pipButton?.addEventListener('click', handleTogglePiP);
    playButton && playButton?.addEventListener('click', handleClickVideo);

    return () => {
      pipButton?.removeEventListener('click', handleTogglePiP);
      playButton && playButton?.removeEventListener('click', handleClickVideo);
      videoCurrent && videoCurrent.destroy && videoCurrent.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (dataMiniPlayer.showMiniPlayer && dataMiniPlayer.idVideo === id) {
      videoRef.current.pause();
    }
  }, [videoRef.current?.id]);

  React.useEffect(() => {
    // Đăng ký event listener cho sự kiện khi tab được chuyển
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // Hủy đăng ký event listener khi component bị unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const videoRect = videoRef.current.getBoundingClientRect();
        const isVideoFullyVisible =
          videoRect.top >= 0 &&
          videoRect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);

        if (!isVideoFullyVisible) {
          videoRef.current.pause();
        }
      }
    };

    // Add scroll event listener to check visibility on scroll
    window.addEventListener('scroll', handleScroll);

    // Initial check for visibility
    handleScroll();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (
      dataMiniPlayer.showMiniPlayer &&
      dataMiniPlayer.idVideo === id &&
      isFullScreen
    ) {
      document.exitFullscreen();
    }
  }, [dataMiniPlayer.showMiniPlayer]);

  React.useEffect(() => {
    window.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    });

    return window.removeEventListener('fullscreenchange', () => {
      setIsFullScreen(false);
    });
  }, []);

  const handleClickVideo = event => {
    const refPoster = document.querySelector('.plyr__poster');
    if (event.target === refPoster) {
      if (idViewPort === id) {
        event.stopPropagation();
        action && action();
      }
    } else {
      let targetElement;
      if (
        videoRef.current.paused &&
        query.get('v') &&
        !event.target.closest('.plyr__controls')
      ) {
        query.set('mediaId', id);
        history.push(`${location.pathname}?${query.toString()}`);
        setOpenMediaPost(true);
        return;
      }
      if (type === 'moment') {
        targetElement = document.getElementById(`moment-${id}`);
      } else {
        targetElement = document.getElementById(`video-${id}`);
      }
      event.stopPropagation();
      if (targetElement) {
        targetElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  };

  const path_hidden_controller_player = (): boolean => {
    switch (match?.path) {
      case PATHS.USER:
      case PATHS.COURSE_DETAIL:
      case PATHS.EVENT_DETAIL:
      case PATHS.GROW_DETAIL:
      case PATHS.GROUP_DETAIL:
      case PATHS.PAGE_DETAIL:
        return true;
      default:
        return false;
    }
  };

  const renderVideo = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <Grid
          ref={boxVideoRef}
          onClick={handleClickVideo}
          id="boxVideo"
          style={{
            width: '100%',
            height: '100%'
          }}
          sx={{
            '& .plyr__controls': {
              display:
                path_hidden_controller_player() &&
                !query.get('postMediaId') &&
                !query.get('mediaId')
                  ? 'none !important'
                  : null
            }
          }}
        >
          <video
            disablePictureInPicture
            poster={previewMedia}
            ref={videoRef}
            style={{
              ...styleJs,
              width: '100%',
              height: '100%',
              maxHeight:
                type === 'watch-selected'
                  ? '600px'
                  : type === 'preview-inBoxChat'
                  ? '200px'
                  : typePage === 'video_course' ||
                    typePage === 'preview_product'
                  ? '100%'
                  : typePage === 'preview_livehost'
                  ? '420px'
                  : typeLive
                  ? '100%'
                  : typePage === 'preview-watch'
                  ? '650px'
                  : '450px',
              position: 'relative',
              ...styleVideo
            }}
            id={
              type === 'watch-selected'
                ? `video${type}${numberRandom}${id}${typePage}`
                : `video${numberRandom}${id}${typePage}`
            }
            onLoadedMetadata={handleVideoLoaded}
            onEnded={handleEnded}
            className={
              visibleVideo ? `js-player visibleVideo ${id}` : `js-player ${id}`
            }
            controls
            autoPlay={autoPlay ?? false}
            preload="none"
          >
            <source src={src} />
          </video>
        </Grid>
        {dataMiniPlayer.showMiniPlayer && dataMiniPlayer.idVideo === id ? (
          <Box
            sx={{
              background: '#000000',
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              fontWeight: 600
            }}
            onClick={() => {
              dispatch(miniPlayer({ showMiniPlayer: false }));
            }}
          >
            Đang phát
          </Box>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {isLiveStream && processingLiveStream !== 'ended_live' ? (
        <div style={{ position: 'relative' }}>
          <div style={{ ...styleDivRoot, position: 'absolute' }}>
            <div style={styleDivChildLive}>TRỰC TIẾP</div>
            {liveStreamSocket?.eye ? (
              <div style={styleDivChildSeen}>
                <i
                  className="fa-light fa-eye"
                  style={{ color: '#fff', marginRight: '2px' }}
                ></i>
                <div>{liveStreamSocket?.eye}</div>
              </div>
            ) : null}
          </div>
          {renderVideo()}
        </div>
      ) : (
        renderVideo()
      )}
      {openMediaPost && (
        <MediaDetail
          openPreview={openMediaPost}
          setOpenPreview={setOpenMediaPost}
        />
      )}
    </>
  );
};

export default VideoJs;
