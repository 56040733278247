import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { getNotificationApi } from 'src/apis/commonSocialApi';
import { getNotificationsApi } from 'src/apis/socialNoti';
import logo from 'src/assets/images/LogoEmso.svg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import Header from 'src/components/Header/HeaderGlobal/HeaderGlobal';
import LogoAndSearch from 'src/components/Header/HeaderGlobal/LogoAndSearch';
import RightHeaderGlobal from 'src/components/Header/HeaderGlobal/RightHeaderGlobal';
import TabsGlobal from 'src/components/Tabs/TabsGlobal';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import {
  actionReload,
  resetActivitiesCache,
  saveCachePage
} from 'src/store/action/saveCacheAction';
import { resetActivities } from 'src/store/action/socialPostAction';
import DropdownArrow from './DropdownArrow';
import DropdownGlobalContainer from './DropdownGlobalContainer';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      appBar: {
        boxShadow:
          '-85px -49px 3px -100px rgba(0,0,0,0.1),0px 0px 20px -3px rgba(0,0,0,0.1) !important',
        height: '56px !important'
      },
      toolBar: {
        height: '56px',
        display: 'flex',
        minHeight: '0px !important',
        justifyContent: 'space-between'
      },
      complain: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 20px'
      },
      rightHeader: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'end'
      },
      logoSearch: {
        display: 'flex !important',
        alignItems: 'center'
      }
    }),
  { index: 1 }
);

function Index(props) {
  const { noti, setNumberTitle, isBannerHidden, setIsBannerHidden } = props;
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const matches = useMediaQuery('(max-width:1220px)');
  const [valueTab, setValueTab] = useState('home');
  const [defaultDataNoti, setDefaultDataNoti] = useState<any>([]);
  const [numberNotification, setNumberNotification] = useState<any>();
  const [openMenuArrow, setOpenMenuArrow] = React.useState(false);
  const classes = useStyles();
  let token: any = localStorage.getItem('token');

  const fetchNotification = async () => {
    let res: any = await getNotificationsApi();
    setNumberTitle && setNumberTitle(res.data.notifications.unseen);
    setNumberNotification(res.data.notifications.unseen);
  };

  const fetchDataNoti = async () => {
    let response: any = await getNotificationApi({ limit: 20 });
    if (response.status === 200) {
      setDefaultDataNoti(response.data);

      if (response.data.length) {
      } else {
      }
    }
  };

  useEffect(() => {
    fetchNotification();
    fetchDataNoti();
  }, [JSON.stringify(noti)]);

  useEffect(() => {
    if (match.path?.includes('/group/') || match.path?.includes('/groups/')) {
      setValueTab('group');
    } else if (match.path?.includes('/moment')) {
      setValueTab('media');
    } else if (match.path?.includes('/watch')) {
      setValueTab('watch');
    } else if (match.path === PATHS.HOME || match.path === PATHS.WELCOME) {
      setValueTab('home');
    } else if (
      match.path?.includes('/marketplace') ||
      match.path?.includes('/product') ||
      match.path?.includes('/order') ||
      match.path?.includes('/products')
    ) {
      setValueTab('store');
    } else {
      setValueTab('');
    }
  }, [match.path]);

  const handleClick = () => {
    setOpenMenuArrow(prev => !prev);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValueTab(newValue);
    switch (newValue) {
      case 'group':
        history.push(PATHS.GROUP);
        break;
      case 'home':
        handleLinkToHome();
        break;
      case 'media':
        history.push('/moment');
        break;
      case 'watch':
        history.push('/watch');
        break;
      case 'store':
        history.push(PATHS.MARKETPLACE);
        break;
    }
  };

  function smoothScrollToTop(): Promise<void> {
    return new Promise(resolve => {
      const scrollStep = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 0) {
          window.scrollTo(0, scrollPosition - scrollPosition / 8);
          requestAnimationFrame(scrollStep);
        } else {
          resolve(); // Resolve the promise when scroll reaches the top
        }
      };
      scrollStep();
    });
  }

  const handleLinkToHome = () => {
    if (match.path === PATHS.HOME) {
      dispatch(resetActivitiesCache());
      dispatch(resetActivities('activity'));
      smoothScrollToTop().then(() => {
        // history.push(PATHS.HOME);
        dispatch(actionReload(true));
        sessionStorage.clear();
      });
    } else {
      history.push(PATHS.HOME);
    }
  };

  const handleLinkToStore = () => {
    if (match.path.includes(PATHS.MARKETPLACE)) {
      if (match.path !== PATHS.MARKETPLACE) {
        history.push(PATHS.MARKETPLACE);
        window.scrollTo({ top: 0 });
      }
    } else {
      history.push(PATHS.MARKETPLACE);
      window.scrollTo({ top: 0 });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  function handleLinkToMe() {
    history.push(`/user/${meInfo.username ? meInfo.username : meInfo.id}`);
    if (match.path === PATHS.USER) {
      scrollToTop();
    }
  }

  function handleLogOut() {
    localStorage.removeItem('isFastLogin');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    history.push(`${PATHS.LOGIN}`);
    const channel = new BroadcastChannel('tokenChannel');
    channel.postMessage(null);
  }

  return (
    <div style={{ zIndex: 1102 }}>
      {meInfo?.suspended && match.path !== PATHS.HELPER ? (
        <AppBar
          classes={{ root: classes.appBar }}
          position="fixed"
          color="inherit"
          sx={{
            borderBottom: ' 1px solid #e5e7ec',
            padding: '0px !important'
          }}
        >
          <Toolbar
            classes={{ root: classes.toolBar }}
            sx={{
              backgroundColor: buttonColor.backgroundColor,
              paddingLeft: '8px !important',
              paddingRight: '8px !important'
            }}
          >
            <Box className={classes.complain}>
              <Button
                variant="contained"
                sx={{ backgroundColor: 'transparent', boxShadow: 'unset' }}
                onClick={handleLogOut}
              >
                <i className="fa-solid fa-xmark"></i>
              </Button>
              {/* <ButtonInherit
                label="Trợ giúp"
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff'
                }}
                action={() => history.push('/help')}
              /> */}
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <Header
          valueTab={valueTab}
          handleChangeTab={handleChangeTab}
          isBannerHidden={isBannerHidden}
          setIsBannerHidden={setIsBannerHidden}
        >
          {token ? (
            <Grid container>
              <Grid item xs={3} className={classes.logoSearch}>
                <LogoAndSearch
                  handleLinkToHome={() => {
                    if (token) {
                      handleLinkToHome();
                    }
                  }}
                  matches={matches}
                />
              </Grid>
              {!matches && (
                <Grid item xs={6}>
                  <TabsGlobal
                    valueTab={valueTab}
                    handleChangeTab={handleChangeTab}
                    handleLinkToHome={handleLinkToHome}
                    handleLinkToStore={handleLinkToStore}
                  />
                </Grid>
              )}
              <Grid item xs={!matches ? 3 : 9} className={classes.rightHeader}>
                <RightHeaderGlobal
                  onSelectExpand={() => handleClick()}
                  numberNotification={numberNotification}
                  setNumberNotification={setNumberNotification}
                  noti={noti}
                  defaultDataNoti={defaultDataNoti}
                  setNumberTitle={setNumberTitle}
                >
                  <DropdownGlobalContainer />
                </RightHeaderGlobal>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={3} className={classes.logoSearch}>
                <Avatar
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(PATHS.LOGIN)}
                  alt="logo"
                  src={logo}
                />
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                    marginLeft: '10px'
                  }}
                >
                  Trung tâm trợ giúp
                </Typography>
              </Grid>
              <Grid item xs={9} sx={{ textAlign: 'right' }}>
                <div
                  onClick={() => {
                    history.push(PATHS.LOGIN);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className="fa-solid fa-right-to-bracket"
                    style={{ fontSize: '30px', padding: '10px' }}
                  ></i>
                </div>
              </Grid>
            </Grid>
          )}

          {openMenuArrow && (
            <DropdownArrow
              handleLinkToProfile={handleLinkToMe}
              setOpen={setOpenMenuArrow}
            />
          )}
        </Header>
      )}
    </div>
  );
}

export default Index;
