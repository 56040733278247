import { cloneDeep } from 'lodash';
import { apiDelete, apiGet, apiPatch, apiPost } from './apis';

export const createPageApi = async (data: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  formdata.append('description', data.description);
  formdata.append('page_type', data?.page_type?.id);
  formdata.append('page_purpose', data.page_purpose?.id);
  for (var i = 0; i < data.page_category_ids.length; i++) {
    formdata.append('page_category_ids[]', data.page_category_ids[i]);
  }
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar?.show_url) {
      formdata.append('avatar[show_url]', data.avatar.show_url);
    }
    formdata.append('avatar[status]', data.avatar.status);
  }
  if (data.banner) {
    if (data.banner.file) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    if (data.banner.show_url) {
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }

  return apiPost('pages', null, formdata);
};

export const getPageCategoriesApi = (params: Object) => {
  return apiGet('page_categories', params, null);
};

export const getListPagesApi = (params: any) => {
  return apiGet('pages', params, null);
};

export const getDetailPageApi = async (pageId: any) => {
  return apiGet(`pages/${pageId}`, null, null);
};

export const updatePageApi = async (id: any, data: any) => {
  var formdata = new FormData();
  let params: any = null;
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar?.frame_id)
      formdata.append('avatar[frame_id]', data.avatar.frame_id ?? '');
    if (data.avatar?.show_url)
      formdata.append('avatar[show_url]', data.avatar.show_url);
    if (data.avatar?.status)
      formdata.append('avatar[status]', data.avatar.status);
  }
  if (data.banner) {
    if (data.banner.file) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    if (data.banner.show_url) {
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }
  if (data.title) {
    formdata.append('title', data.title);
  }
  if (data.username) {
    formdata.append('username', data.username);
  }
  if (data.description) {
    formdata.append('description', data.description);
  }
  if (data.page_category_ids) {
    for (var i = 0; i < data.page_category_ids.length; i++) {
      formdata.append('page_category_ids[]', data.page_category_ids[i]);
    }
  }
  if (data.email !== null && data.email !== undefined) {
    formdata.append('email', data.email);
  }
  if (data.phone_number !== null && data.phone_number !== undefined) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.website !== null && data.website !== undefined) {
    formdata.append('website', data.website);
  }
  if (data.impressum !== null && data.impressum !== undefined) {
    formdata.append('impressum', data.impressum);
  }
  if (data.products !== null && data.products !== undefined) {
    formdata.append('products', data.products);
  }
  if (data.policy_link !== null && data.policy_link !== undefined) {
    formdata.append('policy_link', data.policy_link);
  }
  if (
    data.addition_information !== null &&
    data.addition_information !== undefined
  ) {
    formdata.append('addition_information', data.addition_information);
  }
  if (data.button_key !== null && data.button_key !== undefined) {
    formdata.append('button_key', data.button_key);
  }
  if (data.button_value !== null && data.button_value !== undefined) {
    if (typeof data.button_value === 'object') {
      params = { button_value: data.button_value };
    } else {
      formdata.append('button_value', data.button_value);
    }
  }
  if (data.hours_type) {
    formdata.append('hours_type', data.hours_type);
  }
  if (data.temporary_hours_status) {
    formdata.append('temporary_hours_status', data.temporary_hours_status);
  }
  if (data.zip) {
    formdata.append('zip', data.zip);
  }

  if (data.address) {
    formdata.append('address', data.address);
  }
  if (data.location?.lat && data.location?.lng) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }

  if (data.province) formdata.append('province_id', data.province.id);
  if (data.district) formdata.append('district_id', data.district.id);
  if (data.ward) formdata.append('ward_id', data.ward.id);

  let _data: any = null;
  if (data.no_place) {
    let tempData: any = cloneDeep(_data);
    _data = {
      ...tempData,
      zip: null,
      province_id: null,
      district_id: null,
      ward_id: null,
      address: null,
      location: null
    };
  }
  if (data.schedule) {
    if (typeof data.schedule === 'object') {
      let tempData: any = cloneDeep(_data);
      _data = { ...tempData, schedule: data.schedule };
    }
  }

  if (_data) {
    return apiPatch(`pages/${id}`, params, {
      ...data,
      ..._data
    });
  }
  return apiPatch(`pages/${id}`, params, formdata);
};

export const deletePageApi = async (id: any) => {
  return apiDelete(`pages/${id}`, null, null);
};

export const lockPageApi = async (data: any) => {
  return apiPatch('lock_page', null, data);
};

export const unlockPageApi = async (data: any) => {
  return apiPatch('unlock_page', null, data);
};

export const getListInvitedPageApi = async (param: any) => {
  return apiGet('page_invitation_follows', param, null);
};

export const createInviteFollowsPage = async (idPage: any, data: any) => {
  return apiPost(`pages/${idPage}/invitation_follows`, null, data);
};

export const respondInviteFollowsPage = async (idPage: any, data: any) => {
  return apiPost(
    `pages/${idPage}/invitation_follows/invitation_follows_respond`,
    data,
    null
  );
};

export const respondInviteManagePage = async (idPage: any, data: any) => {
  return apiPost(`pages/${idPage}/invitations/invitations_respond`, data, null);
};

export const likeFollowPage = async (idPage: any, action: string) => {
  return apiPost(`pages/${idPage}/${action}`, null, null);
};

export const getPageManagementHistory = async (id: any, params: any) => {
  return apiGet(`pages/${id}/management_histories`, params, null);
};

export const inviteManagePage = async (idPage: any, params: any) => {
  return apiPost(`pages/${idPage}/invitations`, params, null);
};

export const getUserLikeFollowPage = async (
  idPage: any,
  params: any,
  action: any
) => {
  return apiGet(`pages/${idPage}/${action}`, params, null);
};

export const getListAdminPage = async (idPage: any) => {
  return apiGet(`pages/${idPage}/invitations`, null, null);
};

export const updateDeleteInviteManagePage = async (
  idPage: any,
  idUser: any,
  action: any
) => {
  if (action === 'delete') {
    return apiDelete(`pages/${idPage}/invitations/${idUser}`, null, null);
  }

  return apiPatch(`pages/${idPage}/invitations/${idUser}`, null, null);
};

export const getSettingContentPageApi = async (idPage: any) => {
  return apiGet(`pages/${idPage}/settings`, null, null);
};

export const updateSettingContentPage = async (idPage: any, params: any) => {
  return apiPatch(`pages/${idPage}/settings`, null, params);
};

export const getSettingNotification = async (idPage: any) => {
  return apiGet(`pages/${idPage}/setting_notifications`, null, null);
};

export const updateSettingNotification = async (idPage: any, params: any) => {
  return apiPatch(`pages/${idPage}/setting_notifications`, params, null);
};

export const blockUserPage = async (idPage: any, params: any, action: any) => {
  if (action === 'blocks') {
    return apiPost(`pages/${idPage}/blocks`, params, null);
  }

  return apiDelete(`pages/${idPage}/blocks`, params, null);
};

export const getListLikedFollowedPages = async (id: any, params: any) => {
  return apiGet(`accounts/${id}/page_likes`, params, null);
};

export const pageTimelineApi = async (id, params) => {
  return apiGet(`timelines/page/${id}`, params, null);
};

export const getMediaPage = async (pageId: string, params: any) => {
  return apiGet(`pages/${pageId}/media_attachments`, params, null);
};
export const getPlaylistPage = async (pageId: string, params: any) => {
  return apiGet(`pages/${pageId}/series`, params, null);
};
export const deletePlaylistPage = async (pageId: string, idSeries: any) => {
  return apiDelete(`pages/${pageId}/series/${idSeries}`, null, null);
};
export const deleteVideoPlaylistPage = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return apiPost(
    `pages/${pageId}/series/${idSeries}/remove_status`,
    null,
    data
  );
};
export const getListPlaylistPage = async (
  pageId: string,
  idSeries: any,
  params: any
) => {
  return apiGet(
    `pages/${pageId}/series/${idSeries}/statuses`,

    params,
    null
  );
};
export const addVideoToPlaylist = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return apiPost(`pages/${pageId}/series/${idSeries}/add_status`, null, data);
};
export const updatePlaylist = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return apiPatch(`pages/${pageId}/series/${idSeries}`, null, data);
};

export const getPopularPage = async (pageId: string) => {
  return apiGet(`pages/${pageId}/popular_video`, null, null);
};

export const getFeedbacksPage = async (pageId: string, params: any) => {
  return apiGet(`pages/${pageId}/feedbacks`, params, null);
};

export const createFeedbacksPage = async (pageId: string, params: any) => {
  return apiPost(`pages/${pageId}/feedbacks`, params, null);
};
export const createPlayList = async (pageId: string, data: any) => {
  return apiPost(`pages/${pageId}/series`, null, data);
};

export const updateFeedbacksPage = async (pageId: string, params: any) => {
  return apiPatch(`pages/${pageId}/feedbacks`, params, null);
};

export const deleteFeedbacksPage = async (
  pageId: string,
  idFeedback: string
) => {
  return apiDelete(
    `pages/${pageId}/feedbacks/${idFeedback}`,

    null,
    null
  );
};

export const addMonitoredKeywordPage = async (pageId: string, data: any) => {
  return apiPatch(`pages/${pageId}`, null, data);
};

export const validateUsernamePage = async (params: any) => {
  return apiGet(`pages_validate_username`, params, null);
};

export const changeRoleManagePageApi = async (
  pageId: any,
  userId: any,
  method: any,
  params: any
) => {
  if (method === 'DELETE') {
    return apiDelete(`pages/${pageId}/accounts/${userId}`, params, null);
  }

  return apiPatch(`pages/${pageId}/accounts/${userId}`, null, params);
};

export const searchPage = async (params: any) => {
  return apiGet(`search/page`, params, null);
};
export const searchPageApi = async (id, params) => {
  return apiGet(`timelines/page/${id}/search`, params, null);
};
export const getSuggestionPage = async (params: any) => {
  return apiGet(`suggestions/page`, params, null);
};

export const getPinnedPostPage = async (idPage: any, params: any) => {
  return apiGet(`pages/${idPage}/pins`, params, null);
};

export const getScheduledPostPage = async (idPage: any) => {
  return apiGet(`pages/${idPage}/scheduled_statuses`, null, null);
};

export const updateScheduledPostPage = async (
  idPage: any,
  idPost: any,
  data: any
) => {
  return apiPatch(
    `pages/${idPage}/scheduled_statuses?scheduled_status_id=${idPost}`,
    null,
    data
  );
};

export const publicScheduledPostPage = async (idPage: any, idPost: any) => {
  return apiPost(
    `pages/${idPage}/scheduled_statuses/${idPost}/public`,
    null,
    null
  );
};

export const getStatusAlertsApi = async (idPage: any, params: any) => {
  return apiGet(`pages/${idPage}/status_alerts`, params, null);
};

export const approveStatusAlertApi = async (idPage: any, params: any) => {
  return apiPost(`pages/${idPage}/status_alerts/approve`, params, null);
};

export const deleteSchedulePostPageApi = async (idPage: any, params: any) => {
  return apiDelete(
    `pages/${idPage}/scheduled_statuses`,

    null,
    params
  );
};

export const deleteStatusAlertApi = async (idPage: any, params: any) => {
  return apiDelete(
    `pages/${idPage}/status_alerts`,

    null,
    params
  );
};

export const getListPageLikesApi = async (idPage: any, params: any) => {
  return apiGet(
    `/accounts/${idPage}/page_likes/page_likes_watch`,

    params,
    null
  );
};
export const createPageAddress = async (params: any) => {
  return apiPost(`pages`, null, params);
};
export const createlinkGroupToPage = async (idPage: any, params: any) => {
  return apiPost(`pages/${idPage}/link_group`, null, params);
};
export const getGroupsLinkToPage = async (idPage: any, params: any) => {
  return apiGet(`pages/${idPage}/linked_groups`, params, null);
};
export const deleteLinkGroupToPage = async (idPage: any, params: any) => {
  return apiDelete(`pages/${idPage}/remove_linked_group`, null, params);
};
export const pageIdentityVerification = async (
  data: any,
  type: 'create' | 'update'
) => {
  const formData: any = new FormData();
  if (data.tax_code) {
    formData.append('tax_code', data.tax_code);
  }
  if (data.business_registration_id) {
    formData.append(
      'business_registration_id',
      data.business_registration_id.id
    );
  }
  if (data.company) {
    formData.append('company', data.company);
  }
  if (data.page_id && type === 'create') {
    formData.append('page_id', data.page_id);
  }
  if (type === 'create') {
    return apiPost('page_identity_verifications', null, formData);
  } else {
    return apiPatch(
      `page_identity_verifications/${data.page_id}`,
      null,
      formData
    );
  }
};

export const updateAccountHolderApi = async (idPage: any, params: any) => {
  return apiPatch(`pages/${idPage}/account_holder`, null, params);
};

export const createPageBloodDonations = async data => {
  return apiPost('page_blood_donations', null, data);
};

export const getListReportPageShop = async () => {
  return apiGet('report_categories', { shop: true }, null);
};

export const blockChatPage = async data => {
  return apiPost('block_chat_page', null, data);
};

export const unBlockChatPage = async data => {
  return apiPost('unblock_chat_page', null, data);
};