import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import { lineClamp } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  titlePost: {
    fontSize: '17px',
    fontWeight: '600 !important',
    width: '70%',
    wordBreak: 'break-word',
    ...(lineClamp(1) as any)
  }
}));

function NewEventCreated(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { post } = props;

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative'
      }}
    >
      <Card
        style={{
          width: '100%',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          backgroundColor:
            theme.palette.mode === 'light' ? '#f0f2f5' : '#3a3b3c'
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => {
          history.push(`/event/${post?.event.id}/about`);
        }}
      >
        <CardMedia
          component="img"
          height="245px"
          image={post?.event?.banner?.url || post?.event?.banner?.preview_url}
          alt="Không hiển thị ảnh"
          style={{
            objectFit: 'cover'
          }}
        />
        <CardContent
          sx={{
            marginTop: 0,
            paddingTop: '5px',
            paddingBottom: '5px !important',
            minHeight: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography
            sx={{ fontSize: '13px', fontWeight: 400, color: '#f0284a' }}
          >
            {!!post.event.start_time &&
              formatDateTimeToStringDateTime(post.event.start_time, true)}
          </Typography>
          <Typography className={classes.titlePost}>
            {post.event.title}
          </Typography>
          {!!post.event.address && (
            <Typography
              sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
            >
              {post.event.address}
            </Typography>
          )}

          <Typography
            sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
          >
            {post.event.users_interested_count} người quan tâm
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default NewEventCreated;
