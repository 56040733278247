export const EVENT_PREVIEW_INFO = 'EVENT_PREVIEW_INFO';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_ERROR = 'GET_EVENT_DETAIL_ERROR';
export const GET_EVENT_DETAIL_REQUEST = 'GET_EVENT_DETAIL_REQUEST';
export const UPDATE_STATUS_EVENT = 'UPDATE_STATUS_EVENT';

// export const SHARE_EVENT_SUCCESS = 'SHARE_EVENT_SUCCESS';
export const RESET_EVENT = 'RESET_EVENT';
export const LOADING_EVENT = 'LOADING_EVENT';
export const CHECK_UNMOUNT_DETAIL_EVENT = 'CHECK_UNMOUNT_DETAIL_EVENT';
export const STATUS_EVENT_UPDATE = 'STATUS_EVENT_UPDATE';
export const CHECK_EMPTY_LAYOUT_EVENT = 'CHECK_EMPTY_LAYOUT_EVENT';
