import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import ThemeContextProvider from 'src/context/ThemeContext';
import MainLayout from 'src/layouts/MainLayout';
import AboutUs from 'src/pages/AboutUs';
import InvitesChat from 'src/pages/Chat/InvitesLinkChat/InvitePageChat';
import Login from 'src/pages/Login/Login';
import Test from 'src/pages/Test';
import MainRoutes from './MainRoutes';
import NoMatch from './NoMatchRoutes';
export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={[PATHS.LOGIN, PATHS.LOGIN_RESET]}>
          <Login />
        </Route>
        <Route exact path={PATHS.CHAT_INVITES}>
          <InvitesChat />
        </Route>
        <Route
          exact
          path={[
            PATHS.HOME,
            PATHS.USER,
            PATHS.GROUP,
            PATHS.GROUP_ACTIVITIES_USER,
            PATHS.GROUP_CATEGORY,
            PATHS.GROUP_CREATE,
            PATHS.GROUP_DETAIL,
            PATHS.GROUP_DISCOVER,
            PATHS.GROUP_REQUEST,
            PATHS.GROUP_SEARCH,
            PATHS.PAGE_HASHTAG,
            PATHS.PAGE_SAVED,
            PATHS.PAGE_SAVED_DETAIL,
            PATHS.PAGE,
            PATHS.PAGE_LIKED,
            PATHS.PAGE_CREATE,
            PATHS.PAGE_DETAIL,
            PATHS.PAGE_DISCOVER,
            PATHS.PAGE_INVITES,
            PATHS.POST_DETAIL,
            PATHS.GROUP_JOINS,
            PATHS.GROUP_POST_DETAIL,
            PATHS.POST_IMAGE_DETAIL,
            PATHS.POST_VIDEO_DETAIL,
            PATHS.MOMENT,
            PATHS.MOMENT_TAG,
            PATHS.MOMENT_USER,
            PATHS.MOMENT_SEARCH,
            PATHS.WATCH,
            PATHS.WATCH_PAGE,
            PATHS.WATCH_SEARCH,
            PATHS.WATCH_SHOW_DETAIL,
            PATHS.FRIEND,
            PATHS.SETTINGS_USER,
            PATHS.DIARY_USER,

            PATHS.LIVESTREAM_HOME,
            PATHS.LIVESTREAM_FOLLOW,
            PATHS.LIVESTREAM_SELL,
            PATHS.LIVESTREAM_GAME,
            PATHS.LIVESTREAM_DETAIL,

            PATHS.EVENT,
            PATHS.EVENT_CREATE,
            PATHS.EVENT_MINE,
            PATHS.EVENT_DETAIL,
            PATHS.EVENT_SEARCH,
            PATHS.EVENT_CATEGORY,

            PATHS.FRIEND,
            PATHS.SEARCH_GLOBAL,
            PATHS.GROW,
            PATHS.GROW_CREATE,
            PATHS.GROW_MINE,
            PATHS.GROW_DETAIL,
            PATHS.GROW_CATEGORY,
            PATHS.GROW_SEARCH,
            PATHS.GROW_NOTI_IDENTITY,
            PATHS.NOTI,
            PATHS.RECRUIT,
            PATHS.RECRUIT_CREATE,
            PATHS.RECRUIT_MINE,
            PATHS.RECRUIT_DETAIL,
            PATHS.RECRUIT_CATEGORY,
            PATHS.RECRUIT_SEARCH,
            PATHS.RECRUIT_CREATECV,
            PATHS.PATH_DETAILRECRUIT,
            PATHS.COURSE,
            PATHS.COURSE_CREATE,
            PATHS.COURSE_MINE,
            PATHS.COURSE_DETAIL,
            PATHS.COURSE_CATEGORY,
            PATHS.COURSE_SEARCH,

            PATHS.MARKETPLACE,
            PATHS.PRODUCT_CREATE,
            PATHS.MARKETPLACE_MINE,
            PATHS.MARKETPLACE_SHOP,
            PATHS.PRODUCT_DETAIL,
            PATHS.ORDER_DETAIL,
            PATHS.MANAGE_ORDER_DETAIL,
            PATHS.MARKETPLACE_CATEGORY,
            PATHS.CATEGORY_GUIDE,
            PATHS.PRODUCT_SEARCH,
            PATHS.MARKETPLACE_TOP_PRODUCTS,
            PATHS.MARKETPLACE_FLASH_SALE,
            PATHS.MARKETPLACE_CAMPAIGN,
            PATHS.MARKETPLACE_BEST_SELLER,
            PATHS.MARKETPLACE_FULL_STAR,
            PATHS.MARKETPLACE_MALL,
            PATHS.MARKETPLACE_CART_RECOMMEND,
            PATHS.MARKETPLACE_EMSO_COMMIT,

            PATHS.PAYMENT,
            PATHS.PAYMENT_MONEY,
            PATHS.PAYMENT_HISTORY,
            PATHS.PAYMENT_COIN,
            PATHS.PAYMENT_BUY,
            PATHS.PAYMENT_VTV_PAY,
            PATHS.RECURRING_PAYMENTS,
            PATHS.REQUEST_PAYMENTS,
            PATHS.REFUND_PAYMENTS,
            PATHS.SETTINGS_PAYMENT,
            PATHS.HELP_PAYMENT,
            PATHS.PAYMENT_TERMS,
            PATHS.PAYMENT_BANK,

            PATHS.CHAT,
            PATHS.LIVESTREAM,
            PATHS.LIVESTREAM_CREATE,
            PATHS.LIVESTREAM_REALTIME,

            PATHS.MUSICSPACE,
            PATHS.MUSICSPACE_CHART,
            PATHS.MUSICSPACE_ALBUM,
            PATHS.MUSICSPACE_SEARCH,
            PATHS.MUSICSPACE_MY_PLAYLIST,
            PATHS.MUSICSPACE_UPLOADED,
            PATHS.MUSICSPACE_DETAIL_PLAYLIST,
            PATHS.MUSICSPACE_TRACK,
            PATHS.MUSICSPACE_DETAIL_SEARCH,
            PATHS.MUSICSPACE_NEW_RELEASE_SONG,
            PATHS.MUSICSPACE_NEW_CHART,
            PATHS.MUSICSPACE_NEW_RELEASE_ALBUM,
            PATHS.MUSICSPACE_CREATE_ALBUM,
            PATHS.MUSICSPACE_ARTIST,
            PATHS.MUSICSPACE_INVITE,
            PATHS.MUSICSPACE_DETAIL,
            PATHS.MUSICSPACE_DETAIL_ALBUM,
            PATHS.MUSICSPACE_UPDATE_ALBUM,
            PATHS.MUSICSPACE_RANK_SONG,
            PATHS.MUSICSPACE_MUSIC_AUTHOR_LIST,

            PATHS.ALBUM_CREATE,
            PATHS.ALBUM_UPDATE,
            PATHS.ALBUM_DETAIL,
            PATHS.HELPER,
            PATHS.HElPER_WRAP,
            PATHS.HELPER_ITEM,
            PATHS.TERMS_ITEM,
            PATHS.POLICY_DOC,
            PATHS.SALE,
            PATHS.WELCOME,
            PATHS.BLOOD_DONATION,

            PATHS.CELEBRATE,
            PATHS.CELEBRATENOTIFI,
            PATHS.CELEBRATEHIDE
          ]}
        >
          <ThemeContextProvider>
            <MainLayout>
              <MainRoutes />
            </MainLayout>
          </ThemeContextProvider>
        </Route>
        <Route path={[PATHS.ABOUTUS, PATHS.INVESTORS, PATHS.CONTACT]}>
          <AboutUs />
        </Route>
        <Route path={[PATHS.PATH_TEST]}>
          <Test />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
