import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/settingAction';
import * as actionsAlert from 'src/store/action/socialPostAction';
import * as types from 'src/constants/store/setting';
import { uploadMediaApi } from 'src/apis/commonSocialApi';
const typesSetting: any = types;

export const getColorImageSagas = function* ({ payload }) {
  try {
    const { params, setState, href, typeEndpoint } = payload;
    yield put(actions.checkSetLoading(true));
    let response = yield call(
      uploadMediaApi,
      params[0],
      setState,
      null,
      typeEndpoint
    );
    if (response.status === 200) {
      let { data } = response;
      yield put(actions.getColorImageSuccess(data, href));
      yield put(actions.checkSetLoading(false));
    }
  } catch (error: any) {
    yield put(actions.checkSetLoading(false));
    yield put(actions.getColorImageFailed());
    yield put(
      actionsAlert.notiSharePost({
        code: 500,
        msg: error.response?.data?.error
      })
    );
  }
};

export function* watchGetSettingAsync() {
  yield takeEvery(typesSetting.GET_COLOR_IMAGE_REQ, getColorImageSagas);
}
