export const KEYWORD_SEARCH = 'KEYWORD_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';

export const ANCHOREL_DIALOG_SEARCH = 'ANCHOREL_DIALOG_SEARCH';

export const SEARCH_FILTER_POST_USER = 'SEARCH_FILTER_POST_USER';

export const SEARCH_FILTER_POST_GROUP = 'SEARCH_FILTER_POST_GROUP';

export const SEARCH_FILTER_POST_PAGE = 'SEARCH_FILTER_POST_PAGE';
