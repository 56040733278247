import * as types from 'src/constants/store/eventSocial';
export const eventPreview = (data: Object) => ({
  type: types.EVENT_PREVIEW_INFO,
  payload: {
    data
  }
});

export const getEventDetailSuccess = (data: any) => ({
  type: types.GET_EVENT_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getEventDetailError = (data: any) => ({
  type: types.GET_EVENT_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getEventDetailRequest = (idEvent: any) => ({
  type: types.GET_EVENT_DETAIL_REQUEST,
  payload: {
    idEvent
  }
});

export const updateEventStatus = (data: any) => ({
  type: types.UPDATE_STATUS_EVENT,
  payload: {
    data
  }
});

export const resetEvent = () => ({
  type: types.RESET_EVENT
});

export const loadingEvent = (value: any) => ({
  type: types.LOADING_EVENT,
  payload: { value }
});

// export const shareEventSuccess = (data: object) => ({
//   type: types.SHARE_EVENT_SUCCESS,
//   payload: { data }
// });

export const statusEventUpdate = status => ({
  type: types.STATUS_EVENT_UPDATE,
  payload: status
});
export const checkEmptyLayout = (isCheck: boolean) => ({
  type: types.CHECK_EMPTY_LAYOUT_EVENT,
  payload: isCheck
});

export const checkUnmountDetailEvent = () => ({
  type: types.CHECK_UNMOUNT_DETAIL_EVENT
});
