import store from 'src/store/store';
import { v4 } from 'uuid';
import _, { debounce, result, update } from 'lodash';
import {
  eventReceiveNewMessage,
  receiveMessage,
  receiveActionBoxChat,
  subRoomSuccess,
  eventDeleteMessage,
  getInfoMeChatSuccess,
  getMesseageReq,
  changeAvatarPopupChat,
  changedEmoticon,
  changedTopic,
  changedRoleGroupChat,
  changedRolesAddMemberGroup,
  isSoundNotification,
  getListConversationReq,
  changeNamePopupChat,
  getStatusUserRealTime,
  eventMessageFromChatPage,
  getCreateVoteForm,
  updateMembersGroupChat,
  eventReceiveSubscriptionRoom,
  eventReceiveRoomChange
} from 'src/store/action/socialChatAction';
import { updateStatusFriend } from 'src/store/action/socialFriendsAction';
import Dexie from 'dexie';
import { urlWebSocket } from 'src/util/config';
import storeReducer from '../store/store';

const messageQueue: any[] = [];
const rocketChatToken = localStorage.getItem('token');

let ws;
let connected: Boolean = false;
let hasSoundNoti = false;

function dispatchSoundNotification() {
  const isPlay = true;
  store.dispatch(isSoundNotification(isPlay));
  hasSoundNoti = false; // Đánh dấu đã dispatch
}
const onmessage = e => {
  let response = JSON.parse(e.data);

  // Mỗi khi nhận được 'ping' thì gửi lên là 'pong' đế giữ kết nối với server

  if (response.msg === 'ping') {
    ws.send(JSON.stringify({ msg: 'pong' }));
    return;
  }
  if (response.msg === '111') {
  }
  // const rocketId = meInfo.info.id;

  const userId = localStorage.getItem('userId');

  if (
    response.collection === 'stream-notify-user' &&
    response.msg === 'changed'
  ) {
    if (
      response.fields.eventName.endsWith('rooms-changed') ||
      response.fields.eventName.endsWith('subscriptions-changed')
    ) {
      let updateConversation = _.cloneDeep(response.fields.args[1]);

      if (response.fields.eventName.endsWith('rooms-changed')) {
        store.dispatch(eventReceiveRoomChange(response.fields.args[1]));
        const userId = localStorage.getItem('userId');
        let listPopupChat =
          storeReducer.getState().socialChatReducer.listPopupChat;

        const boxChatActiveIndex = listPopupChat.findIndex(
          el => el.conversation.rid === updateConversation.rid
        );
        if (updateConversation?.lastMessage) {
          updateConversation.rid = updateConversation._id;
          if (updateConversation.t === 'cp') {
            if (
              boxChatActiveIndex === -1 &&
              updateConversation?.u?._id === userId
            ) {
              store.dispatch(eventReceiveNewMessage(updateConversation));

              store.dispatch(
                eventReceiveSubscriptionRoom(
                  updateConversation,
                  updateConversation?.lastMessage
                )
              );
            }
          } else if (
            updateConversation.t !== 'cp' &&
            !updateConversation.lastMessage.t
            // && updateConversation?.lastMessage?.type !== 'forward'
          ) {
            // updateConversation.displayName =
            //   updateConversation.t === 'd'
            //     ? updateConversation.lastMessage?.u?.name
            //     : updateConversation.fname;
            if (response.fields.args[0] === 'inserted') {
              updateConversation.unread = 1;
            }

            if (boxChatActiveIndex === -1) {
              // Rooms change dùng để cập nhật last message
                store.dispatch(eventReceiveNewMessage(updateConversation));
            }
          }
        }
        // if (updateConversation.t === 'c' || updateConversation.t === 'p') {
        //   const members = response.fields.args?.[2];

        //   const roomId = updateConversation?.rid || updateConversation._id;
        //   db.conversations.get(roomId).then(result => {
        //     const newMember = result.members.filter(item2 => {
        //       return members.findIndex(item1 => item1.id === item2._id) !== -1;
        //     });

        //     if (result.members.length !== members?.length) {
        //       db.conversations.update(roomId, {
        //         members,
        //         '_updatedAt.$date': updatedAt
        //       });

        //       // store.dispatch(
        //       //   updateMembersGroupChat(newMember, updateConversation._id)
        //       // );
        //     }
        //   });
        // }

        // updateConversation.avatar_media = updateConversation.avatarEtag;
      } else if (response.fields.eventName.endsWith('subscriptions-changed')) {
        store.dispatch(eventReceiveSubscriptionRoom(updateConversation, {}));
       
        if (updateConversation.t === 'p' || updateConversation.t === 'c') {
          if (updateConversation?.alert) {
            updateConversation.unread = 1;
          }
          store.dispatch(
            getMesseageReq(
              updateConversation,
              updateConversation?._updatedAt?.$date
            )
          );
        }
        if (
          (updateConversation.type === 'chat_page' ||
            updateConversation.t === 'cp') &&
          updateConversation?.u?._id === userId
        ) {
          store.dispatch(
            getMesseageReq(
              updateConversation,
              updateConversation?._updatedAt?.$date
            )
          );
        }
      }
    } else if (response.fields.eventName.endsWith('userData')) {
      // store.dispatch(
      //   getInfoMeChatSuccess({
      //     status: response.fields.args[0].diff?.status   // gọi api me để xem me đang online hay không
      //   })
      // );
    } else if (
      response.fields.eventName.endsWith('notification') &&
      !hasSoundNoti
    ) {
      hasSoundNoti = true;
      const debouncedDispatch = debounce(dispatchSoundNotification, 1000);
      debouncedDispatch();
    } else if (response.fields.eventName.endsWith('uiInteraction')) {
      store.dispatch(getCreateVoteForm(response.fields.args[0]));
    }
  } else if (response.collection === 'stream-room-messages') {
    const db: any = new Dexie(`chat_emso_db_${userId}`);
    db.version(1).stores({
      conversations: '++rid',
      conversations_pinned: '++rid'
    });

    const updatedAt = Date.now();
    if (response.fields.args[0] && response.fields.args[0].t) {
      let typeMessageEvent = response.fields.args[0]?.t;
      const messageType = response.fields.args[0];
      const roomId = messageType?.rid;
      switch (typeMessageEvent) {
        case 'room_changed_topic': {
          const topic = messageType.msg;
          store.dispatch(changedTopic(roomId, topic));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            topic: topic,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'room_e2e_enabled': {
          const emoticon = messageType.msg.icon;
          store.dispatch(changedEmoticon(roomId, emoticon));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            'customFields.icon': emoticon,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'room_changed_privacy': {
          const roomType = messageType.msg === 'private' ? 'p' : 'c';
          store.dispatch(changedRolesAddMemberGroup(roomId, roomType));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            t: roomType,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'room-set-read-only': {
          const readOnly = true;
          store.dispatch(changedRoleGroupChat(roomId, readOnly));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            ro: readOnly,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'room-removed-read-only': {
          const readOnly = false;
          store.dispatch(changedRoleGroupChat(roomId, readOnly));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            ro: readOnly,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'room_changed_avatar': {
          const avatarETag = messageType.msg || '';
          store.dispatch(changeAvatarPopupChat(roomId, avatarETag));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            avatarETag: avatarETag,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
        case 'r': {
          const roomName = messageType.msg;
          store.dispatch(changeNamePopupChat(roomId, roomName));
          // store.dispatch(getListConversationReq(20, Date.now()));
          db.conversations.update(roomId, {
            fname: roomName,
            '_updatedAt.$date': updatedAt
          });

          break;
        }
      }
    }
    const message = response.fields.args[0];
    const roomId = message?.rid;
    db.conversations.update(roomId, {
      lastMessage: message,
      '_updatedAt.$date': updatedAt
    });
    // store.dispatch(eventMessageFromChatPage(message));
    store.dispatch(receiveMessage(message));
  } else if (response.collection === 'stream-notify-room') {
    if (response.fields.eventName.endsWith('user-activity')) {
      // console.log('box chat action ==>>>>> ', response.fields.args[1]);
      const action = {
        fname: response.fields.args[0],
        action: response.fields.args[1][0],
        roomId: response.fields.eventName.split('/')[0],
        username: response.fields.args[2]
      };
      store.dispatch(receiveActionBoxChat(action));
    } else if (response.fields.eventName.endsWith('deleteMessage')) {
      const messageId = response.fields.args[0]._id;
      const roomId = response.fields.eventName.split('/')[0];
      store.dispatch(eventDeleteMessage(roomId, messageId));
    }
  } else if (response.collection === 'stream-user-presence') {
    // console.log('stream-user-presence', response.fields);
    const username = response.fields.args[0][0];
    const status = convertStatus(response.fields.args[0][1]);
    store.dispatch(updateStatusFriend(username, status));
    store.dispatch(getStatusUserRealTime(username, status));
  } else {
  }
};

const connectRocket = (rocketId: any) => {
  let listPopupChat = storeReducer.getState().socialChatReducer.listPopupChat;

  try {
    ws = new WebSocket(urlWebSocket);

    ws.onopen = () => {
      console.log('connect thành công');
      // connect
      if (ws.readyState === 1) {
        connected = true;
        ws.send(
          JSON.stringify({
            msg: 'connect',
            version: '1',
            support: ['1', 'pre2', 'pre1']
          })
        );

        // login
        ws.send(
          JSON.stringify({
            msg: 'method',
            id: '1',
            method: 'login',
            params: [
              {
                resume: rocketChatToken
              }
            ]
          })
        );

        // sub meteor
        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'meteor.loginServiceConfiguration',
            params: []
          })
        );

        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'meteor_autoupdate_clientVersions',
            params: []
          })
        );

        // sub Stream notify user
        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'stream-notify-user',
            params: [
              `${rocketId}/notification`,
              { useCollection: false, args: [] }
            ]
          })
        );

        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'stream-notify-user',
            params: [
              `${rocketId}/rooms-changed`,
              { useCollection: false, args: [] }
            ]
          })
        );

        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'stream-notify-user',
            params: [
              `${rocketId}/subscriptions-changed`,
              { useCollection: false, args: [] }
            ]
          })
        );
        // sub vote
        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'stream-notify-user',
            params: [
              `${rocketId}/uiInteraction`,
              { useCollection: false, args: [] }
            ]
          })
        );

        ws.send(
          JSON.stringify({
            msg: 'sub',
            id: v4(),
            name: 'stream-notify-user',
            params: [`${rocketId}/userData`, { useCollection: false, args: [] }]
          })
        );

        messageQueue.forEach(el => {
          ws.send(el);
        });
        if (listPopupChat.length > 0) {
          listPopupChat.forEach((element: any) => {
            subRoom(element.conversation.rid);
          });
        }
      }
    };
    ws.onclose = () => {
      connected = false;
      setTimeout(() => {
        let websocket: any = connectRocket(rocketId);
        websocket.onmessage = onmessage;
      }, 5000);
    };
    ws.onerror = error => {
      console.error('Lỗi kết nối WebSocket:', error);

      connected = false;
    };
    return ws;
  } catch (error) {
    console.log('lỗi  mât kết nối ws rồi', error);
  }
};

const subRoom = (roomId: string) => {
  if (connected) {
    // sub stream-room-messages

    ws.send(
      JSON.stringify({
        msg: 'sub',
        id: v4(),
        name: 'stream-room-messages',
        params: [
          roomId,
          {
            useCollection: false,
            args: []
          }
        ]
      })
    );

    // ws.send(
    //   JSON.stringify({
    //     msg: 'sub',
    //     id: v4(),
    //     name: 'stream-notify-logged',
    //     params: [
    //       'updateAvatar',
    //       {
    //         useCollection: false,
    //         args: []
    //       }
    //     ]
    //   })
    // );

    // sub stream-notify-room / deleteMessage
    ws.send(
      JSON.stringify({
        msg: 'sub',
        id: v4(),
        name: 'stream-notify-room',
        params: [
          `${roomId}/deleteMessage`,
          {
            useCollection: false,
            args: []
          }
        ]
      })
    );

    let subId = v4();

    // sub stream-notify-room / user-activity (for event typing)
    ws.send(
      JSON.stringify({
        msg: 'sub',
        id: subId,
        name: 'stream-notify-room',
        params: [
          `${roomId}/user-activity`,

          {
            useCollection: false,
            args: []
          }
        ]
      })
    );
    store.dispatch(subRoomSuccess(roomId, subId));
  }
};

const actionTyping = (
  roomId: string,
  displayName: string,
  event: any,
  username: string
) => {
  if (connected) {
    ws.send(
      JSON.stringify({
        msg: 'method',
        id: v4(),
        method: 'stream-notify-room',
        params: [`${roomId}/user-activity`, displayName, [event], username, {}]
      })
    );
  }
};

const subStatusUser = (ids: string[]) => {
  const message = JSON.stringify({
    msg: 'sub',
    id: v4(),
    name: 'stream-user-presence',
    params: [
      '',
      {
        added: ids
      }
    ]
  });

  if (connected) {
    ws.send(message);
  } else {
    messageQueue.push(message);
  }
};

const changeStatusActive = (status: string) => {
  if (connected) {
    ws.send(
      JSON.stringify({
        msg: 'method',
        id: '17',
        method: 'setUserStatus',
        params: [status, '']
      })
    );
  }
};

const unSubRoom = (subId: string) => {
  if (connected) {
    ws.send(JSON.stringify({ msg: 'unsub', id: subId }));
  }
};

// handle  logout

const logoutWebsocket = () => {
  if (connected) {
    ws.send(
      JSON.stringify({ msg: 'method', id: '999', method: 'logout', params: [] })
    );
  }
};

// get user status

const subUserStatus = (userId: string) => {
  if (connected) {
    ws.send(
      JSON.stringify({
        msg: 'sub',
        id: '888',
        name: 'stream-user-presence',
        params: ['', { add: [userId] }]
      })
    );
  }
};

// ==============================================================================
const convertStatus = id => {
  switch (id) {
    case 0: {
      return 'offline';
    }

    case 1: {
      return 'online';
    }

    case 2: {
      return 'away';
    }

    case 3: {
      return 'busy';
    }

    default: {
      return 'offline';
    }
  }
};

export {
  connectRocket,
  subRoom,
  unSubRoom,
  actionTyping,
  subStatusUser,
  changeStatusActive,
  onmessage,
  logoutWebsocket,
  subUserStatus
};
