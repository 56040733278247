import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

const CandidateInformation = (props: any) => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    workingForm,
    workExperience,
    level,
    gender,
    setCheckStep,
    setNotificationLeave
  } = props;
  const [verify, setVerify] = React.useState<any>(false);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        formik.values.gender &&
        formik.values.work_experience &&
        formik.values.working_form &&
        formik.values.level
      ) {
        setCheckStep(prev => ({ ...prev, step2: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step2: false }));
      }
    }
  }, [JSON.stringify(formik.values)]);

  let fieldUpdate: any = [
    {
      field_type: 'autocomplete',
      name: 'level',
      options: level,
      multiple: false,
      placeholder: '- Chọn cấp bậc -',
      descriptionLabel: 'Bắt buộc*',
      label: 'Cấp bậc'
    },
    {
      field_type: 'autocomplete',
      name: 'work_experience',
      options: workExperience,
      multiple: false,
      placeholder: '- Chọn kinh nghiệm',
      descriptionLabel: 'Bắt buộc*',
      label: 'Kinh nghiệm làm việc'
    },
    {
      field_type: 'autocomplete',
      name: 'working_form',
      options: workingForm,
      multiple: false,
      placeholder: '- Chọn hình thức làm việc -',
      descriptionLabel: 'Bắt buộc*',
      label: 'Hình thức làm việc'
    },
    {
      field_type: 'autocomplete',
      name: 'gender',
      options: gender,
      multiple: false,
      placeholder: '- Chọn giới tính -',
      descriptionLabel: 'Bắt buộc*',
      label: 'Giới tính'
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default CandidateInformation;
