import * as types from 'src/constants/store/marketplaceSocial';
const _ = require('lodash');

const initialState = {
  info: {},
  rolePreviewEdit: { role: '' },
  error: false,
  errorMessage: '',
  tempInteract: [],
  infoPreview: { basic: [], sale: {} },
  detailCart: {
    countChildProduct: 0,
    listCart: [],
    variantChecked: []
  },
  detailOrder: {},
  isLoadingDetailOrder: false,
  productOwner: null,
  isLoading: false,
  paymentMethod: 'cod',
  statusLoginFinanceMarket: false,
  balance: null,
  myPageMarket: {
    listPage: [],
    hasMore: true,
    isLoading: false
  }
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCT_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, infoPreview: newState };
    }

    case types.GET_PRODUCT_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: data,
        error: false,
        errorMessage: '',
        infoPreview: { basic: [], sale: {} }
      };
    }

    case types.GET_ORDER_DETAIL_REQUEST: {
      return {
        ...state,
        isLoadingDetailOrder: true
      };
    }

    case types.GET_ORDER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        detailOrder: data,
        isLoadingDetailOrder: false
      };
    }

    case types.RESET_PRODUCT: {
      return {
        ...state,
        info: {
          id: '',
          title: '',
          product_category_id: null,
          description: '',
          brand: null,
          product_video: null,
          product_images: [],
          product_options_attributes: [],
          product_variants_attributes: [],
          page_id: null,
          weight_package: null,
          length_package: null,
          width_package: null,
          height_package: null,
          product_attribute_informations_attributes: []
        },
        error: false,
        rolePreviewEdit: { role: '' },
        infoPreview: { basic: [], sale: {} }
      };
    }

    case types.GET_CART_DETAIL: {
      let { data } = action.payload;

      let newState = _.cloneDeep(data);
      const newListCart = newState?.listCart?.map(el => ({
        ...el,
        shop_voucher: null
      }));

      return { ...state, detailCart: { ...newState, listCart: newListCart } };
    }

    case types.GET_COUNT_CHILD_PRODUCT: {
      const { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          countChildProduct: newState
        }
      };
    }

    case types.GET_LIST_CART: {
      const { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          listCart: [...state.detailCart.listCart, ...newState]
        }
      };
    }

    case types.REPLACE_LIST_CART: {
      const { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          listCart: [...newState]
        }
      };
    }

    case types.UPDATE_LIST_CART: {
      const { data } = action.payload;
      let newListCart = _.cloneDeep(state.detailCart.listCart);
      const index = newListCart?.findIndex(
        (el: any) => el.page_id === data[0].page_id
      );
      if (index !== -1) {
        newListCart[index] = {
          ...data[0],
          shop_voucher: newListCart[index]?.shop_voucher
        };
      }
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          listCart: newListCart
        }
      };
    }

    case types.UPDATE_VARIANT_CHECKED: {
      const { data } = action.payload;
      const { new_product_variant_id, old_product_variant_id } = data;
      const newState: Array<string> = [...state.detailCart.variantChecked];
      const index = newState.findIndex(el => el === old_product_variant_id);
      if (index !== -1) newState[index] = new_product_variant_id;
      else newState.push(new_product_variant_id);
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          variantChecked: newState
        }
      };
    }

    case types.UPDATE_LIST_VOUCHER: {
      const { data } = action.payload;
      const shopVoucher = data?.voucher;

      let newListCart = _.cloneDeep(state.detailCart.listCart);
      const index = newListCart?.findIndex(
        (el: any) => el.page_id === shopVoucher.page?.id
      );
      if (index !== -1) {
        newListCart[index].shop_voucher = shopVoucher;
      }
      return {
        ...state,
        detailCart: {
          ...state.detailCart,
          listCart: newListCart
        }
      };
    }

    case types.GET_PRODUCT_OWNER: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, productOwner: newState };
    }

    case types.GET_PRODUCT_DETAIL_ERROR: {
      const { data } = action.payload;
      return {
        ...state,
        error: true,
        errorMessage: data,
        infoPreview: { basic: [], sale: {} }
      };
    }

    case types.GET_ORDER_DETAIL_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: '',
        isLoadingDetailOrder: false
      };
    }
    case types.LOADING_PRODUCT: {
      return { ...state, isLoading: action.payload.value };
    }
    case types.PAYMENT_METHOD: {
      return { ...state, paymentMethod: action.payload.value };
    }

    case types.LOGIN_FINANCE_MARKET: {
      const type = action.payload;
      return {
        ...state,
        statusLoginFinanceMarket: type === 'success' ? true : false
      };
    }

    case types.LOADING_LIST_MY_PAGE_MARKET: {
      const { value } = action.payload;
      return {
        ...state,
        myPageMarket: {
          ...state.myPageMarket,
          isLoading: value
        }
      };
    }

    case types.GET_LIST_MY_PAGE_MARKET_SUCCESS: {
      const {
        data: { listPage, hasMore, isLoading }
      } = action.payload;

      return {
        ...state,
        myPageMarket: {
          listPage: [...state.myPageMarket.listPage, ...listPage],
          hasMore: hasMore,
          isLoading: isLoading
        }
      };
    }

    case types.GET_BALANCE_SUCCESS: {
      const { data } = action.payload;
      return { ...state, balance: data };
    }

    default:
      return state;
  }
};
