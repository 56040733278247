import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ListButton from 'src/components/List/ListButton';
import { PATHS } from 'src/constants/paths';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '310px',
      height: '100%',
      backgroundColor: theme.palette.background.primary,
      position: 'fixed',
      boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
    },
    title: {
      margin: '20px 14px 8px 10px !important',
      fontSize: '24px !important'
    }
  })
);

const downLoadFile = async (url: string) => {
  try {
    let fileName: string = 'Chính sách về E-XU';

    const response = await fetch(url);
    const blob = await response.blob(); //object container file docx
    const aTag = document.createElement('a');
    const objectURL = URL.createObjectURL(blob); //save url temporary

    aTag.href = objectURL;
    aTag.setAttribute('download', fileName);

    document.body.appendChild(aTag);
    aTag.click(); //download
    document.body.removeChild(aTag); //remove element a

    URL.revokeObjectURL(objectURL); //release URL temporary (release memories)
  } catch (error) {}
};

const PaymentLeft = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');

  const listMenuOptions = [
    {
      id: 'payment',
      title: 'Trang chủ',
      styleTitle: { fontWeight: 600 },
      icon: <i className="fa-sharp fa-solid fa-house-chimney"></i>,
      action: () => {
        // history.push(PATHS.PAYMENT);
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px',
        padding: 0
      },
      position: 'top',
      accordion: true,
      itemChildren: [
        {
          id: 'coin',
          title: 'E-XU',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-gift-card"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(PATHS.PAYMENT);
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'money',
          title: 'Tiền',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-gift-card"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(PATHS.PAYMENT_MONEY);
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
      ]
    },
    {
      id: 'payment_history',
      title: 'Yêu cầu thanh toán',
      styleTitle: { fontWeight: 600 },
      styleButton: { marginTop: '2px' },
      icon: <i className="fa-solid fa-buildings"></i>,
      action: () => {
        history.push(PATHS.PAYMENT_HISTORY);
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px'
      },
      position: 'top'
    },

    // {
    //   id: 'refund_payment',
    //   title: 'Yêu cầu hoàn tiền',
    //   styleTitle: { fontWeight: 600 },
    //   styleButton: { marginTop: '2px' },
    //   icon: <i className="fa-solid fa-users-gear"></i>,
    //   action: () => {
    //     history.push(PATHS.REFUND_PAYMENTS);
    //   },
    //   style: {
    //     height: 36,
    //     width: 36,
    //     fontSize: 18,
    //     marginRight: '0px'
    //   },
    //   position: 'top'
    // },
    {
      id: 'payment_coin',
      title: 'Nạp E-XU',
      styleTitle: { fontWeight: 600 },
      styleButton: { marginTop: '2px' },
      icon: <i className="fa-solid fa-file-invoice-dollar"></i>,
      action: () => {
        history.push(PATHS.PAYMENT_COIN);
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px'
      },
      position: 'top'
    },
    {
      id: 'account_bank',
      title: 'Tài khoản ngân hàng',
      styleTitle: { fontWeight: 600 },
      styleButton: { marginTop: '2px' },
      icon: <i className="fa-solid fa-building-columns"></i>,
      action: () => {
        history.push(PATHS.PAYMENT_BANK);
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px'
      },
      position: 'top'
    },
    {
      id: 'payment_terms',
      title: 'Điều khoản & quyền riêng tư',
      styleTitle: { fontWeight: 600 },
      styleButton: { marginTop: '2px' },
      icon: <i className="fa-solid fa-users-gear"></i>,
      action: () => {
        history.push('/help');
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px'
      },
      position: 'top'
    },
    {
      id: 'terms_ecoin',
      title: 'Chính sách về E-XU',
      styleTitle: { fontWeight: 600 },
      styleButton: { marginTop: '2px' },
      icon: <i className="fa-solid fa-file"></i>,
      action: () => {
        downLoadFile(
          'https://s3-hn-2.cloud.cmctelecom.vn/sn-web/helper/Ch%C3%ADnh%20s%C3%A1ch%20v%E1%BB%81%20E-XU.docx'
        );
      },
      style: {
        height: 36,
        width: 36,
        fontSize: 18,
        marginRight: '0px'
      },
      position: 'top'
    }
  ];

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.PAYMENT:
        setSelectedIndex('coin');
        break;
      case PATHS.PAYMENT_MONEY:
        setSelectedIndex('money');
        break;
      case PATHS.PAYMENT_HISTORY:
        setSelectedIndex('payment_history');
        break;
      case PATHS.PAYMENT_COIN:
        setSelectedIndex('payment_coin');
        break;
      case PATHS.RECURRING_PAYMENTS:
        setSelectedIndex('recurring_payment');
        break;
      case PATHS.REQUEST_PAYMENTS:
        setSelectedIndex('request_payment');
        break;
      case PATHS.REFUND_PAYMENTS:
        setSelectedIndex('refund_payment');
        break;
      case PATHS.SETTINGS_PAYMENT:
        setSelectedIndex('setting_payment');
        break;
      case PATHS.HELP_PAYMENT:
        setSelectedIndex('help_payment');
        break;
      case PATHS.PAYMENT_TERMS:
        setSelectedIndex('payment_terms');
        break;
      case PATHS.PAYMENT_BANK:
        setSelectedIndex('account_bank');
    }
  }, [match.path]);

  const renderHeader = () => {
    return (
      <Typography className={classes.title} fontWeight={700} variant="h5">
        Đơn đặt hàng và thanh toán
      </Typography>
    );
  };

  const renderBody = () => {
    switch (match.path) {
      case PATHS.PAYMENT:
      case PATHS.PAYMENT_MONEY:
      case PATHS.PAYMENT_HISTORY:
      case PATHS.RECURRING_PAYMENTS:
      case PATHS.REQUEST_PAYMENTS:
      case PATHS.REFUND_PAYMENTS:
      case PATHS.SETTINGS_PAYMENT:
      case PATHS.HELP_PAYMENT:
      case PATHS.PAYMENT_TERMS:
      case PATHS.PAYMENT_COIN:
      case PATHS.PAYMENT_BANK:
        return (
          <ListButton
            item={listMenuOptions.filter(el => el.position === 'top')}
            selectedItem={selectedIndex}
            width={'310px'}
            styleAccordionDetail={{ padding: '3px' }}
            colorIcon={true}
            typeAction={'action'}
            stylePadding={{
              padding: '0 !important'
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <div>{renderHeader()}</div>
      <div>{renderBody()}</div>
    </div>
  );
};
export default PaymentLeft;
