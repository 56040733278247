import { List, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import CommentBox from 'src/components/CommentBox/Index';
import Comment from './Comment';
import { useSelector } from 'react-redux';
import { createUpdatePostApi, getPostDetailApi } from 'src/apis/socialPost.api';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      child: {
        marginLeft: 5
      },

      loadMoreReply: {
        '&:hover': {
          textDecoration: 'underline'
        },
        cursor: 'pointer',
        marginLeft: 40,
        fontWeight: '500 !important',
        fontSize: '15px !important',
        color: '#65676b',
        padding: '5px 0px'
      }
    }),
  { index: 1 }
);

const CommentChild = React.memo((props: any) => {
  const {
    info,
    handleGetComment,
    isShowBoxChat,
    updateComment,
    gifSelected,
    setGifSelected,
    stickySelected,
    setStickySelected,
    post,
    type,
    commentParentRef,
    setCommentInfo,
    respondent,
    setRespondent,
    replyActionComment,
    setReplyActionComment
  } = props;
  const classes = useStyles();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const roleInteract: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const [listCommentChild, setListCommentChild] = React.useState<any>([]);
  const [isShowBoxChatChild, setIsShowBoxChatChild] = React.useState(false);
  let commentItems = listCommentChild.map((item, index) => {
    return item ? (
      <Comment
        type={type}
        post={post}
        key={index}
        setListComment={setListCommentChild}
        createComment={updateComment}
        style={{ marginLeft: 4 }}
        info={item}
        gifSelected={gifSelected}
        setGifSelected={setGifSelected}
        itemChildren={item}
        setIsShowBoxChatChild={setIsShowBoxChatChild}
        setCommentInfo={setCommentInfo}
        commentChildRef={commentParentRef}
        setRespondent={setRespondent}
      />
    ) : null;
  });

  const getComments = () => {
    handleGetComment(info.id, null, setListCommentChild);
  };
  const handleTextContent = textContent => {
    const regex = /@\[([^\]]+)\]\((\d+)\)/g;
    let contentComment = textContent.replace(regex, '[$2]');
    return contentComment;
  };

  const createComment = async (data: any) => {
    let _files = data?.files ?? [];
    let date = new Date();
    let preResponse: any = {
      id: Math.random(),
      uuid: Math.random(),
      page_owner: roleInteract?.page_owner_id ? roleInteract : null,
      account: meInfo,
      content: handleTextContent(data.textInputCommentBox),
      in_reply_to_parent_id: data.in_reply_to_id,
      created_at: date,
      status_tags: data.status_tags,
      pre_comment: true,
      media_attachments:
        data?.files?.length > 0
          ? [
              {
                preview_url: data.preview,
                ...data?.file?.[0]
              }
            ]
          : [],
      card:
        data?.display_gift !== '' || data?.display_sticky !== ''
          ? {
              description: data?.display_sticky !== '' ? 'sticky' : '',
              provider_name: data?.display_gift !== '' ? 'GIPHY' : undefined,
              title: '',
              url: data?.display_gift
            }
          : {}
    };
    if (
      preResponse?.content?.length > 0 ||
      data?.files?.length > 0 ||
      data?.display_gift !== '' ||
      data?.display_sticky !== ''
    ) {
      setListCommentChild(prev => [...prev, preResponse]);
    }

    let _data: any = {
      status: handleTextContent(data.textInputCommentBox),
      in_reply_to_id: data.in_reply_to_id,
      sensitive: false,
      media_ids: _files,
      spoiler_text: '',
      visibility: 'public',
      poll: null,
      extra_body:
        gifSelected || stickySelected
          ? {
              title: '',
              description: stickySelected ? 'sticky' : '',
              url: gifSelected ?? stickySelected?.url ?? stickySelected?.link
            }
          : null,
      tags: data.status_tags?.map((el: any) => ({
        entity_id: el.id,
        entity_type: el.type,
        position: el.position,
        name: el.name
      })),
      page_owner_id: roleInteract.page_owner_id
        ? roleInteract.page_owner_id
        : null
    };

    let listIdMedia = await uploadMediaSaga(_files, 'comment');

    let response: any = await createUpdatePostApi(
      {
        ..._data,
        media_ids: listIdMedia
      },
      null
    );

    if (response.status === 200) {
      let res: any = await getPostDetailApi(
        response?.data?.in_reply_to_parent_id
      );
      if (res.status === 200) {
        setCommentInfo(prev => ({
          ...prev,
          replies_total: res.data.replies_total
        }));
      }
      setListCommentChild(prev =>
        prev.map(cmt => (cmt?.id === preResponse?.id ? response.data : cmt))
      );
    }
  };

  const handleOnSubmitForm = e => {
    e['in_reply_to_id'] = info.id;
    createComment(e);
  };

  return (
    <div className={classes.child}>
      {listCommentChild.length ? <List>{commentItems}</List> : null}
      {info.replies_count - listCommentChild.length > 0 ? (
        <Typography onClick={() => getComments()}>
          <i
            style={{ transform: 'rotate(175deg)' }}
            className="fas fa-reply"
          ></i>
          &nbsp; Xem {info.replies_count - listCommentChild.length} phản hồi
        </Typography>
      ) : null}
      {isShowBoxChat || isShowBoxChatChild ? (
        <CommentBox
          type={type}
          focus={true}
          ref={commentParentRef}
          post={info}
          onSubmitForm={e => handleOnSubmitForm(e)}
          accept={'image/jpeg,image/png'}
          style={{ paddingLeft: 32 }}
          avatarStyle={{ width: 32, height: 32 }}
          avatarUrl={meInfo?.avatar_media?.url ?? ''}
          gifSelected={gifSelected}
          setGifSelected={setGifSelected}
          stickySelected={stickySelected}
          setStickySelected={setStickySelected}
          defaultValue={{
            text: respondent.name
              ? `@[${respondent?.name}](${
                  respondent?.entity_id ?? respondent?.id
                })`
              : '',
            files: [],
            extra_body: [],
            status_tags: [respondent],
            info: respondent?.info
          }}
          replyActionComment={replyActionComment}
          setReplyActionComment={setReplyActionComment}
        />
      ) : null}
    </div>
  );
});

export default CommentChild;
