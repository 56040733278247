import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  createDirectMessage,
  getSubscriptionRoom,
  getListGroupsChat,
  searchConversation,
  searchConversationGroup,
  sendMessageApi
} from 'src/apis/socialChat.apis';
import ButtonCustom from 'src/components/Button/Button';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import Loading from 'src/components/Loading/Loading';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor } from 'src/constants/styles';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import {
  eventMessageFromChatPage,
  receiveMessage,
  selectedUserBoxChat
} from 'src/store/action/socialChatAction';
import { urlRocketChat } from 'src/util/config';
import { subRoom } from 'src/socket/RocketChatSubscriptions';
import { handleTimeShow } from 'src/helpers/string';
import TextCollapse from 'src/components/TextCollapse/Index';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        textAlign: 'center'
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '20px 0px 10px 0px'
      },
      inputSearch: {
        borderRadius: '40px !important',
        backgroundColor: '#f0f2f5',
        width: '100%',
        marginTop: '14px !important',
        '&:hover': {
          cursor: 'text'
        }
      },
      searchIcon: {
        marginRight: 10
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '40px !important',
        width: '100%'
      },
      notchedOutline: {
        border: 'none !important',
        borderRadius: '40px'
      },
      multilineColor: {
        fontSize: 13
      },
      buttonGroup: {
        '& .css-1daoff1-MuiButtonBase-root-MuiButton-root:hover': {
          backgroundColor: '#cccccc36 !important'
        },
        width: '100%',
        justifyContent: 'flex-start !important',
        color: '#333 !important',
        marginTop: '20px !important',
        padding: '12px 15px !important',
        borderRadius: '10px !important',
        fontSize: '17px !important'
      },
      sendGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      sendFriend: {
        minWidth: '30px !important',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? `${buttonColor.backgroundColor} !important`
            : '#E7F3FF !important',
        color:
          theme.palette.mode === 'dark'
            ? '#00b2ff !important'
            : `${buttonColor.backgroundColor} !important`,
        fontWeight: '500 !important'
      },
      textBold: {
        fontWeight: '500 !important',
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      textFriend: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        marginLeft: '15px !important',
        marginBottom: '5px !important'
      },
      customHeaderContent: {
        backgroundColor: '#F0F2F5',
        padding: '5px 10px',
        borderRadius: '10px',
        fontWeight: '500'
      },
      boxHeader: {
        padding: '15px',
        backgroundColor: theme.palette.mode === 'dark' ? '#2A2A2A' : '#F0F2F5',
        borderRadius: '10px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
      },
      nameInfo: {
        fontWeight: 'bold !important'
      },
      timeCreated: { fontSize: '13px !important' },
      BoxRightInfoHeader: {
        padding: '0px 15px'
      },
      boxInfoHeader: {
        display: 'flex'
      },
      boxInfoContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      imageShare: {
        width: '100px',
        height: '80px',
        objectFit: 'cover',
        borderRadius: '10px'
      }
    }),
  { index: 1 }
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    zIndex: 1000,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function DialogShareToMessenger(props: any) {
  const { open, handleClose, post, type, meInfo, page } = props;
  const classes = useStyles();
  const match: any = useRouteMatch();
  const [value, setValue] = React.useState<any>('');
  const [listGroup, setListGroup] = React.useState<any>('');
  const [keyword, setKeyword] = React.useState<string>('');
  const [listResultSearch, setListResultSearch] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [messengerSelected, setMessengerSelected] = React.useState<any>([]);
  const [friendSelected, sendFriendSelected] = React.useState<any>([]);
  const [listRecentlyChat, setListRecentlyChat] = React.useState([]);
  const [sendToId, setSendToId] = React.useState<string | null>();
  const [isDisableSendMsg, setIsDisableSendMsg] = React.useState(false);

  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const listConversations = useSelector(
    (state: any) => state.socialChatReducer.listConversations
  );

  React.useEffect(() => {
    setListRecentlyChat(
      listConversations
        ?.filter((item: any) => item.t !== 'cp' && item.blocker !== true)
        ?.splice(0, 5)
    );
  }, []);
  async function getListGroupChat() {
    let response = await getListGroupsChat(5);
    if (response.status === 200) {
      setListGroup(response.data);
    }
  }
  const debounceSearchRocketChat = React.useCallback(
    _.debounce(async function (q) {
      let response = await searchConversation(q);
      let responseGr = await searchConversationGroup(q);
      if (response.status === 200 && responseGr.status === 200) {
        let listConversations = response.data.map(el => {
          if (el.t && el.t === 'p') {
            el.type = 'group';
            el.avatar_media = {
              avatar_top: `${urlRocketChat}/avatar/${el?.members[0]}`,
              avatar_bottom: `${urlRocketChat}/avatar/${el?.members[1]}`
            };
            el.rid = el._id;
            el.fname = el.fname;
          } else {
            el.fname = el.name;
            el.avatar_media = {
              show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el._id}`
            };
            el.userId = el._id;
            el.type = 'user';
          }

          return el;
        });
        setIsLoading(false);
        setListResultSearch(listConversations);
      }
    }, 800),
    []
  );

  const handleSearch = () => {
    debounceSearchRocketChat(keyword);
  };

  React.useEffect(() => {
    getListGroupChat();
  }, []);

  React.useEffect(() => {
    if (keyword) {
      handleSearch();
    }
  }, [keyword]);

  const dispatch = useDispatch();

  const handleChatNow = async (item: any, type) => {
    const id = uuidv4();
    const keyId = item._id ?? item.id;
    try {
      let roomId;
      let linkPost;
      let originLocation = window.location.origin;
      const {
        page,
        group,
        event,
        project,
        post_type,
        media_attachments,
        account,
        title
      } = post;
      if (post_type === 'moment') {
        linkPost = `${originLocation}/moment?mediaId=${media_attachments[0].id}`;
      } else if (page) {
        linkPost = `${originLocation}/${page.username ?? page.id}/posts/${
          post.id
        }`;
      } else if (group) {
        linkPost = `${value ? `${value} ` : ''}${originLocation}/${
          group.username ?? group.id
        }/posts/${post.id}`;
      } else if (event) {
        linkPost = `${originLocation}/event/${event.id}/discussion`;
      } else if (project) {
        linkPost = `${originLocation}/grow/${project.id}/discussion`;
      } else {
        if (match.params?.id === post.id && post?.title) {
          linkPost = `${value ? `${value} ` : ''}${originLocation}/group/${
            post.id
          }`;
        } else {
          linkPost = `${value ? `${value} ` : ''}${originLocation}/${
            title ? 'group' : account?.username ?? account?.id
          }/posts/${post.id}`;
        }
      }

      setSendToId(keyId);
      setIsDisableSendMsg(true);

      if (item.t === 'p') {
        roomId = item.rid ?? item._id;
      } else {
        const response = await createDirectMessage(item?.username);
        if (response.status === 200) {
          roomId = JSON.parse(response.data.message).result.rid;
          subRoom(roomId);
          const res = await getSubscriptionRoom(roomId);
          dispatch(selectedUserBoxChat(res.data.subscription));
          sendFriendSelected(prev => [
            ...prev,
            JSON.parse(response.data.message).result
          ]);
        }
      }
      dispatch(
        eventMessageFromChatPage({
          ...item.lastMessage,
          msg: linkPost,
          status: 'sending',
          _id: id,
          u: {
            _id: meInfo.id,
            username: meInfo.username,
            name: meInfo.display_name
          },
          urls: []
        })
      );

      dispatch(
        receiveMessage({
          ...item.lastMessage,
          msg: linkPost,
          status: 'sending',
          _id: id,
          u: {
            _id: meInfo.id,
            username: meInfo.username,
            name: meInfo.display_name
          },
          urls: []
        })
      );
      const res = await sendMessageApi(linkPost, roomId, undefined, undefined, id);

      if (res.status === 200) {
        setMessengerSelected(prev => [...prev, res.data.message]);
      }
    } catch (error) {
    } finally {
      setSendToId(null);
      setIsDisableSendMsg(false);
    }
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography className={classes.title}>Gửi bằng Emso chat</Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {type === 'group' ? (
          <Box className={classes.customHeaderContent}>{post?.title}</Box>
        ) : (
          <Box className={classes.boxHeader}>
            <Box className={classes.boxInfoHeader}>
              <AvatarSocial
                type="feed"
                style={{ width: 40, height: 40, cursor: 'pointer' }}
                avatarObj={
                  post?.page?.avatar_media ?? post?.account?.avatar_media
                }
                isPopup
                object={post?.page ?? post?.account}
              />
              <Box className={classes.BoxRightInfoHeader}>
                <Typography className={classes.nameInfo}>
                  {post.account.display_name}
                </Typography>
                <Typography className={classes.timeCreated}>
                  {handleTimeShow(post.created_at)}{' '}
                  <i className="fas fa-globe-asia" aria-label="Công khai" />
                </Typography>
              </Box>
            </Box>
            <Box className={classes.boxInfoContent}>
              <TextCollapse
                type={true}
                data={post.content ? post.content : ''}
                status_tags={post.status_tags}
                style={{ fontSize: '16px' }}
                info={post}
                typeShare="share-messenger"
              />
              {post?.media_attachments.length > 0 && (
                <img
                  src={
                    post.media_attachments[0].preview_url ||
                    post.media_attachments[0].url
                  }
                  alt="share-messenger"
                  className={classes.imageShare}
                />
              )}
              {post?.status_question && post.status_question.color && (
                <Typography>{post?.status_question.content}</Typography>
              )}
            </Box>
          </Box>
        )}

        <div className={classes.content}>
          <div>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <AvatarSocial
                avatarObj={
                  rolePage?.role && rolePage?.role
                    ? rolePage?.avatar_media
                    : meInfo.avatar_media
                }
                style={{
                  marginTop: '10px ',
                  margin: '0',
                  height: 40,
                  width: 40
                }}
                isPopup
                object={rolePage?.role ? page : meInfo}
              />
            </StyledBadge>
          </div>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Hãy viết gì đó về nội dung này..."
            inputProps={{ 'aria-label': 'Hãy viết gì đó về nội dung này...' }}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
          />
        </div>
        <Divider sx={{ marginBottom: '10px' }} />
        <InputSearch
          label="Tìm kiếm người và nhóm"
          keyword={keyword}
          setKeyword={setKeyword}
          type={'share-group'}
          styleInput={{
            backgroundColor: ' #ccc3',
            borderRadius: '30px'
          }}
        />

        {/* <Button
          startIcon={<i className="fa-solid fa-users-medical"></i>}
          className={classes.buttonGroup}
          sx={{ textTransform: 'inherit' }}
        >
          <div className={classes.sendGroup}>
            Gửi cho nhóm mới
            <i
              className="fa-regular fa-chevron-right"
              style={{ fontSize: '20px', color: '#65676b75' }}
            ></i>
          </div>
        </Button> */}
        {keyword.length < 1 ? (
          <div>
            <List>
              {listRecentlyChat?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Gần đây
                  </Typography>
                  {listRecentlyChat?.map((item: any, index) => {
                    let sendMess = messengerSelected?.filter(
                      el => el?.rid === item?.rid
                    );
                    return (
                      <ListItem key={index}>
                        <>
                          <ListItemAvatar style={{ position: 'relative' }}>
                            {item.t === 'p' ? (
                              <>
                                <AvatarSocial
                                  avatarObj={item?.avatar_media?.avatar_top}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    zIndex: 1
                                  }}
                                  isAvatarGroupChat={'yes'}
                                />
                                <AvatarSocial
                                  type="feed"
                                  avatarObj={item?.avatar_media?.avatar_bottom}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    position: 'absolute',
                                    right: '6px',
                                    top: '-8px',
                                    zIndex: 0
                                  }}
                                />
                              </>
                            ) : (
                              <Avatar
                                alt="avatar"
                                sx={{
                                  height: 40,
                                  width: 40
                                }}
                                src={item?.avatar_media?.show_url}
                              />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ width: '100%' }}
                            primary={
                              <Typography className={classes.textBold}>
                                {item?.fname}
                              </Typography>
                            }
                          />
                        </>
                        <ButtonCustom
                          label={
                            sendMess?.length ? (
                              <Typography style={{ fontWeight: 500 }}>
                                <i
                                  className="fa-solid fa-message-xmark"
                                  style={{
                                    paddingRight: '10px'
                                  }}
                                ></i>
                                Đã gửi
                              </Typography>
                            ) : (
                              <Typography style={{ fontWeight: 500 }}>
                                Gửi
                              </Typography>
                            )
                          }
                          style={{
                            width: sendMess?.length ? '120px' : '60px',
                            backgroundColor: sendMess?.length
                              ? '#333 !important'
                              : '#e7f3ff',
                            color: sendMess?.length
                              ? '#fff'
                              : buttonColor.backgroundColor
                          }}
                          action={() =>
                            handleChatNow(item, item.t === 'p' ? 'p' : 'd')
                          }
                          disabled={isDisableSendMsg || !!sendMess?.length}
                          isLoading={sendToId === item._id}
                        />
                      </ListItem>
                    );
                  })}
                </>
              ) : null}
            </List>
            <List>
              {listGroup?.length ? (
                <>
                  <Typography className={classes.textFriend}>Nhóm</Typography>
                  {listGroup?.map((item: any, index) => {
                    let avatar_top = {
                      show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${item.uids[0]}`
                    };
                    let avatar_bottom = {
                      show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${item.uids[1]}`
                    };
                    let avatarMe = `${urlRocketChat}/api/v1/users.getAvatar?userId=${item.u._id}`;
                    let sendGroup = messengerSelected?.filter(
                      el => el?.rid === item?.lastMessage?.rid
                    );
                    return (
                      <ListItem key={index}>
                        <>
                          {item.uids.length > 1 ? (
                            <ListItemAvatar style={{ position: 'relative' }}>
                              <AvatarSocial
                                avatarObj={avatar_top}
                                style={{
                                  width: 30,
                                  height: 30,
                                  zIndex: 1
                                }}
                                isAvatarGroupChat={'yes'}
                              />
                              <AvatarSocial
                                type="feed"
                                avatarObj={avatar_bottom}
                                style={{
                                  width: 30,
                                  height: 30,
                                  position: 'absolute',
                                  right: '6px',
                                  top: '-8px',
                                  zIndex: 0
                                }}
                              />
                            </ListItemAvatar>
                          ) : (
                            <ListItemAvatar style={{ position: 'relative' }}>
                              <Avatar
                                alt="avatar"
                                sx={{
                                  height: 40,
                                  width: 40
                                }}
                                src={avatarMe}
                              />
                            </ListItemAvatar>
                          )}

                          <ListItemText
                            sx={{ width: '100%' }}
                            primary={
                              <Typography
                                className={classes.textBold}
                                sx={{ marginLeft: '10px' }}
                              >
                                {item.fname}
                              </Typography>
                            }
                          />
                        </>
                        <ButtonCustom
                          label={
                            sendGroup?.length ? (
                              <Typography style={{ fontWeight: 500 }}>
                                <i
                                  className="fa-solid fa-message-xmark"
                                  style={{
                                    paddingRight: '10px'
                                  }}
                                ></i>
                                Đã gửi
                              </Typography>
                            ) : (
                              <Typography style={{ fontWeight: 500 }}>
                                Gửi
                              </Typography>
                            )
                          }
                          style={{
                            width: sendGroup?.length ? '120px' : '60px',
                            backgroundColor: sendGroup?.length
                              ? '#333 !important'
                              : '#e7f3ff',
                            color: sendGroup?.length
                              ? '#fff'
                              : buttonColor.backgroundColor
                          }}
                          disabled={isDisableSendMsg || !!sendGroup?.length}
                          isLoading={sendToId === item._id}
                          action={() => handleChatNow(item, 'p')}
                        />
                      </ListItem>
                    );
                  })}
                </>
              ) : null}
            </List>
            <List>
              {friends?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Người liên hệ
                  </Typography>
                  {friends?.map((item: any, index) => {
                    // let newItem = {
                    //   ...item,
                    //   avatar_media: {
                    //     show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${item?.id}`
                    //   }
                    // };
                    let sendFriend = friendSelected?.filter(
                      friend =>
                        friend?.usernames[0] === item?.username ||
                        friend?.usernames[1] === item?.username
                    );
                    return (
                      <ListItem key={index}>
                        <>
                          <ListItemAvatar>
                            <Avatar
                              alt="avatar"
                              sx={{
                                height: 40,
                                width: 40
                              }}
                              src={
                                item?.avatar_media?.show_url ??
                                item?.avatar_media?.preview_url
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography className={classes.textBold}>
                                {item.display_name}
                              </Typography>
                            }
                          />
                        </>
                        <ButtonCustom
                          label={
                            sendFriend?.length ? (
                              <Typography style={{ fontWeight: 500 }}>
                                <i
                                  className="fa-solid fa-message-xmark"
                                  style={{
                                    paddingRight: '10px'
                                  }}
                                ></i>
                                Đã gửi
                              </Typography>
                            ) : (
                              <Typography style={{ fontWeight: 500 }}>
                                Gửi
                              </Typography>
                            )
                          }
                          style={{
                            width: sendFriend?.length ? '90px' : '60px',

                            backgroundColor: sendFriend?.length
                              ? '#333 !important'
                              : '#e7f3ff',
                            color: sendFriend?.length
                              ? '#fff'
                              : buttonColor.backgroundColor
                          }}
                          disabled={isDisableSendMsg || !!sendFriend?.length}
                          isLoading={sendToId === item.id}
                          action={() => handleChatNow(item, 'd')}
                        />
                      </ListItem>
                    );
                  })}
                </>
              ) : null}
            </List>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Loading style={{ marginTop: '20px' }} />
            ) : listResultSearch && listResultSearch.length > 0 ? (
              listResultSearch.map((el: any, index) => {
                let sendFriend = friendSelected?.filter(
                  friend =>
                    friend?.usernames[0] === el?.username ||
                    friend?.usernames[1] === el?.username
                );

                return (
                  <Box
                    key={index}
                    sx={{ width: '100%', height: 'auto', position: 'relative' }}
                  >
                    {console.log(el)}
                    <Conversation
                      type="search"
                      conversation={el}
                      key={index}
                      styleConversation={{ pointerEvents: 'none' }}
                    />
                    {open && (
                      <ButtonCustom
                        label={
                          sendFriend?.length ? (
                            <Typography style={{ fontWeight: 500 }}>
                              <i
                                className="fa-solid fa-message-xmark"
                                style={{
                                  paddingRight: '10px'
                                }}
                              ></i>
                              Đã gửi
                            </Typography>
                          ) : (
                            <Typography style={{ fontWeight: 500 }}>
                              Gửi
                            </Typography>
                          )
                        }
                        style={{
                          width: sendFriend?.length ? '90px' : '60px',
                          backgroundColor: sendFriend?.length
                            ? '#333 !important'
                            : '#e7f3ff',
                          color: sendFriend?.length
                            ? '#fff'
                            : buttonColor.backgroundColor,

                          zIndex: '10',
                          position: 'absolute',
                          top: 2,
                          right: 0
                        }}
                        disabled={sendFriend?.length ? true : false}
                        isLoading={sendToId === el._id}
                        action={() => handleChatNow(el, 'd')}
                      />
                    )}
                  </Box>
                );
              })
            ) : (
              <div style={{ textAlign: 'center', margin: '20px auto' }}>
                Không tìm thấy cuộc trò chuyện.
              </div>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
export default React.memo(DialogShareToMessenger);
