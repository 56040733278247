import {
  Box,
  Divider,
  Grid,
  Paper,
  Popover,
  Theme,
  Typography
} from '@mui/material';
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getListGroupApi } from 'src/apis/socialGroup';
import { getListPagesApi } from 'src/apis/socialPages.api';
import { createMediaMastodon } from 'src/apis/socialPost.api';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogOptionChange from 'src/components/Dialog/DialogOptionChange';
import DialogPostVisibility from 'src/components/Dialog/DialogPostVisibility';
import ListButton from 'src/components/List/ListButton';
import Loading from 'src/components/Loading/Loading';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { listVisibility } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { openDialogPost } from 'src/store/action/socialLiveStreamAction';
import { getPageDetailReq } from 'src/store/action/socialPageAction';
import { createPostRequestAction } from 'src/store/action/socialPostAction';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        // backgroundColor: '#fff',
        position: 'fixed',
        padding: '5px 0px 40px 10px'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px 10px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      },
      styleBoxLinearProgressRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      styleBoxLinearProgressChildren: {
        width: '100%',
        mr: 1
      },
      stylePaddingDiv: {
        margin: '10px 10px 0px 0px',
        padding: '0px 5px'
      },
      styleBoxRootLivestreamCreate: {
        display: 'flex',
        padding: '12px',
        backgroundColor: 'background.secondary',
        marginBottom: '-8px'
      },
      styleBoxRootLivestreamRealTime: {
        display: 'flex',
        justifyContent: 'center'
      },
      styleCheckbox: {
        '&.MuiCheckbox-root': {
          width: 'auto !important',
          color: 'green'
        }
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      },
      boxInfo: {
        display: ' flex',
        alignItems: 'center',
        marginTop: '15px'
      },
      imageLogo: {
        width: '30px',
        height: '30px',
        borderRadius: '50%'
      },
      textCount: {
        fontSize: '50px !important',
        fontWeight: 'bold !important'
      },
      textBold: {
        fontSize: '20px !important',
        fontWeight: 'bold !important'
      },
      textNormal: {
        fontSize: '18px !important',
        fontWeight: '500 !important'
      }
    }),
  { index: 1 }
);

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const [listGroups, setListGroups] = React.useState<any>([]);
  const [listPageAdmin, setListPageAdmin] = React.useState<any>([[]]);
  const [visibility, setVisibility] = React.useState<any>(listVisibility[0]);
  const [openVisibility, setOpenVisibility] = React.useState<any>(false);
  const [open, setOpen] = React.useState<any>(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const typeLive = useSelector(
    (state: any) => state.socialLiveStreamReducer.type
  );
  const [itemSelected, setItemSelected] = React.useState<any>({
    id: 'myTime',
    data: null
  });
  const liveStreamInfo =
    useSelector(
      (state: any) => state.socialLiveStreamReducer.inforLiveStream
    ) ?? {};
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let pageId: any = query.get('page');
  const pageInfo = useSelector((state: any) => state?.pageReducer?.info) || {};
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [count, setCount] = React.useState(3);
  const [startLive, setStartLive] = React.useState(false);
  const [destroyLive, setDestroyLive] = React.useState(false);
  const refLive = React.useRef();

  const otherAction: any = [
    [
      {
        id: 'myTime',
        label: 'Đăng lên dòng thời gian',
        avatar_social: {
          type: 'avatar',
          avatarObj: pageId
            ? pageInfo?.avatar_media?.preview_url
            : meInfo?.avatar_media ?? {
                show_url: avatarDefault
              },
          style: {
            width: 24,
            height: 24,
            margin: '0px 15px 0px 0px',
            border: '1px solid #fff'
          }
        },
        checkbox: true,
        action: () => {
          setItemSelected({
            id: 'myTime',
            data: null
          });
        }
      },
      {
        id: 'page',
        label: 'Đăng lên trang bạn quản lý',
        isFather: true,
        options: listPageAdmin,
        icon: 'fa-solid fa-flag',
        styleIcon: { fontSize: '20px' },
        checkbox: true
      },
      {
        id: 'group',
        label: 'Đăng trong nhóm',
        icon: 'fa-solid fa-user-group',
        isFather: true,
        options: listGroups,
        styleIcon: { fontSize: '20px' },
        checkbox: true
      }
    ]
  ];

  const fetchGroup = async params => {
    let response = await getListGroupApi(params);
    if (response.status === 200) {
      let tempData: any = response.data.data.map((el: any) => ({
        id: el.id,
        label: el.title,
        avatar_social: {
          type: 'avatar',
          avatarObj: el?.banner ?? {
            show_url: avatarDefault
          },
          style: {
            width: '45px',
            height: '45px',
            margin: '0px 15px 0px 0px',
            border: '1px solid #fff'
          }
        },
        action: item => {
          setItemSelected({
            id: 'group',
            data: item
          });
        },
        infiniteScroll: true
      }));
      setListGroups([
        [
          {
            id: 'groups',
            title: 'Chọn nhóm',
            infiniteScroll: true
          },
          ...tempData
        ]
      ]);
    }
  };

  const fetchPageAdmin = async () => {
    let response = await getListPagesApi(null);
    if (response.status === 200) {
      if (response.data.length) {
        let tempData: any = response.data.data.map((el: any) => ({
          id: el.id,
          label: el.title,
          avatar_social: {
            type: 'avatar',
            avatarObj: el?.banner ?? {
              show_url: avatarDefault
            },
            style: {
              width: '45px',
              height: '45px',
              margin: '0px 15px 0px 0px',
              border: '1px solid #fff'
            }
          },
          action: item => {
            setItemSelected({
              id: 'page',
              data: item
            });
          }
        }));
        setListPageAdmin(() => {
          return [
            [
              {
                id: 'pages',
                title: 'Chọn trang',
                infiniteScroll: true,
                styleTitle: { margin: '0px' }
              },
              ...tempData
            ]
          ];
        });
      }
    }
  };

  const handleCreatePostLiveStream = async () => {
    let res = await createMediaMastodon({
      video_id: liveStreamInfo?.videoUUID,
      remote_url: 'master.m3u8',
      mimeType: 'video/mp4'
    });
    if (res.status === 200) {
      setOpen(false);
      handlePopoverClose();
      setIsLoading(false);
      dispatch(openDialogPost('close'));
      dispatch(
        createPostRequestAction(
          {
            status: liveStreamInfo?.textContentLive,
            id: null,
            media_ids: [res.data.id],
            sensitive: false,
            visibility: visibility.key,
            page_id: pageId ? pageId : null,
            page_owner_id: pageId ? pageId : null,
            post_type: 'livestream',
            livestream_type:
              typeLive === 'live_marketplace '
                ? 'marketplace'
                : typeLive === 'live_game'
                ? 'game'
                : null
          },
          '',
          '',
          '',
          ''
        )
      );
    }
  };

  const handleVisibility = key => {
    let visibilitySelected: any = listVisibility?.find(
      (el: any) => el.key === key
    );
    setVisibility(visibilitySelected);
  };

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopup = Boolean(anchorEl);

  React.useEffect(() => {
    fetchGroup({ tab: 'join' });
    fetchPageAdmin();
  }, []);

  React.useEffect(() => {
    if (pageId) {
      dispatch(getPageDetailReq(pageId));
    }
  }, []);

  React.useEffect(() => {
    let timer;

    if (Boolean(anchorEl)) {
      timer = setInterval(() => {
        if (count > 1) {
          setCount(count - 1);
        } else {
          handleCreatePostLiveStream();
          setIsLoading(true);
          setStartLive(true);
          clearInterval(timer);
          setOpen(false);
          setDestroyLive(false);
        }
      }, 500);
    }

    return () => {
      clearInterval(timer);
    };
  }, [count, anchorEl]);

  const renderPopup = () => {
    return (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={openPopup}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper
          sx={{
            padding: '10px',
            width: '300px'
          }}
        >
          <img src={logoEmso} alt="logo" className={classes.imageLogo} />
          <Typography className={classes.textBold} textAlign={'center'}>
            {startLive
              ? ' Đang bắt đầu video trực tiếp...'
              : 'Phát trực tiếp sau...'}
          </Typography>
          {startLive ? (
            <Loading style={{ padding: '10px' }} />
          ) : (
            <Typography className={classes.textCount} textAlign={'center'}>
              {count}
            </Typography>
          )}
        </Paper>
      </Popover>
    );
  };

  const renderButtonOptions = () => (
    <IconButtonOptions
      name={otherAction.map(
        el => el.find(item => item.id === itemSelected.id).label
      )}
      description={'Chọn nơi đăng'}
      styleText={{ flexDirection: 'column-reverse' }}
      icon={
        <i
          style={{
            fontSize: '16px',
            fontWeight: '500'
          }}
          className="fa-sharp fa-solid fa-caret-down"
        ></i>
      }
      openPopup={true}
      style={{
        margin: '0px 0px',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'background.primary',
        border: '1px solid silver',
        '&:hover': {
          border: '1px solid #050505',
          backgroundColor: 'background.primary'
        }
      }}
      options={pageId ? [otherAction[0].slice(0, 1)] : otherAction}
      styleListMenu={{
        minWidth: '328px',
        maxHeight: '300px',
        overflowY: 'auto'
      }}
      stylePopup={{
        marginTop: '5px',
        zIndex: '1001',
        width: '330px'
      }}
      searchItems="search_children"
      horizontalAnchor="center"
      horizontalTransform="center"
    />
  );

  const renderInfo = item => (
    <Box className={classes.boxInfo}>
      <AvatarSocial
        type={item.avatar_social.type}
        avatarObj={item.avatar_social.avatarObj}
        style={item.avatar_social.style}
        noAction={true}
      />
      <Typography
        fontWeight={500}
        fontSize={'16px'}
        className={classes.lineClamp}
      >
        {item.label ?? item.name}
      </Typography>
    </Box>
  );

  React.useEffect(() => {
    if (liveStreamInfo?.isLiveStream) {
      history.push('/live/realtime');
    }
  }, [liveStreamInfo?.isLiveStream]);
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number } & {
      completed: number;
    }
  ) {
    return (
      <Box className={classes.styleBoxLinearProgressRoot}>
        <Box className={classes.styleBoxLinearProgressChildren}>
          <LinearProgress
            sx={{ borderRadius: '15px', height: '10px' }}
            variant="determinate"
            {...props}
          />
        </Box>
        <Typography
          style={{ color: theme.palette.mode === 'dark' ? 'white' : 'blue' }}
        >
          {props.completed}/3
        </Typography>
      </Box>
    );
  }

  const renderHeader = () => {
    switch (match.path) {
      case PATHS.LIVESTREAM_CREATE:
      case PATHS.LIVESTREAM:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Tạo video trực tiếp
              </Typography>
            </Grid>
          </Grid>
        );
      case PATHS.LIVESTREAM_REALTIME:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Bảng điều khiển trực tiếp
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  };
  const renderFromCheckBox = (
    label: string,
    value: boolean,
    isDefaultcheck: boolean
  ) => {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
      >
        {/* <Checkbox
          defaultChecked={isDefaultcheck}
          checked={value}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          className={classes.styleCheckbox}
        /> */}
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: value ? '#00a200' : 'inherit',
            border: value
              ? 'none'
              : theme.palette.mode === 'dark'
              ? '2px solid #a6a8ac'
              : '2px solid #606770'
          }}
        >
          {value && (
            <i
              className="fa-sharp fa-solid fa-check"
              style={{
                fontSize: '12px',
                color: theme.palette.mode === 'dark' ? '#050505' : '#fff'
              }}
            ></i>
          )}
        </div>
        <Typography sx={{ marginLeft: '10px' }}>{label}</Typography>
      </div>
    );
  };
  const renderBody = () => {
    switch (match.path) {
      case PATHS.LIVESTREAM_CREATE:
      case PATHS.LIVESTREAM:
        return {
          bodyTop: (
            <div className={classes.stylePaddingDiv}>
              <LinearProgressWithLabel
                value={
                  ((liveStreamInfo?.connectOBS && liveStreamInfo?.isLiveStream
                    ? 3
                    : liveStreamInfo?.connectOBS &&
                      !liveStreamInfo?.isLiveStream
                    ? 2
                    : 1) /
                    3) *
                  100
                }
                completed={
                  liveStreamInfo?.connectOBS && liveStreamInfo?.isLiveStream
                    ? 3
                    : liveStreamInfo?.connectOBS &&
                      !liveStreamInfo?.isLiveStream
                    ? 2
                    : 1
                }
              />
              <Box>
                {renderFromCheckBox(
                  'Kết nối nguồn video',
                  liveStreamInfo?.connectOBS,
                  false
                )}
                {renderFromCheckBox('Hoàn tất chi tiết bài viết', true, true)}
                {renderFromCheckBox(
                  'Phát trực tiếp',
                  liveStreamInfo?.isLiveStream,
                  false
                )}
              </Box>
              <Divider style={{ paddingBottom: '10px', paddingTop: '10px' }} />
            </div>
          ),
          bodyCenter: (
            <div className={classes.stylePaddingDiv}>
              {match.path !== PATHS.LIVESTREAM_CREATE ? (
                <ListButton
                  item={[
                    {
                      id: 'home_live',
                      title: 'Trang chủ',
                      styleTitle: { fontWeight: '600' },
                      icon: (
                        <i
                          className="fa-light fa-video"
                          style={{ fontSize: '18px' }}
                        ></i>
                      ),
                      style: {
                        height: 36,
                        width: 36,
                        borderRadius: '50%',
                        backgroundColor: buttonColor.backgroundColor,
                        color: '#fff'
                      },

                      position: 'top'
                    }
                  ]}
                  styleBg={{ backgroundColor: 'button.primary.background' }}
                  selectedItem={selectedIndex}
                  width={'340px'}
                  typeAction={'action'}
                />
              ) : null}
              <IconButtonOptions
                name={pageId ? pageInfo?.title : meInfo?.display_name}
                avatar={
                  pageId
                    ? pageInfo?.avatar_media?.preview_url
                    : meInfo?.avatar_media?.show_url ?? avatarDefault
                }
                description={'Người tổ chức - Trang cá nhân của bạn'}
                styleDescription={{ color: theme.palette.text.primary }}
                styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                style={{
                  margin: '0px 0px',
                  width: '100%',
                  minHeight: '64px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'button.primary.background'
                }}
                disabled
              />
              {renderButtonOptions()}
              {itemSelected?.data ? renderInfo(itemSelected.data) : null}
              {match.path === PATHS.LIVESTREAM_CREATE ? (
                <ButtonInherit
                  label={visibility.label}
                  style={{
                    minWidth: '120px',
                    marginTop: '10px'
                  }}
                  color={theme.palette.mode === 'dark' ? '#3a3b3c' : '#e4e6eb'}
                  textColor={theme.palette.mode === 'dark' ? '#fff' : '#050505'}
                  icon={
                    <i
                      style={{
                        marginTop: 1,
                        color:
                          theme.palette.mode === 'dark' ? '#fff' : '#050505'
                      }}
                      className={`${visibility.icon} fa-xs`}
                    ></i>
                  }
                  action={() => setOpenVisibility(true)}
                />
              ) : null}
            </div>
          ),
          bodyFooter: null
        };
      case PATHS.LIVESTREAM_REALTIME:
        return {
          bodyTop: (
            <div className={classes.stylePaddingDiv}>
              <Typography>
                Giờ đây khi phát trực tiếp, bạn có thể xem thông tin chi tiết
                theo thời gian thực hoặc thêm công cụ để tăng cường hoạt động
                phân phối hay tương tác.
              </Typography>
            </div>
          ),
          bodyCenter: (
            <IconButtonOptions
              name={`${
                liveStreamInfo?.post?.page
                  ? liveStreamInfo.post.page.title
                  : meInfo?.display_name.split(' ').pop()
              } đang phát trực tiếp`}
              avatar={
                liveStreamInfo?.post?.page
                  ? liveStreamInfo.post.page.avatar_media?.show_url ??
                    avatarDefault
                  : meInfo?.avatar_media?.show_url ?? avatarDefault
              }
              description={'Vừa xong .'}
              styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
              style={{
                margin: '0px 0px',
                width: '100%',
                minHeight: '64px',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'button.primary.background',
                color:
                  theme.palette.mode === 'dark'
                    ? '#ffffff '
                    : '#050505 !important'
              }}
              disabled
            />
          ),
          bodyFooter: null
        };
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  const renderFooter = () => {
    switch (match.path) {
      case PATHS.LIVESTREAM_CREATE: {
        return (
          <>
            <Box ref={refLive}></Box>
            <Box className={classes.styleBoxRootLivestreamCreate}>
              <ButtonInherit
                style={{
                  width: 'fit-content',
                  minWidth: '100px',
                  color: theme.palette.mode === 'dark' ? '#ffffff ' : '#050505'
                }}
                label="Quay lại"
                color={theme.palette.mode === 'dark' ? '#3a3b3c' : '#e4e6eb'}
                fullWidth={true}
                action={() => {
                  history.push('/live');
                }}
              />
              <ButtonInherit
                icon={
                  <i style={{ fontSize: 15 }} className="fa-light fa-video"></i>
                }
                label={destroyLive ? 'Hủy' : 'Phát trực tiếp'}
                color={buttonColor.backgroundColor}
                textColor="#fff"
                fullWidth={true}
                action={() => {
                  if (liveStreamInfo?.textContentLive && !destroyLive) {
                    handlePopoverOpen(refLive.current);
                    setCount(3);
                    setDestroyLive(true);
                    setStartLive(false);
                  } else if (destroyLive) {
                    setAnchorEl(null);
                    setDestroyLive(!destroyLive);
                  } else {
                    setOpen(true);
                  }
                }}
                disabled={
                  liveStreamInfo?.connectOBS || destroyLive ? false : true
                }
                loading={isLoading}
              />
              {anchorEl && renderPopup()}
            </Box>
          </>
        );
      }
      case PATHS.LIVESTREAM_REALTIME: {
        return (
          <Box className={classes.styleBoxRootLivestreamRealTime}>
            <ButtonInherit
              label="Kết thúc video trực tiếp"
              color="#e41e3f"
              textColor="#fff"
              fullWidth={true}
              action={() => {}}
              style={{
                maxWidth: '320px'
              }}
            />
          </Box>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Box
        className={classes.root}
        sx={{
          position:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 'fixed'
              : 'static',

          width:
            match.path === PATHS.LIVESTREAM ||
            match.path === PATHS.LIVESTREAM_CREATE ||
            match.path === PATHS.LIVESTREAM_REALTIME
              ? 360
              : renderBody().bodyTop ||
                renderBody().bodyCenter ||
                renderBody().bodyFooter
              ? 310
              : 0,
          margin:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 0
              : '5px 10px 40px -20px',
          padding:
            match.params?.key === 'search'
              ? '0px 0px 0px 0px'
              : '5px 0px 40px 6px',
          backgroundColor: 'background.primary',
          boxShadow:
            theme.palette.mode === 'dark'
              ? '1px 0px 5px 0.4px rgb(200 200 200 / 15%)'
              : '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
        }}
      >
        {match.params?.key !== 'search' && (
          <div className={classes.headerPage}>{renderHeader()}</div>
        )}

        <div className={classes.rootPage}>
          {match.path === PATHS.LIVESTREAM_CREATE ||
          match.path === PATHS.LIVESTREAM_REALTIME
            ? renderBody().bodyTop
            : null}
          {renderBody().bodyCenter}
        </div>

        <div className={classes.footerPage}>{renderFooter()}</div>
      </Box>

      {openVisibility && (
        <DialogPostVisibility
          open={openVisibility}
          setOpen={setOpenVisibility}
          value={visibility.key}
          handleAction={handleVisibility}
        />
      )}
      {open && (
        <DialogOptionChange
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          title="Đặt tên"
          contentDialog="Bạn có thể đặt tên cho video trực tiếp để tiếp cận nhiều người hơn."
          labelButton="Phát trực tiếp"
          notCancel="Đặt tên"
          action={() => {
            handlePopoverOpen(refLive.current);
            setCount(3);
            setOpen(false);
            setDestroyLive(true);
            setStartLive(false);
          }}
          actionCancel={() => {
            dispatch(openDialogPost('open'));
          }}
        />
      )}
    </>
  );
};

export default Index;
