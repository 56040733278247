import * as types from 'src/constants/store/login';
import * as actions from 'src/store/action/login';
import * as loginApi from 'src/apis/socialAuth.api';
import { takeEvery, put, call } from 'redux-saga/effects';
import { getInfoMe } from 'src/apis/user.api';
import { updateTokenUser } from 'src/util/config';

export const LoginSagas = function* (action) {
  try {
    let data = action.payload;
    let response: any;
    if (!data.token) {
      response = yield call(loginApi.socialLoginApi, data);
      if (response.status === 200) {
        let token = response.data.access_token;
        updateTokenUser(token);
        localStorage.setItem('token', token);
        if (localStorage.getItem('isFastLogin') === null)
          localStorage.setItem('isFastLogin', 'no');
      }
    } else {
      localStorage.setItem('token', data.token);
      localStorage.setItem('isFastLogin', 'yes');
      const channel = new BroadcastChannel('tokenChannel');
      channel.postMessage(data.token);
      window.location.href = '/';
    }
    let dataToken = localStorage.getItem('token');
    // Trường hợp chỉ gửi vào token thì đã gọi api me ở trước đó để check rồi nên k gọi api nữa
    if (response.status === 200 && dataToken && !data.token) {
      let res = yield call(getInfoMe, dataToken);
      if (res.status === 200) {
        if (res.data.id) {
          localStorage.setItem('userId', res.data.id);
          localStorage.setItem('darkThemeUser', res.data?.theme);
        }
      }
      yield put(actions.loginSuccess());
      window.location.href = '/';
    }
  } catch (err: any) {
    yield put(actions.messageFail(err.response.data));
    yield put(
      actions.loginFailed({
        isError: true
      })
    );
  }
};

export function* watchLoginAsync() {
  yield takeEvery(types.LOGIN_REQ, LoginSagas);
}
