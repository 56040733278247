import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import {
  getListDistrictsApi,
  getListProvincesApi
} from 'src/apis/socialLocation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface RecruitMedia {
  infoUpdate?: any;
  formik?: any;
  recruitInfo?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>>;
  locationSelected?: any;
  setLocationSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  openDialogUpdateLocation?: boolean;
  setOpenDialogUpdateLocation?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>>;
}

const RecruitMedia: React.FC<RecruitMedia> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    recruitInfo,
    setCheckStep,
    locationSelected,
    setLocationSelected,
    openDialogUpdateLocation,
    setOpenDialogUpdateLocation,
    setNotificationLeave
  } = props;
  const [verify, setVerify] = React.useState<any>(false);
  const [provinceOptions, setProvinceOptions] = React.useState<any>([]);
  const [districtOptions, setDistrictOptions] = React.useState<any>([]);
  React.useEffect(() => {
    if (recruitInfo?.location) {
      setLocationSelected({ location: [recruitInfo.location] });
    }
  }, [JSON.stringify(recruitInfo?.location)]);

  React.useEffect(() => {
    if (locationSelected) {
      formik.setFieldValue('location', locationSelected);
    }
  }, [JSON.stringify(locationSelected)]);

  React.useEffect(() => {
    if (setCheckStep) {
      if (formik.values.banner && !Object.keys(formik.errors).length) {
        setCheckStep(prev => ({ ...prev, step4: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step4: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);
  const getProvinces = async () => {
    let response = await getListProvincesApi();
    if (response.status === 200) {
      setProvinceOptions(response.data);
    }
  };

  const getDistricts = async id => {
    let response = await getListDistrictsApi(id);
    if (response.status === 200) {
      setDistrictOptions(response.data);
    }
  };
  React.useEffect(() => {
    getProvinces();
  }, []);

  React.useEffect(() => {
    if (formik.values.province) {
      getDistricts(formik.values.province.id);
    }
  }, [formik.values?.province?.id]);
  let fieldUpdate: any = [
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'banner',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: recruitInfo?.banner
        ? recruitInfo?.banner
        : formik?.values?.banner,
      type_field: 'binary'
    },
    {
      field_type: 'autocomplete',
      name: 'province',
      label: 'Tỉnh/Thành phố',
      options: provinceOptions,
      multiple: false,
      setOptions: setProvinceOptions,
      placeholder: 'Tỉnh/Thành phố',
      span: 12
    },
    {
      field_type: 'autocomplete',
      name: 'district',
      label: 'Quận/Huyện',
      options: districtOptions,
      multiple: false,
      setOptions: setDistrictOptions,
      placeholder: 'Quận/Huyện',
      span: 12
    },
    {
      field_type: 'textField',
      name: 'address',
      placeholder: 'VD: Số 250 Minh Khai, Hai Bà Trưng',
      label: 'Địa chỉ công ty',
      onChange: true,
      maxRows: 2,
      defaultValue: formik.values?.address === 'null' ? '' : formik.values?.address,
    },
    {
      field_type: locationSelected ? 'map' : 'autocomplete',
      name: 'location',
      options: [{ name: 'Chọn vị trí' }],
      label: 'Vị trí',
      placeholder: 'Vị trí',
      descriptionTitle:
        'Thêm vị trí thực để mọi người tham gia sự kiện của bạn.',
      endButton: {
        icon: 'fa-solid fa-location-dot'
      },
      disabled: true,
      action: () => setOpenDialogUpdateLocation(true),
      style: { margin: '8px 0px 0px' },
      styleLabel: { margin: '8px 0px 0px' }
    },
    {
      field_type: locationSelected ? 'button_add' : null,
      name: 'remove_location',
      label: 'Chọn lại vị trí công ty',
      action: () => {
        setLocationSelected(null);
        formik.setFieldValue('location', null);
      }
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        locationSelected={locationSelected}
        setNotificationLeave={setNotificationLeave}
      />
      <DialogLocation
        open={openDialogUpdateLocation}
        handleClose={() => {
          setOpenDialogUpdateLocation(false);
        }}
        locationSelected={locationSelected}
        setLocationSelected={setLocationSelected}
        type={'recuirt'}
      />
    </Box>
  );
};

export default RecruitMedia;
