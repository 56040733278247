import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import {
  buttonColor,
  buttonColorHover,
  buttonStyle,
  modalBackdrop
} from 'src/constants/styles';
import { useTheme } from '@mui/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface CustomizedDialogsProps {
  title?: any;
  text?: any;
  open?: any;
  handleClose?: any;
  action?: any;
  labelButton?: string;
  colorButton?: any;
  loading?: any;
  setLoading?: any;
  contentDialog?: any;
  alignText?: any;
  hiddenButton?: any;
  styleDialogContent?: any;
  maxWidth?: any;
  notCancel?: any;
  setOpen?: any;
  hiddenTitle?: any;
  styleDialog?: any;
  disabledSubmit?: boolean;
}

export default function CustomizedDialogs(props: CustomizedDialogsProps) {
  const {
    title,
    text,
    open,
    handleClose,
    action,
    labelButton,
    colorButton,
    loading,
    setLoading,
    contentDialog,
    alignText,
    hiddenButton,
    styleDialogContent,
    maxWidth,
    notCancel,
    hiddenTitle,
    styleDialog,
    disabledSubmit
  } = props;
  const theme: any = useTheme();
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth ?? undefined}
        sx={{ ...styleDialog }}
        BackdropProps={modalBackdrop}
      >
        {!hiddenTitle && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Typography
                style={{ fontWeight: 'bold', fontSize: 20 }}
                textAlign={alignText ?? 'start'}
              >
                {title}
              </Typography>
            </BootstrapDialogTitle>
            <Divider />
          </>
        )}
        <DialogContent
          // dividers={!hiddenButton}
          style={{ ...styleDialogContent }}
          id="scroll-dialog-content"
        >
          <Typography style={{ fontSize: 15, wordBreak: 'break-word' }}>
            {text}
          </Typography>
          {contentDialog || null}
        </DialogContent>
        {!hiddenButton && (
          <DialogActions>
            {!notCancel && (
              <ButtonInherit
                label="Hủy"
                // color={buttonColor.backgroundColor}
                style={{
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#4b4b4b' : '#eeeeee'
                  // '&:hover': {
                  //   backgroundColor: buttonColorHover.backgroundColor
                  // }
                }}
                action={handleClose}
              />
            )}

            <ButtonInherit
              action={() => {
                action && action();
                handleClose && handleClose();
                setLoading && setLoading(true);
              }}
              color={colorButton || buttonColor.backgroundColor}
              style={{
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: buttonColorHover.backgroundColor
                }
              }}
              label={labelButton ? labelButton : 'Xóa'}
              loading={loading}
              disabled={disabledSubmit}
            />
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
