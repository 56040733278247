import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  interface Palette {
    myTextColor?: Palette['primary'];
    myBackgroundColor?: Palette['primary'];
    myButtonColor?: Palette['primary'];
  }
  interface PaletteOptions {
    myTextColor?: PaletteOptions['primary'];
    myBackgroundColor?: PaletteOptions['primary'];
    myButtonColor?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    primary?: string;
    secondary?: string;
    textButtonSelected?: string;
    paper?: string;
    hover1?: string;
    selected2?: string;
    hover2?: string;
    textIcon?: string;
  }
  interface SimplePaletteColorOptions {
    primary?: string;
    secondary?: string;
    textButtonSelected?: string;
    paper?: string;
    hover1?: string;
    selected2?: string;
    hover2?: string;
    textIcon?: string;
  }
}

export const lightTheme = createTheme({
  palette: {
    myTextColor: {
      main: '#050505',
      primary: '#fff',
      secondary: '#65676B',
      textButtonSelected: '#1871ed',
      textIcon: '#828282'
    },
    myBackgroundColor: {
      main: '#f1f2f5',
      primary: '#fff',
      //SideNav color
      secondary: '#373737'
    },
    myButtonColor: {
      main: 'rgba(0,0,0,0.05)',

      // Button selected1 (Grey)
      primary: '#5e5e5e',
      hover1: '#282828',

      // Button selected2 (Blue)
      secondary: '#E7F1FE',
      hover2: 'rgba(0, 0, 0, 0.04)'
    }
  },
  typography: {
    fontFamily: 'Segoe UI, Roboto, Tahoma, Geneva, Verdana, sans-serif'
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Segoe UI, Roboto, Tahoma, Geneva, Verdana, sans-serif'
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: 'Segoe UI, Roboto, Tahoma, Geneva, Verdana, sans-serif'
        }
        // same for other variants
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: 'Segoe UI, Roboto, Tahoma, Geneva, Verdana, sans-serif'
        }
      }
    }
  }
});
