import { apiGet } from './apis';
import {
  apiDeleteMarket,
  apiGetMarket,
  apiPatchMarket,
  apiPostMarket
} from './apisMarket';

export const getCalendarCourseApi = async (params: any) => {
  return apiGetMarket('courses', params, null);
};

export const getCourseOfPageApi = async (params: any) => {
  return apiGetMarket(`pages/${params.pageId}/courses`, params, null);
};

export const getListCourseJoined = async (params: any) => {
  return apiGetMarket('list_joined_course', params, null);
};

export const createUpdateCourseApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('benefits', data.benefits);
  formdata.append('target_audience', data.target_audience);
  formdata.append('address', data.address);
  formdata.append('allow_discussion', data.allow_discussion);
  if (data.title) {
    formdata.append('title', data.title);
  }
  if (data.course_category_id) {
    formdata.append('course_category_id', data.course_category_id);
  }
  if (data.banner) {
    if (data.banner.file?.id) {
      formdata.append('banner[id]', data.banner.file?.id);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
      formdata.append('banner[show_url]', data.banner.show_url ?? null);
    }
  }
  if (data.introduction_video) {
    if (data.introduction_video.file) {
      formdata.append(
        'introduction_video[id]',
        data.introduction_video.file?.id
      );
    }
    if (data.introduction_video.id) {
      formdata.append('introduction_video[id]', data.introduction_video.id);
      formdata.append(
        'introduction_video[show_url]',
        data.introduction_video.show_url
      );
    }
  }
  if (data.description_course) {
    formdata.append('description_course', data.description_course);
  }
  if (data.description_company) {
    formdata.append('description_company', data.description_company);
  }
  if (data.refund_policy) {
    formdata.append('refund_policy', data.refund_policy);
  }
  formdata.append('price', data.price === null ? 0 : data.price);
  formdata.append('free', data.free);
  if (data.course_host_added) {
    for (var i = 0; i < data.course_host_added.length; i++) {
      formdata.append('course_host_added[]', data.course_host_added[i]);
    }
  }
  if (data.course_host_removed) {
    for (var i = 0; i < data.course_host_removed.length; i++) {
      formdata.append('course_host_removed[]', data.course_host_removed[i]);
    }
  }
  if (data.phone_number) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.age_restrictions) {
    formdata.append('age_restrictions', data.age_restrictions);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }

  if (type === 'create') return apiPostMarket('courses', null, formdata);

  return apiPatchMarket(`courses/${type}`, null, formdata);
};

export const getDetailCourseApi = async (idCourse: any) => {
  return apiGetMarket(`courses/${idCourse}`, null, null);
};

export const getCourseCategories = async () => {
  return apiGetMarket('course_categories', null, null);
};

export const deleteCourseApi = async (id: any) => {
  return apiDeleteMarket(`courses/${id}`, null, null);
};

export const getTimelineCourseApi = async (idCourse: any, params: any) => {
  return apiGet(`timelines/course/${idCourse}`, params, null);
};

export const createInviteCourse = async (idCourse: any, params: any) => {
  return apiPostMarket(`courses/${idCourse}/invitations`, null, {
    target_account_ids: params
  });
};

export const interesteCourseApi = async (idCourse: any) => {
  return apiPostMarket(
    `courses/${idCourse}/course_followers`,

    null,
    null
  );
};
export const noInteresteCourseApi = async (idCourse: any) => {
  return apiDeleteMarket(`courses/${idCourse}/course_followers`, null, null);
};

export const getListInvitation = async (params: any) => {
  return apiGetMarket('course_invitations', params, null);
};

export const getListInvitationHostCourse = async (params: any) => {
  return apiGetMarket('course_invitation_hosts', params, null);
};
export const getSuggestionsCourse = async (params: any) => {
  return apiGetMarket('suggestions/course', params, null);
};

export const respondInvitationCourse = async (idCourse: any, params: any) => {
  return apiPostMarket(
    `courses/${idCourse}/invitations/invitations_respond`,
    null,
    params
  );
};

export const getListHostOfCourse = async (idCourse: any, params: any) => {
  return apiGetMarket(`courses/${idCourse}/invitation_hosts`, params, null);
};

export const respondInvitationHostCourse = async (idCourse: any, data: any) => {
  return apiPostMarket(
    `courses/${idCourse}/invitation_hosts/invitation_respond`,

    null,
    data
  );
};

export const getListHostCourse = async (idCourse: any, params: any) => {
  return apiGetMarket(`courses/${idCourse}/hosts`, params, null);
};

export const getListFaqs = async (idCourse: any) => {
  return apiGetMarket(`courses/${idCourse}/course_faqs`, null, null);
};

export const CRUDFaqs = async (type, idCourse: any, params: any, data) => {
  if (type === 'patch')
    return apiPatchMarket(
      `courses/${idCourse}/course_faqs/${params}`,
      null,
      data
    );

  if (type === 'delete')
    return apiDeleteMarket(
      `courses/${idCourse}/course_faqs/${params}`,
      null,
      data
    );

  return apiPostMarket(`courses/${idCourse}/course_faqs`, null, data);
};

export const getCourseContentLibrary = async (param?: any) => {
  return apiGetMarket('course_content_library', param, null);
};

export const getDetailLesson = async (idLesson: any, course_id: any) => {
  return apiGetMarket(
    `course_lessons/${idLesson}?course_id=${course_id}`,
    null,
    null
  );
};

export const CRUDChapters = async (
  type,
  idCourse: any,
  idChapter: any,
  params
) => {
  if (type === 'patch')
    return apiPatchMarket(
      `courses/${idCourse}/course_chapters/${idChapter}`,
      params,
      null
    );

  if (type === 'delete')
    return apiDeleteMarket(
      `courses/${idCourse}/course_chapters/${idChapter}`,
      params,
      null
    );

  if (type === 'post')
    return apiPostMarket(`courses/${idCourse}/course_chapters`, params, null);

  return apiGetMarket(`courses/${idCourse}/course_chapters`, params, null);
};

export const CRUDLessons = async (type, idLesson: any, params: any, data) => {
  if (type === 'patch')
    return apiPatchMarket(`course_lessons/${idLesson}`, params, data);

  if (type === 'delete')
    return apiDeleteMarket(`course_lessons/${idLesson}`, params, data);

  if (type === 'post') return apiPostMarket(`course_lessons`, params, data);

  return apiGetMarket(`course_lessons`, params, data);
};

export const paymentCourse = async (idCourse: any) => {
  return apiPostMarket(`courses/${idCourse}/payment_course`, null, {
    detail_type: 'payment_course'
  });
};

export const sendApproval = async (idCourse: any) => {
  return apiPostMarket(`courses/${idCourse}/send_approval`, null, null);
};

export const getBalance = async () => {
  return apiGetMarket(`transactions`, null, null);
};

export const rechargeEcoin = async (data: any) => {
  return apiPostMarket(`transfer`, null, data);
};

export const ratingCourse = async (idCourse: any, data: any, type: any) => {
  if (type === 'create')
    return apiPostMarket(`courses/${idCourse}/course_rating`, null, data);

  return apiPatchMarket(
    `courses/${idCourse}/course_rating/${type}`,
    null,
    data
  );
};

export const listRatingCourse = async (idCourse: any) => {
  return apiGetMarket(
    `courses/${idCourse}/course_rating`,

    null,
    null
  );
};

export const delRatingCourse = async (idCourse: string, idRating: string) => {
  return apiDeleteMarket(
    `courses/${idCourse}/course_rating/${idRating}`,

    null,
    null
  );
};

export const getListPaid = async idCourse => {
  return apiGetMarket(`courses/${idCourse}/list_participants`, null, null);
};

export const reportCourseApi = (data: any) => {
  return apiPostMarket(`report_violations`, null, data);
};

export const sendComplainMarketPlace = data => {
  return apiPostMarket('complain_reports', null, data);
};