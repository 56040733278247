import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Typography, Grid, Chip } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import SocialPost from 'src/components/SocialPost';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postSelectedAction } from 'src/store/action/socialWatchAction';
import { getListWatchApi, getDiscoverWatchApi } from 'src/apis/socialPost.api';

import { collapseString } from 'src/common/string';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 20,
    backgroundColor: theme.palette.button.primary.background,
    [theme.breakpoints.down(1300)]: {
      flexDirection: 'column'
    }
  },
  left: { minWidth: 900, padding: '0px 20px' },
  right: {
    width: 490,
    marginTop: 20,
    padding: '0px 16px'
  },
  title: {
    fontSize: '17px !important',
    fontWeight: '600 !important'
  },
  wrapBox: {
    height: 100,
    borderRadius: 8,
    border: '1px solid rgba(0,0,0,0.1)',
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  titleBox: {
    fontSize: '17px !important',
    fontWeight: '500 !important',
    color: '#fff'
  },
  chipSelected: {
    marginRight: '10px !important',
    border: '1px solid rgba(0,0,0,0.05) !important',
    backgroundColor: '#e7f3ff !important'
  },
  chip: {
    marginRight: '10px !important',
    border: '1px solid rgba(0,0,0,0.05) !important'
  },
  textChip: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  textChipSelected: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    color: buttonColor.backgroundColor
  },
  videoItem: {
    height: 130,
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  video: {
    height: 130,
    width: 210,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    borderRadius: 8
  },
  content: {
    width: 'calc(100% - 220px)',
    marginLeft: 10
  }
}));

const FeedScreen = props => {
  const classes = useStyles();
  const { widthLeft, setOpen, scrollToTop } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [keyOption, setKeyOption] = React.useState<any>('new');
  const [listNewVideo, setListNewVideo] = React.useState<any>([]);
  const [listDiscoverVideo, setListDisCoverVideo] = React.useState<any>([]);
  const [followPageId, setFollowPageId] = React.useState<string>();
  const postWatchSelected = useSelector(
    (state: any) => state.watchReducer.postSelected
  );
  const listMenuWatch = [
    {
      icon: 'fa-solid fa-display',
      id: 'watch',
      label: 'Trang chủ',
      color: buttonColor.backgroundColor
    },
    {
      icon: 'fa-solid fa-clapperboard',
      id: 'watch-live',
      label: 'Trực tiếp',
      color: '#f0284a'
    },
    {
      icon: 'fa-solid fa-clapperboard-play',
      id: 'watch-shows',
      label: 'Chương trình',
      color: '#2abba7'
    }
  ];

  const listOption = [{ id: 'new', label: 'Video mới nhất' }];

  const fetchData = async (type, params) => {
    let response;
    if (type === 'discover') {
      response = await getDiscoverWatchApi(params);
    } else if (type === 'video-new') {
      response = await getListWatchApi({
        limit: 3
      });
    }

    if (response.status === 200) {
      if (type === 'discover') {
        setListDisCoverVideo(prev => [...prev, ...response.data]);
      } else if (type === 'video-new') {
        setListNewVideo(response.data);
      }
    }
  };

  const funcLoad = () => {
    let postLast = [...listDiscoverVideo].pop();
    let maxId = postLast?.score ?? postLast?.id;
    fetchData('discover', {
      max_id: maxId,
      limit: 2
    });
  };

  const updateFollowingStatus = (page_id: string) => {
    const updatedData = listDiscoverVideo.map(item => {
      if (item.page && item.page.id === page_id) {
        return {
          ...item,
          page: {
            ...item.page,
            page_relationship: {
              ...item.page.page_relationship,
              following: true
            }
          }
        };
      }
      return item;
    });

    setListDisCoverVideo(updatedData);
  };

  React.useEffect(() => {
    if (!listDiscoverVideo.length) {
      funcLoad();
    }
    fetchData('video-new', {});
  }, []);

  React.useEffect(() => {
    if (followPageId) {
      updateFollowingStatus(followPageId);
    }
  }, [followPageId]);


  let hasMore = useSelector((state: any) => {
    return state.socialPostReducer?.hasMore;
  });

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleClick = key => {
    if (key === 'watch') {
      history.push('/watch');
    } else {
      history.push(`/watch/${key}`);
    }
    setOpen && setOpen(false);
    dispatch(postSelectedAction({}));
  };

  return (
    <div className={classes.root}>
      <div style={{ width: widthLeft }} className={classes.left}>
        <InfiniteScroll
          dataLength={listDiscoverVideo.length}
          next={funcLoad}
          hasMore={hasMore}
          loader={''}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scroll-watch"
          scrollThreshold={0.85}
        >
          <div>
            {listDiscoverVideo.length
              ? listDiscoverVideo?.map((el: any, index) =>
                  el?.id !== postWatchSelected?.id ? (
                    <React.Fragment key={el.id}>
                      <SocialPost
                        key={el.id}
                        post={el}
                        type="watch"
                        scrollToTop={scrollToTop}
                        setFollowPageId={setFollowPageId}
                      />
                    </React.Fragment>
                  ) : null
                )
              : null}
          </div>
        </InfiniteScroll>
      </div>
      <div className={classes.right}>
        <Typography className={classes.title}>
          Khám phá thêm video trên Watch
        </Typography>
        <div style={{ width: ' 100%', marginTop: 20 }}>
          <Grid container spacing={2}>
            {listMenuWatch?.map((el: any) => (
              <Grid key={el.id} item xs={6}>
                <div
                  onClick={() => handleClick(el.id)}
                  style={{ backgroundColor: `${el.color}` }}
                  className={classes.wrapBox}
                >
                  <Typography className={classes.titleBox}>
                    <i style={{ fontSize: 22 }} className={el.icon}></i>
                  </Typography>
                  <Typography className={classes.titleBox}>
                    {el.label}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        <Divider style={{ margin: '15px 0px' }} />

        <div>
          {listOption?.map((el: any) => (
            <Chip
              className={
                keyOption === el.id ? classes.chipSelected : classes.chip
              }
              key={el.id}
              label={
                <Typography
                  className={
                    keyOption === el.id
                      ? classes.textChipSelected
                      : classes.textChip
                  }
                >
                  {el.label}
                </Typography>
              }
              onClick={() => setKeyOption(el.id)}
            />
          ))}
        </div>

        <div style={{ height: 10 }}></div>
        {listNewVideo?.map((el: any, index) =>
          el.id !== postWatchSelected?.id ? (
            <div
              key={el.id}
              onClick={() => {
                history.push(`/watch?v=${el.media_attachments?.[0]?.id}`);
                dispatch(postSelectedAction(el));
                scrollToTop();
              }}
              className={classes.videoItem}
            >
              <div
                className={classes.video}
                style={{
                  backgroundImage: `url(${el.media_attachments?.[0]?.preview_url})`
                }}
              >
                <div
                  style={{
                    padding: '0px 1px',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'inline-block',
                    position: 'absolute',
                    bottom: 5,
                    right: 5
                  }}
                >
                  <Typography style={{ color: '#fff', fontSize: 14 }}>
                    {formatDuration(
                      Math.ceil(
                        el.media_attachments?.[0]?.meta?.original?.duration
                      )
                    )}
                  </Typography>
                </div>
              </div>
              <div className={classes.content}>
                <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                  {collapseString(el.content, 55)}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#65676b',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5
                  }}
                >
                  <AvatarSocial
                    type="feed"
                    avatarObj={el.page?.avatar_media}
                    style={{ height: 24, width: 24 }}
                  />{' '}
                  &nbsp; {collapseString(el.page?.title, 50)}
                </Typography>
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default FeedScreen;
