import React from 'react';
import Media from './Media';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
import SocialContent from 'src/components/SocialPost/SocialContent';
import FeedMoment from 'src/pages/Moment/FeedMoment';
import MomentItem from 'src/pages/Moment/MomentItem';
const Share = props => {
  const { post, type, typePost } = props;
  let postReblog = type ? post : post.reblog;

  const renderMedia = () => {
    if (
      postReblog.media_attachments.length &&
      postReblog?.post_type !== 'moment'
    ) {
      return (
        <Media
          key={`media-reblog-${post.id}`}
          post={postReblog}
          type="share_post"
          styleBox={{
            overflow: 'hidden',
            borderTopRightRadius: '11px',
            borderTopLeftRadius: '11px'
          }}
          styleImage={{
            borderTop: 'unset !important',
            borderBottom: 'unset !important'
          }}
        />
      );
    } else if (postReblog?.post_type === 'moment') {
      // ui share moment
      return (
        //   <MomentItem
        //     moment={postReblog}
        //     typeShare={'postShareMoment'}
        //   />

        <FeedMoment
          moment={postReblog}
          type={'postMoment'}
          typePost={typePost}
          typeShare={'postShareMoment'}
          notShowData={true}
        />
      );
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: type && type === 'share' ? '470px' : '100% !important',
        borderRadius: '10px',
        border: '1px solid rgba(0,0,0,0.2)',
        margin: type ? 0 : '0 20px'
      }}
    >
      {renderMedia()}
      <div
        style={{
          width: '100%',
          height: 'auto'

          // border: '1px solid rgba(0,0,0,0.2)',
          // borderTop: postReblog.media_attachments.length
          //   ? 0
          //   : '1px solid rgba(0,0,0,0.2)',
          // marginTop: '0px'
        }}
      >
        <SocialPostInformation
          key={`${postReblog.id}-postInformation`}
          post={postReblog}
          type="sharePost"
          typePost={typePost}
        />
        <SocialContent
          key={`${postReblog.id}-socialContent`}
          post={postReblog}
          type="sharePost"
          postShare={post?.reblog}
          typePost={typePost}
        />
      </div>
    </div>
  );
};

export default Share;
