import { apiDelete, apiGet, apiPatch, apiPost } from './apis';

export const createUpdateEventApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  formdata.append('description', data.description ?? '');
  for (var i = 0; i < data.event_host_added.length; i++) {
    formdata.append('event_host_added[]', data.event_host_added[i]);
  }
  for (let i = 0; i < data.event_host_removed.length; i++) {
    formdata.append('event_host_removed[]', data.event_host_removed[i]);
  }
  if (data.banner) {
    if (data.banner.file && !data.banner.id) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    if (data.banner.show_url) {
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.category_id) {
    formdata.append('category_id', data.category_id);
  }
  if (data.start_time) {
    formdata.append('start_time', data.start_time);
  }
  if (data.end_time) {
    formdata.append('end_time', data.end_time);
  }
  if (data.event_type) {
    formdata.append('event_type', data.event_type);
  }
  if (data.location) {
    formdata.append('id', data.location.id);
    formdata.append('location[lat]', data.location.location.lat);
    formdata.append('location[lng]', data.location.location.lng);
    formdata.append('address', data.location.title);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }

  if (type === 'create') return apiPost('events', null, formdata);

  return apiPatch(`events/${type}`, null, formdata);
};

export const getCalendarEventApi = async (params: any) => {
  return apiGet('events', params, null);
};
export const getEventCategories = async () => {
  return apiGet('categories', null, null);
};
export const getPageDetailEvent = async (params: any) => {
  return apiGet('events', params, null);
};
export const getEventCategoriesFilter = async (params: any) => {
  return apiGet('events', params, null);
};
export const getDetailEventApi = async (idEvent: any) => {
  return apiGet(`events/${idEvent}`, null, null);
};

export const deleteEventApi = async (id: any) => {
  return apiDelete(`events/${id}`, null, null);
};

export const shareEventApi = async (params: any, idEvent: any) => {
  return apiPost(`events/${idEvent}/share`, params, null);
};

export const getTimelineEventApi = async (idEvent: any, params: any) => {
  return apiGet(`timelines/event/${idEvent}`, params, null);
};

export const createInviteEvent = async (idEvent: any, params: any) => {
  return apiPost(`events/${idEvent}/invitations`, null, {
    target_account_ids: params
  });
};

export const joinEventApi = async (idEvent: any, params: any) => {
  return apiPost(`events/${idEvent}/accounts`, null, params);
};

export const getListInvitation = async (params: any) => {
  return apiGet('event_invitations', params, null);
};

export const respondInvitationEvent = async (idEvent: any, params: any) => {
  return apiPost(
    `events/${idEvent}/invitations/invitations_respond`,

    null,
    params
  );
};

export const getListHostOfEvent = async (idEvent: any, params: any) => {
  return apiGet(
    `/events/${idEvent}/invitation_hosts`,

    params,
    null
  );
};

export const respondInvitationHostEvent = async (idEvent: any, params: any) => {
  return apiPost(
    `events/${idEvent}/invitation_hosts/invitations_respond`,
    null,
    params
  );
};

export const getListHostEvent = async (idEvent: any, params: any) => {
  return apiGet(`events/${idEvent}/hosts`, params, null);
};

export const getListInvitationHost = async (params?: any) => {
  return apiGet('event_invitation_hosts', params, null);
};

export const getListMemberBirthdays = async () => {
  return apiGet('birthdays/index', null, null);
};

export const getListMemberBirthdaysToday = async () => {
  return apiGet('birthdays/today', null, null);
};

export const getListMemberBirthdaysRecent = async () => {
  return apiGet('birthdays/recent', null, null);
};

export const getListMemberBirthdaysUpcoming = async () => {
  return apiGet('birthdays/upcoming', null, null);
};

export const getMediaEvent = async (eventId: string, params: any) => {
  return apiGet(`events/${eventId}/media_attachments`, params, null);
};

export const getEventSuggestion = async (params: any) => {
  return apiGet(`suggestions/event`, params, null);
};

export const getUserInterestedOrGoingEvent = async (
  idEvent: any,
  params: any
) => {
  return apiGet(`events/${idEvent}/accounts`, params, null);
};
