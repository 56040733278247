export const PAGE_PREVIEW_INFO = 'PAGE_PREVIEW_INFO';
export const ROLE_EDIT_PREVIEW_DETAIL_USER = 'ROLE_EDIT_PREVIEW_DETAIL_USER';
export const ROLE_EDIT_PREVIEW_DETAIL_PAGE = 'ROLE_EDIT_PREVIEW_DETAIL_PAGE';
export const GET_PAGE_DETAIL_SUCCESS = 'GET_PAGE_DETAIL_SUCCESS';
export const SET_PAGE_TARGET = 'SET_PAGE_TARGET';
export const RESET_PAGE_TARGET = 'RESET_PAGE_TARGET';
export const GET_PAGE_DETAIL_ERROR = 'GET_PAGE_DETAIL_ERROR';
export const GET_PAGE_DETAIL_REQ = 'GET_PAGE_DETAIL_REQ';

export const GET_LIST_MY_PAGE_REQ = 'GET_LIST_MY_PAGE_REQ';
export const GET_LIST_MY_PAGE_SUCCESS = 'GET_LIST_MY_PAGE_SUCCESS';
export const FILTER_LIST_MY_PAGE = 'FILTER_LIST_MY_PAGE';
export const LOADING_LIST_PAGE = 'LOADING_LIST_PAGE';
export const RESET_LIST_PAGE = 'RESET_LIST_PAGE';

export const GET_SETTING_PAGE_SUCCESS = 'GET_SETTING_PAGE_SUCCESS';
export const GET_SETTING_PAGE_REQ = 'GET_SETTING_PAGE_REQ';

export const UPDATE_LOCATION_PAGE = 'UPDATE_LOCATION_PAGE';
export const UPDATE_HAS_MORE = 'UPDATE_HAS_MORE';
export const RESET_PAGE = 'RESET_PAGE';
export const GET_TOTAL_CENSORSHIP_DIARY = 'GET_TOTAL_CENSORSHIP_DIARY';
export const UPDATE_TOTAL_CENSORSHIP_DIARY = 'UPDATE_TOTAL_CENSORSHIP_DIARY';

export const UPDATE_PAGE_NAME_NOTIFY_VERIFY = 'UPDATE_PAGE_NAME_NOTIFY_VERIFY';
