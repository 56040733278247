import axios, { AxiosRequestConfig } from 'axios';
import { BASE_ROOT, USER_TOKEN } from 'src/util/config';
import { apiGet, apiPost } from './apis';

export const getInfoMe = async (token?: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/me`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN === '' && token ? token : USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const updateThemeApi = async params => {
  return apiPost('theme', params, null);
};

export const requestResetPasswordApi = async data => {
  return apiPost('forgot_password', null, data);
};

export const requestVerifyOtpPasswordApi = async data => {
  return apiPost('verify_otp', null, data);
};

export const sendEmailResetPasswordApi = async data => {
  return apiPost('reconfirmation', null, data);
};

export const changePasswordApi = async data => {
  return apiPost('reset_password', null, data);
};

export const registrationAccountApi = async data => {
  return apiPost('registrations', null, data);
};

export const checkEmailUniqueApi = async params => {
  return apiGet('validate_email', params, null);
};

export async function signInGoogle(data) {
  return apiPost('authorization', null, data);
}
