import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import { useMeasure } from 'react-use';
import ModalView3D from 'src/components/ImageGrid/components/ModalView3D';
import WatchPlayer from 'src/components/VideoPlayer/WatchPlayer';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
    fontSize: '40px !important',
    fontWeight: '600 !important',
    textAlign: 'center',
    width: '100%'
  },
  iconPlay: {
    fontSize: '60px !important',
    color: '#ffffff9c'
  },
  button: {}
}));

const PictureGrid = props => {
  const classes = useStyles();

  const { images, imagesType, medias, typeAction, type, renderButtonClose } =
    props;

  const [refImg, { width }] = useMeasure();
  const isAVideo = path => {
    if (path?.includes('video')) {
      return true;
    }

    return false;
  };
  const hasAVideo = paths => {
    var hasVideo = false;
    paths.map(path => {
      if (path?.includes('video')) {
        hasVideo = true;
      }
    });

    return hasVideo;
  };

  const getAspect = media => {
    return media.width / media.height;
  };

  const renderWatch = (video, index) => {
    return typeAction === 'update' ? (
      <WatchPlayer
        type="video"
        video={video}
        typePage="post"
        style={{ borderRadius: '10px' }}
      />
    ) : (
      <Box sx={{ width: '100%' }}>
        <video
          style={{
            backgroundColor: '#000',
            borderRadius: '10px',
            position: 'relative'
          }}
          className={classes.button}
          width="100%"
          height="100%"
          src={video}
        />
      </Box>
    );
  };

  const renderVideo = (item, index) => {
    if (images.length === 2) {
      if (isAVideo(imagesType[index])) {
        return (
          <Grid key={index} item xs={6} md={6} lg={6} xl={6} sm={6}>
            <Grid
              container
              direction="row"
              justify="center"
              height="100%"
              sx={{
                position: type === 'multiple_item' ? 'relative' : 'static'
              }}
            >
              {renderWatch(item?.preview ?? item?.url ?? item, index)}
              {typeAction !== 'update' && (
                <PlayCircleOutlineIcon
                  className={classes.iconPlay}
                  sx={{
                    position: 'absolute',
                    top: '32%',
                    left: index === 0 ? '20%' : '70%',
                    zIndex: '1000'
                  }}
                />
              )}
              {renderButtonClose && renderButtonClose(item)}
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
          <Grid
            container
            direction="row"
            justify="center"
            style={{
              backgroundImage: `url(${
                typeAction === 'update'
                  ? item.url ?? item.preview
                  : item?.preview ?? item?.url ?? item
              }`,
              width: 'auto',
              height:
                getAspect(medias[0]) < 1 && getAspect(medias[1]) < 1
                  ? 433
                  : 240,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '10px'
            }}
            className={classes.button}
          ></Grid>
          {renderButtonClose && renderButtonClose(item)}
        </Grid>
      );
    } else if (images.length === 3) {
      if (index != 0) {
        if (isAVideo(imagesType[index])) {
          return (
            <Grid key={index} item xs={6}>
              <Grid
                container
                direction="row"
                justify="center"
                height="100%"
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                {renderWatch(item?.preview ?? item?.url ?? item, index)}
                {typeAction !== 'update' && (
                  <PlayCircleOutlineIcon
                    className={classes.iconPlay}
                    sx={{
                      position: 'absolute',
                      top: index === 0 ? '32%' : '75%',
                      left: index === 0 ? '45%' : index === 1 ? '18%' : '70%',
                      zIndex: '1000'
                    }}
                  />
                )}
                {renderButtonClose && renderButtonClose(item)}
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid
              key={index}
              item
              xs={6}
              sx={{
                position: type === 'multiple_item' ? 'relative' : 'static'
              }}
            >
              <Grid
                key={index}
                container
                direction="row"
                justify="center"
                style={{
                  backgroundImage: `url(${
                    typeAction === 'update'
                      ? item.url ?? item.preview
                      : item?.preview ?? item?.url ?? item
                  }`,
                  width: '100%',
                  height: 199,
                  marginBottom: 2,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
                className={classes.button}
              ></Grid>
              {renderButtonClose && renderButtonClose(item)}
            </Grid>
          );
        }
      }
    } else if (images.length === 4) {
      if (index != 0) {
        if (isAVideo(imagesType[index])) {
          return (
            <Grid
              key={index}
              item
              md={12}
              lg={12}
              xl={12}
              xs={12}
              sm={12}
              sx={{ padding: '1px !important' }}
            >
              <Grid
                container
                direction="row"
                justify="center"
                height="133px"
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                {renderWatch(item?.preview ?? item?.url ?? item, index)}
                {renderButtonClose && renderButtonClose(item)}
              </Grid>
            </Grid>
          );
        } else if (index >= 1 && index <= 3 && !isAVideo(imagesType[index])) {
          return (
            <Grid
              key={index}
              sx={{
                padding: '1px !important',
                position: type === 'multiple_item' ? 'relative' : 'static'
              }}
              item
              xs={12}
            >
              <Grid
                container
                direction="row"
                justify="center"
                style={{
                  backgroundImage: `url(${
                    typeAction === 'update'
                      ? item.url ?? item.preview
                      : item?.preview ?? item?.url ?? item
                  }`,
                  width: 'auto',
                  height: 190,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  borderRadius: '10px'
                }}
                className={classes.button}
              ></Grid>
              {renderButtonClose && renderButtonClose(item)}
            </Grid>
          );
        }
      } else {
        if (getAspect(medias[0]) > 1) {
          return (
            <Grid key={index} spacing={0.1} container>
              <Grid spacing={0.2} container item xs={6}>
                {[0, 1]?.map(el => (
                  <Grid key={el} item xs={12}>
                    {isAVideo(imagesType[el]) ? (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        sx={{
                          position:
                            type === 'multiple_item' ? 'relative' : 'static'
                        }}
                      >
                        {renderWatch(
                          typeAction === 'update'
                            ? medias[el]
                            : images[el]?.preview ??
                                images[el]?.url ??
                                images[el]
                        )}
                        {renderButtonClose && renderButtonClose(images[el])}
                      </Grid>
                    ) : (
                      <Grid
                        sx={{
                          position:
                            type === 'multiple_item' ? 'relative' : 'static'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          sx={{
                            padding: '1px !important',
                            position:
                              type === 'multiple_item' ? 'relative' : 'static'
                          }}
                          style={{
                            width: '100%',
                            backgroundImage: `url(${
                              typeAction === 'update'
                                ? medias[el]
                                : images[el]?.preview ??
                                  images[el]?.url ??
                                  images[el]
                            }`,
                            height: 233,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                          }}
                          className={classes.button}
                        ></Grid>
                        {renderButtonClose && renderButtonClose(images[el])}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={6}>
                {images.map((image, index) => {
                  if (index >= 1 && index <= 3) {
                    return (
                      <Grid
                        key={`${item.url} + ${index}`}
                        sx={{ padding: '1px !important' }}
                        item
                        xs={12}
                      >
                        {isAVideo(imagesType[index]) ? (
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            sx={{
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                          >
                            {renderWatch(
                              typeAction === 'update'
                                ? medias[0]
                                : images[0]?.preview ??
                                    images[0]?.url ??
                                    images[0]
                            )}
                            {renderButtonClose && renderButtonClose(images[0])}
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              style={{
                                backgroundImage: `url(${
                                  typeAction === 'update'
                                    ? item.url
                                    : item?.preview ?? item?.url ?? item
                                }`,
                                width: '100%',
                                height: 155,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                              className={classes.button}
                            ></Grid>
                            {renderButtonClose && renderButtonClose(item)}
                          </Grid>
                        )}
                      </Grid>
                    );
                  } else if (index === 4) {
                    return (
                      <Grid key={index} item xs={12}>
                        {isAVideo(imagesType[index]) ? (
                          <Grid
                            style={{
                              position: 'relative',
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                            container
                            direction="row"
                            justify="center"
                          >
                            <video
                              style={{
                                backgroundColor: '#000',
                                borderRadius: '10px'
                              }}
                              className={classes.button}
                              width="100%"
                              height="155"
                              src={
                                images[0]?.preview ??
                                images[0]?.url ??
                                images[0]
                              }
                            />
                            {renderButtonClose && renderButtonClose(images[0])}

                            {images.length - index - 1 ? (
                              <Typography
                                style={{
                                  position: 'absolute',
                                  top: 50
                                }}
                                className={classes.title}
                              >
                                +{images.length - index - 1}
                              </Typography>
                            ) : null}
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                          >
                            <Grid
                              container
                              sx={{ padding: '1px !important' }}
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{
                                background: 'gray',
                                width: '100%',
                                height: 155,
                                cursor: 'pointer',

                                backgroundImage: `url(${
                                  typeAction === 'update'
                                    ? medias[index]
                                    : images[index]?.preview ??
                                      images[index]?.url ??
                                      images[index]
                                })`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'none'
                              }}
                              className={classes.button}
                            >
                              {images.length - index - 1 ? (
                                <Typography className={classes.title}>
                                  +{images.length - index - 1}
                                </Typography>
                              ) : null}
                            </Grid>
                            {renderButtonClose &&
                              renderButtonClose(images[index])}
                          </Grid>
                        )}
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </Grid>
          );
        }
      }
    } else if (images.length > 4) {
      if (index >= 2 && index <= 3) {
        return (
          <Grid
            key={index}
            sx={{ padding: '1px !important' }}
            item
            xs={imagesType[index] === 'video' ? 6 : 4}
          >
            {isAVideo(imagesType[index]) ? (
              <Grid
                container
                direction="row"
                justify="center"
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                {renderWatch(
                  typeAction === 'update'
                    ? medias[index]
                    : images[index]?.preview ??
                        images[index]?.url ??
                        images[index]
                )}
                {renderButtonClose && renderButtonClose(images[index])}
                {typeAction !== 'update' && (
                  <PlayCircleOutlineIcon
                    className={classes.iconPlay}
                    sx={{
                      position: 'absolute',
                      top: '66%',
                      left: index === 2 ? '10%' : '43%',
                      zIndex: '1000'
                    }}
                  />
                )}
              </Grid>
            ) : (
              <Grid
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{
                    backgroundImage: `url(${
                      typeAction === 'update'
                        ? item.url ?? item.preview
                        : item?.preview ?? item?.url ?? item
                    }`,
                    width: 'auto',
                    height: 190,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderRadius: '10px'
                  }}
                  className={classes.button}
                ></Grid>
                {renderButtonClose && renderButtonClose(item)}
              </Grid>
            )}
          </Grid>
        );
      } else if (index === 4) {
        return (
          <Grid key={4} item xs={imagesType[index] === 'video' ? 12 : 4}>
            {isAVideo(imagesType[4]) ? (
              <Grid
                style={{ position: 'relative' }}
                container
                direction="row"
                justify="center"
              >
                {renderWatch(
                  typeAction === 'update'
                    ? medias[4]
                    : images[4]?.preview ?? images[4]?.url ?? images[4]
                )}
                {typeAction !== 'update' && (
                  <PlayCircleOutlineIcon
                    className={classes.iconPlay}
                    sx={{
                      position: 'absolute',
                      top: '18%',
                      left: '32%',
                      zIndex: '1000'
                    }}
                  />
                )}
                {renderButtonClose && renderButtonClose(images[4])}

                {images.length - index - 1 ? (
                  <Typography
                    style={{
                      position: 'absolute',
                      zIndex: 1500,
                      top: 70,
                      height: 190
                    }}
                    className={classes.title}
                  >
                    +{images.length - index - 1}
                  </Typography>
                ) : null}
              </Grid>
            ) : (
              <Grid
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                <Grid
                  container
                  sx={{
                    padding: '1px !important'
                  }}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{
                    background: 'gray',
                    width: 'auto',
                    height: 190,
                    cursor: 'pointer',
                    backgroundImage: `url(${
                      images[index]?.preview ??
                      images[index]?.url ??
                      images[index]
                    })`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none',
                    borderRadius: '10px'
                  }}
                  className={classes.button}
                >
                  {images.length - index - 1 ? (
                    <Typography className={classes.title}>
                      +{images.length - index - 1}
                    </Typography>
                  ) : null}
                </Grid>
                {renderButtonClose && renderButtonClose(images[index])}
              </Grid>
            )}
          </Grid>
        );
      } else {
        return null;
      }
    }
  };

  const displayImage = images => {
    if (images) {
      if (images.length === 1) {
        if (isAVideo(imagesType[0])) {
          return (
            <Grid container direction="row" justify="center">
              {renderWatch(
                typeAction === 'update'
                  ? medias[0]
                  : images[0]?.preview ?? images[0]?.url ?? images[0]
              )}
              {typeAction !== 'update' && (
                <PlayCircleOutlineIcon
                  className={classes.iconPlay}
                  sx={{
                    position: 'absolute',
                    top: '42%',
                    left: '42%',
                    zIndex: '1000'
                  }}
                />
              )}
              {renderButtonClose &&
                renderButtonClose(
                  typeAction === 'update' ? medias[0] : images[0]
                )}
            </Grid>
          );
        } else {
          return (
            <div
              style={{
                maxHeight: '635px',
                overflow: 'hidden'
              }}
            >
              <img
                style={{
                  borderRadius: '10px',
                  width: '100%'
                }}
                src={images[0]?.preview ?? images[0]?.url ?? images[0]}
                alt="images"
              />
              {renderButtonClose && renderButtonClose(images[0])}
            </div>
          );
        }
      } else if (images.length === 2) {
        if (hasAVideo(imagesType)) {
          return (
            <Grid spacing={0.5} container>
              {typeAction === 'update'
                ? medias.map((item, index) => renderVideo(item, index))
                : images.map((image, index) => renderVideo(image, index))}
            </Grid>
          );
        } else if (isNaN(getAspect(medias[0])) || isNaN(getAspect(medias[1]))) {
          return (
            <Grid container>
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      padding: '1px !important',
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                    item
                    xs={6}
                    ref={refImg}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          image?.preview ?? image?.url ?? image
                        })`,
                        width: '100%',
                        height: width,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                );
              })}
            </Grid>
          );
        } else if (getAspect(medias[0]) < 1) {
          return (
            <Grid container>
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      padding: '1px !important',
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                    item
                    md={6}
                    lg={6}
                    xl={6}
                    xs={6}
                    sm={6}
                    ref={refImg}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          image?.preview ?? image?.url ?? image
                        }`,
                        width: '100%',
                        height: width / getAspect(medias[0]),
                        maxHeight: '400px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        borderRadius: '10px'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                );
              })}
            </Grid>
          );
        } else if (getAspect(medias[0]) > 1) {
          if (getAspect(medias[1]) > 1) {
            return images.map((image, index) => (
              <Grid
                key={index}
                item
                md={12}
                lg={12}
                xl={12}
                xs={12}
                sm={12}
                ref={refImg}
                sx={{
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{
                    width: '100%',
                    height: width / getAspect(medias[index])
                    // maxHeight: 350
                  }}
                  className={classes.button}
                >
                  <img
                    src={image?.preview ?? image?.url ?? image}
                    alt="image"
                    style={{
                      width: '100%',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  />
                  {renderButtonClose && renderButtonClose(image)}
                </Grid>
              </Grid>
            ));
          } else if (getAspect(medias[1]) < 1) {
            return (
              <Grid container md={12} lg={12} xl={12} xs={12} sm={12}>
                {images.map((image, index) => (
                  <Grid
                    key={index}
                    sx={{
                      padding: '1px !important',
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                    item
                    md={6}
                    lg={6}
                    xl={6}
                    xs={6}
                    sm={6}
                    ref={refImg}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={
                        index === 0
                          ? {
                              backgroundImage: `url(${
                                image?.preview ?? image?.url ?? image
                              })`,
                              width: '100%',
                              height: width / getAspect(medias[1]),
                              maxHeight: '400px',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }
                          : {
                              backgroundImage: `url(${
                                image?.preview ?? image?.url ?? image
                              })`,
                              width: '100%',
                              height: width / getAspect(medias[index]),
                              maxHeight: '400px',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }
                      }
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                ))}
              </Grid>
            );
          } else if (getAspect(medias[1]) === 1) {
            return (
              <Grid container>
                {images.map((image, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        padding: '1px !important',
                        position:
                          type === 'multiple_item' ? 'relative' : 'static'
                      }}
                      item
                      xs={6}
                      ref={refImg}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        style={{
                          backgroundImage: `url(${
                            image?.preview ?? image?.url ?? image
                          })`,
                          width: '100%',
                          height: width,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                        className={classes.button}
                      ></Grid>
                      {renderButtonClose && renderButtonClose(image)}
                    </Grid>
                  );
                })}
              </Grid>
            );
          }
        } else if (getAspect(medias[0]) === 1) {
          return (
            <Grid container>
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      padding: '1px !important',
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                    item
                    xs={6}
                    ref={refImg}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          image?.preview ?? image?.url ?? image
                        })`,
                        width: '100%',
                        height: width,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                );
              })}
            </Grid>
          );
        } else {
          return (
            <Grid container>
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      padding: '1px !important',
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                    item
                    xs={6}
                    ref={refImg}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          image?.preview ?? image?.url ?? image
                        })`,
                        width: '100%',
                        height: width,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                );
              })}
            </Grid>
          );
        }
      } else if (images.length === 3) {
        if (getAspect(medias[0]) <= 1) {
          return (
            <Grid container spacing={0.5}>
              <Grid
                key={0}
                sx={{ padding: '1px !important' }}
                item
                md={6}
                lg={6}
                xl={6}
                xs={6}
                sm={6}
                ref={refImg}
              >
                {isAVideo(imagesType[0]) ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    sx={{
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                  >
                    <video
                      style={{
                        backgroundColor: '#000',
                        borderRadius: '10px'
                      }}
                      className={classes.button}
                      width="100%"
                      height="400"
                      src={images[0]?.preview ?? images[0]?.url ?? images[0]}
                    />
                    {renderButtonClose && renderButtonClose(images[0])}
                  </Grid>
                ) : (
                  <Grid
                    sx={{
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          images[0]?.preview ?? images[0]?.url ?? images[0]
                        }`,
                        height: 400,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        borderRadius: '10px'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(images[0])}
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{ padding: '1px !important' }}
                item
                md={6}
                lg={6}
                xl={6}
                xs={6}
                sm={6}
              >
                {images.map((image, index) => {
                  if (index != 0) {
                    if (isAVideo(imagesType[index])) {
                      return (
                        <Grid
                          key={index}
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          xs={12}
                          sm={12}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            sx={{
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                          >
                            <video
                              style={{
                                backgroundColor: '#000',
                                maxHeight: 205,
                                borderRadius: '10px',
                                marginTop:
                                  index === 2 && isAVideo(imagesType[index - 1])
                                    ? 2
                                    : 0
                              }}
                              width="100%"
                              height="199"
                              src={image?.preview ?? image?.url ?? image}
                              className={classes.button}
                            />
                            {renderButtonClose && renderButtonClose(image)}
                          </Grid>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          key={index}
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          xs={12}
                          sm={12}
                          sx={{
                            position:
                              type === 'multiple_item' ? 'relative' : 'static'
                          }}
                        >
                          <Grid
                            key={index}
                            container
                            direction="row"
                            justify="center"
                            style={{
                              backgroundImage: `url(${
                                image?.preview ?? image?.url ?? image
                              }`,
                              width: 'auto',
                              height: 199,
                              marginBottom: 2,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              borderRadius: '10px'
                            }}
                            className={classes.button}
                          ></Grid>
                          {renderButtonClose && renderButtonClose(image)}
                        </Grid>
                      );
                    }
                  }
                })}
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid container spacing={0.5}>
              <Grid
                sx={{ padding: '1px !important' }}
                item
                md={12}
                lg={12}
                xl={12}
                xs={12}
                sm={12}
              >
                {isAVideo(imagesType[0]) ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    sx={{
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                  >
                    {renderWatch(
                      typeAction === 'update'
                        ? medias[0]
                        : images[0]?.preview ?? images[0]?.url ?? images[0]
                    )}
                    {typeAction !== 'update' && (
                      <PlayCircleOutlineIcon
                        className={classes.iconPlay}
                        sx={{
                          position: 'absolute',
                          top: '28%',
                          left: '42%',
                          zIndex: '1000'
                        }}
                      />
                    )}
                    {renderButtonClose && renderButtonClose(images[0])}
                  </Grid>
                ) : (
                  <Grid>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          images[0]?.preview ?? images[0]?.url ?? images[0]
                        }`,
                        height: 267,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(images[0])}
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{ padding: '1px !important' }}
                container
                spacing={0.2}
                item
                md={12}
                lg={12}
                xl={12}
                xs={12}
                sm={12}
              >
                {typeAction === 'update'
                  ? medias.map((item, index) => renderVideo(item, index))
                  : images.map((image, index) => renderVideo(image, index))}
              </Grid>
            </Grid>
          );
        }
      } else if (images.length === 4) {
        if (getAspect(medias[0]) === 1) {
          return (
            <Grid container spacing={0.3}>
              {images?.map((image, index) => {
                if (isAVideo(imagesType[index])) {
                  return (
                    <Grid key={index} item xs={6}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        sx={{
                          position:
                            type === 'multiple_item' ? 'relative' : 'static'
                        }}
                      >
                        <video
                          style={{
                            backgroundColor: '#000',
                            borderRadius: '10px'
                          }}
                          className={classes.button}
                          width="100%"
                          height="193px"
                          src={image?.preview ?? image?.url ?? image}
                        />
                        {renderButtonClose && renderButtonClose(image)}
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={6} key={index}>
                    <Grid
                      style={{
                        backgroundImage: `url(${
                          image?.preview ?? image?.url ?? image
                        }`,
                        height: 193,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                      sx={{ padding: '1px !important' }}
                      container
                      direction="row"
                      justify="center"
                    ></Grid>
                    {renderButtonClose && renderButtonClose(image)}
                  </Grid>
                );
              })}
            </Grid>
          );
        } else if (
          getAspect(medias[0]) < 1 &&
          getAspect(medias[0]) >= 0.67 &&
          !hasAVideo(imagesType)
        ) {
          return (
            <Grid
              style={{
                height: 247,
                marginLeft: 4,
                marginRight: 5.3,
                width: 'calc(100% - 16px)'
              }}
              container
              spacing={1}
            >
              {medias?.map((el, index) => (
                <Grid
                  item
                  container
                  alignItems={
                    [0, 2].includes(index) ? 'flex-start' : 'flex-end'
                  }
                  xs={3}
                  key={index}
                  sx={{
                    position: type === 'multiple_item' ? 'relative' : 'static'
                  }}
                >
                  <Grid
                    style={{
                      backgroundImage: `url(${
                        images[index]?.preview ??
                        images[index]?.url ??
                        images[index]
                      }`,
                      height: 227,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      borderRadius: 10,
                      border: '1px solid rgba(0,0,0,0.05)'
                    }}
                    className={classes.button}
                    sx={{ padding: '1px !important' }}
                    container
                    direction="row"
                    justify="center"
                  ></Grid>
                  {renderButtonClose && renderButtonClose(images[index])}
                </Grid>
              ))}
            </Grid>
          );
        } else if (getAspect(medias[0]) > 1) {
          return (
            <Grid key={4} container spacing={0.5}>
              <Grid
                sx={{ padding: '1px !important' }}
                item
                md={12}
                lg={12}
                xl={12}
                xs={12}
                sm={12}
              >
                {isAVideo(imagesType[0]) ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    sx={{
                      position: type === 'multiple_item' ? 'relative' : 'static'
                    }}
                  >
                    <video
                      style={{
                        backgroundColor: '#000',
                        borderRadius: '10px'
                      }}
                      className={classes.button}
                      width="100%"
                      height="260px"
                      src={images[0]?.preview ?? images[0]?.url ?? images[0]}
                    />
                    {renderButtonClose && renderButtonClose(images[0])}
                    {typeAction !== 'update' && (
                      <PlayCircleOutlineIcon
                        className={classes.iconPlay}
                        sx={{
                          position: 'absolute',
                          top: '28%',
                          left: '44%',
                          zIndex: '1000'
                        }}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${
                          images[0]?.preview ?? images[0]?.url ?? images[0]
                        }`,
                        // width: 250,
                        height: 267,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                      className={classes.button}
                    ></Grid>
                    {renderButtonClose && renderButtonClose(images[0])}
                  </Grid>
                )}
              </Grid>{' '}
              <Grid
                sx={{ padding: '1px !important' }}
                item
                container
                spacing={0.2}
                md={12}
                lg={12}
                xl={12}
                xs={12}
                sm={12}
              >
                {images.map((image, index) => {
                  if (index != 0) {
                    if (isAVideo(imagesType[index])) {
                      return (
                        <Grid
                          key={index}
                          item
                          md={4}
                          lg={4}
                          xl={4}
                          xs={4}
                          sm={4}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            sx={{
                              position:
                                type === 'multiple_item' ? 'relative' : 'static'
                            }}
                          >
                            <video
                              style={{
                                backgroundColor: '#000',
                                borderRadius: '10px'
                              }}
                              className={classes.button}
                              width="100%"
                              height="133px"
                              src={image?.preview ?? image?.url ?? image}
                            />
                            {typeAction !== 'update' && (
                              <PlayCircleOutlineIcon
                                className={classes.iconPlay}
                                sx={{
                                  position: 'absolute',
                                  top: '74%',
                                  left:
                                    index === 1
                                      ? '10%'
                                      : index === 2
                                      ? '44%'
                                      : '78%',
                                  zIndex: '1000'
                                }}
                              />
                            )}
                            {renderButtonClose && renderButtonClose(image)}
                          </Grid>
                        </Grid>
                      );
                    }
                    return (
                      <Grid key={index} item xs={4}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          style={{
                            backgroundImage: `url(${
                              image?.preview ?? image?.url ?? image
                            }`,
                            width: '100%',
                            height: 133,
                            marginBottom: 2,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                          }}
                          className={classes.button}
                        ></Grid>
                        {renderButtonClose && renderButtonClose(image)}
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid container spacing={0.5}>
              <Grid
                sx={{
                  padding: '1px !important',
                  position: type === 'multiple_item' ? 'relative' : 'static'
                }}
                item
                md={6}
                lg={6}
                xl={6}
                xs={6}
                sm={6}
              >
                {isAVideo(imagesType[0]) ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    height="100%"
                  >
                    {renderWatch(
                      typeAction === 'update'
                        ? medias[0]
                        : images[0]?.preview ?? images[0]?.url ?? images[0]
                    )}
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    style={{
                      backgroundImage: `url(${
                        typeAction === 'update'
                          ? medias[0].url
                          : images[0]?.preview ?? images[0]?.url ?? images[0]
                      }`,
                      height: imagesType[0] === 'video' ? '400px' : '100%',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      borderRadius: '10px'
                    }}
                    className={classes.button}
                  ></Grid>
                )}
                {renderButtonClose && renderButtonClose(images[0])}
              </Grid>
              <Grid
                sx={{ padding: '1px !important' }}
                item
                md={6}
                lg={6}
                xl={6}
                xs={6}
                sm={6}
              >
                {typeAction === 'update'
                  ? medias.map((item, index) => renderVideo(item, index))
                  : images.map((image, index) => renderVideo(image, index))}
              </Grid>
            </Grid>
          );
        }
      } else {
        return (
          <Grid spacing={0.2} container>
            <Grid item xs={6}>
              {isAVideo(imagesType[0]) ? (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  height="100%"
                  sx={{
                    position: type === 'multiple_item' ? 'relative' : 'static'
                  }}
                >
                  {renderWatch(
                    typeAction === 'update'
                      ? medias[0]
                      : images[0]?.preview ?? images[0]?.url ?? images[0]
                  )}
                  {typeAction !== 'update' && (
                    <PlayCircleOutlineIcon
                      className={classes.iconPlay}
                      sx={{
                        position: 'absolute',
                        top: '20%',
                        left: '20%',
                        zIndex: '1000'
                      }}
                    />
                  )}
                  {renderButtonClose && renderButtonClose(images[0])}
                </Grid>
              ) : (
                <Grid
                  sx={{
                    position: type === 'multiple_item' ? 'relative' : 'static'
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    sx={{ padding: '1px !important' }}
                    style={{
                      backgroundImage: `url(${
                        images[0]?.preview ?? images[0]?.url ?? images[0]
                      }`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: 292,
                      borderRadius: '10px'
                    }}
                    className={classes.button}
                  ></Grid>
                  {renderButtonClose && renderButtonClose(images[0])}
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              {isAVideo(imagesType[1]) ? (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  height="100%"
                  sx={{
                    position: type === 'multiple_item' ? 'relative' : 'static'
                  }}
                >
                  {renderWatch(
                    typeAction === 'update'
                      ? medias[1]
                      : images[1]?.preview ?? images[1]?.url ?? images[1]
                  )}
                  {typeAction !== 'update' && (
                    <PlayCircleOutlineIcon
                      className={classes.iconPlay}
                      sx={{
                        position: 'absolute',
                        top: '20%',
                        left: '70%',
                        zIndex: '1000'
                      }}
                    />
                  )}
                  {renderButtonClose && renderButtonClose(images[1])}
                </Grid>
              ) : (
                <Grid
                  sx={{
                    position: type === 'multiple_item' ? 'relative' : 'static'
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    sx={{ padding: '1px !important' }}
                    style={{
                      backgroundImage: `url(${
                        images[1]?.preview ?? images[1]?.url ?? images[1]
                      }`,
                      paddingRight: 5,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: 292,
                      borderRadius: '10px'
                    }}
                    className={classes.button}
                  ></Grid>
                  {renderButtonClose && renderButtonClose(images[1])}
                </Grid>
              )}
            </Grid>
            <Grid spacing={0.2} container item xs={12}>
              {typeAction === 'update'
                ? medias.map((item, index) => renderVideo(item, index))
                : images.map((image, index) => renderVideo(image, index))}
            </Grid>
          </Grid>
        );
      }
    }
  };
  const renderPdf = images => {
    return (
      <div>
        <object
          data={images[0]}
          type="application/pdf"
          width="600px"
          height="500px"
        ></object>
      </div>
    );
  };
  const displayModelGlb = () => {
    return <ModalView3D path={images[0]} />;
  };

  return (
    <div style={{ width: '100%' }}>
      {medias[0].type.includes('image') || medias[0].type.includes('video')
        ? displayImage(images) != null && displayImage(images)
        : medias[0].type.includes('application/pdf')
        ? renderPdf(images)
        : displayModelGlb()}
    </div>
  );
};

export default React.memo(PictureGrid);
