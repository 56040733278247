import React from 'react';import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import logoAboutUs from 'src/assets/images/logoweb.png';
import { createStyles, makeStyles } from '@mui/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: '20px 0',
      width: '100%'
    },
    title: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      color: '#f36b2c !important',
      textTransform: 'uppercase'
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    boxChildren: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      width: '180px',
      height: '100vh',
      position: 'absolute',
      left: '140px',
      top: 0,
      padding: '120px 0',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      zIndex: 100
    },
    boxMobile: {
      display: 'flex',
      alignItems: 'center'
    },
    boxChildrenMobile: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      width: '100%',
      padding: '10px 0',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center'
    }
  })
);
const AboutUsLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const screenIpad = useMediaQuery('(min-width:900px)');
  const screenIphone = useMediaQuery('(min-width:400px)');
  const [open, setOpen] = React.useState('');
  const [selected, setSelected] = React.useState('');
  const [openMobile, setOpenMobile] = React.useState(false);
  const styleIcon = { fontSize: 32, color: '#f36b2c' };
  const { key } = match.params;

  const listOption = [
    {
      type: 'about_us',
      icon: <i className="fa-light fa-users" style={styleIcon}></i>,
      title: 'Về chúng tôi',
      action: () => {
        setOpen('about_us');
        setSelected('about_us');
        history.push('/about_us/mission');
      },
      children: [
        {
          textChildren: 'Sứ mệnh',
          typeChildren: 'mission',
          actionChldren: () => {
            history.push('/about_us/mission');
            screenIphone
              ? screenIpad
                ? setOpen('about_us')
                : setOpen('')
              : setOpenMobile(false);
          }
        },
        {
          textChildren: 'Tầm nhìn',
          typeChildren: 'vision',
          actionChldren: () => {
            history.push('/about_us/vision');
            screenIphone
              ? screenIpad
                ? setOpen('about_us')
                : setOpen('')
              : setOpenMobile(false);
          }
        },
        {
          textChildren: 'Gía trị cốt lõi',
          typeChildren: 'coreValues',
          actionChldren: () => {
            history.push('/about_us/coreValues');
            screenIphone
              ? screenIpad
                ? setOpen('about_us')
                : setOpen('')
              : setOpenMobile(false);
          }
        }
      ]
    },
    {
      type: 'investors',
      icon: <i className="fa-regular fa-rocket-launch" style={styleIcon}></i>,
      title: 'Nhà đầu tư',
      action: () => {
        setOpen('investors');
        setSelected('investors');
        history.push('/investors/support');
      },
      children: [
        {
          textChildren: 'Hỗ trợ EMSO',
          typeChildren: 'support',
          actionChldren: () => {
            history.push('/investors/support');
            screenIphone
              ? screenIpad
                ? setOpen('investors')
                : setOpen('')
              : setOpenMobile(false);
          }
        },
        {
          textChildren: 'Dành cho NĐT',
          typeChildren: 'investors',
          actionChldren: () => {
            history.push('/investors/investors');
            screenIphone
              ? screenIpad
                ? setOpen('investors')
                : setOpen('')
              : setOpenMobile(false);
          }
        }
      ]
    },
    {
      type: 'contact',
      icon: <i className="fa-regular fa-envelope" style={styleIcon}></i>,
      title: 'Liên hệ',
      action: () => {
        history.push('/aboutUs/contact');
        setSelected('contact');
        setOpen('');
        setOpenMobile(false);
      }
    },
    {
      icon: <i className="fa-light fa-users" style={styleIcon}></i>,
      title: 'Tuyển dụng',
      action: () => {
        history.push('/recruits/home');
        setOpen('');
        setOpenMobile(false);
      }
    },
    {
      icon: <i className="fa-light fa-question-circle" style={styleIcon}></i>,
      title: 'Hỗ trợ',
      action: () => {
        history.push('/help');
        setOpen('');
        setOpenMobile(false);
      }
    }
  ];

  React.useEffect(() => {
    const handlePath = () => {
      switch (match.path) {
        case PATHS.ABOUTUS: {
          setSelected('about_us');
          break;
        }
        case PATHS.INVESTORS: {
          setSelected('investors');
          break;
        }
        case PATHS.CONTACT: {
          setSelected('contact');
          break;
        }
      }
    };
    handlePath();
  }, [match.path]);

  const renderOptionLeft = () => {
    return (
      <Grid>
        <Box sx={{ zIndex: 100 }}>
          <Box className={classes.box} sx={{ marginBottom: '100px' }}>
            <img
              src={logoAboutUs}
              alt="logo"
              onClick={()=>{history.push('/')}}
              style={{
                cursor: 'pointer',
                width: '56px',
                height: '70px'
              }}
            />
          </Box>
          {listOption.map((item, index) => (
            <Box
              key={index}
              className={classes.box}
              sx={{
                flexDirection: 'column',
                margin: '30px 0',
                cursor: 'pointer'
              }}
              onClick={() => item?.action()}
            >
              <Box>{item?.icon}</Box>
              <Box
                sx={{ paddingTop: '10px', width: '100%', textAlign: 'center' }}
              >
                <Typography
                  className={classes.title}
                  sx={{
                    marginTop: '10px',
                    background:
                      item?.type === selected
                        ? 'rgba(0,0,0,0.2)'
                        : 'transparent',
                    padding: '6px'
                  }}
                >
                  {item?.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {(open === 'about_us' || open === 'investors') && (
          <Box
            className={classes.boxChildren}
            sx={{
              width: screenIpad ? '180px' : '110px',
              left: screenIpad ? '140px' : '100px'
            }}
          >
            {listOption?.map(
              item =>
                item?.type === open &&
                item?.children?.map((itemChildren: any, index) => (
                  <Box
                    key={itemChildren}
                    className={classes.box}
                    sx={{ marginTop: '30px', cursor: 'pointer' }}
                    onClick={() => itemChildren?.actionChldren()}
                  >
                    <Typography
                      className={classes.title}
                      sx={{
                        width: '100%',
                        backgroundColor:
                          itemChildren?.typeChildren === key
                            ? 'rgba(0,0,0,0.2)'
                            : 'transparent',
                        padding: '6px'
                      }}
                    >
                      {itemChildren?.textChildren}
                    </Typography>
                  </Box>
                ))
            )}
          </Box>
        )}
      </Grid>
    );
  };

  const renderBodyIphone = () => {
    return (
      <Grid>
        <Box sx={{ zIndex: 100 }}>
          <Box
            className={classes.box}
            sx={{
              justifyContent: 'space-between',
              borderBottom: '1px solid #dcdcdc',
              padding: '5px 30px'
            }}
          >
            <Box className={classes.boxMobile}>
              <img
                src={logoAboutUs}
                alt="logo"
                style={{
                  cursor: 'pointer',
                  width: '40px',
                  height: '50px'
                }}
              />
            </Box>
            <Box onClick={() => setOpenMobile(!openMobile)}>
              <i className="fa-solid fa-bars" style={{ fontSize: 30 }}></i>
            </Box>
          </Box>
          {openMobile && (
            <Box sx={{ backgroundColor: '#fff', padding: '20px 0' }}>
              {listOption.map((item, index) => (
                <Box
                  key={index}
                  className={classes.box}
                  sx={{
                    flexDirection: 'column',
                    cursor: 'pointer',
                    margin: '10px 0'
                  }}
                  onClick={() => item?.action()}
                >
                  <Box>{item?.icon}</Box>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    <Typography
                      className={classes.title}
                      sx={{
                        marginTop: '10px',
                        background:
                          item?.type === selected
                            ? 'rgba(0,0,0,0.2)'
                            : 'transparent',
                        padding: '6px'
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Box>
                  {item?.type === open &&
                    (item?.type === 'about_us' ||
                      item?.type === 'investors') && (
                      <Box className={classes.boxChildrenMobile}>
                        {item?.children?.map((itemChildren: any, index) => (
                          <Box
                            key={itemChildren}
                            className={classes.box}
                            sx={{ marginTop: '30px', cursor: 'pointer' }}
                            onClick={() => itemChildren?.actionChldren()}
                          >
                            <Typography
                              className={classes.title}
                              sx={{
                                width: '100%',
                                backgroundColor:
                                  itemChildren?.typeChildren === key
                                    ? 'rgba(0,0,0,0.2)'
                                    : 'transparent',
                                padding: '6px'
                              }}
                            >
                              {itemChildren?.textChildren}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      className={classes.root}
      sx={{ padding: screenIphone ? '20px 0' : '0' }}
    >
      {screenIphone ? renderOptionLeft() : renderBodyIphone()}
    </Grid>
  );
};

export default AboutUsLeft;
