import React from 'react';
import {
  Avatar,
  Theme,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { AvatarGroup } from '@mui/material';
import { staticImgReaction } from 'src/helpers/common';

import { getReactionPeopleApi } from 'src/apis/socialPost.api';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      avatar: {
        width: '20px !important',
        height: '20px !important',
        fontSize: '10px !important'
      },
      avatarComment: {
        width: '18px!important',
        height: '18px!important',
        fontSize: '10px !important'
      },
      avatarMessage: {
        width: '16px!important',
        height: '16px!important',
        fontSize: '8px !important'
      },
      like: {
        width: '19px !important',
        height: '19px !important',
        paddingBottom: '1px !important',
        marginRight: '4px !important'
      },
      love: {
        width: '32px !important',
        height: '26px !important'
        // margin: '0px 4px 0px -13px !important'
      },
      yay: {
        width: '21px !important',
        height: '30px !important',
        marginRight: '4px !important'
      },
      haha: {
        width: '24px !important',
        height: '23px !important',
        marginRight: '4px !important'
      },
      wow: {
        width: '21px !important',
        height: '20px !important',
        paddingBottom: '2px !important',
        marginRight: '4px !important'
      },
      angry: {
        width: '18px !important',
        height: '18px !important',
        paddingBottom: '2px !important',
        marginRight: '4px !important'
      },
      sad: {
        width: '21px !important',
        height: '21px !important',
        paddingBottom: '2px !important',
        marginRight: '4px !important'
      }
    }),
  { index: 1 }
);

interface RenderReactionImg {
  post?: any;
  isComment?: any;
  isMessage?: any;
  listReaction?: any;
}

const RenderReactionImg: React.FC<RenderReactionImg> = props => {
  const classes = useStyles();
  let { post, isComment, isMessage, listReaction } = props;

  const [listPeople, setListPeople] = React.useState<any>([]);

  let typeCount: number = listReaction
    ?.map((el: any) => Object.values(el)[1])
    .filter(item => item).length;

  const valuesRender = listReaction
    ?.map((el: any) => Object.values(el))
    ?.sort((a, b) => b[1] - a[1])
    .filter(item => item[1])
    .map(item => item[0]);

  const getPeopleReaction = async type => {
    if (isMessage) {
      const listReacts =
        listReaction.find(el => el.type === type)?.username ?? [];
      setListPeople(
        listReacts.map(el => {
          return {
            account: {
              display_name: el
            }
          };
        })
      );
    } else {
      let params = {
        limit: 20,
        vote_type: type
      };
      let res: any = await getReactionPeopleApi(post.id, params);
      if (res.status === 200) {
        if (res.data.length) {
          setListPeople(res.data);
        }
      }
    }
  };

  const renderTitle: any = item => {
    let type = 'Thích';
    switch (item) {
      case 'haha':
        type = 'Ha ha';
        break;
      case 'angry':
        type = 'Tức giận';
        break;
      case 'wow':
        type = 'Bất ngờ';
        break;
      case 'love':
        type = 'Yêu thích';
        break;
      case 'yay':
        type = 'Tự hào';
        break;
      case 'sad':
        type = 'Buồn';
        break;
      default:
        type = 'Thích';
        break;
    }

    return (
      <>
        <Typography
          style={{ fontWeight: 500, fontSize: 14, marginBottom: 5 }}
          variant="body1"
        >
          {type}
        </Typography>
        {listPeople.length ? (
          listPeople?.map((el: any, index) => (
            <span key={index} style={{ display: 'block', fontSize: 12 }}>
              {el?.page ? el?.page?.title : el.account.display_name}
            </span>
          ))
        ) : (
          <CircularProgress style={{ height: 20, width: 20 }} color="inherit" />
        )}
      </>
    );
  };

  const handleMouseEnter = item => {
    getPeopleReaction(item);
  };

  let renderImgReaction = valuesRender?.map((item, index) => {
    return (
      <Tooltip disableInteractive key={index} title={renderTitle(item)}>
        <Avatar
          onMouseEnter={() => handleMouseEnter(item)}
          onMouseLeave={() => setListPeople([])}
          classes={{
            root: isComment ? classes.avatarComment : classes[item]
          }}
          alt={item}
          src={staticImgReaction[item]}
          sx={{
            border: 'unset !important',
            width: item !== 'like' && item !== 'angry' ? '22px !important' : 0,
            height: item !== 'like' && item !== 'angry' ? '22px !important' : 0
          }}
        />
      </Tooltip>
    );
  });

  let total: number;
  typeCount === 1 ? (total = 1) : typeCount === 2 ? (total = 2) : (total = 3);

  return typeCount ? (
    <AvatarGroup
      classes={{
        avatar: isComment
          ? classes.avatarComment
          : isMessage
          ? classes.avatarMessage
          : classes.avatar
      }}
      sx={{ display: 'flex', alignItems: 'end' }}
      max={3}
      total={total}
    >
      {renderImgReaction}
    </AvatarGroup>
  ) : (
    <></>
  );
};

export default RenderReactionImg;
