import { apiGet, apiPost } from './apis';

export const getNotificationsApi = async () => {
  return apiGet(`markers?timeline[]=notifications`, null, null);
};
export const postSeenNotiApi = async (data: any) => {
  return apiPost(`markers`, null, data);
};
export const getDetailNotification = async (id: any) => {
  return apiGet(`notifications/${id}`, null, null);
};
export const postReadNotiApi = async (id: any) => {
  return apiPost(`notifications/${id}/read`, null, null);
};
