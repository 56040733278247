import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextareaAutosize,
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import { useTheme } from '@mui/material/styles';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import { useDispatch } from 'react-redux';
import { notiSharePost } from 'src/store/action/socialPostAction';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  textArea: {
    width: '95%',
    minHeight: '120px !important',
    height: '120px !important',
    color: theme?.palette?.mode === 'dark' ? '#fff' : '#000',
    fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    border: '1px solid #ccc',
    wordBreak: 'break-word',
    '&:focus-visible': {
      outline: 'none'
    },
    backgroundColor: 'transparent',
    resize: 'none',
    marginTop: '10px',
    marginLeft: '8px',
    padding: '10px ',

    zIndex: 110
  },
  linkMedia: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    marginTop: '15px',
    padding: '0px 10px',
    height: '36px',
    borderRadius: '6px',
    position: 'relative'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0px',
    overflow: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    title,
    open,
    handleClose,
    action,
    loading,
    setLoading,
    alignText,
    hiddenButton,
    styleDialogContent,
    maxWidth,
    listIdFile,
    setListIdFile
  } = props;
  const classes = useStyles();
  const [valueText, setValueText] = React.useState<any>('');
  const theme = useTheme();
  const inputRef = React.useRef(null);
  const [isLoadingUpload, setIsLoadingupload] = React.useState(false);
  const [textError, setTextError] = React.useState('');
  const [filesOpinions, setFilesOpinions] = React.useState<any>([]);
  const dispatch = useDispatch();
  const course = useSelector((state: any) => state.courseReducer.info);
  const product = useSelector((state: any) => state.productReducer.info);

  const validateFilesUpload = (files: any) => {
    if (files.length > 6) {
      return setTextError('Bạn chỉ được tải lên tối đa 6 files');
    }
    const allowedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'video/mp4'
    ];
    files.forEach(file => {
      if (!allowedTypes.includes(file.type)) {
        return setTextError(
          'Bạn chỉ được tải lên các tệp theo định dạng (image/jpeg, image/jpg, image/png, docx, video/mp4, sheet)'
        );
      }
    });
    return true;
  };
  const handleFileChange = async (event: any) => {
    setIsLoadingupload(true);
    try {
      setTextError('');
      setFilesOpinions([]);
      let filesObject: Object = event.target.files;
      const filesArray = Object.keys(filesObject).map(key => filesObject[key]);
      if (validateFilesUpload(filesArray)) {
        setFilesOpinions(filesArray);
        let resFiles =
          course?.id || product?.id
            ? await uploadMediaSaga(filesArray, 'moment', 'course')
            : await uploadMediaSaga(filesArray, 'moment');
        const _files = [...resFiles];
        setListIdFile(_files);
      }
    } catch (error) {
      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
        })
      );
      setFilesOpinions([]);
      setListIdFile([]);
    } finally {
      setIsLoadingupload(false);
    }
  };
  const handleDeleteFile = (indexFile, inputRef) => {
    const newListFileIds = listIdFile.filter(
      (item, index) => index !== indexFile
    );
    const newListFileOpinions = filesOpinions.filter(
      (item, index) => index !== indexFile
    );
    if (newListFileIds.length === 0 || newListFileOpinions.length === 0) {
      inputRef.current.value = '';
    }
    setFilesOpinions(newListFileOpinions);
    setListIdFile(newListFileIds);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth ?? undefined}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={alignText ?? 'start'}
          >
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent style={{ ...styleDialogContent }}>
          <TextareaAutosize
            id="textInputFeedBack"
            className={classes.textArea}
            value={valueText}
            placeholder="Phản hồi quyết định..."
            onChange={e => setValueText(e.target.value)}
          ></TextareaAutosize>
          <Box sx={{ padding: '0px 20px' }}>
            <Box
              className={classes.linkMedia}
              sx={{
                background:
                  theme.palette.mode === 'dark' ? '#3A3B3C' : '#E4E6EB'
              }}
            >
              <i className="fa-solid fa-link"></i>
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: '5px'
                }}
              >
                Thêm video hoặc ảnh chụp màn hình (đề xuất)
              </Typography>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                ref={inputRef}
                accept=".jpg, .jpeg, .png, .docx, .xlsx, .mp4"
                style={{
                  position: 'absolute',
                  width: '100%',
                  opacity: 0,
                  cursor: 'pointer'
                }}
              />
            </Box>
            {filesOpinions.length !== 0 && !textError ? (
              <Box
                sx={{
                  width: '100%',
                  height: '77px',
                  margin: '15px 10px 0px 0px',
                  display: 'flex'
                }}
              >
                {filesOpinions.map((fileOpinion, index) => {
                  return fileOpinion.type === 'image/jpeg' ||
                    fileOpinion.type === 'image/png' ||
                    fileOpinion.type === 'image/jpg' ? (
                    <Box
                      style={{
                        width: '80px',
                        height: '100%',
                        borderRadius: '8px',
                        backgroundColor: '#E4E6EB',
                        padding: '0px 10px',
                        position: 'relative',
                        marginRight: '8px'
                      }}
                    >
                      <img
                        key={fileOpinion}
                        src={URL.createObjectURL(fileOpinion)}
                        alt=""
                        style={{
                          maxHeight: '100%',
                          maxWidth: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)'
                        }}
                      />
                      <i
                        className="fa-solid fa-circle-xmark"
                        style={{
                          position: 'absolute',
                          top: '3px',
                          right: '0px',
                          color: '#606770',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleDeleteFile(index, inputRef)}
                      ></i>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        width: '80px',
                        height: '100%',
                        borderRadius: '8px',
                        backgroundColor: '#E4E6EB',
                        padding: '0px 10px',
                        position: 'relative',
                        marginRight: '8px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '10px',
                          textTransform: 'uppercase',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          textAlign: 'center',
                          width: '60px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: 'black'
                        }}
                      >
                        {fileOpinion.type}
                      </Typography>
                      <i
                        className="fa-solid fa-circle-xmark"
                        style={{
                          position: 'absolute',
                          top: '3px',
                          right: '0px',
                          color: '#606770',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleDeleteFile(index, inputRef)}
                      ></i>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
            {textError && (
              <Typography sx={{ color: '#FF0000' }}>{textError}</Typography>
            )}
          </Box>
        </DialogContent>
        {!hiddenButton && (
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{
                textTransform: 'none'
              }}
            >
              {'Hủy'}
            </Button>

            <ButtonInherit
              action={() => {
                action(valueText);
                setLoading && setLoading(true);
              }}
              color={buttonColor.backgroundColor}
              style={{ fontSize: '14px' }}
              label={'Phản hồi quyết định'}
              loading={loading}
            />
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
