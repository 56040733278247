export const PRODUCT_PREVIEW_INFO = 'PRODUCT_PREVIEW_INFO';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_ERROR = 'GET_PRODUCT_DETAIL_ERROR';
export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';

export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_ERROR = 'GET_ORDER_DETAIL_ERROR';
export const GET_ORDER_DETAIL_REQUEST = 'GET_ORDER_DETAIL_REQUEST';

export const RESET_PRODUCT = 'RESET_PRODUCT';
export const GET_CART_DETAIL = 'GET_CART_DETAIL';
export const GET_COUNT_CHILD_PRODUCT = 'GET_COUNT_CHILD_PRODUCT';
export const GET_LIST_CART = 'GET_LIST_CART';
export const REPLACE_LIST_CART = 'REPLACE_LIST_CART';
export const GET_PRODUCT_OWNER = 'GET_PRODUCT_OWNER';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const LOGIN_FINANCE_MARKET = 'LOGIN_FINANCE_MARKET';

export const UPDATE_LIST_CART = 'UPDATE_LIST_CART';
export const UPDATE_VARIANT_CHECKED = 'UPDATE_VARIANT_CHECKED';
export const UPDATE_LIST_VOUCHER = 'UPDATE_LIST_VOUCHER';

export const GET_LIST_MY_PAGE_MARKET_REQUEST =
  'GET_LIST_MY_PAGE_MARKET_REQUEST';
export const GET_LIST_MY_PAGE_MARKET_SUCCESS =
  'GET_LIST_MY_PAGE_MARKET_SUCCESS';
export const LOADING_LIST_MY_PAGE_MARKET = 'LOADING_LIST_MY_PAGE_MARKET';
export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
