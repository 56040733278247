import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NotificationUi from 'src/Notification/NotificationUi';
import { getSearchMoment } from 'src/apis/socialPost.api';
import {
  addUserSearchHistory,
  userSearchHistory
} from 'src/apis/socialSearch.api';
import {
  getListAccountsFollow,
  getListAccountsSuggest,
  getListHashTagMoment
} from 'src/apis/socialUser';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogCUMoment from 'src/components/Dialog/DialogCUMoment';
import IconFavorite from 'src/components/IconFavorite';
import BaseLink from 'src/components/Link/BaseLink';
import Loading from 'src/components/Loading/Loading';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import logo from 'src/assets/images/LogoEmso.svg';
import {
  getPostRequestAction,
  resetActivities
} from 'src/store/action/socialPostAction';
import { searchMoment } from 'src/store/action/socialWatchAction';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 'calc(100% - 50px)',
    width: '315px',
    position: 'fixed',
    top: 50,
    marginBottom: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '1px 0px 2px 0.4px rgb(200 200 200 / 20%)'
        : '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
  },
  rootTitle: {
    fontSize: '24px !important',
    fontWeight: '700 !important'
  },
  wrapHeader: {
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapInput: {
    padding: '8px 12px'
  },
  wrapMenu: {
    padding: '0px 12px'
  },
  list: {
    width: '285px'
  },
  selected: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#3e3e3e !important'
        : '#ECF3FF !important'
  },
  textPrimary: {
    fontSize: '15px !important',

    fontWeight: '500 !important'
  },
  wrapBlock: {
    padding: '8px 12px'
  },
  wrapTitleBlock: { fontSize: '17px !important', fontWeight: '500 !important' },
  hashtag: {
    display: 'inline-block',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 20,
    padding: '2px 5px',
    margin: '5px 10px 0px 0px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapContent: {
    width: '100%',
    height: 'calc(100vh - 175px)',
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    },
    '&:hover&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bdbdbd',
      borderRadius: 10
    }
  },
  textShowBonus: {
    fontWeight: 500
  },
  linkItem: {
    color: `${theme.palette.text.primary} !important`,
    width: '100%',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.button.primary.hover,
      cursor: 'pointer'
    },
    marginTop: '8px',
    marginLeft: '-8px'
  },
  truncateText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  iconTime: {
    marginRight: '10px !important'
  },
  boxVideo: {
    width: '100% !important',
    height: '30px !important',
    display: 'flex',
    alignItems: 'center'
  },
  inputSearch: {
    width: '100%'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '30px !important',
    height: 35,
    padding: '7px !important',
    margin: 'auto'
  },
  input: {
    '&::placeholder': {
      fontSize: 15
    }
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
  },
  multilineColor: {
    fontSize: 12
  },
  subText: {
    color: theme.palette.text.customColor,
    fontSize: '15px !important',
    marginTop: 17,
    padding: '0px 5px 0px 5px'
  }
}));

const MomentBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();

  const [keyword, setKeyword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [selectedIndex, setSelectedIndex] = React.useState('video-show');
  const [listAccount, setListAccount] = React.useState([]);
  const [listAccountSuggest, setListAccountSuggest] = React.useState([]);
  const [showMore, setShowMore] = React.useState({
    isShowFollow: false,
    isShowSuggest: false
  });
  const listMomentSearch =
    useSelector((state: any) => state.watchReducer.momentSearch) || [];
  const [listSearchHistory, setListSearchHistory] = React.useState<any>([]);
  const [listDiscover, setListDiscover] = React.useState([]);
  const meSelected = useSelector((state: any) => state.meReducer.info);
  const [isLoadingAccount, setIsLoadingAccount] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openNoti, setOpenNoti] = React.useState(false);

  const getListMoment = async type => {
    dispatch(
      getPostRequestAction(
        {
          limit: 3
        },
        type,
        window.location.href
      )
    );
  };

  const listMenu = [
    {
      id: 'video-show',
      label: 'Dành cho bạn',
      action: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getListMoment('discover-moment');
      },
      icon: 'fa-light fa-tv-music'
    },
    {
      id: 'video-follow',
      label: 'Đang theo dõi',
      action: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getListMoment('moment');
      },
      icon: 'fa-light fa-clapperboard'
    }
    // {
    //   id: 'video-live',
    //   label: 'Trực tiếp',
    //   action: () => {},
    //   icon: 'fa-light fa-video'
    // }
  ];
  const [openMoment, setOpenMoment] = React.useState<boolean>(false);

  const resetActivity = React.useCallback(
    () => dispatch(resetActivities()),
    [dispatch]
  );

  const handleAddUserSearchHistory = async params => {
    let response = await addUserSearchHistory(params);
    if (response.status === 200) {
      fetchUserSearchHistory();
    }
  };

  const fetchUserSearchHistory = async () => {
    setIsLoading(true);
    let response;
    response = await userSearchHistory({
      search_type: 'moment'
    });
    if (response.status === 200) {
      setListSearchHistory(response.data);
      setIsLoading(false);
    }
  };

  const getListAccountSuggest = async (idUser: any) => {
    let response: any = await getListAccountsSuggest(idUser, {
      limit: 10
    } as any);
    if (response.status === 200) {
      setListAccountSuggest(response.data);
      setIsLoadingAccount(false);
    }
  };

  const getListHashTag = async () => {
    let response: any = await getListHashTagMoment({
      limit: 10
    });
    if (response.status === 200) {
      setListDiscover(response.data);
      setIsLoadingAccount(false);
    }
  };

  async function fetchFollowMoment(idUser: any) {
    let response: any = await getListAccountsFollow(idUser, {
      limit: 10
    } as any);
    if (response.status === 200) {
      setListAccount(response.data);
      setIsLoadingAccount(false);
    }
  }

  const handleSearch = React.useCallback(
    _.debounce(async keyword => {
      setIsLoading(true);
      let response = await getSearchMoment(keyword);
      if (response.status === 200) {
        dispatch(searchMoment(response.data));
        setIsLoading(false);
      }
    }, 800),
    []
  );
  function checkHashtagsStr(str: any) {
    const regex = /#(\S+)/g;
    return regex.test(str);
  }

  React.useEffect(() => {
    if (meSelected?.id) {
      fetchFollowMoment(meSelected?.id);
      getListAccountSuggest(meSelected?.id);
      getListHashTag();
    }
  }, [meSelected?.id]);

  React.useEffect(() => {
    if (keyword) {
      handleSearch(keyword);
    }
  }, [keyword]);

  const renderList = (list, type = null) => {
    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {list?.map((item: any, index) => (
          <BaseLink
            key={index}
            url={item?.url ? item?.url : null}
            component={
              <ListItem
                sx={{
                  borderRadius: '10px',
                  maxHeight: item.icon ? '58px' : '72px',
                  padding: '8px',
                  backgroundColor:
                    selectedIndex === item?.id ||
                    selectedIndex === item?.page?.id
                      ? 'button.secondary.background'
                      : 'transparent'
                }}
                button
                onClick={() => {
                  resetActivity();
                  history.push(
                    item.id === 'video-show'
                      ? '/moment'
                      : type === 'user'
                      ? `/moment/user/${item.id}`
                      : `/moment/${item.id}`
                  );
                  item?.action && item?.action();
                }}
                key={item?.id}
              >
                {item?.icon && (
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <i style={{}} className={`${item.icon} fa-lg`}></i>
                  </ListItemIcon>
                )}

                {!item?.icon && (
                  <AvatarSocial
                    type="feed"
                    style={{ width: 40, height: 40 }}
                    avatarObj={item.avatar_media}
                  />
                )}

                <ListItemText
                  style={{ marginLeft: 10 }}
                  primary={
                    <Typography className={classes.textPrimary}>
                      {item?.label
                        ? item?.label
                        : item?.display_name ?? item.name ?? item.keyword}
                      {item?.certified ? (
                        <span>
                          <VerifiedIcon
                            titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                            fontSize="small"
                            viewBox="0 -2 24 24"
                            sx={{
                              color: buttonColor.backgroundColor,
                              fontSize: '14px',
                              '&:hover': {
                                color: buttonColorHover.backgroundColor
                              }
                            }}
                          />
                        </span>
                      ) : null}
                    </Typography>
                  }
                />
              </ListItem>
            }
          />
        ))}
      </List>
    );
  };

  const renderAccount = (title, listShowAccount, listAccount, type) => {
    return (
      <div className={classes.wrapBlock}>
        <Typography className={classes.wrapTitleBlock}>{title}</Typography>

        {isLoadingAccount ? (
          <Loading
            style={{
              marginTop: '10px',
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
          />
        ) : listAccount?.length ? (
          renderList(listShowAccount, 'user' as any)
        ) : (
          <Typography style={{ padding: '8px 12px' }}>
            Không có tài khoản nào
          </Typography>
        )}

        {listAccount?.length > 2 && !showMore[type] ? (
          <ButtonInherit
            style={{
              margin: '0px 4px 8px 4px',
              width: '280px'
            }}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textShowBonus}>
                  Xem thêm
                </Typography>
                &nbsp;
                <i className="fa-solid fa-angle-down"></i>
              </div>
            }
            action={() => {
              setShowMore(prev => ({
                ...prev,
                [type]: true
              }));
            }}
          />
        ) : null}
        <div style={{ height: 5 }}></div>
        <Divider />
      </div>
    );
  };

  React.useEffect(() => {
    if (match.params?.key) {
      setSelectedIndex(match.params?.key);
    } else if (match.params?.idUser) {
      setSelectedIndex(match.params?.idUser);
    } else {
      setSelectedIndex('video-show');
    }
  }, [match.params?.key, match.params?.idUser]);

  const renderSearchMoment = () => {
    if (isLoading) {
      return (
        <Loading
          style={{
            marginTop: '10px',
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}
        />
      );
    } else if (listSearchHistory.length && keyword.length < 1) {
      return (
        <>
          <div>
            <Typography
              fontSize="16px"
              fontWeight="600"
              sx={{ paddingLeft: '10px' }}
            >
              Tìm kiếm gần đây
            </Typography>
          </div>
          <List className={classes.list} component="nav" aria-label="sidebar">
            {listSearchHistory?.map((el: any, index) => (
              <Box
                key={index}
                sx={{ width: '100%', height: 'auto' }}
                onClick={event => {
                  history.push(
                    `/moments/search/top?q=${el.keyword ?? el.name}`
                  );
                  handleAddUserSearchHistory({
                    keyword: el.keyword ?? el.name,
                    search_type: 'moment'
                  });
                }}
              >
                <Conversation type="moment" conversation={el} key={index} />
              </Box>
            ))}
          </List>
        </>
      );
    } else {
      if (listMomentSearch.length) {
        return listMomentSearch.map((el, index) => {
          return (
            <Box
              key={index}
              sx={{ width: '100%', height: 'auto' }}
              onClick={() => {
                handleAddUserSearchHistory({
                  keyword: el.display_name ?? el.title,
                  search_type: 'moment'
                });
              }}
            >
              <Conversation type="moment" conversation={el} key={index} />
            </Box>
          );
        });
      } else {
        return <div style={{ textAlign: 'center' }}>Không tìm thấy video.</div>;
      }
    }
  };

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <div className={classes.wrapHeader}>
        <Typography className={classes.rootTitle}>Khoảnh khắc</Typography>
        <IconFavorite type="moment" />
      </div>

      <div className={classes.wrapInput}>
        <TextField
          classes={{ root: classes.inputSearch }}
          id="button_search_moment"
          value={keyword}
          style={{
            fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif'
          }}
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Tìm kiếm video"
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <i
                style={{
                  marginRight: 5,
                  padding: 5,
                  fontWeight: 500,
                  color: '#7b7b7b'
                }}
                className="fa-light fa-magnifying-glass"
              ></i>
            ),
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
              input: classes.input
            },
            className: classes.multilineColor
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              if (checkHashtagsStr(keyword)) {
                history.push(`/hashtag/${keyword.slice(1)}`);
              } else {
                history.push(`/moments/search/top?q=${keyword}`);
              }
              handleAddUserSearchHistory({
                keyword: keyword,
                search_type: 'moment'
              });
            }
          }}
          onChange={event => {
            setKeyword(event.target.value);
          }}
          onClick={event => {
            setAnchorEl(event.currentTarget);
            setOpenPopper(true);
            if (!listSearchHistory.length) {
              fetchUserSearchHistory();
            }
          }}
        />
        <div style={{ height: 15 }}></div>
        <Divider />
      </div>
      <div className={classes.wrapContent}>
        {openPopper && (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
              setOpenPopper(false);
              setListSearchHistory([]);
            }}
          >
            <Popper
              id="simple_menu"
              anchorEl={anchorEl}
              open={openPopper}
              placement={'bottom-end'}
              disablePortal
              style={{
                padding: '5px 0px',
                zIndex: 1001
              }}
            >
              <Paper
                sx={{
                  width: '300px',
                  maxHeight: '400px',
                  height: '100%',
                  overflow: 'hidden auto'
                }}
              >
                {renderSearchMoment()}
              </Paper>
            </Popper>
          </ClickAwayListener>
        )}
        <div className={classes.wrapMenu}>
          {renderList(listMenu)}
          <div style={{ padding: '0px 18px 0px 8px', width: 300 }}>
            <ButtonCustom
              label="Tạo khoảnh khắc"
              action={() => setOpenMoment(true)}
              solid={true}
              style={{
                color: '#e7f3ff',
                backgroundColor: buttonColor.backgroundColor
              }}
              icon={<i className="far fa-plus"></i>}
            />
          </div>
          <div style={{ height: 5 }}></div>
          <Divider />
        </div>

        {renderAccount(
          'Tài khoản được đề xuất',
          !showMore.isShowSuggest
            ? listAccountSuggest.slice(0, 2)
            : listAccountSuggest,
          listAccountSuggest,
          'isShowSuggest'
        )}

        {renderAccount(
          'Tài khoản đang theo dõi',
          !showMore.isShowFollow ? listAccount.slice(0, 2) : listAccount,
          listAccount,
          'isShowFollow'
        )}

        <div className={classes.wrapBlock}>
          <Typography className={classes.wrapTitleBlock}>Khám phá</Typography>
          {listDiscover?.map((el: any) => {
            return (
              <div
                className={classes.hashtag}
                key={el.name}
                onClick={() => {
                  history.push(`/hashtag/${el.name}`);
                }}
              >
                <i className="fa-light fa-hashtag"></i>
                {el.name}
              </div>
            );
          })}
        </div>
      </div>
      {openMoment && (
        <DialogCUMoment
          open={openMoment}
          setOpen={setOpenMoment}
          handleClose={() => setOpenMoment(false)}
          setOpenNoti={setOpenNoti}
        />
      )}
      {openNoti && (
        <NotificationUi
          openNoti={openNoti}
          setOpenNoti={setOpenNoti}
          component={() => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  cursor: 'pointer',
                  width: '50px',
                  height: 'auto'
                }}
                alt="Emso"
                src={logo}
              />
              <Typography className={classes.subText}>
                Video của bạn đang trong quá trình xử lý, chúng tôi sẽ thông báo
                cho bạn khi video đã sẵn sàng.
              </Typography>
            </div>
          )}
        />
      )}
    </Box>
  );
};

export default MomentBar;
