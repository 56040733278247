import VerifiedIcon from '@mui/icons-material/Verified';
import { ListItem, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRequestFriendApi, requestFriendApi } from 'src/apis/socialUser';
import ButtonInherit from '../Button/ButtonInherit';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import NameSocial from '../ProfileCardImage/NameSocial';
import {
  handleChatNow,
  handleCreateChatPage
} from 'src/pages/Chat/functionChat';
import { buttonColor } from 'src/constants/styles';
const UserReactionItem = props => {
  const { item, index, renderIconDetail, renderTitle, handleClose } = props;
  const [relationships, setRelationships] = React.useState<any>([]);
  const dispatch = useDispatch();
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );
  const handleAction = async (type, userInfor) => {
    let response;
    if (type === 'removeRequest') {
      response = await cancelRequestFriendApi(userInfor.id);
    } else if (type === 'addFriend') {
      response = await requestFriendApi(userInfor.id);
    }
    if (response.status === 200) {
      setRelationships(response.data);
    }
  };

  const renderButton = userInfor => {
    if (userInfor?.relationships?.friendship_status === 'ARE_FRIENDS') {
      return (
        <div>
          <ButtonInherit
            label="Nhắn tin"
            icon={
              <i
                style={{ fontSize: 15 }}
                className="fa-brands fa-facebook-messenger"
              ></i>
            }
            action={() => {
              if (!item?.page) {
                const existsPopup = listPopupChat.find(el =>
                  el.conversation.rid.includes(item.id)
                );
                if (!existsPopup) {
                  handleChatNow(item?.account, rocketId, dispatch);
                }
              } else handleCreateChatPage(item?.page, dispatch);
              handleClose();
            }}
          />
        </div>
      );
    } else if (relationships?.friendship_status === 'OUTGOING_REQUEST') {
      return (
        <ButtonInherit
          label="Hủy yêu cầu"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-xmark"></i>
          }
          action={() => handleAction('removeRequest', userInfor)}
        />
      );
    } else if (relationships?.friendship_status === 'CAN_REQUEST') {
      return (
        <ButtonInherit
          label="Thêm bạn bè"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-plus"></i>
          }
          action={() => handleAction('addFriend', userInfor)}
        />
      );
    }
  };

  React.useEffect(() => {
    if (item?.account?.relationships) {
      setRelationships(item?.account?.relationships);
    }
  }, [JSON.stringify(item?.account?.relationships)]);

  return (
    <ListItem key={index} secondaryAction={renderButton(item?.account)}>
      <div style={{ position: 'relative' }}>
        <AvatarSocial
          type="feed"
          style={{ width: 40, height: 40 }}
          avatarObj={
            item?.page && item?.page?.avatar_media
              ? item?.page?.avatar_media
              : !item?.page
              ? item?.account?.avatar_media
              : null
          }
          isPopup
          object={item?.page ? item?.page : item?.account}
        />
        {renderIconDetail(renderTitle(item?.custom_vote_type))}
      </div>
      &nbsp;&ensp;
      <ListItemText
        primary={
          <>
            <NameSocial
              isPopup
              name={
                item?.page ? item?.page?.title : item?.account?.display_name
              }
              object={item?.page ? item?.page : item?.account}
            />{' '}
            {item.account.certified ? (
              <Tooltip
                placement="top-start"
                title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                arrow
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      fontSize: '14px',
                      padding: '10px'
                    }
                  }
                }}
              >
                <VerifiedIcon
                  // titleAccess="Tài khoản đã xác minh"
                  fontSize="small"
                  viewBox="0 -2 24 24"
                  sx={{
                    color: buttonColor.backgroundColor,
                    fontSize: '14px',
                    '&:hover': {
                      color: buttonColor.backgroundColor
                    }
                  }}
                />
              </Tooltip>
            ) : null}
            {!item.account.certified && item.account.identity_verification ? (
              <Tooltip
                placement="top-start"
                title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                arrow
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      fontSize: '14px',
                      padding: '10px'
                    }
                  }
                }}
              >
                <VerifiedIcon
                  // titleAccess="Tài khoản đã xác minh"
                  fontSize="small"
                  viewBox="0 -2 24 24"
                  sx={{
                    color: 'hsla(0, 0%, 50%)',
                    fontSize: '14px',
                    '&:hover': {
                      color: 'hsla(0, 0%, 60%)'
                    }
                  }}
                />
              </Tooltip>
            ) : null}
          </>
        }
      />
    </ListItem>
  );
};
export default UserReactionItem;
