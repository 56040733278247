// export let USER_TOKEN = '';
// let tokenUser = localStorage.getItem('token');
// if (tokenUser) {
//   USER_TOKEN = tokenUser;
// }

// export function updateTokenUser(token) {
//   if (token) {
//     USER_TOKEN = token;
//   }
// }

// export const BASE_ROOT = process.env.REACT_APP_BASE_ROOT;
// export const BASE_ROOT_MARKET = process.env.REACT_APP_BASE_ROOT_MARKET;
// // export const BASE_ROOT = 'https://mxh.emso.asia';

// // // rocket chat production env
// export const urlRocketChat = process.env.REACT_APP_URL_ROCKET_CHAT;
// export const urlWebSocket = process.env.REACT_APP_URL_WEB_SOCKET;

// export const TOKEN_VIDEO_UPLOAD = process.env.REACT_APP_TOKEN_VIDEO_UPLOAD;

// export const urlWebEmso = process.env.REACT_APP_URL_WEB_EMSO;
// export const urlStreamingSocket = process.env.REACT_APP_URL_STREAMING_SOCKET;
// export const urlNotificationApi = process.env.REACT_APP_URL_NOTIFICATION_API;
// export const urlNotificationSocket =
//   process.env.REACT_APP_URL_NOTIFICATION_SOCKET;

//*****cmc-sn.emso.vn*****
export let USER_TOKEN = '';
export let TOKEN_VIDEO_UPLOAD = '';
let tokenUser = localStorage.getItem('token');
let tokenVideoUpload = localStorage.getItem('token_video');

if (tokenUser) USER_TOKEN = tokenUser;
if (tokenVideoUpload) TOKEN_VIDEO_UPLOAD = tokenVideoUpload;

export const BASE_ROOT = process.env.REACT_APP_PRO_BASE_ROOT;
// export const BASE_ROOT = 'https://mxh.emso.asia';
export function updateTokenUser(token) {
  if (token) {
    USER_TOKEN = token;
  }
}

export function updateTokenVideoUpload(token) {
  if (token) TOKEN_VIDEO_UPLOAD = token;
}

// // rocket chat production env
export const urlRocketChat = process.env.REACT_APP_PRO_URL_ROCKET_CHAT;
export const urlWebSocket = process.env.REACT_APP_PRO_URL_WEB_SOCKET;

export const BASE_ROOT_MARKET = process.env.REACT_APP_PRO_BASE_ROOT_MARKET;

export const urlWebEmso = process.env.REACT_APP_PRO_URL_WEB_EMSO;
export const urlStreamingSocket =
  process.env.REACT_APP_PRO_URL_STREAMING_SOCKET;
export const urlNotificationApi =
  process.env.REACT_APP_PRO_URL_NOTIFICATION_API;
export const urlNotificationSocket =
  process.env.REACT_APP_PRO_URL_NOTIFICATION_SOCKET;
export const applicationIdentifier =
  process.env.REACT_APP_PRO_NOTIFICATION_APIKEY;

// const baseRootPtube = process.env.REACT_APP_BASE_ROOT_PTUBE;
// export const urlRocketChat = process.env.REACT_APP_PRO_URL_ROCKET_CHAT_EMSO;
// export const urlWebSocket = process.env.REACT_APP_PRO_URL_WEB_SOCKET_EMSO;

// // rocket chat dev env
// export const urlRocketChat = process.env.REACT_APP_DEV_URL_ROCKET_CHAT;
// export const urlWebSocket = process.env.REACT_APP_DEV_URL_WEB_SOCKET;
