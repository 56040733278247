import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import _ from 'lodash';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { OnChangeHandlerFunc } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogConfirm from 'src/components/Dialog/DialogConfirmDelete';
import ListButton from 'src/components/List/ListButton';
import Mentions from 'src/components/Mentions/Mentions';
import VisibilityComponent from 'src/components/Visibility';
import { listVisibility } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import {
  createPostRequestAction,
  notiSharePost,
  resetFalsePostLoad,
  resetPostLoad
} from 'src/store/action/socialPostAction';

const useStyles = makeStyles((theme: Theme) => ({
  wrapBoxUpload: {
    width: '260px',
    height: '485px',
    background: 'rgba(22, 24, 35, 0.03)',
    border: '2px dashed rgb(0,0,0,0.2)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0px 15px',
    transition: 'background 0.15s ease-in-out 0s',
    '&:hover': {
      border: '2px dashed rgb(254, 44, 85)',
      cursor: 'pointer'
    }
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important',
    margin: '10px 0px !important'
  },
  wrapText: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    marginBottom: 5
  },
  textButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    fontWeight: '500 !important',
    textAlign: 'right',
    width: 'fit-content'
  },
  wrapBoxButtonUpload: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 8,
    width: 280,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    marginTop: 10
  },
  showDotsText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150
  },
  imgSelected: {
    width: 103.9,
    maxWidth: 103.9,
    objectFit: 'cover',
    objectPosition: 'center',
    opacity: 1,
    borderRadius: 5,
    transform: 'scaleX(1.07) scaleY(1.07)'
  },
  img: {
    width: 103.9,
    maxWidth: 103.9,
    objectFit: 'cover',
    objectPosition: 'center',
    opacity: 0.3
  },
  textLabel: {
    fontSize: '13px !important',
    fontWeight: '500 !important',
    marginBottom: '2px !important'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: any;
  pageOwnerId?: any;
  groupId?: any;
  type?: String;
  groupTitle?: String;
  groupVisibility?: any;
  courseInfo?: any;
  projectInfo?: any;
  eventInfo?: any;
  setOpenNoti?: any;
}

export default function ScrollDialog(props: Props) {
  const {
    open,
    setOpen,
    handleClose,
    pageOwnerId,
    groupId,
    type,
    groupTitle,
    groupVisibility,
    courseInfo,
    projectInfo,
    eventInfo,
    setOpenNoti
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const [visibility, setVisibility] = React.useState('public');
  const [isOpen, setIsOpen] = React.useState(true);
  const [openDialogPush, setOpenDialogPush] = React.useState<any>(false);
  const [files, setFiles] = React.useState<any>([]);
  const [thumbnails, setThumbnails] = React.useState<any>([]);
  const [textAlert, setTextAlert] = React.useState<string>('');
  const [dataCreate, setDataCreate] = React.useState({
    sensitive: false,
    status: '',
    post_type: 'moment'
  });
  const [checkCreateMoment, setCheckCreateMoment] = React.useState(false);
  const [showUrl, setShowUrl] = React.useState<any>(null);
  const isLoad =
    useSelector((state: any) => state.socialPostReducer.isLoad) || false;
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const error = useSelector((state: any) => state.socialPostReducer.error);
  const inputRef: any = React.useRef();
  const theme = useTheme();
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const [listMentions, setListMentions] = React.useState<any>(
    friends.slice(0, 8)
  );
  const [objectMentions, setObjectMentions] = React.useState<any>([]);
  const elementText: any = document.getElementById('textInputCreateMoment');
  const [errorText, setErrorText] = React.useState<string>('');
  const classes = useStyles();
  const listVisibilityPage = listVisibility.filter(
    item => item.key === 'public'
  );

  async function getDuration(file) {
    if (file) {
      const url = URL.createObjectURL(file);

      return new Promise(resolve => {
        const audio = document.createElement('audio');
        audio.muted = true;
        const source = document.createElement('source');
        source.src = url; //--> blob URL
        audio.preload = 'metadata';
        audio.appendChild(source);
        audio.onloadedmetadata = function () {
          resolve(audio.duration);
        };
      });
    }
  }
  const handleRejectedFiles = fileRejections => {
    const tooLargeFiles = fileRejections.filter(
      file => file.file.size > 1024 * 1024 * 1024
    );

    if (tooLargeFiles.length > 0) {
      setTextAlert('Video không vượt quá giới hạn 1GB');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.mp4, .m4v, .mov, .webm',
    multiple: false,
    maxSize: 1024 * 1024 * 1024,
    onDropRejected: handleRejectedFiles,
    onDrop: async (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      if (acceptedFiles.length) {
        const duration: any = await getDuration(acceptedFiles[0]);
        if (duration < 3) {
          setTextAlert('Video cần dài hơn 3 giây');
        } else if (duration > 301) {
          setTextAlert('Video cần ngắn hơn 5 phút');
        } else {
          if (textAlert) {
            setTextAlert('');
          }
          let newFiles = filesState.concat(fileAccepts);
          generateVideoThumbnails(fileAccepts[0], 4, null as any).then(
            thumbs => {
              setThumbnails(thumbs);
            }
          );
          setFiles(newFiles);
        }
      } else {
        setTextAlert('File định dạng không hợp lệ!');
      }
    }
  });

  const handleKeyDown = e => {
    if (e.key === '@') {
      setListMentions(friends.slice(0, 8));
    }
  };

  const createPostRequest = () => {
    let newFile = files[0];

    Object.defineProperty(newFile, 'show_url', {
      value: showUrl,
      writable: true
    });
    let data = {
      sensitive: false,
      status: handleTextContent(),
      post_type: 'moment',
      visibility,
      media_ids: [newFile],
      project_id: projectInfo && projectInfo?.id ? projectInfo.id : null,
      course_id: courseInfo && courseInfo?.id ? courseInfo.id : null,
      event_id: eventInfo && eventInfo?.id ? eventInfo.id : null,
      tags: objectMentions?.map((el: any) => ({
        entity_id: el.id,
        entity_type: el.type,
        position: el.position
      }))
    };
    dispatch(
      createPostRequestAction(
        eventInfo?.page_owner ||
          projectInfo?.page_owner ||
          courseInfo?.page_owner
          ? {
              ...data,
              page_owner_id:
                eventInfo?.page_owner?.id ||
                projectInfo?.page_owner?.id ||
                courseInfo?.page_owner?.id
            }
          : pageOwnerId
          ? { ...data, page_owner_id: pageOwnerId, page_id: pageOwnerId }
          : groupId
          ? { ...data, group_id: groupId }
          : data,
        'post',
        'post_moment',
        null
      )
    );
  };

  const handleTextContent = () => {
    const regex = /@\[([^\]]+)\]\((\d+)\)/g;
    let contentPost = dataCreate.status.replace(regex, '[$2]');
    return contentPost;
  };

  const handleChange: OnChangeHandlerFunc = (event, newValue, displayValue) => {
    if (newValue.length > 150) {
      setErrorText('Nội dung đã vượt quá kí tự cho phép!');
    } else {
      setErrorText('');
    }
    setDataCreate(prev => ({
      ...prev,
      status: newValue
    }));
  };

  const handlePaste = e => {
    if (Array.from(e.clipboardData.files).length > 0) {
      let filesState = [...files];
      let fileAccepts = Array.from(e.clipboardData.files).map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      let newFiles = filesState.concat(fileAccepts);
      setFiles(newFiles);
    }
  };

  const handleActionMention = id => {
    const inputElement: any = document.getElementById('textInputCreateMoment');
    const mentionEndPosition = inputElement.selectionEnd;

    const item = listMentions?.find(item => item.id === id);

    let typeObjectMention = item?.page_relationship
      ? 'Page'
      : item?.group_relationship
      ? 'Group'
      : 'Account';
    let nameObjectMention = item?.display_name ?? item?.title;

    setObjectMentions(prev => [
      ...prev,
      {
        id: item?.id ?? '',
        type: typeObjectMention,
        name: nameObjectMention,
        position: mentionEndPosition
      }
    ]);
    inputRef.current.focus();
  };

  const debounceSearchGlobal = React.useCallback(
    _.debounce(async function (q, callback) {
      if (!q) {
        callback(
          friends.map((item: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }))
        );
      }
      let response = await searchUserGlobal({
        q,
        limit: 5,
        type: ['accounts', 'pages', 'groups']
      });

      if (response.status === 200) {
        let accounts: any = response.data.accounts.slice(0, 3);
        let pages: any = response.data.pages.slice(0, 3);
        let groups: any = response.data.groups.slice(0, 3);
        let optionNewSearch: any = [...accounts, ...groups, ...pages];
        if (optionNewSearch.length) {
          const results = optionNewSearch.map((item: any, index: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }));
          setListMentions(optionNewSearch);
          callback(results);
        }
      }
    }, 800),
    []
  );

  React.useEffect(() => {
    if (thumbnails.length) {
      setShowUrl(thumbnails[0]);

      let newFile = files[0];

      if (newFile) {
        Object.defineProperty(newFile, 'show_url', {
          value: thumbnails[0],
          writable: true
        });

        setFiles([newFile]);
      }
    }
  }, [JSON.stringify(thumbnails)]);

  React.useEffect(() => {
    if (isLoad === 'success' && !isOpen && !error?.code) {
      if (type === 'header' && match.path !== PATHS.USER) {
        setOpenDialogPush(false);
        setFiles([]);
        setDataCreate({
          sensitive: false,
          status: '',
          post_type: 'moment'
        });
        setThumbnails([]);
        setVisibility('public');
      } else if (match.path === PATHS.USER || match.path === PATHS.HOME) {
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Bạn đã đăng tải khoảnh khắc lên thành công.'
          })
        );
        handleClose();
      } else {
        handleClose();
        setOpenNoti && setOpenNoti(true);
      }
    }
  }, [isLoad, isOpen]);

  React.useEffect(() => {
    setIsOpen(true);
    dispatch(resetFalsePostLoad());
  }, []);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (elementText) {
      elementText.style.color = theme.palette.text.primary;
      elementText.style.padding = '2px';
      elementText.style.overflow = 'auto';
    }
  }, [elementText]);

  React.useEffect(() => {
    if (
      (dataCreate.status.trim().length === 0 && files.length) ||
      (dataCreate.status.trim().length && !files.length) ||
      (dataCreate.status.trim().length === 0 && !files.length) ||
      errorText
    ) {
      setCheckCreateMoment(true);
    } else {
      setCheckCreateMoment(false);
    }
  }, [dataCreate.status, JSON.stringify(files)]);
  React.useEffect(() => {
    if (groupId) {
      setVisibility(groupVisibility ? 'private' : 'public');
    }
  }, [groupId]);
  return (
    <>
      <Dialog
        open={openDialogPush ? false : open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        BackdropProps={modalBackdrop}
        maxWidth="md"
        fullWidth
      >
        {isLoad === 'loading' ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 2000,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: 'rgba(255,255,255,0.6)'
            }}
          >
            <CircularProgress color="inherit" />
            <Typography style={{ fontSize: 20 }}>Đang đăng</Typography>
          </div>
        ) : null}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Tạo bài khoảnh khắc
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          style={{
            overflowX: 'hidden',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ display: 'flex' }}>
            {files.length ? (
              <div>
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 485,
                    borderRadius: 16,
                    overflow: 'hidden'
                  }}
                >
                  <video
                    src={files[0].preview}
                    style={{ width: 280, height: 485 }}
                    poster={showUrl}
                  ></video>
                </div>
                <div className={classes.wrapBoxButtonUpload}>
                  <Typography className={classes.showDotsText}>
                    {files[0].name}
                  </Typography>
                  <Typography
                    onClick={() => {
                      setFiles([]);
                      setThumbnails([]);
                      setShowUrl(null);
                    }}
                    className={classes.textButton}
                  >
                    Hủy chọn
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                {...getRootProps({ className: 'dropzone' })}
              >
                <div className={classes.wrapBoxUpload}>
                  <i className="fa-regular fa-cloud-arrow-up fa-2x"></i>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      margin: '10px 0px'
                    }}
                  >
                    Chọn video để tải lên
                  </Typography>
                  <Typography style={{ margin: '10px 0px' }}>
                    Hoặc kéo thả tập tin
                  </Typography>
                  <Typography className={classes.subText}>
                    MP4 hoặc WebM
                  </Typography>
                  <Typography className={classes.subText}>
                    Độ phân giải 720x1280 trở lên
                  </Typography>
                  <Typography className={classes.subText}>
                    Tối thiểu 3 giây, tối đa 5 phút
                  </Typography>
                  <Typography className={classes.subText}>
                    Ít hơn 1 GB
                  </Typography>
                  <ButtonInherit label="Chọn tập tin" />

                  {textAlert && (
                    <Typography
                      style={{ fontSize: 12, color: 'red', marginTop: 12 }}
                    >
                      <i className="fa-regular fa-circle-exclamation"></i>{' '}
                      &nbsp; {textAlert}
                    </Typography>
                  )}
                </div>
                <input {...getInputProps()} id="files" name="files" />
              </div>
            )}
            <div style={{ width: '520px', marginLeft: 15 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography className={classes.wrapText}>
                      Nội dung *
                    </Typography>
                    <Typography sx={{ color: '#B8b8b8' }}>
                      {dataCreate.status.length}/150
                    </Typography>
                  </div>

                  <Mentions
                    inputRef={inputRef}
                    idInput={'textInputCreateMoment'}
                    placeholder="Nhập nội dung..."
                    style={{
                      width: '100%',
                      height: '120px',
                      marginLeft: '0px',
                      marginTop: '6px',
                      border: '1px solid #ced4da',
                      borderRadius: '6px'
                    }}
                    localContent={dataCreate.status}
                    handleChange={handleChange}
                    handlePaste={handlePaste}
                    debounceSearchGlobal={debounceSearchGlobal}
                    handleActionMention={handleActionMention}
                    handleKeyDown={handleKeyDown}
                  />
                  <Typography style={{ color: '#ff4d4d', fontSize: '14px' }}>
                    {errorText && errorText}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.wrapText}>
                    Ảnh bìa hiển thị
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                      height: 184,
                      border: '1px solid rgba(0,0,0,0.12)',
                      display: 'flex',
                      marginTop: 10
                    }}
                  >
                    {thumbnails?.map((el: any, index) => (
                      <img
                        className={
                          showUrl === el ? classes.imgSelected : classes.img
                        }
                        onClick={() => setShowUrl(el)}
                        key={index}
                        alt=""
                        src={el}
                      />
                    ))}
                  </div>
                </Grid>
                {eventInfo ? null : (
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    item
                    xs={12}
                  >
                    <Typography className={classes.wrapText}>
                      Ai có thể xem video này: &nbsp;
                    </Typography>
                    {groupId ? (
                      <Tooltip
                        title={
                          groupVisibility ? `Thành viên của ${groupTitle}` : ''
                        }
                        sx={{
                          width: '50%'
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <i
                            style={{ marginTop: 1, fontSize: '12px' }}
                            className={
                              groupVisibility
                                ? 'fa-solid fa-users'
                                : 'fas fa-globe-asia'
                            }
                          ></i>{' '}
                          &nbsp;
                          <Typography
                            className={classes.textLabel}
                            style={{ width: '100%', wordWrap: 'break-word' }}
                          >
                            <span
                              className={classes.lineClamp}
                              style={{ width: '95%' }}
                            >
                              {groupVisibility
                                ? `Thành viên của ${groupTitle}`
                                : 'Công khai'}
                            </span>
                          </Typography>
                        </div>
                      </Tooltip>
                    ) : (
                      <VisibilityComponent
                        visibility={visibility}
                        setVisibility={setVisibility}
                        styleVisibility={
                          type === 'project' || type === 'course'
                            ? { pointerEvents: 'none', opacity: 0.6 }
                            : null
                        }
                        listVisibility={
                          match.path === PATHS.PAGE_DETAIL ||
                          match.path === PATHS.WATCH_PAGE
                            ? listVisibilityPage
                            : listVisibility
                        }
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <ButtonInherit
            action={() => {
              handleClose();
            }}
            label="Hủy"
          />
          <ButtonInherit
            action={() => {
              setIsOpen(false);
              dispatch(resetPostLoad());
              createPostRequest();
            }}
            disabled={checkCreateMoment}
            color={buttonColor.backgroundColor}
            label="Đăng bài"
            style={{ marginRight: 0 }}
          />
        </DialogActions>
      </Dialog>
      {openDialogPush && (
        <DialogConfirm
          open={openDialogPush}
          handleClose={() => {
            setOpenDialogPush(false);
            setOpen(false);
          }}
          title="Video của bạn đã được tải lên."
          alignText="center"
          contentDialog={
            <ListButton
              item={[
                {
                  id: 'detail',
                  title: 'Xem khoảnh khắc',
                  styleTitle: { textAlign: 'center' },
                  styleText: { width: '100%' },
                  action: () => {
                    history.push(`/user/${meInfo?.username ?? meInfo?.id}`);
                    setOpenDialogPush(false);
                    handleClose();
                  }
                },
                {
                  id: 'none',
                  title: 'Tải thêm video',
                  styleTitle: { textAlign: 'center' },
                  styleText: { width: '100%' },
                  action: () => {
                    setOpen(true);
                    setOpenDialogPush(false);
                    setFiles([]);
                    setDataCreate({
                      sensitive: false,
                      status: '',
                      post_type: 'moment'
                    });
                    setThumbnails([]);
                  }
                }
              ]}
              typeAction={'action'}
            />
          }
          hiddenButton={true}
          maxWidth={'xs'}
        />
      )}
    </>
  );
}
