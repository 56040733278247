import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface BasicCourseInfomation {
  infoUpdate?: any;
  categories?: any;
  fetchCategories?: any;
  formik?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  courseInfo?: any;
  match?: any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const BasicCourseInfomation: React.FC<BasicCourseInfomation> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    categories,
    fetchCategories,
    formik,
    setCheckStep,
    courseInfo,
    match,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);
  const [CheckDuration, setCheckDuration] = React.useState<any>(0);
  const [flag, setFlag] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  React.useEffect(() => {
    if (setCheckStep) {
      if (
        formik.values?.title.trim() &&
        (formik.values?.course_category_id &&
        formik.values?.course_category_id.subcategories?.length
          ? formik.values?.course_category_subjects
          : formik.values?.course_category_id) &&
        formik.values?.banner &&
        Object.keys(formik.errors)?.length === 0 &&
        CheckDuration < 180
      ) {
        setCheckStep(prev => ({ ...prev, step1: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step1: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);
  React.useEffect(() => {
    if (CheckDuration !== null && CheckDuration > 180) {
      setOpenSnackbar(true);
    }
  }, [CheckDuration]);

  let checkShowVideo = null;

  React.useEffect(() => {
    if (CheckDuration !== 0) {
      if (CheckDuration > 180) {
        if (!flag) {
          setFlag(true);
          setOpenSnackbar(true);
        }
      }
    } else {
      checkShowVideo = formik?.values?.introduction_video;
    }
  }, [CheckDuration]);

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'VD: Khóa học Marketing,Designer,PHP ...',
      label: 'Tiêu đề khóa học',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.title
    },
    {
      field_type: 'autocomplete',
      name: 'course_category_id',
      options: categories,
      multiple: false,
      setOptions: fetchCategories,
      placeholder: 'VD: Chuyên ngành Marketing, Designer, ...',
      descriptionLabel: 'Bắt buộc*',
      label: 'Chuyên ngành'
    },
    {
      field_type:
        formik.values.course_category_id !== undefined &&
        formik?.values?.course_category_id?.subcategories?.length > 0
          ? 'autocomplete'
          : null,
      name: 'course_category_subjects',
      options:
        formik?.values?.course_category_id !== undefined
          ? formik?.values?.course_category_id?.subcategories
          : null,
      multiple: false,
      setOptions: fetchCategories,
      placeholder: 'VD: Marketing, Designer, ...',
      descriptionLabel: 'Bắt buộc*',
      label: 'Môn học'
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      descriptionLabel: 'Bắt buộc*',
      type: 'file_media',
      typeCourse: 'file_course',
      height: 160,
      default_media: courseInfo?.banner
        ? courseInfo?.banner
        : formik.values.banner
    },
    {
      field_type: 'upload_media',
      name: 'introduction_video',
      label: 'Video Trailer',
      type: 'file',
      type_video: 'file_video_intro',
      typeCourse: 'file_course',
      height: '160px !important',
      default_media: courseInfo?.introduction_video
        ? courseInfo?.introduction_video
        : formik.values.introduction_video
    }
  ];
  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        categories={categories}
        spacing={0}
        match={match}
        CheckDuration={CheckDuration}
        setCheckDuration={setCheckDuration}
        setNotificationLeave={setNotificationLeave}
      />
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={400}
        message={'Thời lượng video tối đa là 3 phút!'}
      />
    </Box>
  );
};

export default BasicCourseInfomation;
