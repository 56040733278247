import * as types from 'src/constants/saga/watchSocial';
export const watchSelectedAction = (watch: any) => ({
  type: types.WATCH_SELECTED,
  payload: {
    watch
  }
});

export const postSelectedAction = (post: any) => ({
  type: types.POST_SELECTED,
  payload: {
    post
  }
});
export const dataActivity = (data: any) => ({
  type: types.DATA_ACTIVITY,
  payload: {
    data
  }
});

export const resetWatchSelectedAction = () => ({
  type: types.RESET_WATCH_SELECTED
});

export const timePlayAction = time => ({
  type: types.TIME_PLAY_SELECTED,
  payload: {
    time
  }
});
export const resetTimePlayAction = time => ({
  type: types.RESET_TIME_PLAY_SELECTED
});
export const watchSaveTimeRun = (idWatch: any, timePlay: any, openDialog = false) => ({
  type: types.WATCH_SAVE_TIME_RUN,
  payload: {
    idWatch,
    timePlay,
    openDialog
  }
});
export const volumePlayAction = volume => ({
  type: types.VOLUME_PLAY_SELECTED,
  payload: {
    volume
  }
});

export const searchWatchVideos = (data: any, key: string, type: string) => ({
  type: types.WATCH_SEARCH,
  payload: {
    data,
    key,
    type
  }
});

export const searchMoment = (data: any) => ({
  type: types.SEARCH_MOMENT,
  payload: {
    data
  }
});
export const resetMomentSearch = () => ({
  type: types.RESET_MOMENT_SEARCH
});

export const miniPlayer = (data: any) => ({
  type: types.SHOW_MINI_PLAYER,
  payload: {
    data
  }
});

export const changeReaction = (data: any) => ({
  type: types.CHANGE_REACTION,
  payload: {
    data
  }
});
