/* eslint-disable react-hooks/exhaustive-deps */ import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  Typography
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import NotificationUi from 'src/Notification/NotificationUi';
import {
  postDonateAccountUser,
  postDonatePage
} from 'src/apis/socialPayment.api';
import {
  getPreviewUrlApi,
  reactionPostApi,
  unReactionPostApi
} from 'src/apis/socialPost.api';
import logo from 'src/assets/images/LogoEmso.svg';
import CommentBox from 'src/components/CommentBox/Index';
import DialogPayment from 'src/components/Dialog/DialogPayment';
import DialogPaymentDetail from 'src/components/Dialog/DialogPaymentDetail';
import DialogViewReaction from 'src/components/Dialog/DialogViewReation';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import NameSocial from 'src/components/ProfileCardImage/NameSocial';
import ReactEmoji from 'src/components/ReactEmoji/Index';
import RenderReactionImg from 'src/components/ReactEmoji/RenderReactionImg';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import TextCollapse from 'src/components/TextCollapse/Index';
import { discountData, listReactCount } from 'src/constants/common';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { buttonColor } from 'src/constants/styles';
import { urlify } from 'src/helpers/common';
import { handleTimeShow } from 'src/helpers/string';
import Media from '../../SocialContent/Media';
import MenuComment from './MenuComment';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const setEmojiReactShow = (id: number, type) => {
  switch (id) {
    case EnumEmojiReactType.like:
      return <span style={{ color: buttonColor.backgroundColor }}>Thích</span>;
    case EnumEmojiReactType.angry:
      return <span style={{ color: '#e9710f' }}>Phẫn nộ</span>;
    case EnumEmojiReactType.love:
      return <span style={{ color: '#f33e58' }}>Yêu thích</span>;
    case EnumEmojiReactType.haha:
      return <span style={{ color: '#f7b125' }}>Haha</span>;
    case EnumEmojiReactType.sad:
      return <span style={{ color: '#f7b125' }}>Buồn</span>;
    case EnumEmojiReactType.wow:
      return <span style={{ color: '#f7b125' }}>Bất ngờ</span>;
    case EnumEmojiReactType.yay:
      return <span style={{ color: '#f7b125' }}>Tự hào</span>;
    default:
      return <span>Thích</span>;
  }
};

interface Props {
  info?: any;
  children?: any;
  style?: any;
  type?: String;
  createComment?: any;
  setListComment?: React.Dispatch<React.SetStateAction<any>> | any;
  gifSelected?: any;
  setGifSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  stickySelected?: React.Dispatch<React.SetStateAction<any>> | any;
  setStickySelected?: React.Dispatch<React.SetStateAction<any>> | any;
  commentElement?: any;
  setIsShowBoxChatChild?: React.Dispatch<React.SetStateAction<any>> | any;
  itemChildren?: any;
  post?: any;
  setCommentInfo?: React.Dispatch<React.SetStateAction<any>> | any;
  commentChildRef?: any;
  setRespondent?: React.Dispatch<React.SetStateAction<any>> | any;
  keyId?: any;
}

const Comment = React.memo((props: Props) => {
  const {
    info,
    children,
    style,
    type,
    createComment,
    setListComment,
    gifSelected,
    setGifSelected,
    stickySelected,
    setStickySelected,
    commentElement,
    setIsShowBoxChatChild,
    itemChildren,
    post,
    setCommentInfo,
    commentChildRef,
    setRespondent,
    keyId
  } = props;
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [emojiReactId, setEmojiReactId] = React.useState(-1);
  const emojiReactShow = setEmojiReactShow(emojiReactId, type);
  const [optionDisplay, setOptionDisplay] = React.useState<any>('hidden');
  const [isShowBoxChat, setIsShowBoxChat] = React.useState(false);
  const [countReaction, setCountReaction] = React.useState<any>(0);
  const [postComment, setPostComment] = React.useState<any>(null);
  const [openViewReaction, setOpenViewReaction] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [listReactionComment, setListReactionComment] = React.useState<any>([]);
  const [anchorElPayment, setAnchorElPayment] =
    React.useState<HTMLButtonElement | null>(null);
  const [exchangeSelected, setExchangeSelected] = React.useState<any>(
    discountData[0]
  );
  const [resNameDataDonate, setResNameDataDonate] = React.useState<string>('');
  const [openDialogPaymentDetail, setOpenDialogPaymentDetail] =
    React.useState<any>(false);
  const [previewComent, setPreviewComent] = React.useState<any>(null);
  const [loadingDonate, setLoadingDonate] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [replyActionComment, setReplyActionComment] = React.useState<any>('');
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [openNotiDonate, setOpenNotiDonate] = React.useState<boolean>(false);
  const roleInteract: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const theme: any = useTheme();
  const ref: any = useRef();
  const refTxt: any = useRef();
  const refMedia: any = useRef();
  const checkRoleGroup = (roleGroupMember: any) => {
    if (roleGroupMember?.admin) {
      return 'Quản trị viên';
    } else if (roleGroupMember?.moderator) {
      return 'Người kiểm duyệt';
    } else if (roleGroupMember?.member) {
      return 'Thành viên';
    }
    return null;
  };
  const commentParentRef: any = useRef();
  const handleCheckLink = async (linkFirst: any) => {
    try {
      let res: any = await getPreviewUrlApi({
        urls: [linkFirst + '']
      });
      if (res.status === 200) {
        setPreviewComent(res?.data[0]);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (urlify(info.content)?.length) {
      let linkFirst = urlify(info.content)[0];
      handleCheckLink(linkFirst);
    }
  }, [info.content]);

  useEffect(() => {
    if (info.id) {
      setPostComment(info);
      setListReactionComment(info.reactions);
    }
  }, [info.id]);

  useEffect(() => {
    if (postComment?.id) {
      setListReactionComment(postComment.reactions);
    }
  }, [JSON.stringify(postComment)]);

  let typeCount: number = postComment?.reactions
    ?.map((el: any) => Object.values(el)[1])
    .filter(item => item).length;

  useEffect(() => {
    let _emojiReactId = parseInt(
      EnumEmojiReactType[postComment?.viewer_reaction ?? 'default']
    );
    setEmojiReactId(_emojiReactId);
  }, [postComment?.viewer_reaction]);
  useEffect(() => {
    if (postComment) {
      let countReactionEmoji = listReactCount?.reduce(
        (total, el) => total + getReactCount(el),
        0
      );
      setCountReaction(countReactionEmoji);
    }
  }, [JSON.stringify(postComment)]);

  const handleOpenPayment = (event: any) => {
    setAnchorElPayment(event.currentTarget);
  };

  const handlePaymentDonate = async (data: any) => {
    try {
      let res;
      if (postComment?.page_owner) {
        res = await postDonatePage(data);
      } else {
        res = await postDonateAccountUser(data);
      }
      if (res.status === 200) {
        setLoadingDonate(false);
        setResNameDataDonate(res.data.target_account.display_name);
        setOpenNotiDonate(true);
      }
    } catch (err) {
      setLoadingDonate(false);
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Donate thất bại!'
      });
    }
  };
  const useStyles = makeStyles((theme: any) => {
    let reactionStyle = {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '2px',
      height: '20px',
      color: theme.palette.mode === 'dark' ? '#ADAFB5' : '#757575',
      boxShadow: theme.palette.mode === 'dark' ? '' : '1px 1px 3px #EAEAEA',
      backgroundColor: theme.palette.mode === 'dark' ? '#3A3B3C' : '#fff',
      borderRadius: 10,
      '&:hover': {
        cursor: 'pointer'
      }
    };
    return createStyles({
      root: {
        backgroundColor: theme.palette.background.paper
      },
      inline: {
        display: 'inline',
        padding: 3.5,
        fontWeight: '600 !important',
        color: '#65676b',
        fontSize: '12px !important',
        '&:hover': {
          textDecoration: 'underline'
        },
        cursor: 'pointer'
      },
      inlineDisable: {
        display: 'inline',
        padding: 3.5,
        fontWeight: '600 !important',
        fontSize: '12px !important',
        cursor: 'default',
        color: '#999999 !important'
      },
      txtSpan: {
        '&:hover': {
          textDecoration: 'underline !important'
        }
      },
      listItem_parent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'inherit !important',
        padding: '4px 0px 0px 0px !important'
      },
      content: {
        display: isUpdate ? 'block' : 'flex',
        alignItems: 'center'
      },
      contentTxt: {
        borderRadius: 18,
        padding: '8px 12px',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        marginRight: 0,
        color: theme.palette.text.primary,
        marginBottom: 2
      },
      contentNoTxt: {
        paddingTop: 4,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        marginRight: 0,
        color: theme.palette.text.primary
      },

      reactionCount: {
        ...reactionStyle,
        position: 'absolute',
        bottom: refTxt?.current?.clientWidth > 208 ? '-15px' : '0px',
        right:
          refTxt?.current?.clientWidth > 208
            ? '0%'
            : typeCount === 1
            ? '-15px'
            : typeCount === 2
            ? '-39px'
            : '-53px',
        visibility:
          postComment?.media_attachments?.length ||
          postComment?.card ||
          postComment?.card
            ? 'hidden'
            : 'visible'
      },
      reactionMedia: {
        ...reactionStyle,
        position: 'absolute',
        top: !postComment?.card
          ? refMedia?.current?.clientHeight + refTxt?.current?.clientHeight + 20
          : undefined,
        bottom: postComment?.card?.description === 'sticky' ? undefined : 18,
        right: postComment?.in_reply_to_parent_id ? '68px' : '36px'
      },
      parent: {
        position: 'relative',
        display: isUpdate ? 'block' : 'flex',
        marginRight: isUpdate ? 0 : 10,
        maxWidth: isUpdate
          ? info?.in_reply_to_parent_id
            ? 'calc(100% - 33px) !important'
            : '100%'
          : postComment?.in_reply_to_parent_id
          ? 'calc(100% - 70px) !important'
          : 'calc(100% - 36px) !important'
      },
      child: {
        marginLeft: 5
      },
      button_action: {
        marginLeft: info?.in_reply_to_parent_id ? 0 : 5,
        display: 'inherit'
      },
      typography: {
        padding: theme.spacing(2)
      },
      popover: {
        pointerEvents: 'none'
      },
      txtName: {
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none'
        },
        fontWeight: '600 !important',
        fontSize: '14px !important'
      },
      rootAvatar: {
        minWidth: info?.in_reply_to_parent_id
          ? '30px !important'
          : '42px !important'
      },
      img: {
        maxWidth: 300,
        minHeight: 120,
        marginLeft: 44,
        borderRadius: 10
      },
      iconButton: {
        '&:hover': {
          cursor: 'pointer'
        }
      },
      contentComment: {
        whiteSpace: 'pre-wrap',
        'line-height': '20px',
        fontSize: '15px',

        '& > a': {
          color: `${buttonColor.backgroundColor} !important`,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      },
      contentTextComment: {
        whiteSpace: 'pre-wrap',
        'line-height': '20px',
        fontSize: '15px',

        '& > a': {
          color: `${buttonColor.backgroundColor} !important`,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      },
      contentTextPreComment: {
        whiteSpace: 'pre-wrap',
        'line-height': '20px',
        fontSize: '15px',
        color: `${theme.palette.text.secondary} !important`,

        '& > a': {
          color: `${buttonColor.backgroundColor} !important`,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      },
      subText: {
        color: theme.palette.text.customColor,
        fontSize: '15px !important',
        marginTop: 17,
        padding: '0px 5px 0px 5px'
      },
      iconWarning: {
        background: '#fff',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '40%',
        left: '7%'
      }
    });
  });
  const classes = useStyles();
  const getReactCount = key => {
    return postComment?.reactions?.find(el => el[key] || el[key] === 0)
      ? postComment?.reactions?.find(el => el[key] || el[key] === 0)[key]
      : 0 ?? 0;
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickOpenPopover = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangeReaction = (idReaction, viewerReaction) => {
    setListReactionComment(prev =>
      prev.map(item =>
        viewerReaction &&
        postComment?.viewer_reaction === viewerReaction &&
        item.type === viewerReaction
          ? {
              ...item,
              [`${viewerReaction}s_count`]: item[`${viewerReaction}s_count`] - 1
            }
          : item.type === idReaction
          ? {
              ...item,
              [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
            }
          : item
      )
    );
    setPostComment(prev => ({
      ...prev,
      reactions: prev.reactions.map(item =>
        viewerReaction &&
        postComment?.viewer_reaction === viewerReaction &&
        item.type === viewerReaction
          ? {
              ...item,
              [`${viewerReaction}s_count`]: item[`${viewerReaction}s_count`] - 1
            }
          : item.type === idReaction
          ? {
              ...item,
              [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
            }
          : item
      ),
      viewer_reaction: idReaction
    }));
  };

  const handleUnfavourte = idReaction => {
    setListReactionComment(prev =>
      prev.map(item =>
        item.type === idReaction
          ? {
              ...item,
              [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
            }
          : item
      )
    );
    setPostComment(prev => ({
      ...prev,
      reactions: prev.reactions.map(item =>
        item.type === idReaction
          ? {
              ...item,
              [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
            }
          : item
      ),
      viewer_reaction: null
    }));
  };

  const handleChangeReactionAfterCallAPI = response => {
    setListReactionComment(response.reactions);
    setPostComment(response);
  };
  const handleCallAPIClickVote = async (
    idReaction: number,
    idPage: string | null
  ) => {
    let response;
    let data = {
      custom_vote_type: EnumEmojiReactType[idReaction],
      page_id: idPage
    };

    if (idReaction < 0) {
      response = await unReactionPostApi(info?.id, { page_id: idPage });
      if (response.status === 200) {
      }
    } else {
      response = await reactionPostApi(info?.id, data);
      if (response.status === 200) {
        handleChangeReactionAfterCallAPI(response.data);
      }
    }
  };

  const debounceClickPost = useCallback(
    _.debounce(idReaction => {
      handleCallAPIClickVote(idReaction, roleInteract?.page_owner_id);
    }, 500),
    [roleInteract?.page_owner_id]
  );

  const handleClickVote = async (idReaction: any) => {
    if (emojiReactId < 0) {
      setEmojiReactId(idReaction);
      setCountReaction(prev => prev + 1);
    } else if (idReaction === emojiReactId) {
      setEmojiReactId(idReaction);
    } else {
      setEmojiReactId(idReaction);
      if (idReaction < 0) {
        setCountReaction(prev => prev - 1);
      }
    }
    switch (idReaction) {
      case 0:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'love':
              handleChangeReaction('like', 'love');
              break;
            case 'haha':
              handleChangeReaction('like', 'haha');
              break;
            case 'yay':
              handleChangeReaction('like', 'yay');
              break;
            case 'wow':
              handleChangeReaction('like', 'wow');
              break;
            case 'sad':
              handleChangeReaction('like', 'sad');
              break;
            case 'angry':
              handleChangeReaction('like', 'angry');
              break;
          }
        } else {
          handleChangeReaction('like', '');
        }
        break;
      case 1:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('love', 'like');
              break;
            case 'haha':
              handleChangeReaction('love', 'haha');
              break;
            case 'yay':
              handleChangeReaction('love', 'yay');
              break;
            case 'wow':
              handleChangeReaction('love', 'wow');
              break;
            case 'sad':
              handleChangeReaction('love', 'sad');
              break;
            case 'angry':
              handleChangeReaction('love', 'angry');
              break;
          }
        } else {
          handleChangeReaction('love', '');
        }
        break;
      case 2:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('haha', 'like');
              break;
            case 'love':
              handleChangeReaction('haha', 'love');
              break;
            case 'yay':
              handleChangeReaction('haha', 'yay');
              break;
            case 'wow':
              handleChangeReaction('haha', 'wow');
              break;
            case 'sad':
              handleChangeReaction('haha', 'sad');
              break;
            case 'angry':
              handleChangeReaction('haha', 'angry');
              break;
          }
        } else {
          handleChangeReaction('haha', '');
        }
        break;
      case 3:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('yay', 'like');
              break;
            case 'love':
              handleChangeReaction('yay', 'love');
              break;
            case 'haha':
              handleChangeReaction('yay', 'haha');
              break;
            case 'wow':
              handleChangeReaction('yay', 'wow');
              break;
            case 'sad':
              handleChangeReaction('yay', 'sad');
              break;
            case 'angry':
              handleChangeReaction('yay', 'angry');
              break;
          }
        } else {
          handleChangeReaction('yay', '');
        }
        break;
      case 4:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('wow', 'like');
              break;
            case 'love':
              handleChangeReaction('wow', 'love');
              break;
            case 'haha':
              handleChangeReaction('wow', 'haha');
              break;
            case 'yay':
              handleChangeReaction('wow', 'yay');
              break;
            case 'sad':
              handleChangeReaction('wow', 'sad');
              break;
            case 'angry':
              handleChangeReaction('wow', 'angry');
              break;
          }
        } else {
          handleChangeReaction('wow', '');
        }
        break;
      case 5:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('sad', 'like');
              break;
            case 'love':
              handleChangeReaction('sad', 'love');
              break;
            case 'haha':
              handleChangeReaction('sad', 'haha');
              break;
            case 'yay':
              handleChangeReaction('sad', 'yay');
              break;
            case 'wow':
              handleChangeReaction('sad', 'wow');
              break;
            case 'angry':
              handleChangeReaction('sad', 'angry');
              break;
          }
        } else {
          handleChangeReaction('sad', '');
        }
        break;
      case 6:
        if (postComment?.viewer_reaction) {
          switch (postComment?.viewer_reaction) {
            case 'like':
              handleChangeReaction('angry', 'like');
              break;
            case 'love':
              handleChangeReaction('angry', 'love');
              break;
            case 'haha':
              handleChangeReaction('angry', 'haha');
              break;
            case 'yay':
              handleChangeReaction('angry', 'yay');
              break;
            case 'wow':
              handleChangeReaction('angry', 'wow');
              break;
            case 'sad':
              handleChangeReaction('angry', 'sad');
              break;
          }
        } else {
          handleChangeReaction('angry', '');
        }
        break;
      case -1:
        switch (postComment?.viewer_reaction) {
          case 'like':
            handleUnfavourte('like');
            break;
          case 'love':
            handleUnfavourte('love');
            break;
          case 'haha':
            handleUnfavourte('haha');
            break;
          case 'yay':
            handleUnfavourte('yay');
            break;
          case 'wow':
            handleUnfavourte('wow');
            break;
          case 'sad':
            handleUnfavourte('sad');
            break;
          case 'angry':
            handleUnfavourte('angry');
            break;
        }
        break;
    }
    debounceClickPost(idReaction);
  };

  const functionUpdate = (id, data) => {
    setPostComment(data);
    setListComment(prev => {
      let indexData = prev.findIndex((el: any) => el.id === id);
      return [...prev.slice(0, indexData), data, ...prev.slice(indexData + 1)];
    });
  };

  const childrenWithProps = React.Children.map(children, child => {
    let params: any = {
      isShowBoxChat,
      commentParentRef,
      replyActionComment,
      setReplyActionComment
    };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, params);
    }
    return child;
  });

  const renderComment = () => {
    let newText = postComment?.content ?? postComment?.text;

    return newText;
  };
  const getTextComment = () => {
    // convert format [id] => @name to update comment
    if (info?.status_tags && info?.status_tags?.length) {
      let newText: any = info.content || info.text;
      const regexp = /\[\d+\]/gm;
      newText = newText.replace(regexp, userIdMention => {
        const objectMentions = info.status_tags.find(
          el =>
            el.entity_id === userIdMention.slice(1, userIdMention.length - 1)
        );

        return (
          `@[${objectMentions?.name}](${objectMentions?.entity_id})` ??
          userIdMention
        );
      });

      return newText;
      //
    } else return info.content || info.text;
  };

  // server save position as string '1,2,3', each position needs to be convert into a separate object
  const handleConvertObjectMentionServerToClient = objectMentions => {
    try {
      let objectMentionsAfterSplit = _.cloneDeep(objectMentions).map(el => {
        let positionAfterSplit = el.position?.split(',').map(el => Number(el));
        el.position = positionAfterSplit;
        return el;
      });
      let objectMentionsAfterSeparate: any[] = [];
      objectMentionsAfterSplit.map(el => {
        if (el.position.length === 1) {
          objectMentionsAfterSeparate.push({
            type: el.entity_type,
            id: el.entity_id,
            entity_id: el.entity_id,
            name: el.name,
            position: el.position[0]
          });
        } else if (el.position.length > 1) {
          el.position.map((_el, index) => {
            objectMentionsAfterSeparate.push({
              type: el.entity_type,
              id: el.entity_id,
              entity_id: el.entity_id,
              name: el.name,
              position: el.position[index]
            });
          });
        } else {
        }
      });
      return objectMentionsAfterSeparate;
    } catch (error) {
      return [];
    }
  };

  const renderReaction = () => {
    return (
      <div
        onClick={() => setOpenViewReaction(true)}
        className={classes.reactionMedia}
      >
        <span>
          <RenderReactionImg
            post={postComment}
            // reactions={postComment.reactions}
            listReaction={listReactionComment}
            isComment
          />
        </span>
        {countReaction > 1 && (
          <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
            {countReaction}
          </span>
        )}
      </div>
    );
  };

  const handleTextContent = textContent => {
    const regex = /@\[([^\]]+)\]\((\d+)\)/g;
    let contentComment = textContent.replace(regex, '[$2]');
    return contentComment;
  };
  return (
    postComment && (
      <ListItem
        id={keyId}
        sx={style ? style : 'undefined'}
        className={classes.listItem_parent}
      >
        <div
          className={classes.content}
          onMouseEnter={() => {
            setOptionDisplay('visible');
          }}
          onMouseLeave={() => {
            setOptionDisplay('hidden');
            handleClosePopover();
          }}
        >
          <div className={classes.parent}>
            {isUpdate ? (
              <CommentBox
                type={isUpdate ? 'edit' : type}
                ref={ref}
                focus={true}
                gifSelected={gifSelected}
                setGifSelected={setGifSelected}
                stickySelected={stickySelected}
                setStickySelected={setStickySelected}
                onSubmitForm={e => {
                  setPostComment(prev => ({
                    ...prev,
                    content: handleTextContent(e.textInputCommentBox),
                    media_attachments: e?.files ?? [],
                    status_tags: e?.status_tags
                  }));
                  setIsUpdate(false);
                  createComment(
                    {
                      ...e,
                      id: info.id
                    },
                    'update',
                    functionUpdate
                  );
                }}
                accept={'image/jpeg,image/png,video/mp4,video/x-m4v'}
                avatarUrl={meInfo?.avatar_media?.url ?? ''}
                post={info}
                defaultValue={{
                  text: getTextComment(),
                  files: info.media_attachments,
                  extra_body: info.card,
                  status_tags:
                    handleConvertObjectMentionServerToClient(
                      info.status_tags
                    ) ?? [],
                  tags: info.tags ?? []
                }}
                replyActionComment={replyActionComment}
              />
            ) : (
              <>
                <ListItemAvatar
                  style={{ display: 'inline-block' }}
                  classes={{ root: classes.rootAvatar }}
                >
                  <AvatarSocial
                    type="feed"
                    avatarObj={
                      info?.page_owner
                        ? info?.page_owner?.avatar_media
                        : postComment.account?.avatar_media
                    }
                    style={{
                      height: postComment?.in_reply_to_parent_id ? 24 : 32,
                      width: postComment?.in_reply_to_parent_id ? 24 : 32,
                      border: '1px solid #ccc',
                      borderRadius: '50%'
                    }}
                    isPopup={
                      !postComment?.account_relationships?.blocking &&
                      !postComment?.account_relationships?.blocked_by
                    }
                    object={info?.page_owner ?? postComment?.account}
                  />
                  {info?.haveError && (
                    <Grid className={classes.iconWarning}>
                      <i
                        className="fa-solid fa-circle-exclamation fa-lg"
                        style={{
                          color: '#ec0909',
                          padding: '6px 4px'
                        }}
                      ></i>
                    </Grid>
                  )}
                </ListItemAvatar>
                <div>
                  <Box
                    className={
                      postComment?.content?.length ?? postComment?.text?.length
                        ? classes.contentTxt
                        : classes.contentNoTxt
                    }
                    sx={{
                      backgroundColor:
                        (postComment?.media_attachments?.length &&
                          postComment?.content?.length === 0) ||
                        (postComment?.card?.provider_name === 'GIPHY' &&
                          postComment?.content?.length === 0) ||
                        (postComment?.card?.description === 'sticky' &&
                          postComment?.content?.length === 0)
                          ? 'transparent'
                          : 'background.input',
                      border: !!info?.forbidden_words
                        ? '1px solid #F44E3B'
                        : info?.haveError
                        ? '1px solid red'
                        : 'none'
                    }}
                    ref={refTxt}
                  >
                    {/* comment của page */}
                    {info?.page_owner &&
                      commentElement?.page_owner?.id &&
                      info?.page_owner?.id ===
                        commentElement?.page_owner?.id && (
                        <span
                          style={{
                            color: '#65676b',
                            padding: '1px 0px',
                            borderRadius: '5px',
                            fontWeight: 500,
                            fontSize: '12px'
                          }}
                        >
                          <i
                            className="fa-light fa-microphone-stand"
                            style={{
                              margin: '0px 5px 0px 0px',
                              color: '#393939'
                            }}
                          ></i>
                          Tác giả
                        </span>
                      )}
                    <Typography
                      className={classes.txtName}
                      sx={{ display: 'flex' }}
                    >
                      <NameSocial
                        post={postComment}
                        isPopup={
                          !postComment?.account_relationships?.blocking &&
                          !postComment?.account_relationships?.blocked_by
                        }
                        name={
                          info?.page_owner
                            ? info?.page_owner?.title
                            : postComment?.account?.display_name
                        }
                        object={info?.page_owner ?? postComment?.account}
                        style={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: info?.pre_comment
                            ? 'text.secondary !important'
                            : 'inherit',
                          '&:hover': {
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }
                        }}
                      />
                      {groupSelected &&
                        Object.keys(groupSelected)?.length !== 0 &&
                        ((postComment?.account?.id ===
                          commentElement?.account?.id &&
                          !postComment?.page_owner) || //comment của user
                          post?.account?.id === itemChildren?.account?.id) && (
                          <span
                            style={{
                              color: buttonColor.backgroundColor,
                              backgroundColor: '#e7f3ff',
                              padding: '1px 5px',
                              borderRadius: '5px',
                              fontWeight: 400,
                              marginLeft: 3
                            }}
                          >
                            Tác giả
                          </span>
                        )}
                      {postComment?.group_account_relationship ? (
                        <span
                          style={{
                            color: buttonColor.backgroundColor,
                            backgroundColor: '#e7f3ff',
                            padding: '1px 5px',
                            borderRadius: '5px',
                            fontWeight: 400,
                            marginLeft: 3
                          }}
                        >
                          {checkRoleGroup(
                            postComment?.group_account_relationship
                          )}
                        </span>
                      ) : null}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        display: 'block'
                      }}
                      color="textPrimary"
                      className={
                        info?.pre_comment
                          ? classes.contentTextPreComment
                          : classes.contentTextComment
                      }
                    >
                      <TextCollapse
                        data={renderComment()}
                        status_tags={postComment.status_tags}
                        info={info}
                        style={{
                          fontSize: '16px',
                          color: theme.palette.text.customColor
                        }}
                      />
                    </Typography>
                    {countReaction > 0 &&
                      (postComment?.content || postComment?.text) && (
                        <div
                          onClick={() => setOpenViewReaction(true)}
                          className={classes.reactionCount}
                        >
                          <span>
                            <RenderReactionImg
                              post={postComment}
                              listReaction={listReactionComment}
                              isComment
                            />
                          </span>
                          {countReaction > 1 && (
                            <span
                              style={{ fontSize: '12px', paddingLeft: '2px' }}
                            >
                              {countReaction}
                            </span>
                          )}
                        </div>
                      )}
                  </Box>

                  {previewComent && Object.keys(previewComent).length && (
                    <Link
                      href={previewComent.link}
                      underline="none"
                      target="_blank"
                    >
                      <Card
                        sx={{
                          display: 'flex',
                          borderRadius: '18px'
                        }}
                      >
                        <CardMedia
                          sx={{ height: 140, width: 140, flexShrink: 0 }}
                          image={previewComent?.url}
                        />
                        <CardContent sx={{ width: '80%' }}>
                          <Typography gutterBottom variant="h5" component="div">
                            {new URL(previewComent.link).hostname}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {`${previewComent.description.substring(0, 99)}...`}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>
          <div>
            {!isUpdate && (
              <>
                <Box
                  onClick={handleClickOpenPopover}
                  className={classes.iconButton}
                  sx={{
                    visibility: optionDisplay,
                    display: 'flex',
                    width: 30,
                    height: 30,
                    backgroundColor: 'background.secondary',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 15
                  }}
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </Box>

                <MenuComment
                  type={type}
                  meInfo={meInfo}
                  info={postComment}
                  setPostComment={setPostComment}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  setIsUpdate={setIsUpdate}
                  post={post}
                  setCommentInfo={setCommentInfo}
                />
              </>
            )}
          </div>
        </div>
        {postComment?.media_attachments?.length ? (
          isUpdate ? null : (
            <div
              style={{
                maxWidth: '80%',
                margin: postComment?.in_reply_to_parent_id
                  ? '15px 0px 0px 30px'
                  : type === 'watch-selected'
                  ? '8px 0px 0px 10px'
                  : '8px 0px 0px 42px',
                display: 'flex'
              }}
              ref={refMedia}
            >
              <Media
                type="comment"
                key={`media-comment-${postComment?.id}`}
                post={postComment}
                setPostData={setPostComment}
                isPreCommment={postComment?.pre_comment}
              />
              {countReaction > 0 && renderReaction()}
            </div>
          )
        ) : null}

        {!isUpdate &&
        postComment?.card &&
        Object.keys(postComment?.card)?.length &&
        !postComment?.media_attachments?.length &&
        (postComment?.card?.provider_name === 'GIPHY' ||
          postComment?.card?.description === 'sticky') ? (
          <div
            style={{
              margin: postComment?.in_reply_to_parent_id
                ? '15px 0px 0px 30px'
                : '8px 0px 0px 42px'
            }}
            ref={refMedia}
          >
            <img
              style={
                postComment.card.description === 'sticky'
                  ? { width: 80, height: 80 }
                  : { borderRadius: 15, width: '65%' }
              }
              src={postComment.card.url || postComment.card.link}
              alt="gif"
            />
            {countReaction > 0 && renderReaction()}
          </div>
        ) : null}

        {isUpdate ? (
          <div style={{ marginLeft: 40, marginTop: -10 }}>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
              onClick={() => setIsUpdate(false)}
            >
              Hủy
            </Typography>
          </div>
        ) : (
          <div className={classes.button_action}>
            {groupSelected?.inactivate_reason ? (
              <>&nbsp; &nbsp; &nbsp;</>
            ) : (
              <>
                {!postComment?.account_relationships?.blocking &&
                !postComment?.account_relationships?.blocked_by ? (
                  <ReactEmoji
                    onChange={id => {
                      if (postComment && postComment?.id) {
                        handleClickVote(id);
                      }
                    }}
                    isComment
                    closePopper={info?.pre_comment}
                  >
                    {(popupState, bindHover) => {
                      return info?.pre_comment ? (
                        <Typography
                          {...bindHover(popupState)}
                          component="span"
                          variant="body2"
                          style={{ marginLeft: 35 }}
                          className={classes.inlineDisable}
                          color="#444444"
                        >
                          {info?.haveError ? 'Không thể bình luận' : 'Thích'}
                        </Typography>
                      ) : (
                        <Typography
                          {...bindHover(popupState)}
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          style={{ marginLeft: 35 }}
                          color="textPrimary"
                          onClick={() => {
                            if (emojiReactId < 0) {
                              handleClickVote(EnumEmojiReactType.like);
                            } else {
                              handleClickVote(EnumEmojiReactType.default);
                            }
                          }}
                        >
                          {emojiReactShow}
                        </Typography>
                      );
                    }}
                  </ReactEmoji>
                ) : null}
                {!post?.off_comment &&
                !postComment?.account_relationships?.blocking &&
                !postComment?.account_relationships?.blocked_by ? (
                  info?.pre_comment ? (
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inlineDisable}
                      color="#444444"
                    >
                      {info?.haveError ? '' : 'Phản hồi'}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color={'textPrimary'}
                      onClick={() => {
                        if (meInfo?.id !== info?.account?.id) {
                          setReplyActionComment(
                            `@${info.account.display_name}`
                          );
                        }
                        setIsShowBoxChat(true);
                        setIsShowBoxChatChild && setIsShowBoxChatChild(true);
                        commentParentRef?.current?.firstChild.focus();
                        commentChildRef?.current?.firstChild.focus();
                        if (setRespondent) {
                          setRespondent({
                            type:
                              info.account.page_relationship || info.page_owner
                                ? 'Page'
                                : info.account.group_relationship
                                ? 'Group'
                                : 'Account',
                            id: info.page_owner
                              ? info.page_owner.id
                              : meInfo?.id !== info?.account?.id
                              ? info.account.id
                              : '',
                            entity_id: info.page_owner
                              ? info.page_owner.id
                              : meInfo?.id !== info?.account?.id
                              ? info.account.id
                              : '',
                            name: info.page_owner
                              ? info.page_owner.title
                              : meInfo?.id !== info?.account?.id
                              ? info.account.display_name
                              : '',
                            position: 1,
                            info: info,
                            updateResponse: Math.random()
                          });
                        }
                      }}
                    >
                      Phản hồi
                    </Typography>
                  )
                ) : null}
                {meInfo.id !== info?.account.id &&
                !postComment?.account_relationships?.blocking &&
                !postComment?.account_relationships?.blocked_by &&
                ((postComment?.account.earn_money &&
                  !postComment?.page_owner) ||
                  postComment?.page_owner?.earn_money) ? (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color={'textPrimary'}
                    onClick={e => handleOpenPayment(e)}
                  >
                    Tặng quà
                  </Typography>
                ) : null}
              </>
            )}
            &nbsp;
            {info?.pre_comment ? null : (
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                sx={{
                  marginLeft:
                    postComment?.account_relationships?.blocking ||
                    postComment?.account_relationships?.blocked_by
                      ? '30px'
                      : '0px'
                }}
              >
                {handleTimeShow(postComment?.created_at ?? '0')}
              </Typography>
            )}
          </div>
        )}

        <Collapse in={true}>{childrenWithProps}</Collapse>

        {openViewReaction ? (
          <DialogViewReaction
            open={openViewReaction}
            setOpen={setOpenViewReaction}
            post={postComment}
          />
        ) : null}

        {anchorElPayment && (
          <DialogPayment
            anchorEl={anchorElPayment}
            handleClosePayment={() => {
              setAnchorElPayment(null);
            }}
            subsystem={
              postComment?.page_owner
                ? postComment?.page_owner?.title
                : postComment?.account?.display_name
            }
            discountData={discountData}
            exchangeSelected={exchangeSelected}
            setExchangeSelected={setExchangeSelected}
            data={{
              title: ''
            }}
            statusPayment="donate"
            setOpenDialogPaymentDetail={setOpenDialogPaymentDetail}
            action={data => handlePaymentDonate(data)}
            infoDonate={postComment}
            loading={loadingDonate}
            setLoading={setLoadingDonate}
          />
        )}
        {openNotiDonate && (
          <NotificationUi
            openNoti={openNotiDonate}
            setOpenNoti={setOpenNotiDonate}
            component={() => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  style={{
                    cursor: 'pointer',
                    width: '50px',
                    height: 'auto'
                  }}
                  alt="Emso"
                  src={logo}
                />
                <Typography className={classes.subText}>
                  Cảm ơn bạn đã ủng hộ cho {resNameDataDonate}, hãy cùng chúng
                  tôi ủng hộ cho những điều tốt đẹp trên Mạng xã hội EMSO
                </Typography>
              </div>
            )}
          />
        )}
        {openDialogPaymentDetail && (
          <DialogPaymentDetail
            open={openDialogPaymentDetail}
            handleClose={() => {}}
            exchangeSelected={exchangeSelected}
            setOpenSnackbar={value => {}}
            setNoti={func => {}}
            setOpenDialogPaymentDetail={setOpenDialogPaymentDetail}
          />
        )}
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      </ListItem>
    )
  );
});

export default Comment;
