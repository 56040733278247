import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUpdatePlaylistApi } from 'src/apis/SocialMusic.api';
import { RegText } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import { buttonColor } from 'src/constants/styles';
import { detailAlbum } from 'src/store/action/socialMusicAction';
import * as Yup from 'yup';

export default function DialogAddPlaylist(props: any) {
  const {
    open,
    setOpen,
    setDataPlaylist,
    dataPlaylist,
    type,
    idPlaylist,
    setNoti,
    setOpenSnackbar,
    idMusic,
    handleAddMusicToPlaylist
  } = props;

  const [verify, setVerify] = React.useState<any>(false);
  const [loadingBtnCreate, setLoadingBtnCreate] = React.useState<any>(false);
  const [disableBtnCreate, setDisableBtnCreate] = React.useState<any>(false);
  const [errorItem, setErrorItem] = React.useState<any>(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleCreatUpdatePlaylist = async values => {
    try {
      let res = await createUpdatePlaylistApi(
        {
          name: values.name,
          banner: values.banner
        },
        type,
        idPlaylist
      );

      if (res.status === 200) {
        if (idMusic) {
          handleAddMusicToPlaylist(res.data.id);
        }
        dispatch(detailAlbum(res.data));
        setDataPlaylist && setDataPlaylist(prev => [res.data, ...prev]);

        setNoti({
          code: 200,
          message:
            type === 'update'
              ? 'Cập nhật danh sách phát thành công'
              : 'Tạo mới danh sách phát thành công'
        });
      }
    } catch (error: any) {
      setNoti({
        code: error.response.status,
        message:
          error.response?.data?.error || type === 'update'
            ? 'Cập nhật danh sách phát thất bại'
            : 'Tạo mới danh sách phát thất bại'
      });
    } finally {
      setLoadingBtnCreate(false);
      setOpenSnackbar && setOpenSnackbar(true);
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: null as any,
      banner: null as any
    } as any,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(RegText, 'Tên danh sách phát không được chứa ký tự đặc biệt')
        .max(150, 'Tên danh sách phát không quá 150 ký tự')
        .min(3, 'Không được để trống, tối thiểu 3 kí tự')
        .nullable()
        .transform(value => {
          if (typeof value === 'string' && value !== null) {
            return value.trim();
          }
          return value;
        })
    }),
    onSubmit: (values: any) => {
      try {
        handleCreatUpdatePlaylist(values);
        setLoadingBtnCreate(true);
      } catch (error) {
        console.log(error);
      }
    }
  } as any);

  useEffect(() => {
    if (
      formik.values.name &&
      formik.values.banner &&
      !errorItem &&
      !Object.keys(formik.errors).length
    ) {
      setDisableBtnCreate(false);
    } else {
      setDisableBtnCreate(true);
    }
  }, [JSON.stringify(formik.values), errorItem, JSON.stringify(formik.errors)]);
  useEffect(() => {
    if (type === 'update') {
      let tempData: any = {
        name: dataPlaylist.name,
        banner: dataPlaylist?.banner
      };
      formik.setValues(tempData);
    }
  }, [JSON.stringify(dataPlaylist)]);

  let createPlaylist: any = [
    {
      field_type: 'textField',
      name: 'name',
      placeholder: 'Nhập tên danh sách phát',
      label: 'Tên danh sách phát',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.name
    },

    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*',
      default_media: formik?.values?.banner
        ? formik?.values?.banner
        : dataPlaylist?.banner?.preview_url
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          {type === 'update'
            ? 'Cập nhật danh sách phát'
            : '  Tạo danh sách phát mới'}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <SocialFieldUpdate
          setVerify={setVerify}
          verify={verify}
          formik={formik}
          fieldUpdate={createPlaylist}
          setErrorItem={setErrorItem}
        />
      </DialogContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          margin: '0px 0px 15px 0px',
          padding: '0px 24px'
        }}
      >
        <ButtonInherit
          label="Hủy"
          // color={buttonColor.backgroundColor}
          // textColor="#fff"
          action={() => {
            setOpen(false);
            setLoadingBtnCreate(false);
          }}
        />
        <ButtonInherit
          loading={loadingBtnCreate}
          label={type !== 'update' ? 'Tạo mới' : 'Cập nhật'}
          color={buttonColor.backgroundColor}
          textColor="#fff"
          action={() => {
            formik.handleSubmit();
          }}
          disabled={disableBtnCreate}
        />
      </Box>
    </Dialog>
  );
}
