import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  DialogActions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';

import { handleOutGroupChat } from 'src/pages/Chat/functionChat';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { buttonColor } from 'src/constants/styles';
function DialogLeaveGroup(props) {
  const {
    open,
    setOpen,
    conversation,
    setIsToastMessage,
    idSubRocket,
    setTitleToastMessage,
    setCodeMessage,
    listAdmin
  } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const theme: any = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const handleLeaveGroupChat = async () => {
    await handleOutGroupChat(
      idSubRocket,
      conversation,
      dispatch,
      history,
      match,
      setIsToastMessage,
      setTitleToastMessage,
      rocketId,
      setCodeMessage
    );

    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: '200px',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              {listAdmin &&
              listAdmin.length === 1 &&
              listAdmin[0].u._id === rocketId
                ? 'Rời đi mà không chọn Quản trị viên ?'
                : ' Rời khỏi cuộc trò chuyện này ?'}
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <Typography style={{ fontSize: '14px', padding: '12px 0 0' }}>
            {listAdmin &&
            listAdmin.length === 1 &&
            listAdmin[0].u._id === rocketId
              ? 'Bạn có thể chọn quản trị viên mới trong số những người có tên ở phần Thành viên. Nếu bạn rời khỏi nhóm mà không chọn ai thay thế, thành viên kỳ cựu nhất của nhóm sẽ trở thành quản trị viên.'
              : 'Bạn sẽ không nhận được tin nhắn từ cuộc trò chuyện này nữa và mọi người sẽ thấy bạn rời nhóm.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              // color: buttonColor.backgroundColor,
              // backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Rời khỏi nhóm'}
            style={{
              width: '120px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: theme.palette.background.secondary,

              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleLeaveGroupChat}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogLeaveGroup;
