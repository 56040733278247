import { apiDelete, apiGet, apiPatch, apiPost } from './apis';

export const getCalendarRecruitApi = async (params: any) => {
  return apiGet('recruits', params, null);
};
export const getListApplyRecruit = async (params: any) => {
  return apiGet('recruit_applies', params, null);
};

export const createUpdateRecruitApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  if (data.banner) {
    if (data.banner.file?.path && !data.banner.id) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    if (data.banner.show_url) {
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }
  if (data.work_experience) {
    formdata.append('work_experience', data.work_experience);
  }
  if (data.working_form) {
    formdata.append('working_form', data.working_form);
  }
  if (data.level) {
    formdata.append('level', data.level);
  }
  if (data.recruitments_count) {
    formdata.append('recruitments_count', data.recruitments_count);
  }
  if (data.gender) {
    formdata.append('gender', data.gender);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.recruit_category_id) {
    formdata.append('recruit_category_id', data.recruit_category_id);
  }
  if (data.salary_min) {
    formdata.append('salary_min', data.salary_min);
  }
  if (data.salary_max) {
    formdata.append('salary_max', data.salary_max);
  }
  if (data.job_description) {
    formdata.append('job_description', data.job_description);
  }
  if (data.company_description) {
    formdata.append('company_description', data.company_description);
  }
  if (data.requirement) {
    formdata.append('requirement', data.requirement);
  }
  if (data.benefits) {
    formdata.append('benefits', data.benefits);
  }
  if (data.due_date) {
    formdata.append('due_date', data.due_date);
  }
  if (data.province) {
    formdata.append('province_id', data.province);
  }
  if (data.district) {
    formdata.append('district_id', data.district);
  }
    formdata.append('address', data.address);
  if (data.location) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }

  if (type === 'create') {
    return apiPost('recruits', null, formdata);
  }

  return apiPatch(`recruits/${type}`, null, formdata);
};

export const getDetailRecruitApi = async (idRecruit: any) => {
  return apiGet(`recruits/${idRecruit}`, null, null);
};

export const getRecruitCategories = async () => {
  return apiGet('recruit_categories', null, null);
};

export const deleteRecruitApi = async (id: any) => {
  return apiDelete(`recruits/${id}`, null, null);
};

export const getTimelineRecruitApi = async (idRecruit: any, params: any) => {
  return apiGet(`timelines/recruit/${idRecruit}`, params, null);
};

export const createInviteRecruit = async (idRecruit: any, params: any) => {
  return apiPost(`recruits/${idRecruit}/invitations`, null, {
    target_account_ids: params
  });
};

export const interesteRecruitApi = async (idRecruit: any) => {
  return apiPost(`recruits/${idRecruit}/recruit_followers`, null, null);
};
export const noInteresteRecruitApi = async (idRecruit: any) => {
  return apiDelete(
    `recruits/${idRecruit}/recruit_followers`,

    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return apiGet('recruit_invitations', params, null);
};

export const respondInvitationRecruit = async (idRecruit: any, params: any) => {
  return apiPost(
    `recruits/${idRecruit}/invitations/invitations_respond`,
    null,
    params
  );
};

export const getListHostOfRecruit = async (idRecruit: any, params: any) => {
  return apiGet(`/recruits/${idRecruit}/invitation_hosts`, params, null);
};

export const respondInvitationHostRecruit = async (
  idRecruit: any,
  data: any
) => {
  return apiPost(
    `recruits/${idRecruit}/invitation_hosts/invitations_respond`,
    null,
    data
  );
};

export const getListHostRecruit = async (idRecruit: any, params: any) => {
  return apiGet(`recruits/${idRecruit}/hosts`, params, null);
};

export const getListFaqs = async (idRecruit: any) => {
  return apiGet(`recruits/${idRecruit}/faqs`, null, null);
};

export const createUpdateCV = async (data: any, idUser: any, type: any) => {
  if (type === 'create') {
    return apiPost(`accounts/${idUser}/account_resumes`, null, data);
  }

  return apiPatch(`accounts/${idUser}/account_resumes/${type}`, null, data);
};

export const getListCV = async (idUser: any) => {
  return apiGet(`accounts/${idUser}/account_resumes`, null, null);
};

export const deleteCV = async (idUser: any, idCV: any) => {
  return apiDelete(`accounts/${idUser}/account_resumes/${idCV}`, null, null);
};

export const getListUserApplyRecruit = async (
  idRecruit: any,
  action: any,
  params = null
) => {
  if (action === 'POST') {
    return apiPost(`recruits/${idRecruit}/recruit_applies`, null, params);
  }

  return apiGet(`recruits/${idRecruit}/recruit_applies`, null, null);
};
