import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createUpdateCourseApi,
  getListHostOfCourse
} from 'src/apis/socialCourse.api';
import { searchFriendUser } from 'src/apis/socialUser';
import { genderSubject, privacyOptions } from 'src/constants/common';
import {
  coursePreview,
  getCourseDetailRequest
} from 'src/store/action/socialCourseAction';
import * as Yup from 'yup';
import BasicCourseInfomation from './StepCUCourse/BasicCourseInfomation';
import CandidateInformation from './StepCUCourse/CandidateInformation';
import CourseMedia from './StepCUCourse/CourseMedia';
import CustomPrompt from 'src/components/CustomPrompt';
import { RegText } from 'src/common/string';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px 20px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface CourseCreateUpdate {
  infoUpdate?: any;
  createCourse?: any;
  setCreateCourse?: React.Dispatch<React.SetStateAction<any>> | any;
  updateCourse?: any;
  setUpdateCourse?: React.Dispatch<React.SetStateAction<any>> | any;
  idUpdate?: string | any;
  organization?: any;
  setCheckCreateUpdate?: React.Dispatch<React.SetStateAction<any>> | any;
  categories?: any;
  fetchCategories?: any;
  activeStep?: any;
  match?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
}

const CourseCreateUpdate: React.FC<CourseCreateUpdate> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const courseInfo = useSelector((state: any) => state.courseReducer.info);
  const meInfo = useSelector((state: any) => state.meReducer.info);

  const {
    infoUpdate,
    createCourse,
    setCreateCourse,
    updateCourse,
    setUpdateCourse,
    idUpdate,
    organization,
    setCheckCreateUpdate,
    categories,
    fetchCategories,
    activeStep,
    match,
    setCheckStep,
    setNoti,
    setOpenSnackbar
  } = props;
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [hostTeacherAdd, setHostTeacherAdd] = React.useState([] as any);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    title: Yup.string()
      // .matches(RegText, 'Tên khóa học không được chứa ký tự đặc biệt')
      .transform(value => (typeof value === 'string' ? value.trim() : value))
      .min(20, 'Tên khoá học phải có tối thiểu 20 ký tự')
      .max(120, 'Tên khoá học không vượt quá 120 ký tự')
      .nullable(),

    benefits: Yup.string()
      .max(3000, 'Không được vượt quá 3000 ký tự'),
    target_audience: Yup.string()
      .max(255, 'Không được vượt quá 255 ký tự'),
    description_company: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .min(10, 'Không được để trống , tối thiểu 10 ký tự')
      .max(3000, 'Không được vượt quá 3000 ký tự')
      .nullable(),

    description_course: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .min(10, 'Không được để trống , tối thiểu 10 ký tự')
      .max(3000, 'Không được vượt quá 3000 ký tự')
      .nullable(),

    refund_policy: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .min(10, 'Không được để trống, tối thiểu 10 ký tự')
      .max(3000, 'Không được vượt quá 3000 ký tự')
      .nullable(),

    price: Yup.number()
      .integer('Giá tiền phải là số, không chứa ký tự đặc biệt')
      .positive('Số tiền phải lớn hơn 0')
      .test('min', 'Số tiền thấp nhất là 10.000 VND', function (price, context) {
        const { parent } = context;
        if (parent.free) {
          return true;
        }
        if (!parent.free && typeof price === 'number') {
          return price >= 10000;
        }

        return false;
      })
      .max(100000000, 'Số tiền không được vượt quá 100.000.000 VND.')
      .typeError('Số tiền nhập không hợp lệ.Vui lòng nhập lại!!')
      .nullable(),
    phone_number: Yup.string()
      .matches(
        phoneRegExp,
        'Số điện thoại chỉ được chứa số, đầu cuối không được chứa khoảng cách'
      )
      .max(10, 'Số điện thoại không được dài hơn 10 ký tự.')
      .min(10, 'Số điện thoại phải có ít nhất 10 ký tự.')
      .nullable(),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (
          value?.file?.file &&
          (value?.file?.file instanceof File ||
            value?.file?.file instanceof Blob)
        ) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }
        return true;
      }
    ),
    introduction_video: Yup.mixed()
      .test('fileSize', 'Video không được quá 3 phút!', (value: any) => {
        if (!value) {
          return true;
        }
        if (value && value.meta) {
          return value?.meta?.original?.duration <= 180;
        } else {
          return value && value?.file?.file?.meta?.original?.duration <= 180;
        }
      })
      .nullable()
  });

  React.useEffect(() => {
    if (courseInfo?.id) {
      fetchInviteHostCourse(courseInfo.id);
    }
  }, [JSON.stringify(courseInfo)]);

  React.useEffect(() => {
    if (courseInfo?.id) {
      let tempData: any = {
        title: courseInfo?.title,
        course_category_id: courseInfo?.course_category?.parent_category,
        course_category_subjects: courseInfo?.course_category
          ? courseInfo?.course_category
          : null,
        banner: courseInfo?.banner ? courseInfo?.banner : null,
        introduction_video: courseInfo?.introduction_video
          ? courseInfo?.introduction_video
          : null,
        benefits: courseInfo?.benefits,
        target_audience: courseInfo?.target_audience,
        description_course: courseInfo?.description_course,
        description_company: courseInfo?.description_company,
        refund_policy: courseInfo?.refund_policy,
        allow_discussion:
          courseInfo?.allow_discussion !== undefined
            ? courseInfo?.allow_discussion
            : true,
        // options_price: courseInfo?.options_price,
        price: parseInt(courseInfo?.price),
        free: courseInfo?.free,
        course_host_added: courseInfo?.course_host_added || [],
        phone_number: courseInfo?.phone_number,
        age_restrictions: genderSubject?.find(
          (el: any) => el.id === courseInfo?.age_restrictions
        ),
        visibility: privacyOptions?.find(
          (el: any) => el.id === courseInfo?.visibility
        ),
        address: courseInfo?.address,
        location: null,
        course_host_removed: []
      };
      formik.setValues(tempData);
    }
  }, [JSON.stringify(courseInfo)]);
  const handleCreateUpdateCourse = async (type: any) => {
    try {
      let response: any = await createUpdateCourseApi(
        {
          title: formik.values.title,
          course_category_id: formik.values.course_category_subjects.id,
          banner: formik.values.banner
            ? {
                file: formik.values.banner?.file?.file ?? '',
                id: formik.values.banner?.file?.id ?? null,
                show_url: formik.values.banner.show_url
              }
            : null,
          introduction_video:
            formik.values.introduction_video ?? null
              ? {
                  file: formik.values.introduction_video?.file?.file ?? '',
                  id: formik.values.introduction_video?.id ?? null,
                  show_url: formik.values.introduction_video.show_url
                }
              : null,
          benefits: formik.values.benefits || '',
          target_audience: formik.values.target_audience || '',
          description_course: formik.values.description_course,
          description_company: formik.values.description_company,
          refund_policy: formik.values.refund_policy,
          allow_discussion: formik.values.allow_discussion,
          price: formik.values.free !== true ? formik.values.price : null,
          free: formik.values.free,
          course_host_added: formik.values.course_host_added?.map(
            (el: any) => el?.id
          ),
          course_host_removed: formik.values.course_host_removed?.map(
            (el: any) => el?.id
          ),
          phone_number: formik.values.phone_number,
          age_restrictions: formik.values.age_restrictions.id,
          visibility: formik.values.visibility.id,
          address: formik.values.address || null,
          page_owner_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null
        },
        type
      );

      if (response.status === 200) {
        setOpenSnackbar(true);
        setNotificationLeave(false);
        if (createCourse) {
          setCreateCourse(false);
          dispatch(getCourseDetailRequest(response.data.id));
          history.push(`/course/${response.data.id}/edit`);
          // history.push('/courses/home');
          setNoti({
            code: 200,
            message: 'Lưu bản nháp thành công'
          });
        }
        if (updateCourse) {
          setUpdateCourse(false);
          dispatch(getCourseDetailRequest(type));
          // history.push(`/course/${type}/about`);
          setNoti({
            code: 200,
            message: 'Cập nhập khoá học thành công.'
          });
        }
      }
    } catch (error) {
      console.log(error);
      setOpenSnackbar(true);
      if (createCourse) {
        setCreateCourse(false);
        setNoti({
          code: 400,
          message: 'Tạo khoá học thất bại.'
        });
      }
      if (updateCourse) {
        setUpdateCourse(false);
        dispatch(getCourseDetailRequest(type));
        setNoti({
          code: 400,
          message: 'cập nhập khoá học thất bại.'
        });
      }
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      course_category_id: null as any,
      course_category_subjects: null as any,
      start_date: null,
      due_date: null as any,
      banner: null as any,
      introduction_video: null as any,
      benefits: '',
      target_audience: '',
      description_course: '',
      description_company: '',
      refund_policy: '',
      allow_discussion: true,
      price: null as any,
      free: true,
      course_host_added: [],
      phone_number: null as any,
      age_restrictions: genderSubject[0],
      visibility: privacyOptions[0],
      location: null,
      address: null as any,
      course_host_removed: []
    },
    onSubmit: values => {
      try {
        if (createCourse && values.title) {
          handleCreateUpdateCourse('create');
          setCheckCreateUpdate(false);
        }
        if (updateCourse && idUpdate) {
          handleCreateUpdateCourse(idUpdate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  const fetchHostTeacher = async (keyword: any) => {
    let response: any = await searchFriendUser(meInfo?.id, {
      keyword: keyword === '' ? ' ' : keyword
    });
    if (response.status === 200) {
      setHostTeacherAdd(
        response.data?.data.map((el: any) => ({
          id: el.id,
          display_name: el.display_name,
          avatar:
            el.avatar_media?.show_url ?? el.avatar_media?.preview_url
              ? el.avatar_media?.show_url ?? el.avatar_media?.preview_url
              : el.avatar_static
        }))
      );
    }
  };
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <BasicCourseInfomation
            formik={formik}
            infoUpdate={infoUpdate}
            categories={categories}
            fetchCategories={fetchCategories}
            setCheckStep={setCheckStep}
            courseInfo={courseInfo}
            match={match}
            setNotificationLeave={setNotificationLeave}
          />
        );

      case 1:
        return (
          <CandidateInformation
            formik={formik}
            infoUpdate={infoUpdate}
            setCheckStep={setCheckStep}
            organization={organization}
            setNotificationLeave={setNotificationLeave}
          />
        );

      case 2:
        return (
          <CourseMedia
            formik={formik}
            infoUpdate={infoUpdate}
            hostTeacherAdd={hostTeacherAdd}
            fetchHostTeacher={fetchHostTeacher}
            setNotificationLeave={setNotificationLeave}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }
  const fetchInviteHostCourse = async (id: any) => {
    let response: any = await getListHostOfCourse(id, null);
    if (response.status === 200) {
      let dataDefault: any = response.data.map((el: any) => ({
        id: el?.target_account?.id,
        display_name: el?.target_account?.display_name,
        avatar: el?.target_account?.avatar_url
          ? el?.target_account?.avatar_url
          : el?.target_account?.avatar_static,
        status: el?.status,
        description:
          el?.status === 'pending'
            ? 'Đang chờ'
            : el?.status === 'rejected'
            ? 'Đã từ chối'
            : el?.status === 'approved'
            ? 'Đã đồng ý'
            : null
      }));

      formik.setFieldValue('course_host_added', dataDefault);
    }
  };

  React.useEffect(() => {
    formik.handleSubmit();
  }, [createCourse, updateCourse, idUpdate]);

  React.useEffect(() => {
    if (!infoUpdate) {
      dispatch(
        coursePreview({
          title: formik.values.title,
          course_category_id: formik.values.course_category_id,
          banner: formik.values.banner?.file,
          introduction_video: formik.values.banner?.file,
          benefits: formik.values.benefits,
          target_audience: formik.values.target_audience,
          description_company: formik.values.description_company,
          description_course: formik.values.description_course,
          refund_policy: formik.values.refund_policy,
          allow_discussion: formik.values.allow_discussion,
          // options_price: formik.values.options_price,
          price: formik.values.price,
          free: formik.values.free,
          course_host_added: formik.values.course_host_added,
          phone_number: formik.values.phone_number,
          age_restrictions: formik.values.age_restrictions?.id,
          visibility: formik.values.visibility,
          address: formik.values.address,
          location: formik.values.location,
          pageInfo: organization?.page_owner_id ? { ...organization } : null
        })
      );
    }
    if (formik.values?.course_category_id?.subcategories?.length === 0) {
      formik.setFieldValue('course_category_subjects', '');
    }
    if (setCheckCreateUpdate) {
      if (
        Object.keys(formik.errors).length === 0 &&
        formik.values.title &&
        formik.values.course_category_id &&
        formik.values.description_course &&
        formik.values.refund_policy &&
        formik.values.allow_discussion !== undefined &&
        ((formik.values.price && formik.values.price > 0) ||
          formik.values.free) &&
        formik.values.course_host_added &&
        formik.values.phone_number &&
        formik.values.age_restrictions &&
        formik.values.visibility &&
        !organization?.page_owner_id
      ) {
        setCheckCreateUpdate(true);
      } else if (
        Object.keys(formik.errors).length === 0 &&
        formik.values.title &&
        formik.values.course_category_id &&
        formik.values.description_course &&
        formik.values.description_company &&
        formik.values.refund_policy &&
        formik.values.allow_discussion !== undefined &&
        (formik.values.price || formik.values.free) &&
        formik.values.course_host_added &&
        formik.values.phone_number &&
        formik.values.age_restrictions &&
        formik.values.visibility &&
        organization?.page_owner_id
      ) {
        setCheckCreateUpdate(true);
      } else {
        setCheckCreateUpdate(false);
      }
    }
  }, [
    JSON.stringify(formik.values),
    organization?.page_owner_id,
    JSON.stringify(formik.errors)
  ]);
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };
  return (
    <Box
      className={classes.root}
      sx={{
        width: infoUpdate || matchesCreate ? '100%' : '350px'
      }}
    >
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>{getStepContent(activeStep)}</form>
    </Box>
  );
};

export default CourseCreateUpdate;
