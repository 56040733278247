import * as React from 'react';
import { DialogContent, Grid, Typography } from '@mui/material';
import backgroundCV from 'src/assets/images/backgroundCV.png';
export const InfoCv = React.forwardRef((props: any, ref) => {
  const { recruitInfo, cvSelect, formik, renderPreviewCV, meInfo } = props;

  return (
    <DialogContent
      dividers
      sx={{ width: '100%', height: 'calc(100% - 123px)' }}
      ref={ref}
    >
      <div>
        <Grid
          sx={{
            width: '793.7px',
            margin: '0px auto 10px auto'
          }}
        >
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: 400,
              textAlign: 'center',
              wordBreak: 'break-all'
            }}
          >
            {recruitInfo?.id && cvSelect
              ? cvSelect?.title
              : formik.values.title}
          </Typography>
        </Grid>
        <div
          style={{
            width: '793.7px',
            height: '100%',
            margin: '0px auto',
            backgroundImage: `url(${backgroundCV})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            boxShadow: '0 0 1px rgb(0 0 0 / 25%)',
            position: 'relative',
            borderRadius: '10px'
          }}
        >
          <div style={{
            // position: 'absolute',
            width: '100%'
          }}>
            {recruitInfo?.id && cvSelect
              ? renderPreviewCV({
                  account: cvSelect?.account,
                  title: cvSelect?.title,
                  template: cvSelect?.template,
                  account_information: JSON.parse(cvSelect?.account_information)
                })
              : renderPreviewCV({
                  account: meInfo,
                  title: formik.values?.title,
                  template: backgroundCV,
                  account_information: formik.values
                })}
          </div>
        </div>
      </div>
    </DialogContent>
  );
});
