/* eslint-disable @typescript-eslint/no-unused-vars */ /* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable jsx-a11y/anchor-is-valid */ /* eslint-disable jsx-a11y/alt-text */ import React, {
  CSSProperties
} from 'react';
import {
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  Box,
  Popper,
  Theme,
  TextField,
  Paper,
  List,
  ListItem,
  Skeleton,
  useMediaQuery
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { boxShadow, WebkitScrollbarRGB } from 'src/constants/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getListPagesApi } from 'src/apis/socialPages.api';
import { useDispatch, useSelector } from 'react-redux';
import { PATHS } from 'src/constants/paths';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import { limitText } from 'src/common/limitText';
import { getPageDetailReq } from 'src/store/action/socialPageAction';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
import { getListGroupApi } from 'src/apis/socialGroup';
import user_banner from 'src/assets/images/user_banner.png';

const useStyles = makeStyles((theme: Theme) => ({
  cssScroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
    }
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    overflowWrap: 'break-word'
  },
  inputSearch: {
    borderRadius: '10px !important',
    width: '100%',
    '&:hover': {
      cursor: 'text'
    }
  },
  searchIcon: {
    marginRight: 10
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '10px !important',
    width: '100%'
  },
  notchedOutline: {
    borderRadius: '10px'
  },
  multilineColor: {
    fontSize: 13
  }
}));

interface IconButtonOptionsProps {
  isLoading?: 'await' | 'noAwait';
  icon?: any;
  options?: any;
  action?: any;
  style?: any;
  disabled?: any;
  openPopup?: any;
  name?: any;
  styleLoading?: CSSProperties;
  styleSearch?: CSSProperties;
  styleListOption?: CSSProperties;
  styleListMenu?: CSSProperties;
  stylePopup?: CSSProperties;
  avatar?: any;
  avatar_social?: any;
  style_avatar_social?: CSSProperties;
  optionCheckDefault?: any;
  styleNameButton?: CSSProperties;
  reloadGlobal?: any;
  startIconStyle?: any;
  startIcon?: any;
  socialCutIcon?: any;
  description?: any;
  styleText?: CSSProperties;
  styleAvatar?: CSSProperties;
  styleDescription?: CSSProperties;
  styleButtonItem?: CSSProperties;
  deleteIconButton?: any;
  actionDeleteIconButton?: any;
  horizontalTransform?: any;
  horizontalAnchor?: any;
  typePopup?: any;
  children?: any;
  popperPlacement?: any;
  searchFeature?: any;
  infiniteScroll?: any;
  funcLoad?: any;
  hasMore?: any;
  widthListItem?: any;
  styleChecked?: any;
  searchItems?: any;
  actionSearch?: any;
  paramsSearchPage?: any;
  styleCss?: CSSProperties;
  pageChecked?: RolePreviewEdit;
  pageCurrentId?: any;
  relationship_grow?: any;
  projectAddHostStatus?: any;
  elementNoOptions?: JSX.Element;
  footerPopup?: boolean;
  contentFooterPopup?: JSX.Element;
  scrollableTarget?: any;
  setShowPopper?: any;
  disablePortal?: boolean;
  isBloodPage?: boolean;
  readOnly?: boolean;
  isGroup?: boolean;
}
const pathShowLeft = [
  PATHS.PAGE_CREATE,
  PATHS.EVENT_CREATE,
  PATHS.GROUP_CREATE,
  PATHS.COURSE_CREATE,
  PATHS.RECRUIT_CREATE,
  PATHS.GROW_CREATE
];

const IconButtonOptions: React.FC<IconButtonOptionsProps> = props => {
  const {
    isLoading,
    icon,
    options,
    action,
    style,
    disabled,
    openPopup,
    name,
    styleLoading,
    styleSearch,
    styleListOption,
    styleListMenu,
    stylePopup,
    avatar,
    avatar_social,
    style_avatar_social,
    optionCheckDefault,
    styleNameButton,
    reloadGlobal,
    startIconStyle,
    startIcon,
    socialCutIcon,
    description,
    styleText,
    styleAvatar,
    styleDescription,
    styleButtonItem,
    deleteIconButton,
    actionDeleteIconButton,
    horizontalTransform,
    horizontalAnchor,
    typePopup,
    children,
    popperPlacement,
    searchFeature,
    infiniteScroll,
    funcLoad,
    hasMore,
    widthListItem,
    styleChecked,
    searchItems,
    actionSearch,
    paramsSearchPage = {},
    styleCss,
    pageChecked,
    pageCurrentId,
    relationship_grow,
    projectAddHostStatus,
    elementNoOptions,
    footerPopup,
    contentFooterPopup,
    scrollableTarget,
    setShowPopper,
    disablePortal = true,
    isBloodPage,
    readOnly,
    isGroup
  } = props;

  const match: any = useRouteMatch();
  const theme: any = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [showChecked, setShowChecked] = React.useState<string>('');
  const [optionsMenu, setOptionsMenu] = React.useState<any>([]);
  const [firstTime, setFirstTime] = React.useState<boolean>(true);
  const [optionsChildrenItems, setOptionsChildrenItems] = React.useState<any>([
    []
  ]);
  const meInfo = useSelector((state: any) => state?.meReducer?.info) || {};
  const [menuLevel2, setMenuLevel2] = React.useState(false);
  const [loadingPopover, setLoadingPopover] = React.useState(true);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  React.useEffect(() => {
    setOptionsMenu(options);
  }, [JSON.stringify(options)]);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (optionsMenu?.length < 2 && optionsMenu[0]?.length) {
      setLoadingPopover(false);
    } else if (optionsMenu?.length > 1 && optionsMenu[1]?.length) {
      for (var i = 0; i < optionsMenu.length; i++) {
        if (optionsMenu[i].length > 0) {
          setLoadingPopover(false);
        }
      }
    }
  }, [JSON.stringify(optionsMenu)]);
  const handleSearch = event => {
    setKeyword(event.target.value);
  };

  const handleSearchPage = async keyword => {
    try {
      if (keyword !== '') {
        const params = {
          keyword: keyword,
          ...paramsSearchPage
        };

        const res: any = isGroup
          ? await getListGroupApi(params)
          : await getListPagesApi(params);
        if (res.status === 200) {
          setFirstTime(false);
          setOptionsMenu(
            isGroup
              ? [
                  res.data?.data?.map((el: any) => ({
                    ...el,
                    title: null,
                    description: null,
                    label: el.title,
                    checkbox: true,
                    avatar_icon: el.banner?.url ?? user_banner,
                    action: () => {
                      history.push(`/group/${el?.username ?? el?.id}`);
                    }
                  }))
                ]
              : [
                  res.data?.map((el: any) => ({
                    id: el.id,
                    username: el.username,
                    action: () => {
                      if (isBloodPage) {
                        dispatch(getPageDetailReq(el.id));
                      } else if (actionSearch) {
                        actionSearch(el);
                      } else {
                        history.push(`/page/${el?.username || el.id}`);
                      }
                    },
                    label: el.title,
                    title: null,
                    checkbox: true,
                    avatar_social: {
                      type: 'avatar',
                      avatarObj: el?.avatar_media,
                      style: {
                        width: 36,
                        height: 36,
                        margin: '0px 15px 0px 0px',
                        border: '1px solid #fff'
                      }
                    },
                    styleLabel: { width: '190px' }
                  }))
                ]
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSearchItem = () => {
    const results = optionsMenu[0]?.filter(item =>
      item?.label?.toLowerCase()?.includes(keyword?.toLowerCase())
    );
    setOptionsMenu([[optionsChildrenItems[0][0], ...results]]);
  };

  React.useEffect(() => {
    if (keyword !== '') {
      const timer = setTimeout(() => {
        if (!searchItems) {
          handleSearchPage(keyword);
        } else {
          handleSearchItem();
        }
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      if (searchItems) {
        setOptionsMenu(optionsChildrenItems);
      } else {
        setOptionsMenu(options);
      }
    }
  }, [keyword]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (readOnly) return;
    if (openPopup) {
      setAnchorEl(event.currentTarget);
      setShowPopper && setShowPopper(true);
      setOptionsMenu(options);
      setOpenPopper(true);
    }
    action && action(event);
  };

  const handleChooseAction = el => {
    if (el.isFather) {
      setOptionsMenu(el.options);
      setOptionsChildrenItems(el.options);
      setMenuLevel2(true);
      el.action && el.action();
    } else {
      if (el.action) {
        if (searchItems) {
          el.action(el);
        } else {
          el.action();
        }
      }
      handleClose();
      setOpenPopper(false);
      if (relationship_grow) {
        if (relationship_grow === 'approved') {
          setShowChecked('approved');
        }
        if (relationship_grow === 'rejected') {
          setShowChecked('rejected');
        }
      }
      if (el.id !== 'more') setShowChecked(el.id);
      if (reloadGlobal) {
        window.location.reload();
      }
    }
  };
  React.useEffect(() => {
    if (pageChecked?.page_owner_id) {
      // for option in header ( this only have two option me or page Info)
      if (optionCheckDefault === 'page' && !pageChecked.role) {
        return setShowChecked(meInfo?.id);
      }
      // for option in post action (this is me and list page)
      else return setShowChecked(pageChecked?.page_owner_id);
    } else if (optionCheckDefault) {
      //for other case have option check default
      setShowChecked(optionCheckDefault);
    } else return setShowChecked(meInfo?.id);

    // code cũ để debug
    // if (pageChecked?.page_owner_id) {
    //   //với vai trò mình là chủ page
    //   return setShowChecked(pageChecked?.page_owner_id);
    // } else if (
    //   pageCurrentId === pageChecked?.page_owner_id &&
    //   pageChecked?.role === 'admin' &&
    //   pageCurrentId &&
    //   pageChecked?.page_owner_id
    // ) {
    //   //page hiện tại là page của mình
    //   return setShowChecked(pageCurrentId);
    // } else if (!pageChecked?.role) {
    //   //page hiện tại không phải là page của mình
    //   return setShowChecked(meInfo?.id);
    // } else {
    //   return setShowChecked(optionCheckDefault);
    // }
  }, [pageChecked]);
  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          ...styleLoading
        }}
      >
        <CircularProgress size={20} color={'inherit'} />
      </Box>
    );
  };

  const userSkeleton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
        width: '100%',
        marginLeft: '-4px'
      }}
    >
      <ListItem>
        <Skeleton
          variant="circular"
          animation="wave"
          width="34.4px"
          height="34.4px"
          sx={{ marginRight: 2 }}
        />
        <Skeleton
          animation="wave"
          width={'70%'}
          variant="text"
          sx={{ fontSize: '1.2rem', marginTop: 1 }}
        />
      </ListItem>
    </div>
  );

  const handleClose = () => {
    setOptionsMenu(options);
    setAnchorEl(null);
    setShowPopper && setShowPopper(false);
  };
  const renderInfiniteScroll = (option, index) => {
    return option.length === 0 && !firstTime ? (
      <Typography style={{ textAlign: 'center' }}>Không tìm thấy</Typography>
    ) : (
      <InfiniteScroll
        key={index}
        dataLength={option?.length}
        next={funcLoad}
        hasMore={hasMore}
        scrollThreshold={0.8}
        loader={userSkeleton}
        style={{ overflow: 'hidden' }}
        scrollableTarget={scrollableTarget ? scrollableTarget : 'scroll-paper'}
      >
        {option?.map((el: any, i: any) => (
          <ListItem key={`${index}-${i}`} disablePadding>
            {el.hidden ? null : (
              <Box
                sx={{
                  maxWidth: widthListItem
                    ? widthListItem
                    : (pathShowLeft.includes(match.path) ||
                        (match.params.id && match.params.key === 'edit')) &&
                      matchesCreate
                    ? '100%'
                    : '328px',
                  width: '100%'
                }}
              >
                {el?.title && (
                  <div
                    key={el.title}
                    style={{
                      ...el.styleTitle,
                      maxWidth:
                        pathShowLeft.includes(match.path) && matchesCreate
                          ? '100%'
                          : '328px',
                      padding: '2px 4px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    {menuLevel2 && (
                      <IconButton
                        size="small"
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: '5px'
                        }}
                        onClick={() => {
                          setOptionsMenu(options);
                          setMenuLevel2(false);
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                      </IconButton>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <Typography fontWeight={500} fontSize={'17px'}>
                        {el.title}
                      </Typography>
                      {el.description && (
                        <Typography
                          variant="caption"
                          fontSize={'13px'}
                          sx={{
                            display: 'block',
                            lineHeight: '1.2308'
                          }}
                        >
                          {el.description}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
                {el?.icon ||
                el?.avatar_icon ||
                el?.label ||
                el?.avatar_social ? (
                  <ListItemButton
                    sx={{
                      margin: '0px 8px',
                      padding: '4px 10px 4px 4px',
                      borderRadius: '5px',
                      maxWidth:
                        pathShowLeft.includes(match.path) && matchesCreate
                          ? '100%'
                          : 340,
                      ...styleButtonItem
                    }}
                    onClick={() => handleChooseAction(el)}
                  >
                    {el.icon && (
                      <ListItemIcon
                        sx={{
                          minWidth: '25px',
                          marginRight: '10px'
                        }}
                      >
                        <i style={el.styleIcon} className={el.icon}></i>
                      </ListItemIcon>
                    )}
                    {el?.avatar_social && (
                      <AvatarSocial
                        type={el.avatar_social.type}
                        avatarObj={el.avatar_social.avatarObj}
                        style={el.avatar_social.style}
                        noAction={true}
                      />
                    )}
                    {el.avatar_icon && (
                      <ListItemIcon
                        sx={{
                          minWidth: '25px',
                          marginRight: '10px'
                        }}
                      >
                        <img
                          alt=""
                          style={{
                            height: 36,
                            width: 36,
                            margin: '0px 10px 0px 0px',
                            borderRadius: '50%',
                            ...boxShadow
                          }}
                          src={el.avatar_icon}
                        />
                      </ListItemIcon>
                    )}
                    <ListItemText>
                      <Typography
                        fontWeight={500}
                        fontSize={'15px'}
                        sx={{ ...el.styleLabel }}
                        className={classes.lineClamp}
                      >
                        {el.label ?? el.name}
                      </Typography>
                      {el.description && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            opacity: '0.8'
                          }}
                          className={classes.lineClamp}
                        >
                          {el?.description}
                        </Typography>
                      )}
                    </ListItemText>
                    {el.checkbox &&
                      (el.id === showChecked ||
                        el.username === showChecked) && (
                        <i
                          style={{
                            color: '#3935f4F',
                            margin: styleChecked
                              ? styleChecked
                              : '0 -15px 0px 0px'
                          }}
                          className="fa-light fa-check"
                        ></i>
                      )}
                    {el.endIcon && (
                      <ListItemIcon
                        sx={{
                          minWidth: '25px'
                        }}
                      >
                        <i
                          className={el.endIcon}
                          style={{ ...el.styleEndIcon }}
                        ></i>
                      </ListItemIcon>
                    )}
                    {el.isFather && (
                      <ListItemIcon
                        sx={{
                          minWidth: '25px'
                        }}
                      >
                        <i className="fal fa-chevron-right"></i>
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                ) : null}
              </Box>
            )}
            {index !== optionsMenu.length - 1 && (
              <Divider variant="middle" sx={{ margin: '8px 12px' }} />
            )}
          </ListItem>
        ))}
      </InfiniteScroll>
    );
  };

  const renderTextField = type => {
    return (
      <Box
        sx={{
          width: '100%',
          padding: '0px 10px',
          marginBottom: '10px',
          ...styleSearch
        }}
      >
        <TextField
          classes={{ root: classes.inputSearch }}
          size="small"
          variant="outlined"
          placeholder={type === 'search_pages' ? 'Tìm kiếm trang' : ''}
          value={keyword}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <SearchIcon
                classes={{ root: classes.searchIcon }}
                sx={{ marginLeft: '-5px' }}
              />
            ),
            classes: {
              root: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline
            },
            className: classes.multilineColor
          }}
        />
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        {searchFeature ? renderTextField('search_page') : null}
        {loadingPopover && !isLoading && renderLoading()}
        {
          // noAwait là không cần redux trả dữ liệu
          // await là cần cần redux trả dữ liệu
          // Component cũ không có props này nên không dùng boolean
        }
        {
          // để khớp với component cũ, options vẫn là array chứa 1 phần tử và phần tử đó là 1 array rỗng
        }
        {isLoading === 'noAwait' &&
          optionsMenu[0].length === 0 &&
          firstTime &&
          elementNoOptions}
        {infiniteScroll ? (
          <List disablePadding sx={{ ...styleListOption }}>
            {optionsMenu?.length !== 0 &&
              optionsMenu?.map((option: any, index: any) =>
                renderInfiniteScroll(option, index)
              )}
          </List>
        ) : (
          <>
            {searchItems && menuLevel2 ? renderTextField('search_items') : null}
            {optionsMenu?.length !== 0 &&
              optionsMenu?.map((option: any, index: any) => (
                <React.Fragment key={index}>
                  {option?.map((el: any, i: any) =>
                    el.hidden ? null : (
                      <Box key={`${index}-${i}`}>
                        {el?.title && (
                          <div
                            key={el.title}
                            style={{
                              maxWidth:
                                pathShowLeft.includes(match.path) &&
                                matchesCreate
                                  ? '100%'
                                  : '328px',
                              margin: '0px 8px',
                              padding: '2px 4px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              ...el.styleTitle
                            }}
                          >
                            {menuLevel2 && (
                              <IconButton
                                size="small"
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  marginRight: '5px'
                                }}
                                onClick={() => {
                                  setOptionsMenu(options);
                                  setMenuLevel2(false);
                                }}
                              >
                                <i className="fas fa-arrow-left"></i>
                              </IconButton>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <Typography fontWeight={500} fontSize={'17px'}>
                                {el.title}
                              </Typography>
                              {el.description && (
                                <Typography
                                  variant="caption"
                                  fontSize={'13px'}
                                  sx={{
                                    display: 'block',
                                    lineHeight: '1.2308'
                                  }}
                                >
                                  {el.description}
                                </Typography>
                              )}
                            </div>
                          </div>
                        )}
                        {el?.icon ||
                        el?.avatar_icon ||
                        el?.label ||
                        el?.avatar_social ? (
                          <ListItemButton
                            disabled={el?.disabled}
                            sx={{
                              margin: '0px 8px',
                              padding: '4px 4px',
                              borderRadius: '5px',
                              maxWidth: 340,
                              ...styleButtonItem
                            }}
                            onClick={() => {
                              handleChooseAction(el);
                            }}
                          >
                            {el.icon && (
                              <ListItemIcon
                                sx={{
                                  minWidth: '25px',
                                  marginRight: '10px'
                                }}
                              >
                                {el.url ? (
                                  <img
                                    src={el.icon}
                                    alt="icon"
                                    style={{
                                      height: '25px',
                                      width: '25px',
                                      objectFit: 'fill'
                                    }}
                                  />
                                ) : (
                                  <i
                                    style={el.styleIcon}
                                    className={el.icon}
                                  ></i>
                                )}
                              </ListItemIcon>
                            )}
                            {el?.avatar_social && (
                              <AvatarSocial
                                type={el.avatar_social.type}
                                avatarObj={el.avatar_social.avatarObj}
                                style={el.avatar_social.style}
                                noAction={true}
                              />
                            )}
                            {el.avatar_icon && (
                              <ListItemIcon
                                sx={{
                                  minWidth: '25px',
                                  marginRight: '10px',
                                  height: 36,
                                  width: 36,
                                  overflow: 'hidden',
                                  ...el.styleBoxMessage
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: 36,
                                    width: 36,
                                    // margin: '0px 10px 0px 0px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    ...boxShadow,
                                    ...el.styleIconMessage
                                  }}
                                  src={el.avatar_icon}
                                />
                              </ListItemIcon>
                            )}
                            <ListItemText>
                              <Typography
                                fontWeight={500}
                                fontSize={'15px'}
                                sx={{ ...el.styleLabel }}
                                className={classes.lineClamp}
                              >
                                {el.label ?? el.name}
                              </Typography>
                              {el.description && (
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    opacity: '0.8'
                                  }}
                                  className={classes.lineClamp}
                                >
                                  {el?.description}
                                </Typography>
                              )}
                            </ListItemText>
                            {el.checkbox &&
                              (el.id === showChecked ||
                                el.username === showChecked) && (
                                <i
                                  style={{
                                    color: '#3935f4F',
                                    margin: '0 10px'
                                  }}
                                  className="fa-light fa-check"
                                ></i>
                              )}
                            {el.endIcon && (
                              <ListItemIcon
                                sx={{
                                  minWidth: '25px'
                                }}
                              >
                                <i
                                  className={el.endIcon}
                                  style={{ ...el.styleEndIcon }}
                                ></i>
                              </ListItemIcon>
                            )}
                            {el.isFather && (
                              <ListItemIcon
                                sx={{
                                  minWidth: '25px'
                                }}
                              >
                                <i className="fal fa-chevron-right"></i>
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        ) : null}
                      </Box>
                    )
                  )}
                  {index !== optionsMenu.length - 1 && (
                    <Divider variant="middle" sx={{ margin: '8px 12px' }} />
                  )}
                </React.Fragment>
              ))}
          </>
        )}
        {footerPopup && contentFooterPopup}
      </>
    );
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
        sx={{
          height: '36px',
          width: !name ? '48px' : 'fit-content',
          borderRadius: '6px',
          margin: '0 7px',
          backgroundColor:
            theme.palette.mode === 'dark' ? '#3e4042' : '#e4e6eb',
          '&:hover': {
            backgroundColor: 'button.primary.hover'
          },
          ...style
        }}
      >
        {children}
        <div style={{ display: 'flex', alignItems: 'center', ...styleCss }}>
          {/* code cũ nhận param là class của thư viện bootstrap */}
          {/* {startIcon && socialCutIcon ? (
            startIcon
          ) : startIcon ? (
            <i className={startIcon} style={{ ...startIconStyle }}></i>
          ) : null} */}
          {startIcon && socialCutIcon ? (
            startIcon
          ) : startIcon ? (
            typeof startIcon === 'string' &&
            (startIcon.startsWith('fa-') || startIcon.startsWith('fal')) ? (
              <i className={startIcon} style={{ ...startIconStyle }}></i>
            ) : (
              <img src={startIcon} alt="icon" style={{ ...startIconStyle }} />
            )
          ) : null}

          {avatar_social && (
            <AvatarSocial
              type="avatar"
              avatarObj={avatar_social}
              style={{
                width: 48,
                height: 48,
                margin: '0px 15px 0px 0px',
                border: '1px solid #fff',
                ...style_avatar_social
              }}
              noAction={true}
            />
          )}
          {avatar ? (
            <img
              style={{
                height: 48,
                width: 48,
                margin: '0px 10px 0px 0px',
                borderRadius: '50%',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                flexShrink: 0,
                objectFit: 'cover',
                ...styleAvatar
              }}
              src={avatar}
              alt={'avatar'}
              onError={(e: any) => (e.target.src = avatarDefault)}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'start',
              ...styleText
            }}
          >
            {name ? (
              <Typography
                sx={{
                  fontSize: '16px',
                  marginRight: icon ? '10px' : 0,
                  fontWeight: 600,
                  color: theme.palette.mode === 'light' ? '#3e4042' : '#e4e6eb',
                  ...styleNameButton
                }}
                align="left"
                className={classes.lineClamp}
              >
                {limitText(name, 65)}
              </Typography>
            ) : null}
            {description ? (
              <Typography
                style={{
                  ...styleDescription,
                  fontSize: '14px',
                  marginRight: icon ? '10px' : 0,
                  justifyContent: 'start'
                }}
                align="left"
              >
                {limitText(description, 70)}
              </Typography>
            ) : null}
          </div>
        </div>
        {icon}
        {deleteIconButton && actionDeleteIconButton ? (
          <IconButton
            onClick={actionDeleteIconButton}
            disabled={projectAddHostStatus === 'approved' ? true : false}
          >
            {<i className="fa-light fa-circle-x"></i>}
          </IconButton>
        ) : null}
      </IconButton>
      {!typePopup && optionsMenu?.length !== 0 && openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            id={id}
            open={openPopper}
            anchorEl={anchorEl}
            placement={popperPlacement ?? 'bottom-end'}
            className={classes.cssScroll}
            disablePortal={disablePortal}
            style={{
              borderRadius: '8px',
              zIndex: 1000,
              marginTop: '15px !important',
              boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset',
              ...stylePopup
            }}
          >
            <Paper
              key={id}
              id="scroll-paper"
              sx={{
                ...WebkitScrollbarRGB({}),
                padding: '10px 0px 15px',
                backgroundColor: theme.palette.background.primary,
                borderRadius: '6px',
                ...styleListMenu
              }}
            >
              {renderContent()}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
      {typePopup === 'popover' && optionsMenu && optionsMenu.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom' || 'top',
            horizontal: horizontalAnchor || 'left'
          }}
          transformOrigin={{
            vertical: 'top' || 'bottom',
            horizontal: horizontalTransform || 'left'
          }}
          sx={{ borderRadius: '8px', ...stylePopup }}
          // disablePortal
        >
          <div
            style={{
              padding: '10px 0px',
              ...styleListMenu
            }}
          >
            {renderContent()}
          </div>
        </Popover>
      )}
    </>
  );
};

export default IconButtonOptions;
