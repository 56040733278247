import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { getCalendarRecruitApi } from 'src/apis/socialRecruit.api';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ListButton from 'src/components/List/ListButton';
import StepperSocial from 'src/components/Stepper';
import { getListMyPageReq } from 'src/store/action/socialPageAction';
import {
  getRecruitCategoriesReq,
  resetRecruit
} from 'src/store/action/socialRecruitAction';
import RecruitCreateUpdate from '../RecruitCreateUpdate/RecruitCreateUpdate';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconFavorite from 'src/components/IconFavorite';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        backgroundColor: theme.palette.background.primary,
        position: 'fixed',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 0px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      },
      highlight: {
        backgroundColor: theme.palette.background.rgba
      }
    }),
  { index: 1 }
);

const RecruitLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const theme: any = useTheme();
  const [openSnackbar, setOpenSnackBar] = React.useState<any>(false);
  const [noti, setNoti] = React.useState({
    code: 200,
    message: ''
  });

  const meInfo = useSelector((state: any) => state.meReducer.info);
  const recruitInfo = useSelector((state: any) => state.recruitReducer.info);
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);
  const pagesAdmin = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.map((el: any) => ({
    id: el.id,
    username: el.username,
    avatar_icon: el.avatar_media
      ? el.avatar_media.show_url ??
        el.avatar_media.preview_url ??
        el.avatar_media.url
      : avatarDefault,
    action: () => {
      setRecruitOwner({
        page_owner_id: el.id,
        title: el.title,
        avatar_icon: el.avatar_media
          ? el.avatar_media.show_url ??
            el.avatar_media.preview_url ??
            el.avatar_media.url
          : avatarDefault,
        description: 'Bên tuyển dụng - Trang của bạn'
      });
    },
    label: el.title,
    styleLabel: { width: '228px' }
  }));
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [page, setPage] = React.useState(1);
  const [indexCategories, setIndexCategories] = React.useState(null);
  const [createRecruit, setCreateRecruit] = useState(false);
  const [updateRecruit, setUpdateRecruit] = useState<any>(false);
  const [recruitOwner, setRecruitOwner] = React.useState<any>({});
  const [listRecruitMe, setListRecruitMe] = React.useState<any>(null);
  const [listDiscoverRecruit, setListDiscoverRecruit] =
    React.useState<any>(null);
  const [checkCreateUpdate, setCheckCreateUpdate] = React.useState<any>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [checkStep, setCheckStep] = React.useState<any>({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  });
  const [keyword, setKeyword] = React.useState<any>('');
  const steps = 5;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const categories =
    useSelector((state: any) => state?.recruitReducer?.category) || [];

  const fetchCategories = () => {
    dispatch(getRecruitCategoriesReq());
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (match.path === PATHS.RECRUIT_CREATE) {
      dispatch(resetRecruit());
    }
  }, [match.path]);

  React.useEffect(() => {
    if (recruitInfo?.page_owner) {
      setRecruitOwner({
        page_owner_id: recruitInfo?.page_owner?.id,
        title: recruitInfo?.page_owner?.title,
        avatar_icon: recruitInfo?.page_owner.avatar_media
          ? recruitInfo?.page_owner.avatar_media?.show_url ??
            recruitInfo?.page_owner.avatar_media?.preview_url
          : avatarDefault,
        description: 'Bên tuyển dụng - Trang của bạn'
      });
    } else {
      setRecruitOwner({
        id: meInfo.id,
        title: meInfo.display_name,
        avatar_icon: meInfo.avatar_media
          ? meInfo.avatar_media?.show_url ?? meInfo.avatar_media?.preview_url
          : avatarDefault,
        description: 'Người tuyển dụng - Trang cá nhân'
      });
    }
  }, [JSON.stringify(recruitInfo), JSON.stringify(meInfo)]);

  const fetchCalendarRecruit = async (params: any, setData: any) => {
    let response = await getCalendarRecruitApi(params);
    if (response.status === 200) {
      setData(response.data);
    }
  };
  // let debounce_fetch = _.debounce(function (text) {
  //   setKeyword(text);
  // }, 100);
  const listMenuRecruit = [
    {
      id: 'recruit_home',
      title: 'Khám phá',
      styleTitle: { fontWeight: '600' },
      styleButton: { marginLeft: '5px', width: '280px' },
      icon: (
        <i
          className="fa-light fa-calendar-days"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.RECRUIT);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'calendar',
      title: 'Tuyển dụng của bạn',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-light fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        history.push('/recruits/calendar?q=going');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'invites',
          title: 'Lời mời',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-envelope"
            ></i>
          ),
          action: () => {
            history.push(`/recruits/invites`);
          }
        },
        {
          id: 'interested',
          title: 'Quan tâm',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-star"
            ></i>
          ),
          action: () => {
            history.push(`/recruits/interested`);
          }
        },
        {
          id: 'hosting',
          title: 'Tin đăng tuyển',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-house"
            ></i>
          ),
          action: () => {
            history.push(`/recruits/hosting`);
          }
        },
        {
          id: 'past',
          title: 'Tin đã ứng tuyển',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-arrow-rotate-left"
            ></i>
          ),
          action: () => {
            history.push(`/recruits/past`);
          }
        },
        {
          id: 'cvemso',
          title: 'CV đã lưu',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-arrow-rotate-left"
            ></i>
          ),
          action: () => {
            history.push(`/recruits/cvemso`);
          }
        }
      ]
    }
  ];

  React.useEffect(() => {
    let { id, key, tab } = match?.params;
    switch (match.path) {
      case PATHS.RECRUIT:
        setSelectedIndex('recruit_home');
        setIndexCategories(null);
        break;
      case PATHS.RECRUIT_MINE: {
        if (id) {
          setSelectedIndex(id);
          setIndexCategories(null);
        }
        if (key) {
          setSelectedIndex(key);
          setIndexCategories(null);
        }
        if (tab) {
          setSelectedIndex(tab);
          setIndexCategories(null);
        }
        break;
      }
      case PATHS.RECRUIT_CATEGORY: {
        setSelectedIndex(id);
        setIndexCategories(id);
        break;
      }
      case PATHS.RECRUIT_CREATE: {
        setSelectedIndex('create_recruit');
        setIndexCategories(null);
        break;
      }
      case PATHS.RECRUIT_CREATECV: {
        setSelectedIndex('createCV');
        setIndexCategories(null);
        break;
      }
      case PATHS.RECRUIT_DETAIL: {
        setSelectedIndex('');
        setIndexCategories(null);
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);
  React.useEffect(() => {
    if (
      match.path === PATHS.RECRUIT_MINE ||
      match.path === PATHS.RECRUIT_DETAIL
    ) {
      fetchCalendarRecruit(
        {
          exclude_recruit_id: match.params?.id,
          exclude_current_user: 'true',
          time: 'now',
          limit: 5
        },
        setListDiscoverRecruit
      );
    }
  }, [match.params?.id, match.path]);
  React.useEffect(() => {
    if (
      match.path === PATHS.RECRUIT_DETAIL &&
      match.params?.id &&
      recruitInfo?.recruit_category?.id
    ) {
      fetchCalendarRecruit(
        {
          recruit_id: match.params?.id,
          recruit_category_id: recruitInfo?.recruit_category?.id,
          limit: 5,
          time: 'now'
        },
        setListRecruitMe
      );
    }
  }, [JSON.stringify(match), JSON.stringify(recruitInfo)]);

  React.useEffect(() => {
    if (match.params.key === 'edit') {
      setActiveStep(0);
    }
  }, [match.params.key]);

  const renderListRecruit = (recruit: any) => {
    return (
      <Card
        sx={{
          boxShadow: 'none !important',
          borderRadius: '10px !important'
        }}
      >
        <CardActionArea
          onClick={() => history.push(`/recruit/${recruit.id}/about`)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="50px"
              sx={{
                maxWidth: '80px',
                marginLeft: '12px',
                borderRadius: '8px'
              }}
              image={recruit?.banner ? recruit.banner.preview_url : ''}
            />
            <CardContent
              sx={{
                width: '100%',
                paddingRight: '8px'
              }}
            >
              <Typography fontSize={12} color="#f3425f">
                {recruit?.start_time}
              </Typography>
              <Typography
                gutterBottom
                fontSize={16}
                fontWeight={600}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 160,
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {recruit?.title}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  const loadActivity = page => {
    dispatch(
      getListMyPageReq({
        page: page,
        limit: 10,
        status: 'active'
      })
    );
  };

  const funcLoad = () => {
    setPage(page + 1);
  };
  React.useEffect(() => {
    loadActivity(page);
  }, [page]);

  const renderHeader = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.RECRUIT_CREATE: {
        switch (key) {
          case 'online':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.RECRUIT_CREATE)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết tuyển dụng
                  </Typography>
                </Grid>
              </Grid>
            );
          case 'offline':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.RECRUIT)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết tuyển dụng
                  </Typography>
                </Grid>
              </Grid>
            );
          default:
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#b4b3b3',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.RECRUIT)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Đăng tin tuyển dụng
                  </Typography>
                </Grid>
              </Grid>
            );
        }
      }
      case PATHS.RECRUIT_DETAIL: {
        switch (id) {
          case 'search':
            return null;
        }
        switch (key) {
          case 'edit':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => {
                      history.push(`/recruit/${id}/about`);
                      setUpdateRecruit(false);
                      setActiveStep(0);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chỉnh sửa tuyển dụng
                  </Typography>
                </Grid>
              </Grid>
            );

          default:
            return (
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                    variant="h5"
                  >
                    Tuyển dụng
                  </Typography>

                  <IconFavorite
                    styleContainer={{ marginRight: '10px' }}
                    type="recruits"
                  />
                  <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                    <InputSearch
                      keyword={keyword}
                      setKeyword={setKeyword}
                      label="Tìm kiếm tuyển dụng"
                      action={() =>
                        history.push(`/recruit/search?q=${keyword}`)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            );
        }
      }
      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 'bold', margin: '8px 14px 3px 3px' }}
                variant="h5"
              >
                Tuyển dụng
              </Typography>
              <IconFavorite
                styleContainer={{ marginRight: '10px' }}
                type="recruits"
              />
              <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm tuyển dụng"
                  action={() => history.push(`/recruit/search?q=${keyword}`)}
                />
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  const renderCategory = () => {
    return (
      <div
        style={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '105px'
        }}
      >
        <Divider sx={{ margin: '0px 0px 5px' }} />
        <div
          style={{
            margin: '8px 8px 0px'
          }}
        >
          <Typography fontSize={16} fontWeight={600} gutterBottom>
            Hạng mục
          </Typography>
        </div>
        {categories?.map((item: any) => (
          <React.Fragment key={item.id}>
            <ListItem
              disablePadding
              onClick={() => setIndexCategories(item?.id)}
              className={
                item && item?.id === indexCategories ? classes.highlight : ''
              }
            >
              <ListItemButton
                style={{ paddingLeft: 20 }}
                onClick={() => history.push(`/recruits/categories/${item.id}`)}
              >
                <ListItemText
                  primary={
                    <Typography fontSize={15} fontWeight={600}>
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </div>
    );
  };
  const renderBody = () => {
    let { key, id } = match?.params;
    switch (match.path) {
      case PATHS.RECRUIT_CREATE:
        switch (key) {
          case 'online':
            return {
              bodyTop: null,
              bodyCenter: <div></div>,
              bodyFooter: null
            };
          case 'offline':
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={recruitOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={recruitOwner.avatar_icon}
                    description={recruitOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: matchesCreate ? '100%' : '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setRecruitOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description: 'Người tuyển dụng - Trang cá nhân'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin?.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{
                      zIndex: '1001',
                      width: matchesCreate ? '98%' : '330px'
                    }}
                    searchFeature={true}
                    actionSearch={el => {
                      setRecruitOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tuyển dụng - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />
                  <RecruitCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    organization={recruitOwner}
                    createRecruit={createRecruit}
                    setCreateRecruit={setCreateRecruit}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setCheckStep={setCheckStep}
                    checkStep={checkStep}
                    setOpenSnackBar={setOpenSnackBar}
                    setNoti={setNoti}
                  />
                </div>
              ),
              bodyFooter: null
            };

          default:
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 0px 0px', padding: '0px 5px' }}>
                  <IconButtonOptions
                    name={recruitOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={recruitOwner.avatar_icon}
                    description={recruitOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setRecruitOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description: 'Người tuyển dụng - Trang cá nhân'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{ zIndex: '1001', width: '330px' }}
                    searchFeature={true}
                    actionSearch={el => {
                      setRecruitOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tuyển dụng - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />

                  <ListButton
                    item={[
                      {
                        id: 'create_recruit',
                        title: 'Đăng tin tuyển dụng',
                        styleTitle: { fontWeight: '600' },
                        icon: (
                          <i
                            className="fa-solid fa-user-group"
                            style={{ fontSize: '18px' }}
                          ></i>
                        ),
                        style: { height: 36, width: 36 },
                        position: 'top',
                        action: () => setActiveStep(0)
                      }
                    ]}
                    selectedItem={selectedIndex}
                    width={'340px'}
                    typeAction={'action'}
                  />
                </div>
              ),
              bodyFooter: null
            };
        }
      case PATHS.RECRUIT_CREATE:
      case PATHS.RECRUIT_CREATECV:
      case PATHS.RECRUIT:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuRecruit.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'285px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{
                  padding: '0px !important',
                  margin: '6px 0'
                }}
                typeAction={'action'}
              />
              <div style={{ marginLeft: '5px', width: 280 }}>
                <ButtonCustom
                  label="Đăng tin tuyển dụng mới"
                  action={() => {
                    dispatch(resetRecruit());
                    setActiveStep(0);
                    history.push('/recruits/create/offline');
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.RECRUIT_CATEGORY:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuRecruit.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'285px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                typeAction={'action'}
              />
              <div style={{ marginLeft: '5px', width: 280 }}>
                <ButtonCustom
                  label="Đăng tin tuyển dụng mới"
                  action={() => {
                    dispatch(resetRecruit());
                    setActiveStep(0);
                    history.push('/recruits/create/offline');
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.RECRUIT_MINE:
      case PATHS.RECRUIT_DETAIL:
        switch (id) {
          case 'search':
            return {
              bodyTop: null,
              bodyCenter: (
                <div>
                  <SearchGlobalLeft
                    type="recruit"
                    styleGui={{
                      height: '100%',
                      width: 320,
                      backgroundColor: theme.palette.background.primary,
                      position: 'none',
                      overflowY: 'hidden'
                    }}
                  />
                  ;
                </div>
              ),
              bodyFooter: null
            };
        }
        switch (key) {
          case 'edit':
            return {
              bodyTop: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={recruitInfo?.page_owner?.title ?? recruitOwner.title}
                    avatar={
                      recruitInfo?.page_owner?.avatar_icon ??
                      recruitOwner.avatar_icon
                    }
                    description={
                      recruitInfo?.page_owner?.description ??
                      recruitOwner.description
                    }
                    styleAvatar={{
                      height: 36,
                      width: 36,
                      marginRight: '12px'
                    }}
                    style={{
                      margin: '0px 0px',
                      width: '100%',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                    disabled
                  />
                  <RecruitCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    organization={recruitOwner}
                    updateRecruit={updateRecruit}
                    idUpdate={id}
                    setUpdateRecruit={setUpdateRecruit}
                    activeStep={activeStep}
                    setCheckStep={setCheckStep}
                    setOpenSnackBar={setOpenSnackBar}
                    setNoti={setNoti}
                  />
                </div>
              ),
              bodyCenter: null,
              bodyFooter: null
            };

          default:
            return {
              bodyTop: (
                <>
                  <ListButton
                    item={listMenuRecruit.filter(el => el.position === 'top')}
                    selectedItem={selectedIndex}
                    width={'285px'}
                    styleAccordionDetail={{ padding: '8px 5px 5px' }}
                    stylePadding={{
                      padding: '0px !important',
                      margin: '6px 0'
                    }}
                    styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                    typeAction={'action'}
                  />
                  <div style={{ marginLeft: '5px', width: 280 }}>
                    <ButtonCustom
                      label="Đăng tin tuyển dụng mới"
                      action={() => {
                        dispatch(resetRecruit());
                        setActiveStep(0);
                        history.push('/recruits/create/offline');
                      }}
                      solid={true}
                      style={{
                        color: '#e7f3ff',
                        backgroundColor: buttonColor.backgroundColor
                      }}
                      icon={<i className="far fa-plus"></i>}
                    />
                  </div>
                </>
              ),
              bodyCenter: (
                <div
                  style={{
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {match.path === PATHS.RECRUIT_DETAIL &&
                    listRecruitMe !== null && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Divider />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '8px'
                          }}
                        >
                          <Typography fontSize={16} fontWeight={600}>
                            Việc làm tương tự
                          </Typography>
                          <Typography
                            onClick={() =>
                              history.push(
                                `/recruits/categories/${recruitInfo?.recruit_category?.id}`
                              )
                            }
                            fontSize={14}
                            color={buttonColor.backgroundColor}
                            sx={{ cursor: 'pointer' }}
                          >
                            Xem tất cả
                          </Typography>
                        </div>
                        {listRecruitMe?.length ? (
                          listRecruitMe?.map((el: any) => renderListRecruit(el))
                        ) : (
                          <span style={{ paddingLeft: '10px' }}>
                            Không có dữ liệu
                          </span>
                        )}
                      </div>
                    )}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px'
                      }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        Việc làm đề xuất
                      </Typography>
                      <Typography
                        onClick={() => history.push('/recruits/home')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {listDiscoverRecruit?.length ? (
                      listDiscoverRecruit?.map((el: any) =>
                        renderListRecruit(el)
                      )
                    ) : (
                      <span style={{ paddingLeft: '10px' }}>
                        Không có dữ liệu
                      </span>
                    )}
                  </div>
                </div>
              ),
              bodyFooter: renderCategory()
            };
        }
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  const renderFooter = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.RECRUIT_CREATECV:
      case PATHS.RECRUIT_CREATE: {
        if (key) {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor: 'background.primary',
                marginBottom: '-5px'
              }}
            >
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={createRecruit}
                    label="Đăng tin tuyển dụng"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setCreateRecruit(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      marginTop: '12px',
                      width: '70%',
                      marginLeft: '7px',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      },
                      color: '#fff'
                    }}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                        ? !checkStep.step3
                        : !checkStep.step4
                    }
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        } else return null;
      }
      case PATHS.RECRUIT_CATEGORY:
      case PATHS.RECRUIT_MINE:
        return null;
      case PATHS.RECRUIT_DETAIL:
        if (key === 'edit') {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor: 'background.primary',
                marginBottom: '-5px'
              }}
            >
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={updateRecruit}
                    label="Cập nhật Tuyển dụng"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setUpdateRecruit(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      marginTop: '12px',
                      width: '70%',
                      marginLeft: '7px',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      }
                    }}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                        ? !checkStep.step3
                        : !checkStep.step4
                    }
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        }
        break;

      default:
        return null;
    }
  };

  return (
    <Box>
      <div
        className={classes.root}
        style={{
          position:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 'fixed'
              : 'static',

          width:
            match.path === PATHS.RECRUIT_CREATE ||
            (match.params?.key === 'edit' && !!match.params?.id)
              ? matchesCreate
                ? '100%'
                : 380
              : renderBody().bodyTop ||
                renderBody().bodyCenter ||
                renderBody().bodyFooter
              ? 310
              : 0,
          margin:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 0
              : '5px 10px 40px -20px',
          padding:
            match.path === PATHS.RECRUIT_CREATE ||
            (match.params?.key === 'edit' && !!match.params?.id)
              ? '5px 5px 40px 5px'
              : match.params?.id !== 'search'
              ? '5px 0px 40px 3px'
              : '0px'
        }}
      >
        {match.params?.id !== 'search' && (
          <div className={classes.headerPage}>{renderHeader()}</div>
        )}

        <div className={classes.rootPage}>
          {renderBody().bodyTop}
          {match.path !== PATHS.PAGE_CREATE &&
            match.params?.id !== 'search' && <div style={{ height: 10 }}></div>}
          {renderBody().bodyCenter}
          {match.path === PATHS.RECRUIT_DETAIL ||
          match.path === PATHS.RECRUIT_MINE ||
          match.path === PATHS.RECRUIT ||
          match.path === PATHS.RECRUIT_CREATECV ||
          match.path === PATHS.RECRUIT_CATEGORY
            ? renderBody().bodyFooter
            : null}
        </div>

        <div className={classes.footerPage}>
          {match.path !== PATHS.PAGE_CREATE &&
            match.path !== PATHS.PAGE &&
            renderFooter() && <Divider />}
          {renderFooter()}
        </div>
      </div>
      {openSnackbar && (
        <SnackbarNotification
          style={{ marginLeft: '50px' }}
          open={openSnackbar}
          setOpen={setOpenSnackBar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default RecruitLeft;
