import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

const ProjectMedia = (props: any) => {
  const classes = useStyles();
  const { infoUpdate, formik, growInfo, setNotificationLeave } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  let fieldUpdate: any = [
    {
      field_type: 'upload_media',
      name: 'pitchdeck',
      label: 'File Pitch Desk',
      type: 'file_document',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: growInfo?.pitchdeck
        ? growInfo?.pitchdeck
        : formik.values.pitchdeck
    },

    {
      field_type: 'upload_media',
      name: 'business_license',
      label: 'Giấy phép Đăng ký kinh doanh',
      type: 'file_img_pdf',
      height: 160,
      descriptionTitle: 'Chỉ hỗ trợ tải lên dạng file IMG hoặc PDF',
      default_media: growInfo?.business_license
        ? growInfo?.business_license
        : formik.values.business_license
    },
    {
      field_type: 'upload_media',
      name: 'commitment_form',
      label: 'Biên bản cam kết',
      type: 'file_img_pdf',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      descriptionTitle: 'Chỉ hỗ trợ tải lên dạng file IMG hoặc PDF',
      default_media: growInfo?.commitment_form
        ? growInfo?.commitment_form
        : formik.values.commitment_form
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default ProjectMedia;
