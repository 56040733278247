import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Theme,
  DialogActions,
  TextareaAutosize
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { scrollStyle, modalBackdrop } from 'src/constants/styles';
import CloseIcon from '@mui/icons-material/Close';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';

import background from 'src/assets/images/background.png';
import { listBackgroundAnswer } from 'src/constants/common';
import target_default from 'src/assets/images/target.svg';

import ButtonInherit from 'src/components/Button/ButtonInherit';

import { useDispatch, useSelector } from 'react-redux';
import { notiSharePost } from 'src/store/action/socialPostAction';

const useStyles = makeStyles((theme: Theme) => ({
  rootCenterBottom: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '12px 16px 0px 16px',
    bottom: 5
  },
  icon: {
    backgroundColor: '#e4e6eb',
    width: 32,
    height: 32,
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconSelected: {
    backgroundColor: '#e4e6eb',
    width: 32,
    height: 32,
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #fff'
  },
  background: {
    height: 40,
    width: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textArea: {
    fontWeight: 'bold',
    color: '#ffffff',
    fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    border: 0,
    wordBreak: 'break-word',
    '&::placeholder': {
      color: '#ffffff'
    },
    '&:focus-visible': {
      outline: 'none'
    },
    backgroundColor: 'transparent',
    resize: 'none',
    minHeight: '80px !important',
    zIndex: 110,
    textAlign: 'center',
    width: '85%'
  },
  wrapRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px 0px'
  },
  wrapTop: {
    height: 576,
    width: 432,
    borderRadius: 14,
    position: 'relative'
  },
  wrapText: {
    height: 320,
    position: 'absolute',
    top: 216,
    zIndex: 200,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%'
  },
  wrapAvatar: {
    height: 216,
    width: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100
  },
  wrapAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center !important'
  },
  wrapButton: { width: '460px !important', margin: '12px 0px' }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    padding: '0px !important'
  },
  '& .MuiPaper-root': {
    width: 500
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  open: boolean;
  handleClose?: any;
  bgAnswerSelected?: any;
  setBgAnswerSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  isTypeTarget?: boolean;
  setMenuSelected?: any;
  pageInfo?: any;
  setListKeyDisabled?: React.Dispatch<React.SetStateAction<any>> | any;
}

export default function CustomizedDialogs(props: Props) {
  const {
    open,
    handleClose,
    bgAnswerSelected,
    setBgAnswerSelected,
    isTypeTarget,
    setMenuSelected,
    pageInfo,
    setListKeyDisabled
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [showBGDemo, setShowBGDemo] = React.useState(false);

  const meInfo = useSelector((state: any) => state.meReducer.info) || {};

  const handleSetText = text => {
    if (text.length < 255) {
      setBgAnswerSelected(prev => ({
        ...prev,
        text,
        isChange: true,
        isTarget: isTypeTarget
      }));
    } else {
      // setBgAnswerSelected(prev => ({
      //   ...prev,
      //   text: isTypeTarget ? 'Công bố mục tiêu' : 'Tổ chức buổi hỏi/đáp',
      //   isChange: false,
      //   isTarget: isTypeTarget
      // }));

      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Bạn đã nhập quá 254 ký tự cho phép !'
        })
      );
    }
  };

  React.useEffect(() => {
    if (listBackgroundAnswer.length) {
      setBgAnswerSelected(prev => ({
        ...prev,
        backgroundImage: listBackgroundAnswer[0].color,
        id: listBackgroundAnswer[0].id
      }));
    }
  }, [JSON.stringify(listBackgroundAnswer)]);

  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.placeholder = '';
    }
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      //TransitionComponent={Transition}
      BackdropProps={modalBackdrop}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setBgAnswerSelected({
            backgroundImage: '',
            text: 'Bạn hỏi gì đi...',
            isChange: false,
            isTarget: false
          });
          setMenuSelected([]);
          setListKeyDisabled([]);
          handleClose();
        }}
      >
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          {isTypeTarget ? 'Công bố mục tiêu' : 'Tổ chức buổi hỏi/đáp'}
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent>
        <div className={classes.wrapRoot}>
          <div
            className={classes.wrapTop}
            style={{ background: bgAnswerSelected.backgroundImage }}
          >
            <div className={classes.wrapAvatar}>
              {isTypeTarget ? (
                <AvatarSocial
                  type="feed"
                  src={target_default}
                  style={{ width: 168, height: 168 }}
                ></AvatarSocial>
              ) : (
                <AvatarSocial
                  type="feed"
                  avatarObj={
                    pageInfo ? pageInfo?.avatar_media : meInfo?.avatar_media
                  }
                  style={{ width: 168, height: 168 }}
                ></AvatarSocial>
              )}

              {/* <Avatar
                style={{ width: 168, height: 168 }}
                src={meInfo?.avatar_media?.url}
                alt={meInfo?.display_name}
              /> */}
            </div>
            <div className={classes.wrapText}>
              <TextareaAutosize
                id="textInputCreatePost"
                ref={inputRef}
                className={classes.textArea}
                style={{
                  fontSize:
                    bgAnswerSelected?.text?.length <= 50
                      ? '30px'
                      : bgAnswerSelected?.text?.length <= 196
                      ? '24px'
                      : '20px'
                }}
                placeholder={
                  isTypeTarget ? 'Bạn muốn đặt mục tiêu gì ' : 'Bạn muốn hỏi gì'
                }
                value={bgAnswerSelected.text}
                onChange={e => handleSetText(e.target.value)}
                onFocus={handleFocus}
              ></TextareaAutosize>
            </div>

            <div className={classes.rootCenterBottom}>
              {showBGDemo ? (
                <div
                  onClick={() => setShowBGDemo(false)}
                  className={classes.icon}
                >
                  <i className="fas fa-angle-left fa-lg"></i>
                </div>
              ) : (
                <img
                  onClick={() => setShowBGDemo(true)}
                  className={classes.background}
                  src={background}
                  alt="background"
                />
              )}
              {showBGDemo
                ? listBackgroundAnswer.map((item: any, index) => (
                    <div
                      key={index + ''}
                      onClick={() =>
                        setBgAnswerSelected(prev => ({
                          ...prev,
                          backgroundImage: item.color,
                          id: item.id
                        }))
                      }
                      style={{ background: item.color }}
                      className={
                        bgAnswerSelected?.id === item.id
                          ? classes.iconSelected
                          : classes.icon
                      }
                    ></div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.wrapAction}>
        <div className={classes.wrapButton}>
          <ButtonInherit
            disabled={!bgAnswerSelected.text || !bgAnswerSelected.isChange}
            action={() => {
              handleClose();
              setMenuSelected([isTypeTarget ? 'target' : 'answer']);
            }}
            fullWidth={true}
            label={isTypeTarget ? 'Đặt mục tiêu' : 'Đặt câu hỏi'}
          />
        </div>
      </DialogActions>
    </BootstrapDialog>
  );
}
