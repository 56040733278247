import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { CSSProperties, makeStyles, useTheme } from '@mui/styles';
import { cloneDeep, unionBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ResponseApi } from 'src/@types/api';
import {
  getCalendarCourseApi,
  interesteCourseApi,
  noInteresteCourseApi
} from 'src/apis/socialCourse.api';
import { getCalendarEventApi, joinEventApi } from 'src/apis/socialEvent.api';
import {
  addMemberGroupApi,
  deleteMemberGroupApi,
  searchGroupApi
} from 'src/apis/socialGroup';
import {
  getCalendarGrowApi,
  interesteGrowApi,
  noInteresteGrowApi
} from 'src/apis/socialGrow.api';
import { searchProduct } from 'src/apis/socialMarketplace.api';
import { likeFollowPage, searchPageApi } from 'src/apis/socialPages.api';
import { getCityApiUpdate } from 'src/apis/socialPost.api';
import {
  getCalendarRecruitApi,
  interesteRecruitApi,
  noInteresteRecruitApi
} from 'src/apis/socialRecruit.api';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import {
  approveRequestFriendApi,
  cancelRequestFriendApi,
  getAboutUser,
  requestFriendApi,
  searchPostUser,
  unFriendUser
} from 'src/apis/socialUser';
import productCoverDefault from 'src/assets/images/group_cover.png';
import { useMatchMedia } from 'src/common/useMatchMedia';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonOptions from 'src/components/Button/ButtonOptions';
import DialogQuestionGroup from 'src/components/Dialog/DialogQuestionGroup';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { boxShadow, buttonColor, lineClamp } from 'src/constants/styles';
import { convertMoneyToVietnameseDongString } from 'src/helpers/string';
import { resetMemberQuestion } from 'src/store/action/socialGroupAction';
import { loadingSearchRecruit } from 'src/store/action/socialRecruitAction';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
    minWidth: '470px'
  },
  wrapHeader: {
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    borderRadius: 10,
    padding: '10px 10px 0px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '93%',
    [theme.breakpoints.down('xl')]: {
      width: '95%'
    },
    marginTop: 20,
    ...boxShadow
  },
  wrapNoData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    height: '100px',
    backgroundColor: theme.palette.background.primary,
    borderRadius: 12,
    marginTop: 20
  },
  typography: {
    margin: 10,
    marginBottom: 0,
    fontWeight: 'bold'
  },
  wrapLoadMore: {
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '10px'
    // maxWidth: '680px'
  },
  wrapContentShop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px'
  },
  wrapContent: {
    width: '100%',
    // height: '100%',
    borderRadius: '8px',
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    padding: '15px',
    marginBottom: '10px',
    border: theme.palette.borderColor.color
  },
  wrapListItem: {
    width: '100%',
    paddingRight: '0 15px 0 0'
  },
  wrapButton: {
    display: 'flex',
    maxWidth: '100%',
    height: '62px',
    padding: '10px 17px',
    backgroundColor: theme.palette.mode === 'dark' ? '#2A2A2A' : '#EAEAEA',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'unset',
    margin: '10px 0px'
  },
  textFilter: {
    width: '100%',
    color: theme.palette.mode === 'dark' ? '#fff' : '#050505',
    fontWeight: '500 !important'
  },
  titleLocation: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
  },
  showMore: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: theme.palette.text.primary
  },
  titleCategory: {
    fontSize: '18px !important',
    fontWeight: '500 !important',
    padding: '0px 10px'
  },
  wrapPriceFilter: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  borderColors: {
    border: theme.palette.borderColor.color
  },
  textHashtag: {
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0 16px 8px 0 !important',
    fontSize: 16
  },
  truncateText: {
    ...lineClamp(1)
  } as CSSProperties,
  wrapperShop: {
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : '#fff',
    paddingTop: '10px',
    borderRadius: '10px',
    margin: '0 15px'
  },
  avatarShop: {
    borderRadius: '100%',
    width: '70px'
  },
  labelFavouriteShop: {
    background: '#ee4d2d',
    color: 'white',
    padding: '0 5px',
    borderRadius: '2px',
    fontSize: '14px',
    marginRight: '10px'
  },
  labelRatingShop: {
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center'
  },
  starShop: {
    color: '#ffba1c',
    fontSize: '15px'
  },
  ratingShop: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#ee4d2d'
  },
  labelFollower: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,.54)',
    marginLeft: '8px'
  },
  buttonShop: {
    border: `1px solid ${buttonColor.newBackgroundColor}`,
    color: buttonColor.newTextColor,
    minWidth: '140px',
    backgroundColor: theme.palette.background.primary,
    padding: '6px',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: buttonColor.newBackgroundColor,
      color: theme.palette.background.primary
    },
    cursor: 'pointer',
    borderRadius: '2px'
  },
  imgCardShop: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  titleCardShop: {
    fontSize: '12px',
    fontWeight: 300,
    width: '100%',
    padding: '2px 4px',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-all'
  },
  priceCardShop: {
    fontSize: '12px',
    color: '#ee4d2d',
    fontWeight: 400
  },
  soldCardShop: {
    fontSize: '13px',
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,.54)',
    fontWeight: 500
  }
}));

const LoadMoreDataSearchGlobal = (props: any) => {
  const classes = useStyles();
  const {
    type,
    renderItemList,
    renderItemPost,
    isLoading,
    setIsLoading,
    renderListRecruit,
    renderListCourse,
    renderListEvent,
    renderListGrow,
    renderCardProduct,
    renderItems,
    groupSelected,
    setGroupSelected,
    flag,
    setFlag
  } = props;

  let limitAll = 10;
  let limit = 10;
  const [offset, setOffset] = React.useState<any>(0);
  const [reload, setReload] = React.useState(false);
  const [activities, setActivities] = React.useState<any>([]);
  const [resultSearchAll, setResultSearchAll] = React.useState<any>({
    accounts: [],
    groups: [],
    hashtags: [],
    pages: [],
    statuses: [],
    recruit: [],
    course: [],
    event: [],
    grow: [],
    product: []
  });
  const [hasMore, setHasMore] = React.useState<any>(true);
  const isDesktopResolution = useMatchMedia('(min-width:1500px)', true);
  const isMobile = useMatchMedia('(min-width:1000px)', true);
  const [aboutUser, setAboutUser] = React.useState<any>([]);
  const [listLocation, setListLocation] = React.useState<any>([]);
  const [showMoreLocation, setShowMoreLocation] = React.useState<any>(false);
  const [priceRange, setPriceRange] = React.useState({
    minPrice: '',
    maxPrice: ''
  });
  const [offsetSearchGroupPage, setOffsetSearchGroupPage] =
    React.useState<any>(0);
  const history = useHistory();
  const theme: any = useTheme();
  const dataSearchFilterPostUser = useSelector(
    (state: any) => state.searchGlobalReducer.dataSearchFilterPostUser
  );
  const dataSearchFilterPostGroup = useSelector(
    (state: any) => state.searchGlobalReducer.dataSearchFilterPostGroup
  );
  const dataSearchFilterPostPage = useSelector(
    (state: any) => state.searchGlobalReducer.dataSearchFilterPostPage
  );

  const handleSearch = useSelector(
    (state: any) => state.searchGlobalReducer.handleSearch
  );
  const dispatch = useDispatch();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const recruitIsLoading = useSelector(
    (state: any) => state.recruitReducer.searchLoading
  );
  const memberQuestions = useSelector(
    (state: any) => state.groupReducer.memberQuestions
  );
  const match = useRouteMatch();
  let { key, id, userId }: any = match.params;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [typeSearch, setTypeSearch] = React.useState('resultSearchAll');
  const [openQuestion, setOpenQuestion] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  let query = useQuery();
  let filterProduct: any = query.get('sortBy');
  let keyword: any = query.get('q');
  let province_id: any = query.get('province_id');
  let createdBy: any = query.get('createdBy');
  let createdAt: any = query.get('createdAt');
  let working_form: any = query.get('working_form');
  let work_experience: any = query.get('work_experience');
  let salary_min: any = query.get('salary_min');
  let salary_max: any = query.get('salary_max');
  let recruit_category_id: any = query.get('recruit_category_id');
  let course_category_id: any = query.get('course_category_id');
  let product_category_id: any = query.get('category');
  const handleSearchGlobal = async (
    offset: any,
    keywords: any,
    keyType: any
  ) => {
    let response: ResponseApi = await searchUserGlobal({
      q: keywords,
      type: keyType === 'all' ? null : [keyType],
      offset: offset,
      limit: keyType === 'all' ? limitAll : limit
    });
    if (response.status === 200) {
      setIsLoading(false);
      let { data } = response;
      if (keyType === 'all') {
        if (
          (response.data['accounts']?.length &&
            response.data['groups']?.length &&
            response.data['pages']?.length) < limitAll
        ) {
          setHasMore(false);
        }
        setResultSearchAll({
          accounts: response.data['accounts'],
          groups: response.data['groups'],
          pages: response.data['pages'],
          statuses: response.data['statuses'],
          hashtags: response.data['hashtags']
        });
      } else if (data[keyType]?.length > 0 && offset === 0) {
        setActivities(data[keyType]);
      } else if (keyType !== 'all') {
        setHasMore(data[keyType]?.length >= limit);
        setActivities(preValue => {
          let temp = cloneDeep(preValue);
          return temp.concat(data[keyType]);
        });
      }
    }
  };

  const handlePriceRangeChange = event => {
    const { name, value } = event.target;
    setPriceRange(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  React.useEffect(() => {
    setOffset(0);
    let tempReload: any = reload;
    setReload(!tempReload);
  }, [handleSearch, key]);

  React.useEffect(() => {
    if (memberQuestions.length) {
      setOpenQuestion(true);
    } else {
      setFlag && setFlag(1);
      setOpenQuestion(false);
    }
  }, [memberQuestions]);

  React.useEffect(() => {
    if (flag === 1 && groupSelected && !memberQuestions.length) {
      setOpenQuestion(false);
      setGroupSelected(null);
      handleGroupAction(groupSelected, typeSearch);
    }
  }, [groupSelected, flag]);

  React.useEffect(() => {
    if (resultSearchAll?.accounts?.length) {
      resultSearchAll?.accounts.map(el => fetchAboutUser(el.id));
    } else if (activities?.length && activities?.find(el => el?.display_name)) {
      activities?.map(el => fetchAboutUser(el?.id));
    }
  }, [resultSearchAll?.accounts?.length || activities?.length]);

  React.useEffect(() => {
    // Giá trị khởi tạo của redux là [] và {} không so sánh được với undefined
    if ((key && keyword) || createdBy || createdAt)
      if (
        dataSearchFilterPostUser === undefined &&
        dataSearchFilterPostGroup === undefined &&
        dataSearchFilterPostPage === undefined
      ) {
        setActivities([]);
        setOffset(0);
        setResultSearchAll({
          accounts: [],
          groups: [],
          hashtags: [],
          pages: [],
          statuses: [],
          recruit: [],
          course: [],
          event: [],
          grow: [],
          product: []
        });
        setOffsetSearchGroupPage(0);
      } else {
        if (
          dataSearchFilterPostUser &&
          dataSearchFilterPostUser.length === 0 &&
          Object.keys(dataSearchFilterPostGroup || {}).length === 0 &&
          Object.keys(dataSearchFilterPostPage || {}).length === 0
        ) {
          setActivities([]);
          setOffset(0);
          setResultSearchAll({
            accounts: [],
            groups: [],
            hashtags: [],
            pages: [],
            statuses: [],
            recruit: [],
            course: [],
            event: [],
            grow: [],
            product: []
          });
          setOffsetSearchGroupPage(0);
        }
      }
  }, [key, keyword, reload, createdBy, createdAt]);

  React.useEffect(() => {
    setOffset(0); // reset offset after change keyword
  }, [keyword]);

  React.useEffect(() => {
    if (type === 'recruit' || key === 'recruits') {
      handleSearchRecruit();
    } else if (type === 'course' || key === 'courses') {
      handleSearchCourse();
    } else if (type === 'event' || key === 'events') {
      handleSearchEvent();
    } else if (type === 'grow' || key === 'grows') {
      handleSearchGrow();
    } else if (type === 'product' || key === 'products') {
      handleSearchProduct();
    }
  }, [
    keyword,
    offset,
    province_id,
    key,
    createdBy,
    createdAt,
    recruit_category_id,
    course_category_id,
    product_category_id,
    work_experience,
    salary_min,
    salary_max,
    working_form,
    type
  ]);

  React.useEffect(() => {
    const validTypes = [
      'recruit',
      'course',
      'event',
      'grow',
      'product',
      'group',
      'user'
    ];
    const validKeys = ['events', 'recruits', 'courses', 'grows', 'products'];

    if (keyword && !validTypes?.includes(type) && !validKeys?.includes(key)) {
      loadActivity(offset, keyword, key);
    }
  }, [offset, keyword, key]);

  React.useEffect(() => {
    if (type === 'group' || type === 'page' || type === 'user') {
      if (type === 'group' || type === 'page') {
        handleSearchGroupOrPage(offsetSearchGroupPage);
      } else if (type === 'user') {
        handleSearchGroupOrPage();
      }
    }
  }, [offsetSearchGroupPage, keyword]);

  const fetchAboutUser = async id => {
    let response;
    response = await getAboutUser(id, null);

    if (response.status === 200) {
      setAboutUser(prev => [...prev, response.data]);
    }
  };

  const loadActivity = React.useCallback(
    (offset: any, keywords: any, keyType: any) => {
      handleSearchGlobal(offset, keywords, keyType);
    },
    []
  );

  const funcLoad = () => {
    if (key === 'all') return;
    if (type === 'group' || type === 'page') {
      setOffsetSearchGroupPage(preValue => {
        return preValue + 5;
      });
    } else
      setOffset(preValue => {
        return preValue + limit;
      });
  };

  const handleSearchByProvince = item => {
    // if (searchProvince === false) {
    //   history.push(
    //     `/product/search?q=${keyword.trim()}&province_id=${item.id}`
    //   );
    //   setSearchProvince(true);
    // } else if (searchProvince === true) {
    //   history.push(`/product/search?q=${keyword.trim()}`);
    //   setSearchProvince(false);
    // }
    history.push(`/product/search?q=${keyword.trim()}&province_id=${item.id}`);
  };

  const handleActionAddFriend = async (account, state) => {
    const action =
      account.relationships.friendship_status === 'OUTGOING_REQUEST'
        ? 'removeRequest'
        : account.relationships.friendship_status === 'CAN_REQUEST'
        ? 'addFriend'
        : account.relationships.friendship_status === 'INCOMING_REQUEST'
        ? 'approve'
        : account.relationships.friendship_status === 'ARE_FRIENDS'
        ? 'unFriend'
        : null;
    let response;
    if (state === 'resultSearchAll') {
      switch (action) {
        case 'removeRequest':
          try {
            response = await cancelRequestFriendApi(account.id);
            if (response.status === 200) {
              setResultSearchAll(prev => ({
                ...prev,
                accounts: prev.accounts.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'CAN_REQUEST'
                        }
                      }
                    : item
                )
              }));
            }
          } catch (error) {}
          break;
        case 'addFriend':
          try {
            response = await requestFriendApi(account.id);
            if (response.status === 200) {
              setResultSearchAll(prev => ({
                ...prev,
                accounts: prev.accounts.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'OUTGOING_REQUEST'
                        }
                      }
                    : item
                )
              }));
            }
          } catch (error) {}
          break;
        case 'approve':
          try {
            response = await approveRequestFriendApi(account.id);
            if (response.status === 200) {
              setResultSearchAll(prev => ({
                ...prev,
                accounts: prev.accounts.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'ARE_FRIENDS'
                        }
                      }
                    : item
                )
              }));
            }
          } catch (error) {}
          break;
        case 'unFriend':
          try {
            response = await unFriendUser(account.id);
            if (response.status === 200) {
              setResultSearchAll(prev => ({
                ...prev,
                accounts: prev.accounts.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'CAN_REQUEST'
                        }
                      }
                    : item
                )
              }));
            }
          } catch (error) {}
          break;
        default:
          break;
      }
    } else if (state === 'activities') {
      switch (action) {
        case 'removeRequest':
          try {
            response = await cancelRequestFriendApi(account.id);
            if (response.status === 200) {
              setActivities(prev =>
                prev.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'CAN_REQUEST'
                        }
                      }
                    : item
                )
              );
            }
          } catch (error) {}
          break;
        case 'addFriend':
          try {
            response = await requestFriendApi(account.id);
            if (response.status === 200) {
              setActivities(prev =>
                prev.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'OUTGOING_REQUEST'
                        }
                      }
                    : item
                )
              );
            }
          } catch (error) {}
          break;
        case 'approve':
          try {
            response = await approveRequestFriendApi(account.id);
            if (response.status === 200) {
              setActivities(prev =>
                prev.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'ARE_FRIENDS'
                        }
                      }
                    : item
                )
              );
            }
          } catch (error) {}
          break;
        case 'unFriend':
          try {
            response = await unFriendUser(account.id);
            if (response.status === 200) {
              setActivities(prev =>
                prev.map(item =>
                  item.id === account.id
                    ? {
                        ...item,
                        relationships: {
                          ...item.relationships,
                          friendship_status: 'CAN_REQUEST'
                        }
                      }
                    : item
                )
              );
            }
          } catch (error) {}
          break;
        default:
          break;
      }
    }
  };

  const handleSetSearchResult = (page, state) => {
    if (state === 'resultSearchAll') {
      setResultSearchAll(prev => ({
        ...prev,
        pages: prev.pages?.map(item =>
          item.id === page.id
            ? {
                ...item,
                page_relationship: {
                  ...item.page_relationship,
                  like: !item.page_relationship.like
                }
              }
            : item
        )
      }));
    } else if (state === 'activities') {
      setActivities(prev =>
        prev?.map(item =>
          item.id === page.id
            ? {
                ...item,
                page_relationship: {
                  ...item.page_relationship,
                  like: !item.page_relationship.like
                }
              }
            : item
        )
      );
    }
  };

  const handleLikePage = async (page, state) => {
    const action = page.page_relationship.like ? 'unlikes' : 'likes';
    handleSetSearchResult(page, state);
    try {
      let response: any = await likeFollowPage(page.id, action);
      if (response?.status !== 200) {
        handleSetSearchResult(page, state);
      }
    } catch (error) {
      handleSetSearchResult(page, state);
    }
  };

  const handleGroupAction = async (group, state) => {
    try {
      let checkCallApi = () => {
        if (group.group_relationship.requested) {
          return deleteMemberGroupApi(group.id);
        } else {
          return addMemberGroupApi(group.id, null);
        }
      };

      let response: any = await checkCallApi();
      if (response?.status === 200) {
        if (state === 'resultSearchAll') {
          setResultSearchAll(prev => ({
            ...prev,
            groups: prev.groups.map(item =>
              item.id === group.id
                ? {
                    ...item,
                    group_relationship: {
                      ...item.group_relationship,
                      ...response.data
                    }
                  }
                : item
            )
          }));
        } else if (state === 'activities') {
          setActivities(prev =>
            prev.map(item =>
              item.id === group.id
                ? {
                    ...item,
                    group_relationship: {
                      ...item.group_relationship,
                      ...response.data
                    }
                  }
                : item
            )
          );
        }
      }
    } catch (error) {}
  };

  const handleRequestMember = async data => {
    try {
      let response: ResponseApi = await addMemberGroupApi(
        groupSelected.id,
        data
      );
      const isPrivateGroup = groupSelected?.is_private;
      if (response.status === 200) {
        setOpenQuestion(false);
        if (typeSearch === 'resultSearchAll') {
          setResultSearchAll(prev => ({
            ...prev,
            groups: prev.groups.map(item =>
              item.id === groupSelected.id
                ? {
                    ...item,
                    group_relationship: {
                      ...item.group_relationship,
                      ...response.data
                    }
                  }
                : item
            )
          }));
        } else if (typeSearch === 'activities') {
          setActivities(prev =>
            prev.map(item =>
              item.id === groupSelected.id
                ? {
                    ...item,
                    group_relationship: {
                      ...item.group_relationship,
                      ...response.data
                    }
                  }
                : item
            )
          );
        }

        const notiMessage = Object.keys(data).length
          ? 'Câu trả lời của bạn đã được gửi thành công'
          : isPrivateGroup
          ? 'Yêu cầu của bạn đã được gửi thành công'
          : 'Bạn đã tham gia nhóm thành công';
        setNoti({
          code: 200,
          message: notiMessage
        });
        setOpenSnackbar(true);
        dispatch(resetMemberQuestion());
        setGroupSelected(null);
      }
    } catch (error: any) {
      setNoti({
        code: 400,
        message: error.response.data.error
      });
      setOpenSnackbar(true);
    }
  };

  const handleJoin = async (el: any) => {
    let response;
    if (key === 'events') {
      let typeJoin = el.event_relationship.status === '' ? 'interested' : '';
      response = await joinEventApi(el.id, {
        status: typeJoin
      });
      if (response.status === 200) {
        setActivities(prev =>
          prev.map(item =>
            item.id === el.id
              ? {
                  ...item,
                  event_relationship: {
                    ...item.event_relationship,
                    status: response?.data?.status ? 'interested' : ''
                  }
                }
              : item
          )
        );
      }
    } else if (key === 'recruits') {
      if (el.recruit_relationships.follow_recruit) {
        response = await noInteresteRecruitApi(el.id);
      } else {
        response = await interesteRecruitApi(el.id);
      }
      if (response.status === 200) {
        setActivities(prev =>
          prev.map(item =>
            item.id === el.id
              ? {
                  ...item,
                  recruit_relationships: {
                    ...item.recruit_relationships,
                    follow_recruit: !item.recruit_relationships.follow_recruit
                  }
                }
              : item
          )
        );
      }
    } else if (key === 'courses') {
      if (el.course_relationships.follow_course) {
        response = await noInteresteCourseApi(el.id);
      } else {
        response = await interesteCourseApi(el.id);
      }
      if (response.status === 200) {
        setActivities(prev =>
          prev.map(item =>
            item.id === el.id
              ? {
                  ...item,
                  course_relationships: {
                    ...item.course_relationships,
                    follow_course: !item.course_relationships.follow_course
                  }
                }
              : item
          )
        );
      }
    } else if (key === 'grows') {
      if (el.project_relationship.follow_project) {
        response = await noInteresteGrowApi(el.id);
      } else {
        response = await interesteGrowApi(el.id);
      }
      if (response.status === 200) {
        setActivities(prev =>
          prev.map(item =>
            item.id === el.id
              ? {
                  ...item,
                  project_relationship: {
                    ...item.project_relationship,
                    follow_project: !item.project_relationship.follow_project
                  }
                }
              : item
          )
        );
      }
    }
  };

  const handleSearchGroupOrPage = async (offsetSearchGroupPage: any = null) => {
    let params: any = {
      q: keyword
    };
    if (type === 'group' || type === 'page') {
      if (offsetSearchGroupPage > 0)
        params = { ...params, limit: 5, offset: offsetSearchGroupPage };
      else params = { ...params, limit: 5 };
    }
    if (createdBy) {
      params.created_by = createdBy === 'me' ? meInfo.id : createdBy;
    }
    if (createdAt) {
      // get the first and the last moment of year to filter
      params.start_time = moment(
        `Fri Aug 12 ${createdAt} 16:48:09 GMT+0700 (GMT+07:00)`
      )
        .startOf('year')
        .format();

      params.end_time = moment(
        `Fri Aug 12 ${createdAt} 16:48:09 GMT+0700 (GMT+07:00)`
      )
        .endOf('year')
        .format();
    }
    let response;
    if (type === 'group') {
      response = await searchGroupApi(id, params);
    } else if (type === 'page') {
      response = await searchPageApi(id, params);
    } else response = await searchPostUser(userId, params);
    if (response.status === 200) {
      setIsLoading(false);
      if (type === 'page' || type === 'group') {
        setResultSearchAll(prev => ({
          ...prev,
          accounts: response?.data['accounts']
            ? [...prev.accounts, ...response?.data['accounts']]
            : [],
          statuses: [...prev.statuses, ...response.data['statuses']]
        }));

        if (response.data['statuses'].length < 5) {
          setHasMore(false);
          // setIsLoading(false);
        }
      } else {
        setResultSearchAll({
          accounts: response.data['accounts'],
          statuses: response.data['statuses']
        });
      }
    }
  };
  React.useEffect(() => {
    if (dataSearchFilterPostUser !== undefined) {
      setResultSearchAll({
        statuses: dataSearchFilterPostUser
      });
    } else if (dataSearchFilterPostGroup !== undefined) {
      setResultSearchAll(dataSearchFilterPostGroup);
    } else if (dataSearchFilterPostPage !== undefined) {
      setResultSearchAll(dataSearchFilterPostPage);
    }
  }, [
    dataSearchFilterPostUser,
    dataSearchFilterPostGroup,
    dataSearchFilterPostPage
  ]);
  const handleSearchRecruit = async () => {
    let params: any = {
      q: keyword,
      limit: limit,
      offset: offset
    };
    if (working_form) {
      params.working_form = working_form;
    }
    if (recruit_category_id) {
      params.recruit_category_id = recruit_category_id;
    }
    if (work_experience) {
      params.work_experience = work_experience;
    }
    if (salary_min) {
      params.salary_min = salary_min;
    }
    if (salary_max) {
      params.salary_max = salary_max;
    }
    if (province_id) {
      params.province_id = province_id;
    }

    let response: ResponseApi = await getCalendarRecruitApi(params);

    if (response.status === 200) {
      dispatch(loadingSearchRecruit(false));
      setIsLoading(false);
      if (response.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (key === 'recruits') {
        setActivities(prev => {
          return unionBy(prev, response.data, 'id');
        });
      } else {
        setResultSearchAll(prev => ({
          recruit:
            offset === 0 ? response.data : [...prev.recruit, ...response.data]
        }));
      }
    }
  };

  const handleSearchCourse = async () => {
    let params: any = {
      q: keyword,
      limit: limit,
      offset: offset,
      status: 'approved'
    };

    if (course_category_id) {
      params.course_category_id = course_category_id;
    }
    let response: ResponseApi = await getCalendarCourseApi(params);

    if (response.status === 200) {
      setIsLoading(false);
      if (response.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (key === 'courses') {
        setActivities(prev => {
          return unionBy(prev, response.data, 'id');
        });
      } else {
        setResultSearchAll(prev => ({
          course:
            offset === 0 ? response.data : [...prev.course, ...response.data]
        }));
      }
    }
  };

  const handleSearchEvent = async () => {
    let params: any = {
      keyword: keyword,
      limit: limit,
      offset: offset
    };
    let response: ResponseApi = await getCalendarEventApi(params);
    if (response.status === 200) {
      setIsLoading(false);
      if (response.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (key === 'events') {
        setActivities(prev => {
          return unionBy(prev, response.data, 'id');
        });
      } else {
        setResultSearchAll(prev => ({
          event:
            offset === 0 ? response.data : [...prev.event, ...response.data]
        }));
      }
    }
  };

  const handleSearchGrow = async () => {
    let params: any = {
      keyword: keyword,
      limit: limit,
      offset: offset
    };
    let response: ResponseApi = await getCalendarGrowApi(params);

    if (response.status === 200) {
      setIsLoading(false);
      if (response.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (key === 'grows') {
        setActivities(prev => {
          return unionBy(prev, response.data, 'id');
        });
      } else {
        setResultSearchAll(prev => ({
          grow: offset === 0 ? response.data : [...prev.grow, ...response.data]
        }));
      }
    }
  };

  const handleSearchProduct = async () => {
    let params: any = {
      q: keyword,
      province_id: province_id
    };

    if (product_category_id) {
      params.category = product_category_id;
    }

    let response = await searchProduct(params);

    if (response.status === 200) {
      setIsLoading(false);
      setResultSearchAll({
        product: response.data
      });
    }
  };

  const getListCityProduct = async () => {
    let res = await getCityApiUpdate({ limit: 20 });
    if (res.status === 200) {
      setListLocation(res.data);
    }
  };

  React.useEffect(() => {
    if (type === 'product') {
      getListCityProduct();
    }
  }, []);

  const renderLoadingLeft = () =>
    Array.from(Array(5))?.map(item => (
      <div className={classes.titleLocation}>
        <Skeleton
          width="30px"
          height="20px"
          variant="circular"
          animation="wave"
          sx={{ borderRadius: 'unset', marginRight: '10px' }}
        />
        <Skeleton width="70%" animation="wave" />
      </div>
    ));

  const renderSeeMore = (type: any) => (
    <ButtonCustom
      label={
        (type === 'location' ? !showMoreLocation : null) ? (
          <Typography className={classes.showMore}>
            Xem thêm
            <i
              className="fa-light fa-chevron-down"
              style={{ paddingLeft: '10px' }}
            ></i>
          </Typography>
        ) : (
          <Typography className={classes.showMore}>
            Thu gọn
            <i
              className="fa-light fa-chevron-up"
              style={{ paddingLeft: '10px' }}
            ></i>
          </Typography>
        )
      }
      style={{
        backgroundColor: 'transparent',
        color: '#000',
        justifyContent: 'start',
        padding: '0px 2px'
      }}
      action={() =>
        type === 'location' ? setShowMoreLocation(!showMoreLocation) : null
      }
    />
  );

  const renderCategoryLeft = (data: any, type: any) => (
    <>
      {((type === 'location' ? !showMoreLocation : null)
        ? data?.slice(0, 5)
        : data
      )?.map(item =>
        type === 'location' ? (
          <FormControlLabel
            control={<Checkbox />}
            label={item.province_name}
            sx={{ width: '100%', padding: '0px 10px' }}
            onClick={() => handleSearchByProvince(item)}
          />
        ) : null
      )}
      {renderSeeMore(type)}
    </>
  );

  const renderShopProduct = () => (
    <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
      <Grid item sm={12} xs={12} display={'flex'} justifyContent={'center'}>
        <img
          src={
            'https://down-vn.img.susercontent.com/file/6231ea77f924e08aec0819a0f6fc8fde_tn'
          }
          alt="avatar_shop"
          className={classes.avatarShop}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography display={'flex'} justifyContent={'center'}>
          Mua sắm cùng puberty
        </Typography>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <span className={classes.labelFavouriteShop}>Yêu thích</span>
        <Divider orientation="vertical" flexItem />
        <span className={classes.labelRatingShop}>
          <StarIcon className={classes.starShop} />
          <span className={classes.ratingShop}>4.9</span>
        </span>
        <Divider orientation="vertical" flexItem />
        <span className={classes.labelFollower}>13,4k Followers</span>
      </Grid>
      <Grid item sm={12} xs={12} display={'flex'} justifyContent={'center'}>
        <button className={classes.buttonShop}>Xem shop</button>
      </Grid>
    </Grid>
  );

  const renderCardShopProduct = product => {
    const priceRange: any = product.product_variants?.map(item => item.price);
    return (
      <Grid container>
        <Grid item sm={12} xs={12} border={'1px solid #333'}>
          <img
            src={
              product?.product_image_attachments?.length
                ? product.product_image_attachments[0]?.attachment?.preview_url
                : productCoverDefault
            }
            alt="Name_product"
            className={classes.imgCardShop}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Tooltip title={product?.title}>
            <Typography
              gutterBottom
              component="div"
              className={classes.titleCardShop}
            >
              {product?.title.charAt(0).toUpperCase() + product?.title.slice(1)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          display={'flex'}
          justifyContent={'space-between'}
          paddingX={'3px'}
        >
          <Typography
            gutterBottom
            className={classes.priceCardShop}
            component={'span'}
          >
            {convertMoneyToVietnameseDongString(Math.min(...priceRange))}
          </Typography>
          <Typography
            gutterBottom
            className={classes.soldCardShop}
            component={'span'}
          >
            Đã bán {product?.sold}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCardItem = (titleShop: any, title: any, type: any) => {
    return (
      resultSearchAll[`${type}`]?.length > 0 && (
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <Grid container>
              <Grid item sm={12} xs={12}>
                {titleShop}
              </Grid>
              <Grid item sm={12} xs={12} className={classes.wrapperShop}>
                <Grid container spacing={1} columns={15}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                  >
                    {renderShopProduct()}
                  </Grid>
                  {resultSearchAll[`${type}`]
                    ?.slice(0, 3)
                    .map((el: any, index: any) => {
                      if (type === 'product') {
                        return (
                          <Grid key={index} item xs={3} sm={3}>
                            {renderCardShopProduct(el)}
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Box
              className={classes.wrapContent}
              sx={{
                border:
                  type === 'product' ? 'unset' : '1px solid rgba(0,0,0,0.1)',
                backgroundColor: 'transparent'
              }}
            >
              {title}
              <div className={classes.wrapButton}>
                <Typography className={classes.textFilter}>
                  Sắp xếp theo
                </Typography>
                <ButtonCustom
                  style={{
                    margin: '0px 5px',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? filterProduct === 'trending'
                          ? '#263951'
                          : '#4B4B4B'
                        : filterProduct === 'popular'
                        ? '#E7F3FF'
                        : '#fff',
                    color:
                      filterProduct !== false
                        ? theme.palette.mode === 'dark'
                          ? '#fff'
                          : '#050505'
                        : buttonColor.backgroundColor,
                    borderRadius: 'unset',
                    height: '40px'
                  }}
                  label="Phổ biến"
                  action={() => {
                    // history.push(
                    //   `/marketplace/categories/${idCategory?.idParent}${
                    //     idCategory?.idChild ? '/' + idCategory.idChild : ''
                    //   }?sortBy=popular`
                    // );
                    setOffset(0);
                  }}
                />
                <ButtonCustom
                  style={{
                    margin: '0px 5px',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? filterProduct === 'trending'
                          ? '#263951'
                          : '#4B4B4B'
                        : filterProduct === 'newest'
                        ? '#E7F3FF'
                        : '#fff',
                    color:
                      filterProduct !== false
                        ? theme.palette.mode === 'dark'
                          ? '#fff'
                          : '#050505'
                        : buttonColor.backgroundColor,
                    borderRadius: 'unset',
                    height: '40px'
                  }}
                  label="Mới Nhất"
                  action={() => {
                    // history.push(
                    //   `/marketplace/categories/${idCategory?.idParent}${
                    //     idCategory?.idChild ? '/' + idCategory.idChild : ''
                    //   }?sortBy=newest`
                    // );
                    setOffset(0);
                  }}
                />
                <ButtonCustom
                  style={{
                    margin: '0px 5px',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? filterProduct === 'newest'
                          ? '#263951'
                          : '#4B4B4B'
                        : filterProduct === 'trending'
                        ? '#E7F3FF'
                        : '#fff',
                    color:
                      filterProduct !== true
                        ? theme.palette.mode === 'dark'
                          ? '#fff'
                          : '#050505'
                        : buttonColor.backgroundColor,
                    borderRadius: 'unset',
                    height: '40px'
                  }}
                  label="Bán chạy"
                  action={() => {
                    // history.push(
                    //   `/marketplace/categories/${idCategory?.idParent}${
                    //     idCategory?.idChild ? '/' + idCategory.idChild : ''
                    //   }?sortBy=trending`
                    // );
                    setOffset(0);
                  }}
                />
                <ButtonOptions
                  label={
                    filterProduct
                      ? filterProduct === 'price_low'
                        ? 'Giá: Thấp đến Cao'
                        : filterProduct === 'price_high'
                        ? 'Giá: Cao đến Thấp'
                        : 'Giá'
                      : 'Giá'
                  }
                  endIcon={<ArrowDropDownIcon sx={{ fontSize: '25px' }} />}
                  options={[
                    {
                      id: 'price_low',
                      label: 'Giá: Thấp đến Cao',
                      action: () => {
                        // history.push(
                        //   `/marketplace/categories/${idCategory?.idParent}${
                        //     idCategory?.idChild ? '/' + idCategory.idChild : ''
                        //   }?sortBy=price_low`
                        // );
                        setOffset(0);
                      }
                    },
                    {
                      id: 'price_high',
                      label: 'Giá: Cao đến Thấp',
                      action: () => {
                        // history.push(
                        //   `/marketplace/categories/${idCategory?.idParent}${
                        //     idCategory?.idChild ? '/' + idCategory.idChild : ''
                        //   }?sortBy=price_high`
                        // );
                        setOffset(0);
                      }
                    }
                  ]}
                  style={{
                    width: '200px',
                    height: '40px',
                    borderRadius: 'unset',
                    margin: '0px 5px',
                    justifyContent: 'space-between',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? filterProduct === 'price_low' ||
                          filterProduct === 'price_high'
                          ? '#263951'
                          : '#4B4B4B'
                        : '#fff',
                    color:
                      theme.palette.mode === 'dark'
                        ? '#fff'
                        : filterProduct === 'price_low' ||
                          filterProduct === 'price_high'
                        ? '#ee4d2d'
                        : '#050505',

                    '&:hover': {
                      backgroundColor: 'button.custom.hover'
                    }
                  }}
                  labelStyle={{ fontSize: '16px', fontWeight: 500 }}
                />
              </div>

              <Grid container spacing={1}>
                {resultSearchAll[`${type}`]?.map((el: any, index: any) => {
                  if (type === 'product') {
                    return (
                      <Grid key={index} item xs={2.4}>
                        {renderCardProduct(el)}
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )
    );
  };

  const renderListItem = (title: any, type: any) => {
    let { key, id }: any = match.params;
    switch (id) {
      case 'search': {
        return (
          <>
            {resultSearchAll[`${type}`]?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  {title}
                </Typography>
                <List className={classes.wrapListItem}>
                  {resultSearchAll[`${type}`]?.map((el: any, index: any) => {
                    return type === 'recruit'
                      ? renderListRecruit(el)
                      : type === 'course'
                      ? renderListCourse(el)
                      : type === 'grow'
                      ? renderListGrow(el)
                      : type === 'event'
                      ? renderListEvent(el)
                      : renderCardProduct(el);
                  })}
                </List>
              </Box>
            )}
          </>
        );
      }
    }

    switch (key) {
      case 'all': {
        return (
          <>
            {resultSearchAll.accounts?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
                  Mọi người
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll.accounts.map((el: any, index: any) => (
                    <ListItem
                      key={index}
                      style={{
                        width: '100%',
                        height: '150px',
                        padding: '3px',
                        backgroundColor: 'background.primary'
                      }}
                    >
                      {renderItemList(
                        el,
                        aboutUser?.filter(item => item.id === el.id),
                        el.id,
                        'accounts',
                        () => handleActionAddFriend(el, 'resultSearchAll')
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {resultSearchAll.groups?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  Nhóm
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll.groups.map((el: any, index: any) => (
                    <ListItem
                      key={index}
                      style={{
                        width: '100%',
                        height: '80px',
                        padding: '3px'
                      }}
                    >
                      {renderItemList(el, null, el.id, 'groups', () => {
                        setTypeSearch('resultSearchAll');
                      })}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {resultSearchAll?.pages?.length > 0 ? (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  Trang
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll?.pages?.map((el: any, index: any) => (
                    <ListItem
                      key={index}
                      style={{
                        width: '100%',
                        height: '80px',
                        padding: '3px'
                      }}
                    >
                      {renderItemList(el, null, el.id, 'pages', () =>
                        handleLikePage(el, 'resultSearchAll')
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : null}
            {resultSearchAll.statuses?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  Bài viết
                </Typography>
                <div
                  style={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll.statuses.map((el: any, index: any) =>
                    renderItemPost(el, el.id)
                  )}
                </div>
              </Box>
            )}
            {resultSearchAll.hashtags?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  Hashtag
                </Typography>
                <List>
                  {resultSearchAll.hashtags.map((el: any, index: any) => (
                    <Typography
                      onClick={() => history.push(`/hashtag/${el.name}`)}
                      className={classes.textHashtag}
                    >{`#${el.name}`}</Typography>
                  ))}
                </List>
              </Box>
            )}
          </>
        );
      }

      case 'pages':
      case 'groups':
      case 'accounts': {
        return (
          <List sx={{ width: '100%' }}>
            {activities?.length &&
              activities?.map((el: any, index: any) => (
                <ListItem
                  key={index}
                  style={{
                    width: '100%',
                    height: '130px',
                    padding: 0,
                    borderRadius: '8px',
                    border: '1px solid rgba(0,0,0,0.1)',
                    marginTop: '10px'
                  }}
                >
                  {renderItemList(
                    el,
                    el.display_name
                      ? aboutUser?.filter(item => item.id === el.id)
                      : null,
                    el.id,
                    key,
                    el.display_name
                      ? () => handleActionAddFriend(el, 'activities')
                      : el.page_categories
                      ? () => handleLikePage(el, 'activities')
                      : () => setTypeSearch('activities')
                  )}
                </ListItem>
              ))}
          </List>
        );
      }
      case 'events':
      case 'recruits':
      case 'courses':
      case 'grows': {
        return (
          <List sx={{ width: '100%' }}>
            {activities?.length &&
              activities?.map((el: any, index: any) => (
                <ListItem
                  key={index}
                  style={{
                    width: '100%',
                    height: '150px',
                    padding: 0,
                    borderRadius: '8px',
                    border: '1px solid rgba(0,0,0,0.1)',
                    marginTop: '10px'
                  }}
                >
                  {renderItems(el, () => handleJoin(el))}
                </ListItem>
              ))}
          </List>
        );
      }
      case 'products': {
        return (
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {resultSearchAll?.product?.map((el: any, index: any) => {
              return (
                <Grid key={index} item xs={4} sm={4} md={4}>
                  {renderCardProduct(el)}
                </Grid>
              );
            })}
          </Grid>
        );
      }
      case 'statuses': {
        return (
          <div style={{ width: '100%' }}>
            {activities?.length &&
              activities?.map((el: any, index: any) =>
                renderItemPost(el, el.id)
              )}
          </div>
        );
      }

      case 'search': {
        return (
          <>
            {resultSearchAll?.accounts?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
                  Mọi người
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll?.accounts?.map((el: any, index: any) => (
                    <ListItem
                      key={index}
                      style={{
                        width: '100%',
                        height: '100px',
                        padding: 0
                      }}
                    >
                      {renderItemList(
                        el,
                        aboutUser?.filter(item => item.id === el.id),
                        el.id,
                        'accounts'
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {resultSearchAll.statuses?.length > 0 && (
              <Box className={classes.wrapContent}>
                <Typography fontSize="20px" fontWeight="600">
                  Bài viết
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    paddingRight: '0 15px 0 0'
                  }}
                >
                  {resultSearchAll.statuses.map((el: any, index: any) =>
                    renderItemPost(el, el.id)
                  )}
                </List>
              </Box>
            )}
          </>
        );
      }
    }
  };

  const checkTypeWidth = () => {
    if (type !== 'product') {
      if (type) {
        return '680px';
      } else {
        if (isMobile) {
          return '50vw';
        } else {
          return '80vw';
        }
      }
    } else {
      return '1180px';
    }
  };

  return (
    <div
      className={classes.root}
      style={{ flexDirection: isDesktopResolution ? 'row' : 'column' }}
    >
      <>
        {isLoading || recruitIsLoading ? (
          <Card
            sx={{
              width: '70%',
              height: '128px',
              marginTop: '30px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 2,
              ...boxShadow
            }}
          >
            <CardHeader
              avatar={
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={60}
                  height={60}
                />
              }
              title={<Skeleton animation="wave" height={20} width={200} />}
              subheader={<Skeleton animation="wave" height={20} width={300} />}
            />
          </Card>
        ) : activities?.length > 0 ||
          resultSearchAll?.accounts?.length > 0 ||
          resultSearchAll?.hashtags?.length > 0 ||
          resultSearchAll?.groups?.length > 0 ||
          resultSearchAll?.pages?.length > 0 ||
          resultSearchAll?.statuses?.length > 0 ||
          resultSearchAll?.recruit?.length > 0 ||
          resultSearchAll?.course?.length > 0 ||
          resultSearchAll?.event?.length > 0 ||
          resultSearchAll?.grow?.length > 0 ||
          resultSearchAll?.product?.length > 0 ? (
          <div className={classes.wrapLoadMore}>
            <InfiniteScroll
              dataLength={activities?.length}
              next={funcLoad}
              hasMore={hasMore}
              loader={
                !key?.includes('statuses') ||
                type === 'product' ||
                type === 'recruit' ||
                type === 'event' ? null : (
                  <Card
                    sx={{
                      marginTop: '15px',
                      ...boxShadow
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={40}
                          height={40}
                        />
                      }
                      title={
                        <Skeleton animation="wave" height={20} width="80%" />
                      }
                      subheader={
                        <Skeleton animation="wave" height={20} width="40%" />
                      }
                    />
                    {
                      <Skeleton
                        sx={{ height: '40vh' }}
                        animation="wave"
                        variant="rectangular"
                      />
                    }
                    <CardContent>
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={20}
                          style={{ marginBottom: 6 }}
                        />
                        <Skeleton animation="wave" height={20} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </Card>
                )
              }
              style={{
                overflow:
                  type &&
                  (type !== 'recruit' ||
                    type !== 'course' ||
                    type !== 'event' ||
                    type !== 'grow' ||
                    type !== 'product')
                    ? 'hidden'
                    : 'none',
                width: checkTypeWidth()
              }}
              scrollThreshold={0.8}
            >
              {type === 'recruit' ? (
                renderListItem('Tuyển dụng', 'recruit')
              ) : type === 'course' ? (
                renderListItem('Khóa học', 'course')
              ) : type === 'event' ? (
                renderListItem('Sự kiện', 'event')
              ) : type === 'grow' ? (
                renderListItem('Dự án', 'grow')
              ) : type === 'product' ? (
                <Grid container spacing={2}>
                  <Grid item xs={2} md={2} sm={2} lg={2} xl={2}>
                    <Box sx={{ padding: '16px 0px 0px 10px' }}>
                      <Typography className={classes.titleCategory}>
                        <i
                          className="fa-thin fa-filter"
                          style={{ marginRight: '10px', fontSize: '12px' }}
                        ></i>
                        Bộ lọc tìm kiếm
                      </Typography>
                      <Divider style={{ marginTop: '10px' }} />
                      {listLocation?.length
                        ? renderCategoryLeft(listLocation, 'location')
                        : renderLoadingLeft()}
                      <Divider style={{ margin: '10px 0px' }} />
                      <Box className={classes.wrapPriceFilter}>
                        <TextField
                          label="Giá thấp nhất"
                          type="number"
                          name="minPrice"
                          onChange={handlePriceRangeChange}
                          value={priceRange.minPrice}
                          InputProps={{
                            style: {
                              fontSize: 16,
                              color: '#333',
                              backgroundColor: '#fff',
                              marginTop: '10px'
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 16,
                              color: '#333',
                              marginTop: '10px'
                            }
                          }}
                        />
                        <TextField
                          label="Giá cao nhất"
                          type="number"
                          name="maxPrice"
                          onChange={handlePriceRangeChange}
                          value={priceRange.maxPrice}
                          InputProps={{
                            style: {
                              fontSize: 16,
                              color: '#333',
                              backgroundColor: '#fff',
                              marginTop: '10px'
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 16,
                              color: '#333',
                              marginTop: '10px'
                            }
                          }}
                        />
                        <ButtonInherit
                          fullWidth
                          label="ÁP DỤNG"
                          action={() => {}}
                          style={{ marginTop: '10px' }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    {renderCardItem(
                      <Box className={classes.wrapContentShop}>
                        <Typography
                          fontSize={18}
                          fontWeight={500}
                          className={classes.truncateText}
                          component={'span'}
                        >
                          SHOP LIÊN QUAN ĐẾN ""
                          <span style={{ color: '#ee4d2d' }}>{keyword}</span>"
                        </Typography>
                        <Typography
                          fontSize={16}
                          fontWeight={400}
                          className={classes.truncateText}
                          sx={{ color: '#ee4d2d', cursor: 'pointer' }}
                          component={'span'}
                          onClick={() => {}}
                        >
                          Thêm Kết Quả{' '}
                          <i className="fa-solid fa-angle-right"></i>
                        </Typography>
                      </Box>,
                      <Typography
                        fontSize={18}
                        fontWeight={500}
                        className={classes.truncateText}
                      >
                        <i className="fa-light fa-lightbulb-exclamation"></i>{' '}
                        Kết quả tìm kiếm cho từ khóa '
                        <span style={{ color: '#ee4d2d' }}>{keyword}</span>'
                      </Typography>,
                      'product'
                    )}
                  </Grid>
                </Grid>
              ) : (
                renderListItem(null, null)
              )}
            </InfiniteScroll>
          </div>
        ) : (
          <Box
            className={classes.wrapNoData}
            style={{ ...boxShadow, borderBottom: '1px !important' }}
          >
            <Typography
              style={{
                color: '#65676b',
                fontSize: 20,
                fontWeight: 700
              }}
            >
              Không có dữ liệu
            </Typography>
          </Box>
        )}
      </>
      {openQuestion && (
        <DialogQuestionGroup
          open={openQuestion}
          handleClose={() => {
            setOpenQuestion(false);
            setGroupSelected(null);
            dispatch(resetMemberQuestion());
          }}
          dataGroup={groupSelected}
          action={data => handleRequestMember(data)}
        />
      )}

      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </div>
  );
};

export default LoadMoreDataSearchGlobal;
