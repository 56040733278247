import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { default as Cropper, default as CropperRef } from 'react-easy-crop';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import getCroppedImg from 'src/components/Dialog/DialogEditProfile/cropImage';
import ListButton from 'src/components/List/ListButton';
import * as htmlToImage from 'html-to-image';
import Draggable from 'react-draggable';
import { buttonColor, modalBackdrop, scrollStyle } from 'src/constants/styles';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: any) => ({
  rootGrid: {
    padding: '2px !important',
    margin: '0px !important',
    width: '100% !important'
  },
  wrapAction: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.anTd.primary,
    borderRadius: 10
  },
  buttonArea: {
    position: 'absolute',
    top: 10,
    padding: '0px 10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 2000
  },
  iconClose: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: theme.palette.anTd.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textInput: {
    height: 80,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0
  },
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.anTd.primary,
    padding: '20px 0px'
  },
  myInput: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '30px',
    width: '100%',
    height: '300px',
    '&:focus-visible': {
      outline: 'unset !important'
    },
    resize: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  boxAddText: {
    width: '80%',
    margin: '0 auto'
  },
  optionColor: {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    padding: '10px',
    marginTop: '10px'
  },
  rootColor: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  boxColor: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    margin: '2px 6px',
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  boxText: {
    width: '100%',
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    userSelect: 'none',
    cursor: 'move',
    padding: '0px 10px'
  },
  draggableText: {
    padding: '10px',
    cursor: 'move',
    fontSize: '30px',
    transition: 'background-color 0.3s ease',
    position: 'absolute',
    top: '46%',
    left: '40%',
    whiteSpace: 'normal',
    overflow: 'visible',
    textAlign: 'center',
    '&:hover, &:focus': {
      border: '2px solid #fff'
    }
  },
  buttonClose: {
    position: 'absolute',
    top: '-14px',
    left: '-16px',
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: 0,
    ...scrollStyle,
    backgroundColor: theme.palette.anTd.primary,
    padding: '0px 0px 1px 0px',
    overflowX: 'hidden'
  },
  '& .MuiDialog-paper': {
    width: '1200px',
    maxWidth: '1200px !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

export default function CustomizedDialogs(props) {
  const { open, handleClose, files, setFiles, indexEditImage, formik, field } =
    props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const [isDisable, setIsDisable] = React.useState<any>(false);
  const [isCrop, setIsCrop] = React.useState<any>(false);
  const [isRotation, setIsRotation] = React.useState<any>(false);
  const [isInsertText, setIsInsertText] = React.useState<any>(false);
  const [isDragText, setIsDragText] = React.useState<any>(false);
  const [openAddText, setOpenAddText] = React.useState<any>(false);
  const [openOptionsColor, setOpenOptionsColor] = React.useState<any>(false);
  const [colorSelected, setColorSelected] =
    React.useState<any>('rgb(255, 255, 255)');
  const [crop, setCrop] = React.useState<any>({
    x: 0,
    y: 0
  });
  const [rotation, setRotation] = React.useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  const [croppedImage, setCroppedImage] = React.useState<any>();
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const theme: any = useTheme();
  const imageRef = React.useRef<CropperRef>(null);
  const imgRef: any = React.useRef<HTMLImageElement>(null);
  const inputFocus = React.useRef<any>(null);
  const [text, setText] = React.useState<any>('');
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleDrag = (e, draggableData) => {
    setPosition({ x: draggableData.x, y: draggableData.y });
  };

  const handleDoubleClick = () => {
    setIsInsertText(true);
  };

  const handleDeleteClick = () => {
    setText('');
  };

  function base64ToFile(base64: string, fileName: string): File {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        files[indexEditImage].preview,
        isRotation && !isCrop ? null : croppedAreaPixels,
        rotation
      );
      if (croppedImage) {
        const file = base64ToFile(
          croppedImage,
          formik?.values?.[field]
            ? formik?.values?.[field][indexEditImage].name
            : 'my-image.jpeg'
        );
        let fileAccepts = Object.assign(file, {
          path: formik?.values?.[field]
            ? formik?.values?.[field][indexEditImage].path
            : null,
          preview: URL.createObjectURL(file)
        });
        if (formik) {
          formik.setFieldValue(`${field}[${indexEditImage}]`, fileAccepts);
        }
        if (setFiles)
          setFiles([
            ...files.slice(0, indexEditImage),
            fileAccepts,
            ...files.slice(indexEditImage + 1)
          ]);
        setLoadingSave(false);
      }

      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(croppedAreaPixels), rotation]);

  const rotateImage = () => {
    setIsRotation(true);
    if (isInsertText) setIsInsertText(false);

    if (isDragText) setIsDragText(false);
    setRotation(rotation + 90);
  };
  const handleRotationComplete = newImageUrl => {
    setCroppedImage(newImageUrl);
  };
  const cropperProps =
    isRotation && !isCrop && !isInsertText
      ? { crop: { x: 0, y: 0, width: 1, height: 1 }, showGrid: false }
      : {};
  const customStyles =
    isRotation && !isCrop && !isInsertText
      ? {
          cropAreaStyle: {
            border: 'none ',
            position: 'static',
            transform: 'none'
          }
        }
      : {};

  const listMenuPages = [
    {
      id: 'cut',
      title: 'Cắt',
      icon: (
        <i
          className="fa-light fa-scissors"
          style={{
            fontSize: 20,
            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
          }}
        ></i>
      ),
      style: {
        height: 36,
        width: 36,

        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
      },
      styleButton: {
        marginLeft: '0px'
      },
      action: () => {
        setSelectedIndex('cut');
        setIsCrop(true);
        if (isInsertText) setIsInsertText(false);
        if (isDragText) setIsDragText(false);
      }
    },
    {
      id: 'turn',
      title: 'Xoay',
      icon: (
        <i
          className="fa-light fa-compass"
          style={{
            fontSize: 28,
            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
          }}
        ></i>
      ),
      style: {
        height: 36,
        width: 36,

        marginLeft: '0px',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
      },
      styleButton: {
        marginLeft: '0px'
      },
      action: () => {
        rotateImage();
        setSelectedIndex('turn');
        // history.push(PATHS.PAGE_DISCOVER);
      }
      // position: 'bottom'
    },
    {
      id: 'insert',
      title: 'Công cụ chèn văn bản',
      icon: (
        <i
          className="fa-light fa-thumbs-up"
          style={{
            fontSize: 24,
            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
          }}
        ></i>
      ),
      style: {
        height: 36,
        width: 36,

        marginLeft: '0px',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
      },
      styleButton: {
        marginLeft: '0px'
      },
      action: () => {
        setSelectedIndex('insert');
        setOpenAddText(!openAddText);
        setOpenOptionsColor(false);
      }
      // position: 'bottom'
    }
  ];

  const listOptionsColors = [
    {
      id: 1,
      color: 'rgb(0, 0, 0)'
    },
    {
      id: 2,
      color: 'rgb(255, 255, 255)'
    },
    {
      id: 3,
      color: 'rgb(248, 61, 61)'
    },
    {
      id: 4,
      color: 'rgb(41, 134, 174)'
    },
    {
      id: 5,
      color: 'rgb(94,213,255)'
    },
    {
      id: 6,
      color: 'rgb(136,191,75)'
    },
    {
      id: 7,
      color: 'rgb(248,226,76)'
    },
    {
      id: 8,
      color: 'rgb(249, 203, 209)'
    },
    {
      id: 9,
      color: 'rgb(251, 62, 160)'
    },
    {
      id: 10,
      color: 'rgb(220, 211, 239)'
    },
    {
      id: 11,
      color: 'rgb(43, 69, 124)'
    },
    {
      id: 12,
      color: 'rgb(88, 59, 154)'
    },
    {
      id: 13,
      color: 'rgb(148, 31, 177)'
    },
    {
      id: 14,
      color: 'rgb(202, 237, 248)'
    },
    {
      id: 15,
      color: 'rgb(185, 252, 203)'
    },
    {
      id: 16,
      color: 'rgb(244, 146, 58)'
    },
    {
      id: 17,
      color: 'rgb(241, 196, 58)'
    },
    {
      id: 18,
      color: 'rgb(247, 114, 74)'
    },
    {
      id: 19,
      color: 'rgb(112, 41, 41)'
    },
    {
      id: 20,
      color: 'rgb(142, 147, 156)'
    },
    {
      id: 21,
      color: 'rgb(206, 208, 212)'
    }
  ];

  React.useEffect(() => {
    inputFocus?.current?.focus();
  }, [inputFocus, isInsertText]);
  const handleTextChange = e => {
    setText(e.target.value);
  };
  const handleSave = async () => {
    const canvas = await htmlToImage.toCanvas(imgRef.current);
    const base64Image = canvas.toDataURL();
    const file = base64ToFile(base64Image, 'my-image.jpeg');
    let fileAccepts = Object.assign(file, {
      preview: URL.createObjectURL(file)
    });

    setFiles &&
      setFiles([
        ...files.slice(0, indexEditImage),
        fileAccepts,
        ...files.slice(indexEditImage + 1)
      ]);

    field &&
      formik.setFieldValue(field, [
        ...files.slice(0, indexEditImage),
        fileAccepts,
        ...files.slice(indexEditImage + 1)
      ]);

    handleClose();
    setLoadingSave(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableAutoFocus
        disableEnforceFocus={true}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Chi tiết ảnh
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', height: '780px' }}>
          <Card
            sx={{
              width: '30%',
              height: '100%',
              backgroundColor:
                theme.palette.mode === 'dark' ? '#383838' : '#fff',
              borderRadius: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <ListButton
                item={listMenuPages}
                style={{ height: '40px', width: '40px' }}
                selectedItem={selectedIndex}
                typeAction={'action'}
              />
              {openAddText ? (
                <Box className={classes.boxAddText}>
                  <ButtonInherit
                    label={
                      <Typography>
                        <i className="fa-solid fa-plus"></i> Thêm văn bản
                      </Typography>
                    }
                    style={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '10px'
                    }}
                    action={() => {
                      setIsInsertText(true);
                      setOpenOptionsColor(true);
                      if (isRotation) setIsRotation(false);
                      if (isCrop) setIsCrop(false);
                      if (isDragText) setIsDragText(false);
                    }}
                  />
                  {openOptionsColor ? (
                    <Box className={classes.optionColor}>
                      <Typography
                        sx={{ fontSize: '12px', padding: '0px 10px 10px' }}
                      >
                        Chọn màu chữ
                      </Typography>
                      <Box className={classes.rootColor}>
                        {listOptionsColors.map((item, index) => (
                          <Box
                            sx={{
                              backgroundColor: item.color,
                              border:
                                item.color === colorSelected
                                  ? '3px solid #7165e0'
                                  : 'unset'
                            }}
                            onClick={() => {
                              setColorSelected(item.color);
                              inputFocus?.current?.focus();
                            }}
                            className={classes.boxColor}
                            key={index}
                          ></Box>
                        ))}
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <ButtonInherit
                style={{
                  width: '40%',
                  '&:hover': {
                    backgroundColor: '#7165e0eb'
                  }
                }}
                color={buttonColor.backgroundColor}
                label={'Lưu'}
                action={() => {
                  setLoadingSave(true);
                  if (isDragText && !isRotation && !isCrop) {
                    handleSave();
                  } else {
                    showCroppedImage();
                  }
                }}
                disabled={isDisable}
                loading={loadingSave}
              />
              <ButtonInherit
                style={{ width: '40%' }}
                label={'Hủy'}
                action={() => handleClose()}
              />
            </Box>
          </Card>
          <Card
            sx={{
              backgroundColor: 'black',
              height: '100%',
              width: '70%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <>
              <div
                style={{
                  height: 280,
                  backgroundImage: `url(${files[indexEditImage]?.preview})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '10px 10px 0px 0px',
                  backgroundColor: '#8A8D91',
                  filter: 'opacity(0.5)'
                }}
              ></div>

              {croppedImage ? (
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    transform: `rotate(${rotation}deg)`
                  }}
                  src={croppedImage}
                  alt="Cropped"
                />
              ) : (isCrop || isRotation) &&
                files[indexEditImage] &&
                !isInsertText ? (
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Cropper
                    ref={imageRef}
                    image={files[indexEditImage]?.preview}
                    crop={crop}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    aspect={4 / 3}
                    {...cropperProps}
                    style={customStyles}
                    onRotationComplete={handleRotationComplete}
                  />
                </div>
              ) : isInsertText &&
                !isCrop &&
                !isRotation &&
                files[indexEditImage] ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      borderRadius: '10px 10px 0px 0px',
                      filter: 'opacity(0.5)',
                      objectFit: 'contain',
                      transform: `rotate(${rotation}deg)`
                    }}
                    
                    src={files[indexEditImage]?.preview}
                    alt="images"
                  />
                  <div className={classes.boxText}>
                    <textarea
                      ref={inputFocus}
                      className={classes.myInput}
                      style={{
                        color: colorSelected
                      }}
                      placeholder="Bắt đầu nhập"
                      value={text}
                      onChange={handleTextChange}
                      onBlur={e => {
                        handleTextChange(e);
                        setIsInsertText(false);
                        setIsDragText(true);
                      }}
                    ></textarea>
                  </div>
                </div>
              ) : isDragText &&
                !isCrop &&
                !isRotation &&
                files[indexEditImage] &&
                text ? (
                <div
                  className="image-with-text-container"
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  ref={imgRef}
                >
                  <img
                    src={files[indexEditImage]?.preview}
                    alt="Image"
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      objectFit: 'contain',
                      transform: `rotate(${rotation}deg)`
                    }}
                  />
                  <Draggable
                    onDrag={handleDrag}
                    defaultPosition={{ x: 0, y: 0 }}
                  >
                    <div
                      className={classes.draggableText}
                      style={{
                        color: colorSelected
                      }}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                      onDoubleClick={handleDoubleClick}
                    >
                      {isHovered && (
                        <button
                          className={classes.buttonClose}
                          onClick={handleDeleteClick}
                        >
                          <i
                            className="fa-solid fa-xmark"
                            style={{
                              width: '30px',
                              height: '30px',
                              backgroundColor: '#fff',
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '20px'
                            }}
                          ></i>
                        </button>
                      )}
                      {text}
                    </div>
                  </Draggable>
                </div>
              ) : (
                <img
                  style={{
                    width: '100%',
                    borderRadius: '10px 10px 0px 0px',
                    zIndex: 1800,
                    objectFit: 'contain',
                    transform: `rotate(${rotation}deg)`
                  }}
                  src={files[indexEditImage]?.preview}
                  alt="images"
                />
              )}
            </>
          </Card>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
