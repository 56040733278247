import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createGroupApi,
  groupCategoryApi,
  updateGroupApi
} from 'src/apis/socialGroup';
import { RegText } from 'src/common/string';
import ButtonCustom from 'src/components/Button/Button';
import ButtonOptions from 'src/components/Button/ButtonOptions';
import CustomPrompt from 'src/components/CustomPrompt';
import InputSelect from 'src/components/Input/InputSelect';
import ImageUploadProfile from 'src/components/ProfileCardImage/ImageUploadProfile';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor } from 'src/constants/styles';
import {
  getGroupDetailReq,
  updateGroupPreview
} from 'src/store/action/socialGroupAction';
import { urlWebEmso } from 'src/util/config';
import * as yup from 'yup';
const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '8px 3px 10px 3px',
    backgroundColor: theme.palette.background.primary,
    marginBottom: 30,
    borderRadius: 10,
    width: '100%',
    marginTop: 0,
    border: 0
  },
  textHelp: {
    fontSize: 11,
    fontFamily: 'inherit',
    '&.MuiFormHelperText-root.Mui-error': {
      color: 'red'
    }
  },
  loadingBox: {
    width: '100%',
    maxHeight: '180px',
    height: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '10px',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const GroupCreateUpdate = props => {
  const imageUpload = useSelector((state: any) => state?.settingReducer);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupUpdate } = props;
  const theme: any = useTheme();
  const [categories, setCategories] = React.useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [errorTag, setErrorTag] = React.useState(false);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const [createUpdateGroup, setCreateUpdateGroup] = React.useState(false);
  const fetchCategories = async () => {
    let response = await groupCategoryApi();
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  const createUpdateGroupAction = async data => {
    try {
      let response: any;
      if (groupUpdate) {
        response = await updateGroupApi(groupUpdate.id, data);
      } else {
        response = await createGroupApi(data);
      }
      if (response.status === 200) {
        setIsLoading(false);
        setNotificationLeave(false);
        if (groupUpdate) {
          dispatch(getGroupDetailReq(groupUpdate.id, ''));
          setOpenSnackbar(true);
          setNoti({
            code: 200,
            message: 'Cập nhật dữ liệu thành công'
          });
        } else {
          history.push(`/group/${response.data.username ?? response.data.id}`);
        }
      }
    } catch (error) {
      setOpenSnackbar(true);
      setIsLoading(false);
      setNoti({
        code: 400,
        message: 'Lỗi, vui lòng thử lại sau'
      });
    }
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .trim()
      .matches(RegText, 'Tên của nhóm không được chứa ký tự đặc biệt')
      .required('Tên của nhóm không được để trống')
      .min(5, 'Tên của nhóm phải lớn hơn 5 ký tự')
      .max(80, 'Tên của nhóm phải nhỏ hơn 80 ký tự'),
    tags: yup
      .string()
      .test(
        'check-tags-format',
        'Nhập các tag cách nhau bằng dấu phẩy',
        value => {
          if (!value) return true;
          const tags = value.split(',').map(tag => tag);
          return (
            tags.length === 1 ||
            (tags.length > 1 && tags.every(tag => tag !== ''))
          );
        }
      )
      .required('Tag không được để trống'),
    description: yup.string().max(3000, 'Không vượt quá 3000 kí tự'),
    group_category_id: yup
      .string()
      .nullable()
      .required('Danh mục nhóm không được để trống')
  });
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      group_category_id: null,
      cover_image: null,
      is_private: false,
      is_visible: true,
      tags: '',
      username: ''
    },
    validationSchema,
    onSubmit: (values: any) => {
      let data = values.cover_image?.id
        ? {
            ...values,
            cover_image: {
              id: values.cover_image.id,
              show_url: values.cover_image?.show_url ?? '',
              status: '',
              file: ''
            }
          }
        : {
            ...values
          };
      createUpdateGroupAction(data);
    }
  });
  React.useEffect(() => {
    dispatch(updateGroupPreview(formik.values));
  }, [JSON.stringify(formik.values)]);

  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (categories.length && !groupUpdate) {
      formik.setFieldValue('group_category_id', null);
    }
  }, [JSON.stringify(categories), JSON.stringify(groupUpdate)]);

  React.useEffect(() => {
    if (groupUpdate && Object.keys(groupUpdate).length) {
      let dataGroup: any = {
        title: groupUpdate.title,
        description: groupUpdate.description,
        group_category_id: groupUpdate.category?.id + '',
        cover_image: null,
        is_private: groupUpdate.is_private,
        is_visible: groupUpdate.is_visible,
        tags: groupUpdate.tags?.join(', '),
        username: groupUpdate.username
      };
      formik.setValues(dataGroup);
    }
  }, [JSON.stringify(groupUpdate)]);

  React.useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setCreateUpdateGroup(false);
    } else {
      setCreateUpdateGroup(true);
    }
  }, [JSON.stringify(formik.errors), JSON.stringify(formik.values)]);

  const optionPrivacy = [
    {
      id: 'public',
      label: 'Công khai',
      description:
        'Bất kì ai cũng có thể nhìn thấy mọi người trong nhóm và những gì họ đăng',
      icon: 'fas fa-globe-americas',
      styleIcon: { fontSize: '20px' },
      action: () => {
        formik.setFieldValue('is_private', false);
      }
    },
    {
      id: 'private',
      label: 'Riêng tư',
      icon: 'fas fa-lock-alt',
      description:
        'Chỉ thành viên mới nhìn thấy mọi người trong nhóm và những gì họ đăng',
      styleIcon: { fontSize: '20px' },
      action: () => {
        formik.setFieldValue('is_private', true);
      }
    }
  ];

  const optionHidden = [
    {
      id: 'show',
      label: 'Hiển thị',
      description: 'Ai cũng có thể tìm thấy nhóm này.',
      icon: 'fas fa-eye',
      styleIcon: { fontSize: '20px' },
      action: () => {
        formik.setFieldValue('is_visible', true);
      }
    },
    {
      id: 'hidden',
      label: 'Ẩn nhóm',
      description: 'Chỉ thành viên mới nhìn thấy nhóm này.',
      icon: 'fas fa-eye-slash',
      styleIcon: { fontSize: '20px' },
      action: () => {
        formik.setFieldValue('is_visible', false);
      }
    }
  ];
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };
  return (
    <div className={classes.root}>
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }} variant="standard">
              <b>Tên nhóm*</b>
              <BootstrapInput
                fullWidth
                placeholder="Nhập tên nhóm mới ..."
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <FormHelperText
                error={formik.touched.title && !!formik.errors.title}
                className={classes.textHelp}
                sx={{ fontSize: 13, width: 340, color: 'red' }}
              >
                {formik.touched.title && formik.errors.title}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }} variant="standard">
              <b>Mô tả nhóm</b>
              <BootstrapInput
                multiline
                rows={5}
                fullWidth
                placeholder="Nhóm này nói về..."
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormHelperText
                error={
                  formik.touched.description && !!formik.errors.description
                }
                sx={{ fontSize: 13, width: 340, color: 'red' }}
                className={classes.textHelp}
              >
                {formik.errors.description}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <b>Danh mục nhóm*</b>
            <InputSelect
              formik={formik}
              field="group_category_id"
              options={categories?.map((item: any) => ({
                ...item,
                value: item.text
              }))}
              setNotificationLeave={setNotificationLeave}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }} variant="standard">
              <b>Tag*</b>
              <BootstrapInput
                fullWidth
                placeholder="Nhập ngăn cách nhau dấu phẩy"
                name="tags"
                value={formik.values.tags}
                onBlur={formik.handleBlur}
                onChange={e => {
                  formik.handleChange(e);
                  formik.setFieldTouched('tags', true);
                }}
                onKeyPress={e => {
                  if (e.key === ' ' && !formik.values.tags.endsWith(',')) {
                    e.preventDefault();
                    setErrorTag(true);
                  } else {
                    setErrorTag(false);
                  }
                }}
              />
              {errorTag ? (
                <span
                  style={{ fontSize: '14px', marginLeft: '5px', color: 'red' }}
                >
                  Nhập ngăn cách nhau dấu phẩy.
                </span>
              ) : (
                formik.errors.tags && (
                  <Typography
                    className={classes.textHelp}
                    sx={{ fontSize: 13, width: 340, color: 'red' }}
                  >
                    {formik.errors.tags}
                  </Typography>
                )
              )}
            </FormControl>
          </Grid>
          {groupUpdate && (
            <Grid item xs={12}>
              <FormControl style={{ width: '100%' }} variant="standard">
                <b>Địa chỉ web</b>
                <p
                  style={{
                    color: '#255ef1',
                    fontSize: '16px',
                    fontWeight: 400
                  }}
                >
                  {`${urlWebEmso}/groups/`}
                </p>
                <BootstrapInput
                  fullWidth
                  placeholder="Địa chỉ web để người dùng truy cập nhóm nhanh chóng"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <b>Chế độ cài đặt nhóm</b>
            <List sx={{ width: '100%' }}>
              <Typography style={{ fontSize: '12px', fontWeight: '400' }}>
                Chọn quyền riêng tư
              </Typography>
              <ButtonOptions
                options={optionPrivacy}
                endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
                width={'100%'}
                type="group"
                label={
                  <ListItem>
                    <ListItemIcon>
                      <i
                        style={{ fontSize: '20px' }}
                        className={
                          formik.values.is_private
                            ? 'fas fa-lock-alt'
                            : 'fas fa-globe-americas'
                        }
                      ></i>
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-bluetooth"
                      primary={
                        formik.values.is_private ? 'Riêng tư' : 'Công khai'
                      }
                    />
                  </ListItem>
                }
                style={{
                  '&:hover': {
                    backgroundColor: theme.palette.button.default.hover
                  },
                  borderRadius: '8px',
                  border: '1px solid #ced4da',
                  backgroundColor: 'button.primary.background',
                  width: '100%',
                  height: '50px !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '0px 0px 20px 0px'
                }}
              />

              {formik.values.is_private && (
                <>
                  <Typography style={{ fontSize: '12px', fontWeight: '400' }}>
                    Ẩn nhóm
                  </Typography>
                  <ButtonOptions
                    options={optionHidden}
                    endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
                    width={'100%'}
                    label={
                      <ListItem>
                        <ListItemIcon>
                          <i
                            style={{ fontSize: '20px' }}
                            className={
                              formik.values.is_visible
                                ? 'fas fa-eye'
                                : 'fas fa-eye-slash'
                            }
                          ></i>
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-bluetooth"
                          primary={
                            formik.values.is_visible ? 'Hiển thị' : 'Ẩn nhóm'
                          }
                        />
                      </ListItem>
                    }
                    style={{
                      '&:hover': {
                        backgroundColor: theme.palette.button.default.hover
                      },
                      borderRadius: '8px',
                      border: '1px solid #ced4da',
                      backgroundColor: 'button.primary.background',
                      width: '100%',
                      height: '50px !important',
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: 0
                    }}
                  />
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <b>
              Ảnh bìa nhóm (định dạng <i>'jpeg'</i>, <i>'png'</i>)
            </b>
            <div
              style={{
                height: '180px',
                borderRadius: '10px',
                border: '1px solid rgba(0,0,0,0.2)',
                margin: '10px 0px',
                position: 'relative'
              }}
            >
              <ImageUploadProfile
                height={180}
                type="banner_group"
                coverImage={true}
                action={file => {
                  formik.setFieldValue(
                    'cover_image',
                    file
                      ? {
                          id: file?.id,
                          file: file.file,
                          show_url: file.show_url
                        }
                      : null
                  );
                }}
                setNotiParent={setNoti}
                setOpenSnackbarParent={setOpenSnackbar}
                cancelAction={() => {
                  formik.setFieldValue('cover_image', null);
                }}
                fileUpload={groupUpdate?.banner}
              />
              {imageUpload.loading && (
                <div className={classes.loadingBox}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </Grid>

          <Grid container item>
            {isLoading ? (
              <LoadingButton
                size="small"
                loading={isLoading}
                loadingPosition="end"
                variant="contained"
                fullWidth
                sx={{ textTransform: 'none', height: 36 }}
                disabled={Object.keys(formik.errors).length > 0}
              >
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 15
                  }}
                >
                  {groupUpdate ? 'Cập nhật thông tin nhóm' : 'Tạo nhóm'}
                </Typography>
              </LoadingButton>
            ) : (
              <ButtonCustom
                label={groupUpdate ? 'Cập nhật thông tin nhóm' : 'Tạo nhóm'}
                icon={null}
                action={() => {
                  formik.submitForm();
                  setIsLoading(true);
                }}
                solid={true}
                style={{
                  opacity: createUpdateGroup ? '1' : '0.5',
                  backgroundColor: createUpdateGroup
                    ? buttonColor.backgroundColor
                    : '#c2c2c2',
                  color: createUpdateGroup ? '#ffffff' : '#828282'
                }}
                disabled={createUpdateGroup ? false : true}
              />
            )}
          </Grid>
        </Grid>
      </form>

      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
};

export default GroupCreateUpdate;
