import * as React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Tooltip,
  Typography,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog
} from '@mui/material';
import PopupState from 'material-ui-popup-state';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getPinMessage, unPinMessage } from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';

import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { convertISO8601StringDayToDate } from 'src/common/string';
import DetailPinnedMessage from 'src/pages/Chat/BoxChat/DetailMessage/DetailPinnedMessage';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles((theme: any) => ({
  style_root: {
    width: '100%',
    height: '100%',
    marginTop: '10px'
  },
  styleTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: '700'
  },
  styleDialog: {
    height: '480px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  },
  styleNoPinnedMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  stylePinnedMessage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '0 32px'
    // marginTop: '40px'
  },
  styleDivShowActionItem: {
    width: '60px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  styleButtonActionMessage: {
    width: '100%',
    height: '36px',
    padding: 0
  },
  styleTitleActionMessage: {
    fontSize: '14px',
    fontWeight: '700'
  },
  styleBoxActionMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '80px'
    // width: '100%'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function DialogViewPinMessage(prop) {
  const { open, setOpen, conversation } = prop;
  const [listPinnedMessage, setListPinnedMessage] = React.useState<any>([]);
  const [anchorElMore, setAnchorElMore] =
    React.useState<HTMLButtonElement | null>(null);
  const [elCurrentSelect, setElCurrentSelect] = React.useState(null);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  let popupId = JSON.stringify(Math.random());
  const handleGetPinnedMessages = async () => {
    try {
      const roomId = await conversation.rid;

      const response = await getPinMessage(20, roomId);
      if (response.status === 200) {
        setListPinnedMessage(response.data.messages);
      }
    } catch (error) {
      console.log('[getPinnedMessage]', error);
    }
  };
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  React.useEffect(() => {
    handleGetPinnedMessages();
  }, []);
  const sortedListPinnedMessage = listPinnedMessage.sort(function (a, b) {
    const dateA = new Date(a.pinnedAt);
    const dateB = new Date(b.pinnedAt);

    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    } else {
      return 0;
    }
  });
  const element = {
    title: 'Xem thêm',
    id: 'more',
    icon: 'fa-solid fa-ellipsis'
  };

  const actionPinnedMessage = [
    {
      title: 'Bỏ ghim',
      action: message => {
        if (message) {
          handleUnPinMessage(message);
          setAnchorElMore(null);
        }
      }
    }
  ];

  const handleClickActionMessage = (
    el,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElMore(event.currentTarget);
    setElCurrentSelect(el);
  };

  const handleUnPinMessage = async (message: any) => {
    try {
      const response = await unPinMessage(message?._id, conversation?.rid);
      if (response.status === 200) {
        handleClose();
      }
    } catch (error) {
      console.log('[unpinMessage]', error);
    }
  };
  const openPopoverElMore = Boolean(anchorElMore);
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        style={{ height: '100%' }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className={classes.styleTitle}>Tin nhắn đã ghim</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className={classes.styleDialog}>
          {listPinnedMessage && listPinnedMessage.length ? (
            <Box className={classes.stylePinnedMessage}>
              <InfiniteScroll
                dataLength={listPinnedMessage?.length}
                next={() => console.log('next')}
                hasMore={true}
                loader={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <CircularProgress
                      sx={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                      }}
                    />
                  </div>
                }
                scrollThreshold={1}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  // marginTop: '120px',
                  marginBottom: '20px'
                }}
              >
                {sortedListPinnedMessage.map(el => {
                  const subjectName =
                    el?.u?.username === meInfo?.username
                      ? 'Bạn'
                      : el?.u?.name.split(' ')[0];
                  const handleActionClick = action => () => {
                    action(elCurrentSelect);
                  };
                  return (
                    <PopupState
                      variant="popover"
                      popupId={popupId}
                      key={'popover' + el?._id}
                    >
                      {PopupState => (
                        <Box
                          sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'end',
                            padding: '12px 0',
                            minWidth: '480px',
                            borderBottom: '1px solid'
                          }}
                        >
                          <Tooltip
                            title={el?.u.name}
                            arrow
                            placement="bottom"
                            sx={{
                              maxWidth: '120px'
                            }}
                          >
                            <Box>
                              <AvatarSocial
                                type="feed"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: '4px'
                                }}
                                avatarObj={`${urlRocketChat}/avatar/${el?.u?.username}`}
                              />
                            </Box>
                          </Tooltip>
                          <Box
                            style={{
                              display: 'flex',
                              flex: '1',
                              width: '100%',
                              flexDirection: 'column'
                            }}
                          >
                            <Typography style={{ fontSize: '12px' }}>
                              {subjectName}
                            </Typography>
                            <DetailPinnedMessage
                              message={el}
                              conversation={conversation}
                            />
                          </Box>
                          <Box className={classes.styleBoxActionMessage}>
                            <Typography style={{ fontSize: '12px' }}>
                              {convertISO8601StringDayToDate(el.ts)}
                            </Typography>
                            <Box>
                              <Tooltip title={element.title} placement="top">
                                <IconButton
                                  // aria-describedby={idPopoverElMore}
                                  sx={{ width: '14px', height: '14px' }}
                                  onClick={e => handleClickActionMessage(el, e)}
                                >
                                  <i
                                    style={{
                                      fontSize: '15px',
                                      color: '#8f9296'
                                    }}
                                    className={element.icon}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            {openPopoverElMore ? (
                              <Popover
                                // id={idPopoverElMore}
                                open={openPopoverElMore}
                                anchorEl={anchorElMore}
                                onClose={() => {
                                  setAnchorElMore(null);
                                }}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left'
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right'
                                }}
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500'
                                }}
                              >
                                <List sx={{ width: '100%', height: '100%' }}>
                                  {actionPinnedMessage.map((item, index) => (
                                    <ListItemButton
                                      className={
                                        classes.styleButtonActionMessage
                                      }
                                      key={index}
                                    >
                                      <ListItem
                                        onClick={handleActionClick(item.action)}
                                      >
                                        <span
                                          className={
                                            classes.styleTitleActionMessage
                                          }
                                        >
                                          {item?.title}
                                        </span>
                                      </ListItem>
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Popover>
                            ) : null}
                          </Box>
                        </Box>
                      )}
                    </PopupState>
                  );
                })}
              </InfiniteScroll>
            </Box>
          ) : (
            <Box className={classes.styleNoPinnedMessage}>
              <Typography style={{ fontSize: '16px', fontWeight: '600' }}>
                Chưa ghim tin nhắn nào
              </Typography>
              <i style={{ fontSize: '14px', opacity: '0.75' }}>
                Tin nhắn đã ghim trong đoạn chat sẽ hiển thị tại đây
              </i>
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
