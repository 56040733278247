import React, { lazy, memo, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import ShowNotifications from 'src/components/Header/HeaderGlobal/ShowNotifications';
import { PATHS } from 'src/constants/paths';
import AuthenticatedGuard from 'src/guards/AuthenticatedGuard';
import SearchGlobalCenter from 'src/pages/SeachGlobal/SearchGlobalCenter';
import DialogRecruitCreateCV from 'src/pages/Recruits/RecruitCenter/DialogRecruitCreateCV/';

const Home = lazy(() => import('src/pages/Home/Home'));
const User = lazy(() => import('src/pages/User/User'));
const Group = lazy(() => import('src/pages/Groups/GroupCenter'));
const PageHashtag = lazy(() => import('src/pages/PageInfomation/PageHashtag'));
const Page = lazy(() => import('src/pages/Pages/PageCenter'));
const PostSaved = lazy(() => import('src/pages/PageInfomation/PostSaved'));
const PostDetail = lazy(() => import('src/pages/PageInfomation/PostDetail'));
const GroupDetailPost = lazy(() => import('src/pages/Groups/GroupDetail'));
const Moment = lazy(() => import('src/pages/Moment'));
const Event = lazy(() => import('src/pages/Events/EventCenter'));
const Grow = lazy(() => import('src/pages/Grows/GrowCenter'));
const Recruit = lazy(() => import('src/pages/Recruits/RecruitCenter'));
const Course = lazy(() => import('src/pages/Courses/CourseCenter'));
const Marketplace = lazy(
  () => import('src/pages/Marketplaces/MarketplaceCenter')
);
const HistoryPayment = lazy(
  () => import('src/pages/HistoryPayment/PaymentCenter')
);
const Watch = lazy(() => import('src/pages/Watch'));
const WatchSearch = lazy(() => import('src/pages/Watch/WatchSearch/index'));
const Friends = lazy(() => import('src/pages/Friends'));
const Diary = lazy(() => import('src/pages/User/Diary/DiaryCenter'));
const Setting = lazy(() => import('src/pages/User/Settings/SettingsCenter'));
const Chat = lazy(() => import('src/pages/Chat/FullScreenChat/FullScreenChat'));
const LiveStreamCenter = lazy(
  () => import('src/pages/LiveStream/LiveStreamCenter')
);
const LiveStream = lazy(() => import('src/pages/LiveStream/index'));
const MusicSpace = lazy(
  () => import('src/pages/MusicSpace/MusicSpaceHome/index')
);
const AlbumCenter = lazy(() => import('src/pages/Album/AlbumCenter'));
const HelperCenter = lazy(() => import('src/pages/Helper_ES/index'));
const Sale = lazy(() => import('src/pages/Sale/Sale'));
const Welcome = lazy(() => import('src/pages/Welcome/index'));
const BloodDanation = lazy(
  () => import('src/pages/BooldDonation/BloodDonationCenter')
);
const Celebrate = lazy(() => import('src/pages/Celebrate/CelebrateCenter'));
function Routes(props) {
  const { refScroll } = props;
  const routes = [
    {
      path: [PATHS.HOME],
      component: <Home refScroll={refScroll} />
    },
    {
      path: [PATHS.USER],
      component: <User refScroll={refScroll} />
    },
    {
      path: [
        PATHS.GROUP,
        PATHS.GROUP_CATEGORY,
        PATHS.GROUP_CREATE,
        PATHS.GROUP_DETAIL,
        PATHS.GROUP_DISCOVER,
        PATHS.GROUP_SEARCH,
        PATHS.GROUP_REQUEST,
        PATHS.GROUP_JOINS,
        PATHS.GROUP_POST_DETAIL,
        PATHS.GROUP_ACTIVITIES_USER
      ],
      component: <Group refScroll={refScroll} />
    },

    {
      path: [PATHS.PAGE_HASHTAG],
      component: <PageHashtag />
    },
    {
      path: [PATHS.PAGE_SAVED, PATHS.PAGE_SAVED_DETAIL],
      component: <PostSaved refScroll={refScroll} />
    },
    {
      path: [
        PATHS.EVENT,
        PATHS.EVENT_CREATE,
        PATHS.EVENT_MINE,
        PATHS.EVENT_DETAIL,
        PATHS.EVENT_CATEGORY
      ],
      component: <Event refScroll={refScroll} />
    },
    {
      path: [
        PATHS.GROW,
        PATHS.GROW_CREATE,
        PATHS.GROW_MINE,
        PATHS.GROW_DETAIL,
        PATHS.GROW_CATEGORY,
        PATHS.GROW_NOTI_IDENTITY
      ],
      component: <Grow />
    },
    {
      path: [
        PATHS.RECRUIT,
        PATHS.RECRUIT_CREATE,
        PATHS.RECRUIT_MINE,
        PATHS.RECRUIT_DETAIL,
        PATHS.RECRUIT_CATEGORY,
        PATHS.RECRUIT_CREATECV
      ],
      component: <Recruit refScroll={refScroll} />
    },
    {
      path: [
        PATHS.COURSE,
        PATHS.COURSE_CREATE,
        PATHS.COURSE_MINE,
        PATHS.COURSE_DETAIL,
        PATHS.COURSE_CATEGORY
      ],
      component: <Course refScroll={refScroll} />
    },
    {
      path: [
        PATHS.MARKETPLACE,
        PATHS.PRODUCT_CREATE,
        PATHS.MARKETPLACE_MINE,
        PATHS.MARKETPLACE_SHOP,
        PATHS.PRODUCT_DETAIL,
        PATHS.ORDER_DETAIL,
        PATHS.MANAGE_ORDER_DETAIL,
        PATHS.MARKETPLACE_CATEGORY,
        PATHS.CATEGORY_GUIDE,
        PATHS.SALE,
        PATHS.MARKETPLACE_TOP_PRODUCTS,
        PATHS.MARKETPLACE_FLASH_SALE,
        PATHS.MARKETPLACE_CAMPAIGN,
        PATHS.MARKETPLACE_BEST_SELLER,
        PATHS.MARKETPLACE_FULL_STAR,
        PATHS.MARKETPLACE_MALL,
        PATHS.MARKETPLACE_CART_RECOMMEND,
        PATHS.MARKETPLACE_EMSO_COMMIT
      ],
      component: <Marketplace refScroll={refScroll} />
    },
    {
      path: [
        PATHS.PAYMENT,
        PATHS.PAYMENT_MONEY,
        PATHS.PAYMENT_HISTORY,
        PATHS.RECURRING_PAYMENTS,
        PATHS.REQUEST_PAYMENTS,
        PATHS.REFUND_PAYMENTS,
        PATHS.SETTINGS_PAYMENT,
        PATHS.PAYMENT_COIN,
        PATHS.PAYMENT_BUY,
        PATHS.PAYMENT_VTV_PAY,
        PATHS.PAYMENT_TERMS,
        PATHS.PAYMENT_BANK
      ],
      component: <HistoryPayment />
    },
    {
      path: [
        PATHS.PAGE,
        PATHS.PAGE_CREATE,
        PATHS.PAGE_LIKED,
        PATHS.PAGE_DETAIL,
        PATHS.PAGE_DISCOVER,
        PATHS.PAGE_INVITES
      ],
      component: <Page refScroll={refScroll} />
    },
    {
      path: [PATHS.SETTINGS_USER],
      component: <Setting refScroll={refScroll} />
    },
    {
      path: [PATHS.DIARY_USER],
      component: <Diary />
    },
    {
      path: [PATHS.POST_DETAIL],
      component: <PostDetail />
    },
    { path: [PATHS.GROUP_POST_DETAIL], component: <GroupDetailPost /> },
    {
      path: [PATHS.MOMENT, PATHS.MOMENT_TAG, PATHS.MOMENT_USER],
      component: <Moment />
    },

    {
      path: [PATHS.WATCH, PATHS.WATCH_PAGE, PATHS.WATCH_SHOW_DETAIL],
      component: <Watch />
    },
    {
      path: [
        PATHS.LIVESTREAM_HOME,
        PATHS.LIVESTREAM_FOLLOW,
        PATHS.LIVESTREAM_SELL,
        PATHS.LIVESTREAM_GAME,
        PATHS.LIVESTREAM_DETAIL
      ],
      component: <LiveStream />
    },
    {
      path: [PATHS.WATCH_SEARCH, PATHS.MOMENT_SEARCH],
      component: <WatchSearch />
    },
    {
      path: [PATHS.FRIEND],
      component: <Friends />
    },
    {
      path: [PATHS.SEARCH_GLOBAL],
      component: <SearchGlobalCenter />
    },
    {
      path: [PATHS.NOTI],
      component: <ShowNotifications />
    },
    {
      path: [PATHS.CHAT],
      component: <Chat />
    },

    {
      path: [
        PATHS.LIVESTREAM,
        PATHS.LIVESTREAM_CREATE,
        PATHS.LIVESTREAM_REALTIME
      ],
      component: <LiveStreamCenter refScroll={refScroll} />
    },
    {
      path: [
        PATHS.MUSICSPACE,
        PATHS.MUSICSPACE_CHART,
        PATHS.MUSICSPACE_NEW_CHART,
        PATHS.MUSICSPACE_ALBUM,
        PATHS.MUSICSPACE_SEARCH,
        PATHS.MUSICSPACE_MY_PLAYLIST,
        PATHS.MUSICSPACE_DETAIL_PLAYLIST,
        PATHS.MUSICSPACE_TRACK,
        PATHS.MUSICSPACE_DETAIL_SEARCH,
        PATHS.MUSICSPACE_NEW_RELEASE_SONG,
        PATHS.MUSICSPACE_NEW_RELEASE_ALBUM,
        PATHS.MUSICSPACE_CREATE_ALBUM,
        PATHS.MUSICSPACE_ARTIST,
        PATHS.MUSICSPACE_INVITE,
        PATHS.MUSICSPACE_DETAIL,
        PATHS.MUSICSPACE_DETAIL_ALBUM,
        PATHS.MUSICSPACE_UPLOADED,
        PATHS.MUSICSPACE_UPDATE_ALBUM,
        PATHS.MUSICSPACE_RANK_SONG,
        PATHS.MUSICSPACE_MUSIC_AUTHOR_LIST
      ],
      component: <MusicSpace refScroll={refScroll} />
    },
    {
      path: [PATHS.ALBUM_CREATE, PATHS.ALBUM_UPDATE, PATHS.ALBUM_DETAIL],
      component: <AlbumCenter />
    },

    {
      path: [PATHS.PATH_DETAILRECRUIT],
      component: <DialogRecruitCreateCV type={'seen'} open={true} />
    },
    {
      path: [
        PATHS.HELPER,
        PATHS.HELPER_ITEM,
        PATHS.HElPER_WRAP,
        PATHS.TERMS_ITEM,
        PATHS.POLICY_DOC
      ],
      component: <HelperCenter refScroll={refScroll} />
    },
    {
      path: [PATHS.WELCOME],
      component: <Welcome />
    },

    {
      path: [PATHS.BLOOD_DONATION],
      component: <BloodDanation />
    },
    {
      path: [PATHS.CELEBRATE, PATHS.CELEBRATENOTIFI, PATHS.CELEBRATEHIDE],
      component: <Celebrate />
    }
  ];
  return (
    <Switch>
      {routes.map((item, index) => {
        return (
          <AuthenticatedGuard
            key={index}
            path={item.path}
            exact
            component={() => (
              <Suspense fallback={<></>}>{item.component}</Suspense>
            )}
          />
        );
      })}
    </Switch>
  );
}

export default memo(Routes);
